import * as React from 'react';
import {
  BottomModalContainer as Container,
  ModalTitle,
  BottomModalContentWrapper,
} from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { useClickOutside } from '../../hooks';
import { useCallback, useRef } from 'react';

export interface Props {
  title: string;
  onDismiss?(): void;
  fullWidth?: boolean;
  dismissOnOutside?: boolean;
}

const Bottom: React.FC<Props> = React.memo(
  ({ title, children, onDismiss, fullWidth, dismissOnOutside }) => {
    const elementRef = useRef<HTMLDivElement>(null);

    const onClickOutside = useCallback(() => {
      if (dismissOnOutside) {
        onDismiss?.();
      }
    }, [dismissOnOutside, onDismiss]);

    useClickOutside({ elementRef, onClickOutside });

    return (
      <Container
        ref={elementRef}
        role="dialog"
        onClick={() => (!dismissOnOutside ? onDismiss?.() : undefined)}
        fullWidth={fullWidth}>
        <ModalTitle>
          <Typography variant={TypographyVariants.H3}>{title}</Typography>
        </ModalTitle>
        <BottomModalContentWrapper>{children}</BottomModalContentWrapper>
      </Container>
    );
  },
);

Bottom.displayName = 'Bottom';

export default Bottom;
