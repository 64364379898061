import Konva from 'konva';
import React, { useMemo } from 'react';
import { Arc, Arrow, Group, Text } from 'react-konva';
import { findAngle } from '../../../../utils/glass';
import { selectTheme, useConfigStore } from '../../store/config';

interface Props {
  position: Konva.Vector2d;
  points: [Konva.Vector2d, Konva.Vector2d, Konva.Vector2d];
  rotation: number;
}

const ARCH_RADIUS = 40;

const CornerAngle: React.FC<Props> = React.memo(
  ({ position, points, rotation }) => {
    const theme = useConfigStore(selectTheme);

    const labelProps = useMemo(() => {
      if (rotation === -90 || rotation === -180) {
        return {
          x: ARCH_RADIUS,
          y: ARCH_RADIUS - 8,
          rotation: 135,
        };
      }
      return {
        x: ARCH_RADIUS / 2 - 4,
        y: ARCH_RADIUS / 2 + 4,
        rotation: -45,
      };
    }, []);

    const angle = useMemo(() => findAngle(points[0], points[1], points[2]), [
      points,
    ]);
    const angleBeta = useMemo(() => {
      if (rotation === -270) {
        return (
          (points[1].x < points[0].x ? -1 : 1) *
          findAngle(points[0], points[1], {
            x: points[1].x,
            y: points[0].y,
          })
        );
      }

      if (rotation === -180) {
        return (
          (points[0].y < points[1].y ? 1 : -1) *
          findAngle(points[0], points[1], {
            x: points[0].x,
            y: points[1].y,
          })
        );
      }

      if (rotation === -90) {
        return (
          (points[0].x < points[1].x ? -1 : 1) *
          findAngle(points[0], points[1], {
            x: points[1].x,
            y: points[0].y,
          })
        );
      }

      return (
        (points[0].y < points[1].y ? -1 : 1) *
        findAngle(points[0], points[1], {
          x: points[0].x,
          y: points[1].y,
        })
      );
    }, [points, rotation]);

    return (
      <Group x={position.x} y={position.y} rotation={rotation}>
        <Arc
          stroke={theme.colors.mainBlue3}
          strokeWidth={1}
          lineJoin="round"
          tension={3}
          angle={angle}
          innerRadius={ARCH_RADIUS}
          outerRadius={ARCH_RADIUS}
          rotation={angleBeta}
        />
        <Arrow
          points={[0, -ARCH_RADIUS]}
          stroke={theme.colors.mainBlue3}
          fill={theme.colors.mainBlue3}
          strokeWidth={1}
          pointerWidth={5}
          pointerLength={5}
          rotation={90 + angle + angleBeta}
        />
        <Text text={String(angle) + '°'} {...labelProps} fontSize={10} />
        <Arrow
          points={[0, ARCH_RADIUS]}
          stroke={theme.colors.mainBlue3}
          fill={theme.colors.mainBlue3}
          strokeWidth={1}
          pointerWidth={5}
          pointerLength={5}
          rotation={-90 + angleBeta}
        />
      </Group>
    );
  },
);

CornerAngle.displayName = 'CornerAngle';

export default CornerAngle;
