import * as React from 'react';
import { CategoryOption as Container, Icon, CustomWrapper } from './styles';
import { InsertCategory, InsertOption } from './types';
import { Add } from '../icons';
import { Typography, TypographyVariants } from '../Typography';
import List from './List';
import { InsertNiche } from '../InsertNiche';
import { useTranslation } from 'react-i18next';
import { NicheItem } from '../InsertNiche';

export interface Props {
  category: InsertCategory;
  onSelect(option: InsertOption | NicheItem): void;
}

const CategoryOption: React.FC<Props> = React.memo(({ category, onSelect }) => {
  const { t } = useTranslation('project');

  return (
    <Container
      aria-label={category.title}
      role="option"
      disabled={category.disabled}>
      <Icon>
        <Add />
      </Icon>
      <Typography variant={TypographyVariants.H5}>{category.title}</Typography>
      {category.niche ? (
        <CustomWrapper role="listbox">
          <InsertNiche onSelect={onSelect} title={t('toolbar.insertNiche')} />
        </CustomWrapper>
      ) : (
        <List options={category.options} onSelect={onSelect} />
      )}
    </Container>
  );
});

CategoryOption.displayName = 'CategoryOption';

export default CategoryOption;
