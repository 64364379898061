import { IndentPosition } from '../../../../services/api/models/project';
import Big from 'big.js';
import { fixValue } from '../../utils/fix';

export const findIndentPositionX = (
  position: IndentPosition,
  templateWidth: number,
) => {
  switch (position) {
    default:
    case IndentPosition.TOP_LEFT:
    case IndentPosition.BOTTOM_LEFT: {
      return 0;
    }
    case IndentPosition.TOP_RIGHT:
    case IndentPosition.BOTTOM_RIGHT: {
      return fixValue(new Big(templateWidth).div(2), 5);
    }
  }
};

export const findIndentPositionY = (
  position: IndentPosition,
  templateHeight: number,
) => {
  switch (position) {
    default:
    case IndentPosition.BOTTOM_RIGHT:
    case IndentPosition.BOTTOM_LEFT: {
      return fixValue(new Big(templateHeight).div(2), 5);
    }
    case IndentPosition.TOP_RIGHT:
    case IndentPosition.TOP_LEFT: {
      return 0;
    }
  }
};
