export const createAndDownloadFileFromBlob = (
  data: string,
  fileType: string,
  fileName: string,
) => {
  const file = new Blob([data], { type: fileType });
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
};

export const validateExtension = (
  extension: string,
  supportedExtensions: string[],
) => {
  return supportedExtensions.includes(extension);
};
