export enum Endpoints {
  AUTH = '/auth',
  CLIENT = '/client',
  COMPANY = '/company',
  COMPANY_ADMIN = '/admin/company',
  EMAIL_ADMIN = '/admin/user/email',
  FAQ = '/faq',
  FILE = '/file',
  ORDERS = '/order',
  ORDERS_ADMIN = '/admin/order',
  OFFERS = '/offer',
  PARAMETERS = '/parameters',
  PDF_GENERATOR = '/pdf',
  PRODUCT = '/product',
  PRODUCT_ADMIN = '/admin/product',
  PROJECT = '/project',
  SUBSCRIPTION = '/subscription',
  USER = '/user',
}
