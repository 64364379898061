import * as React from 'react';

const SvgRightBg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M10 19a1 1 0 01-.77-1.64L13.71 12 9.39 6.63a1 1 0 01.15-1.41 1 1 0 011.46.15l4.83 6a1 1 0 010 1.27l-5 6A.999.999 0 0110 19z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgRightBg = React.memo(SvgRightBg);
export default MemoSvgRightBg;
