import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, EmptyWrapper } from './styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { Layout } from '../../containers/Layout';
import { CreateModal } from './components/CreateModal';
import {
  useContextItems,
  useCreateOffer,
  useCreateOrder,
  useCreateProject,
  useCreator,
  useOpenProject,
  useSave,
} from './hooks';
import { Creator } from '../../modules/creator';
import { Toolbar } from '../../components/Toolbar';
import { useAbility, useRWD } from '../../hooks';
import { InsertNiche } from '../../components/InsertNiche';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from './components/ContextMenu';
import { PropertyPanel } from './containers/PropertyPanel';
import useInsert from '../../hooks/useInsert';
import { ProductsPanel } from './containers/ProductsPanel';
import { Action, Subjects } from '../../services/api/models/permissions';
import { RootRoutes } from '../../router/routes';
import { projectApi } from '../../services/api/services';
import useRevalidateProject from './hooks/useRevalidateProject';

export interface RouteParams {
  id?: string;
}

export interface LocationState {
  create?: boolean;
  templateId?: number;
}

const Project: React.FC = () => {
  const { t } = useTranslation('project');
  const { id } = useParams<RouteParams>();
  const location = useLocation<LocationState>();
  const { goBack, replace } = useHistory();
  const { isMobile } = useRWD();
  const { fetch: fetchProject, fetchTemplate } = useOpenProject();
  const [showCreatingMenu, setShowCreatingMenu] = useState(false);
  const ability = useAbility();

  const isTemplate = useMemo(
    () => location.pathname.includes(RootRoutes.TEMPLATE),
    [location.pathname],
  );

  const canSaveTemplate = useMemo(
    () => ability.can(Action.EDIT, Subjects.PROJECT_TEMPLATE_EDIT),
    [ability],
  );

  const canCreateOrder = useMemo(
    () => ability.can(Action.EDIT, Subjects.ORDER_EDIT),
    [ability],
  );

  const canSaveProject = useMemo(
    () => ability.can(Action.EDIT, Subjects.PROJECT_EDIT),
    [ability],
  );

  const {
    project,
    saved,
    pointer,
    showAngles,
    toggleShowAngles,
    onChangePointer,
    onSelectScale,
    selectedScale,
    onRedo,
    onUndo,
    disableRedo,
    disableUndo,
    isHistoryEnd,
    templateId,
    contextMenu,
    closeContextMenu,
    deselect,
    projectProducts,
    projectGlasses,
    projection,
    onSelectProjection,
    validatedHistoryPointer,
    historyPointer,
    toggleValidateProject,
  } = useCreator();

  const { save, saveProjectTemplate } = useSave(project);

  const contextItems = useContextItems();

  const projectId = useMemo(() => id ?? project?.id, [project?.id, id]);

  const hasTemplate =
    typeof templateId !== 'undefined' || location.state?.create;

  const disableSave = !hasTemplate || (saved && isHistoryEnd);

  const [isGlassBeingCreated, setIsGlassBeingCreated] = useState(false);
  const onGlassCreationStart = useCallback(() => {
    setIsGlassBeingCreated(true);
  }, []);
  const onGlassCreationComplete = useCallback(() => {
    setIsGlassBeingCreated(false);
  }, []);

  const { onInsert, insertNiche } = useInsert({
    onGlassCreationStart,
    onGlassCreationComplete,
  });

  const onCancelCreate = useCallback(() => {
    projectApi.createSource.cancel();
    goBack();
  }, [goBack]);

  const onCreated = useCallback(() => {
    replace({ ...location, state: { create: false } });
  }, [location, replace]);

  const handleOpenCreatingMenu = useCallback(() => {
    setShowCreatingMenu(!showCreatingMenu);
  }, [showCreatingMenu]);

  const { progress, pending } = useCreateProject({
    onCreated,
    onCancel: goBack,
    runProcess: location.state?.create,
    templateId: location.state?.templateId,
    createProjectTemplate: canSaveTemplate,
  });

  const {
    create: createOrder,
    pending: orderPending,
    progress: orderProgress,
    cancel: orderCancel,
  } = useCreateOrder(project);

  const {
    create: createOffer,
    pending: offerPending,
    progress: offerProgress,
    cancel: offerCancel,
  } = useCreateOffer(project);

  useHotkeys(
    'ctrl+s',
    (e) => {
      e.preventDefault();
      !disableSave && (canSaveTemplate ? saveProjectTemplate() : save());
    },
    [disableSave, canSaveTemplate, saveProjectTemplate, save],
  );

  useHotkeys(
    'ctrl+shift+s',
    (e) => {
      e.preventDefault();
      canSaveTemplate ? saveProjectTemplate('saveAs') : save('saveAs');
    },
    [disableSave, canSaveTemplate, saveProjectTemplate, save],
  );

  useHotkeys(
    'ctrl+z',
    (e) => {
      e.preventDefault();
      onUndo();
    },
    [],
  );

  useHotkeys(
    'ctrl+shift+z',
    (e) => {
      e.preventDefault();
      onRedo();
    },
    [],
  );

  useEffect(() => {
    if (id && Number(id) !== -1) {
      deselect();
      isTemplate
        ? fetchTemplate(Number(projectId))
        : fetchProject(Number(projectId));
    }
  }, []);

  // The Creator appears cut off when small window is resized to the size of the big one
  // this is easiest fix of the problem
  const [rerender, forceRerender] = useState(false);
  const renderCreator = useCallback(() => {
    if (rerender) {
      return <Creator />;
    } else {
      return (
        <>
          <div></div>
          <Creator />
        </>
      );
    }
  }, [rerender]);

  useEffect(() => {
    const unsub = () => {
      forceRerender((prevState) => !prevState);
    };
    window.addEventListener('resize', unsub);
    return () => window.removeEventListener('resize', unsub);
  }, []);

  return (
    <Layout disableEvents={pending}>
      <Toolbar
        disableCreationActions={isGlassBeingCreated}
        disableSelectProjection
        onChangePointer={onChangePointer}
        selectedPointer={pointer}
        onSelectScale={onSelectScale}
        selectedScale={selectedScale}
        onInsert={onInsert}
        onUndo={onUndo}
        onRedo={onRedo}
        onSelectProjection={onSelectProjection}
        selectedProjection={projection}
        onSave={save}
        onCreateOrder={createOrder}
        onCreateOffer={createOffer}
        onOpenCreatingMenu={handleOpenCreatingMenu}
        onToggleShowAngles={toggleShowAngles}
        disableCreateOrder={
          projectGlasses.length < 1 && projectProducts.length < 1
        }
        shrink={isMobile}
        showAngles={showAngles}
        disableSave={disableSave}
        disableRedo={disableRedo}
        disableUndo={disableUndo}
        showCreatingMenu={showCreatingMenu}
        canSaveTemplate={canSaveTemplate}
        canSaveProject={canSaveProject}
        canCreateOrder={canCreateOrder}
        revalidateProject={
          validatedHistoryPointer < historyPointer && !disableSave
        }
        onRevalidateProject={toggleValidateProject}
        onSaveTemplate={saveProjectTemplate}
      />
      <Container>
        {hasTemplate ? (
          renderCreator()
        ) : (
          <EmptyWrapper>
            <InsertNiche
              onSelect={({ value }) => insertNiche(value)}
              title={t('insertNiche')}
              alignTitle="center"
            />
          </EmptyWrapper>
        )}
        <ProductsPanel />
        <PropertyPanel />
      </Container>
      <CreateModal
        show={pending}
        title={t('modals.create.title')}
        description={t('modals.create.description')}
        onCancel={onCancelCreate}
        progress={progress}
      />
      <CreateModal
        show={orderPending}
        title={t('modals.createOrder.title')}
        description={t('modals.createOrder.description')}
        onCancel={orderCancel}
        progress={orderProgress}
      />
      <CreateModal
        show={offerPending}
        title={t('modals.createOffer.title')}
        description={t('modals.createOffer.description')}
        onCancel={offerCancel}
        progress={offerProgress}
      />
      <ContextMenu
        show={!!contextMenu}
        top={contextMenu?.position.y ?? 0}
        left={contextMenu?.position.x ?? 0}
        onDismiss={closeContextMenu}
        items={contextItems}
      />
    </Layout>
  );
};

export default Project;
