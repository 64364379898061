import * as React from 'react';

const SvgRlshape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={109}
      height={109}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g opacity={0.2}>
        <mask
          id="rlshape_svg__a"
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={109}
          height={109}>
          <path
            transform="rotate(-180 108.5 108.5)"
            fill="#828A98"
            stroke="#828A98"
            d="M108.5 108.5h108v108h-108z"
          />
        </mask>
        <g mask="url(#rlshape_svg__a)">
          <path
            stroke="#828A98"
            d="M86.602 153.963L256.837-16.273M76.804 153.963L247.04-16.273M67.006 153.963L237.242-16.273M57.208 153.963L227.444-16.273M47.41 153.963L217.646-16.273M37.613 153.963L207.849-16.273M27.815 153.963L198.051-16.273M18.017 153.963L188.253-16.273M8.219 153.963L178.455-16.273M-1.578 153.963L168.658-16.273M-11.376 153.963L158.86-16.273M-21.174 153.963L149.062-16.273M-30.971 153.963L139.264-16.273M-40.769 153.963L129.467-16.273M-50.567 153.963L119.669-16.273M-60.365 153.963L109.871-16.273M-70.162 153.963L100.074-16.273M-79.96 153.963L90.276-16.273M-89.758 153.963L80.478-16.273M-99.556 153.963L70.68-16.273M-109.354 153.963L60.882-16.273M-119.151 153.963L51.084-16.273M-128.949 153.963L41.287-16.273M-138.747 153.963L31.489-16.273M-148.544 153.963L21.692-16.273M-158.342 153.963L11.894-16.273"
          />
          <path fill="#fff" d="M45 111H-5V61h50z" />
        </g>
      </g>
      <path d="M101 101H51.78V55H9V9h92v92z" fill="#D3D7E0" />
    </svg>
  );
};

const MemoSvgRlshape = React.memo(SvgRlshape);
export default MemoSvgRlshape;
