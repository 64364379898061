import { Position } from '../types';
import { configStore } from '../store/config';

export const round = (position: Position): Position => ({
  x: Math.round(position.x),
  y: Math.round(position.y),
});

export const translateToCanvasPosition = (x: number, y: number): Position => {
  const { mainLayerPosition, containerRect } = configStore.getState();
  return {
    x: x - mainLayerPosition.x - (containerRect?.x ?? 0),
    y: y - mainLayerPosition.y - (containerRect?.y ?? 0),
  };
};
