import styled, { css } from 'styled-components';
import { layout } from '../../../../styles';

const emptyContent = css`
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Content = styled(layout.Column)<{ empty?: boolean }>`
  flex-grow: 1;
  ${({ empty }) => empty && emptyContent}
  height: calc(100% - 60px);
  overflow-x: hidden;
  overflow-y: auto;
`;

const with_padding = css`
  padding: 16px 15px;
`;

export const FormSection = styled.div<{ noPadding?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  ${({ noPadding }) => !noPadding && with_padding}
`;
