export enum HorizontalPositioning {
  LEFT = 'LEFT',
  MIDDLE = 'MIDDLE_H',
  RIGHT = 'RIGHT',
}

export enum VerticalPositioning {
  TOP = 'TOP',
  MIDDLE = 'MIDDLE_V',
  BOTTOM = 'BOTTOM',
}
