import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  usePasswordInput,
  usePasswordValid,
  PasswordValidators,
} from '../../hooks';
import { Button, ButtonTypes } from '../Button';
import { Input } from '../Input';
import { TooltipPosition, TooltipType, Tooltip } from '../Tooltip';
import { TypographyVariants } from '../Typography';
import { Password } from '../icons';
import {
  ButtonsWrapper,
  Form,
  Header,
  FormBody,
  TooltipWrapper,
  NotificationWrapper,
} from './styles';
import { passwordLow } from '../../utils/regexp';
import { PasswordChecklist } from '../PasswordChecklist';
import { Notification, NotificationVariants } from '../Notification';

export interface UpdateFormValues {
  oldPassword: string;
  password: string;
  retypedPassword: string;
}

export interface Props {
  onSubmit(
    values: UpdateFormValues,
    helpers: FormikHelpers<UpdateFormValues>,
  ): void;
  onCancel(): void;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  submitError: boolean;
}

const initialValues: UpdateFormValues = {
  oldPassword: '',
  password: '',
  retypedPassword: '',
};

const passwordValidators: PasswordValidators = {
  requirement1: (pw) => pw.length >= 8,
  requirement2: (pw) => !!pw.match(passwordLow),
};

const PasswordChangeForm: React.FC<Props> = React.memo(
  ({
    onCancel,
    onSubmit,
    submitButtonTitle,
    cancelButtonTitle,
    submitError,
    ...props
  }) => {
    const { t } = useTranslation('login');
    const [isActive, toggleActive] = useState(false);

    const {
      Icon: CurrentIcon,
      inputType: currentType,
      toggleShow: CurrentToggleShow,
    } = usePasswordInput();

    const {
      Icon: NewIcon,
      inputType: newType,
      toggleShow: NewToggleShow,
    } = usePasswordInput();

    const {
      Icon: ReIcon,
      inputType: reType,
      toggleShow: ReToggleShow,
    } = usePasswordInput();

    const validationSchema = useMemo(
      () =>
        Yup.object().shape({
          oldPassword: Yup.string().required(t('forms.errors.required')),
          password: Yup.string().required(t('forms.errors.required')),
          retypedPassword: Yup.string().required(t('forms.errors.required')),
        }),
      [t],
    );

    const formik = useFormik<UpdateFormValues>({
      initialValues,
      onSubmit: (values, helpers) => onSubmit(values, helpers),
      validationSchema,
    });

    const {
      valid,
      match: passwordsMatch,
      results: { requirement1, requirement2 },
    } = usePasswordValid(
      formik.values.password,
      passwordValidators,
      formik.values.retypedPassword,
    );

    const handleChange = useCallback(
      (value: string, name: string) => {
        formik.setFieldValue(name, value);
        formik.setTouched({ ...formik.touched, [name]: true });
      },
      [formik],
    );

    const {
      errors,
      values: { oldPassword, password, retypedPassword },
      touched,
      isValid,
      dirty,
      isSubmitting,
    } = formik;

    return (
      <Form {...props} onSubmit={formik.handleSubmit}>
        <Header variant={TypographyVariants.H1}>
          {t('forms.password.changePassword')}
        </Header>
        {(passwordsMatch === false || submitError) && (
          <NotificationWrapper>
            <Notification
              text={t(
                passwordsMatch === false
                  ? 'notifications.matchPassword'
                  : 'notifications.general',
              )}
              variant={NotificationVariants.ERROR}
              fullWidth
            />
          </NotificationWrapper>
        )}
        <FormBody>
          <Input
            name="oldPassword"
            testId="oldPassword-input"
            label={t('forms.password.currentPassword')}
            placeholder={t('forms.password.currentPassword')}
            type={currentType}
            onChange={handleChange}
            value={oldPassword}
            invalid={touched['oldPassword'] && !!errors['oldPassword']}
            errorMessage={errors['oldPassword']}
            Leading={<Password />}
            Trailing={CurrentIcon}
            onTrailingClick={CurrentToggleShow}
            fullWidth
          />
          <Input
            name="password"
            testId="password-input"
            onFocus={() => toggleActive(true)}
            onBlur={() => toggleActive(false)}
            label={t('forms.password.newPassword')}
            placeholder={t('forms.password.newPassword')}
            type={newType}
            onChange={handleChange}
            value={password}
            invalid={touched['password'] && !!errors['password']}
            errorMessage={errors['password']}
            Leading={<Password />}
            Trailing={NewIcon}
            onTrailingClick={NewToggleShow}
            fullWidth
          />
          <Input
            name="retypedPassword"
            testId="retypedPassword-input"
            label={t('forms.password.confirmationPassword')}
            placeholder={t('forms.password.confirmationPassword')}
            type={reType}
            onChange={handleChange}
            value={retypedPassword}
            invalid={
              (touched['retypedPassword'] && !!errors['retypedPassword']) ||
              passwordsMatch === false
            }
            errorMessage={errors['retypedPassword']}
            Leading={<Password />}
            Trailing={ReIcon}
            onTrailingClick={ReToggleShow}
            fullWidth
          />
          <TooltipWrapper>
            <Tooltip
              text={t('passwordCheck.title')}
              CustomComponent={
                <PasswordChecklist
                  validation={{ requirement1, requirement2 }}
                />
              }
              position={TooltipPosition.RIGHT}
              type={TooltipType.BIG}
              alwaysVisible={isActive}
            />
          </TooltipWrapper>
        </FormBody>

        <ButtonsWrapper>
          <Button
            testId="cancel-button"
            title={cancelButtonTitle || t('forms.password.cancel')}
            type={ButtonTypes.SECONDARY}
            onClick={onCancel}
          />
          <Button
            testId="submit-button"
            title={submitButtonTitle || t('forms.password.changePassword')}
            htmlButtonType="submit"
            disabled={
              !dirty || !isValid || isSubmitting || !valid || !passwordsMatch
            }
            fullWidth
          />
        </ButtonsWrapper>
      </Form>
    );
  },
);

PasswordChangeForm.displayName = 'PasswordChangeForm';

export default PasswordChangeForm;
