import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
`;

export const Header = styled(layout.Row)<{ active?: boolean }>`
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 12px 16px;
  user-select: none;
  cursor: pointer;
  > svg {
    width: 20px;
    height: 20px;
    > path {
      fill: ${({ theme, active }) =>
        active ? theme.colors.accentBlue : theme.colors.mainBlue};
    }
  }
`;

export const Properties = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 16px 16px;
  > a {
    color: ${({ theme }) => theme.colors.accentBlue};
  }
`;

export const Property = styled(layout.Row)`
  margin: 4px 0;
  flex-shrink: 0;
  &:last-of-type {
    margin: 4px 0 16px;
  }
  > span {
    color: ${({ theme }) => theme.colors.grey};
    flex-basis: 66%;
    &:first-of-type {
      flex-basis: 33%;
      color: ${({ theme }) => theme.colors.dark};
    }
  }
`;
