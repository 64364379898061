import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import createVanilla from 'zustand/vanilla';
import { Position } from '../../types';
export const STORE_NAME = `@store/creator/select`;

export type ShapeId = string | number;

export type ShapeType = 'template' | 'product' | 'glass' | 'pipe';

export interface SelectItem {
  shapeId: ShapeId;
  type: ShapeType;
  isGlassGlass?: boolean;
}

export interface SelectData extends SelectItem {
  multi?: boolean;
}

export interface ContextMenu {
  position: Position;
  type: ShapeType;
  itemId: number | string;
  isGlassGlass?: boolean;
}

export type State = {
  selected: SelectItem[];
  contextMenu?: ContextMenu;
  select(data: SelectData, cb?: () => void): void;
  deselect(): void;
  openContextMenu(contextMenu: ContextMenu): void;
  closeContextMenu(): void;
};

const store = (set: SetState<State>, get: GetState<State>) => ({
  selected: [],
  select: ({ multi, ...item }: SelectData, cb?: () => void) => {
    const { selected } = get();
    set({
      selected: multi
        ? [
            ...selected.filter(
              (prev) =>
                prev.shapeId !== item.shapeId && prev.type === item.type,
            ),
            item,
          ]
        : [item],
    });
    cb?.();
  },
  deselect: () => {
    set({ selected: [] });
  },
  openContextMenu: (contextMenu: ContextMenu) => set({ contextMenu }),
  closeContextMenu: () => set({ contextMenu: undefined }),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
