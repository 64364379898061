import * as React from 'react';
import { useMemo } from 'react';
import { Container } from './styles';
import { EdgeType } from '../../../../../../types';
import { ProjectDimensions } from '../../../../../../services/api/models/project';
import { mapCoordinatesToEdgesDistances } from '../../../../../../utils/shape';
import RectInputForm from './RectInputForm';
import { EdgeChangeProps } from '../../containers/GlassForm/GlassDimensions';
import { HorizontalPositioning, VerticalPositioning } from './types';
import IndentInputForm from './IndentInputForm';

export interface Props {
  selectedId: number;
  dimensions: ProjectDimensions;
  onChange: (props: EdgeChangeProps) => void;
  getMaxDimensions?: (
    direction: VerticalPositioning | HorizontalPositioning,
  ) => Record<EdgeType, number>;
}

const AreaInputs: React.FC<Props> = React.memo(
  ({ selectedId, dimensions, getMaxDimensions, onChange }) => {
    const edges = useMemo(() => mapCoordinatesToEdgesDistances(dimensions), [
      dimensions,
    ]);

    return (
      <Container>
        {dimensions.indent ? (
          <IndentInputForm
            selectedId={selectedId}
            edges={edges}
            handleChange={onChange}
          />
        ) : (
          <RectInputForm
            selectedId={selectedId}
            getMaxDimensions={getMaxDimensions}
            edges={edges}
            handleChange={onChange}
          />
        )}
      </Container>
    );
  },
);

AreaInputs.displayName = 'AreaInputs';

export default AreaInputs;
