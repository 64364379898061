import * as React from 'react';
import { Container } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { Checkbox } from '../../../../../../components/Checkbox';
import { useTranslation } from 'react-i18next';

export interface Props {
  title: string;
  withAction?: boolean;
  actionTitle?: string;
  adjustManually?: boolean;
  onAction?(): void;
  onToggleAdjustManually?(): void;
}

const PanelHeader: React.FC<Props> = React.memo(
  ({
    title,
    actionTitle,
    adjustManually,
    onAction,
    withAction,
    onToggleAdjustManually,
    ...props
  }) => {
    const { t } = useTranslation('project');

    return (
      <Container {...props}>
        <Typography variant={TypographyVariants.H4_BOLD}>{title}</Typography>
        {withAction && (
          <Typography variant={TypographyVariants.LINK} onClick={onAction}>
            {actionTitle}
          </Typography>
        )}
        {adjustManually !== undefined && (
          <Checkbox
            name="adjust-manually"
            onChange={onToggleAdjustManually}
            value={'true'}
            label={t('propertyPanel.manually')}
            checked={adjustManually}
          />
        )}
      </Container>
    );
  },
);

PanelHeader.displayName = 'PanelHeader';

export default PanelHeader;
