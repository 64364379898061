import styled from 'styled-components';
import { Typography } from '../../../../components/Typography';
import { layout } from '../../../../styles';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 386px;
  height: 377px;
`;

export const Form = styled.form`
  width: 386px;
  min-height: 225px;

  > div:nth-child(3) {
    padding-top: 16px;
  }
`;

export const InfoBox = styled.div`
  background: ${({ theme }) => theme.colors.grey4};
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  box-sizing: border-box;
  border-radius: 4px;
  height: 65px;
  margin-top: 38px;
  margin-bottom: 40px;
  min-height: 129px;
  padding: 16px 22px;

  > span {
    display: block;
    text-align: center;
    width: 342px;
  }
  > span:last-of-type {
    padding-top: 16px;
  }
`;

export const Header = styled(Typography)`
  display: block;
  text-align: center;
  padding-bottom: 40px;
`;

export const ButtonsWrapper = styled(layout.Row)`
  > button:first-child {
    width: 77px;
    height: 40px;
    margin-right: 8px;
  }
`;
