import { Distance, Shape as ShapeType } from '../types';
import { fixValue } from './fix';
import { Glass } from '../../../services/api/models/glass';
import { Shape } from '../../space';
import { scalePosition } from '../../../utils/shape';
import { Vector2d } from 'konva/lib/types';
import { Vector2 } from './vector';
import { findPointDistanceFromVector } from './geometry/vectors';

export const findEdgeDistance = (shape: ShapeType, parent: Glass): Distance => {
  const parentObj = new Shape({ corners: parent.corners }, parent.position);

  const shapePosition = scalePosition(shape.position, true);

  let shapePoints;

  if (shape.corners) {
    const shapeObj = new Shape(
      { corners: shape.corners },
      scalePosition(shape.position, true),
    );

    shapePoints = {
      top: {
        x:
          (shapeObj.topEdge.points.left.x + shapeObj.topEdge.points.right.x) *
          0.5,
        y:
          (shapeObj.topEdge.points.left.y + shapeObj.topEdge.points.right.y) *
          0.5,
      },
      bottom: {
        x:
          (shapeObj.bottomEdge.points.left.x +
            shapeObj.bottomEdge.points.right.x) *
          0.5,
        y:
          (shapeObj.bottomEdge.points.left.y +
            shapeObj.bottomEdge.points.right.y) *
          0.5,
      },
      left: {
        x:
          (shapeObj.leftEdge.points.top.x + shapeObj.leftEdge.points.bottom.x) *
          0.5,
        y:
          (shapeObj.leftEdge.points.top.y + shapeObj.leftEdge.points.bottom.y) *
          0.5,
      },
      right: {
        x:
          (shapeObj.rightEdge.points.top.x +
            shapeObj.rightEdge.points.bottom.x) *
          0.5,
        y:
          (shapeObj.rightEdge.points.top.y +
            shapeObj.rightEdge.points.bottom.y) *
          0.5,
      },
    };
  } else {
    shapePoints = {
      top: { x: shapePosition.x, y: shapePosition.y },
      bottom: {
        x: shapePosition.x + shape.width,
        y: shapePosition.y + shape.height,
      },
      left: { x: shapePosition.x, y: shapePosition.y + shape.height },
      right: { x: shapePosition.x + shape.width, y: shapePosition.y },
    };
  }

  const topDistance = findPointDistanceFromVector(shapePoints.top, [
    parentObj.topEdge.points.left,
    parentObj.topEdge.points.right,
  ]);
  const bottomDistance = findPointDistanceFromVector(shapePoints.bottom, [
    parentObj.bottomEdge.points.left,
    parentObj.bottomEdge.points.right,
  ]);
  const leftDistance = findPointDistanceFromVector(shapePoints.left, [
    parentObj.leftEdge.points.top,
    parentObj.leftEdge.points.bottom,
  ]);
  const rightDistance = findPointDistanceFromVector(shapePoints.right, [
    parentObj.rightEdge.points.top,
    parentObj.rightEdge.points.bottom,
  ]);

  const top =
    new Vector2([topDistance.x, topDistance.y]).length().toNumber() *
    (topDistance.y < 0 ? -1 : 1);
  const bottom =
    new Vector2([bottomDistance.x, bottomDistance.y]).length().toNumber() *
    (bottomDistance.y > 0 ? -1 : 1);
  const left =
    new Vector2([leftDistance.x, leftDistance.y]).length().toNumber() *
    (leftDistance.x < 0 ? -1 : 1);
  const right =
    new Vector2([rightDistance.x, rightDistance.y]).length().toNumber() *
    (rightDistance.x > 0 ? -1 : 1);

  return {
    top: fixValue(top, 1),
    bottom: fixValue(bottom, 1),
    left: fixValue(left, 1),
    right: fixValue(right, 1),
  };
};

export const findAxisDistance = (shape: ShapeType, parent: Glass): Distance => {
  const parentObj = new Shape({ corners: parent.corners }, parent.position);

  let center: Vector2d;

  if (shape.corners) {
    const shapeObj = new Shape(
      { corners: shape.corners },
      scalePosition(shape.position, true),
    );

    center = {
      x: Math.round(shapeObj.polygonCentroid.x),
      y: Math.round(shapeObj.polygonCentroid.y),
    };
  } else {
    center = {
      x: shape.position.x + shape.width / 2,
      y: shape.position.y + shape.height / 2,
    };
  }

  const topDistance = findPointDistanceFromVector(center, [
    parentObj.topEdge.points.left,
    parentObj.topEdge.points.right,
  ]);
  const bottomDistance = findPointDistanceFromVector(center, [
    parentObj.bottomEdge.points.left,
    parentObj.bottomEdge.points.right,
  ]);
  const leftDistance = findPointDistanceFromVector(center, [
    parentObj.leftEdge.points.top,
    parentObj.leftEdge.points.bottom,
  ]);
  const rightDistance = findPointDistanceFromVector(center, [
    parentObj.rightEdge.points.top,
    parentObj.rightEdge.points.bottom,
  ]);

  const top =
    new Vector2([topDistance.x, topDistance.y]).length().toNumber() *
    (topDistance.y < 0 ? -1 : 1);
  const bottom =
    new Vector2([bottomDistance.x, bottomDistance.y]).length().toNumber() *
    (bottomDistance.y > 0 ? -1 : 1);
  const left =
    new Vector2([leftDistance.x, leftDistance.y]).length().toNumber() *
    (leftDistance.x < 0 ? -1 : 1);
  const right =
    new Vector2([rightDistance.x, rightDistance.y]).length().toNumber() *
    (rightDistance.x > 0 ? -1 : 1);

  return {
    top: fixValue(top, 1),
    bottom: fixValue(bottom, 1),
    left: fixValue(left, 1),
    right: fixValue(right, 1),
  };
};

export const scaleDistance = (distance: Distance, ratio: number): Distance => ({
  top: fixValue(distance.top / ratio),
  bottom: fixValue(distance.bottom / ratio),
  left: fixValue(distance.left / ratio),
  right: fixValue(distance.right / ratio),
});
