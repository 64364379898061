import { Shape } from '../Shape';
import Big from 'big.js';
import { Vector2 } from '../../vector';

/**
 * A collection of points in 2D, which together define a **convex** solid
 */
export class Polygon implements Shape {
  /**
   * The vertices in the local coordinate space
   */
  public vertices: Vector2[];

  /**
   * Create a new polygon
   * @param vertices The locations of the vertices in local coordinates
   */
  constructor(vertices: Vector2[]) {
    this.vertices = vertices;
  }

  get extremes(): {
    minX: Big;
    maxX: Big;
    minY: Big;
    maxY: Big;
  } {
    const extremes = {
      minX: this.vertices[0].x,
      maxX: this.vertices[0].x,
      minY: this.vertices[0].y,
      maxY: this.vertices[0].y,
    };

    for (const v of this.vertices) {
      if (v.x.lt(extremes.minX)) {
        extremes.minX = v.x;
      }
      if (v.x.gt(extremes.maxX)) {
        extremes.maxX = v.x;
      }
      if (v.y.lt(extremes.minY)) {
        extremes.minY = v.y;
      }
      if (v.y.gt(extremes.maxY)) {
        extremes.maxY = v.y;
      }
    }

    return extremes;
  }

  /**
   * Check if the polygon is rectangular
   * @returns True if the polygon is rectangular, false otherwise
   */
  get isRectangular(): boolean {
    // Check if all angles are 90 degrees (pi/2 radians)
    const epsilon = 0.000001; // Adjust the epsilon value based on your precision requirements

    for (let i = 0; i < this.vertices.length; i++) {
      const p1 = this.vertices[i];
      const p2 = this.vertices[(i + 1) % this.vertices.length];
      const p3 = this.vertices[(i + 2) % this.vertices.length];

      const edge1 = p2.subtract(p1);
      const edge2 = p3.subtract(p2);

      const dotProduct = Vector2.dot(edge2, edge1);
      const magnitudeProduct = edge1.length().times(edge2.length());

      const angle = Math.acos(dotProduct.div(magnitudeProduct).toNumber());

      if (Math.abs(angle - Math.PI / 2) > epsilon) {
        return false;
      }
    }

    return true;
  }

  public centre(): Vector2 {
    const center = { x: new Big(0), y: new Big(0) };

    for (const point of this.vertices) {
      center.x = center.x.plus(point.x);
      center.y = center.y.plus(point.y);
    }

    center.x = center.x.div(this.vertices.length);
    center.y = center.y.div(this.vertices.length);

    return new Vector2([center.x.toNumber(), center.y.toNumber()]);
  }

  /**
   * Given a direction in global coordinates, return the vertex (in global coordinates)
   * that is the furthest in that direction
   * @param direction the direction to find the support vertex in
   * @return Vector2
   */
  public support(direction: Vector2): Vector2 {
    let furthestDistance: number = Number.NEGATIVE_INFINITY;
    let furthestVertex: Vector2 = new Vector2();

    for (const v of this.vertices) {
      const distance: number = Vector2.dot(v, direction).toNumber();
      if (distance > furthestDistance) {
        furthestDistance = distance;
        furthestVertex = v;
      }
    }

    return furthestVertex;
  }
}
