import styled from 'styled-components';
import { layout } from '../../../../styles';

export const Container = styled(layout.Row)`
  width: inherit;
`;

export const Column = styled(layout.Column)<{ withCancel: boolean }>`
  flex: 1;
  padding-right: ${({ withCancel }) => withCancel && '80px'};
  padding-bottom: ${({ withCancel }) => !withCancel && '5px'};
  > span {
    color: ${({ theme }) => theme.colors.dark};
    margin-top: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  margin-top: -12px;
`;
