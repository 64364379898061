import { PositioningCopy } from '../../components/PositioningCopy';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Glass } from '../../../../../../services/api/models/glass';
import { Position } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { ProductModel } from '../../../../../../services/api/models/product';
import { BoundariesCopy } from '../../../../../../modules/creator/types';
import { Gaps } from '../../../../../../modules/creator/types/Gap';

interface GlassPositioningProps {
  selectedGlass: Glass;
  gaps: Gaps;
  mountedProducts: ProductModel[];
  onUpdatePosition: (position: Partial<Position>) => void;
}

const GlassPositioningCopy = ({
  selectedGlass,
  gaps,
  mountedProducts,
  onUpdatePosition,
}: GlassPositioningProps) => {
  const { t } = useTranslation('project');

  const positioningMaxLimit = useMemo(
    (): Record<keyof BoundariesCopy, [number, number]> => ({
      top: [
        gaps.top.value[0] + gaps.bottom.value[0],
        gaps.top.value[1] + gaps.bottom.value[1],
      ],
      bottom: [
        gaps.bottom.value[0] + gaps.top.value[0],
        gaps.bottom.value[1] + gaps.top.value[1],
      ],
      left: [
        gaps.left.value[0] + gaps.right.value[0],
        gaps.left.value[1] + gaps.right.value[1],
      ],
      right: [
        gaps.right.value[0] + gaps.left.value[0],
        gaps.right.value[1] + gaps.left.value[1],
      ],
    }),
    [gaps.bottom.value, gaps.left.value, gaps.right.value, gaps.top.value],
  );

  const positioningDisabled = useMemo(() => {
    const hasProducts = mountedProducts.length > 0;
    const vertical =
      gaps.top.value[0] === 0 &&
      gaps.bottom.value[0] === 0 &&
      gaps.top.value[1] === 0 &&
      gaps.bottom.value[1] === 0;
    const horizontal =
      gaps.left.value[0] === 0 &&
      gaps.right.value[0] === 0 &&
      gaps.left.value[1] === 0 &&
      gaps.right.value[1] === 0;

    return {
      top: hasProducts || vertical,
      bottom: hasProducts || vertical,
      left: hasProducts || horizontal,
      right: hasProducts || horizontal,
    };
  }, [mountedProducts.length, gaps]);

  const handlePositionChange = useCallback(
    (position: Position) => {
      const newPosition = {
        x: selectedGlass.position.x + position.x,
        y: selectedGlass.position.y + position.y,
      };

      onUpdatePosition(newPosition);
    },
    [selectedGlass, onUpdatePosition],
  );
  return (
    <PositioningCopy
      positions={gaps}
      maxLimit={positioningMaxLimit}
      disabled={positioningDisabled}
      onChange={handlePositionChange}
      title={t('propertyPanel.form.nichePosition')}
      step={0.01}
    />
  );
};

GlassPositioningCopy.displayName = 'GlassPositioningCopy';

export default GlassPositioningCopy;
