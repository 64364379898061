import { AxiosInstance } from 'axios';
import { Endpoints } from '../endpoints';
import {
  FinishForcedResetPasswordCommand,
  FinishResetPasswordCommand,
  InitPasswordResetCommand,
  VerifyEmailResetPasswordCommand,
  UpdatePasswordCommand,
} from '../models/user';
import axiosInstance from '../config';
import { LoginResult } from '../models/auth';

class UserApi {
  constructor(private http: AxiosInstance) {}

  public initPasswordReset = (command: InitPasswordResetCommand) =>
    this.http.post(`${Endpoints.USER}/password/init`, command);

  public verifyPasswordReset = (command: VerifyEmailResetPasswordCommand) =>
    this.http.post(`${Endpoints.USER}/password/verify`, command);

  public finishPasswordReset = (command: FinishResetPasswordCommand) =>
    this.http.post(`${Endpoints.USER}/password/finish`, command);

  public finishForcedPasswordReset = (
    command: FinishForcedResetPasswordCommand,
  ) =>
    this.http.post<Omit<LoginResult, 'shouldResetPassword'>>(
      `${Endpoints.USER}/password/change-password`,
      command,
    );

  public resetPasswordByAdmin = (id: number) =>
    this.http.post(`${Endpoints.USER}/password/reset-password/${id}`);

  public updatePassword = (command: UpdatePasswordCommand) =>
    this.http.put<Omit<LoginResult, 'shouldResetPassword'>>(
      `${Endpoints.USER}/password/update-password`,
      command,
    );
}

const userApi = new UserApi(axiosInstance);
export default userApi;
