import * as React from 'react';

import { Container } from './styles';
import { Typography, TypographyVariants } from '../Typography';

export interface Props {
  text: string;
}

const Label: React.FC<Props> = React.memo(({ text, ...props }) => {
  return (
    <Container {...props}>
      <Typography variant={TypographyVariants.PARAGRAPH}>{text}</Typography>
    </Container>
  );
});

Label.displayName = 'Label';

export default Label;
