import * as React from 'react';

const SvgRu = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
        fill="#F0F0F0"
      />
      <path
        d="M23.254 16.174c.482-1.3.746-2.706.746-4.174 0-1.468-.264-2.874-.746-4.174H.746A11.974 11.974 0 000 12c0 1.468.264 2.874.746 4.174L12 17.217l11.254-1.043z"
        fill="#0052B4"
      />
      <path
        d="M12 24c5.16 0 9.558-3.256 11.253-7.826H.746C2.442 20.744 6.84 24 12 24z"
        fill="#D80027"
      />
    </svg>
  );
};

const MemoSvgRu = React.memo(SvgRu);
export default MemoSvgRu;
