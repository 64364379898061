import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { Variants } from './variants';

const disabled_button = css`
  pointer-events: none;
  opacity: 0.66;
`;

const clickable = css`
  cursor: pointer;
  &:hover {
    opacity: 0.66;
  }
  > a {
    color: ${({ theme }) => theme.colors.mainBlue};
    text-decoration: none;
  }
`;

const bold = css`
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const medium = css`
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

const normal = css`
  font-weight: ${({ theme }) => theme.font.weight.normal};
`;

const h1 = css`
  font-size: ${({ theme }) => theme.font.size.h1};
  line-height: 24px;
  ${medium};
`;

const h2 = css`
  font-size: ${({ theme }) => theme.font.size.h2};
  line-height: 24px;
  ${medium};
`;

const h3 = css`
  font-size: ${({ theme }) => theme.font.size.h3};
  line-height: 18px;
  ${bold};
`;

const h4 = css`
  font-size: ${({ theme }) => theme.font.size.h4};
  line-height: 16px;
  ${medium};
`;

const h4_bold = css`
  ${h4};
  ${bold};
`;

const h5 = css`
  font-size: ${({ theme }) => theme.font.size.h5};
  line-height: 16px;
  ${medium};
`;

const h5_bold = css`
  ${h5};
  ${bold};
`;

const body = css`
  font-size: ${({ theme }) => theme.font.size.body};
  line-height: 24px;
  ${normal};
`;

const paragraph = css`
  font-size: ${({ theme }) => theme.font.size.paragraph};
  line-height: 12px;
  ${medium};
`;

const link = css`
  font-size: ${({ theme }) => theme.font.size.link};
  line-height: 16px;
  text-decoration: underline;
  ${bold};
  ${clickable};
`;

const linkBig = css`
  font-size: ${({ theme }) => theme.font.size.linkBig};
  line-height: 16px;
  text-decoration: underline;
  ${bold};
  ${clickable};
`;

const ellipsisOverflow = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const variants: Record<
  Variants,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  [Variants.BODY]: body,
  [Variants.H1]: h1,
  [Variants.H2]: h2,
  [Variants.H3]: h3,
  [Variants.H4]: h4,
  [Variants.H4_BOLD]: h4_bold,
  [Variants.H5]: h5,
  [Variants.H5_BOLD]: h5_bold,
  [Variants.PARAGRAPH]: paragraph,
  [Variants.LINK]: link,
  [Variants.LINK_BIG]: linkBig,
};

export const Container = styled.span<{
  variant: Variants;
  color?: string;
  hasClick?: boolean;
  disabled?: boolean;
  oneLine?: boolean;
}>`
  font-family: ${({ theme }) => theme.font.family.default};
  font-style: normal;
  color: ${({ theme, color }) => (color ? color : theme.colors.mainBlue)};
  ${({ variant }) => variants[variant]};
  ${({ hasClick }) => hasClick && clickable};
  ${({ disabled }) => disabled && disabled_button};
  ${({ oneLine }) => oneLine && ellipsisOverflow}
`;

export const LinkContainer = styled.a<{ big?: boolean }>`
  font-family: ${({ theme }) => theme.font.family.default};
  font-style: normal;
  color: ${({ theme, color }) => (color ? color : theme.colors.mainBlue)};
  text-decoration: none;
  ${({ big }) => (big ? linkBig : link)}
`;
