import * as React from 'react';

const SvgMove = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M21.71 11.31l-3-3a1.004 1.004 0 10-1.42 1.42L18.58 11H13V5.41l1.29 1.3A1.001 1.001 0 0015 7a1 1 0 00.71-1.71l-3-3a.999.999 0 00-1.41 0l-3 3a1 1 0 101.41 1.42L11 5.42V11H5.41l1.3-1.29a1.004 1.004 0 00-1.42-1.42l-3 3A1.001 1.001 0 002 12a1 1 0 00.29.71l3 3A1 1 0 006 16a.999.999 0 00.71-1.71L5.42 13H11v5.59l-1.29-1.3a1.004 1.004 0 00-1.42 1.42l3 3a1.001 1.001 0 001.41 0l3-3a1.004 1.004 0 10-1.42-1.42L13 18.58V13h5.59l-1.3 1.29a1.002 1.002 0 00.326 1.636c.122.05.252.075.384.074a.998.998 0 00.71-.29l3-3A1 1 0 0022 12a1 1 0 00-.29-.69z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgMove = React.memo(SvgMove);
export default MemoSvgMove;
