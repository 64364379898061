import * as React from 'react';
import { Container } from './styles';
import {
  BaseType,
  ProductModel,
  ProductSearch,
} from '../../../../../../services/api/models/product';
import { Glass } from '../../../../../../services/api/models/glass';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { AttachedGlass } from '../AttachedGlass';
import { AttachedProduct } from '../AttachedProduct';

export type ItemType = 'product' | 'glass';

export interface Props {
  title: string;
  type: ItemType;
  items: Glass[] | ProductSearch[];
  productShapes?: Record<number, ProductModel[]>;
  onItemDelete(itemId: number, type: ItemType): void;
  onItemSelect(itemId: number, type: ItemType): void;
}

const showWidth = (product: ProductSearch) =>
  product.baseType === BaseType.BAR ||
  product.baseType === BaseType.GASKET ||
  product.baseType === BaseType.PIPE;

const AttachedSection: React.FC<Props> = React.memo(
  ({
    title,
    type,
    items,
    onItemDelete,
    productShapes,
    onItemSelect,
    ...props
  }) => {
    return (
      <Container role="list" {...props}>
        <Typography variant={TypographyVariants.H5_BOLD}>{title}</Typography>
        {type === 'glass' &&
          (items as Glass[])
            .sort((a, b) => a.id - b.id)
            .map((glass, index) => (
              <AttachedGlass
                key={glass.id}
                data={glass}
                onDelete={onItemDelete}
                onSelect={onItemSelect}
              />
            ))}
        {type === 'product' &&
          (items as ProductSearch[])
            .sort((a, b) => a.id - b.id)
            .map((item) => (
              <AttachedProduct
                key={item.id}
                data={item}
                onDelete={onItemDelete}
                withWidth={showWidth(item)}
                productShapes={productShapes?.[item.id]}
                onSelect={onItemSelect}
              />
            ))}
      </Container>
    );
  },
);

AttachedSection.displayName = 'AttachedSection';

export default AttachedSection;
