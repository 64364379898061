import * as React from 'react';
import { Container, Option } from './styles';
import { useCallback, useMemo } from 'react';
import { Typography, TypographyVariants } from '../Typography';
import { composeSelected } from './utils';

export type OptionValue = string | number;

export interface SwitchOption {
  title: string;
  value: OptionValue;
}

export interface Props {
  options: SwitchOption[];
  selected: OptionValue[];
  onChange?(value: OptionValue[]): void;
  multi?: boolean;
  name?: string;
  width?: number;
  disabled?: boolean;
}

const Switch: React.FC<Props> = React.memo(
  ({ options, selected, onChange, multi, name, width, disabled }) => {
    const handleChange = useCallback(
      (value: OptionValue) => {
        const newSelected = composeSelected(value, selected, multi);
        if (newSelected !== selected && onChange) {
          onChange(newSelected);
        }
      },
      [multi, onChange, selected],
    );

    const Options = useMemo(
      () =>
        options.map((option, index) => (
          <Option
            key={`${option.value}+${index}`}
            role="option"
            disabled={disabled}
            active={selected.includes(option.value)}
            onClick={() => handleChange(option.value)}
            fixedParentWidth={typeof width !== 'undefined'}>
            <Typography variant={TypographyVariants.H4}>
              {option.title}
            </Typography>
          </Option>
        )),
      [disabled, handleChange, options, selected, width],
    );

    return (
      <Container aria-label={name} role="listbox" width={width}>
        {Options}
      </Container>
    );
  },
);

Switch.displayName = 'Switch';

export default Switch;
