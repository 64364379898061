import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled(layout.Column)`
  width: 163px;
  align-items: center;
  text-align: center;
  > span {
    color: ${({ theme }) => theme.colors.grey};
  }
  > svg {
    width: 24px;
    height: 24px;
    margin-bottom: 18px;
    path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
`;
