import * as React from 'react';
import { selectActiveErrorPage, useNavigationStore } from '../store/navigation';
import { Redirect } from 'react-router-dom';

const ErrorHandler: React.FC = React.memo(() => {
  const activeErrorPage = useNavigationStore(selectActiveErrorPage);

  if (activeErrorPage) {
    return <Redirect to={activeErrorPage} push />;
  }
  return null;
});

ErrorHandler.displayName = 'ErrorHandler';

export default ErrorHandler;
