import Big from 'big.js';
import Konva from 'konva';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, Stage } from 'react-konva';
import { RadioModal } from '../../../../components/RadioModal';
import { SameTypeModal } from '../../components/SameTypeModal';
import {
  useDropValidators,
  useMainLayerDrop,
  usePassiveEvent,
} from '../../hooks';
import { preventDefault } from '../../hooks/useContextMenu';
import {
  DEFAULT_RATIO,
  selectMainLayerPosition,
  selectScale,
  selectSetContainerRect,
  selectSetMainLayerPosition,
  selectSetScale,
  selectSetStageDimensions,
  selectStageHeight,
  selectStageWidth,
  useConfigStore,
} from '../../store/config';
import { selectOnDragOver, useDragStore } from '../../store/drag';
import { selectSetGenerator, useSnapshotStore } from '../../store/snapshot';
import { ClickEvent } from '../../types';
import { fixValue } from '../../utils/fix';
import { generateSnapshot } from '../../utils/snapshots';
import { Container } from './styles';
import {
  selectCollidingGlasses,
  selectData,
  selectDeleteGlass,
  selectProject,
  selectUpdateGlass,
  useProjectStore,
} from '../../store/project';
import { Shape } from '../../../space';
import { ConfirmGlassModal } from '../../components/ConfirmGlassModal';
import find from 'lodash/find';

export const TEMPLATE_LAYER = 'template-layer';

const ZOOM_FACTOR = 0.5;

export interface Props {
  onEmptyClick?(e: ClickEvent): void;

  draggable?: boolean;
}

const MainLayer: React.FC<Props> = ({ onEmptyClick, draggable, children }) => {
  const { t } = useTranslation('project');
  const containerRef = useRef<HTMLDivElement>(null);
  const stageRef = useRef<Konva.Stage>(null);
  const stageWidth = useConfigStore(selectStageWidth);
  const stageHeight = useConfigStore(selectStageHeight);
  const setStageDimensions = useConfigStore(selectSetStageDimensions);
  const setContainerRect = useConfigStore(selectSetContainerRect);
  const setMainLayerPosition = useConfigStore(selectSetMainLayerPosition);
  const mainLayerPosition = useConfigStore(selectMainLayerPosition);
  const onDragOver = useDragStore(selectOnDragOver);
  const selectedScale = useConfigStore(selectScale);
  const setScale = useConfigStore(selectSetScale);
  const setSnapshotGenerator = useSnapshotStore(selectSetGenerator);
  const { dimensions } = useProjectStore(selectData);
  const project = useProjectStore(selectProject);
  const updateGlass = useProjectStore(selectUpdateGlass);
  const deleteGlass = useProjectStore(selectDeleteGlass);
  const collidingGlasses = useProjectStore(selectCollidingGlasses);
  const projectCollidingGlasses = useProjectStore(selectCollidingGlasses);

  const persistedPosition = useRef(mainLayerPosition);

  const [stageInitialized, setStageInitialized] = useState(false);

  const {
    sameHandlesActions,
    showSameHandlesModal,
    liftingHingeDecisions,
    liftingHingeActions,
    showLiftingHingeModal,
    liftingHingeOptions,
    validBaseType,
  } = useDropValidators();
  const { handleDrop } = useMainLayerDrop({ validBaseType });

  const updateLayerPosition = useCallback(() => {
    if (stageRef.current) {
      const position = stageRef.current.getPosition();

      setMainLayerPosition(position);
    }
  }, [setMainLayerPosition]);

  const onStageClick = useCallback(
    (e: ClickEvent) => {
      if (!draggable && e.target === e.target.getStage()) {
        onEmptyClick?.(e);
      }
    },
    [draggable, onEmptyClick],
  );

  const onDragMove = useCallback(
    (e: Konva.KonvaEventObject<DragEvent>) => e.evt.preventDefault(),
    [],
  );

  const onDragEnd = useCallback(() => updateLayerPosition(), [
    updateLayerPosition,
  ]);

  const upScale = useCallback(
    (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      const big = new Big(selectedScale);
      if (big.lt(2)) {
        setScale(fixValue(big.plus(ZOOM_FACTOR)));
      }
    },
    [selectedScale, setScale],
  );

  const scale = useCallback(
    (e: WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!e.ctrlKey) {
        return;
      }

      const stage = stageRef?.current;
      if (!stage) {
        return;
      }

      const pointer = stage.getPointerPosition();
      const oldScale = stage.scaleX();

      if (!pointer) {
        return;
      }

      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      let direction = e.deltaY > 0 ? 1 : -1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.ctrlKey) {
        direction = -direction;
      }

      const newScale = fixValue(
        direction > 0 ? oldScale - ZOOM_FACTOR : oldScale + ZOOM_FACTOR,
      );

      if (newScale < 0.2 || newScale > 2) {
        return;
      }

      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);

      setMainLayerPosition(newPos);
      setScale(newScale);
    },
    [selectedScale, setScale],
  );

  const downScale = useCallback(
    (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      const big = new Big(selectedScale);
      if (big.gt(0.5)) {
        setScale(fixValue(big.minus(ZOOM_FACTOR)));
      }
    },
    [selectedScale, setScale],
  );

  const zoomKeyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey) {
        if (e.code === 'Equal') {
          upScale(e);
        }
        if (e.code === 'Minus') {
          downScale(e);
        }
      }
    },
    [downScale, upScale],
  );

  const showConfirmGlassModal = useMemo(() => {
    if (!project) {
      return false;
    }

    return project.data.glassSheets.some((glass) => glass.blank);
  }, [project.data.glassSheets]);

  const updateBlankGlass = useCallback(() => {
    if (project) {
      const blankGlass = project.data.glassSheets.filter(
        (glass) => glass.blank,
      );

      blankGlass[0].blank = undefined;

      updateGlass(blankGlass[0]);
    }
  }, [project, updateGlass]);

  const removeBlankGlass = useCallback(() => {
    if (project) {
      const blankGlass = project.data.glassSheets.filter(
        (glass) => glass.blank,
      );

      if (blankGlass.length > 0) {
        deleteGlass(blankGlass[0].id);
        projectCollidingGlasses.delete(blankGlass[0].id);
      }
    }
  }, [deleteGlass, project.data.glassSheets]);

  const blankIsIntersecting = useMemo(() => {
    const blankGlass = find(project.data.glassSheets, (glass) => !!glass.blank);

    if (!blankGlass) {
      return false;
    }

    return collidingGlasses.has(blankGlass.id);
  }, [collidingGlasses, project.data.glassSheets]);

  usePassiveEvent('wheel', containerRef.current, scale);
  usePassiveEvent('keydown', window.document, zoomKeyHandler);

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      setStageDimensions(offsetWidth, offsetHeight);
    }
  }, [setStageDimensions]);

  useEffect(() => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      setContainerRect(containerRect);
    }
  }, [setContainerRect]);

  useEffect(() => {
    if (stageRef.current) {
      const snapshotStage = stageRef.current.clone(
        stageRef.current.container(),
      ) as Konva.Stage;

      setSnapshotGenerator(() =>
        generateSnapshot(snapshotStage, selectedScale, mainLayerPosition),
      );
    }
  }, [
    setSnapshotGenerator,
    mainLayerPosition,
    selectedScale,
    stageInitialized,
  ]);

  useEffect(() => {
    if (
      persistedPosition.current.x === 0 ||
      persistedPosition.current.y === 0
    ) {
      const templateObj = new Shape(dimensions);
      const centerPosition = {
        x: window.innerWidth * 0.5 - templateObj.width * 0.5 * DEFAULT_RATIO,
        y:
          window.innerHeight * 0.5 -
          templateObj.height * 0.5 * DEFAULT_RATIO -
          50,
      };

      stageRef.current?.setPosition(centerPosition);
      updateLayerPosition();
    }
  }, [persistedPosition.current, project.id]);

  useEffect(() => {
    if (
      stageRef.current &&
      stageRef.current.getClientRect().width > 0 &&
      !stageInitialized
    ) {
      setStageInitialized(true);
    }
  }, [stageRef.current?.getClientRect().width]);

  useEffect(() => {
    return () => {
      setStageInitialized(false);
      stageRef.current?.destroy();
      stageRef.current?.clearCache();
    };
  }, []);

  return (
    <Container
      ref={containerRef}
      isDragged={draggable}
      onDrop={handleDrop}
      onDragOver={onDragOver}>
      <Stage
        x={mainLayerPosition.x}
        y={mainLayerPosition.y}
        ref={stageRef}
        draggable={draggable}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        width={stageWidth}
        height={stageHeight}
        onContextMenu={preventDefault}
        onMouseDown={onStageClick}
        onTouchStart={onStageClick}
        scaleX={selectedScale}
        scaleY={selectedScale}>
        <Layer name={TEMPLATE_LAYER} id={TEMPLATE_LAYER}>
          {children}
        </Layer>
      </Stage>
      <SameTypeModal
        onConfirm={sameHandlesActions.confirm}
        onReject={sameHandlesActions.reject}
        show={showSameHandlesModal}
      />
      <ConfirmGlassModal
        onConfirm={updateBlankGlass}
        onReject={removeBlankGlass}
        show={showConfirmGlassModal}
        disableConfirm={blankIsIntersecting}
      />
      {showLiftingHingeModal && (
        <RadioModal
          decisions={liftingHingeDecisions}
          decisionLabels={liftingHingeOptions}
          title={t('modals.liftingHinge.title')}
          description={t('modals.liftingHinge.description')}
          confirmLabel={t('modals.liftingHinge.confirm')}
          onClose={liftingHingeActions.cancel}
          onResolve={liftingHingeActions.select}
        />
      )}
    </Container>
  );
};

export default MainLayer;
