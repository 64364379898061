import * as React from 'react';
import { Container, ArrowWrapper, ListWrapper, ItemWrapper } from './styles';
import Item from './Item';
import { DownSm } from '../icons';
import { useCallback, useMemo, useRef, useState } from 'react';
import List from './List';
import { useClickOutside, useCurrentLang } from '../../hooks';
import { Lang } from './types';
import { composeLangItem } from './utils';
import { useTranslation } from 'react-i18next';

export interface Props {}

const Language: React.FC<Props> = React.memo(() => {
  const { i18n } = useTranslation();
  const elementRef = useRef<HTMLDivElement>(null);

  const [unfolded, setUnfolded] = useState(false);

  const { currentLang, availableLang, setCurrentLang } = useCurrentLang();

  const toggleList = useCallback(() => setUnfolded(!unfolded), [unfolded]);

  const closeList = useCallback(() => {
    setUnfolded(false);
  }, []);

  useClickOutside({ elementRef, onClickOutside: closeList });

  const selectedLang = useMemo<Lang>(() => composeLangItem(currentLang), [
    currentLang,
  ]);

  const languages = useMemo(
    () => availableLang.map((lang) => composeLangItem(lang)),
    [availableLang],
  );

  const onChange = useCallback(
    async (lang: Lang) => {
      await i18n.changeLanguage(lang.code);
      closeList();
      setCurrentLang(lang.code);
    },
    [closeList, i18n, setCurrentLang],
  );

  return (
    <Container ref={elementRef}>
      <ItemWrapper role="button" onClick={toggleList}>
        <Item lang={selectedLang} active />
        <ArrowWrapper>
          <DownSm />
        </ArrowWrapper>
      </ItemWrapper>
      {unfolded && (
        <ListWrapper>
          <List
            currentItem={selectedLang}
            items={languages}
            onDismiss={closeList}
            onChange={onChange}
          />
        </ListWrapper>
      )}
    </Container>
  );
});

Language.displayName = 'Language';

export default Language;
