import * as React from 'react';
import { useCallback } from 'react';
import { Container, InputRow, InputWrapper } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { Input } from '../../../../../../components/Input';
import { HorizontalPositioning, VerticalPositioning } from './types';
import { useTranslation } from 'react-i18next';
import { DirectionSwitch } from '../../../../../../components/DirectionSwitch';

export interface Props {
  onWidthChange(value: string, name: string): void;
  onHPositioningChange(positioning: HorizontalPositioning): void;
  onHeightChange(value: string, name: string): void;
  onVPositioningChange(positioning: VerticalPositioning): void;
  hPositioning: HorizontalPositioning;
  vPositioning: VerticalPositioning;
  width: number;
  height: number;
  maxWidth?: number;
  maxHeight?: number;
  minWidth?: number;
  minHeight?: number;
  step?: number;
  disabledOptions?: (VerticalPositioning | HorizontalPositioning)[];
  disableVChange?: boolean;
  disableHChange?: boolean;
}

const DimensionsInputs: React.FC<Props> = React.memo(
  ({
    onWidthChange,
    onHeightChange,
    onHPositioningChange,
    onVPositioningChange,
    hPositioning,
    vPositioning,
    width,
    height,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    disabledOptions,
    disableVChange,
    disableHChange,
    step = 0.01,
    ...props
  }) => {
    const { t } = useTranslation('project');

    const handlePositioningChange = useCallback(
      (type: 'horizontal' | 'vertical') => (
        value: (HorizontalPositioning | VerticalPositioning)[],
      ) => {
        switch (type) {
          case 'horizontal': {
            return onHPositioningChange(value[0] as HorizontalPositioning);
          }
          case 'vertical': {
            return onVPositioningChange(value[0] as VerticalPositioning);
          }
        }
      },
      [onHPositioningChange, onVPositioningChange],
    );

    return (
      <Container {...props}>
        <InputRow>
          <Typography variant={TypographyVariants.H5}>
            {t('propertyPanel.form.width')}:
          </Typography>
          <InputWrapper>
            <Input
              name="width"
              type="number"
              unit="mm"
              min={minWidth ?? 100}
              max={maxWidth}
              width={108}
              onBlur={onWidthChange}
              value={String(width)}
              step={step}
              disabled={disableHChange}
              errorFeedback
              withoutDebounce
            />
            <DirectionSwitch
              directionSelected={hPositioning}
              handleDirectionChange={handlePositioningChange('horizontal')}
              disabledOptions={disabledOptions}
              isVertical
            />
          </InputWrapper>
        </InputRow>
        <InputRow>
          <Typography variant={TypographyVariants.H5}>
            {t('propertyPanel.form.height')}:
          </Typography>
          <InputWrapper>
            <Input
              name="height"
              type="number"
              unit="mm"
              min={minHeight ?? 100}
              max={maxHeight}
              width={108}
              onBlur={onHeightChange}
              value={String(height)}
              step={step}
              disabled={disableVChange}
              errorFeedback
              withoutDebounce
            />
            <DirectionSwitch
              directionSelected={vPositioning}
              handleDirectionChange={handlePositioningChange('vertical')}
              disabledOptions={disabledOptions}
            />
          </InputWrapper>
        </InputRow>
      </Container>
    );
  },
);

DimensionsInputs.displayName = 'DimensionsInputs';

export default DimensionsInputs;
