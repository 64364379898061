export enum Action {
  READ = 'READ',
  EDIT = 'EDIT',
}

export enum Subjects {
  RESET_PASSWORD = 'RESET_PASSWORD',
  PASSWORD = 'PASSWORD',
  USER_READ = 'USER_READ',
  USER_EDIT = 'USER_EDIT',
  ADMIN_USER_EDIT = 'ADMIN_USER_EDIT',
  COMPANY_SUBSCRIPTION_READ = 'COMPANY_SUBSCRIPTION_READ',
  COMPANY_SUBSCRIPTION_EDIT = 'COMPANY_SUBSCRIPTION_EDIT',
  PROJECT_READ = 'PROJECT_READ',
  PROJECT_EDIT = 'PROJECT_EDIT',
  PROJECT_TEMPLATE_READ = 'PROJECT_TEMPLATE_READ',
  PROJECT_TEMPLATE_EDIT = 'PROJECT_TEMPLATE_EDIT',
  PRODUCT_READ = 'PRODUCT_READ',
  PRODUCT_EDIT = 'PRODUCT_EDIT',
  PRODUCT_ADMIN_READ = 'PRODUCT_ADMIN_READ',
  PRODUCT_ADMIN_EDIT = 'PRODUCT_ADMIN_EDIT',
  COMPANY_READ = 'COMPANY_READ',
  COMPANY_EDIT = 'COMPANY_EDIT',
  CLIENT_READ = 'CLIENT_READ',
  CLIENT_EDIT = 'CLIENT_EDIT',
  COMPANY_ADMIN_READ = 'COMPANY_ADMIN_READ',
  COMPANY_ADMIN_EDIT = 'COMPANY_ADMIN_EDIT',
  ORDER_READ = 'ORDER_READ',
  ORDER_EDIT = 'ORDER_EDIT',
  ORDER_ADMIN_READ = 'ORDER_ADMIN_READ',
  ORDER_ADMIN_EDIT = 'ORDER_ADMIN_EDIT',
  OFFER_READ = 'OFFER_READ',
  OFFER_EDIT = 'OFFER_EDIT',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PASSWORD_RESET_EDIT = 'PASSWORD_RESET_EDIT',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  EMAIL_EDIT = 'EMAIL_EDIT',
  FILE_READ = 'FILE_READ',
  FILE_EDIT = 'FILE_EDIT',
  PARAMETERS_READ = 'PARAMETERS_READ',
  PARAMETERS_EDIT = 'PARAMETERS_EDIT',
}

export type ActionTypes = keyof typeof Action;
export type SubjectsTypes = keyof typeof Subjects;

export type CaslResponse = {
  actions: ActionTypes[];
  subject: SubjectsTypes[];
}[];
