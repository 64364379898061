import { State } from './config';

export const selectStageWidth = (state: State) => state.stageWidth;

export const selectStageHeight = (state: State) => state.stageHeight;

export const selectSetStageDimensions = (state: State) =>
  state.setStageDimensions;

export const selectTheme = (state: State) => state.theme;

export const selectScale = (state: State) => state.scale;

export const selectSetScale = (state: State) => state.setScale;

export const selectMainLayerPosition = (state: State) =>
  state.mainLayerPosition;

export const selectSetMainLayerPosition = (state: State) =>
  state.setMainLayerPosition;

export const selectPointer = (state: State) => state.pointer;

export const selectSetPointer = (state: State) => state.setPointer;

export const selectReset = (state: State) => state.reset;

export const selectContainerRect = (state: State) => state.containerRect;

export const selectSetContainerRect = (state: State) => state.setContainerRect;

export const selectProjection = (state: State) => state.projection;

export const selectSetProjection = (state: State) => state.setProjection;
