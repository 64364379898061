import * as React from 'react';

const SvgMenu = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M20.05 11H3.95a.95.95 0 00-.95.95v.1c0 .525.425.95.95.95h16.1a.95.95 0 00.95-.95v-.1a.95.95 0 00-.95-.95zM20.05 16H3.95a.95.95 0 00-.95.95v.1c0 .525.425.95.95.95h16.1a.95.95 0 00.95-.95v-.1a.95.95 0 00-.95-.95zM20.05 6H3.95a.95.95 0 00-.95.95v.1c0 .525.425.95.95.95h16.1a.95.95 0 00.95-.95v-.1a.95.95 0 00-.95-.95z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgMenu = React.memo(SvgMenu);
export default MemoSvgMenu;
