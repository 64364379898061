import * as React from 'react';
import { useCallback, useRef } from 'react';
import { useClickOutside } from '../../../../hooks';
import { Container } from './styles';
import ContextMenuItem, {
  Props as ContextMenuItemProps,
} from './ContextMenuItem';

export type MenuItem = ContextMenuItemProps;

export interface Props {
  show?: boolean;
  top: number;
  left: number;
  onDismiss(): void;
  items: MenuItem[];
}

const ContextMenu: React.FC<Props> = React.memo(
  ({ show, onDismiss, items, ...props }) => {
    const elementRef = useRef<HTMLDivElement>(null);

    useClickOutside({ elementRef, onClickOutside: onDismiss });

    const handleItemClick = useCallback(
      (onClick?: () => void) => {
        onClick?.();
        onDismiss();
      },
      [onDismiss],
    );

    if (show) {
      return (
        <Container {...props} role="menu" ref={elementRef}>
          {items.map((item) => (
            <ContextMenuItem
              {...item}
              onClick={() => handleItemClick(item.onClick)}
              key={item.title}
            />
          ))}
        </Container>
      );
    }
    return null;
  },
);

ContextMenu.displayName = 'ContextMenu';

export default ContextMenu;
