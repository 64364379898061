import * as React from 'react';
import {
  MainList as Container,
  CloseButton,
  MainItemsWrapper,
  MinorItemsWrapper,
} from './styles';
import { Close } from '../../../../components/icons';
import { useCallback, useMemo, useReducer, useRef } from 'react';
import MainItem, { MainItemType } from './MainItem';
import MinorItem, { MinorItemType } from './MinorItem';
import { useClickOutside } from '../../../../hooks';
import { RootRoutes } from '../../../../router/routes';

type ShowDescendantsState = Record<number, string>;
interface ShowDescendantsAction {
  type: 'add' | 'clear';
  level?: number;
  itemId?: string;
  disabled?: boolean;
  noDescendants?: boolean;
}

const initShowDescendants = {};

const showDescendantsReducer = (
  state: ShowDescendantsState,
  { level, itemId, disabled, noDescendants, type }: ShowDescendantsAction,
) => {
  switch (type) {
    case 'add': {
      if (typeof level !== 'undefined' && typeof itemId !== 'undefined') {
        const newState = { ...state };
        Object.keys(newState).forEach((key) => {
          const savedLevel = Number(key);
          savedLevel > level && delete newState[savedLevel];
        });
        if (disabled || noDescendants) {
          delete newState[level];
          return newState;
        }
        return { ...newState, [level]: itemId };
      }
      return state;
    }
    case 'clear': {
      return {};
    }
  }
};

export interface Props {
  mainItems: MainItemType[];
  minorItems: MinorItemType[];
  activeMinor: RootRoutes;
  onClose(): void;
}

const MainList: React.FC<Props> = React.memo(
  ({ mainItems, minorItems, activeMinor, onClose }) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const [showDescendants, dispatch] = useReducer(
      showDescendantsReducer,
      initShowDescendants,
    );

    const closeSubMenus = useCallback(() => {
      dispatch({ type: 'clear' });
    }, []);

    const onMouseEnter = useCallback(
      (
        level: number,
        itemId: string,
        disabled?: boolean,
        noDescendants?: boolean,
      ) => {
        dispatch({ level, itemId, disabled, noDescendants, type: 'add' });
      },
      [],
    );

    const closeMenu = useCallback(() => {
      closeSubMenus();
      onClose();
    }, [closeSubMenus, onClose]);

    useClickOutside({ elementRef, onClickOutside: closeMenu });

    const MainItems = useMemo(
      () =>
        mainItems.map((item, index) => {
          const key = `${item.title}+${index}+${item.level}`;
          return (
            <MainItem
              {...item}
              id={key}
              key={key}
              onMouseEnter={onMouseEnter}
              showDescendants={showDescendants}
            />
          );
        }),
      [mainItems, onMouseEnter, showDescendants],
    );

    const MinorItems = useMemo(
      () =>
        minorItems.map((item, index) => (
          <MinorItem
            {...item}
            key={`${item.title}+${index}`}
            active={item.route === activeMinor}
            onMouseEnter={closeSubMenus}
          />
        )),
      [activeMinor, minorItems, closeSubMenus],
    );

    return (
      <Container ref={elementRef} role="menu">
        <CloseButton role="button" onClick={closeMenu}>
          <Close />
        </CloseButton>
        <MainItemsWrapper>{MainItems}</MainItemsWrapper>
        <MinorItemsWrapper>{MinorItems}</MinorItemsWrapper>
      </Container>
    );
  },
);

MainList.displayName = 'MainList';

export default MainList;
