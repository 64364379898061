import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.66;
  }
  > svg {
    height: 17px;
    width: 80px;
  }
`;
