import * as React from 'react';

const SvgLow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <rect
        width={2}
        height={14}
        rx={1}
        transform="matrix(0 -1 -1 0 19 20)"
        fill="#828A98"
      />
      <rect
        width={2}
        height={10}
        rx={1}
        transform="matrix(-1 0 0 1 9 6)"
        fill={props.fill ?? '#272B5F'}
      />
      <rect
        width={2}
        height={10}
        rx={1}
        transform="matrix(-1 0 0 1 17 6)"
        fill={props.fill ?? '#272B5F'}
      />
      <rect
        width={2}
        height={12}
        rx={1}
        transform="matrix(-1 0 0 1 13 4)"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgLow = React.memo(SvgLow);
export default MemoSvgLow;
