import { useReducer } from 'react';

export enum Actions {
  START,
  FINISH,
  SET_PROGRESS,
}

export interface State {
  progress: number;
  pending: boolean;
}

export interface Action {
  type: Actions;
  progress?: number;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.SET_PROGRESS: {
      return { ...state, progress: action?.progress ?? 0 };
    }
    case Actions.START: {
      return { pending: true, progress: 10 };
    }
    case Actions.FINISH: {
      return { pending: false, progress: 100 };
    }
    default: {
      return state;
    }
  }
};

const initState: State = {
  progress: 0,
  pending: false,
};

const useCreateReducer = () => {
  return useReducer(reducer, initState);
};

export default useCreateReducer;
