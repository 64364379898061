import * as React from 'react';
import { SubList as Container } from './styles';
import MainItem, { MainItemType } from './MainItem';
import { useMemo } from 'react';

export interface Props {
  items: MainItemType[];
  showDescendants: Record<number, string>;
  onMouseEnter?(level: number, id: string): void;
}

const SubList: React.FC<Props> = React.memo(
  ({ items, onMouseEnter, showDescendants }) => {
    const Items = useMemo(
      () =>
        items.map((item, index) => {
          const key = `${item.title}+${index}+${item.level}`;
          return (
            <MainItem
              {...item}
              id={key}
              key={key}
              showDescendants={showDescendants}
              onMouseEnter={onMouseEnter}
            />
          );
        }),
      [items, onMouseEnter, showDescendants],
    );

    return (
      <Container data-testid="sub-menu" role="menu">
        {Items}
      </Container>
    );
  },
);

SubList.displayName = 'SubList';

export default SubList;
