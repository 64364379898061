import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AxiosResponse } from 'axios';

import { CompanyMember } from '../../services/api/models/companyMember';
import {
  AddMemberToCompanyCommand,
  EditCompanyMemberCommand,
  CompanyMemberErrorResponse,
} from '../../services/api/models/companyMember';
import { companyApi, companyAdminApi } from '../../services/api/services';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/companyMembers/companyMember`;

export type State = {
  loading: boolean;
  create(
    command: AddMemberToCompanyCommand,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  update(
    id: number,
    command: EditCompanyMemberCommand,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  updateByAdmin(
    companyId: number,
    companyMemberId: number,
    command: EditCompanyMemberCommand,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  resetError(): void;
  fetch(onError?: () => void): Promise<void>;
  data?: CompanyMember;
  error?: AxiosResponse<CompanyMemberErrorResponse>;
};

const createAction = (set: SetState<State>) => async (
  command: AddMemberToCompanyCommand,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  try {
    set({ loading: true });
    await companyApi.createCompanyMember(command);
    onSuccess?.();
  } catch (e) {
    set({ error: e.response });
    onError?.();
  } finally {
    set({ loading: false });
  }
};

const updateAction = (set: SetState<State>) => async (
  id: number,
  command: EditCompanyMemberCommand,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  try {
    set({ loading: true });
    await companyApi.updateCompanyMember(id, command);
    onSuccess?.();
  } catch (e) {
    set({ error: e.response });
    onError?.();
  } finally {
    set({ loading: false });
  }
};

const updateByAdminAction = (set: SetState<State>) => async (
  companyId: number,
  companyMemberId: number,
  command: EditCompanyMemberCommand,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  try {
    set({ loading: true });
    await companyAdminApi.updateCompanyMember(
      companyId,
      companyMemberId,
      command,
    );
    onSuccess?.();
  } catch (e) {
    set({ error: e.response });
    onError?.();
  } finally {
    set({ loading: false });
  }
};

const resetErrorAction = (set: SetState<State>) => () =>
  set({ error: undefined });

const fetchAction = (set: SetState<State>) => async (onError?: () => void) =>
  createAsyncAction(set)(async () => {
    const { data } = await companyApi.getMyProfile();
    set({ data });
  }, onError);

const store = (set: SetState<State>) => ({
  loading: false,
  create: createAction(set),
  update: updateAction(set),
  updateByAdmin: updateByAdminAction(set),
  resetError: resetErrorAction(set),
  fetch: fetchAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
