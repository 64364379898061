import * as React from 'react';
import { useMemo, useRef } from 'react';
import Konva from 'konva';
import { Group } from 'react-konva';
import { DistanceLine } from '../DistanceLine';
import { DistanceArrow } from '../DistanceArrow';
import { SizeLabel } from '../SizeLabel';
import { DEFAULT_RATIO } from '../../store/config';
import {
  ARROW_PADDING,
  LABEL_HEIGHT,
  LABEL_WIDTH,
  OVERFLOW,
} from './AxisDistance';
import { Boundaries } from '../../types';
import { ProductModel } from '../../../../services/api/models/product';
import map from 'lodash/map';
import { Vector2d } from 'konva/lib/types';
import { Vector2 } from '../../utils/vector';
import { scaleDistance } from '../../utils/distance';

interface YAxisDistanceParams {
  leftPosition: boolean;
  topPosition: boolean;
  distance: Boundaries;
  mountedEdgePoints: { top: Vector2d; bottom: Vector2d };
  scaledProduct: ProductModel;
  productPosition: Vector2d;
  verticalEdgeDifferencePoint: number;
}

function YAxisDistance({
  leftPosition,
  topPosition,
  distance,
  scaledProduct,
  productPosition,
  mountedEdgePoints,
  verticalEdgeDifferencePoint,
}: YAxisDistanceParams) {
  const topLabel = useRef<Konva.Label>(null);
  const bottomLabel = useRef<Konva.Label>(null);
  const gapLabel = useRef<Konva.Label>(null);

  const overflowH = leftPosition ? OVERFLOW : -OVERFLOW;
  const overflowV = topPosition ? OVERFLOW : -OVERFLOW;

  const arrowPaddingV = leftPosition ? ARROW_PADDING : -ARROW_PADDING;

  const gapEdgePoints = topPosition
    ? mountedEdgePoints.top
    : mountedEdgePoints.bottom;

  const scaledDistance = scaleDistance(distance, 1 / DEFAULT_RATIO);

  const verticalDistance = useMemo(
    () => (leftPosition ? scaledDistance.left : -scaledDistance.right),
    [scaledDistance.left, scaledDistance.right, leftPosition],
  );

  const productCenter = useMemo(
    () => ({
      x: scaledProduct.width / 2,
      y: scaledProduct.height / 2,
    }),
    [scaledProduct.width, scaledProduct.height],
  );

  const overflowVector = useMemo(() => {
    const top = new Vector2([mountedEdgePoints.top.x, mountedEdgePoints.top.y]);
    const bottom = new Vector2([
      mountedEdgePoints.bottom.x,
      mountedEdgePoints.bottom.y,
    ]);

    const directionVector = bottom.subtract(top);

    // Normalize the direction vector
    const normalizedDirection = directionVector.normalize().copy();

    // Calculate the perpendicular vector to the line (for width and height)
    const perpendicularVector = new Vector2([
      normalizedDirection.y,
      -normalizedDirection.x,
    ]).copy();

    return {
      x: -overflowH * perpendicularVector.x.toNumber(),
      y: -overflowH * perpendicularVector.y.toNumber(),
    };
  }, [
    mountedEdgePoints.bottom.x,
    mountedEdgePoints.bottom.y,
    mountedEdgePoints.top.x,
    mountedEdgePoints.top.y,
    overflowH,
  ]);

  const inlineOverflow = useMemo(() => {
    const top = new Vector2([mountedEdgePoints.top.x, mountedEdgePoints.top.y]);
    const bottom = new Vector2([
      mountedEdgePoints.bottom.x,
      mountedEdgePoints.bottom.y,
    ]);

    const directionVector = bottom.subtract(top);

    // Normalize the direction vector
    const normalizedDirection = directionVector.normalize().copy();

    return {
      x: -overflowV * normalizedDirection.x.toNumber(),
      y: -overflowV * normalizedDirection.y.toNumber(),
    };
  }, [
    mountedEdgePoints.bottom.x,
    mountedEdgePoints.bottom.y,
    mountedEdgePoints.top.x,
    mountedEdgePoints.top.y,
    overflowV,
  ]);

  const productCenterOverflowed = useMemo(
    () => verticalEdgeDifferencePoint + mountedEdgePoints.bottom.x,
    [mountedEdgePoints.bottom.x, verticalEdgeDifferencePoint],
  );

  const topDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: mountedEdgePoints.top.x,
      startY: mountedEdgePoints.top.y,
      endX: mountedEdgePoints.top.x + overflowVector.x,
      endY: mountedEdgePoints.top.y + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [mountedEdgePoints.top.x, mountedEdgePoints.top.y, overflowVector]);

  const topDistanceArrowPoints = useMemo(() => {
    const positioning = {
      startX: mountedEdgePoints.top.x + overflowVector.x,
      startY: mountedEdgePoints.top.y + ARROW_PADDING + overflowVector.y,
      endX: productCenterOverflowed + overflowVector.x,
      endY:
        productPosition.y + productCenter.y - ARROW_PADDING + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    mountedEdgePoints.top.x,
    mountedEdgePoints.top.y,
    overflowVector.x,
    overflowVector.y,
    productCenter.y,
    productCenterOverflowed,
    productPosition.y,
  ]);

  const centerDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: productCenterOverflowed,
      startY: productPosition.y + productCenter.y,
      endX: productCenterOverflowed + overflowVector.x,
      endY: productPosition.y + productCenter.y + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    productCenterOverflowed,
    productPosition.y,
    productCenter.y,
    overflowVector.x,
    overflowVector.y,
  ]);

  const bottomDistanceArrowPoints = useMemo(() => {
    const positioning = {
      startX: mountedEdgePoints.bottom.x + overflowVector.x,
      startY: mountedEdgePoints.bottom.y - ARROW_PADDING + overflowVector.y,
      endX: productCenterOverflowed + overflowVector.x,
      endY:
        productPosition.y + productCenter.y + ARROW_PADDING + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    mountedEdgePoints.bottom.x,
    mountedEdgePoints.bottom.y,
    overflowVector.x,
    overflowVector.y,
    productCenter.y,
    productCenterOverflowed,
    productPosition.y,
  ]);

  const bottomDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: mountedEdgePoints.bottom.x,
      startY: mountedEdgePoints.bottom.y,
      endX: mountedEdgePoints.bottom.x + overflowVector.x,
      endY: mountedEdgePoints.bottom.y + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    mountedEdgePoints.bottom.x,
    mountedEdgePoints.bottom.y,
    overflowVector.x,
    overflowVector.y,
  ]);

  const topLabelPosition = useMemo(() => {
    const padding = leftPosition ? -5 : 5;
    const label1Width = leftPosition
      ? topLabel.current?.width() ?? LABEL_WIDTH
      : 0;
    const label1Height = topLabel.current?.height() ?? LABEL_HEIGHT;
    const middleTopOverflow =
      (mountedEdgePoints.top.x + productCenterOverflowed) * 0.5 +
      overflowVector.x;

    return {
      x: middleTopOverflow - label1Width + padding,
      y: mountedEdgePoints.top.y + scaledDistance.top / 2 - label1Height / 2,
    };
  }, [
    topLabel.current,
    leftPosition,
    mountedEdgePoints.top.x,
    mountedEdgePoints.top.y,
    productCenterOverflowed,
    overflowVector.x,
    scaledDistance.top,
  ]);

  const bottomLabelPosition = useMemo(() => {
    const padding = leftPosition ? -5 : 5;
    const label2Width = leftPosition
      ? bottomLabel.current?.width() ?? LABEL_WIDTH
      : 0;
    const label2Height = bottomLabel.current?.height() ?? LABEL_HEIGHT;
    const middleBottomOverflow =
      (mountedEdgePoints.bottom.x + productCenterOverflowed) * 0.5 +
      overflowVector.x;

    return {
      x: middleBottomOverflow - label2Width + padding,
      y:
        productPosition.y +
        productCenter.y +
        scaledDistance.bottom / 2 -
        label2Height / 2,
    };
  }, [
    bottomLabel.current,
    leftPosition,
    mountedEdgePoints.bottom.x,
    productCenterOverflowed,
    overflowVector.x,
    productPosition.y,
    productCenter.y,
    scaledDistance.bottom,
  ]);

  const gapLabelPosition = useMemo(() => {
    const label2Width = gapLabel.current?.width() ?? LABEL_WIDTH;
    const label2Height =
      (gapLabel.current?.height() ?? LABEL_HEIGHT) * (topPosition ? -1 : 1);

    return {
      x: gapEdgePoints.x + inlineOverflow.x - label2Width / 2,
      y: gapEdgePoints.y + inlineOverflow.y + label2Height / 2,
    };
  }, [
    gapLabel.current,
    topPosition,
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
  ]);

  const gapLeftDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: gapEdgePoints.x,
      startY: gapEdgePoints.y,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y,
    };

    return map(positioning, (value) => value);
  }, [gapEdgePoints.x, gapEdgePoints.y, inlineOverflow.x, inlineOverflow.y]);

  const gapArrowDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: gapEdgePoints.x + inlineOverflow.x + arrowPaddingV,
      startY: gapEdgePoints.y + inlineOverflow.y,
      endX:
        gapEdgePoints.x + verticalDistance + inlineOverflow.x - arrowPaddingV,
      endY: gapEdgePoints.y + inlineOverflow.y,
    };

    return map(positioning, (value) => value);
  }, [
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
    arrowPaddingV,
    verticalDistance,
  ]);

  const gapRightDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: gapEdgePoints.x + verticalDistance,
      startY: gapEdgePoints.y,
      endX: gapEdgePoints.x + verticalDistance + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y,
    };

    return map(positioning, (value) => value);
  }, [
    gapEdgePoints.x,
    gapEdgePoints.y,
    verticalDistance,
    inlineOverflow.x,
    inlineOverflow.y,
  ]);

  const topDistanceValue = useMemo(() => Math.abs(distance.top).toFixed(2), [
    distance.top,
  ]);

  const bottomDistanceValue = useMemo(
    () => Math.abs(distance.bottom).toFixed(2),
    [distance.bottom],
  );

  const gapDistanceValue = useMemo(
    () => Math.abs(leftPosition ? distance.left : distance.right).toFixed(2),
    [distance.left, distance.right, leftPosition],
  );

  return (
    <Group data-testid="y-axis">
      <DistanceLine points={topDistanceLinePoints} />
      <DistanceArrow points={topDistanceArrowPoints} />
      <SizeLabel
        ref={topLabel}
        y={topLabelPosition.y}
        x={topLabelPosition.x}
        value={topDistanceValue}
      />
      <DistanceLine points={centerDistanceLinePoints} />
      <DistanceArrow points={bottomDistanceArrowPoints} />
      <SizeLabel
        ref={bottomLabel}
        y={bottomLabelPosition.y}
        x={bottomLabelPosition.x}
        value={bottomDistanceValue}
      />
      <DistanceLine points={bottomDistanceLinePoints} />
      <DistanceLine points={gapLeftDistanceLinePoints} />
      <DistanceArrow points={gapArrowDistanceLinePoints} />
      <SizeLabel
        ref={gapLabel}
        y={gapLabelPosition.y}
        x={gapLabelPosition.x}
        value={gapDistanceValue}
      />
      <DistanceLine points={gapRightDistanceLinePoints} />
    </Group>
  );
}

YAxisDistance.displayName = 'YAxisDistance';

export default YAxisDistance;
