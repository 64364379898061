import { instance_v2 } from '../config';
import { AxiosInstance } from 'axios';
import {
  ProjectModules,
  ProjectTemplate,
  ProjectTemplateSearch,
} from '../models/project';
import { Endpoints } from '../endpoints';
import { Page, Pageable } from '../models/requests';
import { stringify } from 'qs';
import { ProjectDataCommand } from './project';

export interface CreateProjectTemplateCommand
  extends Pick<ProjectTemplate, 'name'> {
  data: ProjectDataCommand;
  module: ProjectModules;
  description?: string;
  version?: string;
  snapshotUrl?: string;
}

type ProjectTemplateResponse = Omit<ProjectTemplate, 'data'> & {
  data: ProjectDataCommand;
};

export interface UpdateProjectTemplateCommand
  extends CreateProjectTemplateCommand {
  unsafe?: boolean;
}

export interface FilterCommand {
  name: string;
  module: ProjectModules;
}

export interface FilterRequest {
  command?: Partial<FilterCommand>;
  pageable?: Partial<Pageable>;
}

class TemplatesApi {
  public constructor(private http: AxiosInstance) {}

  public getProjectTemplate = (templateId: number) =>
    this.http.get<ProjectTemplateResponse>(
      `${Endpoints.PROJECT}/template/${templateId}`,
    );

  public filterProjectTemplates = ({ command, pageable }: FilterRequest) =>
    this.http.post<Page<ProjectTemplateSearch>>(
      `${Endpoints.PROJECT}/template/filter?${stringify(pageable)}`,
      command ?? {},
    );

  public createProjectTemplate = (command: CreateProjectTemplateCommand) =>
    this.http.post<ProjectTemplateResponse>(
      `${Endpoints.PROJECT}/template`,
      command,
    );

  public updateProjectTemplate = (
    id: number,
    command: UpdateProjectTemplateCommand,
  ) =>
    this.http.put<ProjectTemplateResponse>(
      `${Endpoints.PROJECT}/template/${id}`,
      command,
    );

  public deleteProjectTemplate = (id: number) =>
    this.http.delete<ProjectTemplate>(`${Endpoints.PROJECT}/template/${id}`);
}

// TODO: Temporary instance caused by big BE changes, needs to be removed
const templatesApi = new TemplatesApi(instance_v2);
export default templatesApi;
