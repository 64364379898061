import Konva from 'konva';
import * as React from 'react';
import { Label, Tag, Text } from 'react-konva';
import { selectTheme, useConfigStore } from '../../store/config';

export interface Props extends Konva.LabelConfig {
  value: number | string;
  unit?: string;
}

const GapLabel: React.FC<Props> = React.memo(
  React.forwardRef<Konva.Label, Props>(({ x, y, value }, ref) => {
    const theme = useConfigStore(selectTheme);

    return (
      <Label ref={ref} x={x} y={y}>
        <Tag fill={theme.colors.mainBlue3} cornerRadius={4} />
        <Text
          text={`${String(value)} mm`}
          fontSize={10}
          padding={2}
          fill={theme.colors.white}
        />
      </Label>
    );
  }),
);

GapLabel.displayName = 'GapLabel';

export default GapLabel;
