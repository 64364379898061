import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioModal } from '../../../../../../components/RadioModal';
import { Glass } from '../../../../../../services/api/models/glass';
import { ProductModel } from '../../../../../../services/api/models/product';
import { Position } from '../../../../../../types';
import { ChangeThicknessDecision, ProjectModals } from '../../../../types';
import { AlignTo, AlignToTypes } from '../../components/AlignTo';
import {
  GlassProperties,
  PropertySelectItem,
  PropertySwitchItem,
} from '../../components/GlassProperties';
import { FormSection } from '../../styles';
import { Container } from './styles';
import { OptionObject } from '../../../../../../components/Select';
import GlassPositioning from './GlassPositioning';
import GlassDimensions from './GlassDimensions';
import { ProjectDimensions } from '../../../../../../services/api/models/project';
import { BoundariesCopy } from '../../../../../../modules/creator/types';
import { findXOnSegment, findYOnSegment } from '../../../../../../utils/shape';
import { Shape } from '../../../../../../modules/space';
import GlassPositioningCopy from './GlassPositioningCopy';
import { useGaps } from '../../../../../../modules/creator/hooks';
import { GlassAngles } from './GlassAngles';

interface Props {
  selectedGlass: Glass;
  glasses: Glass[];
  mountedProducts: ProductModel[];
  boundaries: BoundariesCopy;
  modals: Map<string, boolean>;
  templatePosition: Position;
  templateDimensions: ProjectDimensions;
  propertiesInputs: (PropertySelectItem | PropertySwitchItem)[];
  onModalClose: (modal: string) => void;
  onUpdateDimensions: (
    corners: ProjectDimensions['corners'],
    position?: Partial<Position>,
  ) => void;
  onUpdatePosition: (position: Partial<Position>) => void;
  onThicknessChange: (option: OptionObject) => Promise<boolean>;
  onThicknessValid?: (decision: ChangeThicknessDecision) => void;
}

const thicknessDecisions: ChangeThicknessDecision[] = ['leave', 'remove'];

const GlassFormView: React.FC<Props> = ({
  selectedGlass,
  glasses,
  mountedProducts,
  boundaries,
  modals,
  templatePosition,
  templateDimensions,
  propertiesInputs,
  onModalClose,
  onUpdateDimensions,
  onUpdatePosition,
  onThicknessChange,
  onThicknessValid,
}) => {
  const { t } = useTranslation('project');
  const [foldProperties, setFoldProperties] = useState(false);

  const { findGaps } = useGaps({
    glasses,
    templateDimensions,
    templatePosition,
  });
  const Gaps = useMemo(() => findGaps(selectedGlass), [
    findGaps,
    selectedGlass,
  ]);

  const thicknessDecisionsLabels = useMemo(
    () =>
      thicknessDecisions.map((decision) =>
        t(`modals.glassThickness.options.${decision}`),
      ),
    [t],
  );

  const onFoldProperties = useCallback(() => {
    setFoldProperties(!foldProperties);
  }, [foldProperties]);

  const closeThicknessModal = useCallback(
    () => onModalClose(ProjectModals.GLASS_THICKNESS_CHANGE),
    [onModalClose],
  );

  const onAlign = useCallback(
    (type: AlignToTypes) => {
      const { position } = selectedGlass;
      const shape = new Shape(
        { corners: selectedGlass.corners },
        selectedGlass.position,
      );

      const topDistance = findYOnSegment(
        shape.extremePoints.top.point.x,
        boundaries.top,
      );

      const bottomDistance = findYOnSegment(
        shape.extremePoints.bottom.point.x,
        boundaries.bottom,
      );

      const leftDistance = findXOnSegment(
        shape.extremePoints.left.point.y,
        boundaries.left,
      );

      const rightDistance = findXOnSegment(
        shape.extremePoints.right.point.y,
        boundaries.right,
      );

      if (
        topDistance !== undefined &&
        bottomDistance !== undefined &&
        leftDistance !== undefined &&
        rightDistance !== undefined
      ) {
        const leftDifference = leftDistance - shape.extremePoints.left.point.x;
        const rightDifference =
          rightDistance - shape.extremePoints.right.point.x;
        const topDifference = topDistance - shape.extremePoints.top.point.y;
        const bottomDifference =
          bottomDistance - shape.extremePoints.bottom.point.y;

        switch (type) {
          case AlignToTypes.UP: {
            onUpdatePosition({
              y: position.y + topDifference,
            });
            break;
          }
          case AlignToTypes.LOW: {
            onUpdatePosition({
              y: position.y + bottomDifference,
            });
            break;
          }
          case AlignToTypes.RIGHT: {
            onUpdatePosition({
              x: position.x + rightDifference,
            });
            break;
          }
          case AlignToTypes.LEFT: {
            onUpdatePosition({
              x: position.x + leftDifference,
            });
            break;
          }
          case AlignToTypes.CENTER: {
            onUpdatePosition({
              x:
                position.x +
                leftDifference +
                (rightDifference - leftDifference) / 2,
            });
            break;
          }
          case AlignToTypes.MIDDLE: {
            onUpdatePosition({
              y:
                position.y +
                topDifference +
                (bottomDifference - topDifference) / 2,
            });
            break;
          }
        }
      }
    },
    [selectedGlass, onUpdatePosition],
  );

  return (
    <Container>
      <FormSection noPadding>
        <AlignTo onSelect={onAlign} disabled={mountedProducts.length > 0} />
      </FormSection>
      <FormSection>
        <GlassDimensions
          adjustManually={selectedGlass.adjustManually ?? false}
          mountedProducts={mountedProducts}
          selectedGlass={selectedGlass}
          boundaries={boundaries}
          onUpdateDimensions={onUpdateDimensions}
        />
      </FormSection>
      {selectedGlass.adjustManually ? (
        <FormSection>
          <GlassAngles dimensions={{ corners: selectedGlass.corners }} />
        </FormSection>
      ) : null}
      <FormSection>
        {selectedGlass.adjustManually ? (
          <GlassPositioningCopy
            selectedGlass={selectedGlass}
            gaps={Gaps}
            mountedProducts={mountedProducts}
            onUpdatePosition={onUpdatePosition}
          />
        ) : (
          <GlassPositioning
            selectedGlass={selectedGlass}
            gaps={Gaps}
            boundaries={boundaries}
            templateDimensions={templateDimensions}
            templatePosition={templatePosition}
            mountedProducts={mountedProducts}
            onUpdatePosition={onUpdatePosition}
          />
        )}
      </FormSection>
      <FormSection>
        <GlassProperties
          title={t('propertyPanel.form.properties')}
          items={propertiesInputs}
          fold={foldProperties}
          onFold={onFoldProperties}
          validateThicknessChange={onThicknessChange}
        />
      </FormSection>
      {modals.get(ProjectModals.GLASS_THICKNESS_CHANGE) && (
        <RadioModal
          decisions={thicknessDecisions}
          decisionLabels={thicknessDecisionsLabels}
          title={t('modals.glassThickness.title')}
          description={t('modals.glassThickness.description.first')}
          descriptionSec={t('modals.glassThickness.description.second')}
          confirmLabel={t('modals.glassThickness.confirm')}
          onClose={closeThicknessModal}
          onResolve={onThicknessValid}
        />
      )}
    </Container>
  );
};

GlassFormView.displayName = 'GlassFormView';

export default GlassFormView;
