import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled(layout.Column)`
  align-items: center;
  text-align: center;
  > span {
    margin-top: 20px;
    color: ${({ theme }) => theme.colors.grey};
  }
`;
