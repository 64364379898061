import { useCreator } from '../../../../pages/Project/hooks';
import { Shape, Space } from '../../../space';

const LOWER_AREA_THRESHOLD_IN_SQ_MM = 10000;

export const useHasSufficientAreaForGlassCreation = () => {
  const { project, projectGlasses } = useCreator();

  const space = new Space(project.data.dimensions);

  space.addShapes(projectGlasses.map((item) => new Shape(item)));

  const remainingArea = space.area - space.shapesArea;

  return remainingArea > LOWER_AREA_THRESHOLD_IN_SQ_MM;
};
