import * as React from 'react';
import { SelectBadge, BadgeIconWrapper } from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { Option } from './SelectBox';
import { Close } from '../icons';
import { useCallback } from 'react';

export interface Props {
  option: Option;
  onDelete(option: Option): void;
  disabled?: boolean;
}

const Badge: React.FC<Props> = React.memo(
  ({ option, onDelete, disabled, ...props }) => {
    const handleDelete = useCallback(
      (e: React.SyntheticEvent) => {
        if (!disabled) {
          e.stopPropagation();
          onDelete(option);
        }
      },
      [disabled, onDelete, option],
    );

    return (
      <SelectBadge {...props} disabled={disabled}>
        <Typography variant={TypographyVariants.PARAGRAPH}>
          {option.title}
        </Typography>
        <BadgeIconWrapper
          role="button"
          onClick={handleDelete}
          disabled={disabled}>
          <Close />
        </BadgeIconWrapper>
      </SelectBadge>
    );
  },
);

Badge.displayName = 'Badge';

export default Badge;
