import * as React from 'react';
import { Container } from './styles';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { RootRoutes } from '../../router/routes';
import { Default as Logo } from '../images/logo';

export interface Props {}

const Brand: React.FC<Props> = React.memo(() => {
  const { push } = useHistory();

  const goToHome = useCallback(() => {
    push(RootRoutes.HOME);
  }, [push]);

  return (
    <Container onClick={goToHome} role="button">
      <Logo viewBox="0 0 320 70" />
    </Container>
  );
});

Brand.displayName = 'Brand';

export default Brand;
