import * as React from 'react';
import Konva from 'konva';
import { Rect } from 'react-konva';
import { selectTheme, useConfigStore } from '../../store/config';
import { Dimensions } from '../../types';
import { IndentPosition } from '../../../../services/api/models/project';
import { useMemo } from 'react';
import { findIndentPositionX, findIndentPositionY } from './utils';

export const NICHE_INDENT = 'niche-indent';

export interface Props {
  templateDimensions: Dimensions;
  position: IndentPosition;
}

const Indent: React.FC<Props> = React.memo(
  React.forwardRef<Konva.Rect, Props>(
    ({ templateDimensions, position }, ref) => {
      const theme = useConfigStore(selectTheme);

      const x = useMemo(
        () => findIndentPositionX(position, templateDimensions.width),
        [position, templateDimensions.width],
      );

      const y = useMemo(
        () => findIndentPositionY(position, templateDimensions.height),
        [position, templateDimensions.height],
      );

      return (
        <Rect
          ref={ref}
          name={NICHE_INDENT}
          fill={theme.colors.grey3}
          x={x}
          y={y}
          width={templateDimensions.width / 2}
          height={templateDimensions.height / 2}
        />
      );
    },
  ),
);

Indent.displayName = 'Indent';

export default Indent;
