import { AxiosError, AxiosRequestConfig } from 'axios';
import { sessionStore } from '../../../store/session';
import { i18next } from '../../i18n';

export const requestInterceptor = (config: AxiosRequestConfig) => {
  const { accessToken } = sessionStore.getState();
  config.headers['Accept-Language'] = i18next.language;
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

export const requestErrorInterceptor = (error: AxiosError) => {
  return Promise.reject(error);
};
