import * as React from 'react';
import {
  Container,
  InputsColumn,
  InputsContainer,
  InputsRow,
  PositionInput,
  PositionSpacingContainer,
} from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { Boundaries } from '../../../../../../modules/creator/types';
import PositionSpacing from '../../../../../../components/icons/PositionSpacing';
import { Row } from '../../../../../../styles/layout';
import { Gaps } from '../../../../../../modules/creator/types/Gap';
import { BoundariesCopy } from '../../../../../../modules/creator/types';
import { useCallback } from 'react';
import Konva from 'konva';

type Positions = Boundaries;

type Disabled = Record<keyof Positions, boolean>;

export interface Props {
  positions: Gaps;
  title?: string;
  disabled?: Disabled;
  minLimit?: BoundariesCopy;
  maxLimit?: Record<keyof BoundariesCopy, [number, number]>;
  step?: number;
  onChange: (change: Konva.Vector2d) => void;
}

const PositioningCopy: React.FC<Props> = React.memo(
  ({
    positions,
    title,
    onChange,
    disabled,
    minLimit,
    maxLimit,
    step,
    ...props
  }) => {
    // const getPositionValue = React.useCallback(
    //   (direction: 'top' | 'bottom' | 'left' | 'right') => {
    //     if (minLimit && maxLimit) {
    //       return positions[direction] < minLimit[direction]
    //         ? minLimit[direction]
    //         : positions[direction] > maxLimit[direction]
    //         ? maxLimit[direction]
    //         : positions[direction];
    //     }
    //     return positions[direction];
    //   },
    //   [minLimit, maxLimit, positions],
    // );

    const handleChange = useCallback(
      (value: string, name: string) => {
        const changeVector = { x: 0, y: 0 };
        switch (name) {
          case 'top-left-position':
            changeVector.y = Number(value) - positions['top'].value[0];
            break;
          case 'top-right-position':
            changeVector.y = Number(value) - positions['top'].value[1];
            break;
          case 'bottom-left-position':
            changeVector.y = positions['bottom'].value[0] - Number(value);
            break;
          case 'bottom-right-position':
            changeVector.y = positions['bottom'].value[1] - Number(value);
            break;
          case 'left-top-position':
            changeVector.x = Number(value) - positions['left'].value[0];
            break;
          case 'left-bottom-position':
            changeVector.x = Number(value) - positions['left'].value[1];
            break;
          case 'right-top-position':
            changeVector.x = positions['right'].value[0] - Number(value);
            break;
          case 'right-bottom-position':
            changeVector.x = positions['right'].value[1] - Number(value);
            break;
        }

        onChange(changeVector);
      },
      [onChange, positions],
    );

    return (
      <Container {...props}>
        {title && (
          <Typography variant={TypographyVariants.H5_BOLD}>{title}</Typography>
        )}
        <InputsContainer>
          <InputsRow>
            <PositionInput
              name="top-left-position"
              type="number"
              width={105}
              unit="mm"
              value={String(positions.top.value[0])}
              disabled={disabled?.top}
              onBlur={handleChange}
              min={0}
              max={maxLimit?.top[0]}
              step={step}
              errorFeedback
              withoutDebounce
            />
            <PositionInput
              name="top-right-position"
              type="number"
              width={105}
              unit="mm"
              value={String(positions.top.value[1])}
              disabled={disabled?.top}
              onBlur={handleChange}
              min={0}
              max={maxLimit?.top[1]}
              step={step}
              errorFeedback
              withoutDebounce
            />
          </InputsRow>
          <Row>
            <InputsColumn>
              <PositionInput
                name="left-top-position"
                type="number"
                width={105}
                unit="mm"
                value={String(positions.left.value[0])}
                disabled={disabled?.left}
                onBlur={handleChange}
                min={0}
                max={maxLimit?.left[0]}
                step={step}
                errorFeedback
                withoutDebounce
              />
              <PositionInput
                name="left-bottom-position"
                type="number"
                width={105}
                unit="mm"
                value={String(positions.left.value[1])}
                disabled={disabled?.left}
                onBlur={handleChange}
                min={0}
                max={maxLimit?.left[1]}
                step={step}
                errorFeedback
                withoutDebounce
              />
            </InputsColumn>
            <PositionSpacingContainer>
              <PositionSpacing />
            </PositionSpacingContainer>
            <InputsColumn>
              <PositionInput
                name="right-top-position"
                type="number"
                width={105}
                unit="mm"
                value={String(positions.right.value[0])}
                disabled={disabled?.right}
                onBlur={handleChange}
                min={0}
                max={maxLimit?.right[0]}
                step={step}
                errorFeedback
                withoutDebounce
              />
              <PositionInput
                name="right-bottom-position"
                type="number"
                width={105}
                unit="mm"
                value={String(positions.right.value[1])}
                disabled={disabled?.right}
                onBlur={handleChange}
                min={0}
                max={maxLimit?.right[1]}
                step={step}
                errorFeedback
                withoutDebounce
              />
            </InputsColumn>
          </Row>
          <InputsRow>
            <PositionInput
              name="bottom-left-position"
              type="number"
              width={105}
              unit="mm"
              value={String(positions.bottom.value[0])}
              disabled={disabled?.bottom}
              onBlur={handleChange}
              min={0}
              max={maxLimit?.bottom[0]}
              step={step}
              errorFeedback
              withoutDebounce
            />
            <PositionInput
              name="bottom-right-position"
              type="number"
              width={105}
              unit="mm"
              value={String(positions.bottom.value[1])}
              disabled={disabled?.bottom}
              onBlur={handleChange}
              min={0}
              max={maxLimit?.bottom[1]}
              step={step}
              errorFeedback
              withoutDebounce
            />
          </InputsRow>
        </InputsContainer>
      </Container>
    );
  },
);

PositioningCopy.displayName = 'PositioningCopy';

export default PositioningCopy;
