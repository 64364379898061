import React, { useMemo } from 'react';
import { CornerAngle } from '../CornerAngle';
import { ProjectDimensions } from '../../../../services/api/models/project';
import {
  mapCoordinatesToPoints,
  mapPointsToVectors,
} from '../../../../utils/shape';
import { Group } from 'react-konva';
import Konva from 'konva';

interface Props {
  dimensions: ProjectDimensions;
  position: Konva.Vector2d;
}

const GlassAngles: React.FC<Props> = React.memo(({ dimensions, position }) => {
  const dimToPoints = useMemo(() => {
    return mapPointsToVectors(mapCoordinatesToPoints(dimensions));
  }, [dimensions]);

  return (
    <Group x={position.x} y={position.y}>
      <CornerAngle
        points={[dimToPoints[3], dimToPoints[0], dimToPoints[1]]}
        position={dimToPoints[0]}
        rotation={0}
      />
      <CornerAngle
        points={[dimToPoints[0], dimToPoints[1], dimToPoints[2]]}
        position={dimToPoints[1]}
        rotation={-90}
      />
      <CornerAngle
        points={[dimToPoints[1], dimToPoints[2], dimToPoints[3]]}
        position={dimToPoints[2]}
        rotation={-180}
      />
      <CornerAngle
        points={[dimToPoints[2], dimToPoints[3], dimToPoints[0]]}
        position={dimToPoints[3]}
        rotation={-270}
      />
    </Group>
  );
});

GlassAngles.displayName = 'GlassAngles';

export default GlassAngles;
