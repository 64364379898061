import * as React from 'react';

const SvgIt = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#IT_svg__clip0)">
        <path
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
          fill="#F0F0F0"
        />
        <path
          d="M24 12c0-5.16-3.257-9.558-7.826-11.254v22.508C20.744 21.558 24 17.16 24 12z"
          fill="#D80027"
        />
        <path
          d="M0 12c0 5.16 3.256 9.558 7.826 11.254V.746C3.256 2.442 0 6.84 0 12z"
          fill="#6DA544"
        />
      </g>
      <defs>
        <clipPath id="IT_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MemoSvgIt = React.memo(SvgIt);
export default MemoSvgIt;
