import { Anchor } from '../types/Anchor';
import {
  InsertItem,
  InsertItemTypes,
  selectClearInsert,
  useInsertStore,
} from '../store/insert';
import { useCallback } from 'react';
import { NotificationVariants } from '../../../components/Notification';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../store/notifications';
import { selectProducts, useProductsStore } from '../store/products';
import { useTranslation } from 'react-i18next';
import { Gap } from '../types';
import { Glass } from '../../../services/api/models/glass';
import { cutGlass } from '../../../utils/glass';

export interface UseInsertOnGlass {
  onInsert(anchor: Anchor, item: InsertItem): void;
}

const validAnchors = (anchor: Anchor): Anchor[] => {
  switch (anchor) {
    case 'top': {
      return ['top', 'topLeft', 'topRight'];
    }
    case 'bottom': {
      return ['bottom', 'bottomLeft', 'bottomRight'];
    }
    case 'left': {
      return ['left', 'bottomLeft', 'topLeft'];
    }
    case 'right': {
      return ['right', 'bottomRight', 'topRight'];
    }
    default: {
      return [anchor];
    }
  }
};

const useInsertOnGlass = (
  glass: Glass,
  onGlassUpdate: (glass: Glass) => void,
) => {
  const { t } = useTranslation('project');
  const clearInsert = useInsertStore(selectClearInsert);
  const creatorProducts = useProductsStore(selectProducts);
  const setNotification = useNotificationsStore(selectSetNotification);

  const onGapInsert = useCallback(
    (anchor: Anchor, size: Gap) => {
      if (
        creatorProducts
          .filter(
            (product) =>
              product.parentType === 'glass' && product.parent.id === glass.id,
          )
          .every((product) => !validAnchors(anchor).includes(product.anchor))
      ) {
        const cut = cutGlass(anchor, size, glass);
        onGlassUpdate(cut);
        return setNotification({
          text: t('notifications.insertGapSuccess'),
          variant: NotificationVariants.SUCCESS,
        });
      }
      return setNotification({
        text: t('notifications.cannotInsertGap'),
        variant: NotificationVariants.ERROR,
      });
    },
    [creatorProducts, cutGlass, glass, onGlassUpdate, setNotification, t],
  );

  const onInsert = useCallback(
    (anchor: Anchor, item: InsertItem) => {
      clearInsert();
      switch (item.type) {
        case InsertItemTypes.GAP: {
          return onGapInsert(anchor, item.size);
        }
      }
    },
    [clearInsert, onGapInsert],
  );

  return {
    onInsert,
  };
};

export default useInsertOnGlass;
