import { State } from './copy';

export const selectCopied = (state: State) => state.copied;

export const selectCopy = (state: State) => state.copy;

export const selectCut = (state: State) => state.cut;

export const selectPaste = (state: State) => state.paste;

export const selectValidPaste = (state: State) => state.validPaste;

export const selectValidSpace = (state: State) => state.validSpace;

export const selectValidPosition = (state: State) => state.validPosition;

export const selectValidGlassGlass = (state: State) => state.validGlassGlass;
