import {
  DimensionsInputs,
  HorizontalPositioning,
  VerticalPositioning,
} from '../../components/DimensionsInputs';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  fixValue,
  roundPosition,
} from '../../../../../../modules/creator/utils/fix';
import {
  ExtendedAnchoringOptions,
  ProductModel,
} from '../../../../../../services/api/models/product';
import { Glass } from '../../../../../../services/api/models/glass';
import { EdgeType, IndentEdgeType, Position } from '../../../../../../types';
import { AreaInputs } from '../../components/AreaInputs';
import { ProjectDimensions } from '../../../../../../services/api/models/project';
import {
  changeShape,
  findXOnSegment,
  findYOnSegment,
} from '../../../../../../utils/shape';
import { Shape } from '../../../../../../modules/space';
import { BoundariesCopy } from '../../../../../../modules/creator/types';
import Big from 'big.js';

type Direction = HorizontalPositioning | VerticalPositioning;

export interface EdgeChangeProps {
  diff: number;
  edge: IndentEdgeType;
  direction: Direction;
  lockOpposite?: boolean;
}

interface GlassDimensionsProps {
  adjustManually: boolean;
  selectedGlass: Glass;
  mountedProducts: ProductModel[];
  boundaries: BoundariesCopy;
  onUpdateDimensions: (
    corners: ProjectDimensions['corners'],
    position?: Partial<Position>,
  ) => void;
}

const GlassDimensions = ({
  adjustManually,
  selectedGlass,
  mountedProducts,
  boundaries,
  onUpdateDimensions,
}: GlassDimensionsProps) => {
  const [dimVChange, setDimVChange] = useState(VerticalPositioning.TOP);
  const [dimHChange, setDimHChange] = useState(HorizontalPositioning.LEFT);

  const glassObj = useMemo(
    () => new Shape({ corners: selectedGlass.corners }, selectedGlass.position),
    [selectedGlass.corners, selectedGlass.position],
  );

  const anchorPointsWithProducts = useMemo(
    () =>
      new Set(
        mountedProducts.reduce(
          (acc, curr) => [
            ...acc,
            ...curr.connections
              .filter(
                (connection) =>
                  connection.targetId === String(selectedGlass?.id),
              )
              .map((connection) => connection.anchor),
          ],
          [] as (keyof ExtendedAnchoringOptions)[],
        ),
      ),
    [selectedGlass?.id, mountedProducts],
  );

  const disabledResizeOptions = useMemo(() => {
    const disabled: (HorizontalPositioning | VerticalPositioning)[] = [];
    if (
      anchorPointsWithProducts.has('top') ||
      anchorPointsWithProducts.has('topRight') ||
      anchorPointsWithProducts.has('topLeft')
    ) {
      disabled.push(VerticalPositioning.TOP);
      disabled.push(VerticalPositioning.MIDDLE);
    }
    if (
      anchorPointsWithProducts.has('bottom') ||
      anchorPointsWithProducts.has('bottomRight') ||
      anchorPointsWithProducts.has('bottomLeft')
    ) {
      disabled.push(VerticalPositioning.BOTTOM);
      disabled.push(VerticalPositioning.MIDDLE);
    }
    if (
      anchorPointsWithProducts.has('left') ||
      anchorPointsWithProducts.has('topLeft') ||
      anchorPointsWithProducts.has('bottomLeft')
    ) {
      disabled.push(HorizontalPositioning.LEFT);
      disabled.push(HorizontalPositioning.MIDDLE);
    }
    if (
      anchorPointsWithProducts.has('right') ||
      anchorPointsWithProducts.has('topRight') ||
      anchorPointsWithProducts.has('bottomRight')
    ) {
      disabled.push(HorizontalPositioning.RIGHT);
      disabled.push(HorizontalPositioning.MIDDLE);
    }
    return disabled;
  }, [anchorPointsWithProducts]);

  const getMaxDimensions = useCallback(
    (direction: Direction) => {
      const boundariesForShape = {
        right: {
          top:
            findXOnSegment(glassObj.topEdge.points.right.y, boundaries.right) ??
            0,
          bottom:
            findXOnSegment(
              glassObj.bottomEdge.points.right.y,
              boundaries.right,
            ) ?? 0,
        },
        left: {
          top:
            findXOnSegment(glassObj.topEdge.points.left.y, boundaries.left) ??
            0,
          bottom:
            findXOnSegment(
              glassObj.bottomEdge.points.left.y,
              boundaries.left,
            ) ?? 0,
        },
        bottom: {
          left:
            findYOnSegment(
              glassObj.leftEdge.points.bottom.x,
              boundaries.bottom,
            ) ?? 0,
          right:
            findYOnSegment(
              glassObj.rightEdge.points.bottom.x,
              boundaries.bottom,
            ) ?? 0,
        },
        top: {
          left:
            findYOnSegment(glassObj.leftEdge.points.top.x, boundaries.top) ?? 0,
          right:
            findYOnSegment(glassObj.rightEdge.points.top.x, boundaries.top) ??
            0,
        },
      };

      const gaps = {
        right: {
          top: roundPosition(
            boundariesForShape.right.top - glassObj.topEdge.points.right.x,
          ),
          bottom: roundPosition(
            boundariesForShape.right.bottom -
              glassObj.bottomEdge.points.right.x,
          ),
        },
        left: {
          top: roundPosition(
            glassObj.topEdge.points.left.x - boundariesForShape.left.top,
          ),
          bottom: roundPosition(
            glassObj.bottomEdge.points.left.x - boundariesForShape.left.bottom,
          ),
        },
        bottom: {
          left: roundPosition(
            boundariesForShape.bottom.left - glassObj.leftEdge.points.bottom.y,
          ),
          right: roundPosition(
            boundariesForShape.bottom.right -
              glassObj.rightEdge.points.bottom.y,
          ),
        },
        top: {
          left: roundPosition(
            glassObj.leftEdge.points.top.y - boundariesForShape.top.left,
          ),
          right: roundPosition(
            glassObj.rightEdge.points.top.y - boundariesForShape.top.right,
          ),
        },
      };

      const maxOutwardsDimensions = {
        top: fixValue(glassObj.topEdge.length + gaps.right.top),
        bottom: fixValue(glassObj.bottomEdge.length + gaps.right.bottom),
        left: fixValue(glassObj.leftEdge.length + gaps.bottom.left),
        right: fixValue(glassObj.rightEdge.length + gaps.bottom.right),
      };

      const maxInwardsDimensions = {
        top: fixValue(glassObj.topEdge.length + gaps.left.top),
        bottom: fixValue(glassObj.bottomEdge.length + gaps.left.bottom),
        left: fixValue(glassObj.leftEdge.length + gaps.top.left),
        right: fixValue(glassObj.rightEdge.length + gaps.top.right),
      };

      if (
        direction === HorizontalPositioning.RIGHT ||
        direction === VerticalPositioning.BOTTOM
      ) {
        return maxOutwardsDimensions;
      } else if (
        direction === HorizontalPositioning.LEFT ||
        direction === VerticalPositioning.TOP
      ) {
        return maxInwardsDimensions;
      } else {
        const topGapDiff = Math.abs(gaps.left.top - gaps.right.top);
        const bottomGapDiff = Math.abs(gaps.right.bottom - gaps.left.bottom);
        const leftGapDiff = Math.abs(gaps.top.left - gaps.bottom.left);
        const rightGapDiff = Math.abs(gaps.top.right - gaps.bottom.right);

        return {
          top: maxInwardsDimensions.top + gaps.right.top - topGapDiff,
          bottom:
            maxInwardsDimensions.bottom + gaps.right.bottom - bottomGapDiff,
          left: maxInwardsDimensions.left + gaps.bottom.left - leftGapDiff,
          right: maxInwardsDimensions.right + gaps.bottom.right - rightGapDiff,
        };
      }
    },
    [boundaries, glassObj],
  );

  const maxWidth = useMemo(() => {
    const maxDimensions = getMaxDimensions(dimHChange);

    return maxDimensions.top < maxDimensions.bottom
      ? maxDimensions.top
      : maxDimensions.bottom;
  }, [dimHChange, getMaxDimensions]);

  const maxHeight = useMemo(() => {
    const maxDimensions = getMaxDimensions(dimVChange);

    return maxDimensions.left < maxDimensions.right
      ? maxDimensions.left
      : maxDimensions.right;
  }, [dimVChange, getMaxDimensions]);

  const handleEdgeChange = useCallback(
    ({ diff, edge, direction, lockOpposite }: EdgeChangeProps) => {
      const dimensions = changeShape(
        selectedGlass,
        edge as EdgeType,
        diff,
        direction,
        lockOpposite,
      );
      const shapeObj = new Shape({ corners: dimensions.corners });

      shapeObj.roundCorners();

      onUpdateDimensions(shapeObj.corners);
    },
    [onUpdateDimensions, selectedGlass.position, selectedGlass.corners],
  );

  const onWidthChange = useCallback(
    (value: string) => {
      const glassObj = new Shape({ corners: selectedGlass.corners });
      const width = new Big(value);
      const diff = Number(new Big(new Big(width).minus(glassObj.width)));

      const dimensions = changeShape(
        selectedGlass,
        'top',
        diff,
        dimHChange,
        true,
      );

      onUpdateDimensions(dimensions.corners);
    },
    [
      dimHChange,
      onUpdateDimensions,
      selectedGlass.position,
      selectedGlass.corners,
    ],
  );

  const onHeightChange = useCallback(
    (value: string) => {
      const glassObj = new Shape({ corners: selectedGlass.corners });
      const height = new Big(value);
      const diff = Number(new Big(new Big(height).minus(glassObj.height)));

      const dimensions = changeShape(
        selectedGlass,
        'left',
        diff,
        dimVChange,
        true,
      );

      onUpdateDimensions(dimensions.corners);
    },
    [
      dimVChange,
      onUpdateDimensions,
      selectedGlass.position,
      selectedGlass.corners,
    ],
  );

  const disableHChange = useMemo(() => {
    let disabled = false;

    const shape = new Shape(
      { corners: selectedGlass.corners },
      selectedGlass.position,
    );

    if (disabledResizeOptions.includes(HorizontalPositioning.LEFT)) {
      const available = Object.values(HorizontalPositioning).find(
        (value) => !disabledResizeOptions.includes(value),
      );
      if (available) {
        setDimHChange(available);
      }
      disabled = !!available;
    }

    if (!shape.isRectangular) {
      disabled = true;
    }

    if (
      disabledResizeOptions.includes(HorizontalPositioning.LEFT) &&
      disabledResizeOptions.includes(HorizontalPositioning.RIGHT) &&
      disabledResizeOptions.includes(HorizontalPositioning.MIDDLE)
    ) {
      disabled = true;
    }

    return disabled;
  }, [disabledResizeOptions, selectedGlass.corners, selectedGlass.position]);

  const disableVChange = useMemo(() => {
    let disabled = false;
    const shape = new Shape(
      { corners: selectedGlass.corners },
      selectedGlass.position,
    );

    if (disabledResizeOptions.includes(VerticalPositioning.TOP)) {
      const available = Object.values(VerticalPositioning).find(
        (value) => !disabledResizeOptions.includes(value),
      );
      if (available) {
        setDimVChange(available);
      }

      disabled = !!available;
    }

    if (!shape.isRectangular) {
      disabled = true;
    }

    if (
      disabledResizeOptions.includes(VerticalPositioning.TOP) &&
      disabledResizeOptions.includes(VerticalPositioning.BOTTOM) &&
      disabledResizeOptions.includes(VerticalPositioning.MIDDLE)
    ) {
      disabled = true;
    }

    return disabled;
  }, [disabledResizeOptions, selectedGlass.corners, selectedGlass.position]);

  useEffect(() => {
    setDimHChange(HorizontalPositioning.LEFT);
    setDimVChange(VerticalPositioning.TOP);
  }, [selectedGlass.id]);

  if (adjustManually) {
    return (
      <AreaInputs
        selectedId={selectedGlass.id}
        dimensions={{ corners: selectedGlass.corners }}
        getMaxDimensions={getMaxDimensions}
        onChange={handleEdgeChange}
      />
    );
  }

  return (
    <DimensionsInputs
      onWidthChange={onWidthChange}
      onHPositioningChange={setDimHChange}
      onHeightChange={onHeightChange}
      onVPositioningChange={setDimVChange}
      hPositioning={dimHChange}
      vPositioning={dimVChange}
      width={maxWidth && glassObj.width > maxWidth ? maxWidth : glassObj.width}
      height={
        maxHeight && glassObj.height > maxHeight ? maxHeight : glassObj.height
      }
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      disabledOptions={disabledResizeOptions}
      disableVChange={disableVChange}
      disableHChange={disableHChange}
    />
  );
};

GlassDimensions.displayName = 'GlassDimensions';

export default GlassDimensions;
