import styled from 'styled-components';
import { layout } from '../../styles';

export const Container = styled(layout.Row)`
  flex: 0 0 100%;
  height: 57px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 88;
  > div {
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: ${({ theme }) => theme.colors.accentBlue2};
    &:first-of-type {
      border-left: 0;
    }
  }
`;
