import { ProductProperty } from '../../services/api/models/product';
import { Option } from './SelectBox';
import find from 'lodash/find';

export const mapOptions = (values: ProductProperty[]): Option[] => {
  if (values.length !== 0) {
    return values.map((value) => ({
      title: value.name,
      value: String(value.id),
    }));
  }
  return [];
};

export const mapValuesToOptions = (values: string[]): Option[] => {
  if (values.length !== 0) {
    return values.map((value) => ({ title: value, value: value }));
  }
  return [];
};

export const mapOptionsToValues = (options: Option[]): ProductProperty[] => {
  if (options.length !== 0) {
    return options.map((option) => ({
      id: parseInt(option.value),
      name: option.title,
    }));
  }
  return [];
};

export const findPropertiesOption = (
  properties: ProductProperty[],
  value: string,
): Option | undefined => {
  const prop = find(
    properties,
    (item) => String(item.id) === value || item.name === value,
  );

  if (!prop) return prop;

  return {
    title: prop.name,
    value: String(prop.id),
  };
};

export const findOptionsItem = (
  options: Option[],
  value: string,
): Option | undefined => {
  return find(
    options,
    (item) => String(item.value) === value || item.title === value,
  );
};
