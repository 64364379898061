import * as React from 'react';
import { Container, TextWrapper, IconWrapper } from './styles';
import { NotificationVariants } from './types';
import { Typography, TypographyVariants } from '../Typography';
import { Alert, Check, Close, Info } from '../icons';
import { useMemo } from 'react';

export interface Props {
  text: string;
  variant?: NotificationVariants;
  Icon?: React.ReactNode;
  onDismiss?(): void;
  fullWidth?: boolean;
}

const Notification: React.FC<Props> = React.memo(
  ({
    text,
    Icon,
    onDismiss,
    fullWidth,
    variant = NotificationVariants.INFO,
  }) => {
    const NotificationIcon = useMemo(() => {
      switch (variant) {
        case NotificationVariants.SUCCESS: {
          return <Check />;
        }
        case NotificationVariants.ERROR: {
          return <Close />;
        }
        case NotificationVariants.ALERT: {
          return <Alert />;
        }
        default:
        case NotificationVariants.INFO: {
          return <Info />;
        }
      }
    }, [variant]);

    return (
      <Container
        role="alert"
        variant={variant}
        onClick={onDismiss}
        fullWidth={fullWidth}>
        <IconWrapper>{Icon ?? NotificationIcon}</IconWrapper>
        <TextWrapper>
          <Typography variant={TypographyVariants.H4}>{text}</Typography>
        </TextWrapper>
      </Container>
    );
  },
);

Notification.displayName = 'Notification';

export default Notification;
