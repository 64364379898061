import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { SidePanel, SidePanelPosition } from '../../../../components/SidePanel';
import { useCreator } from '../../hooks';
import { EmptyInfo } from './components/EmptyInfo';
import { Content } from './styles';
import { NicheForm } from './containers/NicheForm';
import { GlassForm } from './containers/GlassForm';
import {
  selectSelectedResult,
  useSearchProductsStore,
} from '../../../../store/products';
import { ProductInfo, SearchProduct } from './components/ProductInfo';
import { ProductForm } from './containers/ProductForm';
import { ProductModel } from '../../../../services/api/models/product';
import { creatorProducts, creatorProject } from '../../../../modules/creator';
import {
  selectSelected,
  useSelectStore,
} from '../../../../modules/creator/store/select';

const { useProjectStore, selectProjectGlasses } = creatorProject;
const { selectProduct, useProductsStore } = creatorProducts;
const PropertyPanel: React.FC = () => {
  const selectedSearchResult = useSearchProductsStore(selectSelectedResult);
  const [unfoldProductInfo, setUnfoldProductInfo] = useState(true);
  const [selectedProduct] = useSelectStore(selectSelected);
  const creatorProduct = useProductsStore(
    selectProduct(selectedProduct?.shapeId as string),
  );

  const glasses = useProjectStore(selectProjectGlasses);

  const searchResult = useMemo<SearchProduct | undefined>(
    () =>
      selectedSearchResult && {
        type: 'search',
        product: selectedSearchResult,
      },
    [selectedSearchResult],
  );

  const {
    toggleRightPanel,
    openRightPanel,
    selected,
    usedProductsData,
    projectProducts,
    multiselect,
  } = useCreator();

  const isEmpty = selected.length < 1 && !selectedSearchResult;

  const creatorProducts = useMemo(
    () =>
      selected
        .filter((item) => item.type === 'product')
        .map((item) =>
          projectProducts.find((product) => product.id === item.shapeId),
        )
        .filter(Boolean),
    [projectProducts, selected],
  );

  const productSearchData = useMemo(
    () =>
      Array.from(
        new Set(
          (creatorProducts as ProductModel[]).map(
            (product) => usedProductsData[product.productId],
          ),
        ),
      ),
    [creatorProducts, usedProductsData],
  );

  const toggleProductInfo = useCallback(
    () => setUnfoldProductInfo(!unfoldProductInfo),
    [unfoldProductInfo],
  );

  const PanelContent = useMemo(() => {
    if (selected.length > 0) {
      switch (selected[0].type) {
        case 'template': {
          return <NicheForm />;
        }
        case 'glass': {
          const selectedGlass =
            selected[0]?.type === 'glass'
              ? glasses.find((item) => item.id === selected[0].shapeId)
              : undefined;

          if (!selectedGlass) {
            return null;
          }

          return <GlassForm glass={selectedGlass} glasses={glasses} />;
        }
        case 'product': {
          if (!creatorProduct) {
            return null;
          }
          return (
            <ProductForm
              productSearchData={productSearchData}
              multiselect={multiselect}
              creatorProduct={creatorProduct}
            />
          );
        }
      }
    }
    if (selectedSearchResult && searchResult) {
      return (
        <ProductInfo
          data={searchResult}
          unfold={unfoldProductInfo}
          onToggle={toggleProductInfo}
          realWidth={searchResult.product.width}
          realHeight={searchResult.product.height}
        />
      );
    }
    return <EmptyInfo />;
  }, [
    selected,
    selectedSearchResult,
    searchResult,
    glasses,
    creatorProduct,
    productSearchData,
    multiselect,
    unfoldProductInfo,
    toggleProductInfo,
  ]);

  return (
    <SidePanel
      width={360}
      onToggle={toggleRightPanel}
      open={openRightPanel}
      position={SidePanelPosition.RIGHT}>
      <Content empty={isEmpty}>{PanelContent}</Content>
    </SidePanel>
  );
};

export default PropertyPanel;
