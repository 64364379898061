import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PropertySelectItem,
  PropertySwitchItem,
} from '../containers/PropertyPanel/components/GlassProperties';
import {
  PropertyOptionValue,
  PropertySwitchOption,
} from '../containers/PropertyPanel/components/PropertySwitch';
import { PropertySelectOption } from '../containers/PropertyPanel/components/PropertySelect';
import { OptionObject } from '../../../components/Select';
import { creatorProducts, creatorProject } from '../../../modules/creator';
import {
  Dimensions,
  GlassEdges,
  GlassThickness,
  GlassTypes,
  Position,
} from '../../../types';
import { CutType, Glass } from '../../../services/api/models/glass';
import { selectOpen, useModalsStore } from '../../../store/modals';
import { ChangeThicknessDecision, ProjectModals } from '../types';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../store/notifications';
import { NotificationVariants } from '../../../components/Notification';
import { CreatorProduct } from '../../../modules/creator/store/products';
import { ProjectDimensions } from '../../../services/api/models/project';
import { Shape } from '../../../modules/space/Shape';

const {
  useProjectStore,
  selectUpdateGlass,
  selectUsedProducts,
  selectUnsafeProject,
  selectDeleteManyProducts,
} = creatorProject;

const {
  useProductsStore,
  selectProducts,
  selectDeleteManyProducts: selectDeleteManyCreatorProducts,
} = creatorProducts;

export interface UseGlassProperties {
  propertiesInputs: (PropertySelectItem | PropertySwitchItem)[];
  updatePosition(position: Partial<Position>): void;
  validateThicknessChange(option: OptionObject): Promise<boolean>;
  updateDimensions(
    corners: ProjectDimensions['corners'],
    position?: Partial<Position>,
  ): void;
  toggleAdjustManually(): void;
  thicknessValidResolve?: (decision: ChangeThicknessDecision) => void;
}

enum Properties {
  GLASS_TYPE = 'glassType',
  GLASS_THICKNESS = 'glassThickness',
  GlASS_HARDENING = 'glassHardening',
  TRAPEZOIDAL_CUT = 'trapezoidalCut',
  EDGES = 'edges',
}

type Payload = PropertySelectOption | PropertyOptionValue;

type State = Record<Properties, Payload | undefined>;

const initialState: State = {
  glassType: undefined,
  glassThickness: undefined,
  edges: undefined,
  glassHardening: 1,
  trapezoidalCut: 0,
};

let thicknessValidResolve: (decision: ChangeThicknessDecision) => void;

const isHardening = (thickness: number) =>
  thickness === 10 || thickness === 12 || thickness === 6 || thickness === 8;

const findProductsOnGlass = (
  creatorProducts: CreatorProduct[],
  selectedGlass: Glass,
) =>
  creatorProducts.filter(
    (product) =>
      product.parentType === 'glass' && product.parent.id === selectedGlass.id,
  );

const useGlassProperties = (selectedGlass: Glass): UseGlassProperties => {
  const { t } = useTranslation('project');
  const [state, setState] = useState<State>(initialState);

  const updateGlass = useProjectStore(selectUpdateGlass);
  const usedProducts = useProjectStore(selectUsedProducts);
  const removeProducts = useProjectStore(selectDeleteManyProducts);
  const unsafeProject = useProjectStore(selectUnsafeProject);
  const creatorProducts = useProductsStore(selectProducts);
  const removeCreatorProducts = useProductsStore(
    selectDeleteManyCreatorProducts,
  );
  const openModal = useModalsStore(selectOpen);
  const setNotification = useNotificationsStore(selectSetNotification);

  const updatePosition = useCallback(
    (position: Partial<Position>) => {
      updateGlass({
        ...selectedGlass,
        position: { ...selectedGlass.position, ...position },
      });
    },
    [selectedGlass, updateGlass],
  );

  const updateDimensions = useCallback(
    (corners: ProjectDimensions['corners'], position?: Partial<Position>) => {
      const newPosition = { ...selectedGlass.position, ...position };
      updateGlass({
        ...selectedGlass,
        corners,
        position: newPosition,
      });
    },
    [selectedGlass?.position, updateGlass],
  );

  const toggleAdjustManually = useCallback(() => {
    updateGlass({
      ...selectedGlass,
      adjustManually: !selectedGlass.adjustManually,
    });
  }, [selectedGlass, updateGlass]);

  const mayHardeningBeChanged = useMemo(() => {
    const productsOnGlass = findProductsOnGlass(creatorProducts, selectedGlass);
    const productsData = productsOnGlass
      .map((product) => usedProducts[product.data.productId])
      .filter((item) => typeof item !== 'undefined');
    const properties = productsData.map((item) => item.glassProperties).flat();
    return !(
      selectedGlass.thickness % 1 > 0 &&
      selectedGlass.hardening &&
      properties.some(
        (property) =>
          property.cutType === CutType.EVERY_HINGE ||
          property.cutType === CutType.BIG_INNER_ON_EDGE,
      )
    );

    return false;
  }, [creatorProducts, selectedGlass, usedProducts]);

  const validateThicknessChange = useCallback(
    async ({ option }: OptionObject) => {
      if (selectedGlass && option) {
        const productsOnGlass = findProductsOnGlass(
          creatorProducts,
          selectedGlass,
        );
        const productsData = productsOnGlass
          .map((product) => usedProducts[product.data.productId])
          .filter((item) => typeof item !== 'undefined');
        const incompatible = productsData.filter(
          (product) =>
            !product.glassProperties.some(
              (glass) => glass.thickness === Number(option.value),
            ),
        );
        if (incompatible.length > 0) {
          const modalPromise = new Promise<ChangeThicknessDecision>((res) => {
            thicknessValidResolve = (decision: ChangeThicknessDecision) => {
              res(decision);
            };
          });
          openModal(ProjectModals.GLASS_THICKNESS_CHANGE);
          const result = await modalPromise;
          if (result === 'remove') {
            const toRemove = new Set(
              incompatible
                .map((product) =>
                  productsOnGlass.filter(
                    (onGlass) => onGlass.data.productId === product.id,
                  ),
                )
                .flat()
                .map((item) => item.id),
            );
            const toRemoveFlat = Array.from(toRemove);
            removeProducts(toRemoveFlat, () => {
              removeCreatorProducts(toRemoveFlat);
            });
          }
          if (result === 'leave') {
            unsafeProject();
          }
          result !== 'cancel' &&
            setNotification({
              text: t(`notifications.glassThicknessChange.${result}`),
              variant: NotificationVariants.SUCCESS,
            });
          return result === 'leave' || result === 'remove';
        } else {
          return Promise.resolve(true);
        }
      }
      return Promise.resolve(false);
    },
    [
      creatorProducts,
      openModal,
      removeCreatorProducts,
      removeProducts,
      selectedGlass,
      setNotification,
      t,
      unsafeProject,
      usedProducts,
    ],
  );

  const onSelect = useCallback(
    (option: OptionObject) => {
      switch (option.name) {
        case Properties.GLASS_TYPE: {
          updateGlass({
            ...selectedGlass,
            panelType: option.option?.value as GlassTypes,
          });
          break;
        }
        case Properties.GLASS_THICKNESS: {
          const thickness = Number(option.option?.value) as GlassThickness;
          updateGlass({
            ...selectedGlass,
            thickness,
            hardening: true,
          });
          break;
        }
        case Properties.EDGES: {
          updateGlass({
            ...selectedGlass,
            glassEdges: option.option?.value as GlassEdges,
          });
          break;
        }
      }
    },
    [selectedGlass, updateGlass],
  );

  const onChange = useCallback(
    (value: PropertyOptionValue, name: Properties) => {
      switch (name) {
        case Properties.GlASS_HARDENING: {
          updateGlass({ ...selectedGlass, hardening: Boolean(value) });
          break;
        }
        case Properties.TRAPEZOIDAL_CUT: {
          updateGlass({ ...selectedGlass, trapezoidalCut: Boolean(value) });
        }
      }
    },
    [selectedGlass, updateGlass],
  );

  const typeOptions = useMemo<PropertySelectOption[]>(
    () => [
      {
        title: t('propertyPanel.form.glass.type.options.door', {
          value: GlassTypes.DOOR,
        }),
        value: GlassTypes.DOOR,
      },
      {
        title: t('propertyPanel.form.glass.type.options.fix', {
          value: GlassTypes.FIXED,
        }),
        value: GlassTypes.FIXED,
      },
    ],
    [t],
  );

  const thicknessOptions = useMemo<PropertySelectOption[]>(
    () => [
      {
        title: t('propertyPanel.form.glass.thickness.options.generic', {
          value: GlassThickness.G6,
        }),
        value: String(GlassThickness.G6),
      },
      {
        title: t('propertyPanel.form.glass.thickness.options.generic', {
          value: GlassThickness.G8,
        }),
        value: String(GlassThickness.G8),
      },
      {
        title: t('propertyPanel.form.glass.thickness.options.generic', {
          value: GlassThickness.G10,
        }),
        value: String(GlassThickness.G10),
      },
      {
        title: t('propertyPanel.form.glass.thickness.options.generic', {
          value: GlassThickness.G12,
        }),
        value: String(GlassThickness.G12),
      },
      {
        title: t('propertyPanel.form.glass.thickness.options.laminate', {
          value: GlassThickness.G442L,
        }),
        value: String(GlassThickness.G442L),
      },
      {
        title: t('propertyPanel.form.glass.thickness.options.laminate', {
          value: GlassThickness.G444L,
        }),
        value: String(GlassThickness.G444L),
      },
      {
        title: t('propertyPanel.form.glass.thickness.options.laminate', {
          value: GlassThickness.G552L,
        }),
        value: String(GlassThickness.G552L),
      },
      {
        title: t('propertyPanel.form.glass.thickness.options.laminate', {
          value: GlassThickness.G554L,
        }),
        value: String(GlassThickness.G554L),
      },
      {
        title: t('propertyPanel.form.glass.thickness.options.laminate', {
          value: GlassThickness.G662L,
        }),
        value: String(GlassThickness.G662L),
      },
      {
        title: t('propertyPanel.form.glass.thickness.options.laminate', {
          value: GlassThickness.G664L,
        }),
        value: String(GlassThickness.G664L),
      },
    ],
    [t],
  );

  const edgesOptions = useMemo<PropertySelectOption[]>(
    () => [
      {
        title: t('propertyPanel.form.glass.edges.options.sanded'),
        value: GlassEdges.SANDED,
      },
      {
        title: t('propertyPanel.form.glass.edges.options.sandedAndPolished'),
        value: GlassEdges.SANDED_POLISHED,
      },
    ],
    [t],
  );

  const switchOptions = useMemo<PropertySwitchOption[]>(
    () => [
      { title: t('propertyPanel.form.switchOptions.yes'), value: 1 },
      { title: t('propertyPanel.form.switchOptions.no'), value: 0 },
    ],
    [t],
  );

  const hardeningSwitch = useMemo<PropertySwitchItem>(
    () => ({
      title: t('propertyPanel.form.glass.hardening.title'),
      name: Properties.GlASS_HARDENING,
      type: 'switch',
      selected: state.glassHardening as PropertyOptionValue,
      options: switchOptions,
      disabled: !mayHardeningBeChanged,
      onChange,
    }),
    [onChange, state.glassHardening, switchOptions, t, mayHardeningBeChanged],
  );

  const trapezoidalCutSwitch = useMemo<PropertySwitchItem>(
    () => ({
      title: t('propertyPanel.form.glass.trapezoidalCut.title'),
      name: Properties.TRAPEZOIDAL_CUT,
      type: 'switch',
      selected: state.trapezoidalCut as PropertyOptionValue,
      options: switchOptions,
      onChange,
    }),
    [onChange, state.trapezoidalCut, switchOptions, t],
  );

  const typesSelect = useMemo<PropertySelectItem>(
    () => ({
      title: t('propertyPanel.form.glass.type.title'),
      name: Properties.GLASS_TYPE,
      type: 'select',
      selected: state.glassType as PropertySelectOption,
      options: typeOptions,
      onSelect,
    }),
    [onSelect, state.glassType, t, typeOptions],
  );

  const thicknessSelect = useMemo<PropertySelectItem>(
    () => ({
      title: t('propertyPanel.form.glass.thickness.title'),
      name: Properties.GLASS_THICKNESS,
      type: 'select',
      selected: state.glassThickness as PropertySelectOption,
      options: thicknessOptions,
      onSelect,
    }),
    [onSelect, state.glassThickness, t, thicknessOptions],
  );

  const edgesSelect = useMemo<PropertySelectItem>(
    () => ({
      title: t('propertyPanel.form.glass.edges.title'),
      name: Properties.EDGES,
      type: 'select',
      selected: state.edges as PropertySelectOption,
      options: edgesOptions,
      onSelect,
    }),
    [edgesOptions, onSelect, state.edges, t],
  );

  useEffect(() => {
    const newState: State = {
      [Properties.GlASS_HARDENING]:
        typeof selectedGlass.hardening !== 'undefined'
          ? Number(selectedGlass.hardening)
          : 1,
      [Properties.TRAPEZOIDAL_CUT]:
        typeof selectedGlass.trapezoidalCut !== 'undefined'
          ? Number(selectedGlass.trapezoidalCut)
          : 0,
      [Properties.GLASS_THICKNESS]: thicknessOptions.find(
        (option) => option.value === String(selectedGlass.thickness),
      ),
      [Properties.GLASS_TYPE]: typeOptions.find(
        (option) => option.value === selectedGlass.panelType,
      ),
      [Properties.EDGES]: edgesOptions.find(
        (option) => option.value === selectedGlass.glassEdges,
      ),
    };
    setState(newState);
  }, [edgesOptions, selectedGlass, thicknessOptions, typeOptions]);

  const propertiesInputs = useMemo(
    () => [
      typesSelect,
      thicknessSelect,
      hardeningSwitch,
      edgesSelect,
      trapezoidalCutSwitch,
    ],
    [
      hardeningSwitch,
      thicknessSelect,
      typesSelect,
      trapezoidalCutSwitch,
      edgesSelect,
    ],
  );

  return {
    propertiesInputs,
    updatePosition,
    updateDimensions,
    validateThicknessChange,
    toggleAdjustManually,
    thicknessValidResolve,
  };
};

export default useGlassProperties;
