import * as React from 'react';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import groupBy from 'lodash/groupBy';
import { Container, Sections } from './styles';
import { CatalogSearch } from '../../components/CatalogSearch';
import { PanelTab, PanelTabProps } from '../../components/PanelTab';
import { useTranslation } from 'react-i18next';
import {
  selectDataGroup,
  selectFetchProperties,
  selectFilterByGroup,
  selectProperties,
  selectSearch,
  selectSearchResults,
  selectSelectedResult,
  selectSelectResult,
  useFilterProductsStore,
  useProductsStore,
  useSearchProductsStore,
} from '../../../../../../store/products';
import {
  GetProductsCommand as GroupsFilter,
  ProductProperty,
} from '../../../../../../services/api/models/product';
import { CatalogItem } from '../../components/CatalogItem';
import { SelectOption } from '../../../../../../components/Select';
import { useDebounce } from '../../../../../../hooks';
import { SearchResults } from '../../components/SearchResults';
import { useCreator, useDragActions } from '../../../../hooks';
import { ProductSearch } from '../../../../../../services/api/models/product';

export interface Props {}

type Section = { id: number } & Pick<PanelTabProps, 'title' | 'renderer'>;

enum ActionTypes {
  SET_ACTIVE_SECTIONS,
  SET_ACTIVE_CATEGORIES,
  SET_ACTIVE_SERIES,
  SET_ACTIVE_CATEGORY_SERIES,
  SET_SEARCH_FILTERS,
  TOGGLE_SEARCH_FILTERS,
  SET_SEARCH_PHRASE,
}

interface Action {
  type: ActionTypes;
}

interface ActivateAction extends Action {
  payload: (string | number)[];
}

interface FilterAction extends Action {
  payload: SelectOption[];
}

interface CommonAction extends Action {
  payload: boolean | string;
}

interface State {
  activeSections: number[];
  activeCategories: number[];
  activeSeries: number[];
  activeCategorySeries: number[];
  searchFilters: SelectOption[];
  showSearchFilters: boolean;
  searchPhrase: string;
}

const initState: State = {
  activeCategories: [],
  activeCategorySeries: [],
  activeSections: [],
  activeSeries: [],
  searchFilters: [],
  showSearchFilters: false,
  searchPhrase: '',
};

const stateReducer = (
  state: State,
  action: ActivateAction | FilterAction | CommonAction,
): State => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_CATEGORIES: {
      return {
        ...state,
        activeCategories: action.payload as number[],
      };
    }
    case ActionTypes.SET_ACTIVE_CATEGORY_SERIES: {
      return {
        ...state,
        activeCategorySeries: action.payload as number[],
      };
    }
    case ActionTypes.SET_ACTIVE_SECTIONS: {
      return {
        ...state,
        activeSections: action.payload as number[],
      };
    }
    case ActionTypes.SET_ACTIVE_SERIES: {
      return {
        ...state,
        activeSeries: action.payload as number[],
      };
    }
    case ActionTypes.SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload as SelectOption[],
      };
    }
    case ActionTypes.TOGGLE_SEARCH_FILTERS: {
      return {
        ...state,
        showSearchFilters: action.payload as boolean,
      };
    }
    case ActionTypes.SET_SEARCH_PHRASE: {
      return {
        ...state,
        searchPhrase: action.payload as string,
      };
    }
    default: {
      return state;
    }
  }
};

const CatalogTab: React.FC<Props> = () => {
  const { t } = useTranslation('catalog');
  const fetchProperties = useProductsStore(selectFetchProperties);
  const { series, categories, materialTypes } = useProductsStore(
    selectProperties,
  );
  const filterByGroup = useFilterProductsStore(selectFilterByGroup);
  const searchProducts = useSearchProductsStore(selectSearch);
  const results = useSearchProductsStore(selectSearchResults);
  const selectedResult = useSearchProductsStore(selectSelectedResult);
  const selectItem = useSearchProductsStore(selectSelectResult);
  const categoriesData = useFilterProductsStore(selectDataGroup('category'));
  const seriesData = useFilterProductsStore(selectDataGroup('series'));
  const { deselect: deselectCreator } = useCreator();
  const {
    handleDragStart,
    handleDragEnd,
    handleDrag,
    dragging,
    dragId,
  } = useDragActions();
  const [state, dispatch] = useReducer(stateReducer, initState);

  const toggleTab = useCallback(
    async (
      product: ProductProperty,
      set: (number | string)[],
      setter: (set: (number | string)[]) => void,
      groupToFetch?: keyof GroupsFilter,
    ) => {
      if (set.includes(product.id)) {
        return setter(set.filter((value) => value !== product.id));
      }
      if (groupToFetch) {
        await filterByGroup(groupToFetch, product);
      }
      return setter([...set, product.id]);
    },
    [filterByGroup],
  );

  const toggleActiveSection = useCallback(
    (product: ProductProperty) =>
      toggleTab(product, state.activeSections, (payload) =>
        dispatch({ type: ActionTypes.SET_ACTIVE_SECTIONS, payload }),
      ),
    [state.activeSections, toggleTab],
  );

  const toggleActiveSeries = useCallback(
    (product: ProductProperty) =>
      toggleTab(
        product,
        state.activeSeries,
        (payload) => dispatch({ type: ActionTypes.SET_ACTIVE_SERIES, payload }),
        'series',
      ),
    [state.activeSeries, toggleTab],
  );

  const toggleActiveCategories = useCallback(
    (product: ProductProperty) =>
      toggleTab(
        product,
        state.activeCategories,
        (payload) =>
          dispatch({ type: ActionTypes.SET_ACTIVE_CATEGORIES, payload }),
        'category',
      ),
    [state.activeCategories, toggleTab],
  );

  const toggleActiveCategorySeries = useCallback(
    (product: ProductProperty) =>
      toggleTab(product, state.activeCategorySeries, (payload) =>
        dispatch({ type: ActionTypes.SET_ACTIVE_CATEGORY_SERIES, payload }),
      ),
    [state.activeCategorySeries, toggleTab],
  );

  const toggleSearchFilters = useCallback(
    () =>
      dispatch({
        type: ActionTypes.TOGGLE_SEARCH_FILTERS,
        payload: !state.showSearchFilters,
      }),
    [state.showSearchFilters],
  );

  const selectSearchFilters = useCallback(
    async (payload: SelectOption[]) => {
      dispatch({ type: ActionTypes.SET_SEARCH_FILTERS, payload });
      await searchProducts(
        state.searchPhrase,
        payload.map((option) => ({
          id: parseInt(option.value),
          name: option.title,
        })),
      );
    },
    [searchProducts, state.searchPhrase],
  );

  const removeSearchFilter = useCallback(
    (option: SelectOption) => {
      dispatch({
        type: ActionTypes.SET_SEARCH_FILTERS,
        payload: state.searchFilters.filter(
          (selected) => selected.value !== option.value,
        ),
      });
    },
    [state.searchFilters],
  );

  const onSearch = useCallback(
    async (value: string) => {
      dispatch({ type: ActionTypes.SET_SEARCH_PHRASE, payload: value });

      if (value.length > 1) {
        await searchProducts(
          value,
          state.searchFilters.map((option) => ({
            id: parseInt(option.value),
            name: option.title,
          })),
        );
      }
    },
    [searchProducts, state.searchFilters],
  );

  const onItemSelect = useCallback(
    (item: ProductSearch) => {
      deselectCreator();
      selectItem(item);
    },
    [deselectCreator, selectItem],
  );

  const debouncedSearch = useDebounce(onSearch);

  const itemsRender = useCallback(
    (type: 'series' | 'categories', name: string) => {
      if (type === 'series' && seriesData) {
        return seriesData[name]
          ?.sort((a, b) => a.code.localeCompare(b.code))
          .map((item) => (
            <CatalogItem
              draggable
              key={item.id}
              data={item}
              onSelect={onItemSelect}
              isSelected={selectedResult?.id === item.id}
              currentDragId={dragId}
              dragging={dragging}
              onDragStart={handleDragStart}
              onDrag={handleDrag}
              onDragEnd={handleDragEnd}
            />
          ));
      }
      if (type === 'categories' && categoriesData) {
        const data = categoriesData[name];
        if (data) {
          return Object.entries(groupBy(data, 'series')).map(
            ([seriesName, category]) => {
              const categorySeries = series.find(
                (item) => item.name === seriesName,
              );

              if (!categorySeries) return null;

              return (
                <PanelTab
                  key={categorySeries.id}
                  id={categorySeries.id}
                  title={categorySeries.name}
                  active={state.activeCategorySeries.includes(
                    categorySeries.id,
                  )}
                  renderer={() =>
                    category.map((item) => (
                      <CatalogItem
                        draggable
                        key={item.id}
                        level={2}
                        data={item}
                        onSelect={onItemSelect}
                        isSelected={selectedResult?.id === item.id}
                        currentDragId={dragId}
                        dragging={dragging}
                        onDragStart={handleDragStart}
                        onDrag={handleDrag}
                        onDragEnd={handleDragEnd}
                      />
                    ))
                  }
                  onClick={toggleActiveCategorySeries}
                  level={2}
                />
              );
            },
          );
        }
      }
      return null;
    },
    [
      series,
      seriesData,
      categoriesData,
      onItemSelect,
      selectedResult?.id,
      dragId,
      dragging,
      handleDragStart,
      handleDrag,
      handleDragEnd,
      state.activeCategorySeries,
      toggleActiveCategorySeries,
    ],
  );

  const seriesRender = useCallback(
    () =>
      series.map((series) => (
        <PanelTab
          key={series.id}
          id={series.id}
          title={series.name}
          active={state.activeSeries.includes(series.id)}
          renderer={() => itemsRender('series', series.name)}
          onClick={toggleActiveSeries}
        />
      )),
    [state.activeSeries, itemsRender, series, toggleActiveSeries],
  );

  const categoriesRender = useCallback(
    () =>
      categories.map((category) => (
        <PanelTab
          key={category.id}
          id={category.id}
          title={category.name}
          active={state.activeCategories.includes(category.id)}
          renderer={() => itemsRender('categories', category.name)}
          onClick={toggleActiveCategories}
        />
      )),
    [state.activeCategories, categories, itemsRender, toggleActiveCategories],
  );

  const sections = useMemo<Section[]>(
    () => [
      {
        id: 0,
        title: t('list.filters.series'),
        renderer: seriesRender,
      },
      {
        id: 1,
        title: t('list.filters.category'),
        renderer: categoriesRender,
      },
    ],
    [categoriesRender, seriesRender, t],
  );

  useEffect(() => {
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {materialTypes && (
        <CatalogSearch
          onSearch={debouncedSearch}
          onFilter={selectSearchFilters}
          onRemoveFilter={removeSearchFilter}
          toggleFilters={toggleSearchFilters}
          materialTypes={materialTypes}
          showFilters={state.showSearchFilters}
          selected={state.searchFilters}
        />
      )}
      {state.searchPhrase.length > 0 && results ? (
        <SearchResults
          results={results}
          onSelect={onItemSelect}
          selected={selectedResult}
          dragId={dragId}
          dragging={dragging}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
        />
      ) : (
        <Sections>
          {sections.map((section) => (
            <PanelTab
              key={section.id}
              {...section}
              active={state.activeSections.includes(section.id)}
              onClick={toggleActiveSection}
            />
          ))}
        </Sections>
      )}
    </Container>
  );
};

export default CatalogTab;
