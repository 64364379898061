import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AxiosResponse } from 'axios';

import { CompanyMemberSearch } from '../../services/api/models/companyMember';
import { FilterRequest } from '../../services/api/services/company';
import { companyApi, companyAdminApi } from '../../services/api/services';
import { Page } from '../../services/api/models/requests';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/companyMembers/filter`;

export type State = {
  loading: boolean;
  filter(admin?: boolean, command?: FilterRequest): Promise<void>;
  filterByCompanyId(id: number, command?: FilterRequest): Promise<void>;
  data?: Page<CompanyMemberSearch>;
  error?: AxiosResponse;
};

const filterAction = (set: SetState<State>) => (
  admin?: boolean,
  command?: FilterRequest,
) =>
  createAsyncAction(set)(async () => {
    const { data } = admin
      ? await companyAdminApi.filterAllCompanyMembers(command)
      : await companyApi.filterCompanyMembers(command);
    set({ data });
  });

const filterByCompanyIdAction = (set: SetState<State>) => (
  id: number,
  command?: FilterRequest,
) =>
  createAsyncAction(set)(async () => {
    const { data } = await companyAdminApi.getCompanyMembersById(id, command);
    set({ data });
  });

const store = (set: SetState<State>) => ({
  loading: false,
  filter: filterAction(set),
  filterByCompanyId: filterByCompanyIdAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
