import * as React from 'react';

const SvgUp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <rect
        x={19}
        y={4}
        width={2}
        height={14}
        rx={1}
        transform="rotate(90 19 4)"
        fill="#828A98"
      />
      <rect
        x={9}
        y={18}
        width={2}
        height={10}
        rx={1}
        transform="rotate(-180 9 18)"
        fill={props.fill ?? '#272B5F'}
      />
      <rect
        x={17}
        y={18}
        width={2}
        height={10}
        rx={1}
        transform="rotate(-180 17 18)"
        fill={props.fill ?? '#272B5F'}
      />
      <rect
        x={13}
        y={20}
        width={2}
        height={12}
        rx={1}
        transform="rotate(-180 13 20)"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgUp = React.memo(SvgUp);
export default MemoSvgUp;
