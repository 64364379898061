import * as React from 'react';

const SvgPatterns = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={187}
      height={125}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={36.926}
        width={114.99}
        height={114.99}
        rx={14}
        transform="rotate(4.644 36.926 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#patterns_svg__filter0_d)">
        <rect x={27} y={17} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={69} y={28} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={69} y={43} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={34} y={23} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M52 31h-8a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-8a2 2 0 00-2-2zm-8 1.333h8a.667.667 0 01.667.667v1.333h-9.334V33a.667.667 0 01.667-.667zM43.333 41v-5.333h4v6H44a.667.667 0 01-.667-.667zm8.667.667h-3.333v-6h4V41a.667.667 0 01-.667.667z"
        fill="#fff"
      />
      <g filter="url(#patterns_svg__filter1_d)">
        <rect x={10} y={65} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={52} y={76} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={52} y={91} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={17} y={71} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M35 79h-8a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-8a2 2 0 00-2-2zm-8 1.333h8a.667.667 0 01.667.667v1.333h-9.334V81a.667.667 0 01.667-.667zM26.333 89v-5.333h4v6H27a.667.667 0 01-.667-.667zm8.667.667h-3.333v-6h4V89a.667.667 0 01-.667.667z"
        fill="#fff"
      />
      <defs>
        <filter
          id="patterns_svg__filter0_d"
          x={17}
          y={17}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="patterns_svg__filter1_d"
          x={0}
          y={65}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgPatterns = React.memo(SvgPatterns);
export default MemoSvgPatterns;
