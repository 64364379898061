import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const modal = css`
  display: flex;
  background: ${({ theme }) => theme.colors.white};
`;

export const Container = styled.div<{
  backgroundColor: string;
  alignItems: 'center' | 'flex-start';
}>`
  display: flex;
  background: ${({ backgroundColor }) => backgroundColor};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: center;
  padding: 84px 114px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 90;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 68px;
  right: 94px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.grey3};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.66;
  }
  svg {
    > path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export const ContentWrapper = styled.div``;

const fullWidthModal = css`
  width: 50vw;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop - 1}px) {
    width: 90vw;
  }
`;

export const BottomModalContainer = styled.div<{ fullWidth?: boolean }>`
  ${modal};
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 10px 10px rgba(39, 43, 95, 0.08);
  padding: 12px 24px;
  max-width: 50vw;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop - 1}px) {
    max-width: 90vw;
  }
  ${({ fullWidth }) => fullWidth && fullWidthModal};
`;

export const ModalTitle = styled.div`
  padding: 12px 0 16px;
`;

export const BottomModalContentWrapper = styled.div``;

export const BottomProvider = styled(animated.div)`
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  z-index: 95;
`;

export const ProviderContainer = styled.div``;
