export type InsertValue = number | string;

export enum InsertType {
  NICHE,
  GLASS,
  GAP,
}

export interface InsertOption {
  type: InsertType;
  value: InsertValue;
  title: string;
  label?: string;
}

export interface InsertCategory {
  title: string;
  options: InsertOption[];
  niche?: boolean;
  disabled?: boolean;
  disabledReason?: string;
}
