import create, { SetState } from 'zustand';
import createVanilla from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';
import { NotificationVariants } from '../../components/Notification';

export const STORE_NAME = `@store/notifications`;

export interface NotificationConfig {
  text: string;
  variant: NotificationVariants;
  timeout?: number;
}

export type State = {
  show: boolean;
  text?: string;
  variant?: NotificationVariants;
  timeout?: number;
  dismiss(): void;
  setNotification(config: NotificationConfig): void;
};

const store = (set: SetState<State>) => ({
  show: false,
  dismiss: () => set({ show: false, timeout: undefined }),
  setNotification: (config: NotificationConfig) =>
    set({ show: true, timeout: 5000, ...config }),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
