export enum RootRoutes {
  HOME = '/',
  LOGIN = '/login',
  PROJECTS = '/projects',
  CATALOG = '/catalog',
  CLIENTS = '/clients',
  OFFERS = '/offers',
  ORDERS = '/orders',
  USERS = '/users',
  PARAMS = '/params',
  PROFILE = '/profile',
  PROJECT = '/project',
  TEMPLATE = '/template',
  CONTRACTORS = '/contractors',
  TEMPLATES = '/templates',
}

export enum LoginRoutes {
  RESET_PW_REQUEST = '/login/reset',
  RESET_PW = '/login/reset/:token/:email',
}

export enum DashboardRoutes {
  MAIN = '/',
  TEMPLATES = '/dashboard/templates',
  SAVED = '/dashboard/saved',
  SUPPORT = '/dashboard/support',
}

export enum ProjectRoutes {
  CREATE = '/project',
  EDIT = '/project/:id',
}

export enum TemplateRoutes {
  CREATE = '/template',
  EDIT = '/template/:id',
}

export enum OrdersRoutes {
  ORDER_DETAILS = '/orders/:id',
}

export enum OffersRoutes {
  OFFER_DETAILS = '/offers/:id',
}

export enum ClientsRoutes {
  CLIENT_DETAILS = '/clients/:id',
}

export enum ErrorsRoutes {
  PAGE_404 = '/404',
  PAGE_500 = '/500',
}

export enum EmailRoutes {
  CHANGE_CONFIRM = '/email/confirm/:token/:email',
}

export enum ContractorsRoutes {
  CONTRACTORS_DETAILS = '/contractors/:id',
}
