import styled, { css } from 'styled-components';

export const Container = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-sizing: border-box;
  width: ${({ width }) =>
    typeof width !== 'undefined' ? `${width}px` : 'fit-content'};
  padding: 4px 0;
  overflow: visible;
`;

const active_option = css`
  background: ${({ theme }) => theme.colors.accentBlue3};
  pointer-events: none;

  > span {
    color: ${({ theme }) => theme.colors.accentBlue};
    font-weight: 700;
  }
`;

const inactive_option = css`
  > span {
    color: ${({ theme }) => theme.colors.mainBlue};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.grey4};
  }
`;

const disabled_option = css<{
  active?: boolean;
}>`
  pointer-events: none;

  & > span {
    color: ${({ theme, active }) =>
      active ? theme.colors.accentBlue : theme.colors.grey2};
  }
`;

export const Option = styled.div<{
  active?: boolean;
  fixedParentWidth?: boolean;
  disabled?: boolean;
}>`
  box-sizing: border-box;
  display: inline-block;
  padding: 8px 13px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 4px;
  width: ${({ fixedParentWidth }) =>
    fixedParentWidth ? '100%' : 'fit-content'};
  text-align: center;
  ${({ active }) => (active ? active_option : inactive_option)};
  ${({ disabled }) => disabled && disabled_option}
`;

export const IconSwitchContainer = styled(Container)`
  padding: 0;
`;

const active_icon_option = css`
  opacity: 1;
  background: ${({ theme }) => theme.colors.grey4};
`;

const disabled_icon_option = css`
  background-color: ${({ theme }) => theme.colors.grey5};
  pointer-events: none;
`;

export const IconOption = styled.div<{ active?: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  opacity: 0.5;

  &:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  }

  &:hover {
    ${active_icon_option};
  }

  > div {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  > svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  ${({ active }) => active && active_icon_option}
  ${({ disabled }) => disabled && disabled_icon_option}
`;

export const PointerSwitchContainer = styled(Container)`
  height: 40px;
  width: 100px;
`;

const active_pointer_option = css`
  pointer-events: none;

  > svg {
    path {
      fill: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;

export const PointerSwitchOption = styled(Option)`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  > svg {
    height: 20px;

    path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }

  &:hover {
    > svg {
      path {
        fill: ${({ theme }) => theme.colors.accentBlue};
      }
    }
  }

  ${({ active }) => active && active_pointer_option}
`;
