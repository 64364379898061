import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import createVanilla from 'zustand/vanilla';
import { NotificationVariants } from '../../../../components/Notification';
import {
  Connection,
  ConnectionType,
} from '../../../../services/api/models/connection';
import i18n from '../../../../services/i18n/config';
import { notificationStore } from '../../../../store/notifications';
import { CONNECT_OFFSET } from '../../components/Pipe';
import { DEFAULT_RATIO } from '../config';
import { CreatorProduct, ProductChild, productsStore } from '../products';
import { projectStore } from '../project';
import { getCoordinatesForRect } from '../../../../utils/shape';

export const STORE_NAME = `@store/creator/connect`;

type Set = SetState<State>;
type Get = GetState<State>;

export type State = {
  subject?: CreatorProduct;
  setSubject(subject: CreatorProduct): void;
  connect(target: CreatorProduct): void;
  cancel(): void;
};

const setSubjectAction = (set: Set, get: Get) => (subject: CreatorProduct) => {
  const { cancel } = get();
  set({ subject });
  document.addEventListener('contextmenu', cancel);
  notificationStore.getState().setNotification({
    text: i18n.t('project:notifications.selectConnector'),
    variant: NotificationVariants.INFO,
    timeout: 0,
  });
};

const connectAction = (set: Set, get: Get) => (target: CreatorProduct) => {
  const { products } = productsStore.getState();
  const { subject, cancel } = get();
  if (subject) {
    const connection: Connection = {
      type: ConnectionType.PRODUCT,
      position: target.data.position,
      targetId: target.data.id,
      anchor: 'product',
    };
    const { data } = subject;
    const newConnectionSet = [...data.connections, connection];
    const [minConnection] = newConnectionSet.sort(
      (a, b) => a.position.x - b.position.x,
    );
    const minProd =
      minConnection &&
      products.find((item) => item.id === minConnection.targetId);
    if (minProd) {
      let width = data.width;
      const x = minProd.data.position.x + minProd.data.width - CONNECT_OFFSET;
      const productConnected = newConnectionSet.filter(
        (connect) => connect.type === ConnectionType.PRODUCT,
      );
      if (productConnected.length > 1) {
        const items = productConnected
          .map((connect) =>
            products.find((product) => connect.targetId === product.id),
          )
          .filter(Boolean)
          .sort(
            (a, b) =>
              (a as ProductChild).data.position.x -
              (b as ProductChild).data.position.x,
          );
        if (items.length > 0) {
          const [minItem] = items;
          const xPositions = items.map(
            (item) => (item as ProductChild).data.position.x,
          );
          const minX = Math.min(...xPositions);
          const maxX = Math.max(...xPositions);
          width =
            maxX -
            minX -
            (minItem as ProductChild).data.width * DEFAULT_RATIO +
            CONNECT_OFFSET * DEFAULT_RATIO * 2;
        }
      }
      const updatedProduct = {
        ...data,
        width,
        corners: getCoordinatesForRect(width, data.height),
        position: { ...data.position, x },
        connections: newConnectionSet,
      };
      projectStore.getState().updateProduct(updatedProduct);
    }
    set({ subject: undefined });
    notificationStore.getState().dismiss();
    document.removeEventListener('contextmenu', cancel);
  }
};

const cancelAction = (set: Set, get: Get) => () => {
  const { cancel } = get();
  set({ subject: undefined });
  notificationStore.getState().dismiss();
  document.removeEventListener('contextmenu', cancel);
};

const store = (set: Set, get: Get): State => ({
  setSubject: setSubjectAction(set, get),
  connect: connectAction(set, get),
  cancel: cancelAction(set, get),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
