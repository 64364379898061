import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AxiosResponse } from 'axios';

import {
  AddProductCommandSubmit,
  AllProductProperties,
  EditGlassPropertyCommand,
  Product,
  ProductErrorResponse,
} from '../../services/api/models/product';
import { productAdminApi, productApi } from '../../services/api/services';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/products/product`;

export type State = {
  loading: boolean;
  properties: AllProductProperties;
  fetch(id: number, onError?: () => void): Promise<void>;
  create(
    command: AddProductCommandSubmit,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  update(
    id: number,
    command: AddProductCommandSubmit,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  updateGlassProperty(
    id: number,
    command: EditGlassPropertyCommand[],
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  fetchProperties(): Promise<void>;
  resetErrors(): void;
  data?: Product;
  error?: AxiosResponse<ProductErrorResponse[]>;
};

const fetchAction = (set: SetState<State>) => (
  id: number,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    const { data } = await productApi.getProduct(id);
    const glassPreparation = data.productProperties.glassProperties;
    if (glassPreparation.length !== 0) {
      glassPreparation.sort((a, b) => a.thickness - b.thickness);
    }
    set({ data });
  }, onError);

const createAction = (set: SetState<State>) => async (
  command: AddProductCommandSubmit,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  try {
    await productAdminApi.createProduct(command);
    onSuccess?.();
  } catch (e) {
    set({ error: e.response });
    onError?.();
  }
};

const updateAction = (set: SetState<State>) => async (
  id: number,
  command: AddProductCommandSubmit,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  try {
    await productAdminApi.updateProduct(id, command);
    onSuccess?.();
  } catch (e) {
    set({ error: e.response });
    onError?.();
  }
};

const updateGlassPropertyAction = (set: SetState<State>) => (
  id: number,
  command: EditGlassPropertyCommand[],
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      await productApi.updateGlassProperty(id, command);
    },
    onError,
    onSuccess,
  );

const fetchPropertiesAction = (set: SetState<State>) => () =>
  createAsyncAction(set)(async () => {
    const { data } = await productApi.getProductProperties();
    set({ properties: data });
  });

const resetErrorsAction = (set: SetState<State>) => () =>
  set({ error: undefined });

const initialProperties: AllProductProperties = {
  series: [],
  categories: [],
  types: [],
  mountTypes: [],
  materials: [],
  materialTypes: [],
  shapes: [],
};

const store = (set: SetState<State>) => ({
  loading: false,
  properties: initialProperties,
  fetch: fetchAction(set),
  create: createAction(set),
  update: updateAction(set),
  updateGlassProperty: updateGlassPropertyAction(set),
  fetchProperties: fetchPropertiesAction(set),
  resetErrors: resetErrorsAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
