import * as React from 'react';

const SvgPassword = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M17 8h-1V6.11a4 4 0 10-8 0V8H7a3 3 0 00-3 3v8a3 3 0 003 3h10a3 3 0 003-3v-8a3 3 0 00-3-3zm-7-1.89A2.06 2.06 0 0112 4a2.06 2.06 0 012 2.11V8h-4V6.11zM18 19a1 1 0 01-1 1H7a1 1 0 01-1-1v-8a1 1 0 011-1h10a1 1 0 011 1v8z"
        fill={props.fill ?? '#272B5F'}
      />
      <path
        d="M12 12a3 3 0 100 5.999A3 3 0 0012 12zm0 4a1 1 0 110-2 1 1 0 010 2z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgPassword = React.memo(SvgPassword);
export default MemoSvgPassword;
