import styled from 'styled-components';
import { layout } from '../../../../../../../styles';

export const AngleWrapper = styled(layout.Row)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 14px;
  padding: 14px 0;
`;

export const AngleItem = styled(layout.Row)`
  width: 100%;

  > div:first-child {
    margin-left: 30px;
    margin-right: 10px;
  }

  > span {
    color: ${({ theme }) => theme.colors.dark};
    margin-top: 14px;
  }
`;
