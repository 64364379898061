import * as React from 'react';
import { Container, Input, Box } from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { ChangeEvent, useCallback, useMemo, useRef } from 'react';

export interface CheckboxValue {
  value: string;
  checked: boolean;
}

export interface Props {
  name: string;
  value: string;
  onChange?(value: CheckboxValue, name: string): void;
  checked?: boolean;
  label?: string;
  invalid?: boolean;
  disabled?: boolean;
}

const Checkbox: React.FC<Props> = React.memo(
  ({ name, label, value, onChange, checked, invalid, disabled }) => {
    const inputId = useMemo(() => `${name}-${value}-checkbox`, [name, value]);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) =>
        onChange &&
        onChange({ value: e.target.value, checked: e.target.checked }, name),
      [onChange, name],
    );

    return (
      <Container disabled={disabled} role="checkbox">
        <Input
          id={inputId}
          name={name}
          type="checkbox"
          checked={checked}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          invalid={invalid}
        />
        <Box data-testid="checkbox-box" />
        {label && (
          <Typography variant={TypographyVariants.H5}>{label}</Typography>
        )}
      </Container>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
