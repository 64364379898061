import * as React from 'react';

const SvgGuide = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={124}
      height={124}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={9.311}
        width={114.99}
        height={114.99}
        rx={14}
        transform="rotate(4.644 9.31 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#guide_svg__filter0_d)">
        <rect x={20} y={12} width={53} height={91} rx={4} fill="#fff" />
      </g>
      <path
        d="M47 48a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16.001A8 8 0 0147 66z"
        fill="#272B5F"
      />
      <path
        d="M47 52a3.5 3.5 0 00-3.5 3.5 1 1 0 002 0A1.5 1.5 0 1147 57a1 1 0 00-1 1v2a1 1 0 002 0v-1.16A3.49 3.49 0 0047 52zM47 64a1 1 0 100-2 1 1 0 000 2z"
        fill="#272B5F"
      />
      <g filter="url(#guide_svg__filter1_d)">
        <rect x={75} y={12} width={29} height={91} rx={4} fill="#326DE3" />
      </g>
      <defs>
        <filter
          id="guide_svg__filter0_d"
          x={10}
          y={12}
          width={73}
          height={111}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="guide_svg__filter1_d"
          x={65}
          y={12}
          width={49}
          height={111}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgGuide = React.memo(SvgGuide);
export default MemoSvgGuide;
