import { State } from './resetPassword';

export const selectInit = (state: State) => state.init;

export const selectError = (state: State) => state.error;

export const selectLoading = (state: State) => state.loading;

export const selectInitReset = (state: State) => state.initReset;

export const selectVerifyReset = (state: State) => state.verifyReset;

export const selectEmail = (state: State) => state.email;

export const selectSetEmail = (state: State) => state.setEmail;

export const selectFinishReset = (state: State) => state.finishReset;

export const selectFinishForcedReset = (state: State) =>
  state.finishForcedReset;

export const selectResetPasswordByAdmin = (state: State) =>
  state.resetPasswordByAdmin;

export const selectPasswordChanged = (state: State) => state.passwordChanged;

export const selectForcedPasswordChanged = (state: State) =>
  state.forcedPasswordChanged;
