import { useMemo } from 'react';
import useCurrentLang from './useCurrentLang';
import { Locales } from '../services/i18n';

const DEFAULT_CURRENCY = process.env.REACT_APP_CURRENCY || 'PLN';

const langTags: Record<Locales, string> = {
  enGB: 'en-GB',
  pl: 'pl-PL',
  ru: 'ru-RU',
  it: 'it-IT',
};

export function useAmountWithCurrency(
  amount: number,
  currency: string = DEFAULT_CURRENCY,
): string {
  const { currentLang } = useCurrentLang();

  const formatter = useMemo(() => {
    const locales = langTags[currentLang];
    return new Intl.NumberFormat(locales, {
      currency,
      style: 'currency',
    });
  }, [currency, currentLang]);

  const formattedAmount = useMemo(() => {
    return formatter.format(amount);
  }, [amount, formatter]);

  return formattedAmount;
}

export default useAmountWithCurrency;
