import * as React from 'react';

const SvgLeftBg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M13.83 19a1 1 0 01-.78-.37l-4.83-6a1 1 0 010-1.27l5-6a1.001 1.001 0 111.54 1.28L10.29 12l4.32 5.36a1 1 0 01-.78 1.64z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgLeftBg = React.memo(SvgLeftBg);
export default MemoSvgLeftBg;
