import styled from 'styled-components';
import { Input } from '../../../../../../components/Input';

export const Container = styled.div`
  > span {
    display: inline-block;
    margin-bottom: 16px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PositionInput = styled(Input)`
  height: 40px;
  margin: 5px;
`;

export const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-bottom: 12px;
  margin-top: 12px;
`;

export const InputsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PositionSpacingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`;
