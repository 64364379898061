import { useCallback, useEffect } from 'react';
import { useProjectStore } from '../../../store/projects';
import { useTranslation } from 'react-i18next';
import {
  ProjectModules,
  ProjectStatus,
} from '../../../services/api/models/project';
import { creatorProject, creatorTemplate } from '../../../modules/creator';
import useCreateReducer, {
  Actions,
  State as CreateState,
} from './useCreateReducer';
import { useTemplateStore } from '../../../store/templates';
import { CreateProjectTemplateCommand } from '../../../services/api/services/templates';
import { setProject } from '../../../utils/projectDto';
import { selectSetConfigured } from '../../../modules/creator/store/template';

export type State = CreateState;

export interface UseCreateProjectConfig {
  onCancel(): void;
  onCreated?(): void;
  runProcess?: boolean;
  templateId?: number;
  createProjectTemplate?: boolean;
}

const {
  useTemplateStore: useCreatorTemplateStore,
  selectSetup: selectSetupTemplate,
} = creatorTemplate;
const { projectStore } = creatorProject;

let interval: ReturnType<typeof setInterval>;

const useCreateProject = ({
  onCancel,
  onCreated,
  runProcess,
  templateId,
  createProjectTemplate,
}: UseCreateProjectConfig): State => {
  const { t } = useTranslation('project');
  const { create, fromTemplate } = useProjectStore();
  const { create: createTemplate } = useTemplateStore();
  const setConfigured = useCreatorTemplateStore(selectSetConfigured);
  const setupTemplate = useCreatorTemplateStore(selectSetupTemplate);

  const [state, dispatch] = useCreateReducer();

  const onSuccess = useCallback(
    (interval: ReturnType<typeof setInterval>) => {
      clearInterval(interval);
      setupTemplate();
      dispatch({
        type: Actions.SET_PROGRESS,
        progress: 100,
      });
      setConfigured();
      dispatch({ type: Actions.FINISH });
      onCreated?.();
    },
    [dispatch, onCreated],
  );

  const onError = useCallback(
    (interval: ReturnType<typeof setInterval>) => {
      const { clear: clearProject } = projectStore.getState();
      clearInterval(interval);
      clearProject();
      onCancel();
    },
    [onCancel],
  );

  const createProjectRequest = useCallback(
    async (interval: ReturnType<typeof setInterval>) => {
      const commandBase = {
        name: t('unknown'),
        projectStatus: ProjectStatus.DRAFT,
      };
      if (typeof templateId !== 'undefined') {
        await fromTemplate(
          {
            ...commandBase,
            templateId,
          },
          () => onSuccess(interval),
          () => onError(interval),
        );
      } else {
        const { project } = projectStore.getState();
        if (project) {
          await create(
            {
              ...commandBase,
              data: setProject({
                ...project.data,
              }),
            },
            () => onSuccess(interval),
            () => onError(interval),
          );
        } else {
          throw new Error('Missing project data!');
        }
      }
    },
    [create, fromTemplate, onError, onSuccess, t, templateId],
  );

  const createTemplateRequest = useCallback(
    async (interval: ReturnType<typeof setInterval>) => {
      const { project } = projectStore.getState();
      if (project) {
        const command: CreateProjectTemplateCommand = {
          name: t('unknown'),
          data: setProject({ ...project.data }),
          module: ProjectModules.SHOWER_CABINS,
        };
        await createTemplate(
          command,
          () => onSuccess(interval),
          () => onError(interval),
        );
      }
    },
    [createTemplate, onError, onSuccess, t],
  );

  const createRequest = useCallback(
    async (interval: ReturnType<typeof setInterval>) => {
      if (createProjectTemplate) {
        await createTemplateRequest(interval);
      } else {
        await createProjectRequest(interval);
      }
    },
    [createProjectRequest, createProjectTemplate, createTemplateRequest],
  );

  const createProject = useCallback(async () => {
    let currentProgress = 5;
    dispatch({ type: Actions.START });
    interval = setInterval(() => {
      if (currentProgress <= 90) {
        currentProgress = currentProgress + 5;
        dispatch({
          type: Actions.SET_PROGRESS,
          progress: currentProgress,
        });
      } else {
        clearInterval(interval);
      }
    }, 200);
    await createRequest(interval);
  }, [createRequest, dispatch]);

  useEffect(() => {
    if (runProcess) {
      createProject();
    }
    if (typeof interval !== 'undefined') {
      return () => clearInterval(interval);
    }
  }, [createProject, runProcess]);

  return { ...state };
};

export default useCreateProject;
