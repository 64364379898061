import { Button, ButtonTypes, IconButton } from '../Button';
import { Check, Save } from '../icons';
import { CreateOrderWrapper, Section } from './styles';
import { Tooltip } from '../Tooltip';
import { CreateOrderMenu } from '../CreateOrderMenu';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ToolbarButtonsParams {
  canSaveTemplate?: boolean;
  shrink?: boolean;
  lastSaveDateString?: string;
  disableSave?: boolean;
  disableCreateOrder?: boolean;
  showCreatingMenu?: boolean;
  disableSaveTemplate?: boolean;
  revalidateProject: boolean;
  onRevalidateProject: () => void;
  onOfferSelect: () => void;
  onOrderSelect: () => void;
  onSaveTemplate?: () => void;
  onSave: () => void;
  onOpenCreatingMenu: () => void;
}

const ToolbarButtons = (props: ToolbarButtonsParams) => {
  const { t } = useTranslation('project');

  if (props.revalidateProject) {
    return (
      <Section>
        <Button
          testId="revalidate-project-button"
          LeftIcon={<Check />}
          title={t('toolbar.buttons.revalidateProject')}
          type={ButtonTypes.SECONDARY}
          onClick={props.onRevalidateProject}
          disabled={props.disableSave}
          margin="0 0 0 8px"
        />
      </Section>
    );
  }

  return (
    <Section>
      {!props.canSaveTemplate ? (
        <>
          {!props.shrink ? (
            <Button
              testId="save-button"
              LeftIcon={<Save />}
              title={t('toolbar.buttons.save.title')}
              subtitle={
                props.lastSaveDateString
                  ? t('toolbar.buttons.save.subtitle.saved', {
                      date: props.lastSaveDateString,
                    })
                  : t('toolbar.buttons.save.subtitle.unsaved')
              }
              type={ButtonTypes.SECONDARY}
              onClick={props.onSave}
              disabled={props.disableSave}
              margin="0 8px 0 0"
            />
          ) : (
            <IconButton
              testId="icon-save-button"
              Icon={<Save />}
              onClick={props.onSave}
              disabled={props.disableSave}
              margin="0 8px 0 0"
            />
          )}
          <CreateOrderWrapper>
            <Tooltip
              text={t('toolbar.tooltips.requireProducts')}
              delay={1000}
              disabled={!props.disableCreateOrder}>
              <Button
                testId="create-order-button"
                title={t('toolbar.buttons.createOrder.title')}
                onClick={props.onOpenCreatingMenu}
                disabled={props.disableCreateOrder}
              />
            </Tooltip>
            <CreateOrderMenu
              onOrderSelect={props.onOrderSelect}
              onOfferSelect={props.onOfferSelect}
              show={props.showCreatingMenu}
              onClose={props.onOpenCreatingMenu}
            />
          </CreateOrderWrapper>
        </>
      ) : (
        <Button
          testId="save-template-button"
          LeftIcon={<Save />}
          title={t('toolbar.buttons.saveTemplate.title')}
          type={ButtonTypes.SECONDARY}
          onClick={props.onSaveTemplate}
          disabled={props.disableSaveTemplate}
          margin="0 0 0 8px"
        />
      )}
    </Section>
  );
};

ToolbarButtons.displayName = 'ToolbarButtons';

export default ToolbarButtons;
