import styled, { css } from 'styled-components';
import { layout } from '../../styles';

export const Container = styled(layout.Row)`
  width: 100px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  user-select: none;
`;

const disabled_item = css`
  pointer-events: none;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export const ItemTooltipWrapper = styled(layout.Row)`
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Item = styled(layout.Row)<{ disabled?: boolean }>`
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ disabled }) => disabled && disabled_item}
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.accentBlue};
      }
    }
  }
`;
