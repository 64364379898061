import axiosStatic, { AxiosInstance } from 'axios';
import { stringify } from 'qs';

import { instance_v2 } from '../config';
import { Endpoints } from '../endpoints';
import {
  BasicOffer,
  Offer,
  RecalculateOfferCommand,
  UpdateOfferCommand,
} from '../models/offer';
import { OrderForm } from '../models/order';
import { Page, Pageable } from '../models/requests';

const CancelToken = axiosStatic.CancelToken;

export interface FilterCommand {
  createdAtFrom?: string;
  createdAtTo?: string;
  text?: string;
}

export type FilterRequest = FilterCommand & Partial<Pageable>;

export type GlassFormsRequest = {
  sorting: Pick<Pageable, 'sort'>;
};

export type OfferRecalculateRequest = {
  command: RecalculateOfferCommand;
  sorting: Pick<Pageable, 'sort'>;
};

export type OfferEditRequest = Pick<Offer, 'description'> & {
  clientId: number | null;
};

class OfferApi {
  constructor(private readonly http: AxiosInstance) {}

  public createSource = CancelToken.source();

  public filterOffers = (command: FilterRequest) =>
    this.http.get<Page<BasicOffer>>(
      `${Endpoints.OFFERS}?${stringify(command)}`,
    );

  public filterClientOffers = (clientId: number, command: FilterRequest) =>
    this.http.get<Page<BasicOffer>>(
      `${Endpoints.OFFERS}/client/${clientId}?${stringify(command)}`,
    );

  public filterGlassForms = async (
    offerId: number,
    { sorting }: GlassFormsRequest,
  ) => {
    return await this.http.get<OrderForm[]>(
      `${Endpoints.OFFERS}/forms/${offerId}?${stringify(sorting)}`,
    );
  };

  public recalculateOffer = (
    offerId: number,
    { command, sorting }: OfferRecalculateRequest,
  ) =>
    this.http.post<Offer>(
      `${Endpoints.OFFERS}/calculate/${offerId}?${stringify(sorting)}`,
      command,
    );

  public editOffer = async (id: number, command: UpdateOfferCommand) => {
    return await this.http.put<Offer>(`${Endpoints.OFFERS}/${id}`, command);
  };

  public getOffer = (id: number) =>
    this.http.get<Offer>(`${Endpoints.OFFERS}/${id}`);

  public createOffer = (projectId: number) =>
    this.http.post<Offer>(`${Endpoints.OFFERS}/${projectId}`, undefined, {
      cancelToken: this.createSource.token,
    });
}

// TODO: Temporary instance caused by big BE changes, needs to be removed
export const offerApi = new OfferApi(instance_v2);
export default offerApi;
