import * as React from 'react';
import { Container, Item, ItemTooltipWrapper } from './styles';
import { Redo, Undo } from '../icons';
import { Tooltip } from '../Tooltip';
import { useTranslation } from 'react-i18next';

export interface Props {
  onUndo(): void;
  onRedo(): void;
  disabled?: boolean;
  disableUndo?: boolean;
  disableRedo?: boolean;
}

const UndoRedo: React.FC<Props> = React.memo(
  ({ onUndo, onRedo, disabled, disableRedo, disableUndo }) => {
    const { t } = useTranslation('components');

    return (
      <Container data-testid="undo-redo">
        <ItemTooltipWrapper>
          <Tooltip text={t('undoRedo.undo')} delay={1000}>
            <Item
              aria-label="undo"
              role="button"
              onClick={onUndo}
              disabled={disabled || disableUndo}>
              <Undo />
            </Item>
          </Tooltip>
        </ItemTooltipWrapper>
        <ItemTooltipWrapper>
          <Tooltip text={t('undoRedo.redo')} delay={1000}>
            <Item
              aria-label="redo"
              role="button"
              onClick={onRedo}
              disabled={disabled || disableRedo}>
              <Redo />
            </Item>
          </Tooltip>
        </ItemTooltipWrapper>
      </Container>
    );
  },
);

UndoRedo.displayName = 'UndoRedo';

export default UndoRedo;
