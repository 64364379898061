import styled, { css } from 'styled-components';
import { CheckboxStyles } from '../Checkbox';

const invalid_style = css`
  + div {
    background: ${({ theme }) => theme.colors.validationLight};
    border: 1px solid ${({ theme }) => theme.colors.validation};
  }
`;

export const Container = styled(CheckboxStyles.Container)``;

const checkIcon = css`
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

export const Input = styled.input<{ invalid?: boolean }>`
  display: none;
  &:checked {
    ~ span {
      font-weight: 700;
    }
  }
  &:not(:disabled) {
    &:not(:invalid) {
      &:checked {
        + div {
          &:after {
            ${checkIcon};
            background: ${({ theme, invalid }) =>
              invalid ? theme.colors.validation : theme.colors.mainBlue};
          }
        }
      }
    }
    &:invalid {
      &:checked {
        + div {
          &:after {
            ${checkIcon};
            background: ${({ theme }) => theme.colors.validation};
          }
        }
      }
    }
  }
  &:disabled {
    + div {
      background: ${({ theme }) => theme.colors.grey4};
      border: 1px dashed ${({ theme }) => theme.colors.accentBlue2};
    }
    &:checked {
      + div {
        &:after {
          ${checkIcon};
          background: ${({ theme }) => theme.colors.grey};
        }
      }
    }
  }
  &:invalid {
    ${invalid_style};
  }
  ${({ invalid }) => invalid && invalid_style};
`;

export const Circle = styled(CheckboxStyles.Box)`
  border-radius: 50%;
`;
