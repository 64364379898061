import * as React from 'react';

const SvgAdding = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={124}
      height={124}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={9.311}
        width={114.99}
        height={114.99}
        rx={14}
        transform="rotate(4.644 9.31 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#adding_svg__filter0_d)">
        <rect x={42} y={42} width={40} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={48} y={48} width={28} height={28} rx={4} fill="#272B5F" />
      <path
        d="M62 55.334a.666.666 0 00-.667.666v1.334a.666.666 0 101.333 0V56a.667.667 0 00-.666-.666zM68 61.334h-1.333a.666.666 0 100 1.333H68a.667.667 0 100-1.334zM58 62a.667.667 0 00-.667-.666H56a.666.666 0 100 1.333h1.333A.667.667 0 0058 62zM58.147 57.333a.674.674 0 00-.927.98l.96.927a.666.666 0 00.486.187.666.666 0 00.48-.207.667.667 0 000-.94l-1-.947zM65.333 59.426a.667.667 0 00.46-.186l.96-.927a.667.667 0 00-.9-.98l-.96.947a.667.667 0 000 .94.667.667 0 00.44.206zM62 66a.666.666 0 00-.667.667V68a.666.666 0 101.333 0v-1.333A.667.667 0 0062 66zM65.82 64.76a.667.667 0 10-.927.96l.96.947a.667.667 0 00.94-.014.668.668 0 000-.946l-.973-.947zM58.18 64.76l-.96.927a.668.668 0 00.22 1.095c.082.034.17.052.26.051a.666.666 0 00.446-.166l.96-.927a.667.667 0 10-.926-.96v-.02z"
        fill="#fff"
      />
      <defs>
        <filter
          id="adding_svg__filter0_d"
          x={32}
          y={42}
          width={60}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgAdding = React.memo(SvgAdding);
export default MemoSvgAdding;
