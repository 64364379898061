import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled(layout.Column)`
  padding: 16px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  }
  > span {
    color: ${({ theme }) => theme.colors.grey};
  }
`;
