import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { Types } from './types';

const primary = css`
  span {
    color: ${({ theme }) => theme.colors.white};
  }
  svg {
    > path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.accentBlue};
  }
  &:active {
    background: ${({ theme }) => theme.colors.accentBlue};
    box-shadow: inset 0 3px 2px rgba(39, 43, 95, 0.15);
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.grey2};
    pointer-events: none;
  }
`;

const secondary = css`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  svg {
    > path {
      fill: ${({ theme }) => theme.colors.mainBlue};
    }
  }
  > div {
    span {
      &:first-of-type {
        color: ${({ theme }) => theme.colors.mainBlue};
      }
      color: ${({ theme }) => theme.colors.grey};
    }
  }
  &:hover,
  &:focus {
    svg {
      > path {
        fill: ${({ theme }) => theme.colors.accentBlue};
      }
    }
    > div {
      span {
        &:first-of-type {
          color: ${({ theme }) => theme.colors.accentBlue};
        }
        color: ${({ theme }) => theme.colors.grey};
      }
    }
  }
  &:active {
    svg {
      > path {
        fill: ${({ theme }) => theme.colors.accentBlue};
      }
    }
    > div {
      span {
        &:first-of-type {
          color: ${({ theme }) => theme.colors.accentBlue};
        }
        color: ${({ theme }) => theme.colors.grey};
      }
    }
    box-shadow: inset 0 4px 3px rgba(39, 43, 95, 0.05);
  }
  &:disabled {
    pointer-events: none;
    svg {
      > path {
        fill: ${({ theme }) => theme.colors.grey2};
      }
    }
    > div {
      span {
        &:first-of-type {
          color: ${({ theme }) => theme.colors.grey2};
        }
        color: ${({ theme }) => theme.colors.grey2};
      }
    }
  }
`;

const tertiary = css`
  ${secondary};
  &:disabled {
    svg {
      > path {
        fill: ${({ theme }) => theme.colors.mainBlue};
      }
    }
    > div {
      span {
        &:first-of-type {
          color: ${({ theme }) => theme.colors.mainBlue};
        }
        color: ${({ theme }) => theme.colors.grey};
      }
    }
  }
`;

const quaternary = css`
  ${primary};
  background: ${({ theme }) => theme.colors.accentBlue};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.mainBlue};
  }
  &:active {
    background: ${({ theme }) => theme.colors.mainBlue};
    box-shadow: inset 0 3px 2px rgba(39, 43, 95, 0.15);
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.grey2};
  }
`;

const alert = css`
  ${primary};
  background: ${({ theme }) => theme.colors.validation};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.validation};
  }
  &:active {
    background: ${({ theme }) => theme.colors.validation};
    box-shadow: inset 0 3px 2px rgba(39, 43, 95, 0.15);
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.validation};
  }
`;

const types: Record<Types, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  [Types.PRIMARY]: primary,
  [Types.SECONDARY]: secondary,
  [Types.TERTIARY]: tertiary,
  [Types.QUATERNARY]: quaternary,
  [Types.ALERT]: alert,
};

const full_width_button = css`
  width: 100%;
  min-width: unset;
`;

const red_svg_path = css`
  svg {
    > path {
      fill: ${({ theme }) => theme.colors.validation};
    }
  }
`;

export const Container = styled.button<{
  btnType: Types;
  big?: boolean;
  fullWidth?: boolean;
  noMinWidth?: boolean;
  margin?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  min-width: ${({ noMinWidth }) => (noMinWidth ? 'unset' : '130px')};
  width: fit-content;
  height: ${({ big }) => (big ? 56 : 40)}px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.mainBlue};
  box-sizing: border-box;
  outline: 0;
  border: 0;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  ${({ fullWidth }) => fullWidth && full_width_button};
  ${({ btnType }) => types[btnType]};
  position: relative;
`;

export const IconButtonContainer = styled.button<{
  margin?: string;
  red?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  width: 52px;
  height: 42px;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  > svg {
    width: 20px;
    height: 20px;
  }
  ${secondary};

  ${({ red }) => red && red_svg_path};
`;

const leftIcon = css`
  margin-right: 8px;
  margin-left: -8px;
`;

const rightIcon = css`
  margin-left: 8px;
  margin-right: -8px;
`;

const selectPosition = (
  left?: boolean,
  right?: boolean,
  cornerIcon?: boolean,
) => {
  if (cornerIcon) {
    if (left) {
      return css`
        position: absolute;
        left: 18px;
      `;
    }
    if (right) {
      return css`
        position: absolute;
        right: 18px;
      `;
    }
  }
};

export const IconWrapper = styled.div<{
  left?: boolean;
  right?: boolean;
  cornerIcon?: boolean;
  type: Types;
}>`
  > svg {
    width: ${({ type }) => (type === Types.TERTIARY ? '24px' : '20px')};
    height: ${({ type }) => (type === Types.TERTIARY ? '24px' : '20px')};
  }
  margin-bottom: -3px;
  ${({ left }) => left && leftIcon};
  ${({ right }) => right && rightIcon};
  ${({ right, left, cornerIcon }) => selectPosition(left, right, cornerIcon)};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
