import * as React from 'react';
import { Container, ButtonWrapper } from './styles';
import { Modal } from '../Modal';
import { Button, ButtonTypes } from '../Button';
import { useCallback, useState } from 'react';
import { Typography, TypographyVariants } from '../Typography';
import { useTranslation } from 'react-i18next';
import RadioModalOption from './RadioModalOption';

export interface Props {
  decisions: string[];
  decisionLabels: string[];
  title: string;
  description: string;
  confirmLabel: string;
  descriptionSec?: string;
  onClose(): void;
  onResolve?(decision: string): void;
}

const RadioModal: React.FC<Props> = React.memo(
  ({
    title,
    onClose,
    onResolve,
    decisions,
    description,
    confirmLabel,
    decisionLabels,
    descriptionSec,
    ...props
  }) => {
    const { t } = useTranslation('project');
    const [decision, setDecision] = useState<string>(decisions[0]);

    const handleConfirm = useCallback(() => {
      onResolve?.(decision);
      onClose();
    }, [decision, onClose, onResolve]);

    const handleReject = useCallback(() => {
      onResolve?.('cancel');
      onClose();
    }, [onClose, onResolve]);

    return (
      <Modal onClose={handleReject}>
        <Container {...props}>
          <Typography as="h1" variant={TypographyVariants.H1}>
            {title}
          </Typography>
          <Typography>{description}</Typography>
          {descriptionSec && <Typography>{descriptionSec}</Typography>}
          {decisions.map((value, index) => (
            <RadioModalOption
              key={value}
              onCheck={setDecision}
              checked={decision === value}
              value={value}
              label={decisionLabels[index]}
            />
          ))}
          <ButtonWrapper>
            <Button
              title={t('modals.cancel')}
              type={ButtonTypes.SECONDARY}
              onClick={handleReject}
              noMinWidth
            />
            <Button title={confirmLabel} onClick={handleConfirm} fullWidth />
          </ButtonWrapper>
        </Container>
      </Modal>
    );
  },
);

RadioModal.displayName = 'RadioModal';

export default RadioModal;
