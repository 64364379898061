import { useMemo } from 'react';
import useCurrentLang from './useCurrentLang';
import { Locales } from '../services/i18n';
import { LengthUnits } from '../constants/units';

const langTags: Record<Locales, string> = {
  enGB: 'en-GB',
  pl: 'pl-PL',
  ru: 'ru-RU',
  it: 'it-IT',
};

const useNumberWithUnit = (
  number: number,
  unit: LengthUnits = LengthUnits.MILLIMETER,
): string => {
  const { currentLang } = useCurrentLang();

  const formatter = useMemo(() => {
    const locales = langTags[currentLang];
    return new Intl.NumberFormat(locales, {
      style: 'unit',
      unit: unit,
    });
  }, [unit, currentLang]);

  const formattedNumber = useMemo(() => {
    return formatter.format(number);
  }, [number, formatter]);

  return formattedNumber.replace(',', '.');
};

export default useNumberWithUnit;
