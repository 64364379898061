import { useCallback, useEffect, useRef, useState } from 'react';

export interface UseCountdown {
  value: number;
  clear(): void;
}

export interface UseCountdownConfig {
  startValue: number;
  intervalInMs: number;
  onFinish?(): void;
  endValue?: number;
}

const useCountdown = ({
  startValue,
  intervalInMs,
  onFinish,
  endValue = 0,
}: UseCountdownConfig): UseCountdown => {
  const [value, setValue] = useState(startValue);
  const countdown = useRef<number>();

  const clear = useCallback(() => {
    typeof countdown.current !== 'undefined' &&
      clearInterval(countdown.current);
  }, []);

  useEffect(() => {
    let counter = startValue;
    clear();
    countdown.current = setInterval(() => {
      if (counter > endValue) {
        counter -= 1;
        setValue(counter);
      } else {
        clearInterval(countdown.current);
        onFinish?.();
      }
    }, intervalInMs);
    return () => clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalInMs, endValue, startValue]);

  return { value, clear };
};

export default useCountdown;
