import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootRoutes } from './routes';
import {
  selectHasSession,
  selectPermissions,
  selectShouldResetPassword,
  useSessionStore,
} from '../store/session';
import { useEffect, useState } from 'react';
import { sessionRehydration } from '../store/session/session';
import { Action, Subjects } from '../services/api/models/permissions';
import { Can } from '../components/Can';
import { useAbility } from '../hooks';

type ExtendedProps = RouteProps & { CASLSubject?: Subjects | Subjects[] };

const PrivateRoute: React.FC<ExtendedProps> = React.memo(
  ({ CASLSubject, ...props }) => {
    const [rehydrated, setRehydrated] = useState(false);
    const hasSession = useSessionStore(selectHasSession);
    const permissions = useSessionStore(selectPermissions);
    const shouldResetPassword = useSessionStore(selectShouldResetPassword);
    const ability = useAbility();

    useEffect(() => {
      sessionRehydration.then(() => {
        setRehydrated(true);
      });
    }, []);

    if (!rehydrated || (hasSession && !permissions)) {
      return null;
    }
    if (!hasSession || (hasSession && shouldResetPassword)) {
      return <Redirect to={RootRoutes.LOGIN} />;
    }
    if (CASLSubject && !Array.isArray(CASLSubject)) {
      return (
        <Can I={Action.READ} a={CASLSubject}>
          <Route {...props} />
        </Can>
      );
    }
    if (
      CASLSubject &&
      Array.isArray(CASLSubject) &&
      CASLSubject.some((subject) => ability.can(Action.READ, subject))
    ) {
      return <Route {...props} />;
    }
    return <Route {...props} />;
  },
);

PrivateRoute.displayName = 'PrivateRoute';

export default PrivateRoute;
