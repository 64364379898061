import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionType } from '../../../../services/api/models/connection';
import { Glass } from '../../../../services/api/models/glass';
import {
  BaseType,
  ProductSearch,
} from '../../../../services/api/models/product';
import { DEFAULT_RATIO } from '../../store/config';
import { GlassTarget } from '../../store/drag';
import { Gaps } from '../../types/Gap';
import { DropZone } from '../DropZone';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { RectCorners } from '../../../../types';
import { Position } from '../../types';
import { Shape } from '../../../space';

export interface Props {
  position: Position;
  dimensions: ProjectDimensions;
  parent: Glass;
  product: ProductSearch;
  gaps: Gaps;
}

const HEIGHT = 120;

const WALL_ZONE_WIDTH = 120;

const MIN_TOP_GAP = 100; // in mm;
const MAX_HORIZONTAL_GAP = 20; // in mm;

const GlassConnectorDropZones: React.FC<Props> = React.memo(
  ({ position, dimensions, product, parent, gaps }) => {
    const corners = useMemo(() => {
      const shape = new Shape(dimensions, position);

      shape.scaleShape(DEFAULT_RATIO);

      return shape.corners;
    }, [dimensions, position]);

    const height = useMemo(() => HEIGHT * DEFAULT_RATIO, []);

    const wallZoneWidth = useMemo(() => WALL_ZONE_WIDTH * DEFAULT_RATIO, []);

    const dropTopTarget = useMemo<GlassTarget>(
      () => ({
        type: ConnectionType.GLASS,
        glass: parent,
        targetId: String(parent.id),
        anchor: 'top',
      }),
      [parent],
    );

    const dropLeftTarget = useMemo<GlassTarget>(
      () => ({
        type: ConnectionType.GLASS,
        glass: parent,
        targetId: String(parent.id),
        anchor: 'topLeft',
      }),
      [parent],
    );

    const dropRightTarget = useMemo<GlassTarget>(
      () => ({
        type: ConnectionType.GLASS,
        glass: parent,
        targetId: String(parent.id),
        anchor: 'topRight',
      }),
      [parent],
    );

    const topRightCorners = useMemo(
      (): RectCorners => ({
        'top-left': [
          corners['top-right'][0] - wallZoneWidth,
          corners['top-right'][1] - height / 2,
        ],
        'top-right': [
          corners['top-right'][0],
          corners['top-right'][1] - height / 2,
        ],
        'bottom-left': [
          corners['top-right'][0] - wallZoneWidth,
          corners['top-right'][1] + height / 2,
        ],
        'bottom-right': [
          corners['top-right'][0],
          corners['top-right'][1] + height / 2,
        ],
      }),
      [corners, height, wallZoneWidth],
    );

    const topLeftCorners = useMemo(
      (): RectCorners => ({
        'top-left': [
          corners['top-left'][0],
          corners['top-left'][1] - height / 2,
        ],
        'top-right': [
          corners['top-left'][0] + wallZoneWidth,
          corners['top-left'][1] - height / 2,
        ],
        'bottom-left': [
          corners['top-left'][0],
          corners['top-left'][1] + height / 2,
        ],
        'bottom-right': [
          corners['top-left'][0] + wallZoneWidth,
          corners['top-left'][1] + height / 2,
        ],
      }),
      [corners, height, wallZoneWidth],
    );

    const topCorners = useMemo(
      (): RectCorners => ({
        'top-left': [
          corners['top-left'][0],
          corners['top-left'][1] - height / 2,
        ],
        'top-right': [
          corners['top-right'][0],
          corners['top-right'][1] - height / 2,
        ],
        'bottom-left': [
          corners['top-left'][0],
          corners['top-left'][1] + height / 2,
        ],
        'bottom-right': [
          corners['top-right'][0],
          corners['top-right'][1] + height / 2,
        ],
      }),
      [corners, height],
    );

    const hasMinimumGap = useMemo(
      () =>
        gaps.top.value[0] >= MIN_TOP_GAP &&
        gaps.top.value[1] >= MIN_TOP_GAP &&
        gaps.left.value[0] < MAX_HORIZONTAL_GAP &&
        gaps.right.value[0] < MAX_HORIZONTAL_GAP,
      [gaps.left.value, gaps.right.value, gaps.top.value],
    );

    if (hasMinimumGap) {
      return (
        <>
          {product.baseType === BaseType.CONNECTOR_GLASS && (
            <DropZone
              anchor="top"
              dropTarget={dropTopTarget}
              corners={topCorners}
            />
          )}
          {product.baseType === BaseType.CONNECTOR_WALL && (
            <DropZone
              anchor="topLeft"
              dropTarget={dropLeftTarget}
              corners={topLeftCorners}
            />
          )}
          {product.baseType === BaseType.CONNECTOR_WALL && (
            <DropZone
              anchor="topRight"
              dropTarget={dropRightTarget}
              corners={topRightCorners}
            />
          )}
        </>
      );
    }
    return null;
  },
);

GlassConnectorDropZones.displayName = 'GlassConnectorDropZones';

export default GlassConnectorDropZones;
