import axios from 'axios';
import {
  requestErrorInterceptor,
  requestInterceptor,
} from './interceptors/request';
import {
  responseErrorInterceptor,
  responseInterceptor,
} from './interceptors/response';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
instance.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor(instance),
);
// TODO: Temporary instance caused by big BE changes, needs to be removed
const instance_v2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V2,
});

instance_v2.interceptors.request.use(
  requestInterceptor,
  requestErrorInterceptor,
);
instance_v2.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor(instance_v2),
);

export { instance_v2 };

export default instance;
