import React from 'react';
import { Router } from './router';
import { GlobalStyle, theme } from './styles';
import { ErrorBoundary } from './pages/ErrorBoundary';
import { AppLoading } from './pages/AppLoading';
import { ThemeProvider } from 'styled-components';
import './services/i18n';
import { NotificationProvider } from './components/Notification';
import { ability, AbilityContext } from './services/casl';

const Root: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<AppLoading />}>
        <ErrorBoundary>
          <GlobalStyle />
          <AbilityContext.Provider value={ability}>
            <Router />
          </AbilityContext.Provider>
          <NotificationProvider />
        </ErrorBoundary>
      </React.Suspense>
    </ThemeProvider>
  );
};

export default Root;
