import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { selectCreate, useOfferStore } from '../../../store/offers';
import { RootRoutes } from '../../../router/routes';
import { offerApi } from '../../../services/api/services';
import useCreateReducer, { Actions } from './useCreateReducer';
import useSave from './useSave';
import { Project } from '../../../services/api/models/project';

export interface UseCreateOffer {
  create(): void;
  cancel(): void;
  pending: boolean;
  progress: number;
}

const useCreateOffer = (project: Project | undefined): UseCreateOffer => {
  const { push } = useHistory();
  const createOffer = useOfferStore(selectCreate);
  const [state, dispatch] = useCreateReducer();
  const { save } = useSave(project);

  const create = useCallback(async () => {
    if (typeof project !== 'undefined') {
      await save();
      dispatch({ type: Actions.START });
      await createOffer(project.id, () => push(RootRoutes.OFFERS));
      dispatch({ type: Actions.FINISH });
    }
  }, [createOffer, dispatch, project, push, save]);

  const cancel = useCallback(() => {
    offerApi.createSource.cancel();
  }, []);

  return {
    create,
    cancel,
    ...state,
  };
};

export default useCreateOffer;
