import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  IconOption,
  IconSwitchContainer,
} from '../../../../../../../components/Switch/styles';
import Angle from '../../../../../../../components/icons/Angle';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../../components/Typography';
import { findAngle } from '../../../../../../../utils/glass';
import { toVector } from '../../../../../../../utils/shape';
import { ProjectDimensions } from '../../../../../../../services/api/models/project';
import { AngleItem, AngleWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/GlassProperties/styles';
import { DownSm, UpSm } from '../../../../../../../components/icons';

interface GlassAnglesProps {
  dimensions: ProjectDimensions;
}

const GlassAngles = ({ dimensions }: GlassAnglesProps) => {
  const { t } = useTranslation('project');
  const [foldAngles, setFoldAngles] = useState(false);

  const angles = useMemo(() => {
    const corners = dimensions.corners;

    const topLeft = findAngle(
      toVector(corners['bottom-left']),
      toVector(corners['top-left']),
      toVector(corners['top-right']),
    );

    const topRight = findAngle(
      toVector(corners['top-left']),
      toVector(corners['top-right']),
      toVector(corners['bottom-right']),
    );

    const bottomRight = findAngle(
      toVector(corners['top-right']),
      toVector(corners['bottom-right']),
      toVector(corners['bottom-left']),
    );

    const bottomLeft = findAngle(
      toVector(corners['bottom-right']),
      toVector(corners['bottom-left']),
      toVector(corners['top-left']),
    );
    return { topLeft, bottomLeft, bottomRight, topRight };
  }, [dimensions]);

  const anglesConfig = useMemo(
    () => [
      {
        key: 'top-left',
        value: angles.topLeft,
        angle: 90,
      },
      {
        key: 'top-right',
        value: angles.topRight,
        angle: 180,
      },
      {
        key: 'bottom-left',
        value: angles.bottomLeft,
        angle: 0,
      },
      {
        key: 'bottom-right',
        value: angles.bottomRight,
        angle: -90,
      },
    ],
    [angles],
  );

  const toggleFoldAngles = useCallback(() => setFoldAngles(!foldAngles), [
    foldAngles,
  ]);

  return (
    <>
      <Header aria-label="fold-button" role="button" onClick={toggleFoldAngles}>
        <Typography variant={TypographyVariants.H5_BOLD}>
          {t('propertyPanel.form.angles')}
        </Typography>
        {foldAngles ? <UpSm /> : <DownSm />}
      </Header>
      {!foldAngles ? (
        <AngleWrapper>
          {anglesConfig.map((item) => (
            <AngleItem key={item.key}>
              <IconSwitchContainer>
                <IconOption aria-label={item.key} role="option" active={true}>
                  <Angle style={{ transform: `rotate(${item.angle}deg)` }} />
                </IconOption>
              </IconSwitchContainer>
              <Typography variant={TypographyVariants.H5}>
                {item.value}°
              </Typography>
            </AngleItem>
          ))}
        </AngleWrapper>
      ) : null}
    </>
  );
};

GlassAngles.displayName = 'GlassAngles';

export default GlassAngles;
