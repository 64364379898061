import * as React from 'react';
import { useCallback, useMemo } from 'react';
import Konva from 'konva';
import { ClickEvent, Position } from '../../types';
import { Group, Line } from 'react-konva';
import { DEFAULT_RATIO } from '../../store/config';
import {
  BigPosition,
  mapCoordinatesToPoints,
  scalePoints,
  toPositionBig,
} from '../../../../utils/shape';
import { ProjectDimensions } from '../../../../services/api/models/project';
import Big from 'big.js';
import { Vector2 } from '../../utils/vector';

interface KonvaShapeProps {
  groupRef?: React.RefObject<Konva.Group>;
  lineRef: React.RefObject<Konva.Line>;
  x: number;
  y: number;
  onMouseDown?: (e: ClickEvent) => void;
  draggable?: boolean;
  dragBoundFunc?: (pos: BigPosition, initPos: BigPosition) => Vector2;
  onDragMove?: (pos: Position) => void;
  onDragEnd?: (pos: Position) => void;
  layerPosition?: Position;
  dimensions: ProjectDimensions;
  onContextMenu: (e: Konva.KonvaEventObject<PointerEvent>) => void;
  fillColor: string;
  scale: number;
  opacity?: number;
  strokeColor: string;
}

const KonvaShape = (props: KonvaShapeProps) => {
  const position = useMemo(
    () => ({
      x: new Big(props.x).mul(DEFAULT_RATIO).toNumber(),
      y: new Big(props.y).mul(DEFAULT_RATIO).toNumber(),
    }),
    [props.x, props.y],
  );

  const points = useMemo(
    () => scalePoints(mapCoordinatesToPoints(props.dimensions).flat()),
    [props.dimensions.corners],
  );

  const handleDragBoundFunc = useCallback(
    (pos: Position) => {
      const layerPosBig = toPositionBig(props.layerPosition ?? { x: 0, y: 0 });
      const scale = new Big(props.scale).times(DEFAULT_RATIO);

      const scaledPos = {
        x: new Big(pos.x).minus(layerPosBig.x).div(scale),
        y: new Big(pos.y).minus(layerPosBig.y).div(scale),
      };

      const scaledInitCursor = {
        x: new Big(props.x),
        y: new Big(props.y),
      };

      let corrected = props.dragBoundFunc?.(scaledPos, scaledInitCursor);

      if (!corrected) {
        return pos;
      }

      corrected = corrected
        .scale(scale.toNumber())
        .add(new Vector2([layerPosBig.x, layerPosBig.y]));

      return {
        x: Number(corrected.x.toFixed(8)),
        y: Number(corrected.y.toFixed(8)),
      };
    },
    [props.layerPosition, props.dragBoundFunc, props.scale, props.x, props.y],
  );

  const handleDragMove = useCallback(
    (e: Konva.KonvaEventObject<DragEvent>) => {
      const pos = toPositionBig({
        x: e.target.x(),
        y: e.target.y(),
      });

      const scaledPos = {
        x: pos.x.div(DEFAULT_RATIO).toNumber(),
        y: pos.y.div(DEFAULT_RATIO).toNumber(),
      };

      props.onDragMove?.(scaledPos);
    },
    [props.onDragMove],
  );

  const handleDragEnd = useCallback(
    (e: Konva.KonvaEventObject<DragEvent>) => {
      const pos = toPositionBig({
        x: e.target.x(),
        y: e.target.y(),
      });

      const scaledPos = {
        x: pos.x.div(DEFAULT_RATIO).toNumber(),
        y: pos.y.div(DEFAULT_RATIO).toNumber(),
      };

      props.onDragEnd?.(scaledPos);
    },
    [props.onDragEnd],
  );

  return (
    <Group
      ref={props.groupRef}
      x={position.x}
      y={position.y}
      onMouseDown={props.onMouseDown}
      onTap={props.onMouseDown}
      draggable={props.draggable}
      dragBoundFunc={handleDragBoundFunc}
      onDragMove={handleDragMove}
      onContextMenu={props.onContextMenu}
      onDragEnd={handleDragEnd}>
      <Line
        ref={props.lineRef}
        points={points}
        fill={props.fillColor}
        opacity={props.opacity ?? 1}
        strokeWidth={1}
        stroke={props.strokeColor}
        strokeScaleEnabled={false}
        ignoreStroke={true}
        closed={true}
      />
    </Group>
  );
};

KonvaShape.displayName = 'KonvaShape';

export default KonvaShape;
