import * as React from 'react';

const SvgAlert = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M22.56 16.3L14.89 3.58a3.43 3.43 0 00-5.78 0L1.44 16.3a3 3 0 00-.05 3A3.37 3.37 0 004.33 21h15.34a3.37 3.37 0 002.94-1.66 3 3 0 00-.05-3.04zm-1.7 2.05a1.31 1.31 0 01-1.19.65H4.33a1.31 1.31 0 01-1.19-.65 1 1 0 010-1l7.68-12.73a1.48 1.48 0 012.36 0l7.67 12.72a1 1 0 01.01 1.01z"
        fill={props.fill ?? '#272B5F'}
      />
      <path
        d="M12 17a1 1 0 100-2 1 1 0 000 2zM12 8a1 1 0 00-1 1v4a1 1 0 002 0V9a1 1 0 00-1-1z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgAlert = React.memo(SvgAlert);
export default MemoSvgAlert;
