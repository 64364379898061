import { useMemo } from 'react';
import useCurrentLang from './useCurrentLang';
import { Locales } from '../services/i18n';

const DEFAULT_CURRENCY = process.env.REACT_APP_CURRENCY || 'PLN';

const langTags: Record<Locales, string> = {
  enGB: 'en-GB',
  pl: 'pl-PL',
  ru: 'ru-RU',
  it: 'it-IT',
};

const useCurrency = (currency: string = DEFAULT_CURRENCY): string => {
  const { currentLang } = useCurrentLang();

  const formattedCurrency = useMemo(() => {
    const locales = langTags[currentLang];
    return (0)
      .toLocaleString(locales, {
        currency,
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  }, [currency, currentLang]);

  return formattedCurrency;
};

export default useCurrency;
