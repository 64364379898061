import { State } from './companyMember';
import { State as FilterState } from './filter';

type CommonState = State | FilterState;

export const selectLoading = (state: CommonState) => state.loading;

export const selectCompanyMembers = (state: FilterState) => state.data;

export const selectFilter = (state: FilterState) => state.filter;

export const selectFilterByCompanyId = (state: FilterState) =>
  state.filterByCompanyId;

export const selectFilterError = (state: FilterState) => state.error;

export const selectCompanyMemberError = (state: State) => state.error;

export const selectCreate = (state: State) => state.create;

export const selectUpdate = (state: State) => state.update;

export const selectUpdateByAdmin = (state: State) => state.updateByAdmin;

export const selectErrorReset = (state: State) => state.resetError;

export const selectCompanyMember = (state: State) => state.data;
