import create, { GetState, SetState } from 'zustand';
import createVanilla from 'zustand/vanilla';
import { devtools, persist } from 'zustand/middleware';
import { DEFAULT_RATIO } from '../config';
import { Project } from '../../../../services/api/models/project';
import { Dimensions } from '../../types';

const LIMIT = 99;

export const STORE_NAME = `@store/creator/history`;

export interface HistoryItem {
  project: Project;
}

export type State = {
  history: HistoryItem[];
  get(pointer: number): HistoryItem | undefined;
  length(): number;
  last(): HistoryItem | undefined;
  first(): HistoryItem | undefined;
  insert(
    project: Project,
    scaledDimensions?: Dimensions,
    index?: number,
  ): number;
  clear(): void;
};
// TODO: Restore template changes history
const store = (set: SetState<State>, get: GetState<State>) => ({
  history: [] as HistoryItem[],
  get: (pointer: number) => {
    const { history } = get();
    return history[pointer] as HistoryItem | undefined;
  },
  last: () => {
    const { history } = get();
    return history[history.length - 1] as HistoryItem | undefined;
  },
  first: () => {
    const { history } = get();
    return history[0] as HistoryItem | undefined;
  },
  length: () => {
    const { history } = get();
    return history.length;
  },
  insert: (project: Project, scaledDimensions?: Dimensions, index?: number) => {
    const { history } = get();
    const temp = history.slice(0, index);
    const newItem = {
      project,
      ratio: DEFAULT_RATIO,
    };
    let newHistory = [...temp, newItem];
    if (newHistory.length > LIMIT) {
      newHistory = newHistory.slice(newHistory.length - LIMIT);
    }
    set({ history: newHistory });
    return newHistory.length - 1;
  },
  clear: () => set({ history: [] }),
});

const persistedStore = persist(store, { name: STORE_NAME });

export const vanillaStore = createVanilla<State>(
  devtools(persistedStore, STORE_NAME),
);

export const useStore = create<State>(vanillaStore);
