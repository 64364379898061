import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Position } from '../../types';
import { InsertItem } from '../../store/insert';
import { ANCHOR_SIZE } from '../../constants/anchors';
import { InsertZone } from '../InsertZone';
import { Anchor } from '../../types/Anchor';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { AnchoringOptions } from '../../../../services/api/models/product';
import { RectCorners } from '../../../../types';
import { findPointOnSegment, toVector } from '../../../../utils/shape';
import { Shape } from '../../../space';
import { DEFAULT_RATIO } from '../../store/config';

export interface Props {
  position: Position;
  dimensions: ProjectDimensions;
  insert: InsertItem;
  onSelect(anchor: Anchor, item: InsertItem): void;
}

const GlassInsertZones: React.FC<Props> = React.memo(
  ({ position, dimensions, insert, onSelect }) => {
    const handleSelect = useCallback(
      (anchor: Anchor) => {
        onSelect(anchor, insert);
      },
      [insert, onSelect],
    );

    const getProps = useCallback(
      (zone: keyof AnchoringOptions): RectCorners => {
        const shape = new Shape({ corners: dimensions.corners }, position);

        shape.scaleShape(DEFAULT_RATIO);

        const scaledCorners = shape.corners;

        switch (zone) {
          case 'left': {
            const topRight = findPointOnSegment(ANCHOR_SIZE, [
              toVector(scaledCorners['top-left']),
              toVector(scaledCorners['top-right']),
            ]);
            const bottomRight = findPointOnSegment(ANCHOR_SIZE, [
              toVector(scaledCorners['bottom-left']),
              toVector(scaledCorners['bottom-right']),
            ]);

            return {
              'top-left': scaledCorners['top-left'],
              'top-right': [topRight.x, topRight.y],
              'bottom-left': scaledCorners['bottom-left'],
              'bottom-right': [bottomRight.x, bottomRight.y],
            };
          }
          case 'right': {
            const topLeft = findPointOnSegment(ANCHOR_SIZE, [
              toVector(scaledCorners['top-right']),
              toVector(scaledCorners['top-left']),
            ]);
            const bottomLeft = findPointOnSegment(ANCHOR_SIZE, [
              toVector(scaledCorners['bottom-right']),
              toVector(scaledCorners['bottom-left']),
            ]);
            return {
              'top-left': [topLeft.x, topLeft.y],
              'top-right': scaledCorners['top-right'],
              'bottom-left': [bottomLeft.x, bottomLeft.y],
              'bottom-right': scaledCorners['bottom-right'],
            };
          }
          case 'top': {
            const bottomLeft = findPointOnSegment(ANCHOR_SIZE, [
              toVector(scaledCorners['top-left']),
              toVector(scaledCorners['bottom-left']),
            ]);
            const bottomRight = findPointOnSegment(ANCHOR_SIZE, [
              toVector(scaledCorners['top-right']),
              toVector(scaledCorners['bottom-right']),
            ]);

            return {
              'top-left': scaledCorners['top-left'],
              'top-right': scaledCorners['top-right'],
              'bottom-left': [bottomLeft.x, bottomLeft.y],
              'bottom-right': [bottomRight.x, bottomRight.y],
            };
          }
          case 'bottom': {
            const topLeft = findPointOnSegment(ANCHOR_SIZE, [
              toVector(scaledCorners['bottom-left']),
              toVector(scaledCorners['top-left']),
            ]);
            const topRight = findPointOnSegment(ANCHOR_SIZE, [
              toVector(scaledCorners['bottom-right']),
              toVector(scaledCorners['top-right']),
            ]);
            return {
              'top-left': [topLeft.x, topLeft.y],
              'top-right': [topRight.x, topRight.y],
              'bottom-left': scaledCorners['bottom-left'],
              'bottom-right': scaledCorners['bottom-right'],
            };
          }
          default:
            return scaledCorners;
        }
      },
      [dimensions.corners, position],
    );

    return (
      <>
        <InsertZone
          corners={getProps('left')}
          anchor="left"
          onClick={handleSelect}
        />
        <InsertZone
          corners={getProps('right')}
          anchor="right"
          onClick={handleSelect}
        />
        <InsertZone
          corners={getProps('top')}
          anchor="top"
          onClick={handleSelect}
        />
        <InsertZone
          corners={getProps('bottom')}
          anchor="bottom"
          onClick={handleSelect}
        />
      </>
    );
  },
);

GlassInsertZones.displayName = 'GlassInsertZones';

export default GlassInsertZones;
