import * as React from 'react';

const SvgHome = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M20.42 10.18L12.71 2.3a.999.999 0 00-1.42 0l-7.71 7.89A2 2 0 003 11.62V20a2 2 0 001.89 2h14.22A2 2 0 0021 20v-8.38a2.07 2.07 0 00-.58-1.44zM10 20v-6h4v6h-4zm9 0h-3v-7a1 1 0 00-1-1H9a1 1 0 00-1 1v7H5v-8.42l7-7.15 7 7.19V20z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgHome = React.memo(SvgHome);
export default MemoSvgHome;
