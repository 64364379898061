import LinkActive from '../icons/LinkActive';
import LinkInactive from '../icons/LinkInactive';
import * as React from 'react';
import { Container } from './styles';
import { Tooltip } from '../Tooltip';
import { useTranslation } from 'react-i18next';

interface LockButtonParams {
  locked: boolean;
  onClick: () => void;
}

const LockButton = ({ locked, onClick }: LockButtonParams) => {
  const { t } = useTranslation('project');

  return (
    <Tooltip text={t('propertyPanel.form.keepProportions')} delay={1000}>
      <Container>
        {locked ? (
          <LinkActive width={24} height={24} onClick={onClick} />
        ) : (
          <LinkInactive width={24} height={24} onClick={onClick} />
        )}
      </Container>
    </Tooltip>
  );
};

LockButton.displayName = 'LockButton';

export default LockButton;
