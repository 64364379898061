import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.accentBlue3};
  border-radius: 4px;
  padding: 0 4px;
  margin-left: 4px;
  > span {
    color: ${({ theme }) => theme.colors.accentBlue};
    vertical-align: middle;
  }
`;
