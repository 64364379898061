import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;

export const Grid = styled.div`
  display: grid;
`;

export const Layout = styled(Column)`
  min-height: 100vh;
`;

export const DesktopBox = styled(Column)`
  flex-grow: 1;
  padding: 0 1vw;

  width: ${({ theme }) => theme.breakpoints.desktop}px;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop - 1}px) {
    padding: 0 1.5vw;
    width: calc(100vw);
  }
`;
