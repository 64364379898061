import * as React from 'react';

const SvgUsers = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M9 11a4 4 0 100-8 4 4 0 000 8zm0-6a2 2 0 110 4 2 2 0 010-4zM17 13a3 3 0 100-5.999A3 3 0 0017 13zm0-4a1 1 0 110 2 1 1 0 010-2zM17 14a5 5 0 00-3.06 1.05A7 7 0 002 20a1 1 0 102 0 5 5 0 1110 0 1 1 0 002 0 6.9 6.9 0 00-.86-3.35A3 3 0 0120 19a1 1 0 002 0 5 5 0 00-5-5z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgUsers = React.memo(SvgUsers);
export default MemoSvgUsers;
