import create, { SetState } from 'zustand';
import createVanilla from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';
import { AxiosResponse } from 'axios';

import {
  GetProductsFilter,
  ProductProperty,
  ProductSearch,
} from '../../services/api/models/product';
import { productApi } from '../../services/api/services';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/products/search`;

export type State = {
  loading: boolean;
  search(text: string, materialType?: ProductProperty[]): Promise<void>;
  select(product: ProductSearch): void;
  deselect(): void;
  results?: ProductSearch[];
  selected?: ProductSearch;
  error?: AxiosResponse;
};

const searchAction = (set: SetState<State>) => (
  text: string,
  materialType?: ProductProperty[],
) =>
  createAsyncAction(set)(async () => {
    let command: Partial<GetProductsFilter> = {
      text,
    };

    if (materialType?.length) {
      command = {
        ...command,
        materialType: materialType.map((item) => item.id),
      };
    }

    const { data } = await productApi.filterAllProducts({ command });
    set({ results: data });
  });

const store = (set: SetState<State>) => ({
  loading: false,
  search: searchAction(set),
  select: (product: ProductSearch) => set({ selected: product }),
  deselect: () => set({ selected: undefined }),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
