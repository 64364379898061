import { useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../components/Table';
import { CompanyMember } from '../services/api/models/companyMember';
import { useCompanyMembersStore } from '../store/companyMembers';

type ChangePasswordAction = () => JSX.Element;

export type BasicInfo = Pick<CompanyMember['user'], 'userName' | 'email'> & {
  handlePasswordChange?: JSX.Element;
  companyRole: string;
};
export interface UseCompanyMemberInfo {
  headers: Header<BasicInfo>[];
  data: BasicInfo[];
  loading: boolean;
  refreshData(): Promise<void>;
  userName: string;
}

const useCompanyMemberInfo = (
  handlePasswordChange?: ChangePasswordAction,
): UseCompanyMemberInfo => {
  const { t } = useTranslation('myProfile');

  const { data: dataCompanyMember, loading, fetch } = useCompanyMembersStore();

  const headers = useMemo<Header<BasicInfo>[]>(
    () => [
      {
        id: 1,
        columnName: t('basicData.fullName'),
        dataField: 'userName',
        width: '25%',
      },
      {
        id: 2,
        columnName: t('common.email'),
        dataField: 'email',
        width: '25%',
      },
      {
        id: 3,
        columnName: t('basicData.role.header'),
        dataField: 'companyRole',
        width: '25%',
      },
      {
        id: 4,
        columnName: t('basicData.password'),
        dataField: 'handlePasswordChange',
        width: '25%',
      },
    ],
    [t],
  );

  const data = useMemo((): BasicInfo[] => {
    if (!dataCompanyMember) return [{}] as BasicInfo[];
    const { user, companyRole } = dataCompanyMember;
    return [
      {
        ...user,
        companyRole: t(`basicData.role.${companyRole}`),
        handlePasswordChange: handlePasswordChange?.(),
      },
    ];
  }, [dataCompanyMember, t, handlePasswordChange]);

  const userName = useMemo<string>(() => {
    return !dataCompanyMember ? '' : dataCompanyMember.user.userName;
  }, [dataCompanyMember]);

  const handleEntry = useCallback(async () => {
    await fetch();
  }, [fetch]);

  useEffect(() => {
    handleEntry();
  }, [handleEntry]);

  return { headers, loading, data, refreshData: handleEntry, userName };
};

export default useCompanyMemberInfo;
