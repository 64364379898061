import styled, { css } from 'styled-components';

const move_cursor = css`
  cursor: move;
`;

export const Container = styled.div<{ isDragged?: boolean }>`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  ${({ isDragged }) => isDragged && move_cursor}
`;
