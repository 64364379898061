import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropValidators, useGlassGlassProduct } from '.';
import { NotificationVariants } from '../../../components/Notification';
import { ConnectionType } from '../../../services/api/models/connection';
import {
  BaseType,
  ProductModel,
  ProductSearch,
} from '../../../services/api/models/product';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../store/notifications';
import { DEFAULT_WIDTH } from '../components/Pipe';
import { DEFAULT_RATIO, selectScale, useConfigStore } from '../store/config';
import {
  GlassTarget,
  ProductTarget,
  selectInvalid,
  selectOnDrop,
  useDragStore,
} from '../store/drag';
import {
  CreatorProduct,
  GlassChild,
  selectProducts,
  useProductsStore,
} from '../store/products';
import {
  selectAddProduct,
  selectData,
  selectGlassNeighbors,
  selectProjectGlasses,
  selectUpdateManyProducts,
  selectUsedProducts,
  useProjectStore,
} from '../store/project';
import { selectSelect, useSelectStore } from '../store/select';
import { Shape as ShapeType } from '../types';
import {
  checkGlassGlassType,
  checkIfGlassGlass,
  getBarSize,
  mapPlacedProduct,
  mapProductSearch,
  setProductOrientation,
} from '../utils/product';
import { ProductGaps } from './useDropValidators';
import { getPositionOnGlass } from '../utils/bar';
import { GlassPropertiesSearch } from '../../../services/api/models/glass';
import findIndex from 'lodash/findIndex';
import { scalePosition } from '../../../utils/shape';
import { Shape } from '../../space';
import { GlassGlassType } from './useGlassGlassProduct';

const productsToRevalidate = [BaseType.HINGE];

interface useMainLayerDropProps {
  validBaseType(
    product: ProductSearch,
    target: GlassTarget,
    onTargetEdit?: (newTarget: GlassTarget) => void,
  ): Promise<string | boolean>;
}

const useMainLayerDrop = ({ validBaseType }: useMainLayerDropProps) => {
  const { t } = useTranslation('project');

  const prevProducts = useRef<CreatorProduct[]>([]);

  const addProduct = useProjectStore(selectAddProduct);
  const updateManyProducts = useProjectStore(selectUpdateManyProducts);
  const dragInvalid = useDragStore(selectInvalid);
  const onDrop = useDragStore(selectOnDrop);
  const setNotification = useNotificationsStore(selectSetNotification);
  const select = useSelectStore(selectSelect);
  const selectedScale = useConfigStore(selectScale);
  const projectData = useProjectStore(selectData);
  const projectProducts = useProductsStore(selectProducts);
  const usedProduct = useProjectStore(selectUsedProducts);
  const glasses = useProjectStore(selectProjectGlasses);
  const glassNeighbors = useProjectStore(selectGlassNeighbors);

  const { validDrop } = useGlassGlassProduct();

  const {
    validPositionOnGlass,
    validProductsOnGlass,
    validGlassGap,
    validPositionOnProduct,
    validVSG,
    validRequiredProducts,
    hasGlassInDirection,
  } = useDropValidators();

  const projectBounds = useMemo(() => {
    const templateObj = new Shape(
      projectData?.dimensions,
      projectData?.position,
    );

    return {
      topLeft: templateObj.topEdge.points.left,
      bottomRight: templateObj.bottomEdge.points.right,
    };
  }, [projectData?.dimensions, projectData?.position]);

  const notifyError = useCallback(
    (props: {
      invalidBaseType: string | boolean;
      matchToVSG: boolean;
      metProductsRequirements: boolean;
      validGlassGlassDrop: boolean;
    }) => {
      if (!props.validGlassGlassDrop) {
        setNotification({
          variant: NotificationVariants.ERROR,
          text: t(`notifications.cannotDropProduct`),
        });
      }
      if (props.invalidBaseType) {
        setNotification({
          variant: NotificationVariants.ERROR,
          text: t(
            `notifications.dropProductTypeInvalid.${props.invalidBaseType}`,
          ),
        });
      }
      if (!props.matchToVSG) {
        setNotification({
          variant: NotificationVariants.ERROR,
          text: t(`notifications.dropProductTypeInvalid.vsg`),
        });
      }
      if (!props.metProductsRequirements) {
        setNotification({
          variant: NotificationVariants.ERROR,
          text: t(`notifications.dropProductTypeInvalid.requiredProducts`),
        });
      }
    },
    [setNotification, t],
  );

  const validateProduct = useCallback(
    async (
      data: ProductSearch,
      target: GlassTarget,
      isGlassGlass: boolean,
      glassProperties: GlassPropertiesSearch,
      glassGlassType: GlassGlassType | undefined,
      modifyTarget: (newTarget: GlassTarget) => void,
    ) => {
      const validThickness = data.glassProperties.map((item) => item.thickness);
      const invalidBaseType = await validBaseType(data, target, modifyTarget);
      const matchToVSG = validVSG(data, target);
      const metProductsRequirements = validRequiredProducts(data, target);
      const validGlassGlassDrop = isGlassGlass
        ? validDrop(
            target.glass.id,
            glassGlassType,
            target.anchor,
            glassProperties?.glassToGlassGap,
            validThickness,
          )
        : true;

      if (
        invalidBaseType ||
        !matchToVSG ||
        !metProductsRequirements ||
        !validGlassGlassDrop
      ) {
        notifyError({
          invalidBaseType,
          matchToVSG,
          metProductsRequirements,
          validGlassGlassDrop,
        });
        return false;
      }

      return true;
    },
    [notifyError, validBaseType, validDrop, validRequiredProducts, validVSG],
  );

  const handleOnGlassDrop = useCallback(
    async (shape: ShapeType, data: ProductSearch, target: GlassTarget) => {
      let validShape = shape;

      const isGlassGlass = checkIfGlassGlass(data.baseType, data.mountTypes);

      const glassGlassType = isGlassGlass
        ? checkGlassGlassType(data.baseType, data.mountTypes)
        : undefined;
      const glassProperties = data.glassProperties.find(
        (item) => item.thickness === target.glass.thickness,
      );

      if (!glassProperties) {
        return;
      }

      const modifyTarget = (newTarget: GlassTarget) => {
        target = newTarget;
      };

      const productValid = await validateProduct(
        data,
        target,
        isGlassGlass,
        glassProperties,
        glassGlassType,
        modifyTarget,
      );

      if (!productValid) {
        return;
      }

      validShape.position = scalePosition(validShape.position, true);

      const validProduct = validProductPosition({
        anchor: target.anchor,
        glassProperties,
        attributes: data.attributes,
        glassToGlassGap: data.glassToGlassGap,
        wallToGlassGap: data.wallToGlassGap,
        baseType: data.baseType,
        mountTypes: data.mountTypes,
        glassTarget: target,
        shape: validShape,
      });

      validShape = validProduct;
      target.glass = validProduct.glass;

      const product = mapPlacedProduct(
        data,
        validShape,
        target,
        isGlassGlass,
        selectedScale,
        glassNeighbors,
        glassGlassType,
        glassProperties,
        hasGlassInDirection,
        projectBounds,
      );

      addProduct(product, data, (id) => {
        select({ shapeId: id, type: 'product' });
      });

      setNotification({
        variant: NotificationVariants.SUCCESS,
        text: t('notifications.dropProductSuccess'),
      });
    },
    [
      checkIfGlassGlass,
      validBaseType,
      validVSG,
      validRequiredProducts,
      addProduct,
      setNotification,
      t,
      select,
      validGlassGap,
      validPositionOnGlass,
      checkGlassGlassType,
      validDrop,
      validProductsOnGlass,
      hasGlassInDirection,
      projectBounds,
    ],
  );

  const handleOnProductDrop = useCallback(
    async (
      shape: ShapeType,
      data: ProductSearch,
      dropTarget: ProductTarget,
    ) => {
      const validShape = validPositionOnProduct(
        shape,
        dropTarget,
        data.baseType,
      );

      const product = mapProductSearch({
        product: data,
        shape: validShape,
        dropTarget,
        glassNeighbors,
        customWidth:
          data.baseType === BaseType.PIPE ? DEFAULT_WIDTH : undefined,
      });
      addProduct(product, data);
    },
    [addProduct, validPositionOnProduct],
  );

  const validProductPosition = useCallback(
    (
      product: Pick<GlassChild, 'anchor'> &
        Pick<
          ProductSearch,
          | 'attributes'
          | 'glassToGlassGap'
          | 'wallToGlassGap'
          | 'baseType'
          | 'mountTypes'
        > & {
          glassTarget: GlassTarget;
          shape: ShapeType;
          glassProperties: GlassPropertiesSearch;
        },
    ) => {
      const {
        anchor,
        glassProperties,
        attributes,
        glassToGlassGap,
        wallToGlassGap,
        baseType,
        mountTypes,
        glassTarget,
        shape,
      } = product;

      const isGlassGlass = checkIfGlassGlass(baseType, mountTypes);
      const glassGlassType = isGlassGlass
        ? checkGlassGlassType(baseType, mountTypes)
        : undefined;

      const gaps: ProductGaps = {
        gapFromEdge: attributes.gapFromGlassEdge,
        gapFromGlass:
          glassToGlassGap.length > 0 ? Math.max(...glassToGlassGap) : 0,
        gapFromWall:
          wallToGlassGap.length > 0 ? Math.max(...wallToGlassGap) : 0,
      };

      const cutGlass = validGlassGap(
        baseType,
        mountTypes,
        glassProperties.glassToGlassGap,
        glassProperties.wallToGlassGap,
        glassTarget,
        isGlassGlass,
        glassGlassType,
      );

      const validTarget = { ...glassTarget, glass: cutGlass };

      const validShape = validPositionOnGlass({
        shape,
        baseType: baseType,
        glass: cutGlass,
        gapFromEdge: gaps,
        rotated:
          attributes.symmetry.reversibility &&
          (anchor === 'top' || anchor === 'bottom'),
        glassProperties: glassProperties,
        anchor,
        isGlassGlass,
        glassGlassType,
      });

      validProductsOnGlass(validTarget);

      if (baseType === BaseType.GASKET || baseType === BaseType.BAR) {
        const size = getBarSize(validTarget.glass, shape, anchor);

        validShape.position = getPositionOnGlass(
          {
            ...shape,
            ...size,
            orientation: setProductOrientation(validTarget),
          },
          validTarget,
          [glassProperties],
        );

        validShape.width = size.width;
        validShape.height = size.height;
      }

      return {
        ...shape,
        ...validShape,
        glass: cutGlass,
      };
    },
    [
      checkIfGlassGlass,
      validGlassGap,
      validPositionOnGlass,
      checkGlassGlassType,
      validProductsOnGlass,
    ],
  );

  const shouldProductRevalidate = useCallback(
    (baseType: BaseType) => productsToRevalidate.includes(baseType),
    [],
  );

  //TODO: Need to be fixed
  const updateExistingProducts = useCallback(() => {
    const productsToUpdate: ProductModel[] = [];
    const glassesCopy = [...glasses];

    projectProducts.forEach((product) => {
      if (
        product.parentType === 'glass' &&
        usedProduct[product.data.productId]
      ) {
        const used = usedProduct[product.data.productId];
        const isGlassGlass = checkIfGlassGlass(used.baseType, used.mountTypes);
        const glassGlassType = isGlassGlass
          ? checkGlassGlassType(used.baseType, used.mountTypes)
          : undefined;
        const glassIndex = findIndex(glasses, ['id', product.parent.id]);

        if (
          !shouldProductRevalidate(used.baseType) ||
          !glassesCopy[glassIndex]
        ) {
          return;
        }

        const glassTarget = {
          glass: glassesCopy[glassIndex],
          type: ConnectionType.GLASS as ConnectionType.GLASS,
          targetId: String(product.parent.id),
          anchor: product.anchor,
        };

        if (
          (product.anchor === 'top' || product.anchor === 'bottom') &&
          used.attributes.symmetry.reversibility
        ) {
          const productWidth = JSON.parse(JSON.stringify(product.data.width));
          const productHeight = JSON.parse(JSON.stringify(product.data.height));

          product.data.width = productHeight;
          product.data.height = productWidth;
        }

        const validProduct = validProductPosition({
          ...product,
          glassTarget: glassTarget,
          glassToGlassGap: used.glassToGlassGap,
          wallToGlassGap: used.wallToGlassGap,
          baseType: used.baseType,
          mountTypes: used.mountTypes,
          attributes: used.attributes,
          shape: product.data,
        });

        const mappedProduct = mapPlacedProduct(
          used,
          validProduct,
          glassTarget,
          isGlassGlass,
          selectedScale,
          glassNeighbors,
          glassGlassType,
          product.glassProperties,
          hasGlassInDirection,
          projectBounds,
        );

        productsToUpdate.push({
          ...mappedProduct,
          id: product.id,
        });
        glassesCopy[glassIndex] = validProduct.glass;
      }
    });

    updateManyProducts(productsToUpdate);
  }, [projectProducts, validProductPosition, glasses]);

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      onDrop(e, async (data, shape, invalid, collision, dropTarget) => {
        if (dropTarget && collision[dropTarget.anchor] && !invalid.invalid) {
          if (dropTarget.type === ConnectionType.GLASS) {
            await handleOnGlassDrop(shape, data, dropTarget);
          }
          if (dropTarget.type === ConnectionType.PRODUCT) {
            await handleOnProductDrop(shape, data, dropTarget);
          }
        }
      });
      if (dragInvalid.invalid && dragInvalid.reason) {
        setNotification({
          variant: NotificationVariants.ERROR,
          text: t(`notifications.${dragInvalid.reason}`),
        });
      }
    },
    [
      dragInvalid.invalid,
      dragInvalid.reason,
      handleOnGlassDrop,
      handleOnProductDrop,
      onDrop,
      setNotification,
      t,
    ],
  );

  useEffect(() => {
    if (prevProducts.current.length !== projectProducts.length) {
      if (
        prevProducts.current.length < projectProducts.length &&
        prevProducts.current.length > 0
      ) {
        updateExistingProducts();
      }

      prevProducts.current = projectProducts;
    }
  }, [prevProducts.current, projectProducts]);

  return {
    handleDrop,
  };
};

export default useMainLayerDrop;
