import styled from 'styled-components';
import { Typography } from '../../../../components/Typography';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 386px;
  height: 377px;
`;

export const Wrapper = styled.div`
  width: 386px;
  height: 367px;
`;

export const InfoBox = styled.div`
  background: ${({ theme }) => theme.colors.grey4};
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  box-sizing: border-box;
  border-radius: 4px;
  height: 65px;
  margin-bottom: 40px;
  height: 64px;

  > span {
    display: block;
    width: 365px;
    padding: 16px;
  }
`;

export const Header = styled(Typography)`
  display: block;
  text-align: center;
  padding-bottom: 24px;
`;

export const Tips = styled.div`
  width: 324px;
  height: 128px;
  margin-bottom: 40px;

  span {
    display: block;
    color: ${({ theme }) => theme.colors.grey};
  }

  span:not(:first-of-type) {
    margin-top: 14px;
    counter-increment: line-number;
    &:before {
      content: counter(line-number) '. ';
    }
  }
`;
