import * as React from 'react';
import { Container, Bar } from './styles';

export interface Props {
  current?: number;
  target?: number;
}

const Progress: React.FC<Props> = React.memo(
  ({ current = 0, target = 100 }) => {
    return (
      <Container data-testid="progress">
        <Bar data-testid="bar" progress={(current / target) * 100} />
      </Container>
    );
  },
);

Progress.displayName = 'Progress';

export default Progress;
