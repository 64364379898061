import Konva from 'konva';
import create, { SetState } from 'zustand';
import createVanilla from 'zustand/vanilla';
import { devtools, persist } from 'zustand/middleware';
import { Position } from '../../types';
import { v4 as uuid } from 'uuid';
import { projectStore } from '../project';
import { ProjectDimensions } from '../../../../services/api/models/project';

export const STORE_NAME = `@store/creator/template`;

interface TemplatePosition extends Position {
  init?: boolean;
}

const position: TemplatePosition = { x: 0, y: 0, init: true };

const defaultState: Omit<
  State,
  | 'setPosition'
  | 'setScaledDimensions'
  | 'setBaseDimensions'
  | 'setBaseBreakingPoint'
  | 'setConfigured'
  | 'setDimensions'
  | 'setScaledBaseDimensions'
  | 'setup'
  | 'clear'
> = {
  position,
  dimensions: {
    corners: {
      'top-right': [1000, 0],
      'top-left': [0, 0],
      'bottom-right': [1000, 2000],
      'bottom-left': [0, 2000],
    },
  },
  baseBreakingPoint: undefined,
  configured: false,
  templateId: undefined,
};

export type State = {
  position: TemplatePosition;
  baseBreakingPoint?: number;
  dimensions: ProjectDimensions;
  configured: boolean;
  templateId?: string;
  setPosition(position: Konva.Vector2d): void;
  setBaseBreakingPoint(breakPoint: number): void;
  setDimensions(dimensions: ProjectDimensions): void;
  setConfigured(): void;
  setup(): string;
  clear(): void;
};

const store = (set: SetState<State>) => ({
  ...defaultState,
  setPosition: (position: Konva.Vector2d) => set({ position }),
  setBaseBreakingPoint: (breakPoint: number) =>
    set({ baseBreakingPoint: breakPoint }),
  setDimensions: (dimensions: ProjectDimensions) => set({ dimensions }),
  setConfigured: () => set({ configured: true }),
  setup: () => {
    const templateId = uuid();
    set({
      ...defaultState,
      templateId,
    });
    return templateId;
  },
  clear: () => {
    projectStore.getState().clearIndent();
    set({
      ...defaultState,
    });
  },
});

const persistedStore = persist(store, { name: STORE_NAME });

export const vanillaStore = createVanilla<State>(
  devtools(persistedStore, STORE_NAME),
);

export const useStore = create<State>(vanillaStore);
