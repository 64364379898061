import * as React from 'react';
import { Container } from './styles';
import { Info } from '../../../../../../components/icons';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { useTranslation } from 'react-i18next';

const EmptyInfo: React.FC = React.memo(() => {
  const { t } = useTranslation('project');

  return (
    <Container>
      <Info />
      <Typography variant={TypographyVariants.H5}>
        {t('propertyPanel.empty')}
      </Typography>
    </Container>
  );
});

EmptyInfo.displayName = 'EmptyInfo';

export default EmptyInfo;
