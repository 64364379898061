import {
  IndentPosition,
  ProjectData,
} from '../../../services/api/models/project';
import {
  getCoordinatesForRect,
  getCoordinatesForIndent,
} from '../../../utils/shape';

const WIDTH = 2000;
const HEIGHT = 2000;
const POSITION = { x: 0, y: 0 };

const base: Pick<
  ProjectData,
  'products' | 'glassSheets' | 'unsafe' | 'adjustManually' | 'position'
> = {
  position: POSITION,
  unsafe: false,
  adjustManually: false,
  products: [],
  glassSheets: [],
};

const square: ProjectData = {
  ...base,
  width: WIDTH,
  height: HEIGHT,
  dimensions: {
    corners: getCoordinatesForRect(WIDTH, HEIGHT, POSITION),
  },
};

const rectangle: ProjectData = {
  ...base,
  width: WIDTH / 2,
  height: HEIGHT,
  dimensions: {
    corners: getCoordinatesForRect(WIDTH / 2, HEIGHT, POSITION),
  },
};

const lShape: ProjectData = {
  ...base,
  width: WIDTH,
  height: HEIGHT,
  dimensions: {
    indent: IndentPosition.TOP_RIGHT,
    corners: getCoordinatesForIndent(
      WIDTH,
      HEIGHT,
      POSITION,
      IndentPosition.TOP_RIGHT,
    ),
  },
};

const lShapeRevert: ProjectData = {
  ...base,
  width: WIDTH,
  height: HEIGHT,
  dimensions: {
    indent: IndentPosition.BOTTOM_LEFT,
    corners: getCoordinatesForIndent(
      WIDTH,
      HEIGHT,
      POSITION,
      IndentPosition.BOTTOM_LEFT,
    ),
  },
};

export const templates = {
  square,
  rectangle,
  lShape,
  lShapeRevert,
};

export type TemplateTypes = keyof typeof templates;
