import * as React from 'react';

const SvgLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M8 13h12a1 1 0 000-2H8a1 1 0 000 2zM8 9h8a1 1 0 100-2H8a1 1 0 000 2zM8 17h8a1 1 0 000-2H8a1 1 0 000 2z"
        fill={props.fill ?? '#272B5F'}
      />
      <rect x={3} y={5} width={2} height={14} rx={1} fill="#828A98" />
    </svg>
  );
};

const MemoSvgLeft = React.memo(SvgLeft);
export default MemoSvgLeft;
