import * as React from 'react';
import { Provider as Container, ChildWrapper } from './styles';
import { useMemo } from 'react';
import Small from './Small';
import Big from './Big';
import { CommonProps, TooltipType } from './types';

export interface Props extends CommonProps {
  type?: TooltipType;
  CustomComponent?: React.ReactNode;
  fullWidth?: boolean;
  display?: 'block' | 'inline' | 'flex';
  flex?: string | number;
  delay?: number;
  disabled?: boolean;
}

const Tooltip: React.FC<Props> = React.memo(
  ({
    children,
    alwaysVisible,
    text,
    position,
    drawFrom,
    CustomComponent,
    fullWidth,
    display,
    flex,
    delay,
    disabled,
    type = TooltipType.SMALL,
  }) => {
    const Tooltip = useMemo(() => {
      switch (type) {
        case TooltipType.BIG: {
          return (
            <Big
              text={text}
              position={position}
              drawFrom={drawFrom}
              alwaysVisible={alwaysVisible}
              CustomComponent={CustomComponent}
            />
          );
        }
        default:
        case TooltipType.SMALL: {
          return (
            <Small
              text={text}
              position={position}
              drawFrom={drawFrom}
              alwaysVisible={alwaysVisible}
            />
          );
        }
      }
    }, [CustomComponent, alwaysVisible, drawFrom, position, text, type]);

    return (
      <Container display={display} fullWidth={fullWidth} flex={flex}>
        <ChildWrapper
          fullWidth={fullWidth}
          tooltipDelay={delay}
          display={display}>
          {children}
        </ChildWrapper>
        {!disabled && Tooltip}
      </Container>
    );
  },
);

Tooltip.displayName = 'Tooltip';

export default Tooltip;
