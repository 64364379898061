import * as React from 'react';

const SvgPointer = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M12.399 14.55a.328.328 0 01.328-.126l5.581.455L7.323 2 5 18.768l4.773-3.637a.303.303 0 01.227-.075h.101c.124.032.22.129.252.252L12.324 22l1.945-.58-1.945-6.516a.43.43 0 01.076-.354z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgPointer = React.memo(SvgPointer);
export default MemoSvgPointer;
