import { useCallback, useEffect, useRef } from 'react';

export type TimeoutType = ReturnType<typeof setTimeout>;

export type UseTimeout = TimeoutType | undefined;

const useTimeout = <T>(
  callback: () => void,
  timeout?: number,
  disable?: boolean,
  watchChange?: T,
): UseTimeout => {
  const timeoutRef = useRef<TimeoutType>();

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    clear();
    if (!disable && typeof timeout !== 'undefined') {
      timeoutRef.current = setTimeout(callback, timeout);
    }
    return () => clear();
  }, [callback, clear, disable, timeout, watchChange]);

  return timeoutRef.current;
};

export default useTimeout;
