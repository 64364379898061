import { State } from './template';
import { State as FilterState } from './filter';

type CommonState = State | FilterState;

export const selectLoading = (state: CommonState) => state.loading;

export const selectError = (state: CommonState) => state.error;

export const selectCreate = (state: State) => state.create;

export const selectUpdate = (state: State) => state.update;

export const selectDelete = (state: State) => state.delete;

export const selectFilter = (state: FilterState) => state.filter;

export const selectFetch = (state: State) => state.fetch;
