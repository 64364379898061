import * as React from 'react';
import Page500 from '../ErrorPage/containers/Page500/Page500';

export interface Props {}

export interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  public static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
    };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    process.env.NODE_ENV !== 'production' &&
      console.error(error, JSON.stringify(errorInfo));
  }

  public state = {
    hasError: false,
  };

  public render() {
    if (this.state.hasError) {
      return <Page500 />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
