import * as React from 'react';
import { Radio } from '../Radio';
import { Typography } from '../Typography';
import { ModalOption as Container } from './styles';

export interface Props {
  checked: boolean;
  value: string;
  label: string;
  onCheck(value: string): void;
}

const RadioModalOption: React.FC<Props> = React.memo(
  ({ checked, value, label, onCheck, ...props }) => {
    return (
      <Container onClick={() => onCheck(value)} role="button" {...props}>
        <Radio name={value} value={value} checked={checked} />
        <Typography>{label}</Typography>
      </Container>
    );
  },
);

RadioModalOption.displayName = 'RadioModalOption';

export default RadioModalOption;
