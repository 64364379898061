import styled, { css } from 'styled-components';

const only_edge = css`
  > span {
    display: block;
    margin-bottom: 16px;
  }
`;

export const Container = styled.div<{ onlyEdge?: boolean }>`
  > [role='listbox'] {
    margin: 16px 0;
  }
  ${({ onlyEdge }) => onlyEdge && only_edge};
`;
