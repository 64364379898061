import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { ConnectionType } from '../../../../services/api/models/connection';
import { Glass } from '../../../../services/api/models/glass';
import {
  AnchoringOptions,
  ProductModel,
  ProductSearch,
} from '../../../../services/api/models/product';
import { GlassTarget } from '../../store/drag';
import { Gaps } from '../../types/Gap';
import { DropZone } from '../DropZone';
import { useDropZone } from './useDropZone';
import { ProjectDimensions } from '../../../../services/api/models/project';

export interface Props {
  glass: Glass;
  dimensions: ProjectDimensions;
  product: ProductSearch;
  glassGaps: Gaps;
  glassProducts: ProductModel[];
}

const GlassDropZones: React.FC<Props> = React.memo(
  ({ glass, dimensions, product, glassGaps, glassProducts }) => {
    const { getProps, allowed } = useDropZone({
      corners: dimensions.corners,
      product,
      glass,
      glassGaps,
      glassProducts,
    });

    const getDropTarget = useCallback(
      (anchor: keyof AnchoringOptions): GlassTarget => ({
        targetId: String(glass.id),
        type: ConnectionType.GLASS,
        anchor,
        glass,
      }),
      [glass],
    );

    const targets = useMemo<Record<keyof AnchoringOptions, GlassTarget>>(
      () => ({
        left: getDropTarget('left'),
        right: getDropTarget('right'),
        top: getDropTarget('top'),
        bottom: getDropTarget('bottom'),
        topLeft: getDropTarget('topLeft'),
        topRight: getDropTarget('topRight'),
        bottomLeft: getDropTarget('bottomLeft'),
        bottomRight: getDropTarget('bottomRight'),
        center: getDropTarget('center'),
      }),
      [getDropTarget],
    );

    const invalidGlassThickness = useMemo(
      () =>
        !product.glassProperties
          .map((item) => item.thickness)
          .includes(glass.thickness),
      [glass.thickness, product.glassProperties],
    );

    const renderItems = useMemo(
      () =>
        Object.keys(targets).map((t) => {
          const target = t as keyof AnchoringOptions;

          if (
            !allowed.glass[target] &&
            !allowed.wall[target] &&
            !allowed.mixed[target]
          ) {
            return null;
          }

          return (
            <DropZone
              corners={getProps(target)}
              key={target}
              anchor={target}
              invalidThickness={invalidGlassThickness}
              dropTarget={targets[target]}
            />
          );
        }),
      [
        getProps,
        invalidGlassThickness,
        targets,
        allowed.glass,
        allowed.wall,
        allowed.mixed,
      ],
    );

    return <>{renderItems}</>;
  },
);

GlassDropZones.displayName = 'GlassDropZones';

export default GlassDropZones;
