import create, { GetState, SetState } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import createVanilla from 'zustand/vanilla';
import {
  Glass,
  GlassPropertiesSearch,
} from '../../../../services/api/models/glass';
import { ProductModel } from '../../../../services/api/models/product';
import { Boundaries, Distance } from '../../types';
import { Anchor } from '../../types/Anchor';

export const STORE_NAME = `@store/creator/products`;

type Set = SetState<State>;
type Get = GetState<State>;

export enum DistanceTypes {
  AXIS = 'AXIS',
  EDGE = 'EDGE',
}

export interface BaseProduct {
  id: string;
  data: ProductModel;
  parentType: 'glass' | 'product';
  anchor: Anchor;
}

export interface GlassChild extends BaseProduct {
  parentType: 'glass';
  parent: Glass;
  allParents: Glass[];
  boundaries: Boundaries;
  edgeDistance: Distance;
  axisDistance: Distance;
  glassProperties: GlassPropertiesSearch;
}

export interface ProductChild extends BaseProduct {
  parentType: 'product';
}

export type CreatorProduct = GlassChild | ProductChild;

type UpdateDistance = Partial<
  Pick<GlassChild, 'edgeDistance' | 'axisDistance'>
>;

export type State = {
  products: CreatorProduct[];
  distanceType: DistanceTypes;
  setDistanceType(distanceType: DistanceTypes): void;
  addProduct(product: CreatorProduct): void;
  deleteProduct(id: string): void;
  deleteManyProducts(ids: string[]): void;
  updateProduct(product: CreatorProduct): void;
  updateDistance(id: string, distance: UpdateDistance): void;
  clear(): void;
};

const addProductAction = (set: Set, get: Get) => (product: CreatorProduct) => {
  const { products } = get();
  set({ products: [...products, product] });
};

const deleteProductAction = (set: Set, get: Get) => (id: string) => {
  const { products } = get();
  set({ products: products.filter((product) => product.id !== id) });
};

const deleteManyProductsAction = (set: Set, get: Get) => (ids: string[]) => {
  const { products } = get();
  set({ products: products.filter((product) => !ids.includes(product.id)) });
};

const updateProductAction = (set: Set, get: Get) => (
  product: CreatorProduct,
) => {
  const { products } = get();
  set({
    products: [...products.filter((item) => item.id !== product.id), product],
  });
};

const updateDistanceAction = (set: Set, get: Get) => (
  id: string,
  distance: UpdateDistance,
) => {
  const { products } = get();
  const product = products.find((item) => item.id === id);
  if (product) {
    set({
      products: [
        ...products.filter((item) => item.id !== id),
        {
          ...product,
          ...distance,
        },
      ],
    });
  }
};

const setDistanceTypeAction = (set: Set) => (distanceType: DistanceTypes) => {
  set({ distanceType });
};

const store = (set: Set, get: Get): State => ({
  products: [],
  distanceType: DistanceTypes.AXIS,
  setDistanceType: setDistanceTypeAction(set),
  addProduct: addProductAction(set, get),
  deleteProduct: deleteProductAction(set, get),
  deleteManyProducts: deleteManyProductsAction(set, get),
  updateProduct: updateProductAction(set, get),
  updateDistance: updateDistanceAction(set, get),
  clear: () => set({ products: [] }),
});

const persistedStore = persist(store, { name: STORE_NAME });

export const vanillaStore = createVanilla<State>(
  devtools(persistedStore, STORE_NAME),
);

export const useStore = create<State>(vanillaStore);
