import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.mainBlue};
  border-radius: 4px;
  width: fit-content;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Item = styled.div`
  width: 273px;
  background-color: ${({ theme }) => theme.colors.mainBlue2};
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
  &:first-of-type {
    margin-bottom: 8px;
  }
  &:hover {
    opacity: 0.9;
  }
  > span {
    display: inline-block;
    color: ${({ theme }) => theme.colors.mainBlue4};
    &:first-of-type {
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: 8px;
    }
  }
`;
