import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { creatorProject, creatorTemplate } from '../../../modules/creator';
import { useCreator } from '../../../pages/Project/hooks';
import {
  Dimensions,
  Gap as GapTypes,
  GlassThickness,
  Projection,
  Scale,
} from '../../../types';
import { DropdownOption } from '../../Dropdown';
import { InsertCategory, InsertOption, InsertType } from '../../Insert';
import { SwitchOption } from '../../Switch';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../store/notifications';
import { NotificationVariants } from '../../Notification';
import { selectCollidingGlasses } from '../../../modules/creator/store/project';
import { useHasSufficientAreaForGlassCreation } from '../../../modules/creator/components/Glass/useHasSufficientAreaForGlassCreation';

const { useTemplateStore, selectTemplateId } = creatorTemplate;
const { useProjectStore, selectProjectGlasses } = creatorProject;

export interface UseToolbarConfig {
  insertNiche: InsertCategory[];
  insertGlass: InsertCategory[];
  insertGap: InsertCategory[];
  insertCategories: InsertCategory[];
  projectionOptions: SwitchOption[];
  scaleOptions: DropdownOption[];
  selectedDimensions: Dimensions;
}

interface SelectGlass {
  type: GlassThickness;
  value: string;
  laminate?: boolean;
}

const glasses: SelectGlass[] = [
  { type: GlassThickness.G6, value: '6 mm' },
  { type: GlassThickness.G8, value: '8 mm' },
  { type: GlassThickness.G10, value: '10 mm' },
  { type: GlassThickness.G12, value: '12 mm' },
  { type: GlassThickness.G442L, value: '44.2', laminate: true },
  { type: GlassThickness.G444L, value: '44.4', laminate: true },
  { type: GlassThickness.G552L, value: '55.2', laminate: true },
  { type: GlassThickness.G554L, value: '55.4', laminate: true },
  { type: GlassThickness.G662L, value: '66.2', laminate: true },
  { type: GlassThickness.G664L, value: '66.4', laminate: true },
];

interface Gap {
  type: GapTypes;
  value: string;
}

const gaps: Gap[] = [
  { type: GapTypes.G2, value: '2 mm' },
  { type: GapTypes.G3, value: '3 mm' },
  { type: GapTypes.G4, value: '4 mm' },
  { type: GapTypes.G5, value: '5 mm' },
];

const useToolbarConfig = (payload: {
  disableCreationActions?: boolean;
}): UseToolbarConfig => {
  const { disableCreationActions } = payload;
  const { t } = useTranslation('project');
  const templateId = useTemplateStore(selectTemplateId);
  const projectGlasses = useProjectStore(selectProjectGlasses);
  const { selected, project } = useCreator();

  const hasSufficientAreaForGlassCreation = useHasSufficientAreaForGlassCreation();

  const setNotification = useNotificationsStore(selectSetNotification);

  const projectCollidingGlasses = useProjectStore(selectCollidingGlasses);

  const hasBlankGlass = useMemo(
    () => projectGlasses.some(({ blank }) => !!blank),
    [projectGlasses],
  );

  useEffect(() => {
    if (!hasSufficientAreaForGlassCreation) {
      setNotification({
        text: t('toolbar.tooltips.spaceHasBeenFilled'),
        variant: NotificationVariants.INFO,
      });
    }
  }, [hasSufficientAreaForGlassCreation, setNotification, t]);

  const glassOptions = useMemo<InsertOption[]>(
    () =>
      glasses.map(({ value, type, laminate }) => ({
        title: t('glass.thickness', { value }),
        value: type,
        label: laminate ? t('glass.laminate') : undefined,
        type: InsertType.GLASS,
      })),
    [t],
  );

  const gapOptions = useMemo<InsertOption[]>(
    () =>
      gaps.map(({ type, value }) => ({
        title: t(t('gap.thickness', { value })),
        value: type,
        type: InsertType.GAP,
      })),
    [t],
  );

  const insertNiche = useMemo<InsertCategory[]>(() => {
    const disablingReasons = new Map([
      [
        'only-one-niche',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.onlyOneNiche'),
        },
      ],
      [
        'action-pending',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.creationActionInProgress'),
        },
      ],
    ]);

    return [
      {
        title: t('toolbar.niche'),
        options: [],
        niche: true,
        ...(() => {
          if (disableCreationActions) {
            return disablingReasons.get('action-pending');
          }

          if (typeof templateId !== 'undefined') {
            return disablingReasons.get('only-one-niche');
          }

          return undefined;
        })(),
      },
    ];
  }, [t, disableCreationActions, templateId]);

  const insertGlass = useMemo<InsertCategory[]>(() => {
    const disablingReasons = new Map([
      [
        'no-niche',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.requireNiche'),
        },
      ],
      [
        'action-pending',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.creationActionInProgress'),
        },
      ],
      [
        'space-filled',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.spaceHasBeenFilled'),
        },
      ],
      [
        'glass-collisions',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.glassCollisions', {
            value: Array.from(projectCollidingGlasses).sort().join(', '),
          }),
        },
      ],
      [
        'glass-is-blank',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.glassIsBlank'),
        },
      ],
    ]);

    return [
      {
        title: t('toolbar.glass'),
        options: glassOptions,
        disabled: !templateId || !hasSufficientAreaForGlassCreation,
        ...(() => {
          if (!templateId) {
            return disablingReasons.get('no-niche');
          }

          if (disableCreationActions) {
            return disablingReasons.get('action-pending');
          }

          if (projectCollidingGlasses.size > 0) {
            return disablingReasons.get('glass-collisions');
          }

          if (!hasSufficientAreaForGlassCreation) {
            return disablingReasons.get('space-filled');
          }

          if (hasBlankGlass) {
            return disablingReasons.get('glass-is-blank');
          }

          return undefined;
        })(),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    glassOptions,
    templateId,
    hasSufficientAreaForGlassCreation,
    disableCreationActions,
    projectCollidingGlasses,
    // explicitly listen to the size of the set, too, as the reference can stay the same despite getting updated
    projectCollidingGlasses.size,
    hasBlankGlass,
  ]);

  const insertGap = useMemo<InsertCategory[]>(() => {
    const disablingReasons = new Map([
      [
        'no-glass',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.requireGlass'),
        },
      ],
      [
        'action-pending',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.creationActionInProgress'),
        },
      ],
      [
        'no-niche',
        {
          disabled: true,
          disabledReason: t('toolbar.tooltips.requireNiche'),
        },
      ],
    ]);
    return [
      {
        title: t('toolbar.gap'),
        options: gapOptions,
        ...(() => {
          if (disableCreationActions) {
            return disablingReasons.get('action-pending');
          }

          if (!templateId) {
            return disablingReasons.get('no-niche');
          }

          if (projectGlasses.length < 1) {
            return disablingReasons.get('no-glass');
          }

          return undefined;
        })(),
      },
    ];
  }, [
    gapOptions,
    projectGlasses.length,
    t,
    templateId,
    disableCreationActions,
  ]);

  const insertCategories = useMemo(
    () => [...insertNiche, ...insertGlass, ...insertGap],
    [insertGap, insertGlass, insertNiche],
  );

  const projectionOptions = useMemo<SwitchOption[]>(
    () => [
      {
        title: t('toolbar.projection.front'),
        value: Projection.FRONT,
      },
      {
        title: t('toolbar.projection.top'),
        value: Projection.TOP,
      },
    ],
    [t],
  );

  const scaleOptions = useMemo<DropdownOption[]>(
    () => [
      { title: '200%', value: Scale['200%'] },
      { title: '150%', value: Scale['150%'] },
      { title: '100%', value: Scale['100%'] },
      { title: '50%', value: Scale['50%'] },
      { title: '20%', value: Scale['20%'] },
    ],
    [],
  );

  const selectedDimensions = useMemo(() => {
    if (selected.length > 0) {
      const firstItem = selected[0];

      switch (firstItem.type) {
        case 'glass': {
          const glass = projectGlasses.find(
            (item) => item.id === firstItem.shapeId,
          );

          return {
            width: glass?.width ?? 0,
            height: glass?.height ?? 0,
          };
        }
        case 'template': {
          return {
            width: project?.data.width ?? 0,
            height: project?.data.height ?? 0,
          };
        }
        default: {
          return {
            width: 0,
            height: 0,
          };
        }
      }
    }
    return {
      width: 0,
      height: 0,
    };
  }, [selected, project, projectGlasses]);

  return {
    insertNiche,
    insertGap,
    insertGlass,
    insertCategories,
    projectionOptions,
    scaleOptions,
    selectedDimensions,
  };
};

export default useToolbarConfig;
