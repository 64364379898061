import { DEFAULT_RATIO } from '../config';
import { State } from './project';
import { Bounds } from '../../types';

export const selectProject = (state: State) => state.project;

export const selectHistoryPointer = (state: State) => state.historyPointer;

export const selectClear = (state: State) => state.clear;

export const selectUndo = (state: State) => state.undo;

export const selectRedo = (state: State) => state.redo;

export const selectShowAngles = (state: State) => state.showAngles;

export const selectOpenLeftPanel = (state: State) => state.openLeftPanel;

export const selectOpenRightPanel = (state: State) => state.openRightPanel;

export const selectToggleLeftPanel = (state: State) => state.toggleLeftPanel;

export const selectToggleRightPanel = (state: State) => state.toggleRightPanel;

export const selectToggleShowAngles = (state: State) => state.toggleShowAngles;

export const selectSaved = (state: State) => state.saved;

export const selectSave = (state: State) => state.save;

export const selectSetup = (state: State) => state.setup;

export const selectUpdate = (state: State) => state.update;

export const selectUpdateDimensions = (state: State) => state.updateDimensions;
export const selectUpdateCorners = (state: State) => state.updateCorners;

export const selectUpdatePosition = (state: State) => state.updatePosition;

export const selectUpdateHistory = (state: State) => state.updateHistory;

export const selectSetProjectName = (state: State) => state.setProjectName;

export const selectAddGlass = (state: State) => state.addGlass;

export const selectPasteGlass = (state: State) => state.pasteGlass;

export const selectUpdateGlass = (state: State) => state.updateGlass;

export const selectUpdateGlassList = (state: State) => state.updateGlassList;

export const selectDeleteGlass = (state: State) => state.deleteGlass;

export const selectProjectGlasses = (state: State) =>
  state.project?.data.glassSheets ?? [];

export const selectAddProduct = (state: State) => state.addProduct;

export const selectPasteProduct = (state: State) => state.pasteProduct;

export const selectUpdateProduct = (state: State) => state.updateProduct;

export const selectUpdateManyProducts = (state: State) =>
  state.updateManyProducts;

export const selectDeleteProduct = (state: State) => state.deleteProduct;

export const selectDeleteManyProducts = (state: State) =>
  state.deleteManyProducts;

export const selectProjectProducts = (state: State) =>
  state.project?.data.products ?? [];

export const selectUsedProducts = (state: State) =>
  state.project?.productsUsed ?? [];

export const selectUsedProduct = (id: number) => (state: State) =>
  state.project?.productsUsed[id];

export const selectProjectPosition = (state: State) =>
  state.project?.data.position ?? { x: 0, y: 0 };

export const selectUnsafeProject = (state: State) => state.unsafeProject;

export const selectConnectedProducts = (iid: string) => (state: State) =>
  state.project
    ? state.project.data.products.filter((product) =>
        product.connections.some((connect) => connect.targetId === iid),
      )
    : [];

export const selectIndent = (state: State) =>
  state.project?.data.dimensions.indent;

export const selectClearIndent = (state: State) => state.clearIndent;

export const selectSetIndent = (state: State) => state.setIndent;

export const selectGlassNeighbors = (state: State) => state.glassNeighbors;

export const selectUpdateGlassNeighbors = (state: State) =>
  state.updateGlassNeighbors;

export const selectData = (state: State) => state.project?.data;

export const selectToggleAdjustManually = (state: State) =>
  state.toggleAdjustManually;

export const selectValidatedHistoryPointer = (state: State) =>
  state.validatedHistoryPointer;

export const selectSetValidatedHistoryPointer = (state: State) =>
  state.setValidatedHistoryPointer;

export const selectValidateProject = (state: State) => state.validateProject;

export const selectToggleValidateProject = (state: State) =>
  state.toggleValidateProject;

export const selectCollidingGlasses = (state: State) => state.collidingGlasses;
