import * as React from 'react';
import { useMemo } from 'react';
import { AlignTitle, Container, ItemRow, TitleRow } from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { NicheItem } from './types';
import { useTranslation } from 'react-i18next';
import { Lshape, Rectangle, Rlshape, Square } from '../images/projects';
import Item from './Item';
import { InsertType } from '../Insert';

export interface Props {
  onSelect(niche: NicheItem): void;
  title: string;
  alignTitle?: AlignTitle;
}

const InsertNiche: React.FC<Props> = React.memo(
  ({ onSelect, title, alignTitle = 'left' }) => {
    const { t } = useTranslation('project');

    const items = useMemo<NicheItem[]>(
      () => [
        {
          title: t('niche.square'),
          Image: <Square viewBox="0 0 109 109" />,
          value: 'square',
          type: InsertType.NICHE,
        },
        {
          title: t('niche.rectangle'),
          Image: <Rectangle viewBox="0 0 90 109" />,
          value: 'rectangle',
          type: InsertType.NICHE,
        },
        {
          title: t('niche.l-shape'),
          Image: <Lshape viewBox="0 0 109 109" />,
          value: 'lShape',
          type: InsertType.NICHE,
        },
        {
          title: t('niche.l-shapeRevert'),
          Image: <Rlshape viewBox="0 0 109 109" />,
          value: 'lShapeRevert',
          type: InsertType.NICHE,
        },
      ],
      [t],
    );

    return (
      <Container data-testid="insert-niche">
        <TitleRow aria-label="title" align={alignTitle}>
          <Typography variant={TypographyVariants.H4_BOLD}>{title}</Typography>
        </TitleRow>
        <ItemRow>
          {items.map((item) => (
            <Item key={item.value} niche={item} onClick={onSelect} />
          ))}
        </ItemRow>
      </Container>
    );
  },
);

InsertNiche.displayName = 'InsertNiche';

export default InsertNiche;
