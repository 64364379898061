import * as React from 'react';

const SvgInfo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16.001A8 8 0 0112 20z"
        fill={props.fill ?? '#272B5F'}
      />
      <path
        d="M12 9a1 1 0 100-2 1 1 0 000 2zM12 10a1 1 0 00-1 1v5a1 1 0 002 0v-5a1 1 0 00-1-1z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgInfo = React.memo(SvgInfo);
export default MemoSvgInfo;
