import * as React from 'react';
import {
  AddonContainer,
  AddonControl,
  AddonControlsWrapper,
  UnitWrapper,
} from './styles';
import { Typography } from '../Typography';
import { DownFill, UpFill } from '../icons';
import { Variants } from '../Typography/variants';

export interface Props {
  onIncrease(e: React.MouseEvent<HTMLDivElement>): void;
  onDecrease(e: React.MouseEvent<HTMLDivElement>): void;
  unit?: string;
  disabled?: boolean;
  withButton?: boolean;
}

const NumberAddon: React.FC<Props> = React.memo(
  ({ unit, onIncrease, onDecrease, disabled, withButton }) => {
    return (
      <AddonContainer withButton={withButton}>
        <UnitWrapper>
          <Typography variant={Variants.H5}>{unit}</Typography>
        </UnitWrapper>
        <AddonControlsWrapper>
          <AddonControl
            aria-label="up-button"
            role="button"
            onClick={!disabled ? onIncrease : undefined}>
            <UpFill />
          </AddonControl>
          <AddonControl
            aria-label="down-button"
            role="button"
            onClick={!disabled ? onDecrease : undefined}>
            <DownFill />
          </AddonControl>
        </AddonControlsWrapper>
      </AddonContainer>
    );
  },
);

NumberAddon.displayName = 'NumberAddon';

export default NumberAddon;
