import * as React from 'react';
import {
  PasswordChecklist as Container,
  ChecklistItem,
  Checkbox,
} from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { useTranslation } from 'react-i18next';

export interface ValidChecklist {
  requirement1: boolean;
  requirement2: boolean;
}

export interface Props {
  validation: ValidChecklist;
}

const PasswordChecklist: React.FC<Props> = React.memo(({ validation }) => {
  const { t } = useTranslation('login');

  return (
    <Container data-testid="password-checklist">
      <ChecklistItem checked={validation.requirement1}>
        <Checkbox checked={validation.requirement1} data-testid="checkbox-1" />
        <Typography variant={TypographyVariants.H5}>
          {t('passwordCheck.requirement1')}
        </Typography>
      </ChecklistItem>
      <ChecklistItem checked={validation.requirement2}>
        <Checkbox checked={validation.requirement2} data-testid="checkbox-2" />
        <Typography variant={TypographyVariants.H5}>
          {t('passwordCheck.requirement2')}
        </Typography>
      </ChecklistItem>
    </Container>
  );
});

PasswordChecklist.displayName = 'PasswordChecklist';

export default PasswordChecklist;
