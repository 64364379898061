import {
  BaseType,
  SymetryOptions,
} from '../../../../services/api/models/product';
import { Bar } from '../Bar';
import { Projection } from '../../types';
import { Pipe } from '../Pipe';
import { Image } from 'react-konva';
import * as React from 'react';
import { useMemo } from 'react';
import Konva from 'konva';
import { calculateProductRotation } from '../../utils/product';
import { Connection } from '../../../../services/api/models/connection';

interface ProductElementProps {
  baseType: BaseType;
  shapeRef: React.MutableRefObject<any>;
  isSelected: boolean | undefined;
  handleSelect: () => void;
  openContextMenu: (e: Konva.KonvaEventObject<PointerEvent>) => void;
  shapeWidth: number;
  shapeHeight: number;
  shapeThickness: number;
  projection: Projection;
  openPipeContextmenu: (e: Konva.KonvaEventObject<PointerEvent>) => void;
  stroke: {
    strokeWidth: number;
    stroke: string;
  };
  symmetryOptions?: SymetryOptions;
  anchor: Connection['anchor'];
  edgePoints: [[number, number], [number, number]] | undefined;
  image: HTMLImageElement | undefined;
  topImage: HTMLImageElement | undefined;
}

const ProductElement = ({
  baseType,
  shapeRef,
  isSelected,
  handleSelect,
  openContextMenu,
  shapeWidth,
  shapeHeight,
  shapeThickness,
  projection,
  openPipeContextmenu,
  stroke,
  symmetryOptions,
  anchor,
  edgePoints,
  image,
  topImage,
}: ProductElementProps) => {
  const transform = useMemo(() => {
    if (symmetryOptions) {
      return calculateProductRotation(
        baseType,
        { width: shapeWidth, height: shapeHeight },
        anchor,
        symmetryOptions,
        edgePoints,
      );
    }
  }, [baseType, shapeWidth, shapeHeight, anchor, symmetryOptions, edgePoints]);

  if (projection === Projection.TOP) {
    switch (baseType) {
      case BaseType.GASKET:
      case BaseType.BAR: {
        return null;
      }
      case BaseType.PIPE: {
        return null;
      }
      default: {
        return (
          <Image
            {...stroke}
            {...transform}
            width={shapeWidth}
            height={shapeThickness}
            image={topImage}
            onMouseDown={handleSelect}
            onTouchStart={handleSelect}
            onContextMenu={openContextMenu}
            visible={projection === Projection.TOP}
          />
        );
      }
    }
  } else {
    switch (baseType) {
      case BaseType.GASKET:
      case BaseType.BAR: {
        return (
          <Bar
            ref={shapeRef}
            isSelected={isSelected}
            handleSelect={handleSelect}
            onContextMenu={openContextMenu}
            width={shapeWidth}
            height={shapeHeight}
            rotationDeg={transform?.rotation}
            visible={projection === Projection.FRONT}
          />
        );
      }
      case BaseType.PIPE: {
        return (
          <Pipe
            ref={shapeRef}
            isSelected={isSelected}
            handleSelect={handleSelect}
            onContextMenu={openPipeContextmenu}
            height={shapeHeight}
            width={shapeWidth}
            visible={projection === Projection.FRONT}
          />
        );
      }
      default: {
        return (
          <Image
            {...stroke}
            {...transform}
            ref={shapeRef}
            image={image}
            onMouseDown={handleSelect}
            onTouchStart={handleSelect}
            onContextMenu={openContextMenu}
            visible={projection === Projection.FRONT}
          />
        );
      }
    }
  }
};

ProductElement.displayName = 'ProductElement';

export default ProductElement;
