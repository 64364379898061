import { State } from './navigation';

export const selectActivePage = (state: State) => state.activePage;

export const selectSetActivePage = (state: State) => state.setActivePage;

export const selectShowMainMenu = (state: State) => state.showMainMenu;

export const selectOpenMainMenu = (state: State) => state.openMainMenu;

export const selectCloseMainMenu = (state: State) => state.closeMainMenu;

export const selectActiveErrorPage = (state: State) => state.activeErrorPage;

export const selectRedirectToError = (state: State) => state.redirectToError;

export const selectClearError = (state: State) => state.clearError;
