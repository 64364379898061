import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AxiosResponse } from 'axios';

import {
  ProductProperty,
  ProductSearch,
} from '../../services/api/models/product';
import {
  GetProductsFilterRequest,
  GetProductsCommand,
} from '../../services/api/models/product';
import { productApi, productAdminApi } from '../../services/api/services';
import { Page } from '../../services/api/models/requests';
import { createAsyncAction } from '../middlewares/actions';
import {
  ChangeProductsStatusCommand,
  DeleteProductsCommand,
} from '../../services/api/services/productAdmin';

export const STORE_NAME = `@store/products/filter`;

export type State = {
  loading: boolean;
  filter(
    admin: boolean,
    data?: GetProductsFilterRequest,
    onSuccess?: () => void,
  ): Promise<void>;
  filterByGroup(
    group: keyof GetProductsCommand,
    property: ProductProperty,
  ): Promise<void>;
  changeStatus(
    payload: ChangeProductsStatusCommand,
    onSuccess?: (deletedItems: string | number, active: boolean) => void,
  ): Promise<void>;
  delete(
    payload: DeleteProductsCommand,
    onSuccess?: (deletedItems: string | number) => void,
  ): Promise<void>;
  data?: Page<ProductSearch>;
  dataByGroup?: Partial<
    Record<keyof GetProductsCommand, Record<string, ProductSearch[]>>
  >;
  error?: AxiosResponse;
};

const filterAction = (set: SetState<State>) => (
  admin: boolean,
  payload?: GetProductsFilterRequest,
  onSuccess?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      const { data } = admin
        ? await productAdminApi.filterProducts(payload)
        : await productApi.filterProducts(payload);
      set({ data });
    },
    undefined,
    onSuccess,
  );

const filterByGroupAction = (set: SetState<State>, get: GetState<State>) => (
  group: keyof GetProductsCommand,
  property: ProductProperty,
) =>
  createAsyncAction(set)(async () => {
    const payload: GetProductsFilterRequest = {
      command: { [group]: [property.id] },
    };
    const { data } = await productApi.filterAllProducts(payload);
    const groups = get().dataByGroup;
    set({
      dataByGroup: {
        ...groups,
        [group]: { ...(groups?.[group] ?? []), [property.name]: data },
      },
    });
  });

const changeStatusAction = (set: SetState<State>, get: GetState<State>) => (
  payload: ChangeProductsStatusCommand,
  onSuccess?: (items: string | number, active: boolean) => void,
) =>
  createAsyncAction(set)(async () => {
    await productAdminApi.changeProductsStatus(payload);
    const { productIds, active } = payload;
    if (productIds.length === 1) {
      const productCode = get().data?.content.find(
        (product) => product.id === productIds[0],
      );
      onSuccess?.(productCode?.code ?? '', active);
    } else {
      onSuccess?.(productIds.length, active);
    }
  });

const deleteAction = (set: SetState<State>, get: GetState<State>) => (
  payload: DeleteProductsCommand,
  onSuccess?: (deletedItems: string | number) => void,
) =>
  createAsyncAction(set)(async () => {
    await productAdminApi.deleteProducts(payload);
    const { productIds } = payload;
    if (productIds.length === 1) {
      const productCode = get().data?.content.find(
        (product) => product.id === productIds[0],
      );
      onSuccess?.(productCode?.code ?? '');
    } else {
      onSuccess?.(productIds.length);
    }
  });

const store = (set: SetState<State>, get: GetState<State>) => ({
  loading: false,
  filter: filterAction(set),
  filterByGroup: filterByGroupAction(set, get),
  changeStatus: changeStatusAction(set, get),
  delete: deleteAction(set, get),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
