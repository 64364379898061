import { RefObject, useCallback, useEffect } from 'react';

export type CorrectOverflow = 'top' | 'bottom' | 'left' | 'right';

const OFFSET = 5;

const usePreventOverflow = (
  elementRef: RefObject<HTMLElement>,
  watch?: boolean,
  correct: CorrectOverflow | CorrectOverflow[] = [
    'top',
    'bottom',
    'left',
    'right',
  ],
) => {
  const correctTop = useCallback(
    (top: number) => {
      if (elementRef.current && top < 0) {
        elementRef.current.style.transform = `translateY(calc(-50% - ${
          top + OFFSET
        }px))`;
      }
    },
    [elementRef],
  );

  const correctBottom = useCallback(
    (bottom: number) => {
      const { innerHeight } = window;
      const value = innerHeight - bottom;
      if (elementRef.current && value < 0) {
        elementRef.current.style.transform = `translateY(calc(-50% + ${
          value + OFFSET
        }px))`;
      }
    },
    [elementRef],
  );

  const correctLeft = useCallback(
    (left: number) => {
      if (elementRef.current && left < 0) {
        elementRef.current.style.transform = `translate(${
          -left + OFFSET
        }px, -50%)`;
      }
    },
    [elementRef],
  );

  const correctRight = useCallback(
    (right: number) => {
      const { innerWidth } = window;
      const value = innerWidth - right;
      if (elementRef.current && value < 0) {
        elementRef.current.style.transform = `translate(${
          value + OFFSET
        }px, -50%)`;
      }
    },
    [elementRef],
  );

  useEffect(() => {
    if (elementRef.current) {
      const {
        top,
        bottom,
        left,
        right,
      } = elementRef.current.getBoundingClientRect();

      if (correct === 'top' || correct.includes('top')) {
        correctTop(top);
      }
      if (correct === 'bottom' || correct.includes('bottom')) {
        correctBottom(bottom);
      }
      if (correct === 'left' || correct.includes('left')) {
        correctLeft(left);
      }
      if (correct === 'right' || correct.includes('right')) {
        correctRight(right);
      }
    }
  }, [
    correct,
    correctBottom,
    correctLeft,
    correctRight,
    correctTop,
    elementRef,
    watch,
  ]);
};

export default usePreventOverflow;
