import ReactDom from 'react-dom';
import * as React from 'react';
import { Container, CloseButton, ContentWrapper } from './styles';
import { Close } from '../icons';
import { useCallback, useEffect } from 'react';
import { theme } from '../../styles';

export interface Props {
  onClose(): void;
  backgroundColor?: string;
  alignItems?: 'center' | 'flex-start';
}

const Modal: React.FC<Props> = React.memo(
  ({
    children,
    onClose,
    backgroundColor = theme.colors.white,
    alignItems = 'center',
  }) => {
    const setGlobalOverflow = useCallback((state: 'hidden' | 'unset') => {
      document.body.style.overflow = state;
    }, []);

    useEffect(() => {
      setGlobalOverflow('hidden');
      return () => setGlobalOverflow('unset');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ReactDom.createPortal(
      <Container
        role="dialog"
        backgroundColor={backgroundColor}
        alignItems={alignItems}>
        <CloseButton role="button" onClick={onClose}>
          <Close />
        </CloseButton>
        <ContentWrapper>{children}</ContentWrapper>
      </Container>,
      document.body,
    );
  },
);

Modal.displayName = 'Modal';

export default Modal;
