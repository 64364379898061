import * as React from 'react';
import { CategoryList as Container } from './styles';
import { InsertCategory, InsertOption } from './types';
import CategoryOption from './CategoryOption';
import { NicheItem } from '../InsertNiche';

export interface Props {
  categories: InsertCategory[];
  onSelect(option: InsertOption | NicheItem): void;
}

const CategoryList: React.FC<Props> = React.memo(({ categories, onSelect }) => {
  return (
    <Container
      aria-label="category-list"
      data-testid="category-list"
      role="listbox">
      {categories.map((category) => (
        <CategoryOption
          key={category.title}
          category={category}
          onSelect={onSelect}
        />
      ))}
    </Container>
  );
});

CategoryList.displayName = 'CategoryList';

export default CategoryList;
