import styled from 'styled-components';
import { layout } from '../../../../../../styles';
import { Input } from '../../../../../../components/Input';

export const Container = styled.div`
  > span {
    display: inline-block;
    margin-bottom: 16px;
  }
`;

export const InputsGrid = styled(layout.Grid)`
  grid-template-columns: 105px 105px 105px;
  grid-template-rows: 40px 40px 40px;
  column-gap: 12px;
  row-gap: 18px;
  justify-content: stretch;
`;

export const TopInput = styled(Input)`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  align-items: center;
`;

export const BottomInput = styled(Input)`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 3;
  align-items: center;
`;

export const LeftInput = styled(Input)`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
`;

export const RightInput = styled(Input)`
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
`;

export const Placeholder = styled.div`
  width: 105px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  background-color: ${({ theme }) => theme.colors.grey4};
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
`;
