import * as React from 'react';

const SvgPl = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
        fill="#F0F0F0"
      />
      <path d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12" fill="#D80027" />
    </svg>
  );
};

const MemoSvgPl = React.memo(SvgPl);
export default MemoSvgPl;
