import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { creatorProject } from '../../../../../../modules/creator';
import { findBoundaries } from '../../../../../../modules/creator/utils/boundaries';
import {
  selectClose,
  selectModals,
  useModalsStore,
} from '../../../../../../store/modals';
import { PanelHeader } from '../../components/PanelHeader';
import { useTranslation } from 'react-i18next';
import { useCreator, useGlassProperties } from '../../../../hooks';
import { selectData } from '../../../../../../modules/creator/store/project';
import { Glass } from '../../../../../../services/api/models/glass';
import GlassFormView from './GlassFormView';

const {
  useProjectStore,
  selectProjectProducts,
  selectProjectPosition,
} = creatorProject;

interface GlassFormProps {
  glass: Glass;
  glasses: Glass[];
}

const GlassForm = ({ glass, glasses }: GlassFormProps) => {
  const { t } = useTranslation('project');

  const projectProducts = useProjectStore(selectProjectProducts);
  const templatePosition = useProjectStore(selectProjectPosition);
  const { dimensions: templateDimensions } = useProjectStore(selectData);
  const close = useModalsStore(selectClose);
  const modals = useModalsStore(selectModals);

  const {
    propertiesInputs,
    updatePosition,
    updateDimensions,
    validateThicknessChange,
    thicknessValidResolve,
    toggleAdjustManually,
  } = useGlassProperties(glass);

  const { templateId, selectShape, selected: templateSelected } = useCreator();

  const isTemplateSelected = useMemo(
    () => templateSelected[0]?.type === 'template',
    [templateSelected],
  );

  const selectTemplate = useCallback(() => {
    if (templateId) {
      selectShape({ shapeId: templateId, type: 'template' });
    }
  }, [selectShape, templateId]);

  const mountedProducts = useMemo(
    () =>
      projectProducts.filter((product) =>
        product.connections.some(
          (connection) => connection.targetId === String(glass?.id),
        ),
      ),
    [glass, projectProducts],
  );

  const boundaries = useMemo(() => {
    if (!glass) return;

    const shapes = glasses
      .filter((item) => item.id !== glass.id)
      .map(({ corners, position }) => ({
        corners,
        position,
      }));

    const config = {
      shape: {
        position: glass.position,
        corners: glass.corners,
      },
      shapes,
      parents: [
        {
          position: templatePosition,
          corners: templateDimensions.corners,
          indent: templateDimensions.indent,
        },
      ],
      scale: 1,
    };

    return findBoundaries(config);
  }, [
    glass.position,
    glass.corners,
    glasses,
    templatePosition,
    templateDimensions.corners,
  ]);

  if (!boundaries || !boundaries.left.length) return null;

  return (
    <>
      <PanelHeader
        title={t('propertyPanel.titles.glass')}
        actionTitle={t('propertyPanel.actionTitle')}
        onAction={selectTemplate}
        withAction={!isTemplateSelected}
        adjustManually={glass.adjustManually ?? false}
        onToggleAdjustManually={toggleAdjustManually}
      />
      <GlassFormView
        selectedGlass={glass}
        glasses={glasses}
        mountedProducts={mountedProducts}
        boundaries={boundaries}
        modals={modals}
        templatePosition={templatePosition}
        templateDimensions={templateDimensions}
        propertiesInputs={propertiesInputs}
        onModalClose={close}
        onUpdateDimensions={updateDimensions}
        onUpdatePosition={updatePosition}
        onThicknessChange={validateThicknessChange}
        onThicknessValid={thicknessValidResolve}
      />
    </>
  );
};

GlassForm.displayName = 'GlassForm';

export default GlassForm;
