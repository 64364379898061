import i18next, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dotenvLang from './dotenvLng';
import dictPL from './dict/pl';
import dictEN from './dict/enGB';
import dictIT from './dict/it';
import dictRU from './dict/ru';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(dotenvLang);

export type Locales = 'pl' | 'ru' | 'it' | 'enGB';

const resources: Record<Locales, ResourceLanguage> = {
  pl: dictPL,
  it: dictIT,
  enGB: dictEN,
  ru: dictRU,
};

const detection = {
  order: ['localStorage', 'dotenv'],
  caches: ['localStorage'],
};

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection,
    fallbackLng: 'pl',
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
