import * as React from 'react';

const SvgUpFill = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M16.21 16H7.79a1.76 1.76 0 01-1.59-1 2.1 2.1 0 01.26-2.21l4.21-5.1a1.76 1.76 0 012.66 0l4.21 5.1A2.1 2.1 0 0117.8 15a1.76 1.76 0 01-1.59 1z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgUpFill = React.memo(SvgUpFill);
export default MemoSvgUpFill;
