import styled, { css } from 'styled-components';
import { InputStyles } from '../Input';

const disabledIcon = css`
  pointer-events: none;
  > svg > path {
    fill: ${({ theme }) => theme.colors.grey};
  }
`;

const disabledBadge = css`
  background: ${({ theme }) => theme.colors.grey2};
  > span {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

const enabledButton = css`
  cursor: pointer;
  &:hover {
    opacity: 0.44;
  }
  > svg {
    > path {
      fill: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;

const with_clear = css`
  padding-right: 50px;
`;

const labelBottomMargin = css`
  > span:first-child {
    margin-bottom: 4px;
  }
`;

export const Container = styled.div<{ labeled: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ labeled }) => labeled && labelBottomMargin};
`;

export const Label = styled(InputStyles.Label)``;

export const InputWrapper = styled(InputStyles.InputWrapper)<{
  width?: number;
  fullWidth?: boolean;
}>`
  height: unset;
  align-items: flex-start;
`;

export const Input = styled(InputStyles.Input)<{
  search?: boolean;
  clearable?: boolean;
}>`
  height: 40px;
  cursor: ${({ search }) => (search ? 'text' : 'pointer')};
  ${({ clearable }) => clearable && with_clear}
  &:disabled {
    cursor: default;
  }
`;

const unfoldTop = css`
  top: unset;
  bottom: 100%;
`;

export const BoxWrapper = styled.div<{ unfoldOnTop?: boolean }>`
  position: absolute;
  width: inherit;
  left: 0;
  top: 100%;
  z-index: 1;
  ${({ unfoldOnTop }) => unfoldOnTop && unfoldTop};
`;

export const BoxContainer = styled.div<{
  boxContainerHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  width: inherit;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 10px 10px rgba(39, 43, 95, 0.08);
  overflow: hidden;
  overflow-y: ${({ boxContainerHeight }) => boxContainerHeight && 'scroll'};
  height: ${({ boxContainerHeight }) =>
    boxContainerHeight && `${boxContainerHeight}px`};
`;

export const BoxOption = styled.div<{ minWidth?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  padding: 10px 15px;
  cursor: pointer;
  min-width: ${({ minWidth }) => minWidth ?? 'unset'};
  box-sizing: border-box;
  &:hover {
    background: ${({ theme }) => theme.colors.grey4};
  }
  &:active {
    > span {
      color: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;

export const BoxCustomOption = styled.div`
  padding: 10px 15px 10px 7px;
  display: flex;
  flex-direction: row;
`;

export const CustomOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    opacity: 0.66;
  }
  > svg {
    width: 18px;
    height: 18px;
    path {
      fill: ${({ theme }) => theme.colors.accentBlue};
    }
  }
  > span {
    color: ${({ theme }) => theme.colors.accentBlue};
    cursor: pointer;
  }
`;

export const BoxLabel = styled.div`
  padding: 16px 15px 6px;
`;

export const Icon = styled(InputStyles.TrailingIcon)<{ disabled?: boolean }>`
  top: 12px;
  cursor: pointer;
  ${({ disabled }) => disabled && disabledIcon}
`;

export const ClearButton = styled(Icon)`
  right: 30px;
  top: 13px;
  ${InputStyles.clickable};
`;

export const MultiselectBox = styled.div<{
  invalid?: boolean;
  disabled?: boolean;
  search?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: inherit;
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  background: ${({ theme }) => theme.colors.white};
  align-items: flex-start;
  &:hover {
    background: ${({ theme }) => theme.colors.grey4};
  }
  &:focus-within,
  &:active {
    border: 1px solid ${({ theme }) => theme.colors.mainBlue};
  }
  cursor: ${({ search }) => (search ? 'default' : 'pointer')};
  ${({ disabled }) => disabled && InputStyles.disabled_style};
  ${({ invalid }) => invalid && InputStyles.invalid_style};
`;

export const MultiselectInputWrapper = styled.div`
  box-sizing: border-box;
  flex-flow: row wrap;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 10px 38px 10px 8px;
`;

export const SearchInput = styled.input`
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  width: 50px;
  outline: 0;
  border: 0;
  background: transparent;
  padding: 2px 0;
  color: ${({ theme }) => theme.colors.dark};
  ${InputStyles.input_font};
`;

export const MultiselectIcon = styled.div<{ disabled?: boolean }>`
  box-sizing: border-box;
  flex-shrink: 0;
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  > svg {
    width: 16px;
    height: 16px;
  }
  ${({ disabled }) => disabled && disabledIcon}
`;

export const SelectBadge = styled.div<{ disabled?: boolean }>`
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.accentBlue3};
  min-height: 12px;
  width: fit-content;
  align-items: center;
  justify-content: space-between;
  padding: 3px 4px;
  border-radius: 4px;
  margin: 2px 4px 0 0;
  > span {
    color: ${({ theme }) => theme.colors.accentBlue};
  }
  ${({ disabled }) => disabled && disabledBadge}
`;

export const BadgeIconWrapper = styled.div<{ disabled?: boolean }>`
  width: 12px;
  line-height: 12px;
  > svg {
    width: 12px;
    height: 12px;
    > path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
  ${({ disabled }) => !disabled && enabledButton}
`;

export const ErrorMessage = styled.div`
  width: fit-content;
  > span {
    color: ${({ theme }) => theme.colors.validation};
  }
`;
