import { AxiosInstance } from 'axios';
import { stringify } from 'qs';

import { Endpoints } from '../endpoints';
import { instance_v2 } from '../config';
import {
  AllProductProperties,
  EditGlassPropertyCommand,
  GetProductsFilterRequest,
  Product,
  ProductSearch,
} from '../models/product';
import { Page } from '../models/requests';

class ProductApi {
  constructor(private http: AxiosInstance) {}

  public getProduct = (id: number) =>
    this.http.get<Product>(`${Endpoints.PRODUCT}/${id}`);

  public updateGlassProperty = (
    id: number,
    command: EditGlassPropertyCommand[],
  ) => this.http.put<Product>(`${Endpoints.PRODUCT}/${id}`, command);

  public getProductProperties = () =>
    this.http.get<AllProductProperties>(`${Endpoints.PRODUCT}/properties`);

  public filterProducts = ({
    command,
    pageable,
  }: GetProductsFilterRequest = {}) => {
    return this.http.post<Page<ProductSearch>>(
      `${Endpoints.PRODUCT}/filter?${stringify(pageable)}`,
      command ?? {},
    );
  };

  public filterAllProducts = ({
    command,
  }: Omit<GetProductsFilterRequest, 'pageable'> = {}) => {
    return this.http.post<ProductSearch[]>(
      `${Endpoints.PRODUCT}/all/filter`,
      command ?? {},
    );
  };
}
// TODO: Temporary instance caused by big BE changes, needs to be removed
const productApi = new ProductApi(instance_v2);
export default productApi;
