import { selectProjectGlasses, useProjectStore } from '../store/project';
import useGlassGlassProduct from './useGlassGlassProduct';
import { useCallback } from 'react';
import { Neighbor, NeighborGlass, NeighborType } from '../utils/neighbors';
import { Glass } from '../../../services/api/models/glass';
import { Anchor } from '../types/Anchor';
import isEmpty from 'lodash/isEmpty';

export const useGlassNeighbors = () => {
  const projectGlasses = useProjectStore(selectProjectGlasses);
  const { getGlassNeighbors } = useGlassGlassProduct();

  const findClosestNeighbor = useCallback(
    (neighbors: Neighbor[]) => {
      const neighbor = neighbors.sort((a, b) => a.distance - b.distance)[0];

      if (neighbor?.data?.type === NeighborType.GLASS) {
        return projectGlasses.find(
          (item) => item.id === (neighbor.data as NeighborGlass).glass.id,
        );
      }

      return undefined;
    },
    [projectGlasses],
  );

  const findGlassNeighbors = useCallback(
    (glass: Glass) => {
      const glassNeighbors = getGlassNeighbors(glass.id);
      const closestNeighbors: Record<Anchor, Glass | undefined> = {} as Record<
        Anchor,
        Glass | undefined
      >;

      if (!glassNeighbors || isEmpty(glassNeighbors)) {
        return closestNeighbors;
      }

      Object.entries(glassNeighbors).forEach(([edge, data]) => {
        if (data && data.length) {
          closestNeighbors[edge as Anchor] = findClosestNeighbor(data);
        }
      });

      return closestNeighbors;
    },
    [findClosestNeighbor, getGlassNeighbors],
  );

  return {
    findClosestNeighbor,
    findGlassNeighbors,
  };
};
