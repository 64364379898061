import * as React from 'react';

const SvgCatalog = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={185}
      height={125}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={34.926}
        width={114.99}
        height={114.99}
        rx={14}
        transform="rotate(4.644 34.926 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#catalog_svg__filter0_d)">
        <rect x={10} y={17} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={52} y={28} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={52} y={43} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={17} y={23} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M36 42.666H26a1.647 1.647 0 01-1.667-1.62v-8.093A1.647 1.647 0 0126 31.333h3.066a.667.667 0 01.514.247l1.733 2.12h4.667a1.647 1.647 0 011.686 1.62v5.727A1.646 1.646 0 0136 42.666zm-10.334-4.493v2.873a.307.307 0 00.334.287h10a.306.306 0 00.333-.286V35.32a.307.307 0 00-.333-.287h-5a.667.667 0 01-.514-.247l-1.733-2.12H26a.306.306 0 00-.334.287v5.22z"
        fill="#fff"
      />
      <g filter="url(#catalog_svg__filter1_d)">
        <rect x={25} y={65} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={67} y={76} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={67} y={91} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={32} y={71} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M51 90.666H41a1.647 1.647 0 01-1.667-1.62v-8.093A1.647 1.647 0 0141 79.333h3.066a.667.667 0 01.514.247l1.733 2.12h4.667a1.647 1.647 0 011.686 1.62v5.727A1.646 1.646 0 0151 90.666zm-10.334-4.493v2.873a.307.307 0 00.334.287h10a.306.306 0 00.333-.287V83.32a.307.307 0 00-.333-.287h-5a.667.667 0 01-.514-.246l-1.733-2.12H41a.306.306 0 00-.334.286v5.22z"
        fill="#fff"
      />
      <defs>
        <filter
          id="catalog_svg__filter0_d"
          x={0}
          y={17}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="catalog_svg__filter1_d"
          x={15}
          y={65}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgCatalog = React.memo(SvgCatalog);
export default MemoSvgCatalog;
