import {
  selectClose,
  selectModals,
  selectOpen,
  Modals,
  useModalsStore,
} from '../../../store/modals';
import { useCallback } from 'react';
import { projectApi } from '../../../services/api/services';
import { NotificationVariants } from '../../../components/Notification';
import {
  Project,
  ProjectData,
  ProjectModules,
  ProjectStatus,
} from '../../../services/api/models/project';
import { selectUpdate, useProjectStore } from '../../../store/projects';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../store/notifications';
import { useTranslation } from 'react-i18next';
import {
  selectUpdate as selectTemplateUpdate,
  useTemplateStore,
} from '../../../store/templates';
import {
  selectCatch,
  useSnapshotStore,
} from '../../../modules/creator/store/snapshot';
import {
  selectDeselect,
  useSelectStore,
} from '../../../modules/creator/store/select';
import { wait } from '../../../utils/wait';
import { setProject } from '../../../utils/projectDto';

export type SavingProcessType = 'save' | 'saveAs' | 'confirm';

export interface UseSave {
  modals: Map<string, boolean>;
  save(processType?: SavingProcessType): Promise<void>;
  saveProjectTemplate(processType?: SavingProcessType): Promise<void>;
  saveAs(name: string, clientId?: string): void;
  saveProjectTemplateAs(name: string, description?: string): void;
  confirm(): void;
  cancel(): void;
  closeSaveAs(): void;
  closeSaveTemplate(): void;
  rejectChanges(): void;
}

let resolveSaving: () => void;
const savingPromise = (): Promise<void> =>
  new Promise((resolve) => {
    resolveSaving = resolve;
  });

let resolveConfirm: (confirm: boolean) => void;
const confirmPromise = (): Promise<boolean> =>
  new Promise((resolve) => {
    resolveConfirm = resolve;
  });

interface SavingProject {
  id: number;
  data: ProjectData;
  name: string;
  clientId?: string | number;
  snapshotUrl?: string;
  onSuccess?(): void;
}

interface SavingTemplate {
  id: number;
  data: ProjectData;
  name: string;
  description?: string;
  version?: string;
  snapshotUrl?: string;
  onSuccess?(): void;
}

const useSave = (project?: Project): UseSave => {
  const { t } = useTranslation('project');
  const modals = useModalsStore(selectModals);
  const openModal = useModalsStore(selectOpen);
  const closeModal = useModalsStore(selectClose);
  const saveProject = useProjectStore(selectUpdate);
  const saveTemplate = useTemplateStore(selectTemplateUpdate);
  const setNotification = useNotificationsStore(selectSetNotification);
  const catchSnapshot = useSnapshotStore(selectCatch);
  const deselect = useSelectStore(selectDeselect);

  const savingProject = useCallback(
    async ({
      id,
      data,
      name,
      clientId,
      snapshotUrl,
      onSuccess,
    }: SavingProject) =>
      await saveProject(
        id,
        {
          projectStatus: ProjectStatus.COMPLETED,
          data: setProject(data),
          name,
          clientId,
          snapshotUrl,
        },
        () => {
          onSuccess?.();
          resolveSaving?.();
        },
      ),
    [saveProject],
  );

  const savingTemplate = useCallback(
    async ({
      id,
      data,
      name,
      description,
      version,
      snapshotUrl,
      onSuccess,
    }: SavingTemplate) => {
      await saveTemplate(
        id,
        {
          data: setProject(data),
          name,
          description,
          version,
          snapshotUrl,
          module: ProjectModules.SHOWER_CABINS,
        },
        () => {
          onSuccess?.();
          resolveSaving?.();
        },
      );
    },
    [saveTemplate],
  );

  const handleSavingProject = useCallback(
    async (onSuccess?: () => void, newName?: string, clientId?: string) => {
      if (project) {
        deselect();
        await wait();
        const snapshotUrl = await catchSnapshot();
        const { id, data, name, client } = project;
        await savingProject({
          id,
          data,
          snapshotUrl,
          name: newName ?? name,
          clientId: client?.id ?? clientId,
          onSuccess,
        });
      }
    },
    [catchSnapshot, deselect, project, savingProject],
  );

  const handleSavingTemplate = useCallback(
    async (
      onSuccess?: () => void,
      newName?: string,
      newDescription?: string,
    ) => {
      if (project) {
        deselect();
        await wait();
        const snapshotUrl = await catchSnapshot();
        const { id, data, name, description } = project;

        await savingTemplate({
          id,
          data,
          snapshotUrl,
          name: newName ?? name,
          description: newDescription ?? description,
          onSuccess,
        });
      }
    },
    [catchSnapshot, deselect, project, savingTemplate],
  );

  const cancel = useCallback(() => {
    projectApi.updateSource.cancel();
    closeModal(Modals.SAVING);
  }, [closeModal]);

  const saveAs = useCallback(
    async (name: string, clientId?: string) => {
      closeModal(Modals.SAVE_AS);
      openModal(Modals.SAVING);
      await handleSavingProject(
        () => {
          setNotification({
            variant: NotificationVariants.SUCCESS,
            text: t('notifications.saved', { name }),
          });
        },
        name,
        clientId,
      );
      closeModal(Modals.SAVING);
    },
    [closeModal, handleSavingProject, openModal, setNotification, t],
  );

  const saveProjectTemplateAs = useCallback(
    async (name: string, description?: string) => {
      closeModal(Modals.SAVE_TEMPLATE);
      openModal(Modals.SAVING);

      await handleSavingTemplate(
        () => {
          setNotification({
            variant: NotificationVariants.SUCCESS,
            text: t('notifications.templateSaved', { name }),
          });
        },
        name,
        description,
      );
      closeModal(Modals.SAVING);
    },
    [closeModal, handleSavingTemplate, openModal, setNotification, t],
  );

  const save = useCallback(
    async (processType: SavingProcessType = 'save'): Promise<void> => {
      if (project) {
        const { name } = project;
        if (processType === 'confirm') {
          openModal(Modals.CONFIRM);
          const confirm = await confirmPromise();
          if (confirm) {
            await save();
          }
        } else if (name === t('unknown') || processType === 'saveAs') {
          openModal(Modals.SAVE_AS);
          await savingPromise();
        } else {
          openModal(Modals.SAVING);
          await handleSavingProject(() => {
            setNotification({
              variant: NotificationVariants.SUCCESS,
              text: t('notifications.saved', { name }),
            });
          });
          closeModal(Modals.SAVING);
        }
      }
    },
    [closeModal, handleSavingProject, openModal, project, t],
  );

  const saveProjectTemplate = useCallback(
    async (processType: SavingProcessType = 'save'): Promise<void> => {
      if (project) {
        const { name } = project;
        if (processType === 'confirm') {
          openModal(Modals.CONFIRM);
          const confirm = await confirmPromise();
          if (confirm) {
            await save();
          }
        } else if (name === t('unknown') || processType === 'saveAs') {
          openModal(Modals.SAVE_TEMPLATE);
          await savingPromise();
        } else {
          openModal(Modals.SAVING);
          await handleSavingTemplate(() => {
            setNotification({
              variant: NotificationVariants.SUCCESS,
              text: t('notifications.templateSaved', { name }),
            });
          });

          closeModal(Modals.SAVING);
        }
      }
    },
    [
      closeModal,
      handleSavingTemplate,
      openModal,
      setNotification,
      project,
      save,
      t,
    ],
  );

  const closeSaveAs = useCallback(() => closeModal(Modals.SAVE_AS), [
    closeModal,
  ]);

  const closeSaveTemplate = useCallback(
    () => closeModal(Modals.SAVE_TEMPLATE),
    [closeModal],
  );

  const confirm = useCallback(async () => {
    closeModal(Modals.CONFIRM);
    resolveConfirm(true);
  }, [closeModal]);

  const rejectChanges = useCallback(() => {
    closeModal(Modals.CONFIRM);
    resolveConfirm(false);
  }, [closeModal]);

  return {
    modals,
    save,
    saveAs,
    confirm,
    cancel,
    closeSaveAs,
    saveProjectTemplate,
    saveProjectTemplateAs,
    closeSaveTemplate,
    rejectChanges,
  };
};

export default useSave;
