import styled from 'styled-components';
import { layout } from '../../styles';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  height: 0;
  position: relative;
  overflow: hidden;
`;

export const EmptyWrapper = styled(layout.Column)`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;
