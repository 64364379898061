import { Position } from './position';
import { Connection } from './connection';
import {
  GlassDimensions,
  GlassProperties,
  GlassPropertiesCommand,
  GlassPropertiesSearch,
  GlassThicknessType,
  GlassType,
} from './glass';
import { Price } from './price';
import { Pageable } from './requests';
import { ProductFiltersState } from '../../../pages/Catalog/hooks/useCatalog';
import { RectCorners } from '../../../types';
import { ProductTransform } from '../../../modules/creator/utils/product';

export enum CutoutDirection {
  TOP_LEFT = 'TopLeft',
  TOP_RIGHT = 'TopRight',
  BOTTOM_LEFT = 'BottomLeft',
  BOTTOM_RIGHT = 'BottomRight',
}

export interface CutoutSize {
  x: number;
  y: number;
}

export interface ProductCutout {
  direction: CutoutDirection;
  size: CutoutSize;
}

export interface AnchoringOptions {
  top: boolean;
  topLeft: boolean;
  left: boolean;
  bottomLeft: boolean;
  bottom: boolean;
  bottomRight: boolean;
  right: boolean;
  topRight: boolean;
  center: boolean;
}

export interface ExtendedAnchoringOptions extends AnchoringOptions {
  product: boolean;
}

export interface SymetryOptions {
  horizontal: boolean;
  vertical: boolean;
  reversibility: boolean;
  oppositeSide: boolean;
}

export interface ProductProperties {
  glassProperties: GlassProperties[];
  wallToGlassGap: number[];
  glassToGlassGap: number[];
}

export type AddProductPropertiesCommand = Pick<
  ProductProperties,
  'wallToGlassGap' | 'glassToGlassGap'
> & {
  glassProperties: GlassPropertiesCommand[];
};

export interface ProductAttributes {
  glassToGlass: AnchoringOptions;
  wallToGlass: AnchoringOptions;
  allowAnchoringOtherProducts: boolean;
  minGapFromGlassEdge: number;
  symmetry: SymetryOptions;
  requiredTypes: string[];
  requiredTypeCount: number;
  cutout?: ProductCutout;
}

export interface RequiredProductsCommand {
  productTypes: string[];
  requiredTypeCount: number;
}

export type AddProductAttributesCommand = Pick<
  ProductAttributes,
  | 'glassToGlass'
  | 'wallToGlass'
  | 'allowAnchoringOtherProducts'
  | 'symmetry'
  | 'cutout'
> & { gapFromGlassEdge: number; requiredProducts: RequiredProductsCommand };

export interface ProductAttributesSearch
  extends Omit<ProductAttributes, 'minGapFromGlassEdge'> {
  gapFromGlassEdge: number;
}

export enum AttachmentType {
  PRODUCT_IMAGE = 'PRODUCT_IMAGE',
  PICTOGRAM = 'PICTOGRAM',
  PICTOGRAM_TOP_VIEW = 'PICTOGRAM_TOP_VIEW',
  SPECIFICATION = 'SPECIFICATION',
}

export interface Attachment {
  url: string;
  extension: string;
  type: AttachmentType;
}

export enum BaseType {
  HINGE = 'HINGE',
  LIFTING_HINGE = 'LIFTING_HINGE',
  GASKET = 'GASKET',
  HANDLE = 'HANDLE',
  CONNECTOR_WALL = 'CONNECTOR_WALL',
  CONNECTOR_GLASS = 'CONNECTOR_GLASS',
  PIPE = 'PIPE',
  BAR = 'BAR',
  GLASS_GLASS_90 = 'GLASS_GLASS_90',
  GLASS_GLASS_180 = 'GLASS_GLASS_180',
  GLASS_GLASS_180_4 = 'GLASS_GLASS_180_4',
  OTHER = 'OTHER',
}

export enum ShapeType {
  RECTANGLE = 'RECTANGLE',
  ELLIPSE = 'ELLIPSE',
}

export interface Product {
  id: number;
  createdAt: string; // datetime
  updatedAt: string; // datetime
  code: string;
  description: string;
  width: number;
  height: number;
  thickness: number;
  series: string;
  category: string;
  type: string;
  mountTypes: string[];
  material: string;
  materialType: string;
  netPrice: Price;
  grossPrice: Price;
  productProperties: ProductProperties;
  productAttributes: ProductAttributes;
  attachments: Attachment[];
  active: boolean;
  baseType: BaseType;
  shape: ShapeType;
}

export type AddProductCommand = Pick<
  Product,
  | 'code'
  | 'description'
  | 'baseType'
  | 'width'
  | 'height'
  | 'thickness'
  | 'series'
  | 'category'
  | 'type'
  | 'mountTypes'
  | 'material'
  | 'materialType'
  | 'attachments'
  | 'shape'
> & {
  netPrice: number;
  grossPrice: number;
  properties: AddProductPropertiesCommand;
  attributes: AddProductAttributesCommand;
};

export type AddProductCommandSubmit = Omit<
  AddProductCommand,
  | 'series'
  | 'category'
  | 'type'
  | 'mountTypes'
  | 'material'
  | 'materialType'
  | 'shape'
> & {
  series: string;
  category: string;
  type: string;
  mountTypes: string[];
  material: string;
  materialType: string;
  shape: string;
};

export type EditGlassPropertyCommand = Pick<
  GlassProperties,
  'id' | 'glassToGlassGap' | 'wallToGlassGap'
>;

export enum ProductOrientation {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface ProductModel {
  id: string;
  productId: number;
  width: number;
  height: number;
  corners: RectCorners;
  position: Position;
  rotation: number | undefined;
  orientation: ProductOrientation;
  connections: Connection[];
  thickness?: number;
  cutout?: ProductCutout;
  productPadding?: GlassDimensions;
  wallToGlassGap?: number[];
}

export interface ProductProperty {
  id: number;
  name: string;
}

export interface AllProductProperties {
  series: ProductProperty[];
  categories: ProductProperty[];
  types: ProductProperty[];
  mountTypes: ProductProperty[];
  materials: ProductProperty[];
  materialTypes: ProductProperty[];
  shapes: string[];
}

export type ProductSearch = Pick<
  Product,
  | 'id'
  | 'code'
  | 'description'
  | 'series'
  | 'category'
  | 'type'
  | 'mountTypes'
  | 'material'
  | 'materialType'
  | 'width'
  | 'height'
  | 'active'
  | 'baseType'
  | 'thickness'
> & {
  productImageUrl: string;
  productSpecificationUrl: string;
  productPictogramUrl: string;
  productTopPictogramUrl: string;
  wallToGlassGap: number[];
  glassToGlassGap: number[];
  attributes: ProductAttributesSearch;
  glassProperties: GlassPropertiesSearch[];
};

export interface ProductQuantityCommand {
  productId: number;
  productQuantity: number;
}

export interface ProductPriceList {
  glassType: GlassType;
  chamferingPrice: number;
  grindingPrice: number;
  fiveFortyHoleSizePrice: number;
  fortySeventyHoleSizePrice: number;
  bigInnerOnEdgeCutTypePrice: number | null;
  everyHingeCutTypePrice: number | null;
  glassPrice: number;
}

export interface EditProductPriceListCommand
  extends Pick<
    ProductPriceList,
    | 'glassType'
    | 'chamferingPrice'
    | 'grindingPrice'
    | 'fiveFortyHoleSizePrice'
    | 'fortySeventyHoleSizePrice'
    | 'bigInnerOnEdgeCutTypePrice'
    | 'everyHingeCutTypePrice'
    | 'glassPrice'
  > {
  glassThickness: GlassThicknessType;
}

export interface GetProductsFilter {
  text?: string;
  series: number[];
  category: number[];
  mountType: number[];
  material: number[];
  materialType: number[];
  filters: ProductFiltersState;
}
// TODO: Should be removed
export interface GetProductsCommand {
  text: string;
  series: string[];
  category: string[];
  mountType: string[];
  material: string[];
  materialType: string[];
}

export interface GetProductsFilterRequest {
  command?: Partial<GetProductsFilter>;
  pageable?: Partial<Pageable>;
}

export interface ProductErrorResponse {
  message: string;
  field: string;
  errorCode: string;
}
