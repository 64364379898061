import * as React from 'react';
import {
  HomeTab,
  ProjectTab,
  NameWrapper,
  EditButton,
  NameInput,
} from './styles';
import { Brand } from '../../../../components/Brand';
import { useTranslation, Trans } from 'react-i18next';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import { Edit } from '../../../../components/icons';
import { useContext, useState, useCallback, useRef, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { Tooltip } from '../../../../components/Tooltip';

export interface Props {
  projectName?: string;
  onClick?(): void;
  onNameChange?(name: string): void;
  active?: boolean;
  home?: boolean;
}

const Tab: React.FC<Props> = React.memo(
  ({ projectName, onClick, active, home, onNameChange }) => {
    const { t } = useTranslation('navigation');
    const theme = useContext(ThemeContext);
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState(projectName ?? t('projects.unknown'));
    const inputRef = useRef<HTMLInputElement>(null);

    const persistedName = useRef(name);

    const handleClick = useCallback(() => {
      !active && onClick?.();
    }, [active, onClick]);

    const onEditClick = useCallback(() => {
      setEditMode(true);
    }, []);

    const saveNameChange = useCallback(() => {
      setEditMode(false);
      const newName = name || t('projects.unknown');
      if (!name) {
        setName(newName);
      }
      if (name !== projectName) {
        persistedName.current = newName;
        onNameChange?.(newName);
      }
    }, [name, onNameChange, projectName, t]);

    const handleNameChange = useCallback(({ target: { value } }) => {
      setName(value);
    }, []);

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;
        switch (key) {
          case 'Escape': {
            setName(persistedName.current);
            setTimeout(() => inputRef.current && inputRef.current.blur());
            break;
          }
          case 'Enter': {
            inputRef.current && inputRef.current.blur();
            break;
          }
          default: {
            break;
          }
        }
      },
      [],
    );

    useEffect(() => {
      if (editMode && inputRef.current) {
        inputRef.current.focus();
      }
    }, [editMode]);

    useEffect(() => {
      if (projectName && name !== projectName) {
        setName(projectName);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectName]);

    if (home) {
      return (
        <HomeTab data-testid="home-tab" role="tab" active={active}>
          <Brand />
        </HomeTab>
      );
    }
    return (
      <ProjectTab role="tab" onClick={handleClick} active={active}>
        <NameWrapper>
          {active ? (
            editMode ? (
              <NameInput
                id="project-name"
                name="project-name"
                type="text"
                ref={inputRef}
                value={name}
                onChange={handleNameChange}
                style={{ width: `${name.length + 1}ex` }}
                onBlur={saveNameChange}
                onKeyDown={onKeyDown}
              />
            ) : (
              <Typography
                variant={TypographyVariants.H4_BOLD}
                color={theme.colors.dark}>
                {name}
              </Typography>
            )
          ) : (
            <Typography
              variant={TypographyVariants.H4_BOLD}
              color={theme.colors.dark}>
              <Trans t={t} i18nKey="projects.backTo">
                <Typography
                  variant={TypographyVariants.H4_BOLD}
                  color={theme.colors.accentBlue}>
                  {'Back to: '}
                </Typography>
                {{ projectName: name }}
              </Trans>
            </Typography>
          )}
          {active && (
            <Tooltip text={t('projects.tooltip')} delay={1000}>
              <EditButton role="button" onClick={onEditClick}>
                <Edit />
              </EditButton>
            </Tooltip>
          )}
        </NameWrapper>
      </ProjectTab>
    );
  },
);

Tab.displayName = 'Tab';

export default Tab;
