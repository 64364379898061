import * as React from 'react';

const SvgRectangle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={90}
      height={109}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g opacity={0.2}>
        <mask
          id="rectangle_svg__a"
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={90}
          height={109}>
          <path fill="#828A98" stroke="#828A98" d="M.5.5h89v108H.5z" />
        </mask>
        <g mask="url(#rectangle_svg__a)" stroke="#828A98">
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 43.528 49.098)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 38.646 60.884)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 33.764 72.67)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 28.882 84.456)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 24 96.242)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 19.118 108.028)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 14.236 119.814)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 9.355 131.6)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 4.473 143.386)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -.409 155.171)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -5.291 166.957)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -10.173 178.744)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -15.055 190.529)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -19.937 202.315)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -24.819 214.101)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -29.7 225.888)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -34.582 237.673)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -39.464 249.459)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -44.346 261.245)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -49.228 273.032)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -54.11 284.818)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -58.992 296.603)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -63.874 308.39)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -68.756 320.175)"
            d="M0-.5h239.916"
          />
          <path
            transform="scale(-.99651 -1.00348) rotate(-45 -73.638 331.962)"
            d="M0-.5h239.916"
          />
        </g>
      </g>
      <path fill="#D3D7E0" d="M8 8h73v92H8z" />
    </svg>
  );
};

const MemoSvgRectangle = React.memo(SvgRectangle);
export default MemoSvgRectangle;
