import { State as FilterState } from './filter';
import { State } from './offer';

type CommonState = State | FilterState;

export const selectLoading = (state: CommonState) => state.loading;

export const selectError = (state: CommonState) => state.error;

export const selectOffers = (state: FilterState) => state.data;

export const selectFilterClientOffers = (state: FilterState) =>
  state.filterClientOffers;

export const selectFilterOffers = (state: FilterState) => state.filterOffers;

export const selectOffer = (state: State) => state.data;

export const selectFetch = (state: State) => state.fetch;

export const selectRecalculate = (state: State) => state.recalculate;

export const selectCreate = (state: State) => state.create;

export const selectEdit = (state: State) => state.edit;
