import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Container } from './styles';
import { Menu } from './components/Menu';
import { Tabs } from './components/Tabs';
import { Language } from '../../components/Language';
import { AdminMenu, UserMenu } from './components/UserMenu';
import { selectLogout, useSessionStore } from '../../store/session';
import { useHistory } from 'react-router-dom';
import { RootRoutes } from '../../router/routes';
import { useCreator } from '../../pages/Project/hooks';
import { useEditActions } from '../../hooks';
import { ActionContext } from '../../hooks/useEditActions';
import { Can } from '../../components/Can';
import { Action, Subjects } from '../../services/api/models/permissions';
import { selectClose, selectOpen, useModalsStore } from '../../store/modals';
import { ProjectModals } from './types';
import { AdminForms } from './containers/AdminForms';
import { ProjectStatus } from '../../services/api/models/project';

export interface Props {}

const Navigation: React.FC<Props> = () => {
  const logout = useSessionStore(selectLogout);
  const {
    project,
    saved,
    isHistoryEnd,
    defaultId,
    setProjectName,
    templateId,
    selected,
  } = useCreator();

  const { push } = useHistory();

  const goToProfile = useCallback(() => push(RootRoutes.PROFILE), [push]);

  const projects = useMemo(
    () =>
      project && project.projectStatus !== ProjectStatus.CLEAR ? [project] : [],
    [project],
  );

  const selectedContext = useMemo<ActionContext | undefined>(() => {
    if (selected.length === 1) {
      const [first] = selected;
      return {
        itemId: first.shapeId,
        type: first.type,
      };
    }
  }, [selected]);

  const menuEditActions = useEditActions(selectedContext);

  const open = useModalsStore(selectOpen);
  const close = useModalsStore(selectClose);
  const changeEmail = useCallback(() => open(ProjectModals.EMAIL_CHANGE), [
    open,
  ]);
  const changePassword = useCallback(
    () => open(ProjectModals.PASSWORD_CHANGE),
    [open],
  );
  const closeModal = useCallback((modal: ProjectModals) => close(modal), [
    close,
  ]);

  return (
    <Container>
      <Menu
        project={project}
        projectSaved={saved && isHistoryEnd}
        hasTemplate={typeof templateId !== 'undefined'}
        editActions={menuEditActions}
      />
      <Tabs
        projects={projects}
        activeProjectId={project?.id ?? defaultId}
        onProjectNameChange={setProjectName}
      />
      <Language />
      <Can not I={Action.READ} a={Subjects.COMPANY_ADMIN_READ}>
        <UserMenu onLogout={logout} goToProfile={goToProfile} />
      </Can>
      <Can I={Action.READ} a={Subjects.COMPANY_ADMIN_READ}>
        <AdminMenu
          changeEmail={changeEmail}
          changePassword={changePassword}
          onLogout={logout}
        />
        <AdminForms onClose={closeModal} />
      </Can>
    </Container>
  );
};

export default Navigation;
