import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled(animated.div)<{
  opacity: number;
  color: string;
  zindex: number;
}>`
  background: ${({ color }) => color};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: ${({ zindex }) => zindex};
`;
