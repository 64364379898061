import React from 'react';
import { Props as CommonProps } from './types';
import { Input, Icon, ClearButton } from './styles';
import { useCallback, useRef, useEffect } from 'react';
import { DownSm, UpSm, Close } from '../icons';
import { Option } from './SelectBox';
import Composer from './Composer';

export interface OptionObject {
  option?: Option;
  name: string;
}
export interface Props extends CommonProps {
  onChange?(option: OptionObject): void;
  selected?: Option;
  disabled?: boolean;
  search?: boolean;
  clearButton?: boolean;
  onBlur?(): void;
  validate?(option: OptionObject): Promise<boolean> | boolean;
  clearValueOnSelect?: boolean;
  Trailing?: React.ReactNode;
}

const Select: React.FC<Props> = React.memo(
  ({
    onChange,
    selected,
    name,
    disabled,
    search,
    width,
    fullWidth,
    label,
    clearButton,
    onBlur,
    validate,
    testId,
    errorMessage,
    invalid,
    clearValueOnSelect,
    Trailing,
    ...props
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const setIcon = useCallback(
      (unfolded?: boolean) => (unfolded ? <UpSm /> : <DownSm />),
      [],
    );

    const setUncontrolledInputValue = useCallback(
      (value: string) => inputRef.current && (inputRef.current.value = value),
      [],
    );

    const onSelect = useCallback(
      async ([option]: Option[]) => {
        const optionObject = { option, name };
        if (!validate || (await validate(optionObject))) {
          onChange?.(optionObject);
          !clearValueOnSelect && setUncontrolledInputValue(option.title);
        }
      },
      [validate, onChange, name, setUncontrolledInputValue, clearValueOnSelect],
    );

    const onBoxSelect = useCallback(
      (listTrigger: (state?: boolean) => void, unfolded?: boolean) => {
        if (!disabled) {
          !unfolded && listTrigger(true);
        }
      },
      [disabled],
    );

    const onClear = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onChange?.({ name });
        setUncontrolledInputValue('');
      },
      [onChange, setUncontrolledInputValue, name],
    );

    const handleBlur = useCallback(() => {
      if (onBlur) {
        onBlur();
      } else if (
        inputRef.current &&
        selected &&
        selected?.title !== inputRef.current?.value
      ) {
        inputRef.current.value = selected.title;
      } else if (inputRef.current && !selected) {
        inputRef.current.value = '';
      }
    }, [onBlur, selected]);

    useEffect(() => {
      setUncontrolledInputValue(selected?.title ?? '');
    }, [selected?.title, setUncontrolledInputValue]);

    return (
      <Composer
        {...props}
        label={label}
        name={name}
        selected={selected}
        onSelect={onSelect}
        width={width}
        fullWidth={fullWidth}
        errorMessage={errorMessage}
        invalid={invalid}>
        {({ inputId, i18nPlaceholder, toggleUnfold, unfolded, onSearch }) => (
          <>
            <Icon
              disabled={disabled}
              onClick={() =>
                search ? onBoxSelect(toggleUnfold, unfolded) : toggleUnfold()
              }>
              {Trailing ? Trailing : setIcon(unfolded)}
            </Icon>
            {selected && clearButton && (
              <ClearButton aria-label="clear" role="button" onClick={onClear}>
                <Close />
              </ClearButton>
            )}
            <Input
              aria-label={!label ? name : undefined}
              data-testid={testId}
              ref={inputRef}
              id={inputId}
              name={name}
              placeholder={i18nPlaceholder}
              onClick={() =>
                search ? onBoxSelect(toggleUnfold, unfolded) : toggleUnfold()
              }
              invalid={invalid}
              disabled={disabled}
              hasTrailing
              search={search}
              readOnly={!search}
              onChange={onSearch}
              clearable={clearButton}
              onBlur={handleBlur}
              autoComplete="off"
            />
          </>
        )}
      </Composer>
    );
  },
);

Select.displayName = 'Select';

export default Select;
