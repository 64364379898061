import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { ProductProperty } from '../../../../../../services/api/models/product';
import {
  Container,
  SelectWrapper,
  BadgesWrapper,
  InputWrapper,
} from './styles';
import { Input } from '../../../../../../components/Input';
import {
  SelectBox,
  SelectBadge,
  SelectOption,
} from '../../../../../../components/Select';
import { Search } from '../../../../../../components/icons';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from '../../../../../../hooks';

export interface Props {
  onSearch(value: string, name: string): void;
  onFilter(materialTypes: SelectOption[]): void;
  onRemoveFilter(option: SelectOption): void;
  toggleFilters(): void;
  showFilters: boolean;
  selected: SelectOption[];
  materialTypes: ProductProperty[];
}

const CatalogSearch: React.FC<Props> = React.memo(
  ({
    onSearch,
    materialTypes,
    onFilter,
    showFilters,
    toggleFilters,
    selected,
    onRemoveFilter,
    ...props
  }) => {
    const { t } = useTranslation('project');
    const elementRef = useRef<HTMLDivElement>(null);

    const filterOptions = useMemo(
      () =>
        materialTypes.map<SelectOption>((type) => ({
          title: type.name,
          value: String(type.id),
        })),
      [materialTypes],
    );

    const handleSelectFilter = useCallback(
      (options: SelectOption[]) => onFilter(options),
      [onFilter],
    );

    const closeFilters = useCallback(() => {
      showFilters && toggleFilters();
    }, [showFilters, toggleFilters]);

    useClickOutside({ elementRef, onClickOutside: closeFilters });

    return (
      <Container {...props}>
        <InputWrapper ref={elementRef}>
          <Input
            onChange={onSearch}
            name="catalog-search"
            placeholder={t('productsPanel.search.placeholder')}
            Leading={<Search />}
            withButton
            onButtonClick={toggleFilters}
            onFocus={closeFilters}
            clearButton
          />
          {showFilters && (
            <SelectWrapper>
              <SelectBox
                label={t('productsPanel.search.filter')}
                options={filterOptions}
                onSelect={handleSelectFilter}
                selected={selected}
                multi
              />
            </SelectWrapper>
          )}
        </InputWrapper>
        {selected.length > 0 && (
          <BadgesWrapper>
            {selected.map((option) => (
              <SelectBadge
                aria-label={`${option.value}-badge`}
                key={option.value}
                option={option}
                onDelete={onRemoveFilter}
              />
            ))}
          </BadgesWrapper>
        )}
      </Container>
    );
  },
);

CatalogSearch.displayName = 'CatalogSearch';

export default CatalogSearch;
