import styled, { css } from 'styled-components';
import { layout } from '../../../../../../styles';
const disable = css`
  > div {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const Container = styled(layout.Row)<{ disabled?: boolean }>`
  align-items: center;
  justify-content: space-between;
  ${({ disabled }) => disabled && disable};
  > span {
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.dark};
  }
`;
