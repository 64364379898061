import styled from 'styled-components';
import { layout } from '../../../../styles';

export const Container = styled(layout.Row)`
  margin-bottom: 12px;
  > span {
    color: ${({ theme }) => theme.colors.dark};
  }
`;

export const ButtonsWrapper = styled(layout.Row)`
  flex-shrink: 0;
  margin-left: 24px;
  margin-top: -12px;
  > button {
    &:first-of-type {
      margin-right: 8px;
    }
  }
`;
