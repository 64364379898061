import { useEffect } from 'react';
import * as React from 'react';

const usePassiveEvent = <E extends keyof HTMLElementEventMap>(
  eventName: E,
  element: HTMLElement | Document | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: React.EventHandler<any>,
) => {
  useEffect(() => {
    element?.addEventListener(eventName, handler, { passive: false });
    return () => element?.removeEventListener(eventName, handler);
  }, [element, eventName, handler]);
};

export default usePassiveEvent;
