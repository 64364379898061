import * as React from 'react';
import { Container, IconWrapper, TitleWrapper } from './styles';
import { Types } from './types';
import { Typography, TypographyVariants } from '../Typography';

export interface Props {
  title: string;
  type?: Types;
  onClick?(): void;
  htmlButtonType?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  subtitle?: string;
  LeftIcon?: React.ReactNode;
  RightIcon?: React.ReactNode;
  big?: boolean;
  fullWidth?: boolean;
  testId?: string;
  noMinWidth?: boolean;
  margin?: string;
  cornerIcon?: boolean;
}

const Button: React.FC<Props> = React.memo(
  ({
    title,
    onClick,
    disabled,
    LeftIcon,
    RightIcon,
    subtitle,
    big,
    fullWidth,
    testId,
    noMinWidth,
    margin,
    type = Types.PRIMARY,
    htmlButtonType = 'button',
    cornerIcon,
  }) => {
    return (
      <Container
        data-testid={testId}
        type={htmlButtonType}
        btnType={type}
        onClick={onClick}
        disabled={disabled}
        fullWidth={fullWidth}
        noMinWidth={noMinWidth}
        margin={margin}
        big={big}>
        {LeftIcon && (
          <IconWrapper left cornerIcon={cornerIcon} type={type}>
            {LeftIcon}
          </IconWrapper>
        )}
        <TitleWrapper>
          <Typography variant={TypographyVariants.H3}>{title}</Typography>
          <Typography variant={TypographyVariants.PARAGRAPH}>
            {subtitle}
          </Typography>
        </TitleWrapper>
        {RightIcon && (
          <IconWrapper right cornerIcon={cornerIcon} type={type}>
            {RightIcon}
          </IconWrapper>
        )}
      </Container>
    );
  },
);

Button.displayName = 'Button';

export default Button;
