export enum SessionStorageItems {}

export class SessionStorage {
  public static setItem = (key: string, value: string) =>
    sessionStorage.setItem(key, value);

  public static getItem = (key: string) => sessionStorage.getItem(key);
}

export default SessionStorage;
