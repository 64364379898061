import * as React from 'react';
import { ProductProperty } from '../../../../../../services/api/models/product';
import { Container, Tab, TabContent } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { DownSm, UpSm } from '../../../../../../components/icons';

export interface Props {
  id: number | string;
  title: string;
  renderer: () => React.ReactNode;
  onClick(product: ProductProperty): void;
  level?: number;
  active?: boolean;
}

const PanelTab: React.FC<Props> = React.memo(
  ({ id, title, active, renderer, onClick, level = 1, ...props }) => {
    const handleClick = () =>
      onClick({ id: parseFloat(String(id)), name: title });
    return (
      <Container {...props}>
        <Tab role="tab" active={active} level={level} onClick={handleClick}>
          <Typography variant={TypographyVariants.H4}>{title}</Typography>
          {active ? <UpSm /> : <DownSm />}
        </Tab>
        {active && <TabContent>{renderer()}</TabContent>}
      </Container>
    );
  },
);

PanelTab.displayName = 'PanelTab';

export default PanelTab;
