import { State } from './project';
import { State as FilterState } from './filter';
import { State as RecentState } from './recent';

type CommonState = State | FilterState | RecentState;

export const selectLoading = (state: CommonState) => state.loading;

export const selectError = (state: CommonState) => state.error;

export const selectData = (state: FilterState | RecentState) => state.data;

export const selectFetch = (state: State | RecentState) => state.fetch;

export const selectFilter = (state: FilterState) => state.filter;

export const selectCreate = (state: State) => state.create;

export const selectUpdate = (state: State) => state.update;

export const selectDuplicate = (state: State) => state.duplicate;

export const selectRename = (state: State) => state.rename;
