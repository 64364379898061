export enum TooltipType {
  SMALL,
  BIG,
}

export enum TooltipPosition {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

export enum TooltipDrawFrom {
  LEFT,
  RIGHT,
}

export interface CommonProps {
  text: string;
  position?: TooltipPosition;
  drawFrom?: TooltipDrawFrom;
  alwaysVisible?: boolean;
}
