import { State } from './template';

export const selectPosition = (state: State) => state.position;

export const selectSetPosition = (state: State) => state.setPosition;

export const selectTemplateId = (state: State) => state.templateId;

export const selectBaseBreakingPoint = (state: State) =>
  state.baseBreakingPoint;

export const selectSetBaseBreakingPoint = (state: State) =>
  state.setBaseBreakingPoint;

export const selectConfigured = (state: State) => state.configured;

export const selectSetConfigured = (state: State) => state.setConfigured;

export const selectSetup = (state: State) => state.setup;

export const selectSetDimensions = (state: State) => state.setDimensions;

export const selectClear = (state: State) => state.clear;
