import styled, { css } from 'styled-components';
import { layout } from '../../styles';

export const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
`;

export const Tablist = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  max-height: 60px;
`;

export const Tabpanel = styled.div`
  height: calc(100% - 60px);
  box-sizing: border-box;
`;

const active_tab = css`
  border-bottom: 2px solid ${({ theme }) => theme.colors.accentBlue};
  > span {
    color: ${({ theme }) => theme.colors.mainBlue};
    font-weight: 700;
  }
  &:hover {
    > span {
      color: ${({ theme }) => theme.colors.mainBlue};
    }
  }
`;

const disabled_tab = css`
  cursor: default;
`;

export const Tab = styled.div<{ active?: boolean; disabled?: boolean }>`
  display: inline-block;
  padding: 17px 16px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  > span {
    color: ${({ theme }) => theme.colors.grey};
  }
  &:hover {
    > span {
      color: ${({ theme }) => theme.colors.accentBlue};
    }
  }
  ${({ active }) => active && active_tab};
  ${({ disabled }) => disabled && disabled_tab}'
`;
