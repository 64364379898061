import { AxiosInstance } from 'axios';
import { stringify } from 'qs';

import { instance_v2 } from '../config';
import { Endpoints } from '../endpoints';
import { Order, BasicOrder, CancelOrderCommand } from '../models/order';
import { Page, Pageable } from '../models/requests';

export interface FilterCommand {
  orderDateFrom?: string;
  orderDateTo?: string;
  status?: string;
  text?: string;
}

export type FilterRequest = FilterCommand & Partial<Pageable>;

export interface FilterOrdersRequest {
  pageable?: Partial<Pageable>;
  orderDateTo?: string;
  orderDateFrom?: string;
  status?: string;
  text?: string;
  sort?: string;
}

class OrderApi {
  constructor(private readonly http: AxiosInstance) {}

  public getOrder = async (id: number) => {
    return await this.http.get<Order>(`${Endpoints.ORDERS_ADMIN}/${id}`);
  };

  public getOrders = async (command: FilterRequest) => {
    return await this.http.get<Page<BasicOrder>>(
      `${Endpoints.ORDERS_ADMIN}?${stringify(command)}`,
    );
  };

  public getCompanyOrders = (id: number, command: FilterOrdersRequest = {}) =>
    this.http.get<Page<BasicOrder>>(
      `${Endpoints.ORDERS_ADMIN}/company/${id}?${stringify(command)}`,
    );

  public cancelOrder = (id: number, command: CancelOrderCommand) => {
    const editedCommand = command.messageForCompany
      ? command
      : ({ messageForCompany: null } as CancelOrderCommand);
    return this.http.post<Order>(
      `${Endpoints.ORDERS_ADMIN}/${id}`,
      editedCommand,
    );
  };
}

export const orderApi = new OrderApi(instance_v2);
export default orderApi;
