import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  ClientsRoutes,
  ContractorsRoutes,
  DashboardRoutes,
  EmailRoutes,
  ErrorsRoutes,
  LoginRoutes,
  OffersRoutes,
  OrdersRoutes,
  ProjectRoutes,
  RootRoutes,
  TemplateRoutes,
} from './routes';
import PrivateRoute from './PrivateRoute';
import ErrorHandler from './ErrorHandler';
import { Project } from '../pages/Project';
import { Subjects } from '../services/api/models/permissions';

const Home = React.lazy(() => import('../pages/Home'));
const Login = React.lazy(() => import('../pages/Login'));
const Users = React.lazy(() => import('../pages/Users'));
const Orders = React.lazy(() => import('../pages/Orders'));
const OrderDetails = React.lazy(() => import('../pages/OrderDetails'));
const Catalog = React.lazy(() => import('../pages/Catalog'));
const Clients = React.lazy(() => import('../pages/Clients'));
const ClientDetails = React.lazy(() => import('../pages/ClientDetails'));
const Offers = React.lazy(() => import('../pages/Offers'));
const OfferDetails = React.lazy(() => import('../pages/OfferDetails'));
const ErrorPage = React.lazy(() => import('../pages/ErrorPage'));
const Profile = React.lazy(() => import('../pages/MyProfile'));
const Parameters = React.lazy(() => import('../pages/Parameteres'));
const ChangeEmail = React.lazy(() => import('../pages/ChangeEmail'));
const Contractors = React.lazy(() => import('../pages/Contractors'));
const ContractorDetails = React.lazy(
  () => import('../pages/ContractorDetails'),
);
const Templates = React.lazy(() => import('../pages/Templates'));

const Router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route
        exact
        path={[
          RootRoutes.LOGIN,
          LoginRoutes.RESET_PW_REQUEST,
          LoginRoutes.RESET_PW,
        ]}>
        <Login />
      </Route>
      <Route exact path={EmailRoutes.CHANGE_CONFIRM}>
        <ChangeEmail />
      </Route>
      <PrivateRoute
        exact
        path={[
          RootRoutes.HOME,
          DashboardRoutes.MAIN,
          DashboardRoutes.SUPPORT,
          DashboardRoutes.SAVED,
          DashboardRoutes.TEMPLATES,
        ]}>
        <Home />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[Subjects.PROJECT_EDIT, Subjects.PROJECT_TEMPLATE_EDIT]}
        exact
        path={[
          RootRoutes.PROJECT,
          RootRoutes.TEMPLATE,
          ProjectRoutes.CREATE,
          ProjectRoutes.EDIT,
          TemplateRoutes.CREATE,
          TemplateRoutes.EDIT,
        ]}>
        <Project />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[Subjects.ORDER_READ, Subjects.ORDER_EDIT]}
        exact
        path={RootRoutes.ORDERS}>
        <Orders />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[
          Subjects.ORDER_READ,
          Subjects.ORDER_EDIT,
          Subjects.ORDER_ADMIN_READ,
          Subjects.ORDER_ADMIN_EDIT,
        ]}
        exact
        path={OrdersRoutes.ORDER_DETAILS}
        children={<OrderDetails />}
      />
      <PrivateRoute
        CASLSubject={[
          Subjects.USER_READ,
          Subjects.USER_EDIT,
          Subjects.ADMIN_USER_EDIT,
        ]}
        exact
        path={RootRoutes.USERS}>
        <Users />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[
          Subjects.PRODUCT_READ,
          Subjects.PRODUCT_EDIT,
          Subjects.PRODUCT_ADMIN_READ,
          Subjects.PRODUCT_ADMIN_EDIT,
        ]}
        exact
        path={RootRoutes.CATALOG}>
        <Catalog />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[Subjects.CLIENT_READ, Subjects.CLIENT_EDIT]}
        exact
        path={RootRoutes.CLIENTS}>
        <Clients />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[Subjects.CLIENT_READ, Subjects.CLIENT_EDIT]}
        exact
        path={ClientsRoutes.CLIENT_DETAILS}>
        <ClientDetails />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[Subjects.OFFER_READ, Subjects.OFFER_EDIT]}
        exact
        path={RootRoutes.OFFERS}>
        <Offers />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[
          Subjects.ORDER_READ,
          Subjects.ORDER_EDIT,
          Subjects.ORDER_ADMIN_READ,
          Subjects.ORDER_ADMIN_EDIT,
        ]}
        exact
        path={OffersRoutes.OFFER_DETAILS}>
        <OfferDetails />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[Subjects.PARAMETERS_READ, Subjects.PARAMETERS_EDIT]}
        exact
        path={RootRoutes.PARAMS}>
        <Parameters />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={Subjects.PROJECT_TEMPLATE_EDIT}
        exact
        path={RootRoutes.TEMPLATES}>
        <Templates />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[Subjects.COMPANY_ADMIN_EDIT, Subjects.COMPANY_ADMIN_READ]}
        exact
        path={RootRoutes.CONTRACTORS}>
        <Contractors />
      </PrivateRoute>
      <PrivateRoute
        CASLSubject={[Subjects.COMPANY_ADMIN_EDIT, Subjects.COMPANY_ADMIN_READ]}
        exact
        path={ContractorsRoutes.CONTRACTORS_DETAILS}>
        <ContractorDetails />
      </PrivateRoute>
      <PrivateRoute exact path={RootRoutes.PROFILE}>
        <Profile />
      </PrivateRoute>
      <Route
        exact
        path={[ErrorsRoutes.PAGE_404, ErrorsRoutes.PAGE_500]}
        component={ErrorPage}
      />
      <Route render={() => <Redirect to={ErrorsRoutes.PAGE_404} />} />
    </Switch>
    <ErrorHandler />
  </BrowserRouter>
);

export default Router;
