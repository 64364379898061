import styled, { css } from 'styled-components';
import { layout } from '../../../../../../styles';

const selected_item = css`
  background-color: ${({ theme }) => theme.colors.grey4};
`;

const selected_code = css`
  > span {
    color: ${({ theme }) => theme.colors.accentBlue};
  }
`;

const dragged_item = css`
  background-color: ${({ theme }) => theme.colors.grey4};
  opacity: 0.5;
`;

export const Container = styled(layout.Row)<{
  level: number;
  isSelected?: boolean;
  isDragging?: boolean;
}>`
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px 16px 8px ${({ level }) => level * 16}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  user-select: none;
  ${({ isSelected }) => isSelected && selected_item};
  ${({ isDragging }) => isDragging && dragged_item};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
    cursor: pointer;
  }
`;

export const ImageWrapper = styled.div``;

export const Image = styled.img`
  width: 46px;
  height: 46px;
  object-fit: contain;
`;

export const Requirements = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  border-radius: 4px;
  padding: 3px 4px;
  > span {
    color: ${({ theme }) => theme.colors.grey};
    vertical-align: middle;
  }
`;

export const CodeWrapper = styled.div<{ isSelected?: boolean }>`
  flex-grow: 1;
  padding: 0 16px 0;
  ${({ isSelected }) => isSelected && selected_code}
`;

export const DragImageWrapper = styled.div`
  padding: 10px;
  border: 2px dashed ${({ theme }) => theme.colors.accentBlue};
  background-color: ${({ theme }) => theme.colors.grey4};
  border-radius: 4px;
  display: none;
  position: absolute;
  left: -10000px;
`;

export const DragImage = styled.img`
  object-fit: contain;
  vertical-align: middle;
`;
