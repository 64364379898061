import * as React from 'react';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import { ContextMenuItem as Container, HotkeyBadge } from './styles';

export interface Props {
  title: string;
  hotkey?: string;
  disabled?: boolean;
  onClick?(): void;
}

const ContextMenuItem: React.FC<Props> = React.memo(
  ({ title, hotkey, ...props }) => {
    return (
      <Container {...props} role="menuitem">
        <Typography variant={TypographyVariants.H5}>{title}</Typography>
        {hotkey && (
          <HotkeyBadge>
            <Typography variant={TypographyVariants.PARAGRAPH}>
              {hotkey}
            </Typography>
          </HotkeyBadge>
        )}
      </Container>
    );
  },
);

ContextMenuItem.displayName = 'ContextMenuItem';

export default ContextMenuItem;
