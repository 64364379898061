import styled, { css } from 'styled-components';
import { layout } from '../../styles';

const disabled_item = css`
  pointer-events: none;
  opacity: 0.4;
`;

export const Container = styled.div`
  position: relative;
  width: fit-content;
`;

const active_button = css`
  border-color: ${({ theme }) => theme.colors.accentBlue};
  > span {
    color: ${({ theme }) => theme.colors.accentBlue};
  }
`;

export const Button = styled(layout.Row)<{
  active?: boolean;
  disabled?: boolean;
}>`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  width: fit-content;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  cursor: pointer;
  > span {
    color: ${({ theme }) => theme.colors.dark};
  }
  ${({ disabled }) => disabled && disabled_item}
  ${({ active }) => active && active_button}
  &:hover {
    ${active_button}
  }
`;

export const Icon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.accentBlue3};
  border-radius: 4px;
  margin-right: 8px;
  > svg {
    path {
      fill: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;

export const Option = styled(layout.Row)`
  align-items: center;
  padding: 8px;
  cursor: pointer;
  flex-grow: 1;
  flex-wrap: nowrap;
  flex-shrink: 0;
  min-width: 142px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
  }
  > span {
    white-space: nowrap;
  }
`;

export const Label = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.accentBlue3};
  border-radius: 4px;
  padding: 0 4px;
  margin-left: 4px;
  > span {
    color: ${({ theme }) => theme.colors.accentBlue};
    vertical-align: middle;
  }
`;

export const List = styled(layout.Column)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  width: fit-content;
  border-radius: 4px;
  box-shadow: 0 10px 10px rgba(39, 43, 95, 0.08);
  > div {
    &:first-of-type {
      padding-top: 12px;
    }
    &:last-of-type {
      padding-bottom: 12px;
    }
  }
`;

export const CategoryList = styled(List)``;

export const CategoryOption = styled(Option)<{ disabled?: boolean }>`
  position: relative;
  ${({ disabled }) => disabled && disabled_item}
  > [role='listbox'] {
    display: none;
    top: 0;
    left: calc(100% - 14px);
  }
  &:hover {
    > [role='listbox'] {
      display: initial;
    }
  }
`;

export const CustomWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 10px 10px rgba(39, 43, 95, 0.08);
  border-radius: 4px;
  padding: 16px 15px;
`;
