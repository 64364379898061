import { AxiosInstance } from 'axios';
import { stringify } from 'qs';

import { instance_v2 } from '../config';
import { Endpoints } from '../endpoints';
import { Page, Pageable } from '../models/requests';
import {
  Client,
  ClientSearch,
  CreateClientCommand,
  EdiClientCommand,
} from '../models/client';

export interface FilterCommand {
  input?: string;
}

export interface FilterRequest {
  command?: Partial<FilterCommand>;
  pageable?: Partial<Pageable>;
}

class ClientApi {
  constructor(private http: AxiosInstance) {}

  public getClient = (clientId: number) =>
    this.http.get<Client>(`${Endpoints.CLIENT}/${clientId}`);

  public filterClients = ({ command, pageable }: FilterRequest = {}) => {
    return this.http.get<Page<ClientSearch>>(
      `${Endpoints.CLIENT}${`?${
        command?.input ? `${stringify(command)}&` : ''
      }${stringify(pageable)}`}`,
    );
  };

  public getAllClients = () => {
    return this.http.get<ClientSearch[]>(`${Endpoints.CLIENT}/all`);
  };

  public createClient = (command: CreateClientCommand) =>
    this.http.post<Client>(Endpoints.CLIENT, command);

  public editClient = (clientId: number, command: EdiClientCommand) =>
    this.http.put<Client>(`${Endpoints.CLIENT}/${clientId}`, command);
}

// TODO: Temporary instance caused by big BE changes, needs to be removed
const clientApi = new ClientApi(instance_v2);
export default clientApi;
