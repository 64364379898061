import * as React from 'react';
import {
  Container,
  CodeWrapper,
  Image as ItemImage,
  Requirements,
  DragImage,
  DragImageWrapper,
} from './styles';
import { ProductSearch } from '../../../../../../services/api/models/product';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { v4 as uuid } from 'uuid';
import { useMemo, useRef } from 'react';
import { DEFAULT_RATIO } from '../../../../../../modules/creator/store/config';

export interface Props {
  data: ProductSearch;
  isSelected: boolean;
  onSelect(data: ProductSearch): void;
  onDragStart(
    e: React.DragEvent,
    data: ProductSearch,
    dragId?: string | number,
    dragImage?: HTMLDivElement,
  ): void;
  onDragEnd(e: React.DragEvent): void;
  onDrag(e: React.DragEvent): void;
  level?: number;
  draggable?: boolean;
  dragging?: boolean;
  currentDragId?: string | number;
}

const CatalogItem: React.FC<Props> = React.memo(
  ({
    data,
    draggable,
    onSelect,
    isSelected,
    dragging,
    onDragStart,
    onDragEnd,
    onDrag,
    currentDragId,
    level = 1,
    ...props
  }) => {
    const dragImage = useRef<HTMLDivElement>(null);
    const dragId = useMemo(() => uuid(), []);

    const supportedThickness = useMemo(
      () =>
        data.glassProperties
          ? data.glassProperties.map((property) => property.thickness)
          : [],
      [data.glassProperties],
    );

    const isDragging = useMemo(() => dragging && currentDragId === dragId, [
      currentDragId,
      dragId,
      dragging,
    ]);

    return (
      <>
        <Container
          {...props}
          role="button"
          draggable={draggable}
          onDragStart={(e) =>
            onDragStart(
              e,
              data,
              dragId,
              dragImage.current ? dragImage.current : undefined,
            )
          }
          onDragEnd={onDragEnd}
          onDrag={onDrag}
          onMouseDown={() => onSelect(data)}
          isSelected={isSelected}
          isDragging={isDragging}
          level={level}>
          <ItemImage alt={data.code} src={data.productImageUrl} />
          <CodeWrapper isSelected={isSelected}>
            <Typography variant={TypographyVariants.H5}>{data.code}</Typography>
          </CodeWrapper>
          {supportedThickness && supportedThickness.length > 0 && (
            <Requirements>
              <Typography variant={TypographyVariants.PARAGRAPH}>
                {supportedThickness.length > 1
                  ? `${Math.min(...supportedThickness)}mm - ${Math.max(
                      ...supportedThickness,
                    )}mm`
                  : `${supportedThickness[0]}mm`}
              </Typography>
            </Requirements>
          )}
        </Container>
        <DragImageWrapper ref={dragImage}>
          <DragImage
            alt="ghost"
            src={data.productPictogramUrl}
            width={(data.width < 200 ? data.width : 200) * DEFAULT_RATIO}
            height={data.height * DEFAULT_RATIO}
          />
        </DragImageWrapper>
      </>
    );
  },
);

CatalogItem.displayName = 'CatalogItem';

export default CatalogItem;
