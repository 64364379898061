import { ProjectDimensions } from '../../../services/api/models/project';
import { RectCorners } from '../../../types';
import { Shape, Space } from '../../space';
import Konva from 'konva';
import { Position } from '../types';

type Shapes = {
  corners: RectCorners;
  position: Konva.Vector2d;
};

export const findEmptySpace = (
  dimensions: ProjectDimensions,
  spacePosition: Position,
  shapes: Shapes[] = [],
  minWidth = 50,
  minHeight = 50,
): Shape | undefined => {
  const spaceObj = new Space(
    JSON.parse(JSON.stringify(dimensions)),
    spacePosition,
  );

  const shapesObj = shapes.map(
    ({ corners, position }) => new Shape({ corners }, position),
  );

  spaceObj.addShapes(shapesObj);

  return spaceObj.findEmptyLeftBound(minWidth, minHeight);
};
