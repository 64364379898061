import * as React from 'react';
import { Container, Button } from './styles';
import { DropdownOption } from './types';
import { Typography, TypographyVariants } from '../Typography';
import { DownSm } from '../icons';
import List from './List';
import { useState, useRef, useCallback } from 'react';
import { useClickOutside } from '../../hooks';

export interface Props {
  options: DropdownOption[];
  selected: DropdownOption;
  onSelect(option: DropdownOption): void;
  name?: string;
}

const Dropdown: React.FC<Props> = React.memo(
  ({ options, selected, onSelect, name }) => {
    const [active, setActive] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);

    const onClickOutside = useCallback(() => {
      setActive(false);
    }, []);

    const handleSelect = useCallback(
      (option) => {
        onSelect(option);
        setActive(false);
      },
      [onSelect],
    );

    useClickOutside({ elementRef, onClickOutside });

    if (options.length > 0) {
      return (
        <Container aria-label={name} data-testid="dropdown" ref={elementRef}>
          <Button role="button" onClick={() => setActive(!active)}>
            <Typography variant={TypographyVariants.H4_BOLD}>
              {selected.title}
            </Typography>
            <DownSm />
          </Button>
          {active && (
            <List
              options={options}
              selected={selected}
              onSelect={handleSelect}
            />
          )}
        </Container>
      );
    }
    return null;
  },
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
