import { addDays, format, startOfWeek } from 'date-fns';
import { currentLocale } from './dateLocales';

const first = startOfWeek(new Date(), { weekStartsOn: 1 });

const weekdays: Date[] = Array.from(Array(7)).map((day, index) =>
  addDays(first, index),
);

export const names = weekdays.map((date) =>
  format(date, 'EEEE', { locale: currentLocale() }),
);

export const shortNames = weekdays.map((date) =>
  format(date, 'EEE', { locale: currentLocale() }),
);

export const shortenNames = weekdays.map((date) =>
  format(date, 'EEEEEE', { locale: currentLocale() }),
);

export const firstLetters = weekdays.map((date) =>
  format(date, 'EEEEE', { locale: currentLocale() }),
);
