import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useModalsStore, selectModals } from '../../../../store/modals';
import { ProjectModals } from '../../types';
import { Modal } from '../../../../components/Modal';
import { EmailForm, EmailFormValues } from '../../components/EmailForm';
import { EmailChanged } from '../../components/EmailChanged';
import { useEmailChangeStore, selectError } from '../../../../store/email';
import {
  PasswordChangeForm,
  UpdateFormValues,
} from '../../../../components/PasswordChangeForm';
import {
  useResetPasswordStore,
  selectError as selectResetPasswordError,
} from '../../../../store/resetPassword';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../../store/notifications';
import { NotificationVariants } from '../../../../components/Notification';

export interface Props {
  onClose(modal: ProjectModals): void;
}

const AdminForms: React.FC<Props> = React.memo(({ onClose }) => {
  const { t } = useTranslation('emailChange');
  const setNotification = useNotificationsStore(selectSetNotification);
  const [submittedEmail, setSubmittedEmail] = useState<string>();
  const modals = useModalsStore(selectModals);
  const { initChange } = useEmailChangeStore();
  const emailChangeSubmitErrors = useEmailChangeStore(selectError);
  const handleEmailChange = useCallback(
    (values: EmailFormValues, helpers: FormikHelpers<EmailFormValues>) =>
      initChange(
        values,
        () => {
          setSubmittedEmail(values.newEmail);
        },
        () => {
          helpers.setSubmitting(false);
        },
      ),
    [initChange],
  );
  const { updatePassword, clearError } = useResetPasswordStore();
  const passwordChangeSubmitErrors = useResetPasswordStore(
    selectResetPasswordError,
  );
  const updatePasswordAction = useCallback(
    (values: UpdateFormValues, helpers: FormikHelpers<UpdateFormValues>) =>
      updatePassword(
        values,
        () => {
          helpers.setSubmitting(false);
        },
        () => {
          onClose(ProjectModals.PASSWORD_CHANGE);
          setNotification({
            variant: NotificationVariants.SUCCESS,
            text: t('notification.success.passwordChanged'),
          });
          clearError();
        },
      ),
    [clearError, onClose, setNotification, t, updatePassword],
  );

  const onCloseEmailModal = useCallback(() => {
    onClose(ProjectModals.EMAIL_CHANGE);
  }, [onClose]);

  const onClosePasswordModal = useCallback(() => {
    clearError();
    onClose(ProjectModals.PASSWORD_CHANGE);
  }, [clearError, onClose]);

  if (modals.get(ProjectModals.EMAIL_CHANGE)) {
    return (
      <Modal onClose={onCloseEmailModal}>
        {submittedEmail && (
          <EmailChanged email={submittedEmail} onClose={onCloseEmailModal} />
        )}
        {!submittedEmail && (
          <EmailForm
            onCancel={onCloseEmailModal}
            onSubmit={handleEmailChange}
            submitError={!!emailChangeSubmitErrors}
          />
        )}
      </Modal>
    );
  }
  if (modals.get(ProjectModals.PASSWORD_CHANGE)) {
    return (
      <Modal onClose={onClosePasswordModal}>
        <PasswordChangeForm
          onCancel={onClosePasswordModal}
          onSubmit={updatePasswordAction}
          submitError={!!passwordChangeSubmitErrors}
        />
      </Modal>
    );
  }
  return null;
});

AdminForms.displayName = 'AdminForms';

export default AdminForms;
