import { RefObject, useCallback, useEffect } from 'react';

export interface UseClickOutsideConfig {
  elementRef: RefObject<HTMLElement>;
  onClickOutside(): void;
}

const useClickOutside = ({
  elementRef,
  onClickOutside,
}: UseClickOutsideConfig) => {
  const handler = useCallback(
    (event: MouseEvent) => {
      if (
        elementRef?.current &&
        !elementRef.current.contains(event.target as Node)
      ) {
        onClickOutside();
      }
    },
    [onClickOutside, elementRef],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handler);
    return () => document.removeEventListener('mousedown', handler);
  }, [handler]);
};

export default useClickOutside;
