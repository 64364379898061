import * as React from 'react';
import { Option as Container, Icon } from './styles';
import { InsertOption } from './types';
import { Add } from '../icons';
import { Typography, TypographyVariants } from '../Typography';
import { Label } from '../Label';

export interface Props {
  option: InsertOption;
  onSelect(option: InsertOption): void;
}

const Option: React.FC<Props> = React.memo(({ option, onSelect }) => {
  return (
    <Container
      aria-label={option.title}
      role="option"
      onClick={() => onSelect(option)}>
      <Icon>
        <Add />
      </Icon>
      <Typography variant={TypographyVariants.H5}>{option.title}</Typography>
      {option.label && <Label text={option.label} />}
    </Container>
  );
});

Option.displayName = 'Option';

export default Option;
