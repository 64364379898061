import * as React from 'react';
import { Container, ButtonsWrapper } from './styles';
import { BottomModal } from '../../../../components/Modal';
import { Typography } from '../../../../components/Typography';
import { useTranslation } from 'react-i18next';
import { Button, ButtonTypes } from '../../../../components/Button';
import { useRWD } from '../../../../hooks';

export interface Props {
  show: boolean;
  onReject(): void;
  onConfirm(): void;
}

const ConfirmModal: React.FC<Props> = React.memo(
  ({ show, onReject, onConfirm }) => {
    const { t } = useTranslation('project');
    const { isMobile } = useRWD();

    return (
      <BottomModal show={show} title={t('modals.confirm.title')} fullWidth>
        <Container>
          <Typography>{t('modals.confirm.description')}</Typography>
          <ButtonsWrapper>
            <Button
              testId="cancel-button"
              title={t('modals.confirm.cancel')}
              type={ButtonTypes.SECONDARY}
              onClick={onReject}
              noMinWidth
            />
            <Button
              testId="submit-button"
              title={t(
                isMobile
                  ? 'modals.confirm.submitMobile'
                  : 'modals.confirm.submit',
              )}
              onClick={onConfirm}
            />
          </ButtonsWrapper>
        </Container>
      </BottomModal>
    );
  },
);

ConfirmModal.displayName = 'ConfirmModal';

export default ConfirmModal;
