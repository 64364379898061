import Big, { BigSource, RoundingMode } from 'big.js';
import { BigPosition, Position } from '../types';

export const fixValue = (
  value: BigSource,
  digits = 2,
  roundMode: RoundingMode = 2,
) => Number(new Big(value).toFixed(digits, roundMode));

export const roundPosition = (value: BigSource) => {
  return parseFloat(
    (Math.ceil(Number(new Big(value)) * 20 - 0.5) / 20).toFixed(2),
  );
};

export const fixPosition = (position: Position, digits = 2): Position => ({
  x: fixValue(position.x, digits),
  y: fixValue(position.y, digits),
});

export const roundPositionToNearest = (position: Position): Position => ({
  x: roundPosition(position.x),
  y: roundPosition(position.y),
});

export const fixBigPosition = (
  position: BigPosition,
  digits = 1,
): Position => ({
  x: fixValue(position.x, digits),
  y: fixValue(position.y, digits),
});
