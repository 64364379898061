import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AxiosResponse } from 'axios';

import { BasicOrder } from '../../services/api/models/order';
import { FilterRequest } from '../../services/api/services/order';
import { orderApi, orderAdminApi } from '../../services/api/services';
import { Page } from '../../services/api/models/requests';
import { createAsyncAction } from '../middlewares/actions';
import { FilterOrdersRequest } from '../../services/api/services/orderAdmin';

export const STORE_NAME = `@store/orders/filter`;

export type State = {
  loading: boolean;
  filter(payload: FilterRequest, admin?: boolean): Promise<void>;
  filterCampanyOrders(id: number, command?: FilterOrdersRequest): Promise<void>;
  filterClientOrders(id: number, filters: FilterRequest): Promise<void>;
  data?: Page<BasicOrder>;
  error?: AxiosResponse;
};

const filterAction = (set: SetState<State>) => (
  payload: FilterRequest,
  admin?: boolean,
) =>
  createAsyncAction(set)(async () => {
    const { data } = admin
      ? await orderAdminApi.getOrders(payload)
      : await orderApi.getOrders(payload);
    set({ data });
  });

const filterCampanyOrdersAction = (set: SetState<State>) => (
  id: number,
  command?: FilterOrdersRequest,
) =>
  createAsyncAction(set)(async () => {
    const { data } = await orderAdminApi.getCompanyOrders(id, command);
    set({ data });
  });

const filterClientOrdersAction = (set: SetState<State>) => (
  id: number,
  filters: FilterRequest,
) =>
  createAsyncAction(set)(async () => {
    const { data } = await orderApi.filterClientOrders(id, filters);
    set({ data });
  });

const store = (set: SetState<State>) => ({
  loading: false,
  filter: filterAction(set),
  filterCampanyOrders: filterCampanyOrdersAction(set),
  filterClientOrders: filterClientOrdersAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
