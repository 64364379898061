import styled from 'styled-components';
import { layout } from '../../styles';

export const Container = styled.div`
  text-align: center;
  width: 439px;
  > span,
  h1 {
    display: block;
    margin-bottom: 40px;
  }
  > span {
    color: ${({ theme }) => theme.colors.dark};
  }
`;

export const ModalOption = styled(layout.Row)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  border-radius: 4px;
  padding: 20px;
  margin: 16px 0;
  input[type='radio'] {
    pointer-events: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
  }
`;

export const ButtonWrapper = styled(layout.Row)`
  margin-top: 40px;
  > button:first-of-type {
    margin-right: 16px;
  }
`;
