import * as React from 'react';
import { Container } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import {
  Select,
  SelectOption,
  OptionObject,
} from '../../../../../../components/Select';

export type PropertySelectOption = SelectOption;

export interface Props {
  title: string;
  name: string;
  options: PropertySelectOption[];
  onSelect(option: OptionObject): void;
  selected?: PropertySelectOption;
  placeholder?: string;
  validate?(option: OptionObject): Promise<boolean> | boolean;
}

const PropertySelect: React.FC<Props> = React.memo(
  ({
    title,
    name,
    options,
    selected,
    placeholder,
    onSelect,
    validate,
    ...props
  }) => {
    return (
      <Container aria-label={`select-${name}`} {...props}>
        <Typography variant={TypographyVariants.H5}>{title}:</Typography>
        <Select
          name={name}
          options={options}
          selected={selected}
          width={179}
          placeholder={placeholder}
          onChange={onSelect}
          validate={validate}
        />
      </Container>
    );
  },
);

PropertySelect.displayName = 'PropertySelect';

export default PropertySelect;
