import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Project, ProjectStatus } from '../../services/api/models/project';
import { AxiosError } from 'axios';
import { CreateProjectTemplateCommand } from '../../services/api/services/templates';
import { templatesApi } from '../../services/api/services';
import { createAsyncAction } from '../middlewares/actions';
import { creatorProject } from '../../modules/creator';
import { getProject } from '../../utils/projectDto';

const {
  setProject,
  update: updateProject,
} = creatorProject.projectStore.getState();

export const STORE_NAME = `@store/templates/template`;

export type State = {
  loading: boolean;
  create(
    command: CreateProjectTemplateCommand,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  update(
    id: number,
    command: CreateProjectTemplateCommand,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  fetch(id: number): Promise<void>;
  delete(id: number): Promise<void>;
  error?: AxiosError;
};

const fetchAction = (set: SetState<State>) => (
  id: number,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      const { data: projectResponse } = await templatesApi.getProjectTemplate(
        id,
      );
      const project: Project = {
        ...projectResponse,
        data: getProject(projectResponse.data),
        projectStatus: ProjectStatus.DRAFT,
      };
      setProject(project);
    },
    onError,
    onSuccess,
  );

const createAction = (set: SetState<State>) => (
  command: CreateProjectTemplateCommand,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      const {
        data: projectResponse,
      } = await templatesApi.createProjectTemplate(command);
      const project: Project = {
        ...projectResponse,
        data: getProject(projectResponse.data),
        projectStatus: ProjectStatus.DRAFT,
      };
      setProject(project);
    },
    onError,
    onSuccess,
  );

const updateAction = (set: SetState<State>) => (
  id: number,
  command: CreateProjectTemplateCommand,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      const {
        data: projectResponse,
      } = await templatesApi.updateProjectTemplate(id, command);
      const project: Project = {
        ...projectResponse,
        data: getProject(projectResponse.data),
        projectStatus: ProjectStatus.DRAFT,
      };
      updateProject(project, true);
    },
    onError,
    onSuccess,
  );

const deleteAction = (set: SetState<State>) => (id: number) =>
  createAsyncAction(set)(async () => {
    const { data } = await templatesApi.deleteProjectTemplate(id);
  });

const store = (set: SetState<State>) => ({
  loading: false,
  fetch: fetchAction(set),
  create: createAction(set),
  update: updateAction(set),
  delete: deleteAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
