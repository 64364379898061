import Konva from 'konva';

import { NICHE_INDENT } from '../components/Indent';
import { TEMPLATE_LAYER } from '../containers/MainLayer';
import { theme } from '../../../styles';

export const generateSnapshot = (
  snapshotStage: Konva.Stage,
  scale: number,
  mainLayerPosition: Konva.Vector2d,
): string => {
  const templateLayer = snapshotStage.findOne(`#${TEMPLATE_LAYER}`);
  const { x, y, width, height } = snapshotStage.getClientRect();
  const glassIndents = snapshotStage.find(
    (node: Konva.Node) => node.name() === NICHE_INDENT,
  );
  glassIndents.forEach((node: Konva.Node) =>
    node.setAttr('fill', theme.colors.white),
  );
  templateLayer.position({
    x: (mainLayerPosition.x - x) / scale,
    y: (mainLayerPosition.y - y) / scale,
  });
  templateLayer.scale({ x: 1 / scale, y: 1 / scale });
  snapshotStage.position({ x: 0, y: 0 });
  snapshotStage.width(width / scale);
  snapshotStage.height(height / scale);

  return snapshotStage.toDataURL({ pixelRatio: 2 });
};
