import * as React from 'react';
import {
  InputsGrid,
  Container,
  TopInput,
  BottomInput,
  LeftInput,
  RightInput,
  Placeholder,
} from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { Boundaries } from '../../../../../../modules/creator/types';

type OnChangeFn = (value: string, name: string) => void;

type Positions = Boundaries;

type Disabled = Record<keyof Positions, boolean>;

export interface Props {
  positions: Positions;
  title?: string;
  disabled?: Disabled;
  minLimit?: Positions;
  maxLimit?: Positions;
  step?: number;
  onTopChange: OnChangeFn;
  onBottomChange: OnChangeFn;
  onLeftChange: OnChangeFn;
  onRightChange: OnChangeFn;
}

const Positioning: React.FC<Props> = React.memo(
  ({
    positions,
    title,
    onBottomChange,
    onLeftChange,
    onRightChange,
    onTopChange,
    disabled,
    minLimit,
    maxLimit,
    step,
    ...props
  }) => {
    const getPositionValue = React.useCallback(
      (direction: 'top' | 'bottom' | 'left' | 'right') => {
        if (minLimit && maxLimit) {
          return positions[direction] < minLimit[direction]
            ? minLimit[direction]
            : positions[direction] > maxLimit[direction]
            ? maxLimit[direction]
            : positions[direction];
        }
        return positions[direction];
      },
      [minLimit, maxLimit, positions],
    );

    return (
      <Container {...props}>
        {title && (
          <Typography variant={TypographyVariants.H5_BOLD}>{title}</Typography>
        )}
        <InputsGrid>
          <TopInput
            name="top-position"
            type="number"
            width={105}
            unit="mm"
            value={String(getPositionValue('top'))}
            disabled={disabled?.top}
            onBlur={onTopChange}
            min={minLimit?.top}
            max={maxLimit?.top}
            step={step}
            errorFeedback
            withoutDebounce
          />
          <BottomInput
            name="bottom-position"
            type="number"
            width={105}
            unit="mm"
            value={String(getPositionValue('bottom'))}
            disabled={disabled?.bottom}
            onBlur={onBottomChange}
            min={minLimit?.bottom}
            max={maxLimit?.bottom}
            step={step}
            errorFeedback
            withoutDebounce
          />
          <LeftInput
            name="left-position"
            type="number"
            width={105}
            unit="mm"
            value={String(getPositionValue('left'))}
            disabled={disabled?.left}
            onBlur={onLeftChange}
            min={minLimit?.left}
            max={maxLimit?.left}
            step={step}
            errorFeedback
            withoutDebounce
          />
          <RightInput
            name="right-position"
            type="number"
            width={105}
            unit="mm"
            value={String(getPositionValue('right'))}
            disabled={disabled?.right}
            onBlur={onRightChange}
            min={minLimit?.right}
            max={maxLimit?.right}
            step={step}
            errorFeedback
            withoutDebounce
          />
          <Placeholder />
        </InputsGrid>
      </Container>
    );
  },
);

Positioning.displayName = 'Positioning';

export default Positioning;
