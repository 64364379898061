import { AxiosInstance, AxiosResponse } from 'axios';
import { stringify } from 'qs';

import { Endpoints } from '../endpoints';
import { instance_v2 } from '../config';
import { UserStatus } from '../models/user';
import {
  CompanyMember,
  CompanyMemberSearch,
  AddMemberToCompanyCommand,
  EditCompanyMemberCommand,
} from '../models/companyMember';
import { Page, Pageable } from '../models/requests';
import { Company, UpdateCompanyAction } from '../models/company';

export interface FilterCommand {
  userName: string;
  userStatus: UserStatus;
}

export interface FilterRequest {
  command?: Partial<FilterCommand>;
  pageable?: Partial<Pageable>;
}

class CompanyApi {
  constructor(private http: AxiosInstance) {}

  public getCompany = () => this.http.get<Company>(`${Endpoints.COMPANY}`);

  public updateCompany = (command: UpdateCompanyAction) =>
    this.http.put<Company>(`${Endpoints.COMPANY}`, command);

  public getMyProfile = () =>
    this.http.get<CompanyMember>(`${Endpoints.COMPANY}/me`);

  public updateCompanyMember = (
    id: number,
    command: EditCompanyMemberCommand,
  ) =>
    this.http.put<CompanyMember>(
      `${Endpoints.COMPANY}/edit-user/${id}`,
      command,
    );

  public createCompanyMember = (command: AddMemberToCompanyCommand) =>
    this.http.post<CompanyMember>(`${Endpoints.COMPANY}/add-user`, command);

  public filterCompanyMembers = ({ command, pageable }: FilterRequest = {}) => {
    return this.http
      .post<
        Page<
          Omit<CompanyMemberSearch, 'systemRole'> & {
            systemRole: 'company_owner' | 'designer';
          }
        >
      >(`${Endpoints.COMPANY}/users?${stringify(pageable)}`, command ?? {})
      .then(
        (response): AxiosResponse<Page<CompanyMemberSearch>> => {
          if (!response) {
            return response;
          }

          return {
            ...response,
            data: {
              ...response?.data,
              content:
                response.data?.content?.map((item) => ({
                  ...item,
                  systemRole: item.systemRole.toLocaleUpperCase() as CompanyMemberSearch['systemRole'],
                })) || [],
            },
          };
        },
      );
  };
}

// TODO: Temporary instance caused by big BE changes, needs to be removed
const companyApi = new CompanyApi(instance_v2);
export default companyApi;
