import * as React from 'react';
import { Container, Item } from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from '../../hooks';

export interface Props {
  onOrderSelect(): void;
  onOfferSelect(): void;
  onClose(): void;
  show?: boolean;
}

type ItemType = 'order' | 'offer';

interface ItemButton {
  type: ItemType;
  title: string;
  text: string;
}

const CreateOrderMenu: React.FC<Props> = React.memo(
  ({ onOrderSelect, onOfferSelect, onClose, show, ...props }) => {
    const { t } = useTranslation('components');
    const elementRef = useRef<HTMLDivElement>(null);

    const items = useMemo<ItemButton[]>(
      () => [
        {
          type: 'order',
          title: t('createOrderMenu.generateOrder'),
          text: t('createOrderMenu.orderDescription'),
        },
        {
          type: 'offer',
          title: t('createOrderMenu.generateOffer'),
          text: t('createOrderMenu.offerDescription'),
        },
      ],
      [t],
    );

    useClickOutside({ elementRef, onClickOutside: onClose });

    const handleClick = useCallback(
      (type: ItemType) => {
        if (type === 'order') {
          onOrderSelect();
        }
        if (type === 'offer') {
          onOfferSelect();
        }
        return onClose();
      },
      [onClose, onOfferSelect, onOrderSelect],
    );

    if (show) {
      return (
        <Container {...props} ref={elementRef}>
          {items.map((item) => (
            <Item
              role="button"
              aria-label={`create-${item.type}`}
              key={item.type}
              onClick={() => handleClick(item.type)}>
              <Typography variant={TypographyVariants.H4_BOLD}>
                {item.title}
              </Typography>
              <Typography variant={TypographyVariants.H5}>
                {item.text}
              </Typography>
            </Item>
          ))}
        </Container>
      );
    }
    return null;
  },
);

CreateOrderMenu.displayName = 'CreateOrderMenu';

export default CreateOrderMenu;
