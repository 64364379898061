import Big from 'big.js';
import { Vector3 } from './Vector3';
import { Vector2 as IVector2 } from './types';
import { EPSILON } from './common';

export class Vector2 implements IVector2 {
  /**
   * Retrieves a new instance of the vector (0, 0)
   * @returns {Vector2} The zero vector
   */
  static get zero(): Vector2 {
    return new Vector2([0, 0]);
  }

  private _values = [new Big(0), new Big(0)];

  /**
   * @returns {number} The x-component of the vector
   */
  get x(): Big {
    return this._values[0];
  }

  /**
   * @param {number} value The new x-component of the vector
   */
  set x(value: Big) {
    this._values[0] = value;
  }

  /**
   * @returns {number} The y-component of the vectory
   */
  get y(): Big {
    return this._values[1];
  }

  /**
   * @param {number} value The new y-component of the vector
   */
  set y(value: Big) {
    this._values[1] = value;
  }

  /**
   * @returns {number[]} An array containing the x-component and y-component of the vector
   */
  get xy(): Big[] {
    return [this._values[0], this._values[1]];
  }

  /**
   * @param {number[]} values An array containing the new x-component and y-component of the vector
   */
  set xy(values: Big[]) {
    this._values[0] = values[0];
    this._values[1] = values[1];
  }

  constructor(values?: (number | Big)[]) {
    if (values && values.length) {
      this.xy = values.map((value) => new Big(value));
    }
  }

  cross(vector: Vector2, vector2: Vector2, dest?: Vector3): Vector3 {
    const copied = new Vector3();

    copied.xy = Object.assign({}, dest ? dest.xy : this.xy);

    const x = vector.x;
    const y = vector.y;
    const x2 = vector2.x;
    const y2 = vector2.y;

    const z = x.times(y2).minus(y.times(x2));
    copied.xyz = [new Big(0), new Big(0), z];
    return copied;
  }

  /**
   * Calculates the dot product of two vectors
   * @param {Vector2} vector
   * @param {Vector2} vector2
   * @returns {Big} The dot product of the two vectors
   */
  static dot(vector: Vector2, vector2: Vector2): Big {
    return vector.x.times(vector2.x).plus(vector.y.times(vector2.y));
  }

  /**
   * Calculates the distance between two vectors
   * @param {Vector2} vector
   * @param {Vector2} vector2
   * @returns {Big} The distance between the two vectors
   */
  distance(vector: Vector2, vector2: Vector2): Big {
    return this.squaredDistance(vector, vector2).sqrt();
  }

  /**
   * Calculates the distance between two vectors squared
   * @param {Vector2} vector
   * @param {Vector2} vector2
   * @returns {Big} The distance between the two vectors
   */
  squaredDistance(vector: Vector2, vector2: Vector2): Big {
    const x = vector2.x.minus(vector.x);
    const y = vector2.y.minus(vector.y);
    return x.times(x).plus(y.times(y));
  }

  /**
   * Calculates a normalized vector representing the direction from one vector to another.
   * If no dest vector is specified, a new vector is instantiated.
   * @param {Vector2} vector
   * @param {Vector2} vector2
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  direction(vector: Vector2, vector2: Vector2, dest?: Vector2): Vector2 {
    if (!dest) dest = new Vector2();
    const x = vector2.x.minus(vector.x);
    const y = vector2.y.minus(vector.y);
    let length = x.times(x).plus(y.times(y)).sqrt();
    if (length.toNumber() === 0) {
      dest.reset();
      return dest;
    }
    length = new Big(1).div(length);
    dest.x = x.times(length);
    dest.y = y.times(length);
    return dest;
  }

  /**
   * Performs a linear interpolation over two vectors.
   * If no dest vector is specified, a new vector is instantiated.
   * @param {Vector2} a
   * @param {Vector2} b
   * @param {number} t
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  lerp(a: Vector2, b: Vector2, t: number, dest?: Vector2): Vector2 {
    if (!dest) dest = new Vector2();
    dest.x = a.x.plus(b.x.minus(a.x).times(t));
    dest.y = a.y.plus(b.y.minus(a.y).times(t));
    return dest;
  }

  /**
   * Adds two vectors.
   * If no dest vector is specified, a new vector is instantiated.
   * @param {Vector2} vector
   * @param {Vector2} vector2
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  sum(vector: Vector2, vector2: Vector2, dest?: Vector2): Vector2 {
    if (!dest) dest = new Vector2();
    dest.x = vector.x.plus(vector2.x);
    dest.y = vector.y.plus(vector2.y);
    return dest;
  }

  /**
   * Subtracts two vectors.
   * If no dest vector is specified, a new vector is instantiated.
   * @param {Vector2} vector
   * @param {Vector2} vector2
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  difference(vector: Vector2, vector2: Vector2, dest?: Vector2): Vector2 {
    if (!dest) dest = new Vector2();

    dest.x = vector.x.minus(vector2.x);
    dest.y = vector.y.minus(vector2.y);

    return dest;
  }

  /**
   * Multiplies two vectors piecewise.
   * If no dest vector is specified, a new vector is instantiated.
   * @param {Vector2} vector
   * @param {Vector2} vector2
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  product(vector: Vector2, vector2: Vector2, dest?: Vector2): Vector2 {
    if (!dest) dest = new Vector2();

    dest.x = vector.x.times(vector2.x);
    dest.y = vector.y.times(vector2.y);

    return dest;
  }

  /**
   * Divides two vectors piecewise.
   * If no dest vector is specified, a new vector is instantiated.
   * @param {Vector2} vector
   * @param {Vector2} vector2
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  quotient(vector: Vector2, vector2: Vector2, dest?: Vector2): Vector2 {
    if (!dest) dest = new Vector2();

    dest.x = vector.x.div(vector2.x);
    dest.y = vector.y.div(vector2.y);

    return dest;
  }

  /**
   * Retrieves the x-component or y-component of the vector.
   * @param {number} index
   * @returns {Big}
   */
  at(index: number): Big {
    return this._values[index];
  }

  /**
   * Sets both the x- and y-components of the vector to 0.
   */
  reset(): void {
    this.x = new Big(0);
    this.y = new Big(0);
  }

  /**
   * Copies the x- and y-components from one vector to another.
   * If no dest vector is specified, a new vector is instantiated.
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  copy(dest?: Vector2): Vector2 {
    const copied = new Vector2();

    copied.xy = Object.assign({}, dest ? dest.xy : this.xy);
    return copied;
  }

  /**
   * Multiplies both the x- and y-components of a vector by -1.
   * If no dest vector is specified, the operation is performed in-place.
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  negate(dest?: Vector2): Vector2 {
    const copied = new Vector2();

    copied.xy = Object.assign({}, dest ? dest.xy : this.xy);

    copied.x = copied.x.times(-1);
    copied.y = copied.y.times(-1);
    return copied;
  }

  /**
   * Checks if two vectors are equal, using a threshold to avoid floating-point precision errors.
   * @param {Vector2} other
   * @param {number} threshold
   * @returns {boolean}
   */
  equals(other: Vector2, threshold = EPSILON): boolean {
    return this.x.cmp(other.x) <= threshold && this.y.cmp(other.y) <= threshold;
  }

  /**
   * Returns the distance from the vector to the origin.
   * @returns {number}
   */
  length(): Big {
    return this.squaredLength().sqrt();
  }

  /**
   * Returns the distance from the vector to the origin, squared.
   * @returns {number}
   */
  squaredLength(): Big {
    const x = this.x;
    const y = this.y;
    return x.times(x).plus(y.times(y));
  }

  /**
   * Adds two vectors together.
   * If no dest vector is specified, the operation is performed in-place.
   * @param {Vector2} vector
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  add(vector: Vector2, dest?: Vector2): Vector2 {
    const copied = new Vector2();

    copied.xy = Object.assign({}, dest ? dest.xy : this.xy);
    copied.x = copied.x.plus(vector.x);
    copied.y = copied.y.plus(vector.y);
    return copied;
  }

  /**
   * Subtracts one vector from another.
   * If no dest vector is specified, the operation is performed in-place.
   * @param {Vector2} vector
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  subtract(vector: Vector2, dest?: Vector2): Vector2 {
    const copied = new Vector2();

    copied.xy = Object.assign({}, dest ? dest.xy : this.xy);
    copied.x = copied.x.sub(vector.x);
    copied.y = copied.y.sub(vector.y);
    return copied;
  }

  /**
   * Multiplies two vectors together piecewise.
   * If no dest vector is specified, the operation is performed in-place.
   * @param {Vector2} vector
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  multiply(vector: Vector2, dest?: Vector2): Vector2 {
    const copied = new Vector2();

    copied.xy = Object.assign({}, dest ? dest.xy : this.xy);
    copied.x = copied.x.times(vector.x);
    copied.y = copied.y.times(vector.y);
    return copied;
  }

  /**
   * Divides two vectors piecewise.
   * @param {Vector2} vector
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  divide(vector: Vector2, dest?: Vector2): Vector2 {
    const copied = new Vector2();

    copied.xy = Object.assign({}, dest ? dest.xy : this.xy);
    copied.x = copied.x.div(vector.x);
    copied.y = copied.y.div(vector.y);
    return copied;
  }

  /**
   * Scales a vector by a scalar parameter.
   * If no dest vector is specified, the operation is performed in-place.
   * @param {number} value
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  scale(value: number, dest?: Vector2): Vector2 {
    const copied = new Vector2();

    copied.xy = Object.assign({}, dest ? dest.xy : this.xy);
    copied.x = copied.x.times(value);
    copied.y = copied.y.times(value);
    return copied;
  }

  /**
   * Normalizes a vector.
   * If no dest vector is specified, the operation is performed in-place.
   * @param {Vector2} dest
   * @returns {Vector2}
   */
  normalize(dest?: Vector2): Vector2 {
    const copied = new Vector2();

    copied.xy = Object.assign({}, dest ? dest.xy : this.xy);

    const x = new Big(dest ? dest.x.toString() : this.x.toString());
    const y = new Big(dest ? dest.y.toString() : this.y.toString());

    const length = x.pow(2).plus(y.pow(2)).sqrt();
    if (length.eq(1)) {
      return copied;
    }
    if (length.eq(0)) {
      copied.reset();
      return copied;
    }
    const divided = new Big(1).div(length);

    return new Vector2([x.times(divided), y.times(divided)]);
  }

  toString(): string {
    return '(' + this.x.toString() + ', ' + this.y.toString() + ')';
  }
}
