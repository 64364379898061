import { useCallback, useMemo } from 'react';
import { Connection } from '../../../../services/api/models/connection';
import { Glass } from '../../../../services/api/models/glass';
import { ProductSearch } from '../../../../services/api/models/product';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { defaultGaps, ProductGaps, useGaps } from '../../hooks';
import { Position } from '../../types';
import { Anchor } from '../../types/Anchor';
import { NeighborType } from '../../utils/neighbors';

interface Props {
  glassParent?: Glass;
  usedProduct?: ProductSearch;
  parentConnection: Connection;
  glasses: Glass[];
  templatePosition: Position;
  templateDimensions: ProjectDimensions;
}

const useProductMinEgdeGap = ({
  glassParent,
  usedProduct,
  parentConnection,
  glasses,
  templatePosition,
  templateDimensions,
}: Props) => {
  const { findGapOnAnchor } = useGaps({
    glasses,
    templatePosition,
    templateDimensions,
  });

  const findNeighbor = useCallback(
    (anchor: Anchor, glass: Glass) => {
      const gap = findGapOnAnchor(anchor, glass);
      return gap?.neighbor;
    },
    [findGapOnAnchor],
  );

  const minEdgeGaps = useMemo(() => {
    if (!glassParent) {
      return undefined;
    }
    const neighbor = findNeighbor(parentConnection.anchor, glassParent);
    let gaps: ProductGaps = defaultGaps;
    if (usedProduct) {
      gaps = {
        gapFromEdge: usedProduct.attributes.gapFromGlassEdge,
        gapFromWall:
          usedProduct.wallToGlassGap.length > 0
            ? Math.max(...usedProduct.wallToGlassGap)
            : 0,
        gapFromGlass:
          usedProduct.glassToGlassGap.length > 0
            ? Math.max(...usedProduct.glassToGlassGap)
            : 0,
      };
    }
    const minDistance =
      neighbor === NeighborType.GLASS ? gaps.gapFromGlass : gaps.gapFromWall;
    return {
      top: gaps.gapFromEdge,
      bottom: gaps.gapFromEdge,
      left: minDistance,
      right: minDistance,
    };
  }, [findNeighbor, glassParent, parentConnection.anchor, usedProduct]);

  return minEdgeGaps;
};

export default useProductMinEgdeGap;
