import * as React from 'react';

const SvgAngle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4 2C4 1.44772 3.55228 1 3 1C2.44772 1 2 1.44772 2 2H4ZM2 2L2 21H4L4 2H2Z"
        fill={props.fill ?? '#272B5F'}
      />
      <path
        d="M21 22C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20V22ZM2 22H21V20H2V22Z"
        fill={props.fill ?? '#272B5F'}
      />
      <path
        d="M6 8V8C11.5228 8 16 12.4772 16 18V18"
        stroke={props.fill ?? '#272B5F'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const MemoSvgAngle = React.memo(SvgAngle);
export default MemoSvgAngle;
