import { AxiosInstance } from 'axios';
import { stringify } from 'qs';
import { instance_v2 } from '../config';

import { Endpoints } from '../endpoints';
import {
  AddProductCommandSubmit,
  GetProductsFilterRequest,
  Product,
  ProductSearch,
} from '../models/product';
import { Page } from '../models/requests';

export interface ChangeProductsStatusCommand {
  productIds: number[];
  active: boolean;
}

export interface DeleteProductsCommand {
  productIds: number[];
}

class ProductAdminApi {
  constructor(private http: AxiosInstance) {}

  public createProduct = (command: AddProductCommandSubmit) => {
    // TODO: remove after testing / debugging on dev
    console.log('BANG createProduct', command);
    return this.http.post<Product>(Endpoints.PRODUCT_ADMIN, command);
  };

  public updateProduct = (
    productId: number,
    command: AddProductCommandSubmit,
  ) => {
    // TODO: remove after testing / debugging on dev
    console.log('BANG updateProduct command', command);
    return this.http.put<Product>(
      `${Endpoints.PRODUCT_ADMIN}/${productId}`,
      command,
    );
  };

  public deleteProducts = (command: DeleteProductsCommand) =>
    this.http.delete(
      `${Endpoints.PRODUCT_ADMIN}?${stringify(command, {
        arrayFormat: 'comma',
      })}`,
    );

  public changeProductsStatus = (command: ChangeProductsStatusCommand) =>
    this.http.patch(
      `${Endpoints.PRODUCT_ADMIN}?${stringify(command, {
        arrayFormat: 'comma',
      })}`,
    );

  public filterProducts = ({
    command,
    pageable,
  }: GetProductsFilterRequest = {}) => {
    return this.http.post<Page<ProductSearch>>(
      `${Endpoints.PRODUCT_ADMIN}/filter?${stringify(pageable)}`,
      command ?? {},
    );
  };
}
// TODO: Temporary instance caused by big BE changes, needs to be removed
const productAdminApi = new ProductAdminApi(instance_v2);
export default productAdminApi;
