import React, { useEffect } from 'react';
import { useSpring, UseSpringProps } from 'react-spring';
import { CSSProperties, useMemo, useRef } from 'react';
import ReactDom from 'react-dom';

import { Container } from './styles';
import { SidePanelPosition } from './types';
import Trigger from './Trigger';
import { Backdrop } from '../Backdrop';

export interface Props {
  open?: boolean;
  width?: number;
  position?: SidePanelPosition;
  withTrigger?: boolean;
  onToggle?(): void;
  backdrop?: boolean;
  fullScreen?: boolean;
  fixed?: boolean;
}

const SidePanel: React.FC<Props> = React.memo(
  ({
    children,
    onToggle,
    backdrop,
    fullScreen,
    fixed,
    open = true,
    width = 304,
    position = SidePanelPosition.LEFT,
    withTrigger = true,
  }) => {
    const init = useRef<HTMLDivElement>(null);

    const springConfig = useMemo<UseSpringProps<CSSProperties>>(
      () =>
        position === SidePanelPosition.RIGHT
          ? {
              right: open ? 0 : -width,
              from: { right: open ? -width : 0 },
            }
          : {
              left: open ? 0 : -width,
              from: { left: open ? -width : 0 },
            },
      [open, position, width],
    );

    useEffect(() => {
      if (fixed && open) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'visible';
      }
    }, [fixed, open]);

    const springProps = useSpring({
      ...springConfig,
      immediate: !init.current,
    });

    const SidePanelCoponent = (
      <>
        <Container
          data-testid="panel"
          ref={init}
          style={springProps}
          width={width}
          position={position}
          fixed={fixed ? String(fixed) : undefined}
          fullscreen={fullScreen ? String(fullScreen) : undefined}>
          {children}
          {withTrigger && onToggle && (
            <Trigger onClick={onToggle} open={open} panelPosition={position} />
          )}
        </Container>
        {backdrop && (
          <Backdrop show={open} zIndex={fullScreen ? 89 : 9} opacity={0.3} />
        )}
      </>
    );

    if (fullScreen) {
      return ReactDom.createPortal(SidePanelCoponent, document.body);
    }

    return SidePanelCoponent;
  },
);

SidePanel.displayName = 'SidePanel';

export default SidePanel;
