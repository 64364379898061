import { State } from './select';

export const selectSelected = (state: State) => state.selected;

export const selectSelect = (state: State) => state.select;

export const selectDeselect = (state: State) => state.deselect;

export const selectContextMenu = (state: State) => state.contextMenu;

export const selectOpenContextMenu = (state: State) => state.openContextMenu;

export const selectCloseContextMenu = (state: State) => state.closeContextMenu;

export const selectMulti = (state: State) => state.selected.length > 1;
