import * as React from 'react';
import { useTransition } from 'react-spring';

import { Container } from './styles';
import { theme } from '../../styles';

export interface Props {
  zIndex: number;
  show?: boolean;
  color?: string;
  opacity?: number;
}

const Backdrop: React.FC<Props> = React.memo(
  ({ zIndex, show, color = theme.colors.grey2, opacity = 0 }) => {
    const transition = useTransition(!!show, null, {
      from: { opacity: 0 },
      enter: { opacity },
      leave: { opacity: 0 },
    });

    return (
      <>
        {transition.map(
          ({ item, key, props }) =>
            item && (
              <Container
                key={key}
                style={props}
                zindex={zIndex}
                color={color}
                opacity={opacity}
                data-testid="backdrop"
              />
            ),
        )}
      </>
    );
  },
);

Backdrop.displayName = 'Backdrop';

export default Backdrop;
