import { Anchor } from '../types/Anchor';
import { DiagonalDirection } from '../utils/shadows';
import { Direction } from '../utils/neighbors';

export const ANCHOR_SIZE = 20;

export const OPPOSITE_ANCHORS: Record<Anchor, Anchor> = {
  top: 'bottom',
  topLeft: 'bottomRight',
  left: 'right',
  bottomLeft: 'topRight',
  bottom: 'top',
  bottomRight: 'topLeft',
  right: 'left',
  topRight: 'bottomLeft',
  center: 'center',
  product: 'product',
};

export const SAME_GLASS_ADJACENT_ANCHORS: Record<Anchor, Anchor[]> = {
  top: ['topRight', 'topLeft'],
  topLeft: ['top', 'left'],
  left: ['topLeft', 'bottomLeft'],
  bottomLeft: ['left', 'bottom'],
  bottom: ['bottomLeft', 'bottomRight'],
  bottomRight: ['bottom', 'right'],
  right: ['bottomRight', 'topRight'],
  topRight: ['right', 'top'],
  center: [],
  product: [],
};

export const NEIGHBOR_ADJACENT_ANCHORS: Record<
  Anchor,
  Record<Direction | DiagonalDirection, Anchor[]>
> = {
  top: {
    top: ['bottomLeft', 'bottom', 'bottomRight'],
    topLeft: ['bottomRight'],
    left: ['topRight'],
    bottomLeft: [],
    bottom: [],
    bottomRight: [],
    right: ['topLeft'],
    topRight: ['bottomLeft'],
  },
  topLeft: {
    top: ['bottomLeft'],
    topLeft: ['bottom', 'bottomRight', 'right'],
    left: ['topRight'],
    bottomLeft: [],
    bottom: [],
    bottomRight: [],
    right: [],
    topRight: [],
  },
  left: {
    top: ['bottomLeft'],
    topLeft: ['bottomRight'],
    left: ['bottomRight', 'right', 'topRight'],
    bottomLeft: ['topRight'],
    bottom: ['topLeft'],
    bottomRight: [],
    right: [],
    topRight: [],
  },
  bottomLeft: {
    top: [],
    topLeft: [],
    left: ['bottomRight'],
    bottomLeft: ['top', 'topRight', 'right'],
    bottom: ['topLeft'],
    bottomRight: [],
    right: [],
    topRight: [],
  },
  bottom: {
    top: [],
    topLeft: [],
    left: ['bottomRight'],
    bottomLeft: ['topRight'],
    bottom: ['topLeft', 'top', 'topRight'],
    bottomRight: ['topLeft'],
    right: ['bottomLeft'],
    topRight: [],
  },
  bottomRight: {
    top: [],
    topLeft: [],
    left: [],
    bottomLeft: [],
    bottom: ['topRight'],
    bottomRight: ['top', 'topLeft', 'left'],
    right: ['bottomLeft'],
    topRight: [],
  },
  right: {
    top: ['bottomRight'],
    topLeft: [],
    left: [],
    bottomLeft: [],
    bottom: ['topRight'],
    bottomRight: ['topLeft'],
    right: ['bottomLeft', 'left', 'topLeft'],
    topRight: ['bottomLeft'],
  },
  topRight: {
    top: ['bottomRight'],
    topLeft: [],
    left: [],
    bottomLeft: [],
    bottom: [],
    bottomRight: [],
    right: ['topLeft'],
    topRight: ['bottom', 'bottomLeft', 'left'],
  },
  center: {
    top: [],
    topLeft: [],
    left: [],
    bottomLeft: [],
    bottom: [],
    bottomRight: [],
    right: [],
    topRight: [],
  },
  product: {
    top: [],
    topLeft: [],
    left: [],
    bottomLeft: [],
    bottom: [],
    bottomRight: [],
    right: [],
    topRight: [],
  },
};
