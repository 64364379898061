import { useCallback, useMemo } from 'react';
import {
  Glass,
  GlassDimensions,
  GlassPropertiesSearch,
} from '../../../../services/api/models/glass';
import {
  AnchoringOptions,
  BaseType,
  ProductModel,
  ProductSearch,
} from '../../../../services/api/models/product';
import { ANCHOR_SIZE } from '../../constants/anchors';
import { useGlassGlassProduct } from '../../hooks';
import { Anchor } from '../../types/Anchor';
import { Gaps } from '../../types/Gap';
import { NeighborType } from '../../utils/neighbors';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { findPointOnSegment, toVector } from '../../../../utils/shape';
import { Shape } from '../../../space';
import { DEFAULT_RATIO } from '../../store/config';
import { RectCorners } from '../../../../types';
import Konva from 'konva';
import { findAngle } from '../../../../utils/glass';
import { checkIfGlassGlass } from '../../utils/product';

interface DropZoneProps {
  corners: ProjectDimensions['corners'];
  product: ProductSearch;
  glass: Glass;
  glassGaps: Gaps;
  glassProducts: ProductModel[];
}

const initAnchoring: AnchoringOptions = {
  top: false,
  bottom: false,
  right: false,
  left: false,
  topLeft: false,
  topRight: false,
  bottomLeft: false,
  bottomRight: false,
  center: false,
};

type AnchorVariant = 'glass' | 'wall' | 'mixed';

export const useDropZone = ({
  corners,
  product,
  glass,
  glassGaps,
  glassProducts,
}: DropZoneProps) => {
  const { isValid4CornerDrop } = useGlassGlassProduct();

  const getProps = useCallback(
    (zone: keyof AnchoringOptions): RectCorners => {
      const shape = new Shape({ corners }, glass.position);

      shape.scaleShape(DEFAULT_RATIO);

      const scaledCorners = shape.corners;

      switch (zone) {
        case 'left': {
          const topRight = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-left']),
            toVector(scaledCorners['top-right']),
          ]);
          const bottomRight = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-left']),
            toVector(scaledCorners['bottom-right']),
          ]);

          return {
            'top-left': scaledCorners['top-left'],
            'top-right': [topRight.x, topRight.y],
            'bottom-left': scaledCorners['bottom-left'],
            'bottom-right': [bottomRight.x, bottomRight.y],
          };
        }
        case 'right': {
          const topLeft = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-right']),
            toVector(scaledCorners['top-left']),
          ]);
          const bottomLeft = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-right']),
            toVector(scaledCorners['bottom-left']),
          ]);
          return {
            'top-left': [topLeft.x, topLeft.y],
            'top-right': scaledCorners['top-right'],
            'bottom-left': [bottomLeft.x, bottomLeft.y],
            'bottom-right': scaledCorners['bottom-right'],
          };
        }
        case 'top': {
          const bottomLeft = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-left']),
            toVector(scaledCorners['bottom-left']),
          ]);
          const bottomRight = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-right']),
            toVector(scaledCorners['bottom-right']),
          ]);

          return {
            'top-left': scaledCorners['top-left'],
            'top-right': scaledCorners['top-right'],
            'bottom-left': [bottomLeft.x, bottomLeft.y],
            'bottom-right': [bottomRight.x, bottomRight.y],
          };
        }
        case 'bottom': {
          const topLeft = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-left']),
            toVector(scaledCorners['top-left']),
          ]);
          const topRight = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-right']),
            toVector(scaledCorners['top-right']),
          ]);
          return {
            'top-left': [topLeft.x, topLeft.y],
            'top-right': [topRight.x, topRight.y],
            'bottom-left': scaledCorners['bottom-left'],
            'bottom-right': scaledCorners['bottom-right'],
          };
        }
        case 'center':
          const topLeftX = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-left']),
            toVector(scaledCorners['top-right']),
          ]).x;
          const topRightX = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-right']),
            toVector(scaledCorners['top-left']),
          ]).x;
          const topLeftY = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-left']),
            toVector(scaledCorners['bottom-left']),
          ]).y;
          const topRightY = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-right']),
            toVector(scaledCorners['bottom-right']),
          ]).y;
          const bottomLeftX = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-left']),
            toVector(scaledCorners['bottom-right']),
          ]).x;
          const bottomLeftY = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-left']),
            toVector(scaledCorners['top-left']),
          ]).y;
          const bottomRightX = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-right']),
            toVector(scaledCorners['bottom-left']),
          ]).x;
          const bottomRightY = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-right']),
            toVector(scaledCorners['top-right']),
          ]).y;
          return {
            'top-left': [topLeftX, topLeftY],
            'top-right': [topRightX, topRightY],
            'bottom-left': [bottomLeftX, bottomLeftY],
            'bottom-right': [bottomRightX, bottomRightY],
          };
        case 'topLeft': {
          const topRight = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-left']),
            toVector(scaledCorners['top-right']),
          ]);
          const bottomLeft = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-left']),
            toVector(scaledCorners['bottom-left']),
          ]);

          return {
            'top-left': scaledCorners['top-left'],
            'top-right': [topRight.x, topRight.y],
            'bottom-left': [bottomLeft.x, bottomLeft.y],
            'bottom-right': [topRight.x, bottomLeft.y],
          };
        }
        case 'bottomLeft': {
          const topLeft = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-left']),
            toVector(scaledCorners['top-left']),
          ]);
          const bottomRight = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-left']),
            toVector(scaledCorners['bottom-right']),
          ]);

          return {
            'top-left': [topLeft.x, topLeft.y],
            'top-right': [bottomRight.x, topLeft.y],
            'bottom-left': scaledCorners['bottom-left'],
            'bottom-right': [bottomRight.x, bottomRight.y],
          };
        }
        case 'topRight': {
          const bottomRight = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-right']),
            toVector(scaledCorners['bottom-right']),
          ]);
          const topLeft = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['top-right']),
            toVector(scaledCorners['top-left']),
          ]);

          return {
            'top-left': [topLeft.x, topLeft.y],
            'top-right': scaledCorners['top-right'],
            'bottom-left': [topLeft.x, bottomRight.y],
            'bottom-right': [bottomRight.x, bottomRight.y],
          };
        }
        case 'bottomRight': {
          const topRight = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-right']),
            toVector(scaledCorners['top-right']),
          ]);
          const bottomLeft = findPointOnSegment(ANCHOR_SIZE, [
            toVector(scaledCorners['bottom-right']),
            toVector(scaledCorners['bottom-left']),
          ]);

          return {
            'top-left': [bottomLeft.x, topRight.y],
            'top-right': [topRight.x, topRight.y],
            'bottom-left': [bottomLeft.x, bottomLeft.y],
            'bottom-right': scaledCorners['bottom-right'],
          };
        }
      }
    },
    [corners],
  );

  const isValidGlassGlass4Corner = useCallback(
    (
      anchor: Anchor,
      gapsBetweenGlasses?: GlassDimensions,
      thickness?: number[],
    ) => {
      if (product.baseType === BaseType.GLASS_GLASS_180_4) {
        return isValid4CornerDrop(
          glass.id,
          anchor,
          gapsBetweenGlasses,
          thickness,
        );
      } else {
        return true;
      }
    },
    [glass, product, isValid4CornerDrop],
  );

  const checkGap = useCallback(
    (gap: [number, number], value: number, ratio = 1) => {
      let [value1, value2] = gap;
      value1 = value1 * ratio;
      value2 = value2 * ratio;
      return value1 <= value && value2 <= value;
    },
    [],
  );

  const checkAngle = useCallback(
    (point1: Konva.Vector2d, point2: Konva.Vector2d) => {
      const angle = findAngle(point1, { x: point1.x, y: point2.y }, point2);
      return angle > 89 && angle < 91;
    },
    [],
  );

  const hasProductMounted = useCallback(
    (anchor: string) =>
      glassProducts.some(
        (product) =>
          product.connections[0].anchor === anchor &&
          product.connections[0].targetId === String(glass.id),
      ),
    [glassProducts, glass.id],
  );

  const setWallToGlass = useCallback(
    (glassProperties: GlassPropertiesSearch): AnchoringOptions => {
      const shape = new Shape({ corners }, glass.position);

      const { wallToGlass } = product.attributes;
      const { wallToGlassGap } = glassProperties;
      const { top, bottom, left, right } = glassGaps;
      const wall = { ...initAnchoring };

      wall.top =
        wallToGlass.top &&
        top.neighbor === NeighborType.WALL &&
        checkGap(top.value, wallToGlassGap.top);
      wall.bottom =
        wallToGlass.bottom &&
        bottom.neighbor === NeighborType.WALL &&
        checkGap(bottom.value, wallToGlassGap.bottom);
      wall.left =
        wallToGlass.left &&
        left.neighbor === NeighborType.WALL &&
        checkGap(left.value, wallToGlassGap.left);
      wall.right =
        wallToGlass.right &&
        right.neighbor === NeighborType.WALL &&
        checkGap(right.value, wallToGlassGap.right);
      wall.topLeft =
        wallToGlass.topLeft &&
        top.neighbor === NeighborType.WALL &&
        left.neighbor === NeighborType.WALL &&
        checkGap(top.value, wallToGlassGap.top) &&
        checkGap(left.value, wallToGlassGap.left) &&
        !hasProductMounted('topLeft') &&
        checkAngle(shape.leftEdge.points.bottom, shape.topEdge.points.right);
      wall.topRight =
        wallToGlass.topRight &&
        top.neighbor === NeighborType.WALL &&
        right.neighbor === NeighborType.WALL &&
        checkGap(top.value, wallToGlassGap.top) &&
        checkGap(right.value, wallToGlassGap.right) &&
        !hasProductMounted('topRight') &&
        checkAngle(shape.topEdge.points.left, shape.rightEdge.points.bottom);
      wall.bottomLeft =
        wallToGlass.bottomLeft &&
        bottom.neighbor === NeighborType.WALL &&
        left.neighbor === NeighborType.WALL &&
        checkGap(bottom.value, wallToGlassGap.bottom) &&
        checkGap(left.value, wallToGlassGap.left) &&
        !hasProductMounted('bottomLeft') &&
        checkAngle(shape.leftEdge.points.top, shape.bottomEdge.points.right);
      wall.bottomRight =
        wallToGlass.bottomRight &&
        bottom.neighbor === NeighborType.WALL &&
        right.neighbor === NeighborType.WALL &&
        checkGap(bottom.value, wallToGlassGap.bottom) &&
        checkGap(right.value, wallToGlassGap.right) &&
        !hasProductMounted('bottomRight') &&
        checkAngle(shape.rightEdge.points.top, shape.bottomEdge.points.left);
      wall.center = wallToGlass.center;

      return wall;
    },
    [checkGap, checkAngle, glassGaps, product.attributes],
  );

  const setGlassToGlass = useCallback(
    (glassProperties: GlassPropertiesSearch): AnchoringOptions => {
      const shape = new Shape({ corners }, glass.position);

      const { glassToGlass } = product.attributes;
      const { glassToGlassGap } = glassProperties;
      const { top, bottom, left, right } = glassGaps;
      const glassAnchoring = { ...initAnchoring };
      const thicknesses = [glassProperties.thickness];
      let ratio = 1;

      if (
        product.baseType === BaseType.GLASS_GLASS_90 ||
        product.baseType === BaseType.GLASS_GLASS_180
      ) {
        ratio = 0.5;
      }

      glassAnchoring.top =
        glassToGlass.top &&
        top.neighbor === NeighborType.GLASS &&
        checkGap(top.value, glassToGlassGap.top, ratio);
      glassAnchoring.bottom =
        glassToGlass.bottom &&
        bottom.neighbor === NeighborType.GLASS &&
        checkGap(bottom.value, glassToGlassGap.bottom, ratio);
      glassAnchoring.left =
        glassToGlass.left &&
        left.neighbor === NeighborType.GLASS &&
        checkGap(left.value, glassToGlassGap.left, ratio);
      glassAnchoring.right =
        glassToGlass.right &&
        right.neighbor === NeighborType.GLASS &&
        checkGap(right.value, glassToGlassGap.right, ratio);
      glassAnchoring.topLeft =
        glassToGlass.topLeft &&
        left.neighbor === NeighborType.GLASS &&
        checkGap(top.value, glassToGlassGap.top) &&
        checkGap(left.value, glassToGlassGap.left) &&
        isValidGlassGlass4Corner('topLeft', glassToGlassGap, thicknesses) &&
        !hasProductMounted('topLeft') &&
        checkAngle(shape.leftEdge.points.bottom, shape.topEdge.points.right);
      glassAnchoring.topRight =
        glassToGlass.topRight &&
        right.neighbor === NeighborType.GLASS &&
        checkGap(top.value, glassToGlassGap.top) &&
        checkGap(right.value, glassToGlassGap.right) &&
        isValidGlassGlass4Corner('topRight', glassToGlassGap, thicknesses) &&
        !hasProductMounted('topRight') &&
        checkAngle(shape.topEdge.points.left, shape.rightEdge.points.bottom);
      glassAnchoring.bottomLeft =
        glassToGlass.bottomLeft &&
        left.neighbor === NeighborType.GLASS &&
        checkGap(bottom.value, glassToGlassGap.bottom) &&
        checkGap(left.value, glassToGlassGap.left) &&
        isValidGlassGlass4Corner('bottomLeft', glassToGlassGap, thicknesses) &&
        !hasProductMounted('bottomLeft') &&
        checkAngle(shape.leftEdge.points.top, shape.bottomEdge.points.right);
      glassAnchoring.bottomRight =
        glassToGlass.bottomRight &&
        right.neighbor === NeighborType.GLASS &&
        checkGap(bottom.value, glassToGlassGap.bottom) &&
        checkGap(right.value, glassToGlassGap.right) &&
        isValidGlassGlass4Corner('bottomRight', glassToGlassGap, thicknesses) &&
        !hasProductMounted('bottomRight') &&
        checkAngle(shape.rightEdge.points.top, shape.bottomEdge.points.left);
      glassAnchoring.center = glassToGlass.center;

      return glassAnchoring;
    },
    [
      glassGaps,
      checkAngle,
      checkGap,
      hasProductMounted,
      corners,
      glass.position,
      product.attributes,
      product.baseType,
      isValidGlassGlass4Corner,
    ],
  );

  const compareMixedNeighbors = (
    neighborA: NeighborType,
    neighborB: NeighborType,
  ) => {
    return (
      (neighborA === NeighborType.WALL && neighborB === NeighborType.GLASS) ||
      (neighborB === NeighborType.WALL && neighborA === NeighborType.GLASS)
    );
  };

  const setMixedToGlass = useCallback(
    (glassProperties: GlassPropertiesSearch): AnchoringOptions => {
      const { wallToGlass, glassToGlass } = product.attributes;
      const { wallToGlassGap, glassToGlassGap } = glassProperties;
      const { top, bottom, left, right } = glassGaps;
      const mixed = { ...initAnchoring };

      mixed.topLeft =
        wallToGlass.topLeft &&
        glassToGlass.topLeft &&
        compareMixedNeighbors(top.neighbor, left.neighbor) &&
        checkGap(top.value, wallToGlassGap.top) &&
        checkGap(left.value, wallToGlassGap.left) &&
        checkGap(top.value, glassToGlassGap.top) &&
        checkGap(left.value, glassToGlassGap.left) &&
        !hasProductMounted('topLeft');
      mixed.topRight =
        wallToGlass.topRight &&
        compareMixedNeighbors(top.neighbor, right.neighbor) &&
        checkGap(top.value, wallToGlassGap.top) &&
        checkGap(right.value, wallToGlassGap.right) &&
        checkGap(top.value, glassToGlassGap.top) &&
        checkGap(right.value, glassToGlassGap.right) &&
        !hasProductMounted('topRight');
      mixed.bottomLeft =
        wallToGlass.bottomLeft &&
        compareMixedNeighbors(bottom.neighbor, left.neighbor) &&
        checkGap(bottom.value, wallToGlassGap.bottom) &&
        checkGap(left.value, wallToGlassGap.left) &&
        checkGap(bottom.value, glassToGlassGap.bottom) &&
        checkGap(left.value, glassToGlassGap.left) &&
        !hasProductMounted('bottomLeft');
      mixed.bottomRight =
        wallToGlass.bottomRight &&
        compareMixedNeighbors(bottom.neighbor, right.neighbor) &&
        checkGap(bottom.value, wallToGlassGap.bottom) &&
        checkGap(right.value, wallToGlassGap.right) &&
        checkGap(bottom.value, glassToGlassGap.bottom) &&
        checkGap(right.value, glassToGlassGap.right) &&
        !hasProductMounted('bottomRight');

      return mixed;
    },
    [glassGaps, product.attributes],
  );

  const allowed = useMemo((): Record<AnchorVariant, AnchoringOptions> => {
    const glassProperties = product.glassProperties.find(
      (item) => item.thickness === glass.thickness,
    );

    if (!glassProperties) {
      return {
        wall: { ...initAnchoring },
        glass: { ...initAnchoring },
        mixed: { ...initAnchoring },
      };
    }

    return {
      wall: !checkIfGlassGlass(product.baseType, product.mountTypes)
        ? setWallToGlass(glassProperties)
        : initAnchoring,
      glass: setGlassToGlass(glassProperties),
      mixed: setMixedToGlass(glassProperties),
    };
  }, [
    checkIfGlassGlass,
    glass.thickness,
    product,
    setGlassToGlass,
    setWallToGlass,
    setMixedToGlass,
  ]);

  return {
    getProps,
    allowed,
  };
};
