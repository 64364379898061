import { Positioning } from '../../components/Positioning';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Glass } from '../../../../../../services/api/models/glass';
import { Position } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { ProductModel } from '../../../../../../services/api/models/product';
import { Gaps } from '../../../../../../modules/creator/types/Gap';
import { BoundariesCopy } from '../../../../../../modules/creator/types';
import { Shape } from '../../../../../../modules/space';
import { ProjectDimensions } from '../../../../../../services/api/models/project';
import { fixValue } from '../../../../../../modules/creator/utils/fix';

interface GlassPositioningProps {
  selectedGlass: Glass;
  gaps: Gaps;
  boundaries: BoundariesCopy;
  templatePosition: Position;
  templateDimensions: ProjectDimensions;
  mountedProducts: ProductModel[];
  onUpdatePosition: (position: Partial<Position>) => void;
}

const GlassPositioning = ({
  selectedGlass,
  gaps,
  templatePosition,
  templateDimensions,
  mountedProducts,
  boundaries,
  onUpdatePosition,
}: GlassPositioningProps) => {
  const { t } = useTranslation('project');

  const glassObj = useMemo(
    () => new Shape({ corners: selectedGlass.corners }, selectedGlass.position),
    [selectedGlass],
  );

  const templateObj = useMemo(
    () => new Shape({ corners: templateDimensions.corners }, templatePosition),
    [templateDimensions, templatePosition],
  );

  const extremeGaps = useMemo(
    () => ({
      top: gaps.top.value.sort((a, b) => b - a)[0],
      bottom: gaps.bottom.value.sort((a, b) => b - a)[0],
      right: gaps.right.value.sort((a, b) => b - a)[0],
      left: gaps.left.value.sort((a, b) => b - a)[0],
    }),
    [gaps],
  );

  const extremeBoundaries = useMemo(
    () => ({
      top:
        boundaries.top.sort((a, b) => a.y - b.y)[0].y -
        templateObj.extremePoints.top.point.y,
      bottom:
        boundaries.bottom.sort((a, b) => b.y - a.y)[0].y -
        templateObj.extremePoints.top.point.y,
      left:
        boundaries.left.sort((a, b) => a.x - b.x)[0].x -
        templateObj.extremePoints.left.point.x,
      right:
        boundaries.right.sort((a, b) => b.x - a.x)[0].x -
        templateObj.extremePoints.left.point.x,
    }),
    [
      boundaries.bottom,
      boundaries.left,
      boundaries.right,
      boundaries.top,
      templateObj,
    ],
  );

  const positions = useMemo(() => {
    const relativePosition = {
      x:
        glassObj.extremePoints.left.point.x -
        templateObj.extremePoints.left.point.x,
      y:
        glassObj.extremePoints.top.point.y -
        templateObj.extremePoints.top.point.y,
    };
    return {
      top: fixValue(relativePosition.y),
      bottom: fixValue(
        templateObj.height - (relativePosition.y + glassObj.height),
      ),
      right: fixValue(
        templateObj.extremePoints.right.point.x -
          glassObj.extremePoints.right.point.x,
      ),
      left: fixValue(relativePosition.x),
    };
  }, [glassObj, templateObj, templatePosition]);

  const positioningMinLimit = useMemo(() => {
    return {
      top: extremeBoundaries.top,
      bottom: templateObj.height - extremeBoundaries.bottom,
      left: extremeBoundaries.left,
      right: templateObj.width - extremeBoundaries.right,
    };
  }, [extremeBoundaries, templateObj]);

  const positioningMaxLimit = useMemo(() => {
    return {
      top: fixValue(extremeBoundaries.bottom - glassObj.height),
      bottom: fixValue(
        templateObj.height - extremeBoundaries.top - glassObj.height,
      ),
      left: fixValue(extremeBoundaries.right - glassObj.width),
      right: fixValue(
        templateObj.width - extremeBoundaries.left - glassObj.width,
      ),
    };
  }, [extremeBoundaries, templateObj, glassObj]);

  const positioningDisabled = useMemo(() => {
    const hasProducts = mountedProducts.length > 0;
    const vertical = extremeGaps.top === 0 && extremeGaps.bottom === 0;
    const horizontal = extremeGaps.left === 0 && extremeGaps.right === 0;
    return {
      top: hasProducts || vertical || !glassObj.isRectangular,
      bottom: hasProducts || vertical || !glassObj.isRectangular,
      left: hasProducts || horizontal || !glassObj.isRectangular,
      right: hasProducts || horizontal || !glassObj.isRectangular,
    };
  }, [mountedProducts, extremeGaps, glassObj]);

  const onTopChange = useCallback(
    (value: string) => {
      onUpdatePosition({
        y: Number(value) - positions.top + selectedGlass.position.y,
      });
    },
    [onUpdatePosition, positions.top, selectedGlass.position.y],
  );

  const onLeftChange = useCallback(
    (value: string) => {
      onUpdatePosition({
        x: Number(value) - positions.left + selectedGlass.position.x,
      });
    },
    [onUpdatePosition, positions.left, selectedGlass.position.x],
  );

  const onBottomChange = useCallback(
    (value: string) => {
      onUpdatePosition({
        y: selectedGlass.position.y - (Number(value) - positions.bottom),
      });
    },
    [onUpdatePosition, positions.bottom, selectedGlass.position.y],
  );

  const onRightChange = useCallback(
    (value: string) => {
      onUpdatePosition({
        x: selectedGlass.position.x - (Number(value) - positions.right),
      });
    },
    [onUpdatePosition, positions.right, selectedGlass.position.x],
  );

  return (
    <Positioning
      positions={positions}
      onTopChange={onTopChange}
      onBottomChange={onBottomChange}
      onLeftChange={onLeftChange}
      onRightChange={onRightChange}
      minLimit={positioningMinLimit}
      maxLimit={positioningMaxLimit}
      disabled={positioningDisabled}
      title={t('propertyPanel.form.nichePosition')}
      step={0.01}
    />
  );
};

GlassPositioning.displayName = 'GlassPositioning';

export default GlassPositioning;
