import * as React from 'react';
import { Label, Text, Tag } from 'react-konva';
import Konva from 'konva';
import { selectTheme, useConfigStore } from '../../store/config';

export interface Props extends Konva.LabelConfig {
  value: number | string;
  unit?: string;
}

const SizeLabel: React.FC<Props> = React.memo(
  React.forwardRef<Konva.Label, Props>(
    ({ value, unit = 'mm', ...props }, ref) => {
      const theme = useConfigStore(selectTheme);

      return (
        <Label {...props} ref={ref}>
          <Tag fill={theme.colors.accentBlue} cornerRadius={4} />
          <Text
            text={`${value} ${unit}`}
            padding={4}
            fill={theme.colors.white}
            fontSize={14}
          />
        </Label>
      );
    },
  ),
);

SizeLabel.displayName = 'SizeLabel';

export default SizeLabel;
