import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  InitEmailChange,
  FinishEmailChange,
  VerifyEmailChangeToken,
} from '../../services/api/models/admin';
import { AxiosError } from 'axios';
import { adminApi } from '../../services/api/services';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/admin/email`;

export type State = {
  loading: boolean;
  initChange(
    command: InitEmailChange,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  finishChange(
    command: FinishEmailChange,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  verifyChange(
    command: VerifyEmailChangeToken,
    onError?: () => void,
  ): Promise<void>;
  error?: AxiosError;
};

const initChangeAction = (set: SetState<State>) => (
  command: InitEmailChange,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      await adminApi.initEmailChange(command);
    },
    onError,
    onSuccess,
  );

const verifyChangeAction = (set: SetState<State>) => (
  command: VerifyEmailChangeToken,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    await adminApi.verifyEmailChangeToken(command);
  }, onError);

const finishChangeAction = (set: SetState<State>) => (
  command: FinishEmailChange,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      await adminApi.finishEmailChange(command);
    },
    onError,
    onSuccess,
  );

const store = (set: SetState<State>) => ({
  loading: false,
  finishChange: finishChangeAction(set),
  initChange: initChangeAction(set),
  verifyChange: verifyChangeAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
