import * as React from 'react';

const SvgUndo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M19.977 12.393a4.849 4.849 0 00-4.893-4.235h-7.87l.55-.551a.941.941 0 10-1.33-1.331L4.276 8.434a.941.941 0 000 1.33l2.158 2.159a.941.941 0 101.33-1.331l-.55-.552h7.969a2.898 2.898 0 012.88 2.261 2.827 2.827 0 01-2.769 3.386H5.882a.941.941 0 00-.94.942.941.941 0 00.94.94h9.412a4.711 4.711 0 004.683-5.175z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgUndo = React.memo(SvgUndo);
export default MemoSvgUndo;
