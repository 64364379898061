import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import {
  Background,
  Container,
  ContentWrapper,
  ErrorMsg,
  FlexWrapper,
  Header,
  HeaderWrapper,
} from '../../styles';
import {
  selectClearError,
  useNavigationStore,
} from '../../../../store/navigation';

const Page500: React.FC = () => {
  const { t } = useTranslation('errors');
  const clearError = useNavigationStore(selectClearError);

  useEffect(() => {
    return () => clearError();
  }, [clearError]);

  return (
    <Background>
      <Container small>
        <FlexWrapper>
          <HeaderWrapper>
            <Header>500</Header>
          </HeaderWrapper>
          <ContentWrapper>
            <Typography variant={TypographyVariants.H1}>
              {t('500.header')}
            </Typography>
            <ErrorMsg variant={TypographyVariants.H4} paddingTop={16}>
              {t('500.subHeader')}
            </ErrorMsg>
          </ContentWrapper>
        </FlexWrapper>
      </Container>
    </Background>
  );
};

export default Page500;
