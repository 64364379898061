import common from './common.json';
import errors from './errors.json';
import components from './components.json';
import login from './login.json';
import navigation from './navigation.json';
import home from './home.json';
import faq from './faq.json';
import project from './project.json';
import users from './users.json';
import routes from './routes.json';
import orders from './orders.json';
import offers from './offers.json';
import catalog from './catalog.json';
import creator from './creator.json';
import clients from './clients.json';
import myProfile from './myProfile.json';
import parameters from './parameters.json';
import emailChange from './emailChange.json';
import templates from './templates.json';
import contractors from './contractors.json';

const dict = {
  common,
  errors,
  components,
  login,
  navigation,
  home,
  faq,
  project,
  users,
  routes,
  orders,
  offers,
  catalog,
  creator,
  clients,
  myProfile,
  parameters,
  emailChange,
  templates,
  contractors,
};

export default dict;
