import { AxiosInstance, AxiosResponse } from 'axios';
import { stringify } from 'qs';

import { Endpoints } from '../endpoints';
import { instance_v2 } from '../config';
import { UserStatus } from '../models/user';
import {
  CompanyMember,
  CompanyMemberSearch,
  EditCompanyMemberCommand,
} from '../models/companyMember';
import { Page, Pageable } from '../models/requests';
import {
  Company,
  UpdateCompanyBasicData,
  CreateCompanyCommand,
} from '../models/company';
import { UpdateAddressCommand } from '../models/address';
import { CompanySearch, CompanyStatus } from '../models/company';

export interface FilterCommand {
  userName: string;
  userStatus: UserStatus;
}

export interface CompanyMembersFilterRequest {
  command?: Partial<FilterCommand>;
  pageable?: Partial<Pageable>;
}

export interface CompaniesFilterRequest {
  pageable?: Partial<Pageable>;
  text?: string;
  status?: CompanyStatus;
  sort?: string;
}

class CompanyAdminApi {
  constructor(private http: AxiosInstance) {}

  public getCompanyById = (companyId: number) =>
    this.http.get<Company>(`${Endpoints.COMPANY_ADMIN}/${companyId}`);

  public createCompany = (command: CreateCompanyCommand) =>
    this.http.post(Endpoints.COMPANY_ADMIN, command);

  public editCompany = (id: number, command: UpdateCompanyBasicData) =>
    this.http.put<Company>(`${Endpoints.COMPANY_ADMIN}/${id}`, command);

  public updateCompanyMember = (
    companyId: number,
    companyMemberId: number,
    command: EditCompanyMemberCommand,
  ) =>
    this.http.put<CompanyMember>(
      `${Endpoints.COMPANY_ADMIN}/${companyId}/edit-user/${companyMemberId}`,
      command,
    );

  public updateAddresses = (id: number, command: UpdateAddressCommand) =>
    this.http.put<Company>(`${Endpoints.COMPANY_ADMIN}/${id}/address`, command);

  public filterCompanyMembers = (
    id: number,
    { command, pageable }: CompanyMembersFilterRequest = {},
  ) =>
    this.http.post<Page<CompanyMemberSearch>>(
      `${Endpoints.COMPANY_ADMIN}/${id}/users?${stringify(pageable)}`,
      command ?? {},
    );

  public getCompanyMembersById = (
    id: number,
    { command, pageable }: CompanyMembersFilterRequest = {},
  ) =>
    this.http
      .post<
        Page<
          Omit<CompanyMemberSearch, 'systemRole'> & {
            systemRole: 'company_owner' | 'designer';
          }
        >
      >(
        `${Endpoints.COMPANY_ADMIN}/${id}/users?${stringify(pageable)}`,
        command ?? {},
      )
      .then(
        (response): AxiosResponse<Page<CompanyMemberSearch>> => {
          if (!response) {
            return response;
          }

          return {
            ...response,
            data: {
              ...response?.data,
              content:
                response.data?.content?.map((item) => ({
                  ...item,
                  systemRole: item.systemRole.toLocaleUpperCase() as CompanyMemberSearch['systemRole'],
                })) || [],
            },
          };
        },
      );

  public filterAllCompanyMembers = ({
    command,
    pageable,
  }: CompanyMembersFilterRequest = {}) => {
    return this.http
      .post<
        Page<
          Omit<CompanyMemberSearch, 'systemRole'> & {
            systemRole: 'company_owner' | 'designer';
          }
        >
      >(
        `${Endpoints.COMPANY_ADMIN}/users?${stringify(pageable)}`,
        command ?? {},
      )
      .then(
        (response): AxiosResponse<Page<CompanyMemberSearch>> => {
          if (!response) {
            return response;
          }

          return {
            ...response,
            data: {
              ...response?.data,
              content:
                response.data?.content?.map((item) => ({
                  ...item,
                  systemRole: item.systemRole.toLocaleUpperCase() as CompanyMemberSearch['systemRole'],
                })) || [],
            },
          };
        },
      );
  };

  public filterCompanies = (command: CompaniesFilterRequest = {}) =>
    this.http.get<Page<CompanySearch>>(
      `${Endpoints.COMPANY_ADMIN}/filter?${stringify(command)}`,
    );
}

const companyAdminApi = new CompanyAdminApi(instance_v2);
export default companyAdminApi;
