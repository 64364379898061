import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled(layout.Row)`
  align-items: center;
  justify-content: space-between;
  > span {
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.dark};
  }
`;
