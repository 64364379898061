import * as React from 'react';
import Konva from 'konva';
import { Rect } from 'react-konva';
import { selectTheme, useConfigStore } from '../../store/config';

export interface Props extends Konva.RectConfig {
  isSelected?: boolean;
  handleSelect(evt: Konva.KonvaEventObject<MouseEvent | TouchEvent>): void;
  onContextMenu(evt: Konva.KonvaEventObject<PointerEvent>): void;
}

const Pipe: React.FC<Props> = React.memo(
  React.forwardRef<Konva.Rect, Props>(
    ({ height, width, isSelected, handleSelect, onContextMenu }, ref) => {
      const theme = useConfigStore(selectTheme);

      return (
        <Rect
          ref={ref}
          width={width}
          height={height}
          strokeWidth={0.4}
          stroke={isSelected ? theme.colors.accentBlue : theme.colors.black}
          fill={theme.colors.white}
          onMouseDown={handleSelect}
          onTouchStart={handleSelect}
          onContextMenu={onContextMenu}
        />
      );
    },
  ),
);

Pipe.displayName = 'Pipe';

export default Pipe;
