import styled from 'styled-components';
import { Typography } from '../../components/Typography';
import { layout } from '../../styles';

export const Background = styled(layout.Layout)`
  background: ${({ theme }) => theme.colors.grey3};
`;

export const Container = styled.div<{ small?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 536px;
  height: ${({ small }) => (small ? '184px' : '264px')};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
`;

export const FlexWrapper = styled(layout.Column)`
  height: 100%;
  position: relative;
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  top: -160px;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding-top: 72px;
  text-align: center;
`;

export const ButtonWrapper = styled(layout.Row)`
  padding-bottom: 32px;
  margin-left: 56px;
  > button:last-child {
    margin-left: 16px;
  }
`;

export const ErrorMsg = styled(Typography)<{ paddingTop?: number }>`
  display: block;
  color: ${({ theme }) => theme.colors.grey};
  white-space: pre-line;
  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}px`};
`;

export const Header = styled.h1`
  font-size: ${({ theme }) => theme.font.size.huge};
  color: ${({ theme }) => theme.colors.validation};
  text-align: center;
  font-weight: ${({ theme }) => theme.font.weight.bolder};
`;
