import { AxiosInstance } from 'axios';
import { Endpoints } from '../endpoints';
import {
  LoginCommand,
  LoginResult,
  MultiFactorLoginCommand,
  RefreshCommand,
} from '../models/auth';
import { CaslResponse } from '../models/permissions';
import axiosInstance from '../config';
import { User } from '../models/user';

class AuthApi {
  constructor(private http: AxiosInstance) {}

  public login = (command: LoginCommand) =>
    this.http.post<LoginResult>(Endpoints.AUTH, command);

  public multiFactorLogin = (command: MultiFactorLoginCommand) =>
    this.http.post<LoginResult>(`${Endpoints.AUTH}/mfa`, command);

  public refreshToken = (command: RefreshCommand) =>
    this.http.post<LoginResult>(`${Endpoints.AUTH}/refresh`, command);

  public logout = () => this.http.post(`${Endpoints.AUTH}/logout`);

  public permissions = () =>
    this.http.get<CaslResponse>(`${Endpoints.AUTH}/permissions`);

  public getUser = () => this.http.get<User>(`${Endpoints.AUTH}/me`);
}

const authApi = new AuthApi(axiosInstance);
export default authApi;
