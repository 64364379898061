import * as React from 'react';
import { Group } from 'react-konva';
import { DEFAULT_RATIO } from '../../store/config';
import { GlassChild } from '../../store/products';
import { scaleShape } from '../../utils/shapes';
import { Shape } from '../../../space';
import {
  selectData,
  selectProjectPosition,
  useProjectStore,
} from '../../store/project';
import YAxisDistance from './YAxisDistance';
import { Position } from '../../types';
import XAxisDistance from './XAxisDistance';
import { useMemo } from 'react';
import { scalePosition } from '../../../../utils/shape';

export interface Props {
  product: GlassChild;
  productPosition: Position;
  xAxis?: boolean;
  yAxis?: boolean;
}

export const OVERFLOW = 50;
export const ARROW_PADDING = 2;
export const LABEL_WIDTH = 75;
export const LABEL_HEIGHT = 22;

const X_AXIS_ANCHORS = [
  'top',
  'bottom',
  'topLeft',
  'topRight',
  'bottomLeft',
  'center',
];

const AxisDistance: React.FC<Props> = React.memo(
  ({ product, productPosition, xAxis = true, yAxis = true }) => {
    const templatePosition = useProjectStore(selectProjectPosition);
    const { dimensions: templateDimensions } = useProjectStore(selectData);

    const { parent, data, axisDistance: distance } = product;

    const scaledParent = useMemo(() => {
      const parentObj = new Shape({ corners: parent.corners }, parent.position);
      parentObj.scaleShape(DEFAULT_RATIO);
      return parentObj;
    }, [parent.corners, parent.position]);

    const scaledTemplate = useMemo(() => {
      const templateObj = new Shape(
        { corners: templateDimensions.corners },
        templatePosition,
      );
      templateObj.scaleShape(DEFAULT_RATIO);
      return templateObj;
    }, [templateDimensions.corners, templatePosition]);

    const scaledProduct = useMemo(() => scaleShape(product.data, 1), [
      product.data,
    ]);

    const leftPosition = useMemo(
      () =>
        scalePosition(data.position).x <
        scaledTemplate.extremePoints.right.point.x / 2,
      [data.position, scaledTemplate.extremePoints.right.point.x],
    );

    const topPosition = useMemo(
      () =>
        scalePosition(data.position).y <
        scaledTemplate.extremePoints.bottom.point.y / 2,
      [data.position, scaledTemplate.extremePoints.bottom.point.y],
    );

    const verticalEdgePoints = useMemo(
      () =>
        leftPosition
          ? scaledParent.leftEdge.points
          : scaledParent.rightEdge.points,
      [
        leftPosition,
        scaledParent.leftEdge.points,
        scaledParent.rightEdge.points,
      ],
    );

    const horizontalEdgePoints = useMemo(
      () =>
        topPosition
          ? scaledParent.topEdge.points
          : scaledParent.bottomEdge.points,
      [
        topPosition,
        scaledParent.topEdge.points,
        scaledParent.bottomEdge.points,
      ],
    );

    const verticalEdgeDifferencePoints = useMemo(() => {
      return (
        (verticalEdgePoints.top.x - verticalEdgePoints.bottom.x) *
        (distance.bottom / (distance.top + distance.bottom))
      );
    }, [
      distance.bottom,
      distance.top,
      verticalEdgePoints.bottom.x,
      verticalEdgePoints.top.x,
    ]);

    const horizontalEdgeDifferencePoints = useMemo(() => {
      return (
        (horizontalEdgePoints.left.y - horizontalEdgePoints.right.y) *
        (distance.right / (distance.left + distance.right))
      );
    }, [
      distance.left,
      distance.right,
      horizontalEdgePoints.left.y,
      horizontalEdgePoints.right.y,
    ]);

    return (
      <Group>
        {yAxis && (product.anchor === 'left' || product.anchor === 'right') && (
          <YAxisDistance
            leftPosition={leftPosition}
            topPosition={topPosition}
            mountedEdgePoints={verticalEdgePoints}
            distance={distance}
            scaledProduct={scaledProduct}
            productPosition={productPosition}
            verticalEdgeDifferencePoint={verticalEdgeDifferencePoints}
          />
        )}
        {xAxis && X_AXIS_ANCHORS.includes(product.anchor) && (
          <XAxisDistance
            topPosition={topPosition}
            leftPosition={leftPosition}
            mountedEdgePoints={horizontalEdgePoints}
            distance={distance}
            scaledProduct={scaledProduct}
            productPosition={productPosition}
            horizontalEdgeDifferencePoint={horizontalEdgeDifferencePoints}
          />
        )}
      </Group>
    );
  },
);

AxisDistance.displayName = 'AxisDistance';

export default AxisDistance;
