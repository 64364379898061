import * as React from 'react';

const SvgPositionSpacing = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="82"
      height="82"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 4L41 12"
        stroke="#272B5F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M41 70L41 78"
        stroke="#272B5F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 41H12"
        stroke="#272B5F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M70 41H78"
        stroke="#272B5F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect x="0.5" y="0.5" width="81" height="81" rx="3.5" stroke="#DBE7F4" />
      <rect
        x="16.5"
        y="16.5"
        width="49"
        height="49"
        rx="3.5"
        fill="#FAFBFF"
        stroke="#DBE7F4"
      />
    </svg>
  );
};

const MemoSvgPositionSpacing = React.memo(SvgPositionSpacing);
export default MemoSvgPositionSpacing;
