import Konva from 'konva';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Group, Line } from 'react-konva';
import {
  ANCHOR_POINTS,
  DisabledEdge,
  RectMove,
} from '../../../../services/api/models/transformer';
import { selectTheme, useConfigStore } from '../../store/config';
import { BoundariesCopy, Position } from '../../types';
import {
  calculateDistanceFromEdgeForGlassHandler,
  calculateDistanceFromEdgeForParentHandler,
  CollisionData,
  getAnchorPointsCopy,
  handleAnchorMoveCopy,
  handleIndentMove,
  scaleAnchorPoints,
} from '../../utils/transformer';
import TransformerAnchor from './TransformerAnchor';
import { ProjectDimensions } from '../../../../services/api/models/project';
import {
  mapCoordinatesToPoints,
  scaleCorners,
  scalePoints,
  scalePosition,
} from '../../../../utils/shape';
import { Shape } from '../../../space';
import { fixPosition } from '../../utils/fix';
import { IndentCorners } from '../../../../types';
import {
  DataCopy,
  getParentPolygon,
  isLShape,
  ShapeTypeCopy,
} from '../../utils/boundaries';
import every from 'lodash/every';
import { Glass } from '../../../../services/api/models/glass';
import { Vector2d } from 'konva/lib/types';
import {
  getCornersIntersectingGlasses,
  getCornersOutsideNiche,
  getGlassesWithCornersOutsideNiche,
  getUtmostPoints,
  removeCollisionForCorner,
  removeCollisionForGlass,
  removeCollisionForNiche,
} from '../../utils/resizeValidators';

interface ExtendedShapeTypeCopy extends ShapeTypeCopy {
  id: number;
}

export interface ExtendedDataCopy extends DataCopy {
  shape: ExtendedShapeTypeCopy;
  shapes: ExtendedShapeTypeCopy[];
}

export interface Props {
  groupRef: RefObject<Konva.Group | null>;
  shapeRef: RefObject<Konva.Line | null>;
  shapeId: number;
  adjustManually: boolean;
  dimensions: ProjectDimensions;
  isSelected: boolean;
  layerPosition: Konva.Vector2d;
  templateDimensions: ProjectDimensions;
  templatePosition: Position;
  projectGlasses: Glass[];
  position: Konva.Vector2d;
  disableEdge?: DisabledEdge;
  transformBounds?: BoundariesCopy;
  onTransform: (dim: ProjectDimensions, pos: Konva.Vector2d) => void;
  onTransformEnd: (dim: ProjectDimensions, pos: Konva.Vector2d) => void;
  isParentShape?: boolean;
  scale: number;
}

const MIN_DIM = 100;
const MIN_CORNER_ANGLE = 70;

const Transformer = ({
  groupRef,
  shapeRef,
  shapeId,
  adjustManually,
  dimensions,
  isSelected,
  layerPosition,
  templateDimensions,
  templatePosition,
  projectGlasses,
  position,
  disableEdge,
  transformBounds,
  onTransform,
  onTransformEnd,
  isParentShape = false,
  scale,
}: Props) => {
  const theme = useConfigStore(selectTheme);

  const [currentDim, setCurrentDim] = useState(dimensions);
  const [currentPosition, setCurrentPosition] = useState(position);

  const anchorsPoints = useMemo(() => {
    const shape = new Shape(currentDim, currentPosition);
    return scaleAnchorPoints(
      getAnchorPointsCopy(shape.dimensions, disableEdge),
    );
  }, [currentDim, currentPosition, disableEdge, layerPosition]);

  const points = useMemo(() => {
    const shape = new Shape(currentDim, currentPosition);
    return scalePoints(mapCoordinatesToPoints(shape.dimensions).flat());
  }, [currentDim, currentPosition]);

  const transformShape = useCallback(
    (
      move: RectMove,
      proceed = true,
    ):
      | { dimensions: ProjectDimensions; position: Konva.Vector2d }
      | undefined => {
      const shapeNode = shapeRef.current;
      const groupNode = groupRef.current;

      if (!shapeNode || !groupNode) {
        return { dimensions: currentDim, position };
      }

      const targetMove = fixPosition(move.vector, 8);

      if (!dimensions.indent) {
        const { corners, position } = handleAnchorMoveCopy(
          move.id,
          targetMove,
          currentDim.corners,
          currentPosition,
          adjustManually,
        );

        const points = mapCoordinatesToPoints({
          corners: scaleCorners(corners, false),
          indent: dimensions.indent,
        }).flat();

        if (proceed) {
          shapeNode.points(points);
          groupNode.position(scalePosition(position));
        }

        return {
          dimensions: { indent: undefined, corners },
          position,
        };
      } else if (dimensions.indent) {
        const corners = handleIndentMove(
          move.id,
          targetMove,
          dimensions.indent,
          currentDim.corners as IndentCorners,
          adjustManually,
        );

        const points = mapCoordinatesToPoints({
          corners: scaleCorners(corners, false),
          indent: dimensions.indent,
        }).flat();

        if (proceed) {
          shapeNode.points(points);
        }

        return {
          dimensions: {
            indent: dimensions.indent,
            corners: corners,
          },
          position: currentPosition,
        };
      }
    },
    [
      adjustManually,
      currentDim.corners,
      dimensions.indent,
      shapeRef,
      currentPosition,
    ],
  );

  const handleTransform = useCallback(
    (corners: ProjectDimensions['corners'], position: Konva.Vector2d) => {
      setCurrentDim({
        ...currentDim,
        corners,
      } as ProjectDimensions);
      setCurrentPosition(position);
    },
    [],
  );

  const handleAnchorDrag = (move: RectMove) => {
    const shape = transformShape({
      ...move,
      vector: scalePosition(move.vector, true),
    });

    if (!shape) return;
    const shapeObj = new Shape(shape.dimensions);

    handleTransform(shapeObj.corners, shape.position);

    if (dimensions.corners) {
      onTransform(
        {
          ...shapeObj.dimensions,
          corners: shapeObj.corners,
        } as ProjectDimensions,
        shape.position,
      );
    }
  };

  const handleAnchorDragEnd = (move: RectMove) => {
    const shape = transformShape({
      ...move,
      vector: scalePosition(move.vector, true),
    });

    if (!shape) return;
    const shapeObj = new Shape(shape.dimensions);

    handleTransform(shapeObj.corners, shape.position);

    if (dimensions.corners) {
      onTransformEnd(
        {
          ...shapeObj.dimensions,
          corners: shapeObj.corners,
        } as ProjectDimensions,
        shape.position,
      );
    }
  };

  const validateMove = useCallback(
    (id: ANCHOR_POINTS, vector: Konva.Vector2d) => {
      vector = scalePosition(
        {
          x: vector.x - layerPosition.x,
          y: vector.y - layerPosition.y,
        },
        true,
        scale,
      );

      const moveResult = transformShape(
        {
          id,
          vector,
        },
        false,
      );

      if (!moveResult) return false;

      const shapeObj = new Shape(moveResult.dimensions, moveResult.position);

      const cornersAnglesValid = every(shapeObj.cornerAngles, (angle) => {
        return Math.abs(90 - angle) <= MIN_CORNER_ANGLE;
      });

      return (
        shapeObj.width > MIN_DIM &&
        shapeObj.height > MIN_DIM &&
        !shapeObj.isPolygonSelfIntersecting() &&
        shapeObj.isEdgesGreaterThan(MIN_DIM) &&
        shapeObj.isConvex &&
        cornersAnglesValid
      );
    },
    [shapeRef.current, scale, currentPosition, currentDim, layerPosition],
  );

  const resolveCollision = (
    config: ExtendedDataCopy,
    finalVector: Vector2d,
    id: ANCHOR_POINTS,
    scaledVector: Vector2d,
  ) => {
    const cornersOutside = getCornersOutsideNiche(config);
    const cornersCollisions = getCornersIntersectingGlasses(config);

    if (cornersCollisions.length === 0 && cornersOutside.length === 0) {
      return finalVector;
    }

    let cornersOutsideLength: CollisionData[] = [];

    cornersOutside.forEach((corner) => {
      const length = calculateDistanceFromEdgeForParentHandler(corner, config);
      cornersOutsideLength.push({
        shapeId: config.shape.id,
        colliderId: 0,
        cornerName: corner,
        length: length,
      });
    });

    cornersCollisions.forEach(({ shapeId, colliderId, cornerName }) => {
      const length = calculateDistanceFromEdgeForGlassHandler(
        shapeId,
        colliderId,
        cornerName,
        config,
      );
      cornersOutsideLength.push({
        shapeId: shapeId,
        colliderId: colliderId,
        cornerName: cornerName,
        length: length,
      });
    });

    cornersOutsideLength = cornersOutsideLength
      .sort((a, b) => (a.length < b.length ? 1 : b.length < a.length ? -1 : 0))
      .filter((i) => i.length > 0);

    const cornerOutside = cornersOutsideLength[0];

    if (!cornerOutside) {
      return finalVector;
    }

    if (cornerOutside.colliderId === 0) {
      return removeCollisionForCorner(
        id,
        config,
        scaledVector,
        finalVector,
        cornerOutside.cornerName,
      );
    } else {
      return removeCollisionForGlass(
        id,
        config,
        scaledVector,
        finalVector,
        cornerOutside,
      );
    }
  };

  const validateAnchorMove = useCallback(
    (id: ANCHOR_POINTS, vector: Konva.Vector2d) => {
      let finalVector = vector;

      for (let i = 0; i < 10; i++) {
        const scaledVector = scalePosition(
          {
            x: finalVector.x - layerPosition.x,
            y: finalVector.y - layerPosition.y,
          },
          true,
          scale,
        );

        const moveResult = transformShape(
          {
            id,
            vector: scaledVector,
          },
          false,
        );

        if (!moveResult) {
          return finalVector;
        }

        const config: ExtendedDataCopy = {
          shape: {
            id: shapeId,
            corners: moveResult.dimensions.corners,
            position: moveResult.position,
          },
          shapes: projectGlasses.map(({ originCorners, position, id }) => ({
            id: id,
            corners: originCorners,
            position: position,
          })),
          parents: [
            {
              position: templatePosition,
              corners: templateDimensions.corners,
              indent: templateDimensions.indent,
            },
          ],
          scale: scale,
        };

        finalVector = resolveCollision(config, finalVector, id, scaledVector);
      }

      return finalVector;
    },
    [
      scale,
      layerPosition,
      transformShape,
      templatePosition,
      templateDimensions,
      projectGlasses,
    ],
  );

  const validateAnchorMoveForNiche = useCallback(
    (id: ANCHOR_POINTS, vector: Konva.Vector2d) => {
      let finalVector = vector;

      for (let i = 0; i < 10; i++) {
        const scaledVector = scalePosition(
          {
            x: finalVector.x - layerPosition.x,
            y: finalVector.y - layerPosition.y,
          },
          true,
          scale,
        );

        const moveResult = transformShape(
          {
            id,
            vector: scaledVector,
          },
          false,
        );

        if (!moveResult) {
          return finalVector;
        }

        const config: Omit<DataCopy, 'shape'> = {
          shapes: projectGlasses,
          parents: [
            {
              position: moveResult.position,
              corners: moveResult.dimensions.corners,
              indent: templateDimensions.indent,
            },
          ],
          scale: scale,
        };
        if (isLShape(config.parents[0])) {
          // TODO - validation for L-Shaped niche
          return finalVector;
        }
        const cornersOutside = getGlassesWithCornersOutsideNiche(config);
        if (cornersOutside.length === 0) {
          return finalVector;
        }
        const { parent } = getParentPolygon(config.parents[0]);
        const utomostPointsGlasses = getUtmostPoints(
          parent,
          id,
          cornersOutside,
          true,
        );
        const utomostPointsWithNiche = getUtmostPoints(
          parent,
          id,
          cornersOutside,
          false,
        );
        finalVector = removeCollisionForNiche(
          id,
          config,
          scaledVector,
          finalVector,
          utomostPointsGlasses,
          utomostPointsWithNiche,
          adjustManually,
        );
      }

      return finalVector;
    },
    [
      scale,
      layerPosition,
      transformShape,
      templatePosition,
      templateDimensions,
      projectGlasses,
      adjustManually,
    ],
  );

  useEffect(() => {
    setCurrentDim(dimensions);
  }, [dimensions]);

  useEffect(() => {
    setCurrentPosition(position);
  }, [position]);

  if (!shapeRef.current || !isSelected) {
    return null;
  }

  return (
    <Group>
      <Line
        points={points}
        stroke={theme.colors.accentBlue}
        strokeWidth={1}
        opacity={1}
        closed={true}
        listening={false}
      />
      {anchorsPoints.map((anchor) => (
        <TransformerAnchor
          key={anchor.id}
          id={anchor.id}
          isParentShape={isParentShape}
          position={anchor.pos}
          indent={dimensions.indent}
          transformBounds={transformBounds}
          validateMove={validateMove}
          validateAnchorMove={validateAnchorMove}
          validateAnchorMoveForNiche={validateAnchorMoveForNiche}
          onDragEnd={handleAnchorDragEnd}
          onDragMove={handleAnchorDrag}
        />
      ))}
    </Group>
  );
};

export default Transformer;
