import styled, { css } from 'styled-components';
import { layout } from '../../../../../../styles';

const disabled_container = css`
  pointer-events: none;
  opacity: 0.5;
`;

export const Container = styled(layout.Row)<{ disabled?: boolean }>`
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 4px;
  ${({ disabled }) => disabled && disabled_container}
`;

export const Item = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 40px;
  cursor: pointer;
  > svg {
    width: 24px;
    height: 24px;
  }
`;
