import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AxiosError } from 'axios';

import { Offer, UpdateOfferCommand } from '../../services/api/models/offer';
import { offerApi } from '../../services/api/services';
import { createAsyncAction } from '../middlewares/actions';
import {
  OfferEditRequest,
  OfferRecalculateRequest,
} from '../../services/api/services/offer';

export const STORE_NAME = `@store/offers/offer`;

export type State = {
  loading: boolean;
  fetch(
    id: number,
    onSuccess?: (offer: Offer) => void,
    onError?: () => void,
  ): Promise<void>;
  recalculate(
    offerId: number,
    payload: OfferRecalculateRequest,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  create(
    projectId: number,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  edit(
    offerId: number,
    command: UpdateOfferCommand,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  data?: Offer;
  error?: AxiosError;
};

const fetchAction = (set: SetState<State>) => (
  id: number,
  onSuccess?: (offer: Offer) => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    const { data } = await offerApi.getOffer(id);
    set({ data });
    onSuccess?.(data);
  }, onError);

const recalculateAction = (set: SetState<State>) => (
  offerId: number,
  payload: OfferRecalculateRequest,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      const { data } = await offerApi.recalculateOffer(offerId, payload);
      set({ data });
    },
    onError,
    onSuccess,
  );

const createAction = (set: SetState<State>) => (
  projectId: number,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      await offerApi.createOffer(projectId);
    },
    onError,
    onSuccess,
  );

const editAction = (set: SetState<State>) => (
  offerId: number,
  command: UpdateOfferCommand,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      await offerApi.editOffer(offerId, command);
    },
    onError,
    onSuccess,
  );

const store = (set: SetState<State>) => ({
  loading: false,
  fetch: fetchAction(set),
  recalculate: recalculateAction(set),
  create: createAction(set),
  edit: editAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
