import * as React from 'react';

const SvgOffers = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={141}
      height={127}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={18.537}
        width={114.848}
        height={114.848}
        rx={14}
        transform="rotate(4.644 18.537 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#offers_svg__filter0_d)">
        <rect
          x={12.792}
          y={14.213}
          width={117.259}
          height={92.386}
          rx={4}
          fill="#fff"
        />
        <rect
          x={20.609}
          y={26.294}
          width={27.005}
          height={27.005}
          rx={4}
          fill="#326DE3"
        />
        <rect
          x={52.589}
          y={31.269}
          width={65.381}
          height={4.264}
          rx={2.132}
          fill="#E3EDFF"
        />
        <rect
          x={30.559}
          y={62.612}
          width={12.792}
          height={4.264}
          rx={2.132}
          fill="#326DE3"
        />
        <rect
          x={23.452}
          y={76.115}
          width={19.898}
          height={4.264}
          rx={2.132}
          fill="#326DE3"
        />
        <rect
          x={20.609}
          y={89.618}
          width={22.741}
          height={4.264}
          rx={2.132}
          fill="#326DE3"
        />
        <rect
          x={52.589}
          y={44.772}
          width={42.64}
          height={4.264}
          rx={2.132}
          fill="#E3EDFF"
        />
        <rect
          x={48.325}
          y={62.612}
          width={73.909}
          height={4.264}
          rx={2.132}
          fill="#E3EDFF"
        />
        <rect
          x={48.325}
          y={76.115}
          width={39.797}
          height={4.264}
          rx={2.132}
          fill="#E3EDFF"
        />
        <rect
          x={48.325}
          y={89.618}
          width={51.878}
          height={4.264}
          rx={2.132}
          fill="#E3EDFF"
        />
      </g>
      <defs>
        <filter
          id="offers_svg__filter0_d"
          x={2.792}
          y={14.213}
          width={137.259}
          height={112.386}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgOffers = React.memo(SvgOffers);
export default MemoSvgOffers;
