import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Project, ProjectStatus } from '../../services/api/models/project';
import { AxiosError } from 'axios';
import {
  Command,
  FromTemplateCommand,
  RenameCommand,
} from '../../services/api/services/project';
import { projectApi } from '../../services/api/services';
import { createAsyncAction } from '../middlewares/actions';
import { creatorProject } from '../../modules/creator';
import { getProject } from '../../utils/projectDto';

const {
  setProject,
  update: updateProject,
} = creatorProject.projectStore.getState();

export const STORE_NAME = `@store/projects/project`;

export type State = {
  loading: boolean;
  fetch(id: number): Promise<void>;
  create(
    command: Command,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  update(
    id: number,
    command: Command,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  duplicate(id: number, onSuccess?: (project: Project) => void): Promise<void>;
  rename(
    id: number,
    command: RenameCommand,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  delete(
    id: number,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  fromTemplate(
    command: FromTemplateCommand,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  error?: AxiosError;
};

const fetchAction = (set: SetState<State>) => (id: number) =>
  createAsyncAction(set)(async () => {
    const { data: project } = await projectApi.getProject(id);
    setProject({ ...project, data: getProject(project.data) });
  });

const createProjectAction = (set: SetState<State>) => (
  command: Command,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    const { data: project } = await projectApi.createProject(command);
    setProject({ ...project, data: getProject(project.data) });
    onSuccess?.();
  }, onError);

const updateAction = (set: SetState<State>) => (
  id: number,
  command: Command,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    const { data: project } = await projectApi.updateProject(id, command);
    updateProject({ ...project, data: getProject(project.data) }, true);
    onSuccess?.();
  }, onError);

const duplicateAction = (set: SetState<State>) => (
  id: number,
  onSuccess?: (project: Project) => void,
) =>
  createAsyncAction(set)(async () => {
    const { data } = await projectApi.duplicateProject(id);
    onSuccess?.(data);
  });

const renameAction = (set: SetState<State>) => (
  id: number,
  command: RenameCommand,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    await projectApi.renameProject(id, command);
    onSuccess?.();
  }, onError);

const deleteAction = (set: SetState<State>) => (
  id: number,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    await projectApi.deleteProject(id);
    onSuccess?.();
  }, onError);

const fromTemplateAction = (set: SetState<State>) => (
  command: FromTemplateCommand,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    const { data: project } = await projectApi.createFromTemplate(command);

    setProject({
      ...project,
      data: getProject(project.data),
      projectStatus: ProjectStatus.DRAFT,
    });
    onSuccess?.();
  }, onError);

const store = (set: SetState<State>) => ({
  loading: false,
  fetch: fetchAction(set),
  create: createProjectAction(set),
  update: updateAction(set),
  duplicate: duplicateAction(set),
  rename: renameAction(set),
  delete: deleteAction(set),
  fromTemplate: fromTemplateAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
