import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Column } from '../../styles/layout';
import { Checkbox } from '../Checkbox';
import { Typography, TypographyVariants } from '../Typography';
import { Container, Section, SectionWrapper } from './styles';
import { OptionValue, PointerSwitch, Switch } from '../Switch';
import { Insert, InsertOption } from '../Insert';
import { useTranslation } from 'react-i18next';
import { UndoRedo } from '../UndoRedo';
import { Dropdown, DropdownOption } from '../Dropdown';
import { useToolbarConfig } from './hooks';
import { NicheItem } from '../InsertNiche';
import { Pointer, Projection, Scale } from '../../types';
import ToolbarButtons from './TooblarButtons';

export interface Props {
  onChangePointer(option: Pointer): void;
  onInsert(option: InsertOption | NicheItem): void;
  onUndo(): void;
  onRedo(): void;
  onSelectProjection(value: Projection): void;
  onSelectScale(value: Scale): void;
  onSave(): void;
  onOpenCreatingMenu(): void;
  onCreateOrder(): void;
  onCreateOffer(): void;
  onSaveTemplate?(): void;
  onToggleShowAngles(): void;
  onRevalidateProject(): void;
  showAngles: boolean;
  disableSelectProjection?: boolean;
  selectedProjection?: Projection;
  selectedScale?: Scale;
  selectedPointer?: Pointer;
  lastSaveDateString?: string;
  disableCreateOrder?: boolean;
  disableSave?: boolean;
  disableSaveTemplate?: boolean;
  disableUndo?: boolean;
  disableRedo?: boolean;
  disableCreationActions?: boolean;
  canSaveProject?: boolean;
  canSaveTemplate?: boolean;
  canCreateOrder?: boolean;
  shrink?: boolean;
  showCreatingMenu?: boolean;
  revalidateProject: boolean;
}

const Toolbar: React.FC<Props> = React.memo(
  ({
    onInsert,
    onChangePointer,
    onUndo,
    onRedo,
    onSelectProjection,
    onSelectScale,
    onSave,
    onCreateOrder,
    onCreateOffer,
    onOpenCreatingMenu,
    onSaveTemplate,
    onToggleShowAngles,
    onRevalidateProject,
    showAngles,
    revalidateProject,
    canSaveTemplate,
    shrink,
    disableUndo,
    disableRedo,
    disableCreateOrder,
    disableSave,
    disableSaveTemplate,
    showCreatingMenu,
    lastSaveDateString,
    disableSelectProjection,
    selectedPointer = Pointer.DEFAULT,
    selectedProjection = Projection.FRONT,
    selectedScale = 100,
    disableCreationActions,
  }) => {
    const { t } = useTranslation('project');
    const {
      insertNiche,
      insertGlass,
      insertGap,
      insertCategories,
      projectionOptions,
      scaleOptions,
      selectedDimensions,
    } = useToolbarConfig({
      disableCreationActions,
    });

    const projection = useMemo(() => [selectedProjection], [
      selectedProjection,
    ]);

    const handleSelectProjection = useCallback(
      ([value]: OptionValue[]) => {
        onSelectProjection(value as Projection);
      },
      [onSelectProjection],
    );

    const scale = useMemo(() => {
      const selected = scaleOptions.find(
        (option) => option.value === selectedScale,
      );
      if (selected) {
        return selected;
      } else {
        return {
          title: `${selectedScale}%`,
          value: selectedScale,
        };
      }
    }, [scaleOptions, selectedScale]);

    const handleSelectScale = useCallback(
      ({ value }: DropdownOption) => {
        onSelectScale(value as Scale);
      },
      [onSelectScale],
    );

    return (
      <Container data-testid="toolbar">
        <SectionWrapper>
          <Section>
            <PointerSwitch
              onChange={onChangePointer}
              active={selectedPointer}
            />
          </Section>
          <Section>
            {!shrink ? (
              <>
                <Insert
                  name="insert-niche"
                  categories={insertNiche}
                  onSelect={onInsert}
                />
                <Insert
                  name="insert-glass"
                  categories={insertGlass}
                  onSelect={onInsert}
                />
                <Insert
                  name="insert-gap"
                  categories={insertGap}
                  onSelect={onInsert}
                />
              </>
            ) : (
              <Insert
                name="insert"
                categories={insertCategories}
                onSelect={onInsert}
              />
            )}
          </Section>
          <Section>
            <UndoRedo
              onUndo={onUndo}
              onRedo={onRedo}
              disableUndo={disableUndo}
              disableRedo={disableRedo}
            />
          </Section>
          <Section>
            <Switch
              name="projection-switch"
              options={projectionOptions}
              selected={projection}
              onChange={handleSelectProjection}
              disabled={disableSelectProjection}
            />
          </Section>
          <Section>
            <Dropdown
              name="scale-dropdown"
              options={scaleOptions}
              selected={scale}
              onSelect={handleSelectScale}
            />
          </Section>
          <Section>
            <Checkbox
              name="show-gaps"
              onChange={onToggleShowAngles}
              value={String(showAngles)}
              label={t('toolbar.showGaps')}
              checked={showAngles}
            />
          </Section>
          {showAngles && (
            <Column>
              <Typography variant={TypographyVariants.H5}>
                {t('propertyPanel.form.width')}:{' '}
                {`${selectedDimensions.width} mm`}
              </Typography>
              <Typography variant={TypographyVariants.H5}>
                {t('propertyPanel.form.height')}:{' '}
                {`${selectedDimensions.height} mm`}
              </Typography>
            </Column>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <ToolbarButtons
            canSaveTemplate={canSaveTemplate}
            shrink={shrink}
            lastSaveDateString={lastSaveDateString}
            disableSave={disableSave}
            disableCreateOrder={disableCreateOrder}
            showCreatingMenu={showCreatingMenu}
            disableSaveTemplate={disableSaveTemplate}
            revalidateProject={revalidateProject}
            onRevalidateProject={onRevalidateProject}
            onSave={onSave}
            onOpenCreatingMenu={onOpenCreatingMenu}
            onOrderSelect={onCreateOrder}
            onOfferSelect={onCreateOffer}
            onSaveTemplate={onSaveTemplate}
          />
        </SectionWrapper>
      </Container>
    );
  },
);

Toolbar.displayName = 'Toolbar';

export default Toolbar;
