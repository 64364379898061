import { useState } from 'react';
import { i18next, Locales } from '../services/i18n';

export interface UseCurrentLang {
  availableLang: Locales[];
  currentLang: Locales;
  setCurrentLang(lang: Locales): void;
}

const availableLang: Locales[] = ['pl', 'it', 'enGB', 'ru'];

const useCurrentLang = (): UseCurrentLang => {
  const [currentLang, setCurrentLang] = useState<Locales>(
    (i18next.language as Locales) ?? 'pl',
  );

  return {
    currentLang,
    setCurrentLang,
    availableLang,
  };
};

export default useCurrentLang;
