import styled, { css } from 'styled-components';

const common_style = css`
  height: 4px;
  border-radius: 10px;
`;

export const Container = styled.div`
  ${common_style}
  background-color: ${({ theme }) => theme.colors.accentBlue2};
  width: 100%;
`;

export const Bar = styled.div<{ progress: number }>`
  ${common_style}
  width: ${({ progress }) => `${progress}%`};
  background-color: ${({ theme }) => theme.colors.accentBlue};
  transition: 0.3s linear width;
`;
