import Big from 'big.js';

import {
  BaseType,
  ProductModel,
  ProductOrientation,
  ProductSearch,
} from '../../../services/api/models/product';
import { DropTarget, GlassTarget } from '../store/drag';
import { fixBigPosition } from './fix';
import { ConnectionType } from '../../../services/api/models/connection';
import {
  GlassProperties,
  GlassPropertiesSearch,
} from '../../../services/api/models/glass';
import { Shape } from '../../space';
import { mapCoordinatesToEdges, pointsDistance } from '../../../utils/shape';
import { toLower } from 'lodash';

const getProductGlassPropertiesForGlass = (
  glassProperties: GlassPropertiesSearch[],
  target: DropTarget,
) => {
  if (target.type !== ConnectionType.GLASS) {
    throw new Error('Not a glass target!');
  }

  return glassProperties.find(
    (item) => item.thickness === (target as GlassTarget).glass.thickness,
  ) as GlassProperties;
};

const getNewWidthOrHeight = (
  productModel: ProductModel,
  target: GlassTarget,
): Partial<ProductModel> => {
  const edges = mapCoordinatesToEdges({ corners: target.glass.originCorners });
  const anchor = toLower(productModel.orientation) as
    | 'top'
    | 'bottom'
    | 'left'
    | 'right';

  switch (productModel.orientation) {
    case ProductOrientation.TOP:
    case ProductOrientation.BOTTOM:
      return {
        width: pointsDistance(...edges[anchor]),
      };
    case ProductOrientation.LEFT:
    case ProductOrientation.RIGHT:
      return { height: pointsDistance(...edges[anchor]) };
    default:
      return {};
  }
};

export const getPositionOnGlass = (
  productProps: Pick<
    ProductModel,
    'width' | 'height' | 'orientation' | 'position'
  >,
  target: GlassTarget,
  glassProperties: GlassPropertiesSearch[],
  ratio = 1,
): ProductModel['position'] => {
  const { glass } = target;
  const glassObj = new Shape({ corners: glass.corners }, glass.position);
  const glassObjOrigin = new Shape(
    { corners: glass.originCorners },
    glass.position,
  );

  glassObj.scaleShape(ratio);
  glassObjOrigin.scaleShape(ratio);

  const { orientation, height, width, position } = productProps;

  const { dimensionLinePadding: padding } = getProductGlassPropertiesForGlass(
    glassProperties,
    target,
  );

  switch (orientation) {
    case ProductOrientation.TOP:
      return fixBigPosition(
        {
          x: glassObjOrigin.corners['top-left'][0],
          y: new Big(glassObj.corners['top-left'][1]).minus(
            new Big(padding.top).times(ratio),
          ),
        },
        5,
      );
    case ProductOrientation.BOTTOM:
      return fixBigPosition(
        {
          x: glassObjOrigin.corners['bottom-left'][0],
          y: new Big(glassObj.corners['bottom-left'][1])
            .minus(new Big(height).times(ratio))
            .plus(new Big(padding.bottom).times(ratio)),
        },
        5,
      );
    case ProductOrientation.LEFT:
      return fixBigPosition(
        {
          x: new Big(glassObj.corners['top-left'][0]).minus(
            new Big(padding.left).times(ratio),
          ),
          y: glassObjOrigin.corners['top-left'][1],
        },
        5,
      );
    case ProductOrientation.RIGHT:
      return fixBigPosition(
        {
          x: new Big(glassObj.corners['top-right'][0])
            .minus(new Big(width).times(ratio))
            .plus(new Big(padding.right).times(ratio)),
          y: glassObjOrigin.corners['top-right'][1],
        },
        5,
      );
    default:
      return position;
  }
};

export const resizeBarToFitGlass = (
  product: ProductSearch,
  productModel: ProductModel,
  dropTarget: DropTarget,
  ratio: number,
): ProductModel => {
  if (
    product.baseType !== BaseType.GASKET &&
    product.baseType !== BaseType.BAR
  ) {
    return productModel;
  }

  if (dropTarget.type !== ConnectionType.GLASS) {
    return productModel;
  }

  return {
    ...productModel,
    ...getNewWidthOrHeight(productModel, dropTarget),
    position: getPositionOnGlass(
      productModel,
      dropTarget,
      product.glassProperties,
      ratio,
    ),
  };
};
