import * as React from 'react';
import { ButtonWrapper, Container } from './styles';
import { BottomModal } from '../../../../components/Modal';
import { useTranslation } from 'react-i18next';
import { Button, ButtonTypes } from '../../../../components/Button';

export interface Props {
  show?: boolean;
  disableConfirm: boolean;
  onConfirm(): void;
  onReject(): void;
}

const ConfirmGlassModal: React.FC<Props> = React.memo(
  ({ show, disableConfirm, onReject, onConfirm, ...props }) => {
    const { t } = useTranslation('project');
    return (
      <BottomModal
        show={show}
        title={t('notifications.blankGlassModal.title')}
        timeout={0}
        disableBackdrop>
        <Container {...props}>
          <ButtonWrapper>
            <Button
              testId="reject-button"
              title={t('notifications.blankGlassModal.reject')}
              type={ButtonTypes.SECONDARY}
              onClick={onReject}
            />
            <Button
              testId="confirm-button"
              title={t('notifications.blankGlassModal.confirm')}
              type={ButtonTypes.PRIMARY}
              onClick={onConfirm}
              disabled={disableConfirm}
            />
          </ButtonWrapper>
        </Container>
      </BottomModal>
    );
  },
);

ConfirmGlassModal.displayName = 'ConfirmGlassModal';

export default ConfirmGlassModal;
