import { IndentCorners, RectCorners } from '../../../types';
import { Client, ClientSearch } from './client';
import { Glass } from './glass';
import { Position } from './position';
import { ProductModel, ProductSearch } from './product';

export enum ProjectStatus {
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  CLEAR = 'CLEAR',
}

export enum ProjectModules {
  SHOWER_CABINS = 'SHOWER_CABINS',
}

export enum IndentPosition {
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

interface ProjectDimensionsIndent {
  indent: IndentPosition;
  corners: IndentCorners;
}

interface ProjectDimensionsRect {
  indent?: undefined;
  corners: RectCorners;
}

export type ProjectDimensions = ProjectDimensionsIndent | ProjectDimensionsRect;

export type ProjectData = {
  width: number;
  height: number;
  adjustManually: boolean;
  products: ProductModel[];
  glassSheets: Glass[];
  breakingPoint?: number;
  unsafe: boolean;
  scale?: number;
  position: Position;
  dimensions: ProjectDimensions;
};

export interface Project {
  id: number;
  data: ProjectData;
  name: string;
  projectStatus: ProjectStatus;
  productsUsed: Record<number, ProductSearch>;
  createdBy?: number;
  createdAt?: string;
  updatedAt?: string;
  client?: Client;
  description?: string; // for the template purpose only
}

export interface ProjectSearch {
  id: number;
  createdBy: number;
  creatorName: string;
  createdAt: string;
  updatedAt: string;
  projectStatus: ProjectStatus;
  snapshotUrl?: string;
  name?: string;
  client?: ClientSearch;
}

export interface ProjectTemplate
  extends Pick<Project, 'data' | 'productsUsed'> {
  id: number;
  name: string;
  description: string;
  version: string;
}

export type ProjectTemplateSearch = Pick<
  ProjectTemplate,
  'id' | 'name' | 'description' | 'version'
> & { createdAt: string; updatedAt: string; snapshotUrl?: string };

export interface BasicProject
  extends Pick<Required<Project>, 'id' | 'name' | 'client'> {}
