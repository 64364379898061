import * as React from 'react';
import { Container } from './styles';
import { useCreator } from '../../../../hooks';
import {
  AttachedSection,
  AttachedItemType,
} from '../../components/AttachedSection';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import { useDelete } from '../../../../../../hooks';

export interface Props {}

const AttachedTab: React.FC<Props> = () => {
  const { t } = useTranslation('project');
  const {
    projectProducts,
    projectGlasses,
    usedProductsData,
    selectShape,
    deselect,
  } = useCreator();

  const { deleteProduct, deleteGlass } = useDelete();

  const ids = useMemo(() => projectProducts.map((item) => item.productId), [
    projectProducts,
  ]);

  const usedProducts = useMemo(
    () =>
      Object.values(usedProductsData).filter((data) => ids.includes(data.id)),
    [ids, usedProductsData],
  );

  const products = useMemo(
    () => groupBy(projectProducts, (item) => item.productId),
    [projectProducts],
  );

  const categories = useMemo(
    () => groupBy(usedProducts, (data) => data.category),
    [usedProducts],
  );

  const onItemDelete = useCallback(
    (id: number, type: AttachedItemType) => {
      if (type === 'glass') {
        deleteGlass(id);
      }
      if (type === 'product') {
        const toDel = products[id];
        if (toDel) {
          toDel.forEach((item) => {
            deleteProduct(item.id);
          });
        }
      }
    },
    [deleteGlass, deleteProduct, products],
  );

  const onItemSelect = useCallback(
    (id: number, type: AttachedItemType) => {
      deselect();
      if (type === 'glass') {
        selectShape({ shapeId: id, type });
      }
      if (type === 'product') {
        const toSelect = products[id];
        if (toSelect) {
          toSelect.forEach((item) => {
            selectShape({
              shapeId: item.id,
              type: 'product',
              multi: toSelect.length > 1,
            });
          });
        }
      }
    },
    [deselect, products, selectShape],
  );

  return (
    <Container>
      {projectGlasses.length > 0 && (
        <AttachedSection
          type="glass"
          items={projectGlasses}
          title={t('productsPanel.attached.glass')}
          onItemDelete={onItemDelete}
          onItemSelect={onItemSelect}
        />
      )}
      {Object.entries(categories).map(([title, items]) => (
        <AttachedSection
          key={title}
          type="product"
          items={items}
          title={title}
          onItemDelete={onItemDelete}
          productShapes={products}
          onItemSelect={onItemSelect}
        />
      ))}
    </Container>
  );
};

export default AttachedTab;
