import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AxiosResponse } from 'axios';
import {
  FinishForcedResetPasswordCommand,
  FinishResetPasswordCommand,
  InitPasswordResetCommand,
  VerifyEmailResetPasswordCommand,
  UpdatePasswordCommand,
} from '../../services/api/models/user';
import { userApi } from '../../services/api/services';
import { sessionStore } from '../session';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/resetPw`;

export type State = {
  loading: boolean;
  init: boolean;
  initReset(command: InitPasswordResetCommand): Promise<void>;
  verifyReset(
    command: VerifyEmailResetPasswordCommand,
    onInvalid: () => void,
  ): Promise<void>;
  finishReset(
    command: FinishResetPasswordCommand,
    callback?: () => void,
  ): Promise<void>;
  finishForcedReset(command: FinishForcedResetPasswordCommand): Promise<void>;
  resetPasswordByAdmin(id: number, onSuccess?: () => void): Promise<void>;
  setEmail(email: string): void;
  updatePassword(
    command: UpdatePasswordCommand,
    onError?: () => void,
    onSuccess?: () => void,
  ): Promise<void>;
  email?: string;
  passwordChanged?: boolean;
  forcedPasswordChanged?: boolean;
  error?: AxiosResponse;
  clearError(): void;
};

const initResetAction = (set: SetState<State>) => async (
  command: InitPasswordResetCommand,
) => {
  try {
    set({ loading: true });
    await userApi.initPasswordReset(command);
    set({
      init: true,
      error: undefined,
    });
  } catch (e) {
    set({ error: e.response });
  } finally {
    set({ loading: false });
  }
};

const verifyResetAction = (set: SetState<State>) => async (
  command: VerifyEmailResetPasswordCommand,
  onInvalid: () => void,
) => {
  try {
    set({ loading: true });
    await userApi.verifyPasswordReset(command);
    set({
      error: undefined,
    });
  } catch (e) {
    onInvalid();
  } finally {
    set({ loading: false });
  }
};

const finishResetAction = (set: SetState<State>) => async (
  command: FinishResetPasswordCommand,
  callback?: () => void,
) => {
  try {
    set({ loading: true });
    await userApi.finishPasswordReset(command);
    set({
      passwordChanged: true,
      error: undefined,
    });
    callback?.();
  } catch (e) {
    set({ error: e.response });
  } finally {
    set({ loading: false });
  }
};

const finishForcedResetAction = (set: SetState<State>) => async (
  command: FinishForcedResetPasswordCommand,
) => {
  try {
    set({ loading: true });
    const { data } = await userApi.finishForcedPasswordReset(command);
    set({
      forcedPasswordChanged: true,
      error: undefined,
    });
    sessionStore.setState({
      ...data,
    });
    await sessionStore.getState().fetchPermissions();
  } catch (e) {
    set({ error: e.response });
  } finally {
    set({ loading: false });
  }
};

const resetPasswordByAdmin = (set: SetState<State>) => (
  id: number,
  onSuccess?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      await userApi.resetPasswordByAdmin(id);
    },
    undefined,
    onSuccess,
  );

const updatePasswordAction = (set: SetState<State>) => async (
  command: UpdatePasswordCommand,
  onError?: () => void,
  onSuccess?: () => void,
) => {
  set({ loading: true });
  try {
    const { data } = await userApi.updatePassword(command);
    sessionStore.setState({
      ...data,
    });
    onSuccess?.();
  } catch (e) {
    set({ error: e.response });
    onError?.();
  } finally {
    set({ loading: false });
  }
};

const clearErrorAction = (set: SetState<State>) => () => {
  set({ error: undefined });
};

const store = (set: SetState<State>) => ({
  loading: false,
  init: false,
  initReset: initResetAction(set),
  verifyReset: verifyResetAction(set),
  finishReset: finishResetAction(set),
  finishForcedReset: finishForcedResetAction(set),
  resetPasswordByAdmin: resetPasswordByAdmin(set),
  updatePassword: updatePasswordAction(set),
  setEmail: (email: string) => set({ email }),
  clearError: clearErrorAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
