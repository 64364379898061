import styled from 'styled-components';
import { layout } from '../../../../styles';

export const Container = styled(layout.Row)`
  width: inherit;
`;

export const Column = styled(layout.Column)`
  flex: 1;
  padding-right: 70px;
  > span {
    color: ${({ theme }) => theme.colors.dark};
  }
`;

export const ButtonWrapper = styled(layout.Row)`
  width: fit-content;
  margin-top: -12px;
  > button:first-of-type {
    margin-right: 8px;
  }
`;
