import { AxiosInstance } from 'axios';

import { Endpoints } from '../endpoints';
import {
  InitEmailChange,
  VerifyEmailChangeToken,
  FinishEmailChange,
  EmailChangeErrorResponse,
} from '../models/admin';
import axiosInstance from '../config';

class EmailAdminApi {
  constructor(private http: AxiosInstance) {}

  public initEmailChange = (command: InitEmailChange) =>
    this.http.post<EmailChangeErrorResponse>(
      `${Endpoints.EMAIL_ADMIN}/init`,
      command,
    );

  public verifyEmailChangeToken = (command: VerifyEmailChangeToken) =>
    this.http.post(`${Endpoints.EMAIL_ADMIN}/verify`, command);

  public finishEmailChange = (command: FinishEmailChange) =>
    this.http.post(`${Endpoints.EMAIL_ADMIN}/finish`, command);
}

const emailAdminApi = new EmailAdminApi(axiosInstance);
export default emailAdminApi;
