export enum Variants {
  BODY = 'BODY',
  PARAGRAPH = 'PARAGRAPH',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H4_BOLD = 'H4_BOLD',
  H5 = 'H5',
  H5_BOLD = 'H5_BOLD',
  LINK = 'LINK',
  LINK_BIG = 'LINK_BIG',
}
