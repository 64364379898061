import { AbilityContext, AppAbilityType } from '../services/casl';
import { useContext, useEffect, useState } from 'react';
import { AppAbility } from '../services/casl/abilities';

let rules: string;
const useAbility = (): AppAbilityType => {
  const abilityInstance = useContext(AbilityContext);
  const [ability, setAbility] = useState(abilityInstance);

  useEffect(() => {
    const unsubscribe = abilityInstance.on('update', (event) => {
      const stringify = JSON.stringify(event.rules);
      if (stringify !== rules) {
        rules = JSON.stringify(event.rules);
        setAbility(new AppAbility(event.rules));
      }
    });
    return () => unsubscribe();
  }, [abilityInstance]);

  return ability;
};

export default useAbility;
