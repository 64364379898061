import * as React from 'react';

const SvgLinkActive = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x="24"
        width="24"
        height="24"
        rx="4"
        transform="rotate(90 24 0)"
        fill={props.fill ?? '#DBE7F4'}
      />
      <path
        d="M15.5 13.9091L15.5 15.8182C15.5 16.236 15.4095 16.6498 15.2336 17.0358C15.0577 17.4218 14.7999 17.7726 14.4749 18.0681C14.1499 18.3635 13.764 18.5979 13.3394 18.7578C12.9148 18.9177 12.4596 19 12 19C11.5404 19 11.0852 18.9177 10.6606 18.7578C10.236 18.5979 9.85013 18.3635 9.52513 18.0681C9.20012 17.7726 8.94231 17.4218 8.76642 17.0358C8.59053 16.6498 8.5 16.236 8.5 15.8182L8.5 13.9091M8.5 10.0909L8.5 8.18182C8.5 7.76398 8.59053 7.35022 8.76642 6.96419C8.94231 6.57815 9.20012 6.22739 9.52513 5.93193C10.1815 5.33523 11.0717 5 12 5C12.9283 5 13.8185 5.33523 14.4749 5.93193C15.1313 6.52864 15.5 7.33795 15.5 8.18182L15.5 10.0909"
        stroke={props.stroke ?? '#272B5F'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 9.45459L12.0001 14.5455"
        stroke={props.stroke ?? '#272B5F'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const MemoSvgLinkActive = React.memo(SvgLinkActive);
export default MemoSvgLinkActive;
