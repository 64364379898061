import styled, { css } from 'styled-components';
import { layout } from '../../../../styles';

export const Container = styled(layout.Row)`
  flex: 1;
  align-items: flex-end;
  min-width: 0;
`;

const Tab = styled.div<{ active?: boolean }>`
  display: flex;
  flex: 0 1 auto;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 52px;
  padding: 0 16px;
  margin: 0 0 -1px -1px;
  border-style: solid;
  border-radius: 8px 8px 0 0;
  border-color: ${({ theme }) => theme.colors.accentBlue2};
`;

const active_home_tab = css`
  border-width: 1px 1px 0 1px;
  background-color: ${({ theme }) => theme.colors.grey3};
`;

export const HomeTab = styled(Tab)`
  border-width: 0;
  ${({ active }) => active && active_home_tab};
`;

const active_project_tab = css`
  overflow: visible;
  white-space: nowrap;
  border-width: 1px 1px 0 1px;
  cursor: default;
  max-width: unset;
  min-width: unset;
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const ProjectTab = styled(Tab)`
  border-width: 1px;
  cursor: pointer;
  min-width: 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey3};
  }
  ${({ active }) => active && active_project_tab};
`;

export const NameWrapper = styled(layout.Row)`
  align-items: center;
  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }
`;

export const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  > svg {
    width: 16px;
    height: 16px;
  }
  &:hover {
    > svg {
      path {
        fill: ${({ theme }) => theme.colors.accentBlue};
      }
    }
  }
`;

export const NameInput = styled.input`
  box-sizing: content-box;
  min-width: 10px;
  border: 0;
  outline: 0;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 14px;
  font-weight: 700;
  &::placeholder {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 14px;
    font-weight: 700;
  }
`;
