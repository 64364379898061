import * as React from 'react';
import { Props as CommonProps } from './types';
import {
  BoxWrapper,
  Container,
  InputWrapper,
  Label,
  ErrorMessage,
} from './styles';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useClickOutside } from '../../hooks';
import { Typography, TypographyVariants } from '../Typography';
import SelectBox, { Option } from './SelectBox';
import { useTranslation } from 'react-i18next';

export interface RenderProps {
  inputId: string;
  i18nPlaceholder: string;
  onSearch(e: ChangeEvent<HTMLInputElement>): void;
  toggleUnfold(state?: boolean): void;
  unfolded?: boolean;
}

export interface Props extends CommonProps {
  onSelect(options: Option[]): void;
  children(renderProps: RenderProps): React.ReactElement;
  selected?: Option | Option[];
  multi?: boolean;
}

const Composer: React.FC<Props> = React.memo(
  ({
    children: renderFn,
    name,
    label,
    unfoldOnTop,
    options,
    onSelect,
    placeholder,
    selected,
    multi,
    allowCustom,
    width,
    fullWidth,
    async,
    onAsyncSearch,
    errorMessage,
    invalid,
  }) => {
    const { t } = useTranslation();
    const elementRef = useRef<HTMLDivElement>(null);
    const [unfolded, setUnfolded] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState<string>();
    const [customOption, setCustomOption] = useState<Option>();

    const i18nPlaceholder = useMemo(
      () => placeholder ?? t('components:inputs.select'),
      [placeholder, t],
    );

    const inputId = useMemo(() => `${name}-select`, [name]);

    const toggleUnfold = useCallback(
      (state?: boolean) => setUnfolded(state ?? !unfolded),
      [unfolded],
    );

    const handleSelect = useCallback(
      (options: Option[]) => {
        !multi && setUnfolded(false);
        customOption && setCustomOption(undefined);
        onSelect(options);
      },
      [customOption, multi, onSelect],
    );

    const parsedSelection = useMemo<Option[] | undefined>(
      () => selected && (Array.isArray(selected) ? selected : [selected]),
      [selected],
    );

    const onClickOutside = useCallback(() => {
      customOption && setCustomOption(undefined);
      unfolded && setUnfolded(false);
    }, [customOption, unfolded]);

    const onSearch = useCallback(
      async (e: ChangeEvent<HTMLInputElement>) => {
        if (async && onAsyncSearch) {
          await onAsyncSearch(e.target.value);
        }
        setSearchPhrase(e.target.value);
        if (!unfolded) {
          toggleUnfold(true);
        }
      },
      [async, onAsyncSearch, toggleUnfold, unfolded],
    );

    const filtered = useMemo(
      () =>
        !async && typeof searchPhrase === 'string' && searchPhrase.length > 0
          ? options.filter((option) =>
              option.title.toLowerCase().match(searchPhrase.toLowerCase()),
            )
          : options,
      [async, options, searchPhrase],
    );

    useEffect(() => {
      if (
        searchPhrase &&
        options.every((option) => option.title !== searchPhrase)
      ) {
        setCustomOption({ title: searchPhrase, value: searchPhrase });
      } else {
        setCustomOption(undefined);
      }
    }, [options, searchPhrase]);

    useClickOutside({ elementRef, onClickOutside });

    return (
      <Container labeled={!!label}>
        {label && (
          <Typography variant={TypographyVariants.H5}>
            <Label htmlFor={inputId}>{label}</Label>
          </Typography>
        )}
        <InputWrapper
          data-testid="select-component"
          ref={elementRef}
          width={width}
          fullWidth={fullWidth}>
          {renderFn({
            i18nPlaceholder,
            toggleUnfold,
            inputId,
            unfolded,
            onSearch,
          })}
          {unfolded && (
            <BoxWrapper unfoldOnTop={unfoldOnTop}>
              <SelectBox
                options={filtered}
                onSelect={handleSelect}
                selected={parsedSelection}
                multi={multi}
                allowCustom={allowCustom}
                customOption={customOption}
                closeAction={toggleUnfold}
              />
            </BoxWrapper>
          )}
        </InputWrapper>
        {invalid && errorMessage && (
          <ErrorMessage>
            <Typography variant={TypographyVariants.H5}>
              {errorMessage}
            </Typography>
          </ErrorMessage>
        )}{' '}
      </Container>
    );
  },
);

Composer.displayName = 'Composer';

export default Composer;
