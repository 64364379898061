import * as React from 'react';

const SvgOrders = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={160}
      height={124}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={28.537}
        width={114.848}
        height={114.848}
        rx={14}
        transform="rotate(4.644 28.537 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#orders_svg__filter0_d)">
        <rect
          x={10}
          y={16.981}
          width={110.152}
          height={22.741}
          rx={4}
          fill="#fff"
        />
        <rect
          x={14.264}
          y={21.245}
          width={14.213}
          height={14.213}
          rx={4}
          fill="#326DE3"
        />
        <rect
          x={34.162}
          y={26.22}
          width={81.726}
          height={4.264}
          rx={2.132}
          fill="#E3EDFF"
        />
      </g>
      <g filter="url(#orders_svg__filter1_d)">
        <rect
          x={39.848}
          y={48.25}
          width={110.152}
          height={22.741}
          rx={4}
          fill="#fff"
        />
        <rect
          x={44.111}
          y={52.514}
          width={14.213}
          height={14.213}
          rx={4}
          fill="#326DE3"
        />
        <rect
          x={64.01}
          y={57.489}
          width={81.726}
          height={4.264}
          rx={2.132}
          fill="#E3EDFF"
        />
      </g>
      <g filter="url(#orders_svg__filter2_d)">
        <rect
          x={21.37}
          y={79.519}
          width={110.152}
          height={22.741}
          rx={4}
          fill="#fff"
        />
        <rect
          x={25.634}
          y={83.784}
          width={14.213}
          height={14.213}
          rx={4}
          fill="#326DE3"
        />
        <rect
          x={45.532}
          y={88.758}
          width={81.726}
          height={4.264}
          rx={2.132}
          fill="#E3EDFF"
        />
      </g>
      <defs>
        <filter
          id="orders_svg__filter0_d"
          x={0}
          y={16.981}
          width={130.152}
          height={42.741}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="orders_svg__filter1_d"
          x={29.848}
          y={48.25}
          width={130.152}
          height={42.741}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="orders_svg__filter2_d"
          x={11.37}
          y={79.519}
          width={130.152}
          height={42.741}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgOrders = React.memo(SvgOrders);
export default MemoSvgOrders;
