import * as React from 'react';
import { Container, Content } from './styles';
import { Navigation } from '../Navigation';
import { Modals } from '../Modals';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { layout } from '../../styles';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  selectSetActivePage,
  useNavigationStore,
} from '../../store/navigation';

export interface Props {
  box?: boolean;
  disableEvents?: boolean;
  breadcrumbs?: boolean;
  customRouteName?: string;
}

const Layout: React.FC<Props> = React.memo(
  ({ box, disableEvents, breadcrumbs, customRouteName, children }) => {
    const { pathname } = useLocation();
    const currentPathname = useRef<string>();
    const setActivePage = useNavigationStore(selectSetActivePage);

    useEffect(() => {
      if (currentPathname.current !== pathname) {
        setActivePage(pathname);
        currentPathname.current = pathname;
      }
    }, [pathname, setActivePage]);

    return (
      <Container disableEvents={disableEvents}>
        <Navigation />
        <Content box={box}>
          {box ? (
            <layout.DesktopBox>
              {breadcrumbs && <Breadcrumbs customRouteName={customRouteName} />}
              {children}
            </layout.DesktopBox>
          ) : (
            <>
              {breadcrumbs && <Breadcrumbs customRouteName={customRouteName} />}
              {children}
            </>
          )}
        </Content>
        <Modals />
      </Container>
    );
  },
);

export default Layout;
