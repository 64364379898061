import * as React from 'react';

const SvgEdit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M19.4 7.34L16.66 4.6A2 2 0 0014 4.53l-9 9a2 2 0 00-.57 1.21L4 18.91A1 1 0 005 20h.09l4.17-.38a2 2 0 001.21-.57l9-9a1.92 1.92 0 00-.07-2.71zM9.08 17.62l-3 .28.27-3L12 9.32l2.7 2.7-5.62 5.6zM16 10.68L13.32 8l1.95-2L18 8.73l-2 1.95z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgEdit = React.memo(SvgEdit);
export default MemoSvgEdit;
