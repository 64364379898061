import Konva from 'konva';
import React, { useRef, useState } from 'react';
import { Rect } from 'react-konva';
import { Position } from '../../../../services/api/models/position';
import {
  ANCHOR_POINTS,
  RectMove,
} from '../../../../services/api/models/transformer';
import { selectTheme, useConfigStore } from '../../store/config';
import { BoundariesCopy } from '../../types';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { fixPosition } from '../../utils/fix';

interface RectProps {
  id: ANCHOR_POINTS;
  isParentShape: boolean;
  position: Position;
  indent: ProjectDimensions['indent'];
  transformBounds?: Partial<BoundariesCopy>;
  validateMove: (id: ANCHOR_POINTS, move: Konva.Vector2d) => boolean;
  validateAnchorMove: (
    id: ANCHOR_POINTS,
    move: Konva.Vector2d,
  ) => Konva.Vector2d;
  validateAnchorMoveForNiche: (
    id: ANCHOR_POINTS,
    move: Konva.Vector2d,
  ) => Konva.Vector2d;
  onDragMove: (evt: RectMove) => void;
  onDragEnd: (evt: RectMove) => void;
}

const INDENT_ANCHORS = [
  ANCHOR_POINTS.CENTER_CENTER_LEFT,
  ANCHOR_POINTS.CENTER_CENTER_RIGHT,
  ANCHOR_POINTS.CENTER_CENTER_TOP,
  ANCHOR_POINTS.CENTER_CENTER_BOTTOM,
  ANCHOR_POINTS.CENTER_LEFT,
  ANCHOR_POINTS.CENTER_RIGHT,
  ANCHOR_POINTS.CENTER,
  ANCHOR_POINTS.TOP_RIGHT,
  ANCHOR_POINTS.BOTTOM_LEFT,
];

const TransformerAnchor = ({
  id,
  isParentShape,
  position,
  indent,
  transformBounds,
  validateMove,
  validateAnchorMove,
  validateAnchorMoveForNiche,
  onDragMove,
  onDragEnd,
}: RectProps) => {
  const rectRef = useRef<Konva.Rect>(null);

  const [prevCursor, setPrevCursor] = useState('');

  const theme = useConfigStore(selectTheme);

  const handleMove = (evt: Konva.KonvaEventObject<DragEvent>) => {
    onDragMove({ id, vector: evt.target.position() });
  };

  const handleMoveEnd = (evt: Konva.KonvaEventObject<DragEvent>) =>
    onDragEnd({ id, vector: evt.target.position() });

  const onDragBound = function (this: Konva.Node, pos: Konva.Vector2d) {
    let absoluteMove;
    const rectNode = rectRef.current;

    if (!rectNode) return pos;

    const initPos = fixPosition(rectNode.absolutePosition(), 8);

    switch (id) {
      case ANCHOR_POINTS.BOTTOM:
      case ANCHOR_POINTS.TOP:
      case ANCHOR_POINTS.CENTER_CENTER_LEFT:
      case ANCHOR_POINTS.CENTER_CENTER_RIGHT:
        absoluteMove = {
          x: initPos.x,
          y: pos.y,
        };
        break;
      case ANCHOR_POINTS.LEFT:
      case ANCHOR_POINTS.RIGHT:
      case ANCHOR_POINTS.CENTER_CENTER_TOP:
      case ANCHOR_POINTS.CENTER_CENTER_BOTTOM:
        absoluteMove = {
          x: pos.x,
          y: initPos.y,
        };
        break;
      default:
        absoluteMove = {
          x: pos.x,
          y: pos.y,
        };
        break;
    }

    if (isParentShape) {
      // niche
      absoluteMove = validateAnchorMoveForNiche(id, absoluteMove);
    } else {
      // glass
      absoluteMove = validateAnchorMove(id, absoluteMove);
    }

    if (!validateMove(id, absoluteMove)) {
      return initPos;
    }

    if (
      absoluteMove.x === undefined ||
      isNaN(absoluteMove.x) ||
      absoluteMove.y === undefined ||
      isNaN(absoluteMove.y)
    ) {
      return initPos;
    }

    return fixPosition(absoluteMove, 8);
  };

  const handleMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const container = e.target.getStage()?.container();

    if (container) {
      setPrevCursor(container.style.cursor);

      switch (id) {
        case ANCHOR_POINTS.BOTTOM:
        case ANCHOR_POINTS.TOP:
        case ANCHOR_POINTS.CENTER_CENTER_LEFT:
        case ANCHOR_POINTS.CENTER_CENTER_RIGHT:
          container.style.cursor = 'ns-resize';
          break;
        case ANCHOR_POINTS.LEFT:
        case ANCHOR_POINTS.RIGHT:
        case ANCHOR_POINTS.CENTER_CENTER_TOP:
        case ANCHOR_POINTS.CENTER_CENTER_BOTTOM:
          container.style.cursor = 'ew-resize';
          break;
        case ANCHOR_POINTS.BOTTOM_LEFT:
        case ANCHOR_POINTS.TOP_RIGHT:
          container.style.cursor = 'nesw-resize';
          break;
        case ANCHOR_POINTS.BOTTOM_RIGHT:
        case ANCHOR_POINTS.TOP_LEFT:
          container.style.cursor = 'nwse-resize';
          break;
      }
    }
  };

  const handleMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const container = e.target.getStage()?.container();

    if (container) {
      container.style.cursor = prevCursor;
      setPrevCursor('');
    }
  };

  return (
    <Rect
      ref={rectRef}
      key={id}
      x={position.x}
      y={position.y}
      offsetX={5}
      offsetY={5}
      width={10}
      height={10}
      stroke={theme.colors.accentBlue}
      strokeWidth={1}
      fill={theme.colors.white}
      onDragMove={handleMove}
      onDragEnd={handleMoveEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      dragBoundFunc={onDragBound}
      draggable
    />
  );
};

export default TransformerAnchor;
