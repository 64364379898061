import * as React from 'react';
import { IconSwitchContainer as Container, IconOption } from './styles';
import { SwitchOption, Props as SwitchProps, OptionValue } from './Switch';
import { useCallback, useMemo } from 'react';
import { composeSelected } from './utils';
import { Tooltip } from '../Tooltip';

export interface IconSwitchOption extends SwitchOption {
  tooltip: string;
  Icon: (active: boolean) => React.ReactNode;
}

export interface Props extends SwitchProps {
  options: IconSwitchOption[];
  disabledOptions?: OptionValue[];
}

const IconSwitch: React.FC<Props> = React.memo(
  ({ options, selected, onChange, multi, disabledOptions = [] }) => {
    const handleChange = useCallback(
      (value: OptionValue) => {
        const newSelected = composeSelected(value, selected, multi);
        if (newSelected !== selected && onChange) {
          onChange(newSelected);
        }
      },
      [multi, onChange, selected],
    );

    const Options = useMemo(
      () =>
        options.map((option, index) => (
          <IconOption
            key={`${option.value}+${index}`}
            aria-label={option.title}
            role="option"
            active={selected.includes(option.value)}
            disabled={disabledOptions.includes(option.value)}
            onClick={() => handleChange(option.value)}>
            <Tooltip text={option.tooltip} delay={1000}>
              {option.Icon(selected.includes(option.value))}
            </Tooltip>
          </IconOption>
        )),
      [disabledOptions, handleChange, options, selected],
    );

    return <Container role="listbox">{Options}</Container>;
  },
);

IconSwitch.displayName = 'IconSwitch';

export default IconSwitch;
