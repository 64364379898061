import * as React from 'react';
import { ButtonsWrapper, ErrorColumn } from './styles';
import { BottomModal } from '../../../../components/Modal';
import { Button, ButtonTypes } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../../services/api/models/project';
import { ProductsErrors } from '../../../../pages/Project/hooks/useRevalidateProject';
import { Container } from '../SavingModal/styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import { Column } from '../../../../styles/layout';

export interface Props {
  show: boolean;
  errors: ProductsErrors[];
  onKeepEditing(): void;
  onSaveAnyway(): void;
}

const ValidateProjectModal: React.FC<Props> = React.memo(
  ({ show, errors, onKeepEditing, onSaveAnyway }) => {
    const { t } = useTranslation('project');

    return (
      <BottomModal
        show={show}
        title={t('modals.validateProject.title')}
        timeout={0}>
        <Container>
          {errors.map((item, index) => (
            <ErrorColumn key={index}>
              <Typography variant={TypographyVariants.H4_BOLD}>
                {item.code}
              </Typography>
              <Column>
                {item.errors.map((error, index) => (
                  <Typography key={index} variant={TypographyVariants.H5}>
                    {error}
                  </Typography>
                ))}
              </Column>
            </ErrorColumn>
          ))}
        </Container>
        <ButtonsWrapper>
          <Button
            testId="save-anyway-button"
            htmlButtonType="submit"
            type={ButtonTypes.SECONDARY}
            title={t('modals.validateProject.saveAnyway')}
            onClick={onSaveAnyway}
          />
          <Button
            testId="keep-editing-button"
            title={t('modals.validateProject.keepEditing')}
            onClick={onKeepEditing}
            noMinWidth
          />
        </ButtonsWrapper>
      </BottomModal>
    );
  },
);

ValidateProjectModal.displayName = 'ValidateProjectModal';

export default ValidateProjectModal;
