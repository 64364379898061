import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Container, Trigger } from './styles';
import {
  Catalog,
  Clients,
  Home,
  Menu as Burger,
  Offers,
  Orders,
  Params,
  Project as ProjectIcon,
  Users,
} from '../../../../components/icons';
import MainList from './MainList';
import {
  selectActivePage,
  selectCloseMainMenu,
  selectOpenMainMenu,
  selectShowMainMenu,
  useNavigationStore,
} from '../../../../store/navigation';
import { useTranslation } from 'react-i18next';
import { MainItemType } from './MainItem';
import { MinorItemType } from './MinorItem';
import { useHistory } from 'react-router-dom';
import { DashboardRoutes, RootRoutes } from '../../../../router/routes';
import { HotKeys } from '../../../../utils/hotkeys';
import { Project } from '../../../../services/api/models/project';
import useSave from '../../../../pages/Project/hooks/useSave';
import { useOpenProject } from '../../../../pages/Project/hooks';
import {
  useAbility,
  UseEditActions as EditActions,
  useInsert,
} from '../../../../hooks';
import { Gap } from '../../../../types';
import { Action, Subjects } from '../../../../services/api/models/permissions';

export interface Props {
  projectSaved: boolean;
  hasTemplate: boolean;
  editActions: EditActions;
  project?: Project;
}

const Menu: React.FC<Props> = React.memo(
  ({ project, projectSaved, hasTemplate, editActions }) => {
    const { t } = useTranslation('navigation');
    const { push } = useHistory();
    const showMenu = useNavigationStore(selectShowMainMenu);
    const openMenu = useNavigationStore(selectOpenMainMenu);
    const closeMenu = useNavigationStore(selectCloseMainMenu);
    const activePage = useNavigationStore(selectActivePage);

    const { openBlank } = useOpenProject();

    const [isGlassBeingCreated, setIsGlassBeingCreated] = useState(false);
    const onGlassCreationStart = useCallback(() => {
      setIsGlassBeingCreated(true);
    }, []);
    const onGlassCreationComplete = useCallback(() => {
      setIsGlassBeingCreated(false);
    }, []);

    const { insertNiche, insertGlass, insertGap } = useInsert({
      onGlassCreationStart,
      onGlassCreationComplete,
    });

    const ability = useAbility();

    const canEditTemplate = useMemo(
      () => ability.can(Action.EDIT, Subjects.PROJECT_TEMPLATE_EDIT),
      [ability],
    );

    const openBlankProject = useCallback(() => openBlank(canEditTemplate), [
      canEditTemplate,
      openBlank,
    ]);

    const onClick = useCallback(
      (action: () => void) => () => {
        closeMenu();
        action();
      },
      [closeMenu],
    );

    const isProjectPage =
      activePage === RootRoutes.PROJECT || activePage === RootRoutes.TEMPLATE;

    const disableItem = !isProjectPage || !project || !hasTemplate;

    const { save, saveProjectTemplate } = useSave(project);

    const fileItems = useMemo<MainItemType[]>(
      () => [
        {
          title: canEditTemplate
            ? t('submenu.file.newTemplate')
            : t('submenu.file.new'),
          level: 1,
          onClick: onClick(openBlankProject),
        },
        {
          title: t('submenu.file.save'),
          level: 1,
          label: HotKeys.SAVE,
          onClick: canEditTemplate
            ? onClick(saveProjectTemplate)
            : onClick(save),
          disabled: disableItem || projectSaved,
        },
        {
          title: t('submenu.file.saveAs'),
          level: 1,
          label: HotKeys.SAVE_AS,
          onClick: onClick(() =>
            canEditTemplate ? saveProjectTemplate('saveAs') : save('saveAs'),
          ),
          disabled: disableItem,
        },
      ],
      [
        canEditTemplate,
        t,
        onClick,
        openBlankProject,
        saveProjectTemplate,
        save,
        disableItem,
        projectSaved,
      ],
    );

    const editItems = useMemo<MainItemType[]>(
      () => [
        {
          title: t('submenu.edit.undo'),
          level: 1,
          label: HotKeys.UNDO,
          onClick: editActions.undo,
          disabled: editActions.disabled.undo,
        },
        {
          title: t('submenu.edit.redo'),
          level: 1,
          label: HotKeys.REDO,
          onClick: editActions.redo,
          disabled: editActions.disabled.redo,
        },
        {
          title: t('submenu.edit.copy'),
          level: 1,
          label: HotKeys.COPY,
          onClick: editActions.copy,
          disabled: editActions.disabled.copy,
        },
        {
          title: t('submenu.edit.cut'),
          level: 1,
          label: HotKeys.CUT,
          onClick: editActions.cut,
          disabled: editActions.disabled.cut,
        },
        {
          title: t('submenu.edit.paste'),
          level: 1,
          label: HotKeys.PASTE,
          onClick: editActions.paste,
          disabled: editActions.disabled.paste,
        },
        {
          title: t('submenu.edit.delete'),
          level: 1,
          label: HotKeys.DELETE,
          onClick: editActions.delete,
          disabled: editActions.disabled.delete,
        },
      ],
      [editActions, t],
    );

    const nicheItems = useMemo<MainItemType[]>(
      () => [
        {
          title: t('submenu.niche.square'),
          level: 2,
          onClick: onClick(() => insertNiche('square')),
        },
        {
          title: t('submenu.niche.rectangle'),
          level: 2,
          onClick: onClick(() => insertNiche('rectangle')),
        },
        {
          title: t('submenu.niche.LShape'),
          level: 2,
          onClick: onClick(() => insertNiche('lShape')),
        },
        {
          title: t('submenu.niche.LShapeRevert'),
          level: 2,
          onClick: onClick(() => insertNiche('lShapeRevert')),
        },
      ],
      [insertNiche, onClick, t],
    );

    const glassItems = useMemo<MainItemType[]>(
      () => [
        {
          title: t('submenu.glass', { thickness: 6 }),
          level: 2,
          onClick: onClick(() => insertGlass(6)),
        },
        {
          title: t('submenu.glass', { thickness: 8 }),
          level: 2,
          onClick: onClick(() => insertGlass(8)),
        },
        {
          title: t('submenu.glass', { thickness: 10 }),
          level: 2,
          onClick: onClick(() => insertGlass(10)),
        },
        {
          title: t('submenu.glass', { thickness: 12 }),
          level: 2,
          onClick: onClick(() => insertGlass(12)),
        },
      ],
      [insertGlass, onClick, t],
    );

    const gapItems = useMemo<MainItemType[]>(
      () => [
        {
          title: t('submenu.gap', { width: 2 }),
          level: 2,
          onClick: onClick(() => insertGap(Gap.G2)),
        },
        {
          title: t('submenu.gap', { width: 3 }),
          level: 2,
          onClick: onClick(() => insertGap(Gap.G3)),
        },
        {
          title: t('submenu.gap', { width: 4 }),
          level: 2,
          onClick: onClick(() => insertGap(Gap.G4)),
        },
        {
          title: t('submenu.gap', { width: 5 }),
          level: 2,
          onClick: onClick(() => insertGap(Gap.G5)),
        },
      ],
      [insertGap, onClick, t],
    );

    const addItems = useMemo<MainItemType[]>(
      () => [
        {
          title: t('submenu.add.niche'),
          level: 1,
          descendants: nicheItems,
          disabled: hasTemplate || isGlassBeingCreated,
        },
        {
          title: t('submenu.add.glass'),
          level: 1,
          descendants: glassItems,
          disabled: !hasTemplate || isGlassBeingCreated,
        },
        {
          title: t('submenu.add.gap'),
          level: 1,
          descendants: gapItems,
          disabled:
            !hasTemplate ||
            !project?.data.glassSheets.length ||
            isGlassBeingCreated,
        },
      ],
      [
        gapItems,
        glassItems,
        hasTemplate,
        nicheItems,
        project?.data.glassSheets.length,
        t,
        isGlassBeingCreated,
      ],
    );

    const mainItems = useMemo<MainItemType[]>(
      () => [
        {
          title: t('menu.main.file'),
          level: 0,
          descendants: fileItems,
        },
        {
          title: t('menu.main.edit'),
          level: 0,
          descendants: editItems,
          disabled: disableItem,
        },
        {
          title: t('menu.main.add'),
          level: 0,
          descendants: addItems,
          disabled: !isProjectPage,
        },
      ],
      [addItems, disableItem, editItems, fileItems, isProjectPage, t],
    );

    const minorItems = useMemo<MinorItemType[]>(
      () => [
        {
          title: t('menu.pages.home'),
          Icon: <Home />,
          route: RootRoutes.HOME,
          onClick: onClick(() => push(RootRoutes.HOME)),
          hide:
            ability.cannot(Action.READ, Subjects.PROJECT_READ) &&
            ability.cannot(Action.READ, Subjects.PROJECT_EDIT) &&
            ability.cannot(Action.READ, Subjects.PROJECT_TEMPLATE_EDIT),
        },
        {
          title: t('menu.pages.projects'),
          Icon: <ProjectIcon />,
          route: RootRoutes.PROJECTS,
          onClick: onClick(() => push(DashboardRoutes.SAVED)),
          hide:
            ability.can(Action.EDIT, Subjects.PROJECT_TEMPLATE_EDIT) ||
            (ability.cannot(Action.READ, Subjects.PROJECT_READ) &&
              ability.cannot(Action.READ, Subjects.PROJECT_EDIT)),
        },
        {
          title: t('menu.pages.catalog'),
          Icon: <Catalog />,
          route: RootRoutes.CATALOG,
          onClick: onClick(() => push(RootRoutes.CATALOG)),
          hide:
            ability.cannot(Action.READ, Subjects.PRODUCT_READ) &&
            ability.cannot(Action.READ, Subjects.PRODUCT_EDIT) &&
            ability.cannot(Action.READ, Subjects.PRODUCT_ADMIN_READ) &&
            ability.cannot(Action.READ, Subjects.PRODUCT_ADMIN_EDIT),
        },
        {
          title: t('menu.pages.clients'),
          Icon: <Clients />,
          route: RootRoutes.CLIENTS,
          onClick: onClick(() => push(RootRoutes.CLIENTS)),
          hide:
            ability.cannot(Action.READ, Subjects.CLIENT_READ) &&
            ability.cannot(Action.READ, Subjects.CLIENT_EDIT),
        },
        {
          title: t('menu.pages.offers'),
          Icon: <Offers />,
          route: RootRoutes.OFFERS,
          onClick: onClick(() => push(RootRoutes.OFFERS)),
          hide:
            ability.cannot(Action.READ, Subjects.OFFER_READ) &&
            ability.cannot(Action.READ, Subjects.OFFER_EDIT),
        },
        {
          title: t('menu.pages.orders'),
          Icon: <Orders />,
          route: RootRoutes.ORDERS,
          onClick: onClick(() => push(RootRoutes.ORDERS)),
          hide:
            ability.cannot(Action.READ, Subjects.ORDER_READ) &&
            ability.cannot(Action.READ, Subjects.ORDER_EDIT) &&
            ability.cannot(Action.READ, Subjects.ORDER_ADMIN_READ) &&
            ability.cannot(Action.READ, Subjects.ORDER_ADMIN_EDIT),
        },
        {
          title: t('menu.pages.templates'),
          Icon: <ProjectIcon />,
          route: RootRoutes.TEMPLATES,
          onClick: onClick(() => push(RootRoutes.TEMPLATES)),
          hide: ability.cannot(Action.READ, Subjects.PROJECT_TEMPLATE_EDIT),
        },
        {
          title: t('menu.pages.contractors'),
          Icon: <Clients />,
          route: RootRoutes.CONTRACTORS,
          onClick: onClick(() => push(RootRoutes.CONTRACTORS)),
          hide: ability.cannot(Action.READ, Subjects.COMPANY_ADMIN_EDIT),
        },
        {
          title: t('menu.pages.users'),
          Icon: <Users />,
          route: RootRoutes.USERS,
          onClick: onClick(() => push(RootRoutes.USERS)),
          hide:
            ability.cannot(Action.READ, Subjects.USER_READ) &&
            ability.cannot(Action.READ, Subjects.USER_EDIT) &&
            ability.cannot(Action.READ, Subjects.ADMIN_USER_EDIT),
        },
        {
          title: t('menu.pages.params'),
          Icon: <Params />,
          route: RootRoutes.PARAMS,
          onClick: onClick(() => push(RootRoutes.PARAMS)),
          hide:
            ability.cannot(Action.READ, Subjects.PARAMETERS_READ) &&
            ability.cannot(Action.READ, Subjects.PARAMETERS_EDIT),
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [ability.rules, onClick, push, t],
    );

    return (
      <Container>
        <Trigger role="button" onClick={openMenu} disabled={showMenu}>
          <Burger />
        </Trigger>
        {showMenu && (
          <MainList
            mainItems={mainItems}
            minorItems={minorItems}
            activeMinor={activePage}
            onClose={closeMenu}
          />
        )}
      </Container>
    );
  },
);

Menu.displayName = 'Menu';

export default Menu;
