import * as React from 'react';
import { useMemo } from 'react';
import { DropZone } from '../DropZone';
import {
  BaseType,
  ProductSearch,
} from '../../../../services/api/models/product';
import { ProductTarget } from '../../store/drag';
import { ConnectionType } from '../../../../services/api/models/connection';
import { Position } from '../../types';
import { RectCorners } from '../../../../types';

const OFFSET = 20;

export interface Props {
  product: ProductSearch;
  dragData: ProductSearch;
  position: Position;
  width: number;
  height: number;
  iid: string;
}

const ProductDropZone: React.FC<Props> = React.memo(
  ({ product, dragData, position, width, height, iid }) => {
    const dropTarget = useMemo<ProductTarget>(
      () => ({
        type: ConnectionType.PRODUCT,
        targetId: iid,
        anchor: 'product',
        product,
      }),
      [iid, product],
    );

    const offsetPosition = useMemo<Position>(
      () => ({
        x: position.x - OFFSET / 2,
        y: position.y - OFFSET / 2,
      }),
      [position.x, position.y],
    );

    const validDrop = useMemo(() => {
      switch (product.baseType) {
        case BaseType.CONNECTOR_WALL:
        case BaseType.CONNECTOR_GLASS: {
          return dragData.baseType === BaseType.PIPE;
        }
        default: {
          return false;
        }
      }
    }, [dragData.baseType, product.baseType]);

    const corners = useMemo(
      (): RectCorners => ({
        'top-left': [offsetPosition.x, offsetPosition.y],
        'top-right': [offsetPosition.x + width + OFFSET, offsetPosition.y],
        'bottom-left': [offsetPosition.x, offsetPosition.y + height + OFFSET],
        'bottom-right': [
          offsetPosition.x + width + OFFSET,
          offsetPosition.y + height + OFFSET,
        ],
      }),
      [height, offsetPosition.x, offsetPosition.y, width],
    );

    if (validDrop) {
      return (
        <DropZone anchor="product" corners={corners} dropTarget={dropTarget} />
      );
    }
    return null;
  },
);

ProductDropZone.displayName = 'ProductDropZone';

export default ProductDropZone;
