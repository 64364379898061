import * as React from 'react';
import { Container, Header, Results } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { ProductSearch } from '../../../../../../services/api/models/product';
import { useTranslation } from 'react-i18next';
import { CatalogItem } from '../CatalogItem';
import { EmptyResults } from '../EmptyResults';

export interface Props {
  results: ProductSearch[];
  onSelect(product: ProductSearch): void;
  onDragStart(
    e: React.DragEvent,
    data: ProductSearch,
    dataId?: string | number,
  ): void;
  onDrag(e: React.DragEvent): void;
  onDragEnd(e: React.DragEvent): void;
  dragging?: boolean;
  dragId?: string | number;
  selected?: ProductSearch;
}

const SearchResults: React.FC<Props> = React.memo(
  ({
    results,
    onSelect,
    selected,
    dragging,
    dragId,
    onDragStart,
    onDrag,
    onDragEnd,
    ...props
  }) => {
    const { t } = useTranslation('catalog');

    return (
      <Container {...props}>
        <Header>
          <Typography variant={TypographyVariants.H4}>
            {t('list.results')}
          </Typography>
        </Header>
        <Results empty={results.length < 1}>
          {results.length > 0 ? (
            results
              .sort((a, b) => a.code.localeCompare(b.code))
              .map((item) => (
                <CatalogItem
                  draggable
                  key={item.id}
                  data={item}
                  onSelect={onSelect}
                  isSelected={selected?.id === item.id}
                  currentDragId={dragId}
                  dragging={dragging}
                  onDragStart={onDragStart}
                  onDrag={onDrag}
                  onDragEnd={onDragEnd}
                />
              ))
          ) : (
            <EmptyResults />
          )}
        </Results>
      </Container>
    );
  },
);

SearchResults.displayName = 'SearchResults';

export default SearchResults;
