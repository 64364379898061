import * as React from 'react';
import { Container, Tablist, Tabpanel, Tab as Item } from './styles';
import { useCallback, useMemo } from 'react';
import { Typography } from '../Typography';

export type TabId = string | number;

export type TabPanelRenderer = (tabId: TabId) => React.ReactNode;

export interface Tab {
  id: TabId;
  title: string;
  disabled?: boolean;
}

export interface Props {
  tabs: Tab[];
  activeTabId: TabId;
  renderer: TabPanelRenderer;
  onChange?(currentTabId: TabId): void;
}

const Tabs: React.FC<Props> = React.memo(
  ({ tabs, activeTabId, onChange, renderer }) => {
    const Panel = useMemo(() => renderer(activeTabId), [activeTabId, renderer]);

    const handleChange = useCallback(
      (tab: Tab) => {
        if (!tab.disabled && activeTabId !== tab.id && onChange) {
          onChange(tab.id);
        }
      },
      [activeTabId, onChange],
    );

    const Items = useMemo(
      () =>
        tabs.map((tab) => (
          <Item
            role="tab"
            data-testid={tab.id}
            key={tab.id}
            active={activeTabId === tab.id}
            disabled={tab.disabled}
            onClick={() => handleChange(tab)}>
            <Typography>{tab.title}</Typography>
          </Item>
        )),
      [activeTabId, handleChange, tabs],
    );

    return (
      <Container data-testid="tabs">
        <Tablist role="tablist">{Items}</Tablist>
        <Tabpanel role="tabpanel">{Panel}</Tabpanel>
      </Container>
    );
  },
);

Tabs.displayName = 'Tabs';

export default Tabs;
