import Big from 'big.js';

export type Line = { a: number; b: number };

export const findLine = (
  point1: [number, number],
  point2: [number, number],
) => {
  const point1X = new Big(point1[0]);
  const point1Y = new Big(point1[1]);
  const point2X = new Big(point2[0]);
  const point2Y = new Big(point2[1]);

  // (point1Y - point2Y) / (point1X - point2X);
  const a = new Big(point1Y.minus(point2Y).div(point1X.minus(point2X)));

  //point2Y - a * point2X
  const b = new Big(point2Y.minus(a.times(point2X)));

  const aNumber = a.toNumber();
  const bNumber = b.toNumber();
  return { a: aNumber, b: bNumber };
};

export const findParallelLine = (point: [number, number], line: Line) => {
  const pointX = new Big(point[0]);
  const pointY = new Big(point[1]);
  const lineA = new Big(line.a);
  const lineB = new Big(line.b);

  // pointY - lineA * pointX
  const b = new Big(pointY.minus(lineA.times(pointX)));

  const bNumber = b.toNumber();
  return { a: line.a, b: bNumber };
};

export const findCrossPoint = (line1: Line, line2: Line): [number, number] => {
  const line1a = new Big(line1.a);
  const line1b = new Big(line1.b);
  const line2a = new Big(line2.a);
  const line2b = new Big(line2.b);

  //(line2.b - line1.b) / (line1.a - line2.a)
  const x = new Big(line2b.minus(line1b).div(line1a.minus(line2a)));
  // line2.a * x + line2.b;
  const y = new Big(line2a.times(x).add(line2b));

  const xNumber = x.toNumber();
  const yNumber = y.toNumber();

  return [xNumber, yNumber];
};

export const findPointOnLineInDistance = (
  line: Line,
  point: [number, number],
  distance: Big,
) => {
  const lineA = new Big(line.a);
  const lineB = new Big(line.b);
  const pointX = new Big(point[0]);
  const pointY = new Big(point[1]);

  // Calculate the vector from the line's start point to the target point
  const vx = pointX.minus(lineA);
  const vy = pointY.minus(lineB);

  // Calculate the length of the vector
  const mag = vx.pow(2).plus(vy.pow(2)).sqrt();

  // Normalize the vector to unit length
  const normalizedVx = vx.div(mag);
  const normalizedVy = vy.div(mag);

  // // Calculate the new point along the line
  const newX = normalizedVx.times(mag.plus(distance)).plus(lineA);
  const newY = normalizedVy.times(mag.plus(distance)).plus(lineB);
  //
  return [newX.toNumber(), newY.toNumber()];
};

export const findYPointOnLine = (line: Line, pointX: number) => {
  const lineA = new Big(line.a);
  const lineB = new Big(line.b);
  const x = new Big(pointX);

  // line.a * x + line.b
  return new Big(lineA.times(x).add(lineB)).toNumber();
};

export const findXPointOnLine = (line: Line, pointY: number) => {
  const lineA = new Big(line.a);
  const lineB = new Big(line.b);
  const y = new Big(pointY);

  // (y - line.b) / line.a
  return new Big(y.minus(lineB).div(lineA)).toNumber();
};
