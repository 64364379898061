import Big from 'big.js';
import { Vector3 as IVector3 } from './types';

export class Vector3 implements IVector3 {
  private _values = [new Big(0), new Big(0), new Big(0)];

  constructor(values?: (number | Big)[]) {
    if (values && values.length) {
      this.xyz = values.map((value) => new Big(value));
    }
  }

  get x(): Big {
    return this._values[0];
  }
  set x(value: Big) {
    this._values[0] = value;
  }
  get y(): Big {
    return this._values[1];
  }
  set y(value: Big) {
    this._values[1] = value;
  }
  get z(): Big {
    return this._values[2];
  }
  set z(value: Big) {
    this._values[2] = value;
  }
  get xy(): Big[] {
    return [this._values[0], this._values[1]];
  }
  set xy(values: Big[]) {
    this._values[0] = values[0];
    this._values[1] = values[1];
  }
  get xyz(): Big[] {
    return [this._values[0], this._values[1], this._values[2]];
  }
  set xyz(values: Big[]) {
    this._values[0] = values[0];
    this._values[1] = values[1];
    this._values[2] = values[2];
  }

  static cross(vector: Vector3, vector2: Vector3, dest?: Vector3): Vector3 {
    const copied = new Vector3();

    if (dest) {
      copied.xyz = JSON.parse(JSON.stringify(dest.xyz));
    }

    const x = vector.x;
    const y = vector.y;
    const z = vector.z;

    const x2 = vector2.x;
    const y2 = vector2.y;
    const z2 = vector2.z;

    copied.x = y.times(z2).minus(z.times(y2));
    copied.y = z.times(x2).minus(x.times(z2));
    copied.z = x.times(y2).minus(y.times(x2));

    return copied;
  }
}
