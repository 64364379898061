import styled, { css } from 'styled-components';
import { layout } from '../../styles';
import { checkIcon } from '../Checkbox/styles';

export const Container = styled.div``;

export const ImageBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.mainBlue};
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  z-index: -1;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
`;

export const TitleWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
`;

export const Title = styled.div`
  margin: 0 auto 40px;
  > span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 32px;
    font-weight: 700;
  }
`;

export const Subtitle = styled.div`
  margin: 0 auto;
  width: 60%;
  > span {
    color: ${({ theme }) => theme.colors.accentBlue3};
    font-weight: 700;
    line-height: 25px;
  }
`;

export const ContactBox = styled(layout.Column)`
  align-items: center;
  text-align: center;
  background: ${({ theme }) => theme.colors.grey4};
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  padding: 16px 0;
  border-radius: 4px;
  a {
    color: ${({ theme }) => theme.colors.accentBlue};
  }
`;

export const InfoBox = styled(ContactBox)`
  text-align: left;
  padding: 16px;
  margin: 10px 0 40px;
`;

export const ResetLink = styled.div`
  span {
    > span {
      color: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;

export const Form = styled.form``;

export const HeaderWrapper = styled.div`
  margin-bottom: 24px;
  > span {
    color: ${({ theme }) => theme.colors.dark};
    line-height: 32px;
  }
`;

export const InputsWrapper = styled.div`
  margin-bottom: 16px;
  > div {
    padding: 8px 0;
  }
`;

export const NotificationWrapper = styled.div`
  margin: 8px 0;
`;

const small_bottom_space = css`
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div<{ smallBottomSpace?: boolean }>`
  margin: 40px 0;
  ${({ smallBottomSpace }) => smallBottomSpace && small_bottom_space};
`;

const bottom_space = css`
  margin-bottom: 40px;
`;

export const DescriptionWrapper = styled.div<{ bottomSpace?: boolean }>`
  ${({ bottomSpace }) => bottomSpace && bottom_space};
  > span {
    color: ${({ theme }) => theme.colors.grey};
  }
  a {
    color: ${({ theme }) => theme.colors.accentBlue};
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    > path {
      fill: ${({ theme }) => theme.colors.accentBlue};
    }
  }
  > span {
    color: ${({ theme }) => theme.colors.accentBlue};
  }
`;

export const EmailSentInstructions = styled.div`
  > span {
    display: inline-block;
    color: ${({ theme }) => theme.colors.grey};
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const PasswordChecklist = styled.div`
  padding: 16px 0 0;
`;

export const ChecklistItem = styled.div<{ checked?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  > span {
    color: ${({ theme, checked }) =>
      checked ? theme.colors.white : theme.colors.grey};
  }
  &:first-of-type {
    margin-bottom: 15px;
  }
`;

const is_checked = css`
  &:after {
    ${checkIcon};
    border-color: ${({ theme }) => theme.colors.mainBlue};
  }
`;

export const Checkbox = styled.div<{ checked?: boolean }>`
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, checked }) =>
    checked ? theme.colors.white : theme.colors.grey};
  ${({ checked }) => checked && is_checked};
`;
