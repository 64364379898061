import * as React from 'react';
import { LinkContainer } from './styles';

export interface Props {
  href?: string;
  target?: string;
  big?: boolean;
}

const Link: React.FC<Props> = React.memo(
  ({ children, target = '_blank', ...props }) => {
    return (
      <LinkContainer
        {...props}
        target={target}
        rel="noopener nofollow noreferrer">
        {children}
      </LinkContainer>
    );
  },
);

Link.displayName = 'Link';

export default Link;
