import create, { SetState, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';

import { Sorting } from '../../components/Table';
import { BasicOrder } from '../../services/api/models/order';
import { FilterCommand } from '../../services/api/services/order';

export const STORE_NAME = `@store/orders/list`;

type OrderSorting = Sorting<BasicOrder>;

export type OrdersListState = {
  filters: FilterCommand;
  page: number;
  sorting: OrderSorting;
  setPage(nextPage: number): void;
  setSorting(nextSorting: OrderSorting): void;
  updateFilters(newFilters: FilterCommand): void;
};

const setPageAction = (set: SetState<OrdersListState>) => (nextPage: number) =>
  set({ page: nextPage });

const setSortingAction = (set: SetState<OrdersListState>) => (
  nextSorting: OrderSorting,
) => set({ sorting: nextSorting });

const updateFiltersAction = (set: SetState<OrdersListState>) => (
  newFilters: FilterCommand,
) => {
  set((state) => {
    return {
      ...state,
      // API doesn't filter list correctly when empty filters are provided
      filters: removeEmptyFilters({
        ...state.filters,
        ...newFilters,
      }),
      // reset page when filters change
      page: 1,
    };
  });
};

const ordersListStore: StateCreator<OrdersListState> = (set) => ({
  filters: {},
  page: 1,
  sorting: { sortedColumn: 'orderDate', sort: 'desc' },
  setPage: setPageAction(set),
  setSorting: setSortingAction(set),
  updateFilters: updateFiltersAction(set),
});

export const useOrdersListStore = create(devtools(ordersListStore, STORE_NAME));

function removeEmptyFilters(filters: FilterCommand): FilterCommand {
  return Object.keys(filters).reduce((cleanFilters, key) => {
    const value = filters[key as keyof FilterCommand];

    if (typeof value === 'undefined' || value === '') {
      return cleanFilters;
    } else {
      return {
        ...cleanFilters,
        [key]: value,
      };
    }
  }, {});
}
