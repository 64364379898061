import * as React from 'react';

const SvgAdd = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={124}
      height={124}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={9.311}
        width={114.99}
        height={114.99}
        rx={14}
        transform="rotate(4.644 9.31 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#add_svg__filter0_d)">
        <rect x={42} y={42} width={40} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={48} y={48} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M57.333 58h9.333a.667.667 0 000-1.333h-9.333a.667.667 0 100 1.333z"
        fill="#fff"
      />
      <path
        d="M67.333 58.666v-1.333a.667.667 0 00-1.333 0v1.334a.667.667 0 101.333 0zM58 58.666v-1.333a.667.667 0 00-1.333 0v1.334a.667.667 0 101.333 0zM59.334 63.333a.667.667 0 01-.4-1.2l2.666-2a.666.666 0 01.787 0l2.667 1.88a.667.667 0 11-.774 1.087L62 61.493 59.734 63.2a.666.666 0 01-.4.133z"
        fill="#fff"
      />
      <path
        d="M62 68a.667.667 0 01-.667-.666V62a.666.666 0 111.333 0v5.334A.667.667 0 0162 68z"
        fill="#fff"
      />
      <defs>
        <filter
          id="add_svg__filter0_d"
          x={32}
          y={42}
          width={60}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgAdd = React.memo(SvgAdd);
export default MemoSvgAdd;
