import styled from 'styled-components';
import { layout } from '../../styles';
import { Typography } from '../Typography';

export const Form = styled.form`
  width: 386px;
  height: 364px;
`;

export const ButtonsWrapper = styled(layout.Row)`
  padding-top: 40px;
  > button:first-child {
    width: 77px;
    min-width: unset;
    height: 40px;
    margin-right: 8px;
  }
`;

export const Header = styled(Typography)`
  display: block;
  text-align: center;
  margin-bottom: 24px;
`;

export const FormBody = styled.div`
  position: relative;

  div:not(:last-of-type) {
    margin-top: 8px;
  }
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 57%;
  right: 0;
`;

export const NotificationWrapper = styled.div`
  margin-bottom: 8px;
`;
