import * as React from 'react';
import { Container } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import {
  Switch,
  SwitchOption,
  OptionValue,
} from '../../../../../../components/Switch';
import { useCallback, useMemo } from 'react';

export type PropertySwitchOption = SwitchOption;
export type PropertyOptionValue = OptionValue;

export interface Props {
  title: string;
  name: string;
  onChange(value: PropertyOptionValue, name: string): void;
  options: PropertySwitchOption[];
  selected: PropertyOptionValue;
}

const PropertySwitch: React.FC<Props> = React.memo(
  ({ title, onChange, options, selected, name, ...props }) => {
    const handleChange = useCallback(
      (values: PropertyOptionValue[]) => {
        onChange(values[0], name);
      },
      [name, onChange],
    );

    const selectedValue = useMemo(() => [selected], [selected]);

    return (
      <Container aria-label={`switch-${name}`} {...props}>
        <Typography variant={TypographyVariants.H5}>{title}:</Typography>
        <Switch
          name={name}
          options={options}
          selected={selectedValue}
          onChange={handleChange}
          width={179}
        />
      </Container>
    );
  },
);

PropertySwitch.displayName = 'PropertySwitch';

export default PropertySwitch;
