import * as React from 'react';
import { SmallTooltip as Container } from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { CommonProps } from './types';

export interface Props extends CommonProps {}

const Small: React.FC<Props> = React.memo(
  ({ text, drawFrom, alwaysVisible, position }) => {
    return (
      <Container
        role="tooltip"
        arrowSize="small"
        drawFrom={drawFrom}
        alwaysVisible={alwaysVisible}
        tooltipPosition={position}>
        <Typography variant={TypographyVariants.PARAGRAPH}>{text}</Typography>
      </Container>
    );
  },
);

Small.displayName = 'Small';

export default Small;
