import styled from 'styled-components';
import { layout } from '../../styles';

export const Container = styled.div`
  width: fit-content;
`;

export type AlignTitle = 'left' | 'center';

export const TitleRow = styled.div<{ align: AlignTitle }>`
  margin-bottom: 16px;
  text-align: ${({ align }) => align};
`;

export const ItemRow = styled(layout.Row)`
  > div {
    margin: 0 4px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const Item = styled(layout.Column)`
  width: 150px;
  min-height: 155px;
  border: 1px dashed ${({ theme }) => theme.colors.accentBlue2};
  border-radius: 4px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 9px 16px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  > span {
    margin-top: 5px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
  }
`;

export const ImageWrapper = styled.div`
  > svg {
    width: 90px;
    height: 90px;
  }
`;
