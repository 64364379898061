import * as React from 'react';

const SvgArrowDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={props.transform}>
      <mask
        id="mask0_14_4690"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={props.width}
        height={props.height}>
        <rect
          width={props.width}
          height={props.height}
          fill={props.fill ?? '#D9D9D9'}
        />
      </mask>
      <g mask="url(#mask0_14_4690)">
        <path
          d="M12.7052 17.2987C12.3151 17.6866 11.6849 17.6867 11.2948 17.2987L7.0154 13.0428C6.73121 12.7602 6.73121 12.3003 7.0154 12.0177V12.0177C7.29738 11.7372 7.75292 11.7372 8.0349 12.0177L11.2704 15.2354V6.72957C11.2704 6.32664 11.5971 6 12 6V6C12.4029 6 12.7296 6.32664 12.7296 6.72957V15.2354L15.9651 12.0177C16.2471 11.7372 16.7026 11.7372 16.9846 12.0177V12.0177C17.2688 12.3003 17.2688 12.7602 16.9846 13.0428L12.7052 17.2987Z"
          fill={props.stroke ?? '#828A98'}
        />
      </g>
    </svg>
  );
};

const MemoSvgArrowDown = React.memo(SvgArrowDown);
export default MemoSvgArrowDown;
