import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: fit-content;
`;

export const ItemWrapper = styled.div`
  box-sizing: border-box;
  width: 95px;
  height: 56px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  padding: 0 16px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
    div {
      &:last-of-type {
        svg {
          > path {
            fill: ${({ theme }) => theme.colors.mainBlue};
          }
        }
      }
    }
`;

export const Item = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  > svg {
    width: 20px;
    height: 20px;
  }
  > span {
    color: ${({ theme }) => theme.colors.mainBlue};
    text-transform: uppercase;
    margin: 0 2px 0 8px;
  }
`;

export const ArrowWrapper = styled.div<{ active?: boolean }>`
  > svg {
    path {
      fill: ${({ active, theme }) =>
        active ? theme.colors.accentBlue : theme.colors.grey};
    }
  }
`;

export const ListWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
`;

export const List = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 95px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 10px 10px rgba(39, 43, 95, 0.08);
  padding-bottom: 8px;
`;

export const ListItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  padding: 0 16px;
  height: 36px;
  &:first-of-type {
    height: 56px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
  }
`;
