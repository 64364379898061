import * as React from 'react';
import { List as Container } from './styles';
import { InsertOption } from './types';
import Option from './Option';

export interface Props {
  options: InsertOption[];
  onSelect(option: InsertOption): void;
}

const List: React.FC<Props> = React.memo(({ options, onSelect }) => {
  return (
    <Container
      aria-label="option-list"
      data-testid="option-list"
      role="listbox">
      {options.map((option) => (
        <Option key={option.value} option={option} onSelect={onSelect} />
      ))}
    </Container>
  );
});

List.displayName = 'List';

export default List;
