import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { authApi } from '../services';
import { resetSessionStore, sessionStore } from '../../../store/session';
import { navigationStore } from '../../../store/navigation';
import { Endpoints } from '../endpoints';
import { ErrorsRoutes } from '../../../router/routes';

export const responseInterceptor = (response: AxiosResponse) => {
  return response;
};

const BLACKLIST_404 = [Endpoints.AUTH];

const BLACKLIST_500 = [Endpoints.AUTH];

const blacklistGuard = (
  error: AxiosError,
  blacklist: string[],
  callback: () => void,
) =>
  error.response?.config.url &&
  !blacklist.includes(error.response.config.url) &&
  callback();

export const responseErrorInterceptor = (
  axiosInstance: AxiosInstance,
) => async (error: AxiosError) => {
  const originalRequest = error.config as AxiosRequestConfig & {
    _retry: boolean;
  };

  switch (error.response?.status) {
    case 403: {
      if (
        !originalRequest._retry &&
        originalRequest.url !== `${Endpoints.AUTH}/refresh`
      ) {
        originalRequest._retry = true;
        const { refreshToken } = sessionStore.getState();
        if (refreshToken) {
          const { data } = await authApi.refreshToken({ refreshToken });
          sessionStore.setState({
            ...data,
          });
          return axiosInstance(originalRequest);
        } else {
          sessionStore.setState({
            ...resetSessionStore,
            forcedLogout: true,
          });
        }
      } else {
        sessionStore.setState({
          ...resetSessionStore,
          forcedLogout: true,
        });
      }
      break;
    }
    case 404: {
      const { redirectToError } = navigationStore.getState();
      blacklistGuard(error, BLACKLIST_404, () =>
        redirectToError(ErrorsRoutes.PAGE_404),
      );
      break;
    }
    case 502:
    case 500: {
      const { redirectToError } = navigationStore.getState();
      blacklistGuard(error, BLACKLIST_500, () =>
        redirectToError(ErrorsRoutes.PAGE_500),
      );
      break;
    }
  }
  return Promise.reject(error);
};
