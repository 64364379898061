import { useEffect, useRef } from 'react';

const useEventListener = <T extends Event>(
  event: keyof DocumentEventMap,
  callback: (e: T) => void,
  element: Document | Window = document,
) => {
  const handler = useRef<typeof callback>();

  useEffect(() => {
    handler.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!element || !element.addEventListener) {
      return;
    }

    const eventListener = (e: T) => handler.current?.(e);

    element.addEventListener(event, eventListener as EventListener);
    return () =>
      element.removeEventListener(event, eventListener as EventListener);
  }, [element, event]);
};

export default useEventListener;
