import styled, { css } from 'styled-components';
import { layout } from '../../../../../../styles';

const hover = css`
  background-color: ${({ theme }) => theme.colors.grey4};
`;

const active_tab = css`
  ${hover};
  > svg {
    path {
      fill: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;

export const Container = styled.div``;

export const Tab = styled(layout.Row)<{ level: number; active?: boolean }>`
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 12px 16px 12px ${({ level }) => level * 16}px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  user-select: none;
  > span {
    color: ${({ theme }) => theme.colors.dark};
    flex-basis: 90%;
  }
  > svg {
    width: 20px;
    height: 20px;
  }
  ${({ active }) => active && active_tab}
  &:hover {
    ${hover};
  }
`;

export const TabContent = styled.div``;
