export enum HotKeys {
  NEW_PROJECT = 'ctrl+n',
  SAVE = 'ctrl+s',
  SAVE_AS = 'ctrl+shift+s',
  UNDO = 'ctrl+z',
  REDO = 'ctrl+shift+z',
  COPY = 'ctrl+c',
  CUT = 'ctrl+x',
  PASTE = 'ctrl+v',
  DELETE = 'del',
}
