import * as React from 'react';
import { useTransition } from 'react-spring';
import ReactDom from 'react-dom';

import {
  BottomProvider as BottomWrapper,
  ProviderContainer as Container,
} from './styles';
import { useTimeout } from '../../hooks';
import Bottom, { Props as BottomProps } from './Bottom';
import { Backdrop } from '../Backdrop';

export interface Props extends BottomProps {
  show?: boolean;
  timeout?: number;
  dismissOnOutside?: boolean;
  backdropColor?: string;
  backdropOpacity?: number;
  disableBackdrop?: boolean;
}

const BottomProvider: React.FC<Props> = React.memo(
  ({
    children,
    show,
    onDismiss,
    title,
    fullWidth,
    dismissOnOutside,
    timeout = 5000,
    backdropColor,
    backdropOpacity = 0.3,
    disableBackdrop,
  }) => {
    const modalTransition = useTransition(show, null, {
      from: { bottom: '-130px' },
      enter: { bottom: '40px' },
      leave: { bottom: '-130px' },
    });

    useTimeout(() => onDismiss?.(), timeout, timeout === 0);

    return ReactDom.createPortal(
      <>
        {modalTransition.map(
          ({ item, key, props }) =>
            item && (
              <Container key={key}>
                <BottomWrapper style={props} data-testid="bottom-provider">
                  <Bottom
                    title={title}
                    onDismiss={onDismiss}
                    fullWidth={fullWidth}
                    dismissOnOutside={dismissOnOutside}>
                    {children}
                  </Bottom>
                </BottomWrapper>
              </Container>
            ),
        )}
        <Backdrop
          show={show && !disableBackdrop}
          zIndex={94}
          color={backdropColor}
          opacity={backdropOpacity}
        />
      </>,
      document.body,
    );
  },
);

BottomProvider.displayName = 'BottomProvider';

export default BottomProvider;
