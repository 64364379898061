import { Position } from './position';
import { GlassEdges, GlassTypes, RectCorners } from '../../../types';
import { SelectOption } from '../../../components/Select';

export enum CutType {
  BIG_INNER_ON_EDGE = 'BIG_INNER_ON_EDGE',
  EVERY_HINGE = 'EVERY_HINGE',
}

export enum HoleSize {
  FIVE_FORTY = 'FIVE_FORTY',
  FORTY_SEVENTY = 'FORTY_SEVENTY',
}

export enum GlassType {
  ESG = 'ESG',
  VSG = 'VSG',
  ESG_VSG = 'ESG_VSG',
}

export enum GlassThicknessType {
  G6 = 'G6',
  G8 = 'G8',
  G10 = 'G10',
  G12 = 'G12',
  G442L = 'G442L',
  G444L = 'G444L',
  G552L = 'G552L',
  G554L = 'G554L',
  G662L = 'G662L',
  G664L = 'G664L',
}

export enum MainCutOffPadding {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface Glass {
  id: number;
  width: number;
  height: number;
  position: Position;
  corners: RectCorners;
  originCorners: RectCorners;
  thickness: number;
  hardening: boolean;
  glassEdges?: GlassEdges;
  trapezoidalCut?: boolean;
  panelType?: GlassTypes;
  adjustManually?: boolean;
  blank?: boolean;
}

export interface GlassDimensions {
  top: number;
  bottom: number;
  left: number;
  right: number;

  [key: string]: number;
}

export interface WingDimensions {
  height: number;
  width: number;
}

export interface GlassProperties {
  id: number;
  thickness: number;
  dimensionLinePadding: GlassDimensions;
  cutOffPadding: GlassDimensions;
  glassToGlassGap: GlassDimensions;
  wallToGlassGap: GlassDimensions;
  cutType: CutType;
  holeSize: HoleSize;
  dxfUrl: string;
  visualisationUrl: string;
  mainCutOffPadding: MainCutOffPadding;
  maxWingDimensions: WingDimensions;
  minWingDimensions: WingDimensions;
  maxWingWeight: number;
}

export type GlassPropertiesCommandForm = Omit<
  GlassProperties,
  'id' | 'thickness' | 'cutType' | 'holeSize'
> & {
  cutType?: SelectOption;
  holeSize?: SelectOption;
  thickness: {
    title: string;
    value: GlassThicknessType;
  };
};

export type GlassPropertiesCommand = Omit<
  GlassProperties,
  'id' | 'thickness'
> & {
  thickness: GlassThicknessType;
};

export type GlassPropertiesSearch = Omit<GlassProperties, 'id'>;
