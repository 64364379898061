import create, { SetState } from 'zustand';
import createVanilla from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';
import { RootRoutes, ErrorsRoutes } from '../../router/routes';

export const STORE_NAME = `@store/navigation`;

export type State = {
  activePage: RootRoutes;
  showMainMenu: boolean;
  activeErrorPage?: ErrorsRoutes;
  setActivePage(pathname: string): void;
  openMainMenu(): void;
  closeMainMenu(): void;
  redirectToError(route: ErrorsRoutes): void;
  clearError(): void;
};

const setActivePageAction = (set: SetState<State>) => (pathname: string) => {
  const activePage =
    Object.values(RootRoutes).find(
      (value) =>
        value !== RootRoutes.HOME &&
        pathname.split('/')[1] === value.split('/')[1],
    ) ?? RootRoutes.HOME;
  set({ activePage });
};

const store = (set: SetState<State>): State => ({
  activePage: RootRoutes.HOME,
  setActivePage: setActivePageAction(set),
  showMainMenu: false,
  openMainMenu: () => set({ showMainMenu: true }),
  closeMainMenu: () => set({ showMainMenu: false }),
  redirectToError: (route: ErrorsRoutes) => set({ activeErrorPage: route }),
  clearError: () => set({ activeErrorPage: undefined }),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
