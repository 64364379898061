import styled from 'styled-components';
import { layout } from '../../../../styles';

export const ButtonsWrapper = styled(layout.Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  > button {
    margin-top: 4px;
    &:first-of-type {
      margin-right: 8px;
    }
  }
`;

export const Container = styled(layout.Row)`
  justify-content: space-between;
`;

export const ErrorColumn = styled(layout.Column)`
  margin-right: 16px;
`;
