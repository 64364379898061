import * as React from 'react';

const SvgEmpty = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.332 27.89l-3.115-1.032v-7.735L0 17.725l4.581-7.277L17.999 6l4.606 1.527-1.335 1.78-3.271-1.085-9.375 3.108 8.912 2.954-1.334 1.78-10.478-3.473-2.505 3.98 10.094 3.345-1.335 1.78-5.651-1.874v5.513l2.34.776-1.335 1.78zm7.341.212l-1.335 1.78 4.66 1.545 13.782-4.569v-7.735l4.218-1.398-4.581-7.277-2.806-.93-1.335 1.78.098.032-.162.053-2.218 2.958 5.28-1.75 2.504 3.98-10.664 3.534-.659-1.046-1.355 1.807 1.109 1.762 8.462-2.805v5.512l-10.617 3.52v-6.593l-2.11 2.812v3.78l-2.27-.752z"
        fill="#828A98"
      />
      <path stroke="#828A98" strokeWidth={2} d="M28.8 4.6l-21 28" />
    </svg>
  );
};

const MemoSvgEmpty = React.memo(SvgEmpty);
export default MemoSvgEmpty;
