import styled, { css } from 'styled-components';
import { NotificationVariants } from './types';
import { animated } from 'react-spring';

const info_style = css`
  background: ${({ theme }) => theme.colors.accentBlue};
`;

const success_style = css`
  background: ${({ theme }) => theme.colors.success};
`;

const error_style = css`
  background: ${({ theme }) => theme.colors.validation};
`;

const alert_style = css`
  background: ${({ theme }) => theme.colors.alert};
`;

const selectVariant = (variant?: NotificationVariants) => {
  switch (variant) {
    case NotificationVariants.SUCCESS: {
      return success_style;
    }
    case NotificationVariants.ERROR: {
      return error_style;
    }
    case NotificationVariants.ALERT: {
      return alert_style;
    }
    default:
    case NotificationVariants.INFO: {
      return info_style;
    }
  }
};

export const Container = styled.div<{
  variant?: NotificationVariants;
  fullWidth?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: ${({ fullWidth }) => !fullWidth && 'fit-content'};
  min-width: 202px;
  min-height: 48px;
  border-radius: 4px;
  padding: 16px 13px;
  ${({ variant }) => selectVariant(variant)}
  span {
    color: ${({ theme }) => theme.colors.white};
  }
  svg {
    width: 16px;
    height: 16px;
    > path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  padding-left: 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProviderContainer = styled(animated.div)`
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  z-index: 100;
`;
