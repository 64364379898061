import { IconSwitch, IconSwitchOption, OptionValue } from '../Switch';
import * as React from 'react';
import ArrowDown from '../icons/ArrowDown';
import {
  HorizontalPositioning,
  VerticalPositioning,
} from '../../pages/Project/containers/PropertyPanel/components/DimensionsInputs';
import ArrowUp from '../icons/ArrowUp';
import ArrowSym from '../icons/ArrowSym';
import { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';

const ICON_WIDTH = 24;
const ICON_HEIGHT = 24;

interface DirectionSwitchProps {
  directionSelected: VerticalPositioning | HorizontalPositioning;
  handleDirectionChange: (
    value: (VerticalPositioning | HorizontalPositioning)[],
  ) => void;
  isVertical?: boolean;
  disabledOptions?: OptionValue[];
}

const DirectionSwitch = ({
  directionSelected,
  handleDirectionChange,
  isVertical,
  disabledOptions,
}: DirectionSwitchProps) => {
  const { t } = useTranslation('project');
  const theme = useContext(ThemeContext);

  const widthOptions = useMemo(
    (): IconSwitchOption[] => [
      {
        Icon: (active) => (
          <ArrowDown
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            transform="rotate(90)"
            stroke={active ? theme.colors.mainBlue : theme.colors.grey}
          />
        ),
        title: 'left',
        tooltip: t('propertyPanel.form.editDirection.left'),
        value: HorizontalPositioning.LEFT,
      },
      {
        Icon: (active) => (
          <ArrowUp
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            transform="rotate(90)"
            stroke={active ? theme.colors.mainBlue : theme.colors.grey}
          />
        ),
        title: 'right',
        tooltip: t('propertyPanel.form.editDirection.right'),
        value: HorizontalPositioning.RIGHT,
      },
      {
        Icon: (active) => (
          <ArrowSym
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            transform="rotate(90)"
            stroke={active ? theme.colors.mainBlue : theme.colors.grey}
          />
        ),
        title: 'middleHorizontal',
        tooltip: t('propertyPanel.form.editDirection.symmetric'),
        value: HorizontalPositioning.MIDDLE,
      },
    ],
    [],
  );

  const heightOptions = useMemo(
    (): IconSwitchOption[] => [
      {
        Icon: (active) => (
          <ArrowUp
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            stroke={active ? theme.colors.mainBlue : theme.colors.grey}
          />
        ),
        title: 'top',
        tooltip: t('propertyPanel.form.editDirection.top'),
        value: VerticalPositioning.TOP,
      },
      {
        Icon: (active) => (
          <ArrowDown
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            stroke={active ? theme.colors.mainBlue : theme.colors.grey}
          />
        ),
        title: 'bottom',
        tooltip: t('propertyPanel.form.editDirection.bottom'),
        value: VerticalPositioning.BOTTOM,
      },
      {
        Icon: (active) => (
          <ArrowSym
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            stroke={active ? theme.colors.mainBlue : theme.colors.grey}
          />
        ),
        title: 'middleVertical',
        tooltip: t('propertyPanel.form.editDirection.symmetric'),
        value: VerticalPositioning.MIDDLE,
      },
    ],
    [],
  );

  return (
    <div>
      <IconSwitch
        options={isVertical ? widthOptions : heightOptions}
        selected={[directionSelected]}
        disabledOptions={disabledOptions}
        onChange={handleDirectionChange}
      />
    </div>
  );
};

DirectionSwitch.displayName = 'DirectionSwitch';

export default DirectionSwitch;
