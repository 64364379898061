import styled, { css } from 'styled-components';

const disabled_style = css`
  cursor: default;
`;

const invalid_style = css`
  + div {
    background: ${({ theme }) => theme.colors.validationLight};
    border: 1px solid ${({ theme }) => theme.colors.validation};
  }
`;

export const Container = styled.label<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;
  &:hover {
    > div {
      background: ${({ theme }) => theme.colors.grey4};
    }
  }
  ${({ disabled }) => disabled && disabled_style}
`;

export const checkIcon = css`
  content: '';
  display: block;
  width: 3px;
  height: 6px;
  margin-bottom: 2px;
  margin-right: 1px;
  border-style: solid;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
`;

export const Input = styled.input<{ invalid?: boolean }>`
  display: none;
  &:checked {
    ~ span {
      font-weight: 700;
    }
  }
  &:not(:disabled) {
    &:not(:invalid) {
      &:checked {
        + div {
          &:after {
            ${checkIcon};
            border-color: ${({ theme, invalid }) =>
              invalid ? theme.colors.validation : theme.colors.mainBlue};
          }
        }
      }
    }
    &:invalid {
      &:checked {
        + div {
          &:after {
            ${checkIcon};
            border-color: ${({ theme }) => theme.colors.validation};
          }
        }
      }
    }
  }
  &:disabled {
    + div {
      background: ${({ theme }) => theme.colors.grey4};
      border: 1px dashed ${({ theme }) => theme.colors.accentBlue2};
    }
    &:checked {
      + div {
        &:after {
          ${checkIcon};
          border-color: ${({ theme }) => theme.colors.grey};
        }
      }
    }
  }
  &:invalid {
    ${invalid_style};
  }
  ${({ invalid }) => invalid && invalid_style};
`;

export const Box = styled.div<{ checked?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  margin-right: 8px;
`;
