import { SetState, State } from 'zustand';
import { useNotificationsStore } from '../notifications';
import { NotificationVariants } from '../../components/Notification';
import { OrderModals } from '../../pages/OrderDetails/types';
import { useModalsStore } from '../modals';

const { setNotification } = useNotificationsStore.getState();
const open = useModalsStore.getState().open;
const openModal = () => open(OrderModals.CONFIRM_MODIFIED_PRODUCTS);

export const createAsyncAction = (set: SetState<State>) => async (
  action: () => Promise<void>,
  onError?: () => void,
  onSuccess?: () => void,
) => {
  try {
    set({ loading: true });
    await action();
    onSuccess?.();
  } catch (error) {
    set({ error });
    onError?.();
    if (error.response?.status !== 403 && error.response?.status !== 400) {
      setNotification({
        variant: NotificationVariants.ERROR,
        text: error.message,
      });
    }
    if (error.response?.status === 400) {
      openModal();
    }
  } finally {
    set({ loading: false });
  }
};
