import { EdgeType, IndentEdgeType } from '../../../../../../types';
import RectInput from './RectInput';
import * as React from 'react';
import { useCallback, useState } from 'react';
import LockButton from '../../../../../../components/LockButton/LockButton';
import { Divider } from './styles';
import { EdgeChangeProps } from '../../containers/GlassForm/GlassDimensions';
import { HorizontalPositioning, VerticalPositioning } from './types';

interface RectInputFormProps {
  selectedId: number;
  handleChange: (props: EdgeChangeProps) => void;
  edges: Record<IndentEdgeType, number>;
  getMaxDimensions?: (
    direction: VerticalPositioning | HorizontalPositioning,
  ) => Record<EdgeType, number>;
}

const RectInputForm = ({
  selectedId,
  handleChange,
  edges,
  getMaxDimensions,
}: RectInputFormProps) => {
  const [lockVertical, setLockVertical] = useState(false);
  const [lockHorizontal, setLockHorizontal] = useState(false);

  const handleLockVertical = useCallback(() => setLockVertical(!lockVertical), [
    lockVertical,
  ]);
  const handleLockHorizontal = useCallback(
    () => setLockHorizontal(!lockHorizontal),
    [lockHorizontal],
  );

  return (
    <>
      <RectInput
        key={'top-input-row'}
        wall={'top'}
        onBlur={handleChange}
        edges={edges}
        getMaxDimensions={getMaxDimensions}
        lock={lockVertical}
        selectedId={selectedId}
      />
      <LockButton locked={lockVertical} onClick={handleLockVertical} />
      <RectInput
        key={'bottom-input-row'}
        wall={'bottom'}
        onBlur={handleChange}
        edges={edges}
        getMaxDimensions={getMaxDimensions}
        lock={lockVertical}
        selectedId={selectedId}
      />
      <Divider />
      <RectInput
        key={'left-input-row'}
        wall={'left'}
        onBlur={handleChange}
        edges={edges}
        getMaxDimensions={getMaxDimensions}
        lock={lockHorizontal}
        selectedId={selectedId}
      />
      <LockButton locked={lockHorizontal} onClick={handleLockHorizontal} />
      <RectInput
        key={'right-input-row'}
        wall={'right'}
        onBlur={handleChange}
        edges={edges}
        getMaxDimensions={getMaxDimensions}
        lock={lockHorizontal}
        selectedId={selectedId}
      />
    </>
  );
};

RectInputForm.displayName = 'RectInputForm';

export default RectInputForm;
