import { AxiosInstance } from 'axios';

import { Endpoints } from '../endpoints';
import axiosInstance from '../config';
import { PdfOrderCommand, PdfOfferCommand } from '../models/pdf';
import { i18next, Locales } from '../../i18n';

type LocalesMap = Record<Locales, string> & { [key: string]: string };

const apiLocalesMap: LocalesMap = {
  enGB: 'en',
  pl: 'pl',
  it: 'it',
  ru: 'ru',
};

class PdfGeneratorApi {
  constructor(private http: AxiosInstance) {}

  public getOrderProject = (orderId: number, command: PdfOrderCommand) =>
    this.http.post(
      `${Endpoints.PDF_GENERATOR}/project/${orderId}`,
      this.addLanguage(command),
      {
        responseType: 'arraybuffer',
      },
    );

  public getOrder = (orderId: number, command: PdfOrderCommand) =>
    this.http.post(
      `${Endpoints.PDF_GENERATOR}/order/${orderId}`,
      this.addLanguage(command),
      {
        responseType: 'arraybuffer',
      },
    );

  public getGlassHardeningOrder = (orderId: number, command: PdfOrderCommand) =>
    this.http.post(
      `${Endpoints.PDF_GENERATOR}/glass/${orderId}`,
      this.addLanguage(command),
      {
        responseType: 'blob',
      },
    );

  public getOffer = (offerId: number, command: PdfOfferCommand) =>
    this.http.post(
      `${Endpoints.PDF_GENERATOR}/offer/${offerId}`,
      this.addLanguage(command),
      {
        responseType: 'arraybuffer',
      },
    );

  private addLanguage = (command: PdfOrderCommand | PdfOfferCommand) => ({
    ...command,
    lang: apiLocalesMap[i18next.language],
  });
}

const pdfGeneratorApi = new PdfGeneratorApi(axiosInstance);
export default pdfGeneratorApi;
