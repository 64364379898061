import Konva from 'konva';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ProductModel } from '../../../../services/api/models/product';
import { Dimensions } from '../../types';
import { Anchor } from '../../types/Anchor';
import { scaleShape } from '../../utils/shapes';
import { SizeLabel } from '../SizeLabel';

export interface Props {
  anchor: Anchor;
  product: ProductModel;
}

const GasketLabel: React.FC<Props> = React.memo(({ anchor, product }) => {
  const labelRef = useRef<Konva.Label>(null);
  const [labelSize, setLabelSize] = useState<Dimensions>({
    width: 0,
    height: 0,
  });
  const scaledProduct = scaleShape(product, 1);

  const value = useMemo(() => {
    switch (anchor) {
      default:
      case 'top':
      case 'bottom': {
        return product.width.toFixed(2);
      }
      case 'left':
      case 'right': {
        return product.height.toFixed(2);
      }
    }
  }, [anchor, product.height, product.width]);

  const position = useMemo(() => {
    switch (anchor) {
      default:
      case 'top': {
        return {
          x: scaledProduct.width / 2 - labelSize.width / 2,
          y: -(labelSize.height + 10),
        };
      }
      case 'bottom': {
        return {
          x: scaledProduct.width / 2 - labelSize.width / 2,
          y: scaledProduct.height + 10,
        };
      }
      case 'left': {
        return {
          x: -(labelSize.width + 10),
          y: scaledProduct.height / 2 - labelSize.height / 2,
        };
      }
      case 'right': {
        return {
          x: scaledProduct.width + 10,
          y: scaledProduct.height / 2 - labelSize.height / 2,
        };
      }
    }
  }, [
    anchor,
    labelSize.height,
    labelSize.width,
    product.height,
    product.width,
  ]);

  useEffect(() => {
    if (labelRef.current) {
      setLabelSize({
        width: labelRef.current.width(),
        height: labelRef.current.height(),
      });
    }
  }, []);

  return <SizeLabel ref={labelRef} value={value} {...position} />;
});

GasketLabel.displayName = 'GasketLabel';

export default GasketLabel;
