import Konva from 'konva';
import map from 'lodash/map';
import { DEFAULT_RATIO } from '../modules/creator/store/config';
import {
  IndentPosition,
  ProjectDimensions,
} from '../services/api/models/project';
import {
  EdgeType,
  IndentCorners,
  IndentCornersType,
  IndentEdgeType,
  RectCorners,
  RectCornersType,
} from '../types';
import { forEach, merge, transform } from 'lodash';
import { fixValue } from '../modules/creator/utils/fix';
import Big from 'big.js';
import {
  HorizontalPositioning,
  VerticalPositioning,
} from '../pages/Project/containers/PropertyPanel/components/DimensionsInputs';
import { Position } from '../modules/creator/types';
//TODO: A lot of methods are duplicated in src/modules/creator/utils/geometry

interface IndentParams {
  corners: IndentCorners;
  indent: IndentPosition;
}

interface RectParams {
  corners: RectCorners;
  indent?: undefined;
}

export interface BigPosition {
  x: Big;
  y: Big;
}

type MapCoordinatesParams = RectParams | IndentParams;

export const pointsDistance = (A: [number, number], B: [number, number]) => {
  const distance = Math.sqrt(
    Math.pow(B[0] - A[0], 2) + Math.pow(B[1] - A[1], 2),
  );
  return fixValue(distance);
};

export const sectionAngle = (A: [number, number], B: [number, number]) => {
  const angle = Math.atan2(B[1] - A[1], B[0] - A[0]) * (180 / Math.PI);

  return fixValue(angle);
};

const calculateCrossProduct = (points: number[][]) => {
  const X1 = points[1][0] - points[0][0];
  const Y1 = points[1][1] - points[0][1];
  const X2 = points[2][0] - points[0][0];
  const Y2 = points[2][1] - points[0][1];

  return X1 * Y2 - Y1 * X2;
};

export const isShapeConvex = (points: number[][]) => {
  let prev = 0;
  let curr = 0;

  for (let i = 0; i < points.length; i++) {
    const temp = [
      points[i],
      points[(i + 1) % points.length],
      points[(i + 2) % points.length],
    ];

    curr = calculateCrossProduct(temp);

    if (curr != 0) {
      if (curr * prev < 0) {
        return false;
      } else {
        prev = curr;
      }
    }
  }
  return true;
};

export const getCoordinatesForRect = (
  width: number,
  height: number,
  shapePosition?: Konva.Vector2d,
): RectCorners => {
  const position = shapePosition || { x: 0, y: 0 };

  return {
    'top-left': [position.x, position.y],
    'top-right': [position.x + width, position.y],
    'bottom-left': [position.x, position.y + height],
    'bottom-right': [position.x + width, position.y + height],
  };
};

export const getCoordinatesForIndent = (
  width: number,
  height: number,
  position: Konva.Vector2d,
  indent: IndentPosition,
): IndentCorners => {
  const topLeft = [position.x, position.y] as [number, number];
  const topRight = [position.x + width, position.y] as [number, number];
  const bottomLeft = [position.x, position.y + height] as [number, number];
  const bottomRight = [position.x + width, position.y + height] as [
    number,
    number,
  ];
  const center = [position.x + width / 2, position.y + height / 2] as [
    number,
    number,
  ];
  const centerLeft = [position.x, position.y + height / 2] as [number, number];
  const centerTop = [position.x + width / 2, position.y] as [number, number];
  const centerRight = [position.x + width, position.y + height / 2] as [
    number,
    number,
  ];
  const centerBottom = [position.x + width / 2, position.y + height] as [
    number,
    number,
  ];

  switch (indent) {
    case IndentPosition.TOP_LEFT:
      return {
        'top-left': centerTop,
        'top-right': topRight,
        'bottom-left': bottomLeft,
        'bottom-right': bottomRight,
        center: center,
        'center-left': centerLeft,
        'center-right': undefined,
      };
    case IndentPosition.TOP_RIGHT:
      return {
        'top-left': topLeft,
        'top-right': centerTop,
        'bottom-left': bottomLeft,
        'bottom-right': bottomRight,
        center: center,
        'center-right': centerRight,
        'center-left': undefined,
      };
    case IndentPosition.BOTTOM_LEFT:
      return {
        'top-left': topLeft,
        'top-right': topRight,
        'bottom-left': centerBottom,
        'bottom-right': bottomRight,
        center: center,
        'center-left': centerLeft,
        'center-right': undefined,
      };
    case IndentPosition.BOTTOM_RIGHT:
      return {
        'top-left': topLeft,
        'top-right': topRight,
        'bottom-left': bottomLeft,
        'bottom-right': centerBottom,
        center: center,
        'center-right': centerRight,
        'center-left': undefined,
      };
  }
};

export const mapCoordinatesToPoints = ({
  corners,
  indent,
}: MapCoordinatesParams): number[][] => {
  let sorted: number[][] = [];

  if (!indent) {
    sorted = [
      corners['top-left'],
      corners['bottom-left'],
      corners['bottom-right'],
      corners['top-right'],
    ];
  } else {
    // TS don't get that corners here is IndentCorners
    const indentCorners = corners as IndentCorners;
    switch (indent) {
      case IndentPosition.TOP_LEFT:
        sorted = [
          indentCorners['top-left'],
          indentCorners.center,
          indentCorners['center-left'] ?? [0, 0],
          indentCorners['bottom-left'],
          indentCorners['bottom-right'],
          indentCorners['top-right'],
        ];
        break;
      case IndentPosition.TOP_RIGHT:
        sorted = [
          indentCorners['top-left'],
          indentCorners['bottom-left'],
          indentCorners['bottom-right'],
          indentCorners['center-right'] ?? [0, 0],
          indentCorners['center'],
          indentCorners['top-right'],
        ];
        break;
      case IndentPosition.BOTTOM_LEFT:
        sorted = [
          indentCorners['top-left'],
          indentCorners['center-left'] ?? [0, 0],
          indentCorners['center'],
          indentCorners['bottom-left'],
          indentCorners['bottom-right'],
          indentCorners['top-right'],
        ];
        break;
      case IndentPosition.BOTTOM_RIGHT:
        sorted = [
          indentCorners['top-left'],
          indentCorners['bottom-left'],
          indentCorners['bottom-right'],
          indentCorners['center'],
          indentCorners['center-right'] ?? [0, 0],
          indentCorners['top-right'],
        ];
        break;
    }
  }

  return sorted;
};

export const linesIntersection = (
  line: [[number, number], [number, number]],
  otherLine: [[number, number], [number, number]],
) => {
  const [x1, y1] = line[0];
  const [x2, y2] = line[1];
  const [x3, y3] = otherLine[0];
  const [x4, y4] = otherLine[1];

  const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

  if (denominator === 0) {
    // Lines are parallel, no intersection
    return null;
  }

  const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denominator;
  const intersectionX = x1 - t * (x1 - x2);
  const intersectionY = y1 - t * (y1 - y2);

  return [intersectionX, intersectionY];
};

export const toVector = (point: number[]): Konva.Vector2d => {
  return { x: point[0], y: point[1] };
};

export const toPoint = (vector: Konva.Vector2d): [number, number] => {
  return [vector.x, vector.y];
};

export const toPositionBig = (position: Position): BigPosition => ({
  x: new Big(position.x),
  y: new Big(position.y),
});

export const mapPointsToVectors = (points: number[][]): Konva.Vector2d[] => {
  return points.map((point) => toVector(point));
};

export const scaleVectors = (
  vectors: [Konva.Vector2d, Konva.Vector2d],
  scale: number,
) =>
  map(vectors, (vector) => ({
    x: vector.x * scale,
    y: vector.y * scale,
  }));

export const scalePoints = (points: number[]) =>
  map(points, (pos) => pos * DEFAULT_RATIO);

export const scaleCorners = (
  corners: IndentCorners | RectCorners,
  revert = false,
  scale = 1,
) => {
  const scaled: IndentCorners = {
    'top-left': [0, 0],
    'top-right': [0, 0],
    'bottom-right': [0, 0],
    'bottom-left': [0, 0],
    center: [0, 0],
    'center-right': undefined,
    'center-left': undefined,
  };
  const scaleCombined = scale * DEFAULT_RATIO;

  forEach(corners, (item, key) => {
    if (!item) return;

    scaled[key as IndentCornersType] = [
      item[0] * (revert ? 1 / scaleCombined : scaleCombined),
      item[1] * (revert ? 1 / scaleCombined : scaleCombined),
    ];
  });

  return scaled;
};

export const scalePosition = (
  position: Konva.Vector2d,
  revert = false,
  scale = 1,
) => {
  return {
    x:
      position.x * (revert ? 1 / DEFAULT_RATIO / scale : DEFAULT_RATIO * scale),
    y:
      position.y * (revert ? 1 / DEFAULT_RATIO / scale : DEFAULT_RATIO * scale),
  };
};

export const mapCoordinatesToEdgesDistances = (
  dimensions: MapCoordinatesParams,
): Record<IndentEdgeType, number> =>
  transform(
    mapCoordinatesToEdges(dimensions),
    (result, value, key) => {
      result[key as IndentEdgeType] = pointsDistance(value[0], value[1]);
    },
    {} as Record<IndentEdgeType, number>,
  );

export const mapCoordinatesToEdges = ({
  corners,
  indent,
}: MapCoordinatesParams): Record<
  IndentEdgeType,
  [[number, number], [number, number]]
> => {
  if (!indent) {
    return {
      top: [corners['top-left'], corners['top-right']],
      left: [corners['top-left'], corners['bottom-left']],
      bottom: [corners['bottom-left'], corners['bottom-right']],
      right: [corners['top-right'], corners['bottom-right']],
      'center-vertical': [
        [0, 0],
        [0, 0],
      ],
      'center-horizontal': [
        [0, 0],
        [0, 0],
      ],
    };
  } else {
    // TS don't get that corners here is IndentCorners
    const indentCorners = corners as IndentCorners;
    switch (indent) {
      case IndentPosition.TOP_LEFT:
        return {
          top: [corners['top-left'], corners['top-right']],
          left: [
            indentCorners['center-left'] ?? [0, 0],
            corners['bottom-left'],
          ],
          bottom: [corners['bottom-left'], corners['bottom-right']],
          right: [corners['top-right'], corners['bottom-right']],
          'center-horizontal': [
            indentCorners['center-left'] ?? [0, 0],
            indentCorners['center'],
          ],
          'center-vertical': [corners['top-left'], indentCorners['center']],
        };
      case IndentPosition.TOP_RIGHT:
        return {
          top: [corners['top-left'], corners['top-right']],
          left: [corners['top-left'], corners['bottom-left']],
          bottom: [corners['bottom-left'], corners['bottom-right']],
          right: [
            indentCorners['center-right'] ?? [0, 0],
            corners['bottom-right'],
          ],
          'center-horizontal': [
            indentCorners['center'],
            indentCorners['center-right'] ?? [0, 0],
          ],
          'center-vertical': [corners['top-right'], indentCorners['center']],
        };
      case IndentPosition.BOTTOM_LEFT:
        return {
          top: [corners['top-left'], corners['top-right']],
          left: [corners['top-left'], indentCorners['center-left'] ?? [0, 0]],
          bottom: [corners['bottom-left'], corners['bottom-right']],
          right: [corners['top-right'], corners['bottom-right']],
          'center-horizontal': [
            indentCorners['center'],
            indentCorners['center-left'] ?? [0, 0],
          ],
          'center-vertical': [corners['bottom-left'], indentCorners['center']],
        };
      case IndentPosition.BOTTOM_RIGHT:
        return {
          top: [corners['top-left'], corners['top-right']],
          left: [corners['top-left'], corners['bottom-left']],
          bottom: [corners['bottom-left'], corners['bottom-right']],
          right: [
            corners['top-right'],
            indentCorners['center-right'] ?? [0, 0],
          ],
          'center-horizontal': [
            indentCorners['center'],
            indentCorners['center-right'] ?? [0, 0],
          ],
          'center-vertical': [corners['top-right'], indentCorners['center']],
        };
    }
  }
};

export const findMinXPoint = (
  points: [Konva.Vector2d, Konva.Vector2d],
): Konva.Vector2d => {
  const [p1, p2] = points;
  return p1.x < p2.x ? p1 : p2;
};

export const findMaxXPoint = (
  points: [Konva.Vector2d, Konva.Vector2d],
): Konva.Vector2d => {
  const [p1, p2] = points;
  return p1.x > p2.x ? p1 : p2;
};

export const findMinYPoint = (
  points: [Konva.Vector2d, Konva.Vector2d],
): Konva.Vector2d => {
  const [p1, p2] = points;
  return p1.y < p2.y ? p1 : p2;
};

export const findMaxYPoint = (
  points: [Konva.Vector2d, Konva.Vector2d],
): Konva.Vector2d => {
  const [p1, p2] = points;
  return p1.y > p2.y ? p1 : p2;
};

export const isXBetween = (
  x: number,
  line: [Konva.Vector2d, Konva.Vector2d],
): boolean => {
  const [p1, p2] = line;
  return x >= Math.min(p1.x, p2.x) && x <= Math.max(p1.x, p2.x);
};

export const isYBetween = (
  y: number,
  line: [Konva.Vector2d, Konva.Vector2d],
): boolean => {
  const [p1, p2] = line;
  return y >= Math.min(p1.y, p2.y) && y <= Math.max(p1.y, p2.y);
};

export const findXOnSegment = (
  y: number,
  line: [Konva.Vector2d, Konva.Vector2d],
): number | undefined => {
  const p1 = toPositionBig(line[0]);
  const p2 = toPositionBig(line[1]);

  const x1 = p1.x;
  const y1 = p1.y;
  const x2 = p2.x;
  const y2 = p2.y;

  //((y - y1) * (x2 - x1)) / (y2 - y1) + x1
  const x = new Big(y)
    .minus(y1)
    .times(x2.minus(x1))
    .div(y2.minus(y1))
    .plus(x1)
    .toNumber();

  if (!isXBetween(x, line)) {
    return undefined;
  }

  return x;
};

export const findYOnSegment = (
  x: number,
  line: [Konva.Vector2d, Konva.Vector2d],
): number | undefined => {
  const p1 = toPositionBig(line[0]);
  const p2 = toPositionBig(line[1]);

  const x1 = p1.x;
  const y1 = p1.y;
  const x2 = p2.x;
  const y2 = p2.y;

  // ((x - x1) * (y2 - y1)) / (x2 - x1) + y1
  const y = new Big(x)
    .minus(x1)
    .times(y2.minus(y1))
    .div(x2.minus(x1))
    .plus(y1)
    .toNumber();

  if (!isYBetween(y, line)) {
    return undefined;
  }

  return y;
};

export const findXOnLine = (
  y: number,
  line: [Konva.Vector2d, Konva.Vector2d],
): number | undefined => {
  const p1 = toPositionBig(line[0]);
  const p2 = toPositionBig(line[1]);

  const x1 = p1.x;
  const y1 = p1.y;
  const x2 = p2.x;
  const y2 = p2.y;

  // ((y - y1) * (x2 - x1)) / (y2 - y1) + x1
  return new Big(y)
    .minus(y1)
    .times(x2.minus(x1))
    .div(y2.minus(y1))
    .plus(x1)
    .toNumber();
};

export const findYOnLine = (
  x: number,
  line: [Konva.Vector2d, Konva.Vector2d],
): number | undefined => {
  const p1 = toPositionBig(line[0]);
  const p2 = toPositionBig(line[1]);

  const x1 = p1.x;
  const y1 = p1.y;
  const x2 = p2.x;
  const y2 = p2.y;

  // ((x - x1) * (y2 - y1)) / (x2 - x1) + y1
  return new Big(x)
    .minus(x1)
    .times(y2.minus(y1))
    .div(x2.minus(x1))
    .plus(y1)
    .toNumber();
};

export const findPointOnSegment = (
  distance: number,
  line: [Konva.Vector2d, Konva.Vector2d],
): Konva.Vector2d => {
  const [start, end] = line;
  const segmentLength = Math.sqrt(
    Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2),
  );

  if (distance < 0) {
    throw new Error('Distance is outside the range of the segment.');
  }

  const ratio = distance / segmentLength;
  const pointX = start.x + (end.x - start.x) * ratio;
  const pointY = start.y + (end.y - start.y) * ratio;

  return { x: pointX, y: pointY };
};

const addVectors = (v1: Konva.Vector2d, v2: Konva.Vector2d): Konva.Vector2d => {
  return { x: v1.x + v2.x, y: v1.y + v2.y };
};

export const moveSegmentByVector = (
  segment: [Konva.Vector2d, Konva.Vector2d],
  vector: Konva.Vector2d,
): [Konva.Vector2d, Konva.Vector2d] => {
  return [addVectors(segment[0], vector), addVectors(segment[1], vector)];
};

export const getOppositeEdge = (edge: EdgeType): EdgeType => {
  switch (edge) {
    case 'top':
      return 'bottom';
    case 'bottom':
      return 'top';
    case 'left':
      return 'right';
    case 'right':
      return 'left';
  }
};

const getRelatedEdges = (
  edge: IndentEdgeType,
  direction: HorizontalPositioning | VerticalPositioning,
  indent: IndentPosition,
): { edge: IndentEdgeType; positive: boolean }[] => {
  const result: { edge: IndentEdgeType; positive: boolean }[] = [];
  let inwardMerged;
  let outwardMerged;

  const group_1: Record<EdgeType, any> = {
    top: { edge: 'bottom', positive: true },
    bottom: { edge: 'top', positive: true },
    left: { edge: 'center-vertical', positive: !indent.includes('LEFT') },
    right: { edge: 'center-vertical', positive: !indent.includes('RIGHT') },
  };

  const group_2: Record<EdgeType, any> = {
    top: { edge: 'center-horizontal', positive: !indent.includes('TOP') },
    bottom: { edge: 'center-horizontal', positive: !indent.includes('BOTTOM') },
    left: { edge: 'right', positive: true },
    right: { edge: 'left', positive: true },
  };

  const inward = {
    'center-horizontal': { edge: 'top', positive: !indent.includes('TOP') },
    'center-vertical': { edge: 'left', positive: !indent.includes('LEFT') },
  };

  const outward = {
    'center-horizontal': {
      edge: 'bottom',
      positive: !indent.includes('BOTTOM'),
    },
    'center-vertical': { edge: 'right', positive: !indent.includes('RIGHT') },
  };

  if (indent === IndentPosition.TOP_RIGHT) {
    inwardMerged = merge(inward, group_1);
    outwardMerged = merge(outward, group_2);
  } else {
    inwardMerged = merge(inward, group_2);
    outwardMerged = merge(outward, group_1);
  }

  if (!inwardMerged) {
    return result;
  }

  if (direction === 'LEFT' || direction === 'TOP') {
    result.push(inwardMerged[edge]);
  } else if (direction === 'RIGHT' || direction === 'BOTTOM') {
    result.push(outwardMerged[edge]);
  } else {
    result.push(inwardMerged[edge], outwardMerged[edge]);
  }

  return result;
};

export const changeShape = (
  dimensions: ProjectDimensions,
  edge: EdgeType,
  diff: number,
  direction: HorizontalPositioning | VerticalPositioning,
  lockOppositeEdge?: boolean,
): ProjectDimensions => {
  let dim = { ...dimensions };
  const reverse = direction === 'LEFT' || direction === 'TOP';

  if (direction === 'MIDDLE_H' || direction === 'MIDDLE_V') {
    dim = changeShape(
      dim,
      edge,
      diff / 2,
      direction === 'MIDDLE_H'
        ? ('LEFT' as VerticalPositioning)
        : ('TOP' as HorizontalPositioning),
      lockOppositeEdge,
    );
    dim = changeShape(
      dim,
      edge,
      diff / 2,
      direction === 'MIDDLE_H'
        ? ('RIGHT' as VerticalPositioning)
        : ('BOTTOM' as HorizontalPositioning),
      lockOppositeEdge,
    );
    return dim;
  }

  if (dimensions.indent && lockOppositeEdge) {
    const relatedEdges = getRelatedEdges(edge, direction, dimensions.indent);

    dim = changeEdgeCornersDistanceByValue(dim, edge, diff, reverse);

    relatedEdges.forEach(({ edge, positive }) => {
      dim = changeEdgeCornersDistanceByValue(
        dim,
        edge as EdgeType,
        diff * (positive ? 1 : -1),
        (positive && reverse) || (!positive && !reverse),
      );
    });

    return dim;
  }

  dim = changeEdgeCornersDistanceByValue(dim, edge, diff, reverse);

  if (lockOppositeEdge) {
    const oppositeEdge = getOppositeEdge(edge);
    dim = changeShape(dim, oppositeEdge, diff, direction, false);
  }

  return dim;
};

const mapEdgesToCorner = (
  indent?: IndentPosition,
):
  | Record<EdgeType, RectCornersType[]>
  | Record<IndentEdgeType, IndentCornersType[]>
  | undefined => {
  const rectEdgeToCornersMap: Record<EdgeType, RectCornersType[]> = {
    left: ['top-left', 'bottom-left'],
    right: ['top-right', 'bottom-right'],
    top: ['top-left', 'top-right'],
    bottom: ['bottom-left', 'bottom-right'],
  };

  if (!indent) {
    return rectEdgeToCornersMap;
  }

  if (indent === IndentPosition.TOP_RIGHT) {
    return {
      left: ['top-left', 'bottom-left'],
      right: ['center-right', 'bottom-right'],
      top: ['top-left', 'top-right'],
      bottom: ['bottom-left', 'bottom-right'],
      'center-horizontal': ['center', 'center-right'],
      'center-vertical': ['top-right', 'center'],
    };
  }

  if (indent === IndentPosition.BOTTOM_LEFT) {
    return {
      left: ['center-left', 'bottom-left'],
      right: ['top-right', 'bottom-right'],
      top: ['top-left', 'top-right'],
      bottom: ['bottom-left', 'bottom-right'],
      'center-horizontal': ['center-left', 'center'],
      'center-vertical': ['center', 'bottom-left'],
    };
  }
};

export const isShapeIndent = (
  corners: RectCorners | IndentCorners,
): corners is IndentCorners => {
  return (
    corners.hasOwnProperty('center-left') ||
    corners.hasOwnProperty('center-right')
  );
};

export const isCornerIndent = (
  corner: IndentCornersType | RectCornersType,
): corner is IndentCornersType => {
  return corner === 'center-right' || corner === 'center-left';
};

export const changeEdgeCornersDistanceByValue = (
  dimensions: ProjectDimensions,
  edge: EdgeType,
  diff: number,
  reverseDirection: boolean,
): ProjectDimensions => {
  const edgeToCornersMap = mapEdgesToCorner(dimensions.indent);

  if (!edgeToCornersMap || !edgeToCornersMap[edge]) {
    return dimensions;
  }

  let shapeStartCorner;
  let shapeEndCorner;

  const [startCorner, endCorner] = reverseDirection
    ? edgeToCornersMap[edge].reverse()
    : edgeToCornersMap[edge];

  if (isCornerIndent(startCorner) && isShapeIndent(dimensions.corners)) {
    shapeStartCorner = dimensions.corners[startCorner];
  } else if (!isCornerIndent(startCorner)) {
    shapeStartCorner = dimensions.corners[startCorner];
  }

  if (isCornerIndent(endCorner) && isShapeIndent(dimensions.corners)) {
    shapeEndCorner = dimensions.corners[endCorner];
  } else if (!isCornerIndent(endCorner)) {
    shapeEndCorner = dimensions.corners[endCorner];
  }

  if (!shapeStartCorner || !shapeEndCorner) {
    return dimensions;
  }

  const currentDistance = pointsDistance(shapeStartCorner, shapeEndCorner);

  const startVector = toVector(shapeStartCorner);
  const endVector = toVector(shapeEndCorner);

  const newDistance =
    currentDistance + diff > 100 ? currentDistance + diff : 100;

  const newCorner = findPointOnSegment(newDistance, [startVector, endVector]);

  return {
    indent: dimensions.indent,
    corners: {
      ...dimensions.corners,
      [endCorner]: [newCorner.x, newCorner.y],
    },
  } as ProjectDimensions;
};

export const changeShapeEdge = (
  dimensions: ProjectDimensions,
  edge: IndentEdgeType,
  value: number,
): ProjectDimensions => {
  const { corners } = dimensions;
  switch (edge) {
    case 'top': {
      return {
        ...dimensions,
        corners: {
          ...corners,
          'top-left': [corners['top-left'][0], fixValue(new Big(value))],
          'top-right': [corners['top-right'][0], fixValue(new Big(value))],
        },
      } as ProjectDimensions;
    }
    case 'bottom': {
      return {
        ...dimensions,
        corners: {
          ...corners,
          'bottom-left': [corners['bottom-left'][0], fixValue(new Big(value))],
          'bottom-right': [
            corners['bottom-right'][0],
            fixValue(new Big(value)),
          ],
        },
      } as ProjectDimensions;
    }
    case 'left': {
      return {
        ...dimensions,
        corners: {
          ...corners,
          'top-left': [fixValue(new Big(value)), corners['top-left'][1]],
          'bottom-left': [fixValue(new Big(value)), corners['bottom-left'][1]],
        },
      } as ProjectDimensions;
    }
    case 'right': {
      return {
        ...dimensions,
        corners: {
          ...corners,
          'top-right': [fixValue(new Big(value)), corners['top-right'][1]],
          'bottom-right': [
            fixValue(new Big(value)),
            corners['bottom-right'][1],
          ],
        },
      } as ProjectDimensions;
    }
    default: {
      return dimensions;
    }
  }
};
