import { State } from './history';

export const selectHistory = (state: State) => state.history;

export const selectGet = (state: State) => state.get;

export const selectLast = (state: State) => state.last;

export const selectInsert = (state: State) => state.insert;

export const selectClear = (state: State) => state.clear;

export const selectFirst = (state: State) => state.first;

export const selectLength = (state: State) => state.length;
