import * as React from 'react';
import { ArrowWrapper, ListItem, List as Container } from './styles';
import { Lang } from './types';
import { useMemo } from 'react';
import Item from './Item';
import { UpSm } from '../icons';

export interface Props {
  currentItem: Lang;
  items: Lang[];
  onChange(lang: Lang): void;
  onDismiss?(): void;
}

const List: React.FC<Props> = React.memo(
  ({ items, currentItem, onChange, onDismiss }) => {
    const Items = useMemo(() => {
      const languages = items
        .filter((lang) => lang.code !== currentItem.code)
        .map((lang) => (
          <ListItem key={lang.code} onClick={() => onChange(lang)}>
            <Item lang={lang} />
          </ListItem>
        ));
      languages.unshift(
        <ListItem key={currentItem.code} onClick={onDismiss}>
          <Item lang={currentItem} active />
          <ArrowWrapper active>
            <UpSm />
          </ArrowWrapper>
        </ListItem>,
      );
      return languages;
    }, [currentItem, items, onChange, onDismiss]);

    return <Container role="listbox">{Items}</Container>;
  },
);

List.displayName = 'List';

export default List;
