import * as React from 'react';
import { BigTooltip as Container } from './styles';
import { CommonProps } from './types';
import { Typography, TypographyVariants } from '../Typography';

export interface Props extends CommonProps {
  CustomComponent?: React.ReactNode;
}

const Big: React.FC<Props> = React.memo(
  ({ text, drawFrom, alwaysVisible, position, CustomComponent }) => {
    return (
      <Container
        role="tooltip"
        arrowSize="big"
        drawFrom={drawFrom}
        alwaysVisible={alwaysVisible}
        tooltipPosition={position}>
        <Typography variant={TypographyVariants.H5}>{text}</Typography>
        {CustomComponent}
      </Container>
    );
  },
);

Big.displayName = 'Big';

export default Big;
