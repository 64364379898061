import * as React from 'react';

const SvgArrowSym = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={props.transform}>
      <mask
        id="mask0_9_3201"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={props.width}
        height={props.height}>
        <rect
          x="24"
          width={props.width}
          height={props.height}
          transform="rotate(90 24 0)"
          fill={props.fill ?? '#D9D9D9'}
        />
      </mask>
      <g mask="url(#mask0_9_3201)">
        <path
          d="M7.86863 7.67636C7.57996 7.38768 7.57971 6.91972 7.86809 6.63075L11.2832 3.20857C11.6736 2.81736 12.3073 2.81703 12.6981 3.20784L16.1117 6.62139C16.4008 6.91053 16.4019 7.37898 16.1141 7.66944V7.66944C15.825 7.96121 15.3539 7.96293 15.0627 7.67326L12.7499 5.37303L12.7499 18.6269L15.0627 16.3267C15.3539 16.037 15.825 16.0387 16.1141 16.3305V16.3305C16.4019 16.621 16.4008 17.0894 16.1117 17.3786L12.6974 20.7928C12.3069 21.1834 11.6737 21.1834 11.2832 20.7928L7.86893 17.3786C7.57941 17.089 7.57727 16.6203 7.86414 16.3281V16.3281C8.15418 16.0328 8.62924 16.03 8.92275 16.3219L11.2403 18.6269L11.2403 5.3634L8.91337 7.67778C8.62426 7.96533 8.15697 7.9647 7.86863 7.67636V7.67636Z"
          fill={props.stroke ?? '#828A98'}
        />
      </g>
    </svg>
  );
};

const MemoSvgArrowSym = React.memo(SvgArrowSym);
export default MemoSvgArrowSym;
