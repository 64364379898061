import { useCallback } from 'react';
import { RootRoutes } from '../../../router/routes';
import { selectCreateFromProject, useOrderStore } from '../../../store/orders';
import { useHistory } from 'react-router-dom';
import { orderApi } from '../../../services/api/services';
import useCreateReducer, { Actions } from './useCreateReducer';
import { Project } from '../../../services/api/models/project';
import useSave from './useSave';

export interface UseCreateOrder {
  create(): Promise<void>;
  cancel(): void;
  pending: boolean;
  progress: number;
}

const useCreateOrder = (project: Project | undefined): UseCreateOrder => {
  const { push } = useHistory();
  const createOrder = useOrderStore(selectCreateFromProject);
  const [state, dispatch] = useCreateReducer();
  const { save } = useSave(project);

  const create = useCallback(async () => {
    if (typeof project !== 'undefined') {
      await save();
      dispatch({ type: Actions.START });
      await createOrder(project.id, () => push(RootRoutes.ORDERS));
      dispatch({ type: Actions.FINISH });
    }
  }, [createOrder, dispatch, project, push, save]);

  const cancel = useCallback(() => {
    orderApi.createSource.cancel();
  }, []);

  return {
    create,
    cancel,
    ...state,
  };
};

export default useCreateOrder;
