import styled, { css } from 'styled-components';
import { layout } from '../../styles';

const noEvents = css`
  pointer-events: none;
`;

export const Container = styled(layout.Layout)<{ disableEvents?: boolean }>`
  background-color: ${({ theme }) => theme.colors.grey3};
  ${({ disableEvents }) => disableEvents && noEvents};
`;

const withBox = css`
  align-items: center;
`;

export const Content = styled(layout.Column)<{ box?: boolean }>`
  flex: 1;
  position: relative;
  ${({ box }) => box && withBox};
`;
