import * as React from 'react';

const SvgDownFill = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M12 17a1.72 1.72 0 01-1.33-.64l-4.21-5.1a2.1 2.1 0 01-.26-2.21A1.76 1.76 0 017.79 8h8.42a1.76 1.76 0 011.59 1.05 2.101 2.101 0 01-.26 2.21l-4.21 5.1A1.72 1.72 0 0112 17z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgDownFill = React.memo(SvgDownFill);
export default MemoSvgDownFill;
