import styled from 'styled-components';
import { layout } from '../../../../styles';

export const Container = styled.div`
  width: fit-content;
  position: relative;
  white-space: nowrap;
`;

const MainItem = styled(layout.Row)`
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 56px;
  width: 184px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  flex-wrap: nowrap;
`;

export const Trigger = styled(MainItem)`
  > svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
    > svg {
      path {
        fill: ${({ theme }) => theme.colors.mainBlue};
      }
    }
  }
`;

export const List = styled(layout.Column)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 10px 10px rgba(39, 43, 95, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const ListHeader = styled(MainItem)`
  > svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;

export const ListItem = styled(layout.Row)`
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 10px 15px;
  cursor: pointer;
  > svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
    > svg {
      path {
        fill: ${({ theme }) => theme.colors.mainBlue};
      }
    }
  }
  &:last-of-type {
    padding-bottom: 15px;
  }
`;
