import * as React from 'react';
import {
  Container,
  Image,
  TitleRow,
  DeleteButton,
  DataItem,
  DataColumn,
  ItemsWrapper,
} from './styles';
import {
  ProductModel,
  ProductSearch,
} from '../../../../../../services/api/models/product';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { Close } from '../../../../../../components/icons';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import Big from 'big.js';

export interface Props {
  data: ProductSearch;
  onDelete(productId: number, type: 'product'): void;
  onSelect(productId: number, type: 'product'): void;
  withWidth?: boolean;
  productShapes?: ProductModel[];
}

const checkIfVertical = (shape: ProductModel): boolean =>
  shape.connections.some((connection) => connection.anchor === 'left') ||
  shape.connections.some((connection) => connection.anchor === 'right');

const countWidth = (shape: ProductModel): number =>
  checkIfVertical(shape) ? shape.height : shape.width;

const countRequiredNumber = (
  itemsWidth: number[],
  productWidth: number,
): string =>
  new Big(itemsWidth.reduce((acc, curr) => acc + curr, 0))
    .div(productWidth)
    .round(0, 3)
    .toString();

const AttachedProduct: React.FC<Props> = React.memo(
  ({ data, onDelete, withWidth, productShapes, onSelect, ...props }) => {
    const { t } = useTranslation(['project']);

    const dataItems = useMemo(() => {
      const width =
        withWidth && productShapes ? productShapes.map(countWidth) : [];
      const count = productShapes
        ? withWidth
          ? countRequiredNumber(width, data.width)
          : productShapes.length
        : 0;
      return {
        count,
        width,
      };
    }, [data.width, productShapes, withWidth]);

    return (
      <Container onClick={() => onSelect(data.id, 'product')} {...props}>
        <Image src={data.productImageUrl} />
        <DataColumn>
          <TitleRow>
            <Typography variant={TypographyVariants.H5_BOLD}>
              {data.code}
            </Typography>
            <DeleteButton
              role="button"
              onClick={() => onDelete(data.id, 'product')}>
              <Close />
            </DeleteButton>
          </TitleRow>
          <ItemsWrapper>
            {Object.entries(dataItems).map(
              ([key, value]) =>
                !!value &&
                (!Array.isArray(value) ? (
                  <DataItem key={key} aria-label={key}>
                    <Typography variant={TypographyVariants.H5}>
                      {t(`project:productsPanel.attached.${key}`)}:
                    </Typography>
                    <Typography variant={TypographyVariants.H5_BOLD}>
                      {key !== 'count'
                        ? t('units.millimeterValue', { value })
                        : t('units.piecesValue', { value })}
                    </Typography>
                  </DataItem>
                ) : (
                  value.map((val, index) => (
                    <DataItem key={`${key}+${val}+${index}`} aria-label={key}>
                      <Typography variant={TypographyVariants.H5}>
                        {t(`project:productsPanel.attached.${key}`)}{' '}
                        {value.length > 1 && index + 1}:
                      </Typography>
                      <Typography variant={TypographyVariants.H5_BOLD}>
                        {key !== 'count'
                          ? t('units.millimeterValue', { value: val })
                          : t('units.piecesValue', { value: val })}
                      </Typography>
                    </DataItem>
                  ))
                )),
            )}
          </ItemsWrapper>
        </DataColumn>
      </Container>
    );
  },
);

AttachedProduct.displayName = 'AttachedProduct';

export default AttachedProduct;
