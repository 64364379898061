import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ProjectTemplateSearch } from '../../services/api/models/project';
import { AxiosError } from 'axios';
import { FilterRequest } from '../../services/api/services/templates';
import { templatesApi } from '../../services/api/services';
import { Page } from '../../services/api/models/requests';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/templates/filter`;

export type State = {
  loading: boolean;
  filter(data?: FilterRequest): Promise<void>;
  data?: Page<ProjectTemplateSearch>;
  error?: AxiosError;
};

const filterAction = (set: SetState<State>) => (payload?: FilterRequest) =>
  createAsyncAction(set)(async () => {
    const { data } = await templatesApi.filterProjectTemplates(payload ?? {});
    set({ data });
  });

const store = (set: SetState<State>) => ({
  loading: false,
  filter: filterAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
