import * as React from 'react';
import {
  BoxContainer,
  BoxOption,
  BoxLabel,
  BoxCustomOption,
  CustomOptionWrapper,
} from './styles';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Typography, TypographyVariants } from '../Typography';
import { Checkbox } from '../Checkbox';
import { useTranslation } from 'react-i18next';
import { Add } from '../icons';
import { useEventListener } from '../../hooks';

export interface Option {
  title: string;
  value: string;
}

export interface Props {
  options: Option[];
  onSelect(option: Option[]): void;
  label?: string;
  multi?: boolean;
  allowCustom?: boolean;
  customOption?: Option;
  closeAction?(): void;
  selected?: Option[];
  optionMinWidth?: string;
}

const ESCAPE_KEY = 'Escape';

const SelectBox: React.FC<Props> = React.memo(
  ({
    options,
    onSelect,
    label,
    multi,
    allowCustom,
    customOption,
    closeAction,
    optionMinWidth,
    selected = [],
  }) => {
    const boxContainerRef = useRef<HTMLDivElement>(null);
    const [boxContainerHeight, setBoxContainerHeight] = useState<number>();
    const { t } = useTranslation('components');

    const closeOnKey = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === ESCAPE_KEY) {
          closeAction && closeAction();
        }
      },
      [closeAction],
    );

    useEventListener<KeyboardEvent>('keydown', closeOnKey);

    const selectedValues = useMemo(
      () => selected.map((option) => option.value),
      [selected],
    );

    const handleSelect = useCallback(
      (newOption: Option, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (multi) {
          let updated: Option[] = [];
          if (selectedValues.includes(newOption.value)) {
            updated = selected.filter(
              (option) => option.value !== newOption.value,
            );
          } else {
            updated = [...selected, newOption];
          }
          onSelect(updated);
        } else {
          onSelect([newOption]);
        }
      },
      [multi, onSelect, selected, selectedValues],
    );

    const handleCustomSelect = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (customOption) {
          if (multi) {
            onSelect([...selected, { ...customOption }]);
          } else {
            onSelect([customOption]);
          }
        }
      },
      [customOption, multi, onSelect, selected],
    );

    const Items = useMemo(
      () =>
        options.map((option) => (
          <BoxOption
            role="option"
            key={`${option.title}-${option.value}`}
            onClick={(e) => handleSelect(option, e)}
            minWidth={optionMinWidth}>
            {multi && (
              <Checkbox
                name={option.title}
                value={option.value}
                checked={selectedValues.includes(option.value)}
              />
            )}
            <Typography variant={TypographyVariants.H5}>
              {option.title}
            </Typography>
          </BoxOption>
        )),
      [handleSelect, multi, optionMinWidth, options, selectedValues],
    );

    React.useEffect(() => {
      const boxContainer = boxContainerRef.current?.getBoundingClientRect();

      if (boxContainer) {
        const boxOverflow = Math.floor(
          boxContainer.bottom - window.innerHeight,
        );

        boxOverflow > 0 &&
          setBoxContainerHeight(boxContainer?.height - boxOverflow);
      }
    }, []);

    return (
      <BoxContainer
        role="listbox"
        ref={boxContainerRef}
        boxContainerHeight={boxContainerHeight}>
        {label && (
          <BoxLabel>
            <Typography variant={TypographyVariants.H4}>{label}</Typography>
          </BoxLabel>
        )}
        {Items}
        {allowCustom && customOption && (
          <BoxCustomOption>
            <CustomOptionWrapper role="button" onClick={handleCustomSelect}>
              <Add />
              <Typography variant={TypographyVariants.H5}>
                {t('inputs.useCustomSearch', { phrase: customOption.title })}
              </Typography>
            </CustomOptionWrapper>
          </BoxCustomOption>
        )}
      </BoxContainer>
    );
  },
);

SelectBox.displayName = 'SelectBox';

export default SelectBox;
