import { ProjectDimensions } from '../../services/api/models/project';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import {
  IndentCorners,
  Position,
  RectCorners,
  RectCornersType,
} from '../../types';
import {
  isShapeConvex,
  mapCoordinatesToEdges,
  mapCoordinatesToEdgesDistances,
  mapPointsToVectors,
  pointsDistance,
  scaleCorners,
  toVector,
} from '../../utils/shape';
import { fixValue } from '../creator/utils/fix';
import { Axis } from '../creator/utils/shapes';
import { findAngle } from '../../utils/glass';
import keysIn from 'lodash/keysIn';
import Flatten from '@flatten-js/core';
import { Ring } from 'polygon-clipping';

export interface EdgeVertical {
  points: {
    top: Position;
    bottom: Position;
  };
  value: number;
  length: number;
}

export interface EdgeHorizontal {
  points: {
    left: Position;
    right: Position;
  };
  value: number;
  length: number;
}

interface ExtremePoint {
  point: Position;
  key: RectCornersType;
}

export interface ExtremePoints {
  left: ExtremePoint;
  right: ExtremePoint;
  top: ExtremePoint;
  bottom: ExtremePoint;
}

interface IShape {
  corners: ProjectDimensions['corners'];
  indent: ProjectDimensions['indent'];
  dimensions: ProjectDimensions;
  leftEdge: EdgeVertical;
  rightEdge: EdgeVertical;
  topEdge: EdgeHorizontal;
  bottomEdge: EdgeHorizontal;
  extremePoints: ExtremePoints;
  isRectangular: boolean;
  width: number;
  height: number;
  cornerAngles: Record<RectCornersType, number> | undefined;
  isConvex: boolean;
  splitShape(): IShape;
  transformByVector(move: Position): void;
  removePositionFromShape(): void;
  removePositionFromStart(): void;
  shapeCollision(shape: IShape, axis: Axis): boolean;
  roundCorners(digits?: number): void;
  isPolygonSelfIntersecting(): boolean;
  isEdgesGreaterThan(value: number): boolean;
}

export class Shape implements IShape {
  public corners: ProjectDimensions['corners'];
  public readonly indent: ProjectDimensions['indent'];
  public dimensions: ProjectDimensions;

  public position: Position = { x: 0, y: 0 };

  public constructor(dimensions: ProjectDimensions, position?: Position) {
    if (!dimensions.indent) {
      this.corners = {
        'top-left': dimensions.corners['top-left'],
        'top-right': dimensions.corners['top-right'],
        'bottom-left': dimensions.corners['bottom-left'],
        'bottom-right': dimensions.corners['bottom-right'],
      };
    } else {
      this.corners = dimensions.corners;
    }

    this.indent = dimensions.indent;
    this.dimensions = dimensions;

    if (position) {
      this.transformByVector(position);
    }
  }

  get width() {
    return fixValue(
      this.findRightCorner(this.corners) - this.findLeftCorner(this.corners),
    );
  }

  get height() {
    return fixValue(
      this.findBottomCorner(this.corners) - this.findTopCorner(this.corners),
    );
  }

  get isRectangular() {
    return (
      this.topEdge.points.left.y === this.topEdge.points.right.y &&
      this.topEdge.points.left.x === this.bottomEdge.points.left.x &&
      this.bottomEdge.points.right.y === this.bottomEdge.points.left.y &&
      this.bottomEdge.points.right.x === this.topEdge.points.right.x
    );
  }

  get leftEdge() {
    return {
      points: {
        top: toVector(this.corners['top-left']),
        bottom: toVector(this.corners['bottom-left']),
      },
      value: this.findLeftCorner(this.corners),
      length: pointsDistance(
        this.corners['top-left'],
        this.corners['bottom-left'],
      ),
    };
  }

  protected toPolygon = () => {
    return new Flatten.Polygon(this.spaceToCorners(this.corners));
  };

  protected spaceToCorners = (
    shapeCorners: RectCorners | IndentCorners,
  ): Ring[] => {
    const isIndentCorner = (
      corners: RectCorners | IndentCorners,
    ): corners is IndentCorners => {
      return corners.hasOwnProperty('center');
    };
    let corners = [
      [this.corners['bottom-left'][0], this.corners['bottom-left'][1]],
      [this.corners['top-left'][0], this.corners['top-left'][1]],
      [this.corners['top-right'][0], this.corners['top-right'][1]],
      [this.corners['bottom-right'][0], this.corners['bottom-right'][1]],
    ] as Ring;

    if (isIndentCorner(shapeCorners)) {
      if (!!shapeCorners['center-right']) {
        corners = [
          [this.corners['bottom-left'][0], this.corners['bottom-left'][1]],
          [this.corners['top-left'][0], this.corners['top-left'][1]],
          [this.corners['top-right'][0], this.corners['top-right'][1]],
          [shapeCorners['center'][0], shapeCorners['center'][1]],
          [shapeCorners['center-right'][0], shapeCorners['center-right'][1]],
          [this.corners['bottom-right'][0], this.corners['bottom-right'][1]],
        ] as Ring;
      } else if (!!shapeCorners['center-left']) {
        corners = [
          [shapeCorners['center-left'][0], shapeCorners['center-left'][1]],
          [this.corners['top-left'][0], this.corners['top-left'][1]],
          [this.corners['top-right'][0], this.corners['top-right'][1]],
          [this.corners['bottom-right'][0], this.corners['bottom-right'][1]],
          [this.corners['bottom-left'][0], this.corners['bottom-left'][1]],
          [shapeCorners['center'][0], shapeCorners['center'][1]],
        ] as Ring;
      }
    }

    return [corners];
  };

  public get polygonCentroid() {
    const polygon = this.toPolygon();
    const vertices = polygon.vertices;
    const numVertices = vertices.length;
    let centroidX = 0;
    let centroidY = 0;
    const area = polygon.area();

    for (let i = 0; i < numVertices; i++) {
      const nextIndex = (i + 1) % numVertices;
      const xi = vertices[i].x;
      const yi = vertices[i].y;
      const xiNext = vertices[nextIndex].x;
      const yiNext = vertices[nextIndex].y;
      const commonTerm = xi * yiNext - xiNext * yi;
      centroidX += (xi + xiNext) * commonTerm;
      centroidY += (yi + yiNext) * commonTerm;
    }

    // https://en.wikipedia.org/wiki/Centroid#Of_a_polygon
    centroidX /= 6 * area;
    centroidY /= 6 * area;

    return new Flatten.Point(centroidX, centroidY);
  }

  get rightEdge() {
    const corners = this.dimensions.corners;
    let topRight = corners['top-right'];

    if (
      this.dimensions.indent &&
      this.dimensions.corners['center-right'] &&
      this.dimensions.corners['center-right'][0] >
        this.dimensions.corners['top-right'][0]
    ) {
      topRight = this.dimensions.corners['center-right'];
    }

    return {
      points: {
        top: toVector(topRight),
        bottom: toVector(corners['bottom-right']),
      },
      value: this.findRightCorner(corners),
      length: pointsDistance(topRight, corners['bottom-right']),
    };
  }

  get topEdge() {
    return {
      points: {
        left: toVector(this.corners['top-left']),
        right: toVector(this.corners['top-right']),
      },
      value: this.findTopCorner(this.corners),
      length: pointsDistance(
        this.corners['top-left'],
        this.corners['top-right'],
      ),
    };
  }

  get bottomEdge() {
    return {
      points: {
        left: toVector(this.corners['bottom-left']),
        right: toVector(this.corners['bottom-right']),
      },
      value: this.findBottomCorner(this.corners),
      length: pointsDistance(
        this.corners['bottom-left'],
        this.corners['bottom-right'],
      ),
    };
  }

  get extremePoints() {
    const result = {
      left: {
        point: { x: Infinity, y: Infinity },
        key: 'top-left' as RectCornersType,
      },
      right: {
        point: { x: -Infinity, y: -Infinity },
        key: 'top-left' as RectCornersType,
      },
      top: {
        point: { x: Infinity, y: Infinity },
        key: 'top-left' as RectCornersType,
      },
      bottom: {
        point: { x: -Infinity, y: -Infinity },
        key: 'top-left' as RectCornersType,
      },
    };

    forEach(this.corners, (corner, key) => {
      const Key = key as RectCornersType;

      if (!corner) return;

      if (result.left.point.x > corner[0]) {
        result.left = { point: toVector(corner), key: Key };
      }

      if (result.top.point.y > corner[1]) {
        result.top = { point: toVector(corner), key: Key };
      }

      if (result.right.point.x < corner[0]) {
        result.right = { point: toVector(corner), key: Key };
      }

      if (result.bottom.point.y < corner[1]) {
        result.bottom = { point: toVector(corner), key: Key };
      }
    });

    return result;
  }

  get cornerAngles() {
    if (this.dimensions.indent) {
      return undefined;
    }

    const result: Record<RectCornersType, number> = {
      'top-left': 0,
      'top-right': 0,
      'bottom-left': 0,
      'bottom-right': 0,
    };

    const cornerKeys = keysIn(this.corners);

    const getPrevKey = (key: string) => {
      const index = cornerKeys.indexOf(key);
      return cornerKeys[index - 1] || cornerKeys[cornerKeys.length - 1];
    };

    const getNextKey = (key: string) => {
      const index = cornerKeys.indexOf(key);
      return cornerKeys[index + 1] || cornerKeys[0];
    };

    forEach(cornerKeys, (key) => {
      const Key = key as RectCornersType;

      const prevKey = getPrevKey(Key) as RectCornersType;
      const nextKey = getNextKey(Key) as RectCornersType;

      const prevCorner = this.corners[prevKey];
      const corner = this.corners[Key];
      const nextCorner = this.corners[nextKey];

      if (!prevCorner || !nextCorner) return;

      result[Key] = findAngle(
        toVector(prevCorner),
        toVector(corner),
        toVector(nextCorner),
      );
    });

    return result;
  }

  get isConvex(): boolean {
    const points = [
      this.corners['top-left'],
      this.corners['top-right'],
      this.corners['bottom-right'],
      this.corners['bottom-left'],
    ];

    return isShapeConvex(points);
  }

  private findLeftCorner(corners: ProjectDimensions['corners']) {
    const xArray = filter(
      map(corners, (corner) => (corner ? corner[0] : null)),
      (item) => item !== null,
    ) as number[];
    return Math.min(...xArray);
  }

  private findRightCorner(corners: ProjectDimensions['corners']) {
    const xArray = filter(
      map(corners, (corner) => (corner ? corner[0] : null)),
      (item) => item !== null,
    ) as number[];
    return Math.max(...xArray);
  }

  private findBottomCorner(corners: ProjectDimensions['corners']) {
    const yArray = filter(
      map(corners, (corner) => (corner ? corner[1] : null)),
      (item) => item !== null,
    ) as number[];
    return Math.max(...yArray);
  }

  private findTopCorner(corners: ProjectDimensions['corners']) {
    const yArray = filter(
      map(corners, (corner) => (corner ? corner[1] : null)),
      (item) => item !== null,
    ) as number[];
    return Math.min(...yArray);
  }

  public splitShape() {
    this.corners['top-right'] = [
      this.corners['top-left'][0] -
        (this.corners['top-left'][0] - this.corners['top-right'][0]) / 2,
      this.corners['top-left'][1] -
        (this.corners['top-left'][1] - this.corners['top-right'][1]) / 2,
    ];
    this.corners['bottom-right'] = [
      this.corners['bottom-left'][0] -
        (this.corners['bottom-left'][0] - this.corners['bottom-right'][0]) / 2,
      this.corners['bottom-left'][1] -
        (this.corners['bottom-left'][1] - this.corners['bottom-right'][1]) / 2,
    ];

    return this;
  }

  public transformByVector = (move: Position) => {
    const transformed: RectCorners = {
      'top-left': [0, 0],
      'top-right': [0, 0],
      'bottom-right': [0, 0],
      'bottom-left': [0, 0],
    };

    forEach(this.corners, (item, key) => {
      if (!item) return;

      transformed[key as RectCornersType] = [
        item[0] + move.x,
        item[1] + move.y,
      ];
    });

    this.corners = transformed;
    this.dimensions = {
      ...this.dimensions,
      corners: transformed,
    } as ProjectDimensions;
    this.position = move;
  };

  public scaleShape = (scale: number) => {
    const transformed: RectCorners = {
      'top-left': [0, 0],
      'top-right': [0, 0],
      'bottom-right': [0, 0],
      'bottom-left': [0, 0],
    };

    forEach(this.corners, (item, key) => {
      if (!item) return;

      transformed[key as RectCornersType] = [item[0] * scale, item[1] * scale];
    });

    this.corners = transformed;
    this.dimensions = {
      ...this.dimensions,
      corners: transformed,
    } as ProjectDimensions;
    this.position = {
      x: this.position.x * scale,
      y: this.position.y * scale,
    };
  };

  public removePositionFromShape = () => {
    const position = this.position;
    const transformed: RectCorners = {
      'top-left': [0, 0],
      'top-right': [0, 0],
      'bottom-right': [0, 0],
      'bottom-left': [0, 0],
    };

    forEach(this.corners, (item, key) => {
      if (!item) return;

      transformed[key as RectCornersType] = [
        item[0] - position.x,
        item[1] - position.y,
      ];
    });

    this.corners = transformed;
    this.position = { x: 0, y: 0 };
  };

  public removePositionFromStart = () => {
    const transformed: RectCorners = {
      'top-left': [0, 0],
      'top-right': [0, 0],
      'bottom-right': [0, 0],
      'bottom-left': [0, 0],
    };

    const startPoint = this.corners['top-left'];

    forEach(this.corners, (item, key) => {
      if (!item) return;

      transformed[key as RectCornersType] = [
        item[0] - startPoint[0],
        item[1] - startPoint[1],
      ];
    });

    this.corners = transformed;
    this.position = { x: startPoint[0], y: startPoint[1] };
  };

  public isPointInShape(point: Position) {
    const { left, right, top, bottom } = this.extremePoints;

    return (
      point.x > left.point.x &&
      point.x < right.point.x &&
      point.y > top.point.y &&
      point.y < bottom.point.y
    );
  }

  public shapeCollision = (shape: Shape, axis: Axis): boolean => {
    const shadow1 = {
      start:
        axis === 'x'
          ? this.extremePoints.left.point.x
          : this.extremePoints.top.point.y,
      end:
        axis === 'x'
          ? this.extremePoints.right.point.x
          : this.extremePoints.bottom.point.y,
    };
    const shadow2 = {
      start:
        axis === 'x'
          ? shape.extremePoints.left.point.x
          : shape.extremePoints.top.point.y,
      end:
        axis === 'x'
          ? shape.extremePoints.right.point.x
          : shape.extremePoints.bottom.point.y,
    };

    return (
      (shadow1.start > shadow2.start && shadow1.start < shadow2.end) ||
      (shadow1.end > shadow2.start && shadow1.end < shadow2.end) ||
      (shadow2.start >= shadow1.start && shadow2.end <= shadow1.end)
    );
  };

  public roundCorners = () => {
    forEach(this.corners, (corner, key) => {
      if (!corner) return;

      this.corners[key as RectCornersType] = [
        fixValue(corner[0], 8),
        fixValue(corner[1], 8),
      ];
    });
  };

  private doLinesIntersect = (
    line1: [[number, number], [number, number]],
    line2: [[number, number], [number, number]],
  ): boolean => {
    const [start1, end1] = mapPointsToVectors(line1);
    const [start2, end2] = mapPointsToVectors(line2);

    const denominator =
      (end2.y - start2.y) * (end1.x - start1.x) -
      (end2.x - start2.x) * (end1.y - start1.y);

    if (denominator === 0) {
      return false;
    }

    const a =
      ((end2.x - start2.x) * (start1.y - start2.y) -
        (end2.y - start2.y) * (start1.x - start2.x)) /
      denominator;
    const b =
      ((end1.x - start1.x) * (start1.y - start2.y) -
        (end1.y - start1.y) * (start1.x - start2.x)) /
      denominator;

    return a > 0 && a < 1 && b > 0 && b < 1;
  };

  public isPolygonSelfIntersecting = (): boolean => {
    const lines = Object.values(mapCoordinatesToEdges(this.dimensions));

    for (let i = 0; i < lines.length; i++) {
      for (let j = 0; j < lines.length; j++) {
        if (i === j) continue;

        if (this.doLinesIntersect(lines[i], lines[j])) {
          return true;
        }
      }
    }

    return (
      this.corners['top-left'][0] > this.corners['bottom-right'][0] ||
      this.corners['top-left'][1] > this.corners['bottom-right'][1]
    );
  };

  public isEdgesGreaterThan = (value: number): boolean =>
    !Object.values(mapCoordinatesToEdgesDistances(this.dimensions)).filter(
      (distance) => distance < value && distance !== 0,
    ).length;

  public scaleCorners = (revert = false) => {
    this.corners = scaleCorners(this.corners, revert);
  };
}
