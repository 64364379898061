import { Gap } from '../../types';

export enum InsertItemTypes {
  GAP = 'GAP',
}

interface InsertItem {
  type: InsertItemTypes;
}

export interface InsertGap extends InsertItem {
  type: InsertItemTypes.GAP;
  size: Gap;
}
