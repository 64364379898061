import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import createVanilla from 'zustand/vanilla';
import { InsertGap } from './types';

export const STORE_NAME = `@store/creator/insert`;

export type InsertItem = InsertGap;

export type State = {
  item?: InsertItem;
  insertItem(item: InsertItem): void;
  clearInsert(): void;
};

const store = (set: SetState<State>, get: GetState<State>): State => ({
  insertItem: (item) => set({ item }),
  clearInsert: () => get().item && set({ item: undefined }),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
