import * as React from 'react';
import { IconButtonContainer } from './styles';

export interface Props {
  Icon: React.ReactNode;
  onClick?(): void;
  disabled?: boolean;
  testId?: string;
  margin?: string;
  red?: boolean;
}

const IconButton: React.FC<Props> = React.memo(
  ({ Icon, red, onClick, disabled, testId, margin }) => {
    return (
      <IconButtonContainer
        data-testid={testId}
        onClick={onClick}
        margin={margin}
        disabled={disabled}
        red={red}>
        {Icon}
      </IconButtonContainer>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
