import * as React from 'react';

const SvgArrowUp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={props.transform}>
      <mask
        id="mask0_9_3204"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={props.width}
        height={props.height}>
        <rect
          width={props.width}
          height={props.height}
          fill={props.fill ?? '#D9D9D9'}
        />
      </mask>
      <g mask="url(#mask0_9_3204)">
        <path
          d="M12.7052 6.70128C12.3151 6.31335 11.6849 6.31335 11.2948 6.70128L7.0154 10.9572C6.73121 11.2398 6.73121 11.6997 7.0154 11.9823V11.9823C7.29738 12.2628 7.75292 12.2628 8.0349 11.9823L11.2704 8.7646V17.2704C11.2704 17.6734 11.5971 18 12 18V18C12.4029 18 12.7296 17.6734 12.7296 17.2704V8.7646L15.9651 11.9823C16.2471 12.2628 16.7026 12.2628 16.9846 11.9823V11.9823C17.2688 11.6997 17.2688 11.2398 16.9846 10.9572L12.7052 6.70128Z"
          fill={props.stroke ?? '#828A98'}
        />
      </g>
    </svg>
  );
};

const MemoSvgArrowUp = React.memo(SvgArrowUp);
export default MemoSvgArrowUp;
