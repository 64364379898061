import * as React from 'react';
import { Item as Container } from './styles';
import { Pl, It, Ru, Uk } from '../images/flags';
import { useMemo } from 'react';
import { Typography, TypographyVariants } from '../Typography';
import { Lang } from './types';

export interface Props {
  lang: Lang;
  active?: boolean;
}

const viewBox = '0 0 24 24';

const Item: React.FC<Props> = React.memo(({ lang, active }) => {
  const Flag = useMemo(() => {
    switch (lang.code) {
      case 'it': {
        return <It viewBox={viewBox} />;
      }
      case 'ru': {
        return <Ru viewBox={viewBox} />;
      }
      case 'enGB': {
        return <Uk viewBox={viewBox} />;
      }
      default:
      case 'pl': {
        return <Pl viewBox={viewBox} />;
      }
    }
  }, [lang.code]);

  return (
    <Container role="option">
      {Flag}
      <Typography
        variant={active ? TypographyVariants.H4_BOLD : TypographyVariants.H5}>
        {lang.title}
      </Typography>
    </Container>
  );
});

Item.displayName = 'Item';

export default Item;
