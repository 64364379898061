import { State as FilterState } from './filter';
import { State as SearchState } from './search';
import { State } from './product';
import { GetProductsCommand as FilterGroups } from '../../services/api/models/product';

type CommonState = State | FilterState;

export const selectLoading = (state: CommonState) => state.loading;

export const selectError = (state: CommonState) => state.error;

export const selectData = (state: FilterState) => state.data;

export const selectFilter = (state: FilterState) => state.filter;

export const selectFilterByGroup = (state: FilterState) => state.filterByGroup;

export const selectDataByGroup = (state: FilterState) => state.dataByGroup;

export const selectChangeStatus = (state: FilterState) => state.changeStatus;

export const selectDelete = (state: FilterState) => state.delete;

export const selectDataGroup = (group: keyof FilterGroups) => (
  state: FilterState,
) => state.dataByGroup?.[group];

export const selectProduct = (state: State) => state.data;

export const selectFetch = (state: State) => state.fetch;

export const selectCreate = (state: State) => state.create;

export const selectUpdate = (state: State) => state.update;

export const selectUpdateGlassPriperty = (state: State) =>
  state.updateGlassProperty;

export const selectFetchProperties = (state: State) => state.fetchProperties;

export const selectProperties = (state: State) => state.properties;

export const selectErrorsReset = (state: State) => state.resetErrors;

export const selectSearch = (state: SearchState) => state.search;

export const selectSearchResults = (state: SearchState) => state.results;

export const selectSelectedResult = (state: SearchState) => state.selected;

export const selectSelectResult = (state: SearchState) => state.select;

export const selectDeselectResult = (state: SearchState) => state.deselect;
