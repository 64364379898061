import { useCallback, useMemo, useState } from 'react';
import { HidePw, ShowPw } from '../components/icons';
import * as React from 'react';

export enum PwInputType {
  PW = 'password',
  TEXT = 'text',
}

export interface UsePasswordInput {
  toggleShow?(): void;
  inputType: PwInputType;
  Icon: React.ReactNode;
}

const usePasswordInput = (): UsePasswordInput => {
  const [inputType, setInputType] = useState<PwInputType>(PwInputType.PW);

  const Icon = useMemo(
    () => (inputType === PwInputType.TEXT ? <HidePw /> : <ShowPw />),
    [inputType],
  );

  const toggleShow = useCallback(() => {
    if (inputType === PwInputType.PW) {
      return setInputType(PwInputType.TEXT);
    }
    return setInputType(PwInputType.PW);
  }, [inputType]);

  return {
    toggleShow,
    inputType,
    Icon,
  };
};

export default usePasswordInput;
