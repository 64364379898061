import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled.div``;

export const InputRow = styled(layout.Row)`
  align-items: flex-start;
  justify-content: space-between;
  > span {
    color: ${({ theme }) => theme.colors.dark};
    margin-top: 14px;
  }
`;

export const InputWrapper = styled(layout.Row)`
  > div:first-child {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
`;
