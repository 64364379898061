import * as React from 'react';
import { Label, Text, Tag } from 'react-konva';
import Konva from 'konva';
import { selectTheme, useConfigStore } from '../../store/config';

export interface Props extends Konva.LabelConfig {
  text: string;
}

const GlassLabel: React.FC<Props> = React.memo(
  React.forwardRef<Konva.Label, Props>(({ text, ...props }, ref) => {
    const theme = useConfigStore(selectTheme);

    return (
      <Label {...props} ref={ref}>
        <Tag fill={theme.colors.white} cornerRadius={4} />
        <Text
          text={text}
          padding={4}
          fill={theme.colors.mainBlue3}
          fontSize={12}
        />
      </Label>
    );
  }),
);

GlassLabel.displayName = 'GlassLabel';

export default GlassLabel;
