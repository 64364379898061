import createVanilla from 'zustand/vanilla';
import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ProjectSearch } from '../../services/api/models/project';
import { AxiosError } from 'axios';
import { projectApi } from '../../services/api/services';
import { Page } from '../../services/api/models/requests';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/projects/recent`;

export type State = {
  loading: boolean;
  data?: Page<ProjectSearch>;
  error?: AxiosError;
  clear(): void;
  fetch(size?: number): Promise<void>;
};

const fetchAction = (set: SetState<State>) => (size = 10) =>
  createAsyncAction(set)(async () => {
    const { data } = await projectApi.filterProjects({ pageable: { size } });
    set({ data });
  });

const store = (set: SetState<State>) => ({
  loading: false,
  fetch: fetchAction(set),
  clear: () => set({ data: undefined, error: undefined }),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
