import * as React from 'react';
import { useRef } from 'react';
import { Container, Column, ButtonWrapper } from './styles';
import { BottomModal } from '../../../../components/Modal';
import { Progress } from '../../../../components/Progress';
import { Typography } from '../../../../components/Typography';
import { Button, ButtonTypes } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export interface Props {
  show: boolean;
  onCancel(): void;
}

const SavingModal: React.FC<Props> = React.memo(({ show, onCancel }) => {
  const { t } = useTranslation('project');
  const [progress, setProgress] = useState(0);

  const interval = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    if (show && !interval.current) {
      let intProgress = 0;
      interval.current = setInterval(() => {
        if (intProgress < 99) {
          intProgress += 10;
          setProgress(intProgress);
        }
      }, 200);
    }
    return () => clearInterval(interval.current);
  }, [show]);

  return (
    <BottomModal
      show={show}
      title={t('modals.saving.title')}
      timeout={0}
      fullWidth>
      <Container>
        <Column>
          <Progress current={progress} />
          <Typography>{t('modals.saving.description')}</Typography>
        </Column>
        <ButtonWrapper>
          <Button
            title={t('modals.cancel')}
            onClick={onCancel}
            type={ButtonTypes.SECONDARY}
            noMinWidth
          />
        </ButtonWrapper>
      </Container>
    </BottomModal>
  );
});

SavingModal.displayName = 'SavingModal';

export default SavingModal;
