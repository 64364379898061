import * as React from 'react';
import { Breadcrumb as Container } from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { RightBg } from '../icons';
import { Breadcrumb as BreadcrumbType } from './types';
import { useHistory } from 'react-router-dom';

export interface Props {
  data: BreadcrumbType;
  root?: boolean;
  last?: boolean;
}

const Breadcrumb: React.FC<Props> = React.memo(({ data, root, last }) => {
  const { push } = useHistory();

  return (
    <Container root={root}>
      <Typography
        variant={TypographyVariants.H5}
        disabled={last}
        onClick={() => push(data.pathname)}>
        {data.title}
      </Typography>
      {!last && <RightBg data-testid="breadcrumb-icon" />}
    </Container>
  );
});

Breadcrumb.displayName = 'Breadcrumb';

export default Breadcrumb;
