import { useCallback } from 'react';
import * as React from 'react';
import { ProductSearch } from '../../../services/api/models/product';
import { NotificationVariants } from '../../../components/Notification';
import { useCreator } from './index';
import { useTranslation } from 'react-i18next';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../store/notifications';

export interface UseDragActions {
  handleDragStart(
    e: React.DragEvent,
    data: ProductSearch,
    dragId?: string | number,
    dragImage?: HTMLDivElement,
  ): void;
  handleDrag(e: React.DragEvent): void;
  handleDragEnd(e: React.DragEvent): void;
  dragging?: boolean;
  dragId?: number | string;
}

const useDragActions = (): UseDragActions => {
  const { t } = useTranslation('project');
  const setNotification = useNotificationsStore(selectSetNotification);
  const { onDragStart, onDragEnd, onDrag, dragId, dragging } = useCreator();

  const handleDragStart = useCallback(
    (
      e: React.DragEvent,
      data: ProductSearch,
      dragId?: string | number,
      dragImage?: HTMLDivElement,
    ) => {
      onDragStart(e, data, dragId, dragImage);
      setNotification({
        variant: NotificationVariants.INFO,
        text: t('notifications.dropProduct'),
      });
    },
    [onDragStart, setNotification, t],
  );

  return {
    handleDragStart,
    handleDrag: onDrag,
    handleDragEnd: onDragEnd,
    dragging,
    dragId,
  };
};

export default useDragActions;
