import * as React from 'react';
import { List as Container, Option } from './styles';
import { DropdownOption } from './types';
import { Typography, TypographyVariants } from '../Typography';
import { useMemo } from 'react';
import { UpSm } from '../icons';

export interface Props {
  options: DropdownOption[];
  selected: DropdownOption;
  onSelect(option: DropdownOption): void;
}

const List: React.FC<Props> = React.memo(({ options, selected, onSelect }) => {
  const sorted = useMemo(() => {
    const items = options.filter((option) => option !== selected);
    items.unshift(selected);
    return items;
  }, [options, selected]);

  return (
    <Container role="listbox" aria-label="dropdown-list">
      {sorted.map((option) => (
        <Option
          key={option.value}
          role="option"
          onClick={() => onSelect(option)}
          aria-label={option.title}
          isSelected={option === selected}>
          <Typography variant={TypographyVariants.H5}>
            {option.title}
          </Typography>
          <UpSm />
        </Option>
      ))}
    </Container>
  );
});

List.displayName = 'List';

export default List;
