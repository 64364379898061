import * as React from 'react';

const SvgDiagonalHatch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <pattern
          id="diagonal-hatch_svg__a"
          width={0.8}
          height={1}
          patternTransform="rotate(45)"
          patternUnits="userSpaceOnUse">
          <path stroke="#000" strokeWidth={0.3} d="M0 0v10" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#diagonal-hatch_svg__a)" />
    </svg>
  );
};

const MemoSvgDiagonalHatch = React.memo(SvgDiagonalHatch);
export default MemoSvgDiagonalHatch;
