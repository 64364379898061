import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../store/notifications';
import { useTranslation } from 'react-i18next';
import { Glass } from '../services/api/models/glass';
import { NotificationVariants } from '../components/Notification';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  selectDeselect,
  selectSelected,
  ShapeType,
  useSelectStore,
} from '../modules/creator/store/select';
import {
  selectPasteGlass,
  selectPasteProduct,
  selectProjectGlasses,
  selectRedo,
  selectUndo,
  selectUsedProducts,
  useProjectStore,
} from '../modules/creator/store/project';
import {
  CopyItem,
  selectCopied,
  selectCopy,
  selectCut,
  selectPaste,
  selectValidGlassGlass,
  selectValidPaste,
  selectValidPosition,
  selectValidSpace,
  useCopyStore,
} from '../modules/creator/store/copy';
import {
  selectProducts as selectCreatorProducts,
  useProductsStore,
} from '../modules/creator/store/products';
import { ProductModel } from '../services/api/models/product';
import { Position } from '../services/api/models/position';
import { translateToCanvasPosition } from '../modules/creator/utils/position';
import {
  selectSetSubject,
  useConnectStore,
} from '../modules/creator/store/connect';
import useDelete from './useDelete';
import { Connection } from '../services/api/models/connection';
import { DEFAULT_RATIO } from '../modules/creator/store/config';

export interface DisabledActions {
  copy: boolean;
  cut: boolean;
  paste: boolean;
  undo: boolean;
  redo: boolean;
  delete: boolean;
}

export interface UseEditActions {
  disabled: DisabledActions;
  copy(): void;
  cut(): void;
  paste(): void;
  undo(): void;
  redo(): void;
  delete(): void;
  connect(): void;
}

export interface ActionContext {
  type: ShapeType;
  itemId: number | string;
  position?: Position;
  isGlassGlass?: boolean;
}

const useEditActions = (context?: ActionContext): UseEditActions => {
  const selected = useSelectStore(selectSelected);
  const deselect = useSelectStore(selectDeselect);
  const pasteGlassAction = useProjectStore(selectPasteGlass);
  const glasses = useProjectStore(selectProjectGlasses);
  const pasteProductAction = useProjectStore(selectPasteProduct);
  const products = useProductsStore(selectCreatorProducts);
  const usedProducts = useProjectStore(selectUsedProducts);
  const undo = useProjectStore(selectUndo);
  const redo = useProjectStore(selectRedo);
  const copy = useCopyStore(selectCopy);
  const cut = useCopyStore(selectCut);
  const paste = useCopyStore(selectPaste);
  const validPaste = useCopyStore(selectValidPaste);
  const validSpace = useCopyStore(selectValidSpace);
  const validPosition = useCopyStore(selectValidPosition);
  const validGlassGlass = useCopyStore(selectValidGlassGlass);
  const copied = useCopyStore(selectCopied);
  const setNotification = useNotificationsStore(selectSetNotification);
  const setConnectSubject = useConnectStore(selectSetSubject);

  const { t } = useTranslation('project');

  const { deleteTemplate, deleteProduct, deleteGlass } = useDelete();

  const onDelete = useCallback(() => {
    if (context) {
      switch (context.type) {
        case 'template': {
          return deleteTemplate();
        }
        case 'glass': {
          return deleteGlass(context.itemId as number);
        }
        case 'pipe':
        case 'product': {
          return deleteProduct(context.itemId as string);
        }
      }
    }
  }, [context, deleteGlass, deleteProduct, deleteTemplate]);

  const copyGlass = useCallback(
    (glassId: number) => {
      const item = glasses.find((glass) => glass.id === glassId);
      if (item) {
        copy({ data: item, type: 'glass' });
      }
    },
    [copy, glasses],
  );

  const copyProduct = useCallback(
    (iid: string) => {
      const item = products.find((item) => item.id === iid);
      if (item) {
        copy({ data: item.data, type: 'product' });
      }
    },
    [copy, products],
  );

  const connectProduct = useCallback(
    (iid: string) => {
      const item = products.find((item) => item.id === iid);
      if (item) {
        setConnectSubject(item);
      }
    },
    [products, setConnectSubject],
  );

  const onConnect = useCallback(() => {
    if (context) {
      switch (context.type) {
        case 'pipe': {
          connectProduct(context.itemId as string);
          break;
        }
      }
    }
  }, [connectProduct, context]);

  const onCopy = useCallback(() => {
    if (context) {
      switch (context.type) {
        case 'glass': {
          copyGlass(context.itemId as number);
          break;
        }
        case 'product': {
          copyProduct(context.itemId as string);
          break;
        }
      }
    }
  }, [context, copyGlass, copyProduct]);

  const cutGlass = useCallback(
    (glassId: number) => {
      const item = glasses.find((glass) => glass.id === glassId);
      if (item) {
        cut({ data: item, type: 'glass' });
        deselect();
      }
    },
    [cut, deselect, glasses],
  );

  const cutProduct = useCallback(
    (iid: string) => {
      const item = products.find((product) => product.id === iid);
      if (item) {
        cut({ data: item.data, type: 'product' });
        deselect();
      }
    },
    [cut, deselect, products],
  );

  const onCut = useCallback(() => {
    if (context) {
      switch (context.type) {
        case 'glass': {
          cutGlass(context.itemId as number);
          break;
        }
        case 'product': {
          cutProduct(context.itemId as string);
          break;
        }
      }
    }
  }, [context, cutGlass, cutProduct]);

  const pasteGlass = useCallback(() => {
    const valid = validPaste('template');
    if (valid) {
      const space = validSpace();

      if (space) {
        const item = paste();
        if (item) {
          const newItem = {
            ...item.data,
            position: { ...space.position },
            corners: space.corners,
          };
          pasteGlassAction(newItem as Glass);
        }
      } else {
        setNotification({
          variant: NotificationVariants.ERROR,
          text: t('notifications.glassLimit'),
        });
      }
    } else {
      setNotification({
        variant: NotificationVariants.ERROR,
        text: t('notifications.cannotPasteProduct'),
      });
    }
  }, [paste, pasteGlassAction, setNotification, t, validPaste, validSpace]);

  const selectConnection = useCallback(
    (connections: Connection[], glass: Glass) => {
      if (connections.length > 1) {
        return connections.find(
          (connection) => connection.targetId === String(glass.id),
        );
      }
      return connections[0];
    },
    [],
  );

  const pasteProduct = useCallback(
    (targetId: number | string, position?: Position) => {
      // TODO paste glass-glass product
      const valid = validPaste('glass');
      const glass = glasses.find((item) => item.id === targetId);
      if (valid && glass) {
        const item = paste();
        if (item) {
          const product = usedProducts[(item.data as ProductModel).productId];
          if (
            product?.glassProperties
              .map((glassProp) => glassProp.thickness)
              .includes(glass.thickness)
          ) {
            const connections = (item.data as ProductModel).connections.filter(
              (connect) => connect.type === 'GLASS',
            );
            const connection = selectConnection(connections, glass);
            const validGG =
              connections.length > 1 ? validGlassGlass(glass, item) : true;
            if (validGG) {
              const validPos = validPosition(
                glass,
                {
                  ...item.data,
                  position: {
                    ...(position
                      ? translateToCanvasPosition(position.x, position.y)
                      : item.data.position),
                  },
                },
                connection?.anchor,
              );
              if (validPos) {
                const newProduct: ProductModel = {
                  ...(item.data as ProductModel),
                  id: uuid(),
                  position: validPos,
                  connections: connection
                    ? [
                        { ...connection, targetId: String(targetId) },
                        ...connections.filter(
                          (item) => item.targetId !== connection.targetId,
                        ),
                      ]
                    : [],
                };
                pasteProductAction(newProduct, DEFAULT_RATIO);
              } else {
                setNotification({
                  variant: NotificationVariants.ERROR,
                  text: t('notifications.productsLimit'),
                });
              }
            } else {
              setNotification({
                variant: NotificationVariants.ERROR,
                text: t('notifications.cannotPasteGlassGlassProduct'),
              });
            }
          } else {
            setNotification({
              variant: NotificationVariants.ERROR,
              text: t('notifications.cannotDropProductBecauseThickness'),
            });
          }
        }
      } else {
        setNotification({
          variant: NotificationVariants.ERROR,
          text: t('notifications.cannotPasteGlass'),
        });
      }
    },
    [
      glasses,
      paste,
      pasteProductAction,
      setNotification,
      t,
      usedProducts,
      validPaste,
      validPosition,
      validGlassGlass,
      selectConnection,
    ],
  );

  const onPaste = useCallback(() => {
    if (context) {
      switch (context.type) {
        case 'template': {
          pasteGlass();
          break;
        }
        case 'glass': {
          pasteProduct(context.itemId as number, context.position);
          break;
        }
      }
    }
  }, [context, pasteGlass, pasteProduct]);

  useHotkeys(
    'delete',
    (e) => {
      e.preventDefault();
      deselect();
      selected.forEach((item) => {
        switch (item.type) {
          case 'glass': {
            return deleteGlass(item.shapeId as number);
          }
          case 'template': {
            return deleteTemplate();
          }
          case 'pipe':
          case 'product': {
            return deleteProduct(item.shapeId as string);
          }
        }
      });
    },
    [selected],
  );

  useHotkeys(
    'ctrl+c',
    (e) => {
      e.preventDefault();
      const [context] = selected;
      if (context && !context.isGlassGlass) {
        switch (context.type) {
          case 'glass': {
            copyGlass(context.shapeId as number);
            break;
          }
          case 'product': {
            copyProduct(context.shapeId as string);
            break;
          }
        }
      }
    },
    [selected],
  );

  useHotkeys(
    'ctrl+x',
    (e) => {
      e.preventDefault();
      const [context] = selected;
      if (context && !context.isGlassGlass) {
        switch (context.type) {
          case 'glass': {
            cutGlass(context.shapeId as number);
            break;
          }
          case 'product': {
            cutProduct(context.shapeId as string);
            break;
          }
        }
      }
    },
    [selected],
  );

  useHotkeys(
    'ctrl+v',
    (e) => {
      e.preventDefault();
      const [context] = selected;
      if (context && !context.isGlassGlass) {
        switch (context.type) {
          case 'template': {
            pasteGlass();
            break;
          }
          case 'glass': {
            pasteProduct(context.shapeId as number);
            break;
          }
        }
      }
    },
    [selected],
  );

  const disabled = useMemo<DisabledActions>(
    () => ({
      undo: false,
      redo: false,
      cut: !context || context.type === 'template' || !!context.isGlassGlass,
      copy: !context || context.type === 'template' || !!context.isGlassGlass,
      paste: !context || !copied || !!context.isGlassGlass,
      delete: !context,
    }),
    [context, copied],
  );

  return {
    disabled,
    undo,
    redo,
    copy: onCopy,
    cut: onCut,
    paste: onPaste,
    delete: onDelete,
    connect: onConnect,
  };
};

export default useEditActions;
