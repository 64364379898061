import * as React from 'react';

const SvgRightSm = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M10.5 17a1 1 0 01-.71-1.71L13.1 12 9.92 8.69a1 1 0 111.42-1.41l3.86 4a1 1 0 010 1.4l-4 4a1 1 0 01-.7.32z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgRightSm = React.memo(SvgRightSm);
export default MemoSvgRightSm;
