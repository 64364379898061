import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  position: relative;
`;

export const SelectWrapper = styled.div`
  position: absolute;
  right: 16px;
`;

export const BadgesWrapper = styled(layout.Row)`
  margin-top: 8px;
  flex-wrap: wrap;
`;

export const InputWrapper = styled.div``;
