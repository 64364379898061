import * as React from 'react';
import { useMemo, useRef } from 'react';
import Konva from 'konva';
import { Group } from 'react-konva';
import { DistanceLine } from '../DistanceLine';
import { DistanceArrow } from '../DistanceArrow';
import { SizeLabel } from '../SizeLabel';
import { fixValue } from '../../utils/fix';
import { DEFAULT_RATIO } from '../../store/config';
import {
  ARROW_PADDING,
  LABEL_HEIGHT,
  LABEL_WIDTH,
  OVERFLOW,
} from './EdgeDistance';
import { ProductModel } from '../../../../services/api/models/product';
import { Boundaries } from '../../types';
import map from 'lodash/map';
import { Vector2 } from '../../utils/vector';
import { scaleDistance } from '../../utils/distance';

interface XAxisDistanceParams {
  topPosition: boolean;
  leftPosition: boolean;
  distance: Boundaries;
  mountedEdgePoints: { left: Konva.Vector2d; right: Konva.Vector2d };
  productPosition: Konva.Vector2d;
  scaledProduct: ProductModel;
  horizontalEdgeDifferencePoints: {
    left: number;
    right: number;
  };
}

function XAxisDistance({
  topPosition,
  leftPosition,
  distance,
  mountedEdgePoints,
  productPosition,
  scaledProduct,
  horizontalEdgeDifferencePoints,
}: XAxisDistanceParams) {
  const leftLabel = useRef<Konva.Label>(null);
  const rightLabel = useRef<Konva.Label>(null);
  const gapLabel = useRef<Konva.Label>(null);

  const overflowH = leftPosition ? OVERFLOW : -OVERFLOW;
  const overflowV = topPosition ? OVERFLOW : -OVERFLOW;

  const arrowPaddingH = topPosition ? ARROW_PADDING : -ARROW_PADDING;

  const gapEdgePoints = leftPosition
    ? mountedEdgePoints.left
    : mountedEdgePoints.right;

  const scaledDistance = scaleDistance(distance, 1 / DEFAULT_RATIO);

  const horizontalDistance = useMemo(
    () => (topPosition ? scaledDistance.top : -scaledDistance.bottom),
    [scaledDistance.top, scaledDistance.bottom, topPosition],
  );

  const productLeftOverflowed = useMemo(
    () => horizontalEdgeDifferencePoints.left + mountedEdgePoints.right.y,
    [mountedEdgePoints.right.y, horizontalEdgeDifferencePoints.left],
  );

  const productRightOverflowed = useMemo(
    () => horizontalEdgeDifferencePoints.right + mountedEdgePoints.right.y,
    [mountedEdgePoints.right.y, horizontalEdgeDifferencePoints.right],
  );

  const productCenter = useMemo(
    () => ({
      x: scaledProduct.width,
      y: scaledProduct.height,
    }),
    [scaledProduct.width, scaledProduct.height],
  );

  const overflowVector = useMemo(() => {
    const left = new Vector2([
      mountedEdgePoints.left.x,
      mountedEdgePoints.left.y,
    ]);
    const right = new Vector2([
      mountedEdgePoints.right.x,
      mountedEdgePoints.right.y,
    ]);

    const directionVector = right.subtract(left);

    // Normalize the direction vector
    const normalizedDirection = directionVector.normalize().copy();

    // Calculate the perpendicular vector to the line (for width and height)
    const perpendicularVector = new Vector2([
      normalizedDirection.y,
      -normalizedDirection.x,
    ]).copy();

    return {
      x: overflowV * perpendicularVector.x.toNumber(),
      y: overflowV * perpendicularVector.y.toNumber(),
    };
  }, [
    mountedEdgePoints.left.x,
    mountedEdgePoints.left.y,
    mountedEdgePoints.right.x,
    mountedEdgePoints.right.y,
    overflowV,
  ]);

  const inlineOverflow = useMemo(() => {
    const left = new Vector2([
      mountedEdgePoints.left.x,
      mountedEdgePoints.left.y,
    ]);
    const right = new Vector2([
      mountedEdgePoints.right.x,
      mountedEdgePoints.right.y,
    ]);

    const directionVector = right.subtract(left);

    // Normalize the direction vector
    const normalizedDirection = directionVector.normalize().copy();

    return {
      x: -overflowH * normalizedDirection.x.toNumber(),
      y: -overflowH * normalizedDirection.y.toNumber(),
    };
  }, [
    mountedEdgePoints.left.x,
    mountedEdgePoints.left.y,
    mountedEdgePoints.right.x,
    mountedEdgePoints.right.y,
    overflowH,
  ]);

  const leftDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: mountedEdgePoints.left.x,
      startY: mountedEdgePoints.left.y,
      endX: mountedEdgePoints.left.x + overflowVector.x,
      endY: mountedEdgePoints.left.y + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    mountedEdgePoints.left.x,
    mountedEdgePoints.left.y,
    overflowVector.x,
    overflowVector.y,
  ]);

  const leftDistanceArrowPoints = useMemo(() => {
    const positioning = {
      startX: mountedEdgePoints.left.x + ARROW_PADDING + overflowVector.x,
      startY: mountedEdgePoints.left.y + overflowVector.y,
      endX: productPosition.x - ARROW_PADDING + overflowVector.x,
      endY: productLeftOverflowed + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    mountedEdgePoints.left.x,
    mountedEdgePoints.left.y,
    overflowVector.x,
    overflowVector.y,
    productPosition.x,
    productCenter.x,
    productLeftOverflowed,
  ]);

  const centerLeftDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: productPosition.x,
      startY: productLeftOverflowed,
      endX: productPosition.x + overflowVector.x,
      endY: productLeftOverflowed + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    productPosition.x,
    productCenter.x,
    productLeftOverflowed,
    overflowVector.x,
    overflowVector.y,
  ]);

  const centerRightDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: productPosition.x + productCenter.x,
      startY: productRightOverflowed,
      endX: productPosition.x + overflowVector.x + productCenter.x,
      endY: productRightOverflowed + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    productPosition.x,
    productCenter.x,
    productRightOverflowed,
    overflowVector.x,
    overflowVector.y,
  ]);

  const rightDistanceArrowPoints = useMemo(() => {
    const positioning = {
      startX: mountedEdgePoints.right.x - ARROW_PADDING + overflowVector.x,
      startY: mountedEdgePoints.right.y + overflowVector.y,
      endX:
        productPosition.x + productCenter.x + ARROW_PADDING + overflowVector.x,
      endY: productRightOverflowed + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    mountedEdgePoints.right.x,
    mountedEdgePoints.right.y,
    overflowVector.x,
    overflowVector.y,
    productPosition.x,
    productCenter.x,
    productRightOverflowed,
  ]);

  const rightDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: mountedEdgePoints.right.x,
      startY: mountedEdgePoints.right.y,
      endX: mountedEdgePoints.right.x + overflowVector.x,
      endY: mountedEdgePoints.right.y + overflowVector.y,
    };

    return map(positioning, (value) => value);
  }, [
    mountedEdgePoints.right.x,
    mountedEdgePoints.right.y,
    overflowVector.x,
    overflowVector.y,
  ]);

  const leftLabelPosition = useMemo(() => {
    const padding = topPosition ? -5 : 5;
    const label1Height = topPosition
      ? leftLabel.current?.height() ?? LABEL_HEIGHT
      : 0;
    const label1Width = leftLabel.current?.width() ?? LABEL_WIDTH;
    const middleLeftOverflow =
      (mountedEdgePoints.left.y + productLeftOverflowed) * 0.5 +
      overflowVector.y;

    return {
      x: mountedEdgePoints.left.x + scaledDistance.left / 2 - label1Width / 2,
      y: middleLeftOverflow - label1Height + padding,
    };
  }, [
    leftLabel.current,
    mountedEdgePoints.left.y,
    mountedEdgePoints.left.x,
    overflowVector.y,
    productLeftOverflowed,
    scaledDistance.left,
    topPosition,
  ]);

  const rightLabelPosition = useMemo(() => {
    const padding = topPosition ? -5 : 5;
    const label2Height = topPosition
      ? rightLabel.current?.height() ?? LABEL_HEIGHT
      : 0;
    const label2Width = rightLabel.current?.width() ?? LABEL_WIDTH;
    const middleRightOverflow =
      (mountedEdgePoints.right.y + productRightOverflowed) * 0.5 +
      overflowVector.y;

    return {
      x:
        productPosition.x +
        productCenter.x +
        scaledDistance.right / 2 -
        label2Width / 2,
      y: middleRightOverflow - label2Height + padding,
    };
  }, [
    rightLabel.current,
    topPosition,
    mountedEdgePoints.right.y,
    productRightOverflowed,
    overflowVector.y,
    productPosition.x,
    productCenter.x,
    scaledDistance.right,
  ]);

  const gapLabelPosition = useMemo(() => {
    const padding = leftPosition ? -5 : 5;
    const label2Width =
      (gapLabel.current?.width() ?? LABEL_WIDTH) * (leftPosition ? -1 : 0);
    const label2Height = gapLabel.current?.height() ?? LABEL_HEIGHT;

    return {
      x: gapEdgePoints.x + inlineOverflow.x + label2Width + padding,
      y:
        gapEdgePoints.y +
        inlineOverflow.y +
        horizontalDistance / 2 -
        label2Height / 2,
    };
  }, [
    gapLabel.current,
    leftPosition,
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
    horizontalDistance,
  ]);

  const gapTopDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: gapEdgePoints.x,
      startY: gapEdgePoints.y,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y,
    };

    return map(positioning, (value) => value);
  }, [gapEdgePoints.x, gapEdgePoints.y, inlineOverflow.x, inlineOverflow.y]);

  const gapArrowDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: gapEdgePoints.x + inlineOverflow.x,
      startY: gapEdgePoints.y + inlineOverflow.y + arrowPaddingH,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY:
        gapEdgePoints.y + horizontalDistance + inlineOverflow.y - arrowPaddingH,
    };

    return map(positioning, (value) => value);
  }, [
    gapEdgePoints.x,
    gapEdgePoints.y,
    inlineOverflow.x,
    inlineOverflow.y,
    arrowPaddingH,
    horizontalDistance,
  ]);

  const gapBottomDistanceLinePoints = useMemo(() => {
    const positioning = {
      startX: gapEdgePoints.x,
      startY: gapEdgePoints.y + horizontalDistance,
      endX: gapEdgePoints.x + inlineOverflow.x,
      endY: gapEdgePoints.y + inlineOverflow.y + horizontalDistance,
    };

    return map(positioning, (value) => value);
  }, [
    gapEdgePoints.x,
    gapEdgePoints.y,
    horizontalDistance,
    inlineOverflow.x,
    inlineOverflow.y,
  ]);

  const rightDistanceValue = useMemo(() => {
    return Math.abs(fixValue(distance.right)).toFixed(2);
  }, [distance.right]);

  const leftDistanceValue = useMemo(() => {
    return Math.abs(fixValue(distance.left)).toFixed(2);
  }, [distance.left]);

  const gapDistanceValue = useMemo(
    () => Math.abs(topPosition ? distance.top : distance.bottom).toFixed(2),
    [distance.bottom, distance.top, topPosition],
  );

  return (
    <Group data-testid="x-axis">
      <DistanceLine points={leftDistanceLinePoints} />
      <DistanceArrow points={leftDistanceArrowPoints} />
      <SizeLabel
        ref={leftLabel}
        y={leftLabelPosition.y}
        x={leftLabelPosition.x}
        value={leftDistanceValue}
      />
      <DistanceLine points={centerLeftDistanceLinePoints} />
      <DistanceLine points={centerRightDistanceLinePoints} />
      <DistanceArrow points={rightDistanceArrowPoints} />
      <SizeLabel
        ref={rightLabel}
        y={rightLabelPosition.y}
        x={rightLabelPosition.x}
        value={rightDistanceValue}
      />
      <DistanceLine points={rightDistanceLinePoints} />
      <DistanceLine points={gapTopDistanceLinePoints} />
      <DistanceArrow points={gapArrowDistanceLinePoints} />
      <SizeLabel
        ref={gapLabel}
        y={gapLabelPosition.y}
        x={gapLabelPosition.x}
        value={gapDistanceValue}
      />
      <DistanceLine points={gapBottomDistanceLinePoints} />
    </Group>
  );
}

XAxisDistance.displayName = 'XAxisDistance';

export default XAxisDistance;
