import * as React from 'react';
import { Container, Input, Circle } from './styles';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { Typography, TypographyVariants } from '../Typography';

export interface Props {
  name: string;
  value: string;
  onChange?(value: string, name: string): void;
  checked?: boolean;
  label?: string;
  invalid?: boolean;
  disabled?: boolean;
}

const Radio: React.FC<Props> = React.memo(
  ({ name, label, value, onChange, checked, invalid, disabled }) => {
    const inputId = useMemo(() => `${name}-${value}-radio`, [name, value]);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) =>
        onChange && onChange(e.target.value, name),
      [onChange, name],
    );

    return (
      <Container disabled={disabled} role="radio">
        <Input
          id={inputId}
          name={name}
          type="radio"
          checked={checked}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          invalid={invalid}
        />
        <Circle data-testid="checkbox-circle" />
        {label && (
          <Typography variant={TypographyVariants.H5}>{label}</Typography>
        )}
      </Container>
    );
  },
);

Radio.displayName = 'Radio';

export default Radio;
