import { useCallback } from 'react';
import { useGlassNeighbors } from './useGlassNeighbors';
import {
  selectData,
  selectProjectGlasses,
  selectProjectPosition,
  selectUpdateGlass,
  selectUpdateGlassList,
  useProjectStore,
} from '../store/project';
import useGaps from './useGaps';
import { Glass, GlassDimensions } from '../../../services/api/models/glass';
import { GlassTarget } from '../store/drag';
import { NeighborType } from '../utils/neighbors';
import { Anchor } from '../types/Anchor';
import { fixValue } from '../utils/fix';
import Big from 'big.js';
import { cutGlassByValue } from '../../../utils/glass';
import { BaseType, ProductSearch } from '../../../services/api/models/product';
import { checkIfGlassGlass } from '../utils/product';

export const useUpdateGlassGap = () => {
  const { findGlassNeighbors } = useGlassNeighbors();
  const updateGlass = useProjectStore(selectUpdateGlass);
  const updateGlassList = useProjectStore(selectUpdateGlassList);
  const glasses = useProjectStore(selectProjectGlasses);
  const projectData = useProjectStore(selectData);
  const templatePosition = useProjectStore(selectProjectPosition);
  const { findTopGap, findBottomGap, findLeftGap, findRightGap } = useGaps({
    glasses,
    templatePosition,
    templateDimensions: projectData?.dimensions,
  });

  const getGlassNeighborGaps = useCallback(
    (
      glass: Glass,
      edge: GlassTarget['anchor'],
      glassToGlassGap: GlassDimensions,
      wallToGlassGap: GlassDimensions,
      updatedGlassesList?: Glass[],
    ) => {
      const edges: {
        value: [number, number];
        min: number;
        edge: 'top' | 'bottom' | 'right' | 'left';
        neighborType: NeighborType;
      }[] = [];

      if (edge === 'top' || edge === 'topLeft' || edge === 'topRight') {
        const { value, neighbor } = findTopGap(glass, updatedGlassesList);

        const min =
          neighbor === NeighborType.GLASS
            ? glassToGlassGap.top
            : wallToGlassGap.top;
        edges.push({
          min,
          value,
          edge: 'top',
          neighborType: neighbor,
        });
      }

      if (
        edge === 'bottom' ||
        edge === 'bottomRight' ||
        edge === 'bottomLeft'
      ) {
        const { value, neighbor } = findBottomGap(glass, updatedGlassesList);
        const min =
          neighbor === NeighborType.GLASS
            ? glassToGlassGap.bottom
            : wallToGlassGap.bottom;
        edges.push({
          min,
          value,
          edge: 'bottom',
          neighborType: neighbor,
        });
      }

      if (edge === 'left' || edge === 'topLeft' || edge === 'bottomLeft') {
        const { value, neighbor } = findLeftGap(glass, updatedGlassesList);
        const min =
          neighbor === NeighborType.GLASS
            ? glassToGlassGap.left
            : wallToGlassGap.left;

        edges.push({
          min,
          value,
          edge: 'left',
          neighborType: neighbor,
        });
      }

      if (edge === 'right' || edge === 'topRight' || edge === 'bottomRight') {
        const { value, neighbor } = findRightGap(glass, updatedGlassesList);
        const min =
          neighbor === NeighborType.GLASS
            ? glassToGlassGap.right
            : wallToGlassGap.right;

        edges.push({
          min,
          value,
          edge: 'right',
          neighborType: neighbor,
        });
      }

      return edges;
    },
    [findRightGap, findBottomGap, findLeftGap, findTopGap],
  );

  const updateGlassGap4Glasses = useCallback(
    (glass: Glass, edge: Anchor, glassToGlassGap: GlassDimensions) => {
      const closestNeighbors = findGlassNeighbors(glass);
      let originalTarget = glass;
      const { value: topGap } = findTopGap(glass);
      const { value: rightGap } = findRightGap(glass);
      const { value: bottomGap } = findBottomGap(glass);
      const { value: leftGap } = findLeftGap(glass);
      const minTop = fixValue(new Big(glassToGlassGap.top).div(2));
      const minRight = fixValue(new Big(glassToGlassGap.right).div(2));
      const minBottom = fixValue(new Big(glassToGlassGap.bottom).div(2));
      const minLeft = fixValue(new Big(glassToGlassGap.left).div(2));

      const cutOriginalTop = () =>
        cutGlassByValue(originalTarget, topGap, minTop, 'top');
      const cutOriginalBottom = () =>
        cutGlassByValue(originalTarget, bottomGap, minBottom, 'bottom');
      const cutOriginalRight = () =>
        cutGlassByValue(originalTarget, rightGap, minRight, 'right');
      const cutOriginalLeft = () =>
        cutGlassByValue(originalTarget, leftGap, minLeft, 'left');
      const cutTopNeighborBottomEdge = (neighbor: Glass) =>
        cutGlassByValue(neighbor, topGap, minTop, 'bottom');
      const cutBottomNeighborTopEdge = (neighbor: Glass) =>
        cutGlassByValue(neighbor, bottomGap, minBottom, 'top');
      const cutVerticalNeighborRightEdge = (neighbor: Glass) =>
        cutGlassByValue(neighbor, rightGap, minRight, 'right');
      const cutVerticalNeighborLeftEdge = (neighbor: Glass) =>
        cutGlassByValue(neighbor, leftGap, minLeft, 'left');
      const cutHorizontalNeighborTopEdge = (neighbor: Glass) =>
        cutGlassByValue(neighbor, topGap, minTop, 'top');
      const cutHorizontalNeighborBottomEdge = (neighbor: Glass) =>
        cutGlassByValue(neighbor, bottomGap, minBottom, 'bottom');
      const cutRightNeighborLeftEdge = (neighbor: Glass) =>
        cutGlassByValue(neighbor, rightGap, minRight, 'left');
      const cutLeftNeighborRightEdge = (neighbor: Glass) =>
        cutGlassByValue(neighbor, leftGap, minLeft, 'right');
      if (edge === 'topRight') {
        originalTarget = cutOriginalTop();
        originalTarget = cutOriginalRight();
        updateGlass(originalTarget, true);
        if (closestNeighbors.top) {
          closestNeighbors.top = cutTopNeighborBottomEdge(closestNeighbors.top);
          closestNeighbors.top = cutVerticalNeighborRightEdge(
            closestNeighbors.top,
          );
          updateGlass(closestNeighbors.top, true);
        }
        if (closestNeighbors.right) {
          closestNeighbors.right = cutHorizontalNeighborTopEdge(
            closestNeighbors.right,
          );
          closestNeighbors.right = cutRightNeighborLeftEdge(
            closestNeighbors.right,
          );
          updateGlass(closestNeighbors.right, true);
        }
        if (closestNeighbors.topRight) {
          closestNeighbors.topRight = cutTopNeighborBottomEdge(
            closestNeighbors.topRight,
          );
          closestNeighbors.topRight = cutRightNeighborLeftEdge(
            closestNeighbors.topRight,
          );
          updateGlass(closestNeighbors.topRight, true);
        }
      }
      if (edge === 'bottomRight') {
        originalTarget = cutOriginalBottom();
        originalTarget = cutOriginalRight();
        updateGlass(originalTarget, true);
        if (closestNeighbors.bottom) {
          closestNeighbors.bottom = cutBottomNeighborTopEdge(
            closestNeighbors.bottom,
          );
          closestNeighbors.bottom = cutVerticalNeighborRightEdge(
            closestNeighbors.bottom,
          );
          updateGlass(closestNeighbors.bottom, true);
        }
        if (closestNeighbors.right) {
          closestNeighbors.right = cutHorizontalNeighborBottomEdge(
            closestNeighbors.right,
          );
          closestNeighbors.right = cutRightNeighborLeftEdge(
            closestNeighbors.right,
          );
          updateGlass(closestNeighbors.right, true);
        }
        if (closestNeighbors.bottomRight) {
          closestNeighbors.bottomRight = cutBottomNeighborTopEdge(
            closestNeighbors.bottomRight,
          );
          closestNeighbors.bottomRight = cutRightNeighborLeftEdge(
            closestNeighbors.bottomRight,
          );
          updateGlass(closestNeighbors.bottomRight, true);
        }
      }
      if (edge === 'topLeft') {
        originalTarget = cutOriginalTop();
        originalTarget = cutOriginalLeft();
        updateGlass(originalTarget, true);
        if (closestNeighbors.top) {
          closestNeighbors.top = cutTopNeighborBottomEdge(closestNeighbors.top);
          closestNeighbors.top = cutVerticalNeighborLeftEdge(
            closestNeighbors.top,
          );
          updateGlass(closestNeighbors.top, true);
        }
        if (closestNeighbors.left) {
          closestNeighbors.left = cutHorizontalNeighborTopEdge(
            closestNeighbors.left,
          );
          closestNeighbors.left = cutLeftNeighborRightEdge(
            closestNeighbors.left,
          );
          updateGlass(closestNeighbors.left, true);
        }
        if (closestNeighbors.topLeft) {
          closestNeighbors.topLeft = cutTopNeighborBottomEdge(
            closestNeighbors.topLeft,
          );
          closestNeighbors.topLeft = cutLeftNeighborRightEdge(
            closestNeighbors.topLeft,
          );
          updateGlass(closestNeighbors.topLeft, true);
        }
      }
      if (edge === 'bottomLeft') {
        originalTarget = cutOriginalBottom();
        originalTarget = cutOriginalLeft();
        updateGlass(originalTarget, true);
        if (closestNeighbors.bottom) {
          closestNeighbors.bottom = cutBottomNeighborTopEdge(
            closestNeighbors.bottom,
          );
          closestNeighbors.bottom = cutVerticalNeighborLeftEdge(
            closestNeighbors.bottom,
          );
          updateGlass(closestNeighbors.bottom, true);
        }
        if (closestNeighbors.left) {
          closestNeighbors.left = cutHorizontalNeighborBottomEdge(
            closestNeighbors.left,
          );
          closestNeighbors.left = cutLeftNeighborRightEdge(
            closestNeighbors.left,
          );
          updateGlass(closestNeighbors.left, true);
        }
        if (closestNeighbors.bottomLeft) {
          closestNeighbors.bottomLeft = cutBottomNeighborTopEdge(
            closestNeighbors.bottomLeft,
          );
          closestNeighbors.bottomLeft = cutLeftNeighborRightEdge(
            closestNeighbors.bottomLeft,
          );
          updateGlass(closestNeighbors.bottomLeft, true);
        }
      }
      return originalTarget;
    },
    [
      findBottomGap,
      findLeftGap,
      findRightGap,
      findTopGap,
      findGlassNeighbors,
      updateGlass,
    ],
  );

  const updateGlassGap = useCallback(
    ({
      edge,
      targetGlass,
      productBaseType,
      glassToGlassGap,
      wallToGlassGap,
      updatedGlassesList,
    }: {
      edge: GlassTarget['anchor'];
      targetGlass: Glass;
      productBaseType: BaseType;
      glassToGlassGap: GlassDimensions;
      wallToGlassGap: GlassDimensions;
      updatedGlassesList: Glass[] | undefined;
    }) => {
      let glass = targetGlass;
      const fillGap =
        productBaseType !== BaseType.CONNECTOR_WALL &&
        productBaseType !== BaseType.CONNECTOR_GLASS &&
        productBaseType !== BaseType.GLASS_GLASS_90 &&
        productBaseType !== BaseType.GLASS_GLASS_180;
      const edges = getGlassNeighborGaps(
        targetGlass,
        edge,
        glassToGlassGap,
        wallToGlassGap,
        updatedGlassesList,
      );

      edges.forEach((edge) => {
        glass = cutGlassByValue(
          glass,
          edge.value,
          edge.min,
          edge.edge,
          fillGap,
        );
      });

      updateGlassList(
        updatedGlassesList ? [glass, ...updatedGlassesList] : [glass],
        true,
      );

      return glass;
    },
    [getGlassNeighborGaps, updateGlassList],
  );

  const reverseNeighborMinGaps = useCallback(
    (glassToGlassGap: GlassDimensions) => ({
      top: glassToGlassGap.bottom,
      right: glassToGlassGap.left,
      bottom: glassToGlassGap.top,
      left: glassToGlassGap.right,
    }),
    [],
  );

  const updateGlassNeighborsGap = useCallback(
    ({
      edge,
      targetGlass,
      productBaseType,
      productMountTypes,
      glassToGlassGap,
      wallToGlassGap,
    }: {
      edge: GlassTarget['anchor'];
      targetGlass: Glass;
      productBaseType: BaseType;
      productMountTypes: ProductSearch['mountTypes'];
      glassToGlassGap: GlassDimensions;
      wallToGlassGap: GlassDimensions;
    }) => {
      const fillGap =
        productBaseType !== BaseType.CONNECTOR_WALL &&
        productBaseType !== BaseType.CONNECTOR_GLASS &&
        productBaseType !== BaseType.GLASS_GLASS_90 &&
        productBaseType !== BaseType.GLASS_GLASS_180;
      const closestNeighbors: Record<
        Anchor,
        Glass | undefined
      > = findGlassNeighbors(targetGlass);
      const edges = getGlassNeighborGaps(
        targetGlass,
        edge,
        reverseNeighborMinGaps(glassToGlassGap),
        wallToGlassGap,
      );

      if (!checkIfGlassGlass(productBaseType, productMountTypes)) {
        return undefined;
      }

      const updatedGlass: Glass[] = [];

      edges.forEach((neighbor) => {
        if (neighbor.neighborType !== NeighborType.GLASS) {
          return;
        }

        switch (neighbor.edge) {
          case 'top': {
            if (closestNeighbors.top) {
              updatedGlass.push(
                cutGlassByValue(
                  closestNeighbors.top,
                  neighbor.value,
                  neighbor.min,
                  'bottom',
                  fillGap,
                ),
              );
            }

            break;
          }

          case 'bottom': {
            if (closestNeighbors.bottom) {
              updatedGlass.push(
                cutGlassByValue(
                  closestNeighbors.bottom,
                  neighbor.value,
                  neighbor.min,
                  'top',
                  fillGap,
                ),
              );
            }

            break;
          }

          case 'right': {
            if (closestNeighbors.right) {
              updatedGlass.push(
                cutGlassByValue(
                  closestNeighbors.right,
                  neighbor.value,
                  neighbor.min,
                  'left',
                  fillGap,
                ),
              );
            }

            break;
          }

          case 'left': {
            if (closestNeighbors.left) {
              updatedGlass.push(
                cutGlassByValue(
                  closestNeighbors.left,
                  neighbor.value,
                  neighbor.min,
                  'right',
                  fillGap,
                ),
              );
            }

            break;
          }
        }
      });

      return updatedGlass.length ? updatedGlass : undefined;
    },
    [
      updateGlass,
      findGlassNeighbors,
      getGlassNeighborGaps,
      glasses,
      reverseNeighborMinGaps,
    ],
  );

  return {
    updateGlassGap4Glasses,
    updateGlassGap,
    updateGlassNeighborsGap,
  };
};
