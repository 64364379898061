import { useCallback, useEffect, useState } from 'react';
import {
  Modals,
  selectClose,
  selectOpen,
  useModalsStore,
} from '../../../store/modals';
import {
  selectSetValidatedHistoryPointer,
  selectUsedProducts,
  useProjectStore,
} from '../../../modules/creator/store/project';
import {
  selectProducts,
  useProductsStore,
} from '../../../modules/creator/store/products';
import filter from 'lodash/filter';
import { BaseType } from '../../../services/api/models/product';
import reduce from 'lodash/reduce';
import { Glass, WingDimensions } from '../../../services/api/models/glass';
import { calculateGlassWeight } from '../../../utils/glass';
import { Shape } from '../../../modules/space';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../store/notifications';
import { NotificationVariants } from '../../../components/Notification';
import { fixValue } from '../../../modules/creator/utils/fix';
import { useTranslation } from 'react-i18next';
import { toLower, uniqBy } from 'lodash';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';

interface ValidationItem {
  code: string;
  glass: Glass;
  glassValidation: {
    maxWingWeight: number;
    maxWingDimensions: WingDimensions;
    minWingDimensions: WingDimensions;
  };
  isHinge: boolean;
  correspondingProducts: number;
}

export interface ProductsErrors {
  code: string;
  errors: string[];
}

const useRevalidateProject = (
  validateProject: boolean,
  toggleValidateProject: () => void,
  historyPointer: number,
) => {
  const { t: creatorT } = useTranslation('creator');
  const { t: projectT } = useTranslation('project');

  const openModal = useModalsStore(selectOpen);
  const closeModal = useModalsStore(selectClose);
  const setValidatedPointer = useProjectStore(selectSetValidatedHistoryPointer);
  const projectProducts = useProductsStore(selectProducts);
  const usedProduct = useProjectStore(selectUsedProducts);
  const setNotification = useNotificationsStore(selectSetNotification);

  const [projectErrors, setProjectErrors] = useState<ProductsErrors[]>([]);

  const mapProductsToValidate = useCallback(() => {
    const result: ValidationItem[] = [];

    return reduce(
      projectProducts,
      (result, product) => {
        if (product.parentType !== 'glass') {
          return result;
        }

        const catalogProduct = usedProduct[product.data.productId];
        const glassProperties = catalogProduct.glassProperties.find(
          (item) => item.thickness === product.parent.thickness,
        );
        const isHinge =
          catalogProduct.baseType === BaseType.HINGE ||
          catalogProduct.baseType === BaseType.LIFTING_HINGE ||
          catalogProduct.baseType === BaseType.GLASS_GLASS_90 ||
          catalogProduct.baseType === BaseType.GLASS_GLASS_180;

        const correspondingProducts = filter(projectProducts, (item) => {
          return (
            item.parentType === 'glass' &&
            item.parent.id === product.parent.id &&
            item.data.productId === product.data.productId
          );
        }).length;

        if (
          catalogProduct &&
          ((glassProperties?.maxWingWeight &&
            glassProperties.maxWingWeight > 0) ||
            (glassProperties?.maxWingDimensions &&
              glassProperties?.maxWingDimensions.width > 0 &&
              glassProperties?.maxWingDimensions.height > 0) ||
            (glassProperties?.minWingDimensions &&
              glassProperties?.minWingDimensions.width > 0 &&
              glassProperties?.minWingDimensions.height > 0))
        ) {
          result.push({
            code: catalogProduct.code,
            glass: product.parent,
            glassValidation: {
              maxWingWeight: glassProperties?.maxWingWeight || 0,
              maxWingDimensions: glassProperties?.maxWingDimensions || {
                width: 0,
                height: 0,
              },
              minWingDimensions: glassProperties?.minWingDimensions || {
                width: 0,
                height: 0,
              },
            },
            isHinge,
            correspondingProducts,
          });
        }

        return result;
      },
      result,
    );
  }, [projectProducts, usedProduct]);

  const validateProducts = useCallback(
    (validationItems: ValidationItem[]) => {
      const errors: ProductsErrors[] = [];

      validationItems.forEach((item) => {
        let itemErrors: string[] = [];

        const glassObj = new Shape({ corners: item.glass.corners });
        const glassName = item.glass.panelType
          ? `${projectT(
              `propertyPanel.form.glass.type.options.${toLower(
                item.glass.panelType,
              )}`,
            )} ${item.glass.id}`
          : creatorT('glassLabel', { id: item.glass.id });

        if (item.glassValidation.maxWingWeight > 0) {
          const glassWeight = fixValue(calculateGlassWeight(item.glass));
          const allHingesMaxWeight =
            item.glassValidation.maxWingWeight *
            (item.correspondingProducts / 2);

          if (glassWeight > allHingesMaxWeight) {
            itemErrors.push(
              projectT('modals.validateProject.errors.weightExceeded', {
                glassName,
                weight: glassWeight,
                limit: allHingesMaxWeight,
              }),
            );
          }
        }

        if (item.glassValidation.maxWingDimensions.width > 0) {
          if (glassObj.width > item.glassValidation.maxWingDimensions.width) {
            itemErrors.push(
              projectT('modals.validateProject.errors.maxWidth', {
                glassName,
                width: glassObj.width,
                limit: item.glassValidation.maxWingDimensions.width,
              }),
            );
          }
        }

        if (item.glassValidation.maxWingDimensions.height > 0) {
          if (glassObj.height > item.glassValidation.maxWingDimensions.height) {
            itemErrors.push(
              projectT('modals.validateProject.errors.maxHeight', {
                glassName,
                height: glassObj.height,
                limit: item.glassValidation.maxWingDimensions.height,
              }),
            );
          }
        }

        if (item.glassValidation.minWingDimensions.width > 0) {
          if (glassObj.width < item.glassValidation.minWingDimensions.width) {
            itemErrors.push(
              projectT('modals.validateProject.errors.minWidth', {
                glassName,
                width: glassObj.width,
                limit: item.glassValidation.minWingDimensions.width,
              }),
            );
          }
        }

        if (item.glassValidation.minWingDimensions.height > 0) {
          if (glassObj.height < item.glassValidation.minWingDimensions.height) {
            itemErrors.push(
              projectT('modals.validateProject.errors.minHeight', {
                glassName,
                height: glassObj.height,
                limit: item.glassValidation.minWingDimensions.height,
              }),
            );
          }
        }

        if (item.isHinge && item.correspondingProducts === 1) {
          itemErrors = [
            projectT('modals.validateProject.errors.tooFewHinges', {
              glassName,
            }),
          ];
        }

        if (!!itemErrors.length) {
          const productErrors = findIndex(errors, (i) => i.code === item.code);

          if (productErrors > -1) {
            errors[productErrors].errors.push(...itemErrors);
          } else {
            errors.push({
              code: item.code,
              errors: itemErrors,
            });
          }
        }
      });

      if (!errors.length) {
        setNotification({
          variant: NotificationVariants.SUCCESS,
          text: 'Projekt poprawny',
        });
        setValidatedPointer(historyPointer);
        return;
      }

      const uniqueErrors = map(errors, (err) => {
        return { ...err, errors: uniqBy(err.errors, (e) => e) };
      });

      setProjectErrors(uniqueErrors);
      openModal(Modals.VALIDATE_PROJECT);
    },
    [historyPointer, openModal, setNotification, setValidatedPointer],
  );

  const handleRevalidateProject = useCallback(() => {
    const validationItems = mapProductsToValidate();

    if (!validationItems) {
      setValidatedPointer(historyPointer);
      return;
    }

    validateProducts(validationItems);
  }, [
    historyPointer,
    mapProductsToValidate,
    setValidatedPointer,
    validateProducts,
  ]);

  const clearState = useCallback(() => {
    setProjectErrors([]);
  }, []);

  const handleKeepEditing = useCallback(() => {
    closeModal(Modals.VALIDATE_PROJECT);

    setTimeout(clearState, 200);
  }, [closeModal]);

  const handleSaveAnyway = useCallback(() => {
    closeModal(Modals.VALIDATE_PROJECT);

    setTimeout(clearState, 200);
    setValidatedPointer(historyPointer);
  }, [closeModal, historyPointer, setValidatedPointer]);

  useEffect(() => {
    if (validateProject) {
      handleRevalidateProject();

      toggleValidateProject();
    }
  }, [validateProject]);

  return {
    projectErrors,
    handleRevalidateProject,
    handleKeepEditing,
    handleSaveAnyway,
  };
};

export default useRevalidateProject;
