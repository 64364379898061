import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Line } from 'react-konva';
import { selectScale, selectTheme, useConfigStore } from '../../store/config';
import {
  DropTarget,
  selectDragShape,
  selectSetCollision,
  selectSetDropTarget,
  selectSetInvalid,
  useDragStore,
} from '../../store/drag';
import { getInnerShape } from '../../utils/shapes';
import { Anchor } from '../../types/Anchor';
import { RectCorners } from '../../../../types';
import { mapCoordinatesToPoints } from '../../../../utils/shape';
import { Shape } from '../../../space';

export interface Props {
  anchor: Anchor;
  corners?: RectCorners;
  dropTarget: DropTarget;
  invalidThickness?: boolean;
}

const DropZone: React.FC<Props> = React.memo(
  ({ corners, invalidThickness, dropTarget, anchor }) => {
    const theme = useConfigStore(selectTheme);
    const draggedShape = useDragStore(selectDragShape);
    const setCollision = useDragStore(selectSetCollision);
    const setInvalid = useDragStore(selectSetInvalid);
    const setTarget = useDragStore(selectSetDropTarget);
    const scale = useConfigStore(selectScale);

    const styles = useMemo(
      () => ({
        success: {
          fill: theme.colors.successLight,
          stroke: theme.colors.success,
        },
        error: {
          fill: theme.colors.validationLight,
          stroke: theme.colors.validation,
        },
        default: {
          fill: theme.colors.accentBlue3,
          stroke: theme.colors.accentBlue,
        },
      }),
      [theme],
    );

    const collision = useMemo(() => {
      const innerShape = getInnerShape(draggedShape);

      if (!corners || !innerShape) {
        return false;
      }

      const shape = new Shape({ corners });

      shape.scaleShape(scale);

      return shape.isPointInShape(innerShape.position);
    }, [corners, draggedShape, scale]);

    const currentStyle = useMemo(() => {
      if (collision && invalidThickness) {
        return styles.error;
      }
      if (collision) {
        return styles.success;
      }
      return styles.default;
    }, [collision, invalidThickness, styles]);

    useEffect(() => {
      setCollision({ [anchor]: collision });
    }, [anchor, collision, setCollision]);

    useEffect(() => {
      if (collision) {
        setTarget(dropTarget);
      }
    }, [collision, dropTarget, setTarget]);

    useEffect(() => {
      setInvalid({
        invalid: collision && !!invalidThickness,
        reason:
          collision && !!invalidThickness
            ? 'cannotDropProductBecauseThickness'
            : undefined,
      });
    }, [collision, invalidThickness, setInvalid]);

    const points = useMemo(() => {
      if (!corners) {
        return null;
      }

      return mapCoordinatesToPoints({ corners }).flat();
    }, [corners]);

    if (!points) {
      return null;
    }

    return (
      <Line
        {...currentStyle}
        points={points}
        dash={[4]}
        strokeWidth={2}
        closed
        listening={false}
      />
    );
  },
);

DropZone.displayName = 'DropZone';

export default DropZone;
