import * as React from 'react';

const SvgFilters = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M3.781 8.868h7.922a3.125 3.125 0 006.031 0h1.672a.781.781 0 100-1.563h-1.672a3.125 3.125 0 00-6.03 0H3.78a.781.781 0 000 1.563zM14.72 6.524a1.562 1.562 0 110 3.125 1.562 1.562 0 010-3.125zm4.687 7.813h-7.922a3.125 3.125 0 00-6.03 0H3.78a.781.781 0 100 1.562h1.672a3.125 3.125 0 006.031 0h7.922a.781.781 0 100-1.562zM8.47 16.68a1.562 1.562 0 110-3.124 1.562 1.562 0 010 3.124z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgFilters = React.memo(SvgFilters);
export default MemoSvgFilters;
