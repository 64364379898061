import styled from 'styled-components';
import { layout } from '../../../../styles';

export const Form = styled.form`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-end;
  > div {
    margin-right: 16px;
  }
`;

export const ButtonsWrapper = styled(layout.Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 16px;
  > button {
    margin-top: 4px;
    &:first-of-type {
      margin-right: 8px;
    }
  }
`;
