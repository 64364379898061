import * as React from 'react';

const SvgOffers = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M18 5V4a2 2 0 00-2-2H8a2 2 0 00-2 2v1a3 3 0 00-3 3v11a3 3 0 003 3h12a3 3 0 003-3V8a3 3 0 00-3-3zM8 4h8v4H8V4zm11 15a1 1 0 01-1 1H6a1 1 0 01-1-1V8a1 1 0 011-1v1a2 2 0 002 2h8a2 2 0 002-2V7a1 1 0 011 1v11z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgOffers = React.memo(SvgOffers);
export default MemoSvgOffers;
