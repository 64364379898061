import * as React from 'react';
import { useMemo, useState } from 'react';
import { Line } from 'react-konva';
import { selectTheme, useConfigStore } from '../../store/config';
import { Anchor } from '../../types/Anchor';
import { RectCorners } from '../../../../types';
import { mapCoordinatesToPoints } from '../../../../utils/shape';

export interface Props {
  corners: RectCorners;
  anchor: Anchor;
  onClick(anchor: Anchor): void;
}

const InsertZone: React.FC<Props> = React.memo(
  ({ corners, onClick, anchor }) => {
    const theme = useConfigStore(selectTheme);

    const styles = useMemo(
      () => ({
        mouseOver: {
          fill: theme.colors.accentBlue4,
          stroke: theme.colors.accentBlue,
        },
        default: {
          fill: theme.colors.accentBlue3,
          stroke: theme.colors.accentBlue,
        },
      }),
      [theme],
    );

    const [currentStyle, setCurrentStyle] = useState<keyof typeof styles>(
      'default',
    );

    const handleClick = () => onClick(anchor);
    const handleMouseEnter = () => setCurrentStyle('mouseOver');
    const handleMouseLeave = () => setCurrentStyle('default');

    const points = useMemo(() => {
      if (!corners) {
        return null;
      }

      return mapCoordinatesToPoints({ corners }).flat();
    }, [corners]);

    if (!points) {
      return null;
    }

    return (
      <Line
        {...styles[currentStyle]}
        points={points}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        onTap={handleClick}
        dash={[4]}
        strokeWidth={2}
        closed
      />
    );
  },
);

InsertZone.displayName = 'InsertZone';

export default InsertZone;
