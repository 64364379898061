import styled, { css } from 'styled-components';
import { SidePanelPosition } from './types';
import { animated } from 'react-spring';

const align_left = css`
  left: 0;
  border-width: 0 1px 0 0;
`;

const align_right = css`
  right: 0;
  border-width: 0 0 0 1px;
`;

export const Container = styled(animated.div)<{
  position: SidePanelPosition;
  width: number;
  fullscreen?: string;
  fixed?: string;
}>`
  width: ${({ width }) => `${width}px`};
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.accentBlue2};
  ${({ position }) =>
    position === SidePanelPosition.RIGHT ? align_right : align_left}
  z-index: ${({ fullscreen }) => (fullscreen ? 90 : 10)};
`;

const align_trigger_left = css`
  left: 100%;
  border-width: 1px 1px 0 0;
  border-radius: 0 4px 0 0;
`;

const align_trigger_right = css`
  right: 100%;
  border-width: 1px 0 0 1px;
  border-radius: 4px 0 0 0;
`;

export const Trigger = styled.div<{ position: SidePanelPosition }>`
  position: absolute;
  bottom: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.accentBlue2};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  ${({ position }) =>
    position === SidePanelPosition.RIGHT
      ? align_trigger_right
      : align_trigger_left}
  > svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${({ theme }) => theme.colors.mainBlue};
    }
  }
  &:hover {
    > svg {
      path {
        fill: ${({ theme }) => theme.colors.accentBlue};
      }
    }
  }
`;
