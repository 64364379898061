import { DEFAULT_RATIO } from '../store/config';
import { Position, Shape } from '../types';
import { Glass } from '../../../services/api/models/glass';
import { fixValue } from './fix';
import Big from 'big.js';

export type Axis = 'x' | 'y';

export const scaleShape = <T extends Shape>(shape: T, ratio: number): T => ({
  ...shape,
  width: fixValue(new Big(shape.width).times(ratio).times(DEFAULT_RATIO)),
  height: fixValue(new Big(shape.height).times(ratio).times(DEFAULT_RATIO)),
  cutout: shape.cutout && {
    ...shape.cutout,
    size: {
      x: fixValue(new Big(shape.cutout.size.x).times(ratio)),
      y: fixValue(new Big(shape.cutout.size.y).times(ratio)),
    },
  },
});

export const scaleGlass = <T extends Shape>(shape: T, ratio: number): T => ({
  ...shape,
  width: fixValue(new Big(shape.width).times(ratio).times(DEFAULT_RATIO)),
  height: fixValue(new Big(shape.height).times(ratio).times(DEFAULT_RATIO)),
  position: {
    x: fixValue(new Big(shape.position.x).times(ratio).times(DEFAULT_RATIO), 3),
    y: fixValue(new Big(shape.position.y).times(ratio).times(DEFAULT_RATIO), 3),
  },
});

export const glassToShape = (glass: Glass): Shape => ({
  position: glass.position,
  width: glass.width,
  height: glass.height,
});

export const getInnerShape = (shape?: Shape, size = 0): Shape | undefined =>
  shape && {
    width: size,
    height: size,
    position: {
      x: new Big(shape.position.x)
        .minus(new Big(size).div(2))
        .plus(new Big(shape.width).div(2))
        .toNumber(),
      y: new Big(shape.position.y)
        .minus(new Big(size).div(2))
        .plus(new Big(shape.height).div(2))
        .toNumber(),
    },
  };

export type CornerPoints =
  | 'topLeft'
  | 'topRight'
  | 'bottomRight'
  | 'bottomLeft';
export const getCornerPoints = (
  shape: Shape,
): Record<CornerPoints, Position> => ({
  topLeft: { x: shape.position.x, y: shape.position.y },
  topRight: {
    x: fixValue(new Big(shape.position.x).plus(shape.width), 5),
    y: shape.position.y,
  },
  bottomLeft: {
    x: shape.position.x,
    y: fixValue(new Big(shape.position.y).plus(shape.height), 5),
  },
  bottomRight: {
    x: fixValue(new Big(shape.position.x).plus(shape.width), 5),
    y: fixValue(new Big(shape.position.y).plus(shape.height), 5),
  },
});
