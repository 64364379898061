import styled, { css } from 'styled-components';
import { layout } from '../../../../styles';

export const disabled_item = css`
  pointer-events: none;
  span {
    color: ${({ theme }) => theme.colors.grey2};
  }
  > div {
    background-color: ${({ theme }) => theme.colors.grey2};
    > span {
      color: ${({ theme }) => theme.colors.grey3};
    }
  }
`;

export const Container = styled(layout.Column)<{ top: number; left: number }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 10px 10px rgba(39, 43, 95, 0.08);
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: 200;
  overflow: hidden;
`;

export const ContextMenuItem = styled(layout.Row)<{ disabled?: boolean }>`
  min-width: 136px;
  width: fit-content;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  user-select: none;
  ${({ disabled }) => disabled && disabled_item}
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
  }
`;

export const HotkeyBadge = styled.div`
  display: inline-block;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.accentBlue3};
  padding: 1px 4px;
  > span {
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.accentBlue};
    text-transform: uppercase;
  }
`;
