import * as React from 'react';
import { Props as CommonProps } from './types';
import {
  MultiselectBox,
  SearchInput,
  MultiselectIcon,
  MultiselectInputWrapper,
} from './styles';
import { useCallback, useMemo, useRef } from 'react';
import { Option } from './SelectBox';
import Composer from './Composer';
import { Search } from '../icons';
import Badge from './Badge';

export interface Props extends CommonProps {
  onChange(options: Option[], name: string): void;
  selected?: Option[];
  search?: boolean;
  Icon?: React.ReactNode;
  disabled?: boolean;
}

const Multiselect: React.FC<Props> = React.memo(
  ({
    onChange,
    selected,
    Icon,
    invalid,
    search,
    name,
    options,
    disabled,
    errorMessage,
    ...props
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const onSelect = useCallback(
      (options: Option[]) => {
        onChange && onChange(options, name);
      },
      [onChange, name],
    );

    const onDeselect = useCallback(
      (toDelete: Option) => {
        if (selected) {
          const updated = selected.filter((option) => option !== toDelete);
          onChange(updated, name);
        }
      },
      [onChange, selected, name],
    );

    const SelectedItems = useMemo(
      () =>
        selected?.map((option, index) => (
          <Badge
            key={`${option.value}+${index}`}
            option={option}
            onDelete={onDeselect}
            disabled={disabled}
          />
        )),
      [disabled, onDeselect, selected],
    );

    const onBoxSelect = useCallback(
      (listTrigger: (state?: boolean) => void, unfolded?: boolean) => {
        if (!disabled) {
          !unfolded && listTrigger(true);
          inputRef?.current && inputRef.current.focus();
        }
      },
      [disabled],
    );

    return (
      <Composer
        {...props}
        options={options}
        name={name}
        selected={selected}
        onSelect={onSelect}
        multi
        invalid={invalid}
        errorMessage={errorMessage}>
        {({ inputId, i18nPlaceholder, toggleUnfold, unfolded, onSearch }) => (
          <MultiselectBox
            role="button"
            invalid={invalid}
            disabled={disabled}
            search={search}
            onClick={() => onBoxSelect(toggleUnfold, unfolded)}>
            <MultiselectInputWrapper>
              {SelectedItems}
              {search && (
                <SearchInput
                  ref={inputRef}
                  id={inputId}
                  name={name}
                  onChange={onSearch}
                  placeholder={!selected?.length ? i18nPlaceholder : undefined}
                  autoComplete="off"
                />
              )}
            </MultiselectInputWrapper>
            <MultiselectIcon disabled={disabled}>
              {Icon ?? <Search />}
            </MultiselectIcon>
          </MultiselectBox>
        )}
      </Composer>
    );
  },
);

Multiselect.displayName = 'Multiselect';

export default Multiselect;
