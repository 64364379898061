import Konva from 'konva';
import { BoundariesCopy } from '../../../modules/creator/types';

export enum ANCHOR_POINTS {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center',
  CENTER_RIGHT = 'center-right',
  CENTER_LEFT = 'center-left',
  CENTER_CENTER_RIGHT = 'center-center-right',
  CENTER_CENTER_LEFT = 'center-center-left',
  CENTER_CENTER_TOP = 'center-center-top',
  CENTER_CENTER_BOTTOM = 'center-center-bottom',
}

export interface RectMove {
  vector: Konva.Vector2d;
  id: ANCHOR_POINTS;
}

export type DisabledEdge = Partial<Record<keyof BoundariesCopy, boolean>>;
