import { DEFAULT_RATIO } from '../store/config';
import {
  Boundaries,
  BoundariesCopy,
  Position,
  Shape as ShapeType,
} from '../types';
import { Axis, scaleShape } from './shapes';
import { fixPosition, fixValue } from './fix';
import { Anchor } from '../types/Anchor';
import Big from 'big.js';
import { Glass, GlassDimensions } from '../../../services/api/models/glass';
import {
  CutoutDirection,
  ProductCutout,
} from '../../../services/api/models/product';
import { Direction } from './neighbors';
import Konva from 'konva';
import { Shape, Space } from '../../space';
import { ProjectDimensions } from '../../../services/api/models/project';
import {
  findMaxXPoint,
  findMaxYPoint,
  findMinXPoint,
  findMinYPoint,
  findXOnLine,
  findYOnLine,
  linesIntersection,
  moveSegmentByVector,
  scaleVectors,
  toPositionBig,
} from '../../../utils/shape';
import { RectCorners } from '../../../types';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import { Vector2 } from './vector';
import { GJK2D } from './collisionAlgorithm/Main';
import { Polygon as PolygonClass } from './collisionAlgorithm/shapes/Polygon';
import filter from 'lodash/filter';
import {
  dotProduct,
  findPointDistanceFromVector,
  getVectorLength,
  isPointCollinear,
  isPointInsideVector,
  isVectorCollinear,
  Vector,
} from './geometry/vectors';
import { Vector2d } from 'konva/lib/types';

type Edges = Boundaries;

const OUTER_BOUNDARY = 10000;

export enum Obstacles {
  EDGE = 'EDGE',
  OBJECT = 'OBJECT',
}

export interface Boundary {
  value: number;
  obstacle: Obstacles;
  obstacleData: Glass | undefined;
}

export interface BoundaryCopy {
  value: [Konva.Vector2d, Konva.Vector2d];
  obstacle: Obstacles;
}

export interface Data {
  shape: ShapeType;
  shapes: ShapeType[];
  parents: ShapeType[];
  parentPaddings?: Edges;
  minGapToEdge?: Partial<Edges>;
}

type Corners = {
  'top-left': [number, number];
  'top-right': [number, number];
  'bottom-left': [number, number];
  'bottom-right': [number, number];
  center: [number, number] | undefined;
  'center-left': [number, number] | undefined;
  'center-right': [number, number] | undefined;
};

export interface ShapeTypeCopy {
  position: Position;
  corners: ProjectDimensions['corners'] | Corners;
  indent?: ProjectDimensions['indent'];
  cutout?: ProductCutout;
}

export interface DataCopy {
  shape: ShapeTypeCopy;
  shapes: ShapeTypeCopy[];
  parents: ShapeTypeCopy[];
  parentPaddings?: Edges;
  minGapToEdge?: Partial<Edges>;
  scale: number;
}

export type Polygon = Vector2d[];

export const scalePaddings = (
  paddings: GlassDimensions,
  ratio: number,
): GlassDimensions => ({
  top: new Big(paddings.top).times(ratio).toNumber(),
  bottom: new Big(paddings.bottom).times(ratio).toNumber(),
  left: new Big(paddings.left).times(ratio).toNumber(),
  right: new Big(paddings.right).times(ratio).toNumber(),
});

const findParentRelatedDirection = (
  parent: Shape,
  child: Shape,
  axis: Axis,
): Direction | undefined => {
  if (axis === 'y') {
    if (
      new Big(parent.extremePoints.top.point.y).gt(
        new Big(child.extremePoints.top.point.y),
      )
    ) {
      return 'bottom';
    }
    if (
      new Big(parent.extremePoints.bottom.point.y).lt(
        new Big(child.extremePoints.bottom.point.y),
      )
    ) {
      return 'top';
    }
  }
  if (axis === 'x') {
    if (
      new Big(parent.extremePoints.left.point.x).gt(
        new Big(child.extremePoints.left.point.x),
      )
    ) {
      return 'right';
    }
    if (
      new Big(parent.extremePoints.right.point.x).lt(
        new Big(child.extremePoints.right.point.x),
      )
    ) {
      return 'left';
    }
  }
};

export const findTopBoundary = (
  data: DataCopy,
  includePadding = true,
): BoundaryCopy => {
  const { cutout } = data.shape;
  const shapeObj = new Shape(
    { corners: data.shape.corners },
    data.shape.position,
  );
  const shapesObj = data.shapes.map(
    (shape) => new Shape({ corners: shape.corners }, shape.position),
  );
  const parentsObj = data.parents.map((parent) => {
    const parentObj = new Space(
      { corners: parent.corners, indent: parent.indent } as ProjectDimensions,
      parent.position,
    );

    parentObj.addIndentToSpaceShapes();
    return parentObj;
  });

  const [lowestParent] = parentsObj.sort(
    (a, b) => b.extremePoints.top.point.y - a.extremePoints.top.point.y,
  );

  if (lowestParent.indent) {
    shapesObj.push(...lowestParent.spaceShapes);
  }

  const shapesAbove = shapesObj
    .filter((shape) => {
      const leftDistance = findPointDistanceFromVector(
        shapeObj.topEdge.points.left,
        [shape.bottomEdge.points.left, shape.bottomEdge.points.right],
      );

      const rightDistance = findPointDistanceFromVector(
        shapeObj.topEdge.points.right,
        [shape.bottomEdge.points.left, shape.bottomEdge.points.right],
      );

      return leftDistance.y >= 0 && rightDistance.y >= 0;
    })
    .sort(
      (a, b) => a.extremePoints.bottom.point.y - b.extremePoints.bottom.point.y,
    );

  const collision = shapesAbove.filter((shape) =>
    shapeObj.shapeCollision(shape, 'x'),
  );

  const lowestObject = collision.pop();

  let objectObstacleValue: Big | undefined;
  if (
    lowestObject &&
    lowestObject.extremePoints.bottom.point.y >
      lowestParent.extremePoints.top.point.y
  ) {
    objectObstacleValue = new Big(lowestObject.extremePoints.bottom.point.y);
  }

  let cutoutCorrect = 0;
  if (cutout) {
    const parentDirection = findParentRelatedDirection(
      lowestParent,
      shapeObj,
      'x',
    );
    if (
      (parentDirection === 'right' &&
        cutout.direction === CutoutDirection.TOP_RIGHT) ||
      (parentDirection === 'left' &&
        cutout.direction === CutoutDirection.TOP_LEFT)
    ) {
      cutoutCorrect = cutout.size.y;
    }
  }

  const padding = data.parentPaddings?.top ?? 0;
  const minGapToEdge = data.minGapToEdge?.top ?? 0;

  lowestParent.transformByVector({
    x: 0,
    y: -cutoutCorrect + (includePadding ? -padding : 0) + minGapToEdge,
  });

  if (
    objectObstacleValue !== undefined &&
    lowestObject !== undefined &&
    objectObstacleValue.gt(lowestParent.extremePoints.top.point.y)
  ) {
    return {
      value: scaleVectors(
        [
          fixPosition(lowestObject.bottomEdge.points.left, 8),
          fixPosition(lowestObject.bottomEdge.points.right, 8),
        ],
        data.scale,
      ) as [Konva.Vector2d, Konva.Vector2d],
      obstacle: Obstacles.OBJECT,
    };
  }

  return {
    value: scaleVectors(
      [
        fixPosition(lowestParent.topEdge.points.left, 8),
        fixPosition(lowestParent.topEdge.points.right, 8),
      ],
      data.scale,
    ) as [Konva.Vector2d, Konva.Vector2d],
    obstacle: Obstacles.EDGE,
  };
};

export const findBottomBoundary = (
  data: DataCopy,
  includePadding = true,
): BoundaryCopy => {
  const { cutout } = data.shape;
  const shapeObj = new Shape(
    { corners: data.shape.corners },
    data.shape.position,
  );
  const shapesObj = data.shapes.map(
    (shape) => new Shape({ corners: shape.corners }, shape.position),
  );
  const parentsObj = data.parents.map((parent) => {
    const parentObj = new Space(
      { corners: parent.corners, indent: parent.indent } as ProjectDimensions,
      parent.position,
    );

    parentObj.addIndentToSpaceShapes();
    return parentObj;
  });

  const [highestParent] = parentsObj.sort(
    (a, b) => a.extremePoints.bottom.point.y - b.extremePoints.bottom.point.y,
  );

  if (highestParent.indent) {
    shapesObj.push(...highestParent.spaceShapes);
  }

  const shapesBelow = shapesObj
    .filter((shape) => {
      const leftDistance = findPointDistanceFromVector(
        shapeObj.bottomEdge.points.left,
        [shape.topEdge.points.left, shape.topEdge.points.right],
      );

      const rightDistance = findPointDistanceFromVector(
        shapeObj.bottomEdge.points.right,
        [shape.topEdge.points.left, shape.topEdge.points.right],
      );

      return leftDistance.y <= 0 && rightDistance.y <= 0;
    })
    .sort((a, b) => a.extremePoints.top.point.y - b.extremePoints.top.point.y);

  const collision = shapesBelow.filter((shape) =>
    shapeObj.shapeCollision(shape, 'x'),
  );

  const highestObject = collision.shift();

  let objectObstacleValue: Big | undefined;
  if (
    highestObject &&
    highestObject.extremePoints.top.point.y <
      highestParent.extremePoints.bottom.point.y
  ) {
    objectObstacleValue = new Big(highestObject.extremePoints.top.point.y);
  }

  let cutoutCorrect = 0;
  if (cutout) {
    const parentDirection = findParentRelatedDirection(
      highestParent,
      shapeObj,
      'x',
    );
    if (
      (parentDirection === 'right' &&
        cutout.direction === CutoutDirection.BOTTOM_RIGHT) ||
      (parentDirection === 'left' &&
        cutout.direction === CutoutDirection.BOTTOM_LEFT)
    ) {
      cutoutCorrect = cutout.size.y;
    }
  }

  const padding = data.parentPaddings?.bottom ?? 0;
  const minGapToEdge = data.minGapToEdge?.bottom ?? 0;

  highestParent.transformByVector({
    x: 0,
    y: cutoutCorrect + (includePadding ? padding : 0) - minGapToEdge,
  });

  if (
    objectObstacleValue !== undefined &&
    highestObject !== undefined &&
    objectObstacleValue.lt(highestParent.extremePoints.bottom.point.y)
  ) {
    return {
      value: scaleVectors(
        [
          fixPosition(highestObject.topEdge.points.left, 8),
          fixPosition(highestObject.topEdge.points.right, 8),
        ],
        data.scale,
      ) as [Konva.Vector2d, Konva.Vector2d],
      obstacle: Obstacles.OBJECT,
    };
  }

  return {
    value: scaleVectors(
      [
        fixPosition(highestParent.bottomEdge.points.left, 8),
        fixPosition(highestParent.bottomEdge.points.right, 8),
      ],
      data.scale,
    ) as [Konva.Vector2d, Konva.Vector2d],
    obstacle: Obstacles.EDGE,
  };
};

export const findLeftBoundary = (
  data: DataCopy,
  includePadding = true,
): BoundaryCopy => {
  const { cutout } = data.shape;
  const shapeObj = new Shape(
    { corners: data.shape.corners },
    data.shape.position,
  );
  const shapesObj = data.shapes.map(
    (shape) => new Shape({ corners: shape.corners }, shape.position),
  );
  const parentsObj = data.parents.map((parent) => {
    const parentObj = new Space(
      { corners: parent.corners, indent: parent.indent } as ProjectDimensions,
      parent.position,
    );

    parentObj.addIndentToSpaceShapes();
    return parentObj;
  });

  const [rightestParent] = parentsObj.sort(
    (a, b) => b.extremePoints.right.point.x - a.extremePoints.right.point.x,
  );

  if (rightestParent.indent) {
    shapesObj.push(...rightestParent.spaceShapes);
  }

  const shapesLeft = shapesObj
    .filter((shape) => {
      const topDistance = findPointDistanceFromVector(
        shapeObj.leftEdge.points.top,
        [shape.rightEdge.points.top, shape.rightEdge.points.bottom],
      );

      const bottomDistance = findPointDistanceFromVector(
        shapeObj.leftEdge.points.bottom,
        [shape.rightEdge.points.top, shape.rightEdge.points.bottom],
      );

      return topDistance.x >= 0 && bottomDistance.x >= 0;
    })
    .sort(
      (a, b) => a.extremePoints.right.point.x - b.extremePoints.right.point.x,
    );

  const collision = shapesLeft.filter((shape) =>
    shapeObj.shapeCollision(shape, 'y'),
  );

  const rightestObject = collision.pop();

  let objectObstacleValue: Big | undefined;
  if (
    rightestObject &&
    rightestObject.extremePoints.right.point.x >
      rightestParent.extremePoints.left.point.x
  ) {
    objectObstacleValue = new Big(rightestObject.extremePoints.right.point.x);
  }

  let cutoutCorrect = 0;
  if (cutout) {
    const parentDirection = findParentRelatedDirection(
      rightestParent,
      shapeObj,
      'y',
    );
    if (
      (parentDirection === 'top' &&
        cutout.direction === CutoutDirection.TOP_LEFT) ||
      (parentDirection === 'bottom' &&
        cutout.direction === CutoutDirection.BOTTOM_LEFT)
    ) {
      cutoutCorrect = cutout.size.x;
    }
  }

  const padding = data.parentPaddings?.left ?? 0;
  const minGapToEdge = data.minGapToEdge?.left ?? 0;

  rightestParent.transformByVector({
    y: 0,
    x: -cutoutCorrect + (includePadding ? -padding : 0) + minGapToEdge,
  });

  if (
    objectObstacleValue !== undefined &&
    rightestObject !== undefined &&
    objectObstacleValue.gt(rightestParent.extremePoints.left.point.x)
  ) {
    return {
      value: scaleVectors(
        [
          fixPosition(rightestObject.rightEdge.points.top, 8),
          fixPosition(rightestObject.rightEdge.points.bottom, 8),
        ],
        data.scale,
      ) as [Konva.Vector2d, Konva.Vector2d],
      obstacle: Obstacles.OBJECT,
    };
  }

  return {
    value: scaleVectors(
      [
        fixPosition(rightestParent.leftEdge.points.top, 8),
        fixPosition(rightestParent.leftEdge.points.bottom, 8),
      ],
      data.scale,
    ) as [Konva.Vector2d, Konva.Vector2d],
    obstacle: Obstacles.EDGE,
  };
};

export const findRightBoundary = (
  data: DataCopy,
  includePadding = true,
): BoundaryCopy => {
  const { cutout } = data.shape;
  const shapeObj = new Shape(
    { corners: data.shape.corners },
    data.shape.position,
  );
  const shapesObj = data.shapes.map(
    (shape) => new Shape({ corners: shape.corners }, shape.position),
  );
  const parentsObj = data.parents.map((parent) => {
    const parentObj = new Space(
      { corners: parent.corners, indent: parent.indent } as ProjectDimensions,
      parent.position,
    );

    parentObj.addIndentToSpaceShapes();
    return parentObj;
  });

  const [leftestParent] = parentsObj.sort(
    (a, b) => a.extremePoints.left.point.x - b.extremePoints.left.point.x,
  );

  if (leftestParent.indent) {
    shapesObj.push(...leftestParent.spaceShapes);
  }

  const shapesRight = shapesObj
    .filter((shape) => {
      const topDistance = findPointDistanceFromVector(
        shapeObj.rightEdge.points.top,
        [shape.leftEdge.points.top, shape.leftEdge.points.bottom],
      );

      const bottomDistance = findPointDistanceFromVector(
        shapeObj.rightEdge.points.bottom,
        [shape.leftEdge.points.top, shape.leftEdge.points.bottom],
      );

      return topDistance.x <= 0 && bottomDistance.x <= 0;
    })
    .sort(
      (a, b) => a.extremePoints.left.point.x - b.extremePoints.left.point.x,
    );

  const collision = shapesRight.filter((shape) =>
    shapeObj.shapeCollision(shape, 'y'),
  );

  const leftestObject = collision.shift();

  let objectObstacleValue: Big | undefined;
  if (
    leftestObject &&
    leftestObject.extremePoints.left.point.x <
      leftestParent.extremePoints.right.point.x
  ) {
    objectObstacleValue = new Big(leftestObject.extremePoints.left.point.x);
  }

  let cutoutCorrect = 0;
  if (cutout) {
    const parentDirection = findParentRelatedDirection(
      leftestParent,
      shapeObj,
      'y',
    );
    if (
      (parentDirection === 'top' &&
        cutout.direction === CutoutDirection.TOP_RIGHT) ||
      (parentDirection === 'bottom' &&
        cutout.direction === CutoutDirection.BOTTOM_RIGHT)
    ) {
      cutoutCorrect = cutout.size.x;
    }
  }

  const padding = data.parentPaddings?.right ?? 0;
  const minGapToEdge = data.minGapToEdge?.right ?? 0;

  leftestParent.transformByVector({
    y: 0,
    x: cutoutCorrect + (includePadding ? padding : 0) - minGapToEdge,
  });

  if (
    objectObstacleValue !== undefined &&
    leftestObject !== undefined &&
    objectObstacleValue.lt(leftestParent.extremePoints.right.point.x)
  ) {
    return {
      value: scaleVectors(
        [
          fixPosition(leftestObject.leftEdge.points.top, 8),
          fixPosition(leftestObject.leftEdge.points.bottom, 8),
        ],
        data.scale,
      ) as [Konva.Vector2d, Konva.Vector2d],
      obstacle: Obstacles.OBJECT,
    };
  }

  return {
    value: scaleVectors(
      [
        fixPosition(leftestParent.rightEdge.points.top, 8),
        fixPosition(leftestParent.rightEdge.points.bottom, 8),
      ],
      data.scale,
    ) as [Konva.Vector2d, Konva.Vector2d],
    obstacle: Obstacles.EDGE,
  };
};

export const findBoundaries = (
  data: DataCopy,
  paddingAnchor?: Anchor,
): BoundariesCopy => {
  const { value: left } = findLeftBoundary(
    data,
    paddingAnchor === undefined || paddingAnchor === 'left',
  );

  const { value: right, obstacle } = findRightBoundary(
    data,
    paddingAnchor === undefined || paddingAnchor === 'right',
  );

  const { value: top } = findTopBoundary(
    data,
    paddingAnchor === undefined || paddingAnchor === 'top',
  );

  const { value: bottom } = findBottomBoundary(
    data,
    paddingAnchor === undefined || paddingAnchor === 'bottom',
  );

  return {
    left,
    right,
    top,
    bottom,
  };
};

const updateBoundary = (
  boundary: [Konva.Vector2d, Konva.Vector2d],
  position: Position,
): [Konva.Vector2d, Konva.Vector2d] =>
  boundary.map((point) => ({
    x: fixValue(new Big(point.x).plus(position.x), 3),
    y: fixValue(new Big(point.y).plus(position.y), 3),
  })) as [Konva.Vector2d, Konva.Vector2d];

export const correctBoundariesCopy = (
  boundaries: BoundariesCopy,
  layerPosition: Position,
): BoundariesCopy => ({
  left: updateBoundary(boundaries.left, layerPosition),
  right: updateBoundary(boundaries.right, layerPosition),
  top: updateBoundary(boundaries.top, layerPosition),
  bottom: updateBoundary(boundaries.bottom, layerPosition),
});

export const getBoundariesExtremes = (
  boundaries: BoundariesCopy,
): Boundaries => ({
  left:
    boundaries.left[0].x < boundaries.left[1].x
      ? boundaries.left[0].x
      : boundaries.left[1].x,
  right:
    boundaries.right[0].x > boundaries.right[1].x
      ? boundaries.right[0].x
      : boundaries.right[1].x,
  top:
    boundaries.top[0].y < boundaries.top[1].y
      ? boundaries.top[0].y
      : boundaries.top[1].y,
  bottom:
    boundaries.bottom[0].y > boundaries.bottom[1].y
      ? boundaries.bottom[0].y
      : boundaries.bottom[1].y,
});

export const cutBoundariesToAnchor = (
  boundaries: BoundariesCopy,
  anchor: Anchor,
  shape: ShapeType,
  isGlassGlass180?: boolean,
  gapsBetweenGlasses?: GlassDimensions,
  ratio = 1,
) => {
  const scaledShape = scaleShape(shape, ratio);
  const scaledGaps = gapsBetweenGlasses
    ? scalePaddings(gapsBetweenGlasses, ratio)
    : undefined;
  switch (anchor) {
    case 'top': {
      let bottom = moveSegmentByVector(boundaries.top, {
        x: 0,
        y: scaledShape.height,
      });
      if (isGlassGlass180) {
        const gap = new Big(scaledGaps?.top ?? 0).div(2);

        bottom = moveSegmentByVector(bottom, {
          x: 0,
          y: -new Big(scaledShape.height).div(2).plus(gap).toNumber(),
        });
      }
      return {
        ...boundaries,
        bottom,
      };
    }
    case 'bottom': {
      let top = moveSegmentByVector(boundaries.bottom, {
        x: 0,
        y: -scaledShape.height,
      });
      const gap = new Big(scaledGaps?.bottom ?? 0).div(2);

      if (isGlassGlass180) {
        top = moveSegmentByVector(top, {
          x: 0,
          y: new Big(scaledShape.height).div(2).plus(gap).toNumber(),
        });
      }
      return {
        ...boundaries,
        top,
        bottom: isGlassGlass180
          ? moveSegmentByVector(boundaries.bottom, {
              x: 0,
              y: new Big(scaledShape.height).div(2).plus(gap).toNumber(),
            })
          : boundaries.bottom,
      };
    }
    case 'left': {
      let right = moveSegmentByVector(boundaries.left, {
        x: scaledShape.width,
        y: 0,
      });
      const gap = new Big(scaledGaps?.left ?? 0).div(2);

      if (isGlassGlass180) {
        right = moveSegmentByVector(right, {
          x: -new Big(scaledShape.width).div(2).plus(gap).toNumber(),
          y: 0,
        });
      }
      return {
        ...boundaries,
        right,
        left: isGlassGlass180
          ? moveSegmentByVector(boundaries.left, {
              x: -new Big(scaledShape.width).div(2).plus(gap).toNumber(),
              y: 0,
            })
          : boundaries.left,
      };
    }
    case 'right': {
      let left = moveSegmentByVector(boundaries.right, {
        x: -scaledShape.width,
        y: 0,
      });
      const gap = new Big(scaledGaps?.right ?? 0).div(2);

      if (isGlassGlass180) {
        left = moveSegmentByVector(left, {
          x: new Big(scaledShape.width).div(2).plus(gap).toNumber(),
          y: 0,
        });
      }
      return {
        ...boundaries,
        left,
        right: isGlassGlass180
          ? moveSegmentByVector(boundaries.right, {
              x: new Big(scaledShape.width).div(2).plus(gap).toNumber(),
              y: 0,
            })
          : boundaries.right,
      };
    }
    case 'topLeft': {
      return {
        ...boundaries,
        right: moveSegmentByVector(boundaries.left, {
          x: scaledShape.width,
          y: 0,
        }),
        bottom: moveSegmentByVector(boundaries.top, {
          x: 0,
          y: scaledShape.height,
        }),
      };
    }
    case 'topRight': {
      return {
        ...boundaries,
        left: moveSegmentByVector(boundaries.right, {
          x: -scaledShape.width,
          y: 0,
        }),
        bottom: moveSegmentByVector(boundaries.top, {
          x: 0,
          y: scaledShape.height,
        }),
      };
    }
    case 'bottomLeft': {
      return {
        ...boundaries,
        right: moveSegmentByVector(boundaries.left, {
          x: scaledShape.width,
          y: 0,
        }),
        top: moveSegmentByVector(boundaries.bottom, {
          x: 0,
          y: -scaledShape.height,
        }),
      };
    }
    case 'bottomRight': {
      return {
        ...boundaries,
        left: moveSegmentByVector(boundaries.right, {
          x: -scaledShape.width,
          y: 0,
        }),
        top: moveSegmentByVector(boundaries.bottom, {
          x: 0,
          y: -scaledShape.height,
        }),
      };
    }
    case 'center':
    default: {
      return boundaries;
    }
  }
};

export const findInnerGlassesBounds = (
  glasses: Glass[],
  scale = 1,
  layerPosition = { x: 0, y: 0 },
) => {
  if (glasses.length > 0) {
    const glassesObj = glasses.map(({ corners, position }) => {
      const shape = new Shape({ corners }, position);

      shape.scaleShape(scale);

      return shape;
    });

    const [left] = glassesObj.sort(
      (a, b) => a.extremePoints.left.point.x - b.extremePoints.left.point.x,
    );
    const [top] = glassesObj.sort(
      (a, b) => a.extremePoints.top.point.y - b.extremePoints.top.point.y,
    );
    const [right] = glassesObj.sort(
      (a, b) => b.extremePoints.right.point.x - a.extremePoints.right.point.x,
    );
    const [bottom] = glassesObj.sort(
      (a, b) => b.extremePoints.bottom.point.y - a.extremePoints.bottom.point.y,
    );

    return correctBoundariesCopy(
      {
        top: [top.topEdge.points.left, top.topEdge.points.right],
        left: [left.leftEdge.points.top, left.leftEdge.points.bottom],
        bottom: [bottom.bottomEdge.points.left, bottom.bottomEdge.points.right],
        right: [right.rightEdge.points.top, right.rightEdge.points.bottom],
      },
      layerPosition,
    );
  }
};

function doPolygonsIntersect(polygon1: Polygon, polygon2: Polygon) {
  const clippedPolygon1 = getClippedPolygon(polygon1, polygon2);
  const clippedPolygon2 = getClippedPolygon(polygon2, polygon1);

  if (clippedPolygon1.length >= 3 || clippedPolygon2.length >= 3) {
    return true;
  }
  return false;
}

export type GlassWithEdges = {
  corners: {
    'bottom-left': [number, number];
    'bottom-right': [number, number];
    'top-right': [number, number];
    'top-left': [number, number];
    center?: [number, number];
    'center-left'?: [number, number];
    'center-right'?: [number, number];
  };
  edges: {
    top: Vector;
    right: Vector;
    bottom: Vector;
    left: Vector;
    'inner-top'?: Vector;
    'inner-left'?: Vector;
    'inner-right'?: Vector;
    'inner-bottom'?: Vector;
  };
};

const cornerToPoint = (corner: [number, number]) => {
  return { x: corner[0], y: corner[1] } as Vector2d;
};

export const isLShape = (shape: ShapeTypeCopy) => {
  return shape.indent !== undefined;
};

const convertToLShapedGlass = (
  shape: Omit<ShapeTypeCopy, 'corners'> & { corners: Corners },
) => {
  let glass: GlassWithEdges;
  if (shape.indent === 'TOP_RIGHT') {
    glass = {
      corners: {
        'bottom-left': [
          shape.corners['bottom-left'][0] + shape.position.x,
          shape.corners['bottom-left'][1] + shape.position.y,
        ],
        'bottom-right': [
          shape.corners['bottom-right'][0] + shape.position.x,
          shape.corners['bottom-right'][1] + shape.position.y,
        ],
        'center-right': [
          shape.corners['center-right']![0] + shape.position.x,
          shape.corners['center-right']![1] + shape.position.y,
        ],
        center: [
          shape.corners['center']![0] + shape.position.x,
          shape.corners['center']![1] + shape.position.y,
        ],
        'top-right': [
          shape.corners['top-right'][0] + shape.position.x,
          shape.corners['top-right'][1] + shape.position.y,
        ],
        'top-left': [
          shape.corners['top-left'][0] + shape.position.x,
          shape.corners['top-left'][1] + shape.position.y,
        ],
      },

      edges: {
        top: {
          startX: shape.corners['top-left'][0] + shape.position.x,
          startY: shape.corners['top-left'][1] + shape.position.y,
          endX: shape.corners['top-right'][0] + shape.position.x,
          endY: shape.corners['top-right'][1] + shape.position.y,
        },
        'inner-right': {
          startX: shape.corners['top-right'][0] + shape.position.x,
          startY: shape.corners['top-right'][1] + shape.position.y,
          endX: shape.corners['center']![0] + shape.position.x,
          endY: shape.corners['center']![1] + shape.position.y,
        },
        'inner-top': {
          startX: shape.corners['center']![0] + shape.position.x,
          startY: shape.corners['center']![1] + shape.position.y,
          endX: shape.corners['center-right']![0] + shape.position.x,
          endY: shape.corners['center-right']![1] + shape.position.y,
        },
        right: {
          startX: shape.corners['center-right']![0] + shape.position.x,
          startY: shape.corners['center-right']![1] + shape.position.y,
          endX: shape.corners['bottom-right'][0] + shape.position.x,
          endY: shape.corners['bottom-right'][1] + shape.position.y,
        },
        bottom: {
          startX: shape.corners['bottom-right'][0] + shape.position.x,
          startY: shape.corners['bottom-right'][1] + shape.position.y,
          endX: shape.corners['bottom-left'][0] + shape.position.x,
          endY: shape.corners['bottom-left'][1] + shape.position.y,
        },
        left: {
          startX: shape.corners['bottom-left'][0] + shape.position.x,
          startY: shape.corners['bottom-left'][1] + shape.position.y,
          endX: shape.corners['top-left'][0] + shape.position.x,
          endY: shape.corners['top-left'][1] + shape.position.y,
        },
      },
    };
  } else {
    glass = {
      corners: {
        'bottom-left': [
          shape.corners['bottom-left'][0] + shape.position.x,
          shape.corners['bottom-left'][1] + shape.position.y,
        ],
        'bottom-right': [
          shape.corners['bottom-right'][0] + shape.position.x,
          shape.corners['bottom-right'][1] + shape.position.y,
        ],
        'top-right': [
          shape.corners['top-right'][0] + shape.position.x,
          shape.corners['top-right'][1] + shape.position.y,
        ],
        'top-left': [
          shape.corners['top-left'][0] + shape.position.x,
          shape.corners['top-left'][1] + shape.position.y,
        ],
        center: [
          shape.corners['center']![0] + shape.position.x,
          shape.corners['center']![1] + shape.position.y,
        ],
        'center-left': [
          shape.corners['center-left']![0] + shape.position.x,
          shape.corners['center-left']![1] + shape.position.y,
        ],
      },

      edges: {
        top: {
          startX: shape.corners['top-left'][0] + shape.position.x,
          startY: shape.corners['top-left'][1] + shape.position.y,
          endX: shape.corners['top-right'][0] + shape.position.x,
          endY: shape.corners['top-right'][1] + shape.position.y,
        },
        'inner-left': {
          startX: shape.corners['bottom-left'][0] + shape.position.x,
          startY: shape.corners['bottom-left'][1] + shape.position.y,
          endX: shape.corners['center']![0] + shape.position.x,
          endY: shape.corners['center']![1] + shape.position.y,
        },
        'inner-bottom': {
          startX: shape.corners['center']![0] + shape.position.x,
          startY: shape.corners['center']![1] + shape.position.y,
          endX: shape.corners['center-left']![0] + shape.position.x,
          endY: shape.corners['center-left']![1] + shape.position.y,
        },
        right: {
          startX: shape.corners['top-right']![0] + shape.position.x,
          startY: shape.corners['top-right']![1] + shape.position.y,
          endX: shape.corners['bottom-right'][0] + shape.position.x,
          endY: shape.corners['bottom-right'][1] + shape.position.y,
        },
        bottom: {
          startX: shape.corners['bottom-right'][0] + shape.position.x,
          startY: shape.corners['bottom-right'][1] + shape.position.y,
          endX: shape.corners['bottom-left'][0] + shape.position.x,
          endY: shape.corners['bottom-left'][1] + shape.position.y,
        },
        left: {
          startX: shape.corners['center-left']![0] + shape.position.x,
          startY: shape.corners['center-left']![1] + shape.position.y,
          endX: shape.corners['top-left'][0] + shape.position.x,
          endY: shape.corners['top-left'][1] + shape.position.y,
        },
      },
    };
  }

  return glass;
};

export const convertToGlass = (shape: ShapeTypeCopy) => {
  const positionBig = toPositionBig(shape.position);

  const corners: GlassWithEdges['corners'] = {
    'bottom-left': [
      new Big(shape.corners['bottom-left'][0]).plus(positionBig.x).toNumber(),
      new Big(shape.corners['bottom-left'][1]).plus(positionBig.y).toNumber(),
    ],
    'bottom-right': [
      new Big(shape.corners['bottom-right'][0]).plus(positionBig.x).toNumber(),
      new Big(shape.corners['bottom-right'][1]).plus(positionBig.y).toNumber(),
    ],
    'top-right': [
      new Big(shape.corners['top-right'][0]).plus(positionBig.x).toNumber(),
      new Big(shape.corners['top-right'][1]).plus(positionBig.y).toNumber(),
    ],
    'top-left': [
      new Big(shape.corners['top-left'][0]).plus(positionBig.x).toNumber(),
      new Big(shape.corners['top-left'][1]).plus(positionBig.y).toNumber(),
    ],
  };

  const glass: GlassWithEdges = {
    corners,

    edges: {
      top: {
        startX: corners['top-left'][0],
        startY: corners['top-left'][1],
        endX: corners['top-right'][0],
        endY: corners['top-right'][1],
      },
      right: {
        startX: corners['top-right'][0],
        startY: corners['top-right'][1],
        endX: corners['bottom-right'][0],
        endY: corners['bottom-right'][1],
      },
      bottom: {
        startX: corners['bottom-left'][0],
        startY: corners['bottom-left'][1],
        endX: corners['bottom-right'][0],
        endY: corners['bottom-right'][1],
      },
      left: {
        startX: corners['top-left'][0],
        startY: corners['top-left'][1],
        endX: corners['bottom-left'][0],
        endY: corners['bottom-left'][1],
      },
    },
  };

  return glass;
};

// The Sutherland–Hodgman algorithm
export const getClippedPolygon = (
  subjectPolygon: Polygon,
  clipPolygon: Polygon,
) => {
  let cp1: Vector2d;
  let cp2: Vector2d;
  let s: Vector2d;
  let e: Vector2d;
  const inside = function (p: Vector2d) {
    return (cp2.x - cp1.x) * (p.y - cp1.y) > (cp2.y - cp1.y) * (p.x - cp1.x);
  };
  const intersection = () => {
    const dc = [cp1.x - cp2.x, cp1.y - cp2.y],
      dp = [s.x - e.x, s.y - e.y],
      n1 = cp1.x * cp2.y - cp1.y * cp2.x,
      n2 = s.x * e.y - s.y * e.x,
      n3 = 1.0 / (dc[0] * dp[1] - dc[1] * dp[0]);
    return {
      x: (n1 * dp[0] - n2 * dc[0]) * n3,
      y: (n1 * dp[1] - n2 * dc[1]) * n3,
    };
  };

  let outputList = subjectPolygon;

  const isOutputPointValid = (point: Vector2d) => {
    for (const item of subjectPolygon) {
      if (
        Math.abs(item.x - point.x) < 0.0000001 &&
        Math.abs(item.y - point.y) < 0.0000001
      ) {
        return true;
      }
    }

    for (const item of clipPolygon) {
      if (
        Math.abs(item.x - point.x) < 0.0000001 &&
        Math.abs(item.y - point.y) < 0.0000001
      ) {
        return true;
      }
    }

    for (let i = 0; i < subjectPolygon.length; ++i) {
      const cp1 = subjectPolygon[i];
      const cp2 = subjectPolygon[(i + 1) % subjectPolygon.length];
      if (isPointCollinear(cp1, cp2, point)) {
        const dotProductAC = dotProduct(cp1, cp2, cp1, point);
        const dotProductAB = dotProduct(cp1, cp2, cp1, cp2);

        if (!(dotProductAC < 0) && !(dotProductAC > dotProductAB)) {
          return true;
        }
      }
    }

    for (let i = 0; i < clipPolygon.length; ++i) {
      const cp1 = clipPolygon[i];
      const cp2 = clipPolygon[(i + 1) % clipPolygon.length];
      if (isPointCollinear(cp1, cp2, point)) {
        const dotProductAC = dotProduct(cp1, cp2, cp1, point);
        const dotProductAB = dotProduct(cp1, cp2, cp1, cp2);

        if (!(dotProductAC < 0) && !(dotProductAC > dotProductAB)) {
          return true;
        }
      }
    }

    return false;
  };

  const addToOutputList = (point: Vector2d) => {
    for (const item of outputList) {
      if (
        Math.abs(item.x - point.x) < 0.0000001 &&
        Math.abs(item.y - point.y) < 0.0000001
      ) {
        return;
      }
    }

    outputList.push(point);
  };

  cp1 = clipPolygon[clipPolygon.length - 1];
  for (const j in clipPolygon) {
    cp2 = clipPolygon[j];
    const inputList = outputList;
    outputList = [];
    s = inputList[inputList.length - 1];
    for (const i in inputList) {
      e = inputList[i];
      if (inside(e)) {
        if (!inside(s)) {
          addToOutputList(intersection());
        }
        addToOutputList(e);
      } else if (inside(s)) {
        addToOutputList(intersection());
      }
      s = e;
    }
    cp1 = cp2;
  }

  for (let i = outputList.length - 1; i >= 0; i--) {
    const point = outputList[i];

    if (!isOutputPointValid(point)) {
      outputList.splice(i, 1);
    }
  }

  return outputList;
};

const areGlassesSticked = (glass1: GlassWithEdges, glass2: GlassWithEdges) => {
  let collinearFound = false;

  for (const [_, glass1Edge] of Object.entries(glass1.edges)) {
    for (const [_, glass2Edge] of Object.entries(glass2.edges)) {
      if (
        !collinearFound &&
        glass1Edge !== undefined &&
        glass2Edge !== undefined
      ) {
        const edge1Start = { x: glass1Edge.startX, y: glass1Edge.startY };
        const edge1End = { x: glass1Edge.endX, y: glass1Edge.endY };
        const edge2Start = { x: glass2Edge.startX, y: glass2Edge.startY };
        const edge2End = { x: glass2Edge.endX, y: glass2Edge.endY };

        if (!isVectorCollinear(edge1Start, edge1End, edge2Start, edge2End)) {
          continue;
        }

        const edge1Length = getVectorLength(edge1Start, edge1End);
        const edge2Length = getVectorLength(edge2Start, edge2End);

        if (edge1Length >= edge2Length) {
          if (
            isPointInsideVector(edge1Start, edge1End, edge2Start) ||
            isPointInsideVector(edge1Start, edge1End, edge2End)
          ) {
            collinearFound = true;
          }
        } else {
          if (
            isPointInsideVector(edge2Start, edge2End, edge1Start) ||
            isPointInsideVector(edge2Start, edge2End, edge1End)
          ) {
            collinearFound = true;
          }
        }
      }
    }
  }

  return collinearFound;
};

const findGlassesStickedEdges = (
  glass1: GlassWithEdges,
  glass2: GlassWithEdges,
  onlyOppositeEdges = false,
) => {
  const collinearFound = [];

  for (const [edge1, glass1Edge] of Object.entries(glass1.edges)) {
    for (const [edge2, glass2Edge] of Object.entries(glass2.edges)) {
      if (glass1Edge !== undefined && glass2Edge !== undefined) {
        const edge1Start = { x: glass1Edge.startX, y: glass1Edge.startY };
        const edge1End = { x: glass1Edge.endX, y: glass1Edge.endY };
        const edge2Start = { x: glass2Edge.startX, y: glass2Edge.startY };
        const edge2End = { x: glass2Edge.endX, y: glass2Edge.endY };

        if (!isVectorCollinear(edge1Start, edge1End, edge2Start, edge2End)) {
          continue;
        }

        const cornersSticked =
          (edge1End.x === edge2Start.x && edge1End.y === edge2Start.y) ||
          (edge1Start.x === edge2End.x && edge1Start.y === edge2End.y);

        if (onlyOppositeEdges && cornersSticked) {
          continue;
        }

        const edge1Length = getVectorLength(edge1Start, edge1End);
        const edge2Length = getVectorLength(edge2Start, edge2End);

        if (edge1Length >= edge2Length) {
          if (
            isPointInsideVector(edge1Start, edge1End, edge2Start) ||
            isPointInsideVector(edge1Start, edge1End, edge2End)
          ) {
            collinearFound.push({ edge1, edge2 });
          }
        } else {
          if (
            isPointInsideVector(edge2Start, edge2End, edge1Start) ||
            isPointInsideVector(edge2Start, edge2End, edge1End)
          ) {
            collinearFound.push({ edge1, edge2 });
          }
        }
      }
    }
  }

  return collinearFound;
};

const areGlassesTheSame = (glass1: GlassWithEdges, glass2: GlassWithEdges) => {
  if (
    glass1.corners['top-left'] === glass2.corners['top-left'] &&
    glass1.corners['top-right'] === glass2.corners['top-right'] &&
    glass1.corners['bottom-right'] === glass2.corners['bottom-right'] &&
    glass1.corners['bottom-left'] === glass2.corners['bottom-left']
  ) {
    return true;
  }

  return false;
};

const areAllGlassesConnected = (
  parent: GlassWithEdges,
  glassesToCheck: GlassWithEdges[],
  checkedGlasses: GlassWithEdges[],
  movedGlass: GlassWithEdges,
) => {
  if (areGlassesSticked(movedGlass, parent)) {
    return true;
  }

  const stickedGlasses: GlassWithEdges[] = [];

  glassesToCheck.forEach((item) => {
    if (areGlassesSticked(item, movedGlass)) {
      stickedGlasses.push(item);
    }
  });

  const validStickedGlasses: GlassWithEdges[] = [];

  stickedGlasses.forEach((stickedGlass) => {
    if (
      !areGlassesTheSame(stickedGlass, movedGlass) &&
      checkedGlasses.find((glass) => areGlassesTheSame(stickedGlass, glass)) ===
        undefined
    ) {
      validStickedGlasses.push(stickedGlass);
    }
  });

  let returnValue = false;
  validStickedGlasses.forEach((validGlass) => {
    const newGlassesList = glassesToCheck.filter(
      (item) => !areGlassesTheSame(validGlass, item),
    );

    if (
      areAllGlassesConnected(
        parent,
        newGlassesList,
        [...checkedGlasses, movedGlass],
        validGlass,
      )
    ) {
      returnValue = true;
    }
  });

  return returnValue;
};

const compareShapesByCorners = (polygon: Polygon, clipShape: Polygon) => {
  let checkedCornersCount = 0;
  let allCornersValid = true;

  for (let i = 0; i < clipShape.length; i++) {
    if (allCornersValid) {
      const isPolygonCorner =
        polygon.find(
          (polygonCorner) =>
            Math.abs(polygonCorner.x - clipShape[i].x) <= 0.005 &&
            Math.abs(polygonCorner.y - clipShape[i].y) <= 0.005,
        ) !== undefined;

      if (isPolygonCorner) {
        checkedCornersCount++;
      }
      const prevIndex = i - 1;
      const nextIndex = i + 1;
      const prevPoint =
        prevIndex < 0
          ? clipShape[clipShape.length + prevIndex]
          : clipShape[prevIndex];
      const nextPoint = clipShape[nextIndex % clipShape.length];
      const isInsideEdge = isPointInsideVector(
        prevPoint,
        nextPoint,
        clipShape[i],
      );

      if (isPolygonCorner && isInsideEdge) {
        allCornersValid = false;
      }
    }
  }

  return allCornersValid && checkedCornersCount === polygon.length;
};

export const getParentPolygon = (parentData: ShapeTypeCopy) => {
  let parent: GlassWithEdges;
  let parentPolygon: Polygon;

  if (isLShape(parentData)) {
    parent = convertToLShapedGlass(
      parentData as Omit<ShapeTypeCopy, 'corners'> & { corners: Corners },
    );
    if (parent.corners.hasOwnProperty('center-right')) {
      parentPolygon = [
        cornerToPoint(parent.corners['top-left']),
        cornerToPoint(parent.corners['top-right']),
        cornerToPoint(parent.corners['center']!),
        cornerToPoint(parent.corners['center-right']!),
        cornerToPoint(parent.corners['bottom-right']),
        cornerToPoint(parent.corners['bottom-left']),
      ];
    } else {
      parentPolygon = [
        cornerToPoint(parent.corners['top-left']),
        cornerToPoint(parent.corners['top-right']),
        cornerToPoint(parent.corners['bottom-right']),
        cornerToPoint(parent.corners['bottom-left']),
        cornerToPoint(parent.corners['center-left']!),
        cornerToPoint(parent.corners['center']!),
      ];
    }
  } else {
    parent = convertToGlass(parentData);
    parentPolygon = [
      cornerToPoint(parent.corners['top-left']),
      cornerToPoint(parent.corners['top-right']),
      cornerToPoint(parent.corners['bottom-right']),
      cornerToPoint(parent.corners['bottom-left']),
    ];
  }

  return { parent, polygon: parentPolygon };
};

export const getRectPolygon = (corners: ShapeTypeCopy['corners']): Polygon => [
  cornerToPoint(corners['top-left']),
  cornerToPoint(corners['top-right']),
  cornerToPoint(corners['bottom-right']),
  cornerToPoint(corners['bottom-left']),
];

const getRectPolygonVectors = (corners: ShapeTypeCopy['corners']) =>
  new PolygonClass(map(corners, (item) => new Vector2(item)));

//TODO: Can be refactored to use GJK algorithm
export const validateGlasses = (data: Omit<DataCopy, 'scale'>) => {
  const movedGlass = convertToGlass(data.shape);
  const movedGlassPolygon = getRectPolygon(movedGlass.corners);

  const { parent, polygon: parentPolygon } = getParentPolygon(data.parents[0]);

  const glasses: GlassWithEdges[] = [];

  data.shapes.forEach((shape) => {
    glasses.push(convertToGlass(shape));
  });

  let intersectionFound = false;

  glasses.forEach((glass) => {
    if (!intersectionFound) {
      const glassPolygon = getRectPolygon(glass.corners);

      if (doPolygonsIntersect(glassPolygon, movedGlassPolygon)) {
        intersectionFound = true;
      }
    }
  });

  const collisionWithParent = !compareShapesByCorners(
    movedGlassPolygon,
    getClippedPolygon(parentPolygon, movedGlassPolygon),
  );

  const isConnected = areAllGlassesConnected(parent, glasses, [], movedGlass);

  return { intersectionFound, collisionWithParent, notConnected: !isConnected };
};

const findShapeParentCollision = (parents: DataCopy['parents']) => {
  const outerPolygon = {
    'top-left': [-OUTER_BOUNDARY, -OUTER_BOUNDARY],
    'top-right': [OUTER_BOUNDARY, -OUTER_BOUNDARY],
    'bottom-right': [OUTER_BOUNDARY, OUTER_BOUNDARY],
    'bottom-left': [-OUTER_BOUNDARY, OUTER_BOUNDARY],
  } as Record<string, [number, number]>;

  const outerEdges = {
    top: [outerPolygon['top-left'], outerPolygon['top-right']],
    right: [outerPolygon['top-right'], outerPolygon['bottom-right']],
    bottom: [outerPolygon['bottom-right'], outerPolygon['bottom-left']],
    left: [outerPolygon['bottom-left'], outerPolygon['top-left']],
  } as Record<string, [[number, number], [number, number]]>;

  const parent = new Space(
    {
      corners: parents[0].corners,
      indent: parents[0].indent,
    } as ProjectDimensions,
    parents[0].position,
  );

  parent.addIndentToSpaceShapes();

  const parentEdges = {
    top: [parent.corners['top-left'], parent.corners['top-right']],
    right: [parent.corners['top-right'], parent.corners['bottom-right']],
    bottom: [parent.corners['bottom-right'], parent.corners['bottom-left']],
    left: [parent.corners['bottom-left'], parent.corners['top-left']],
  } as Record<string, [[number, number], [number, number]]>;

  return map(
    [
      ...parent.spaceShapes.map((item) =>
        map(item.corners, (corner) => corner),
      ),
      [
        ...outerEdges.top,
        linesIntersection(parentEdges.top, outerEdges.left) ?? [0, 0],
        linesIntersection(parentEdges.top, outerEdges.right) ?? [0, 0],
      ],
      [
        ...outerEdges.right,
        linesIntersection(parentEdges.right, outerEdges.top) ?? [0, 0],
        linesIntersection(parentEdges.right, outerEdges.bottom) ?? [0, 0],
      ],
      [
        ...outerEdges.bottom,
        linesIntersection(parentEdges.bottom, outerEdges.left) ?? [0, 0],
        linesIntersection(parentEdges.bottom, outerEdges.right) ?? [0, 0],
      ],
      [
        ...outerEdges.left,
        linesIntersection(parentEdges.left, outerEdges.top) ?? [0, 0],
        linesIntersection(parentEdges.left, outerEdges.bottom) ?? [0, 0],
      ],
    ],
    (items) => new PolygonClass(map(items, (item) => new Vector2(item))),
  );
};

const checkStickedEdges = (
  glass: GlassWithEdges,
  parent: GlassWithEdges,
  glasses: GlassWithEdges[],
  moveVector: Vector2,
  validPosition: Vector2,
  initPosition: Position,
): boolean => {
  const stickedEdges = findGlassesStickedEdges(glass, parent);
  const stickedEdgesGlasses = map(glasses, (item) =>
    findGlassesStickedEdges(glass, item, true),
  ).flat();

  const isEdgeSticked = (edge: Direction) => {
    return (
      some(stickedEdges, (item) => item.edge1 === edge) ||
      some(stickedEdgesGlasses, (item) => item.edge1 === edge)
    );
  };

  const topSticked = isEdgeSticked('top');
  const bottomSticked = isEdgeSticked('bottom');
  const leftSticked = isEdgeSticked('left');
  const rightSticked = isEdgeSticked('right');

  if (topSticked && moveVector.y.toNumber() < 0) {
    validPosition.y = new Big(initPosition.y);
  }

  if (bottomSticked && moveVector.y.toNumber() > 0) {
    validPosition.y = new Big(initPosition.y);
  }

  if (leftSticked && moveVector.x.toNumber() < 0) {
    validPosition.x = new Big(initPosition.x);
  }

  if (rightSticked && moveVector.x.toNumber() > 0) {
    validPosition.x = new Big(initPosition.x);
  }

  return topSticked && bottomSticked && leftSticked && rightSticked;
};

const convertData = (data: DataCopy) => ({
  glass: convertToGlass(data.shape),
  glasses: map(data.shapes, convertToGlass),
  ...getParentPolygon(data.parents[0]),
});

const testAABB = (a: PolygonClass, b: PolygonClass) => {
  const d1x = b.extremes.minX.minus(a.extremes.maxX);
  const d1y = b.extremes.minY.minus(a.extremes.maxY);
  const d2x = a.extremes.minX.minus(b.extremes.maxX);
  const d2y = a.extremes.minY.minus(b.extremes.maxY);

  if (d1x.gt(0) || d1y.gt(0)) {
    return false;
  }

  if (d2x.gt(0) || d2y.gt(0)) {
    return false;
  }

  return true;
};

const filterAABBCollisionPolygons = (
  shapes: PolygonClass[],
  shape: PolygonClass,
) => {
  return filter(shapes, (item) => testAABB(shape, item));
};

const checkCollisions = (
  data: DataCopy,
  validPosition: Vector2,
  glasses: GlassWithEdges[],
): Vector2 | undefined => {
  const movedGlass = convertToGlass({
    ...data.shape,
    position: { x: validPosition.x.toNumber(), y: validPosition.y.toNumber() },
  });

  const movedGlassPolygon = getRectPolygonVectors(movedGlass.corners);
  const parentPolygons = findShapeParentCollision(data.parents);

  const glassesPolygons = map(glasses, (glass) =>
    getRectPolygonVectors(glass.corners),
  );

  const shapes = filterAABBCollisionPolygons(
    [...glassesPolygons, ...parentPolygons],
    movedGlassPolygon,
  );

  const intersections: Vector2[] = [];

  const gjk = new GJK2D();

  forEach(shapes, (poly) => {
    const intersection = gjk.intersect(movedGlassPolygon, poly);

    if (intersection) {
      intersections.push(intersection);
    }
  });

  return intersections.length
    ? intersections.sort((a, b) =>
        b.squaredLength().abs().minus(a.squaredLength().abs()).toNumber(),
      )[0]
    : undefined;
};

export const validatePosInBoundsNew = (
  data: DataCopy,
  moveVector: Vector2,
  position: Vector2,
) => {
  const validPosition = position;

  let conflictFound = true;
  let conflictCounter = 0;

  const { glass, glasses, parent } = convertData(data);

  const allEdgesSticked = checkStickedEdges(
    glass,
    parent,
    glasses,
    moveVector,
    validPosition,
    data.shape.position,
  );

  if (allEdgesSticked) {
    return validPosition;
  }

  do {
    const collision = checkCollisions(data, validPosition, glasses);

    if (!collision || (collision.x.eq(0) && collision.y.eq(0))) {
      conflictFound = false;
      break;
    }

    validPosition.x = validPosition.x.minus(collision.x);
    validPosition.y = validPosition.y.minus(collision.y);
    conflictCounter += 1;
  } while (conflictFound && conflictCounter < 20);

  return validPosition;
};

export const validatePosInBounds = (
  position: Position,
  boundaries: BoundariesCopy | undefined,
  corners: RectCorners,
  initPosition?: Position,
  scale = 1,
) => {
  let valid = { ...position };
  let conflictCounter = 0;

  if (boundaries && initPosition) {
    do {
      valid = validateBottomBound({
        corners,
        initPosition,
        valid,
        boundaries,
        scale,
      });
      valid = validateTopBound({
        corners,
        initPosition,
        valid,
        boundaries,
        scale,
      });
      valid = validateRightBound({
        corners,
        initPosition,
        valid,
        boundaries,
        scale,
      });
      valid = validateLeftBound({
        corners,
        initPosition,
        valid,
        boundaries,
        scale,
      });
      conflictCounter += 1;
    } while (conflictCounter < 3);
  }
  return valid;
};

interface BoundValidationProps {
  corners: RectCorners;
  initPosition: Position;
  valid: Position;
  boundaries: BoundariesCopy;
  scale: number;
}

const validateLeftBound = ({
  corners,
  initPosition,
  valid,
  boundaries,
  scale,
}: BoundValidationProps) => {
  const shape = new Shape({ corners });
  const shapeCopy = new Shape({ corners });

  shapeCopy.scaleShape(scale * DEFAULT_RATIO);

  shape.scaleShape(scale * DEFAULT_RATIO);
  shape.transformByVector({ ...valid });

  const minXPoint = findMinXPoint(boundaries.left);

  const commonLeft = {
    top: findXOnLine(shape.leftEdge.points.top.y, boundaries.left),
    bottom: findXOnLine(shape.leftEdge.points.bottom.y, boundaries.left),
    extreme: findXOnLine(shape.extremePoints.left.point.y, boundaries.left),
  };

  if (
    commonLeft.top ||
    commonLeft.bottom ||
    shape.leftEdge.value < minXPoint.x
  ) {
    const topDistance = findPointDistanceFromVector(
      shape.leftEdge.points.top,
      boundaries.left,
    ).x;

    const bottomDistance = findPointDistanceFromVector(
      shape.leftEdge.points.bottom,
      boundaries.left,
    ).x;

    if (
      topDistance < 0 ||
      parseInt(String(topDistance)) === 0 ||
      bottomDistance < 0 ||
      parseInt(String(bottomDistance)) === 0
    ) {
      if (commonLeft.top && commonLeft.bottom) {
        valid = {
          ...valid,
          x:
            topDistance < bottomDistance
              ? commonLeft.top - shapeCopy.leftEdge.points.top.x
              : commonLeft.bottom - shapeCopy.leftEdge.points.bottom.x,
        };
      } else if (commonLeft.extreme) {
        valid = {
          ...valid,
          x: commonLeft.extreme - shapeCopy.extremePoints.left.point.x,
        };
      } else {
        valid = {
          ...valid,
          x: initPosition.x,
        };
      }
    }
  }

  return valid;
};

const validateRightBound = ({
  corners,
  initPosition,
  valid,
  boundaries,
  scale,
}: BoundValidationProps) => {
  const shape = new Shape({ corners });
  const shapeCopy = new Shape({ corners });

  shapeCopy.scaleShape(scale * DEFAULT_RATIO);

  shape.scaleShape(scale * DEFAULT_RATIO);
  shape.transformByVector({ ...valid });

  const maxXPoint = findMaxXPoint(boundaries.right);
  const commonRight = {
    top: findXOnLine(shape.rightEdge.points.top.y, boundaries.right),
    bottom: findXOnLine(shape.rightEdge.points.bottom.y, boundaries.right),
    extreme: findXOnLine(shape.extremePoints.right.point.y, boundaries.right),
  };

  if (
    commonRight.top ||
    commonRight.bottom ||
    shape.rightEdge.value > maxXPoint.x
  ) {
    const topDistance = findPointDistanceFromVector(
      shape.rightEdge.points.top,
      boundaries.right,
    ).x;

    const bottomDistance = findPointDistanceFromVector(
      shape.rightEdge.points.bottom,
      boundaries.right,
    ).x;

    if (
      topDistance > 0 ||
      parseInt(String(topDistance)) === 0 ||
      bottomDistance > 0 ||
      parseInt(String(bottomDistance)) === 0
    ) {
      if (commonRight.top && commonRight.bottom) {
        valid = {
          ...valid,
          x:
            topDistance > bottomDistance
              ? commonRight.top - shapeCopy.rightEdge.points.top.x
              : commonRight.bottom - shapeCopy.rightEdge.points.bottom.x,
        };
      } else if (commonRight.extreme) {
        valid = {
          ...valid,
          x: commonRight.extreme - shapeCopy.extremePoints.right.point.x,
        };
      } else {
        valid = {
          ...valid,
          x: initPosition.x,
        };
      }
    }
  }

  return valid;
};

const validateTopBound = ({
  corners,
  initPosition,
  valid,
  boundaries,
  scale,
}: BoundValidationProps) => {
  const shape = new Shape({ corners });
  const shapeCopy = new Shape({ corners });

  shapeCopy.scaleShape(scale * DEFAULT_RATIO);

  shape.scaleShape(scale * DEFAULT_RATIO);
  shape.transformByVector({ ...valid });

  const minYPoint = findMinYPoint(boundaries.top);
  const commonTop = {
    left: findYOnLine(shape.topEdge.points.left.x, boundaries.top),
    right: findYOnLine(shape.topEdge.points.right.x, boundaries.top),
    extreme: findYOnLine(shape.extremePoints.top.point.x, boundaries.top),
  };

  if (commonTop.left || commonTop.right || shape.topEdge.value < minYPoint.y) {
    const leftDistance = findPointDistanceFromVector(
      shape.topEdge.points.left,
      boundaries.top,
    ).y;

    const rightDistance = findPointDistanceFromVector(
      shape.topEdge.points.right,
      boundaries.top,
    ).y;

    if (
      leftDistance < 0 ||
      parseInt(String(leftDistance)) === 0 ||
      rightDistance < 0 ||
      parseInt(String(rightDistance)) === 0
    ) {
      if (commonTop.left && commonTop.right) {
        valid = {
          ...valid,
          y:
            leftDistance < rightDistance
              ? commonTop.left - shapeCopy.topEdge.points.left.y
              : commonTop.right - shapeCopy.topEdge.points.right.y,
        };
      } else if (commonTop.extreme) {
        valid = {
          ...valid,
          y: commonTop.extreme - shapeCopy.extremePoints.top.point.y,
        };
      } else {
        valid = {
          ...valid,
          y: initPosition.y,
        };
      }
    }
  }

  return valid;
};

const validateBottomBound = ({
  corners,
  initPosition,
  valid,
  boundaries,
  scale,
}: BoundValidationProps) => {
  const shape = new Shape({ corners });
  const shapeCopy = new Shape({ corners });

  shapeCopy.scaleShape(scale * DEFAULT_RATIO);

  shape.scaleShape(scale * DEFAULT_RATIO);
  shape.transformByVector({ ...valid });

  const maxYPoint = findMaxYPoint(boundaries.bottom);
  const commonBottom = {
    left: findYOnLine(shape.bottomEdge.points.left.x, boundaries.bottom),
    right: findYOnLine(shape.bottomEdge.points.right.x, boundaries.bottom),
    extreme: findYOnLine(shape.extremePoints.bottom.point.x, boundaries.bottom),
  };

  if (
    commonBottom.left ||
    commonBottom.right ||
    shape.bottomEdge.value > maxYPoint.y
  ) {
    const leftDistance = findPointDistanceFromVector(
      shape.bottomEdge.points.left,
      boundaries.bottom,
    ).y;

    const rightDistance = findPointDistanceFromVector(
      shape.bottomEdge.points.right,
      boundaries.bottom,
    ).y;

    if (
      leftDistance > 0 ||
      parseInt(String(leftDistance)) === 0 ||
      rightDistance > 0 ||
      parseInt(String(rightDistance)) === 0
    ) {
      if (commonBottom.left && commonBottom.right) {
        valid = {
          ...valid,
          y:
            leftDistance > rightDistance
              ? commonBottom.left - shapeCopy.bottomEdge.points.left.y
              : commonBottom.right - shapeCopy.bottomEdge.points.right.y,
        };
      } else if (commonBottom.extreme) {
        valid = {
          ...valid,
          y: commonBottom.extreme - shapeCopy.extremePoints.bottom.point.y,
        };
      } else {
        valid = {
          ...valid,
          y: initPosition.y,
        };
      }
    }
  }

  return valid;
};
