import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled(layout.Row)`
  align-items: center;
  justify-content: space-between;
  padding: 17px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  height: 60px;
  > span {
    color: ${({ theme }) => theme.colors.dark};
    &[role='button'] {
      color: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;
