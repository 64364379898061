import styled from 'styled-components';

export const Container = styled.div`
  margin: 16px 0;
`;

export const Breadcrumb = styled.div<{ root?: boolean }>`
  display: inline-block;
  > span {
    vertical-align: middle;
    color: ${({ theme, root }) =>
      root ? theme.colors.mainBlue : theme.colors.grey};
  }
  > svg {
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin: 0 4px;
    > path {
      fill: ${({ theme }) => theme.colors.mainBlue};
    }
  }
`;
