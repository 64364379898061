import styled from 'styled-components';
import { layout } from '../../../../styles';

export const Container = styled(layout.Row)`
  justify-content: space-between;
`;

export const Column = styled(layout.Column)`
  flex: 1;
  padding-right: 80px;
  margin-bottom: 12px;
  > span {
    color: ${({ theme }) => theme.colors.dark};
    margin-top: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  margin-top: -12px;
`;
