import Big from 'big.js';
import { useCallback } from 'react';
import { Shape } from '../types';
import { Anchor } from '../types/Anchor';
import { fixValue } from '../utils/fix';
import { GlassDimensions } from '../../../services/api/models/glass';
import { selectGlassNeighbors, useProjectStore } from '../store/project';
import {
  Direction,
  GlassNeighbors,
  NeighborGlass,
  NeighborType,
} from '../utils/neighbors';
import { normalizeVector } from '../utils/geometry/vectors';
import { DiagonalDirection } from '../utils/shadows';

export enum GlassGlassType {
  T90 = 90,
  T180 = 180,
  T180_4 = 1804,
}

export interface UseGlassGlassProduct {
  isValid4CornerDrop(
    glassId: number,
    anchor?: Anchor,
    gapsBetweenGlasses?: GlassDimensions,
    thickness?: number[],
  ): boolean;

  correctProductPosition(
    valid: Shape,
    type?: GlassGlassType,
    anchor?: Anchor,
    rotated?: boolean,
    gapsBetweenGlasses?: GlassDimensions,
    ratio?: number,
  ): Shape;

  validDrop(
    glassId: number,
    type?: GlassGlassType,
    anchor?: Anchor,
    gapsBetweenGlasses?: GlassDimensions,
    validThickness?: number[],
  ): boolean;

  getGlassNeighbors(glassId: number): GlassNeighbors;
}

const validDistance = (
  neighbors: GlassNeighbors,
  gapsBetweenGlasses?: GlassDimensions,
  ratio = 1,
): ((direction: Direction | DiagonalDirection) => boolean) => (direction) => {
  switch (direction) {
    case 'top': {
      return (
        neighbors.top.filter(
          (neighbor) =>
            neighbor.distance * ratio <= (gapsBetweenGlasses?.top ?? 0),
        ).length > 0
      );
    }
    case 'bottom': {
      return (
        neighbors.bottom.filter(
          (neighbor) =>
            neighbor.distance * ratio <= (gapsBetweenGlasses?.bottom ?? 0),
        ).length > 0
      );
    }
    case 'left': {
      return (
        neighbors.left.filter(
          (neighbor) =>
            neighbor.distance * ratio <= (gapsBetweenGlasses?.left ?? 0),
        ).length > 0
      );
    }
    case 'right': {
      return (
        neighbors.right.filter(
          (neighbor) =>
            neighbor.distance * ratio <= (gapsBetweenGlasses?.right ?? 0),
        ).length > 0
      );
    }
    case 'topLeft': {
      return (
        neighbors.topLeft.filter(
          (neighbor) =>
            neighbor.distance <=
            normalizeVector({
              x: gapsBetweenGlasses?.left ?? 0,
              y: gapsBetweenGlasses?.top ?? 0,
            }),
        ).length > 0
      );
    }
    case 'bottomLeft': {
      return (
        neighbors.bottomLeft.filter(
          (neighbor) =>
            neighbor.distance <=
            normalizeVector({
              x: gapsBetweenGlasses?.left ?? 0,
              y: gapsBetweenGlasses?.bottom ?? 0,
            }),
        ).length > 0
      );
    }
    case 'topRight': {
      return (
        neighbors.topRight.filter(
          (neighbor) =>
            neighbor.distance <=
            normalizeVector({
              x: gapsBetweenGlasses?.right ?? 0,
              y: gapsBetweenGlasses?.top ?? 0,
            }),
        ).length > 0
      );
    }
    case 'bottomRight': {
      return (
        neighbors.bottomRight.filter(
          (neighbor) =>
            neighbor.distance <=
            normalizeVector({
              x: gapsBetweenGlasses?.right ?? 0,
              y: gapsBetweenGlasses?.bottom ?? 0,
            }),
        ).length > 0
      );
    }
    default: {
      return false;
    }
  }
};

const validThickness = (
  neighbors: GlassNeighbors,
  thickness: number[],
): ((direction: Direction | DiagonalDirection) => boolean) => (direction) => {
  const [neighbor] = neighbors[direction].sort(
    (a, b) => a.distance - b.distance,
  );
  if (neighbor && neighbor.data.type === NeighborType.GLASS) {
    return thickness.includes(neighbor.data.glass.thickness);
  }
  return false;
};

const useGlassGlassProduct = (): UseGlassGlassProduct => {
  const glassNeighbors = useProjectStore(selectGlassNeighbors);

  const getGlassNeighbors = useCallback(
    (glassId: number) => {
      return glassNeighbors[glassId];
    },
    [glassNeighbors],
  );

  const isValid4CornerDrop = useCallback(
    (
      glassId: number,
      anchor?: Anchor,
      gapsBetweenGlasses?: GlassDimensions,
      thickness: number[] = [],
    ) => {
      const neighbors = glassNeighbors[glassId];
      const diagonalOppositeGlass = (neighbors[
        anchor as keyof typeof neighbors
      ][0]?.data as NeighborGlass)?.glass;

      if (diagonalOppositeGlass === undefined) {
        return false;
      }

      const diagonalOppositeGlassNeighbors = getGlassNeighbors(
        diagonalOppositeGlass.id,
      );

      const distanceValidator = validDistance(
        diagonalOppositeGlassNeighbors,
        gapsBetweenGlasses,
      );
      const thicknessValidator = validThickness(
        diagonalOppositeGlassNeighbors,
        thickness,
      );
      const valid = (direction: Direction | DiagonalDirection) =>
        distanceValidator(direction) && thicknessValidator(direction);

      switch (anchor) {
        case 'topLeft':
          return valid('bottom') && valid('right');
        case 'topRight':
          return valid('bottom') && valid('left');
        case 'bottomLeft':
          return valid('top') && valid('right');
        case 'bottomRight':
          return valid('top') && valid('left');
        default:
          return false;
      }
    },
    [glassNeighbors, getGlassNeighbors],
  );

  const validDrop = useCallback(
    (
      glassId: number,
      type?: GlassGlassType,
      anchor?: Anchor,
      gapsBetweenGlasses?: GlassDimensions,
      thickness: number[] = [],
    ) => {
      let ratio = 1;

      if (type === GlassGlassType.T90 || type === GlassGlassType.T180) {
        ratio = 0.5;
      }

      const neighbors = glassNeighbors[glassId];
      const distanceValidator = validDistance(
        neighbors,
        gapsBetweenGlasses,
        ratio,
      );
      const thicknessValidator = validThickness(neighbors, thickness);
      const valid = (direction: Direction | DiagonalDirection) =>
        distanceValidator(direction) && thicknessValidator(direction);

      if (
        type === GlassGlassType.T180_4 &&
        !isValid4CornerDrop(glassId, anchor, gapsBetweenGlasses, thickness)
      ) {
        return false;
      }

      return (
        ((anchor === 'left' ||
          anchor === 'right' ||
          anchor === 'top' ||
          anchor === 'bottom') &&
          valid(anchor)) ||
        (anchor === 'topLeft' && valid('top') && valid('left')) ||
        (anchor === 'topRight' && valid('top') && valid('right')) ||
        (anchor === 'bottomLeft' && valid('bottom') && valid('left')) ||
        (anchor === 'bottomRight' && valid('bottom') && valid('right'))
      );
    },
    [glassNeighbors, isValid4CornerDrop],
  );

  const correctProductPosition = useCallback(
    (
      shape: Shape,
      type?: GlassGlassType,
      anchor?: Anchor,
      rotated?: boolean,
      gapsBetweenGlasses?: GlassDimensions,
      ratio = 1,
    ) => {
      const valid = { ...shape };
      if (type === GlassGlassType.T180_4) {
        if (anchor === 'bottomRight') {
          const rightGap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.right).div(2).times(ratio)
            : 0;
          const bottomGap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.bottom).div(2).times(ratio)
            : 0;
          valid.position.x = fixValue(
            new Big(valid.position.x)
              .plus(new Big(rotated ? valid.height : valid.width).div(2))
              .plus(rightGap),
          );
          valid.position.y = fixValue(
            new Big(valid.position.y)
              .plus(new Big(rotated ? valid.width : valid.height).div(2))
              .plus(bottomGap),
          );
        }
        if (anchor === 'bottomLeft') {
          const leftGap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.left).div(2).times(ratio)
            : 0;
          const bottomGap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.bottom).div(2).times(ratio)
            : 0;
          valid.position.x = fixValue(
            new Big(valid.position.x)
              .minus(new Big(rotated ? valid.height : valid.width).div(2))
              .minus(leftGap),
          );
          valid.position.y = fixValue(
            new Big(valid.position.y)
              .plus(new Big(rotated ? valid.width : valid.height).div(2))
              .plus(bottomGap),
          );
        }
        if (anchor === 'topLeft') {
          const leftGap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.left).div(2).times(ratio)
            : 0;
          const topGap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.top).div(2).times(ratio)
            : 0;
          valid.position.x = fixValue(
            new Big(valid.position.x)
              .minus(new Big(rotated ? valid.height : valid.width).div(2))
              .minus(leftGap),
          );
          valid.position.y = fixValue(
            new Big(valid.position.y)
              .minus(new Big(rotated ? valid.width : valid.height).div(2))
              .minus(topGap),
          );
        }
        if (anchor === 'topRight') {
          const rightGap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.right).div(2).times(ratio)
            : 0;
          const topGap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.top).div(2).times(ratio)
            : 0;
          valid.position.x = fixValue(
            new Big(valid.position.x)
              .plus(new Big(rotated ? valid.height : valid.width).div(2))
              .plus(rightGap),
          );
          valid.position.y = fixValue(
            new Big(valid.position.y)
              .minus(new Big(rotated ? valid.width : valid.height).div(2))
              .minus(topGap),
          );
        }
      } else if (type === GlassGlassType.T180) {
        if (anchor === 'right') {
          const gap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.right)
            : 0;
          valid.position.x = fixValue(
            new Big(valid.position.x)
              .plus(new Big(rotated ? valid.height : valid.width).div(2))
              .plus(gap),
            5,
          );
        }
        if (anchor === 'left') {
          const gap = gapsBetweenGlasses ? new Big(gapsBetweenGlasses.left) : 0;

          valid.position.x = fixValue(
            new Big(valid.position.x)
              .minus(new Big(rotated ? valid.height : valid.width).div(2))
              .minus(gap),
            5,
          );
        }
        if (anchor === 'top') {
          const gap = gapsBetweenGlasses ? new Big(gapsBetweenGlasses.top) : 0;
          valid.position.y = fixValue(
            new Big(valid.position.y)
              .minus(new Big(rotated ? valid.width : valid.height).div(2))
              .minus(gap),
            5,
          );
        }
        if (anchor === 'bottom') {
          const gap = gapsBetweenGlasses
            ? new Big(gapsBetweenGlasses.bottom)
            : 0;
          valid.position.y = fixValue(
            new Big(valid.position.y)
              .plus(new Big(rotated ? valid.width : valid.height).div(2))
              .plus(gap),
            5,
          );
        }
      }
      return valid;
    },
    [],
  );

  return {
    isValid4CornerDrop,
    correctProductPosition,
    validDrop,
    getGlassNeighbors,
  };
};

export default useGlassGlassProduct;
