import { EdgeType, IndentEdgeType } from '../../../../../../types';
import * as React from 'react';
import { EdgeChangeProps } from '../../containers/GlassForm/GlassDimensions';
import { HorizontalPositioning, VerticalPositioning } from './types';
import RectInput from './RectInput';
import { Divider, InputContainer } from './styles';

interface IndentInputFormProps {
  selectedId: number;
  handleChange: (props: EdgeChangeProps) => void;
  edges: Record<IndentEdgeType, number>;
  getMaxDimensions?: (
    direction: VerticalPositioning | HorizontalPositioning,
  ) => Record<EdgeType, number>;
}

const IndentInputForm = ({
  selectedId,
  edges,
  handleChange,
}: IndentInputFormProps) => {
  return (
    <div>
      <InputContainer>
        <RectInput
          key={'top-input-row'}
          wall={'top'}
          onBlur={handleChange}
          edges={edges}
          lock={false}
          selectedId={selectedId}
        />
      </InputContainer>
      <InputContainer>
        <RectInput
          key={'bottom-input-row'}
          wall={'bottom'}
          onBlur={handleChange}
          edges={edges}
          // getMaxDimensions={getMaxDimensions}
          lock={false}
          selectedId={selectedId}
        />
      </InputContainer>
      <InputContainer>
        <RectInput
          key={'center-horizontal-input-row'}
          wall={'center-horizontal'}
          onBlur={handleChange}
          edges={edges}
          // getMaxDimensions={getMaxDimensions}
          lock={false}
          selectedId={selectedId}
        />
      </InputContainer>
      <Divider />
      <InputContainer>
        <RectInput
          key={'left-input-row'}
          wall={'left'}
          onBlur={handleChange}
          edges={edges}
          // getMaxDimensions={getMaxDimensions}
          lock={false}
          selectedId={selectedId}
        />
      </InputContainer>
      <InputContainer>
        <RectInput
          key={'right-input-row'}
          wall={'right'}
          onBlur={handleChange}
          edges={edges}
          // getMaxDimensions={getMaxDimensions}
          lock={false}
          selectedId={selectedId}
        />
      </InputContainer>
      <InputContainer>
        <RectInput
          key={'center-vertical-input-row'}
          wall={'center-vertical'}
          onBlur={handleChange}
          edges={edges}
          // getMaxDimensions={getMaxDimensions}
          lock={false}
          selectedId={selectedId}
        />
      </InputContainer>
    </div>
  );
};

IndentInputForm.displayName = 'IndentInputForm';

export default IndentInputForm;
