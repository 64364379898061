import * as React from 'react';

const SvgParams = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M8.61 22a2.25 2.25 0 01-1.35-.46L5.19 20a2.37 2.37 0 01-.49-3.22 2.06 2.06 0 00.23-1.86l-.06-.16a1.83 1.83 0 00-1.12-1.22h-.16a2.34 2.34 0 01-1.48-2.94L2.93 8a2.18 2.18 0 011.948-1.642c.289-.02.579.017.852.112a1.93 1.93 0 001.78-.29l.13-.1a1.94 1.94 0 00.73-1.51v-.24A2.32 2.32 0 0110.66 2h2.55a2.26 2.26 0 011.6.67 2.37 2.37 0 01.68 1.68v.28a1.76 1.76 0 00.69 1.43l.11.08a1.74 1.74 0 001.59.26l.34-.11A2.26 2.26 0 0121.1 7.8l.79 2.52a2.36 2.36 0 01-1.46 2.93l-.2.07A1.89 1.89 0 0019 14.6a1.999 1.999 0 00.25 1.65l.26.38a2.38 2.38 0 01-.5 3.23L17 21.41a2.242 2.242 0 01-3.22-.53l-.12-.17a1.75 1.75 0 00-1.5-.78 1.8 1.8 0 00-1.43.77l-.23.33A2.251 2.251 0 019 22a2 2 0 01-.39 0zM4.4 11.62a3.83 3.83 0 012.38 2.5v.12a4 4 0 01-.46 3.62.38.38 0 000 .51L8.47 20a.251.251 0 00.37-.07l.23-.33a3.77 3.77 0 016.2 0l.12.18a.3.3 0 00.18.12.25.25 0 00.19-.05l2.06-1.56a.36.36 0 00.07-.49l-.26-.38A4 4 0 0117.1 14a3.92 3.92 0 012.49-2.61l.2-.07a.34.34 0 00.19-.44l-.78-2.49a.35.35 0 00-.2-.19.21.21 0 00-.19 0l-.34.11a3.74 3.74 0 01-3.43-.57L15 7.65a3.76 3.76 0 01-1.49-3v-.31a.37.37 0 00-.1-.26.31.31 0 00-.21-.08h-2.54a.31.31 0 00-.29.33v.25a3.901 3.901 0 01-1.52 3.09l-.13.1a3.91 3.91 0 01-3.63.59.22.22 0 00-.14 0 .28.28 0 00-.12.15L4 11.12a.36.36 0 00.22.45l.18.05z"
        fill={props.fill ?? '#272B5F'}
      />
      <path
        d="M12 15.5a3.5 3.5 0 110-7 3.5 3.5 0 010 7zm0-5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgParams = React.memo(SvgParams);
export default MemoSvgParams;
