import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';

export const STORE_NAME = `@store/modals`;

export enum Modals {
  SAVING = 'SAVING',
  SAVE_AS = 'SAVE_AS',
  CONFIRM = 'CONFIRM',
  SAVE_TEMPLATE = 'SAVE_TEMPLATE',
  VALIDATE_PROJECT = 'VALIDATE_PROJECT',
}

export type State = {
  modals: Map<string, boolean>;
  open(modal: string): void;
  close(modal: string): void;
};

const store = (set: SetState<State>, get: GetState<State>) => ({
  modals: new Map(),
  open: (modal: string) => {
    const { modals } = get();
    modals.set(modal, true);
    set({ modals: new Map(modals) });
  },
  close: (modal: string) => {
    const { modals } = get();
    modals.set(modal, false);
    set({ modals: new Map(modals) });
  },
});

export const useStore = create<State>(devtools(store, STORE_NAME));
