import * as React from 'react';
import { Item as Container, ImageWrapper } from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { NicheItem } from './types';

export interface Props {
  niche: NicheItem;
  onClick(niche: NicheItem): void;
}

const Item: React.FC<Props> = React.memo(({ niche, onClick }) => {
  return (
    <Container
      aria-label={`template-${niche.value}`}
      role="button"
      onClick={() => onClick(niche)}>
      <ImageWrapper>{niche.Image}</ImageWrapper>
      <Typography variant={TypographyVariants.H5_BOLD}>
        {niche.title}
      </Typography>
    </Container>
  );
});

Item.displayName = 'Item';

export default Item;
