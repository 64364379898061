import ReactDOM from 'react-dom';
import * as React from 'react';
import { ProviderContainer } from './styles';
import Notification from './Notification';
import {
  selectText,
  selectDismiss,
  selectVariant,
  selectShow,
  selectTimeout,
  useNotificationsStore,
} from '../../store/notifications';
import { useTransition } from 'react-spring';
import { useTimeout } from '../../hooks';

export interface Props {}

const Provider: React.FC<Props> = React.memo(() => {
  const text = useNotificationsStore(selectText);
  const variant = useNotificationsStore(selectVariant);
  const show = useNotificationsStore(selectShow);
  const dismiss = useNotificationsStore(selectDismiss);
  const timeout = useNotificationsStore(selectTimeout);

  const transition = useTransition(show, null, {
    from: { bottom: '-50px' },
    enter: { bottom: '40px' },
    leave: { bottom: '-50px' },
  });

  useTimeout(dismiss, timeout, timeout === 0, variant);

  return ReactDOM.createPortal(
    transition.map(
      ({ item, key, props }) =>
        item && (
          <ProviderContainer key={key} style={props}>
            <Notification
              text={text ?? ''}
              variant={variant}
              onDismiss={dismiss}
            />
          </ProviderContainer>
        ),
    ),
    document.body,
  );
});

Provider.displayName = 'Provider';

export default Provider;
