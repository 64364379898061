import * as React from 'react';
import { SidePanel } from '../../../../components/SidePanel';
import { useCreator } from '../../hooks';
import { Tab, TabId, Tabs } from '../../../../components/Tabs';
import { useMemo, useState } from 'react';
import { CatalogTab } from './containers/CatalogTab';
import { AttachedTab } from './containers/AttachedTab';
import { useTranslation } from 'react-i18next';

export interface Props {}

const tabRenderer = (tabId: TabId) => {
  switch (tabId) {
    default:
    case 0: {
      return <CatalogTab />;
    }
    case 1: {
      return <AttachedTab />;
    }
  }
};

const ProductsPanel: React.FC<Props> = () => {
  const { t } = useTranslation('project');
  const { toggleLeftPanel, openLeftPanel } = useCreator();
  const [activeTab, setActiveTab] = useState<TabId>(0);

  const tabs: Tab[] = useMemo(
    () => [
      { id: 0, title: t('productsPanel.tabs.catalog') },
      { id: 1, title: t('productsPanel.tabs.attached') },
    ],
    [t],
  );

  return (
    <SidePanel onToggle={toggleLeftPanel} open={openLeftPanel}>
      <Tabs
        tabs={tabs}
        activeTabId={activeTab}
        renderer={tabRenderer}
        onChange={setActiveTab}
      />
    </SidePanel>
  );
};

export default ProductsPanel;
