export enum GlassThickness {
  G6 = 6,
  G8 = 8,
  G10 = 10,
  G12 = 12,
  G442L = 44.2,
  G444L = 44.4,
  G552L = 55.2,
  G554L = 55.4,
  G662L = 66.2,
  G664L = 66.4,
}
