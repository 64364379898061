import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled.div``;

export const InputRow = styled(layout.Row)`
  align-items: flex-start;
  justify-content: space-between;
  > span {
    color: ${({ theme }) => theme.colors.dark};
    margin-top: 14px;
  }
`;

export const InputWrapper = styled(layout.Row)`
  > div:first-child {
    margin-right: 8px;
    margin-left: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  > span {
    color: ${({ theme }) => theme.colors.dark};
    margin-top: 14px;
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  margin: 20px 0;
`;
