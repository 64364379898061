import * as React from 'react';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Container,
  ErrorMessage,
  Input as StyledInput,
  InputWrapper,
  Label,
  LabelWrapper,
  LeadingIcon,
  OptionalLabel,
  TrailingButton,
  TrailingIcon,
} from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { Close, Filters } from '../icons';
import NumberAddon from './NumberAddon';
import { clamp } from '../../utils/clamp';
import { useDebounce } from '../../hooks';
import { fixValue } from '../../modules/creator/utils/fix';
import { useTranslation } from 'react-i18next';

export type InputTypes =
  | 'text'
  | 'email'
  | 'password'
  | 'number'
  | 'search'
  | 'hidden'
  | 'url';

export interface Props {
  name: string;
  testId?: string;
  type?: InputTypes;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  label?: string;
  Leading?: React.ReactNode;
  Trailing?: React.ReactNode;
  withButton?: boolean;
  ButtonIcon?: React.ReactNode;
  buttonDisabled?: boolean;
  defaultValue?: string | number;
  unit?: string;
  min?: number;
  max?: number;
  maxlength?: number;
  readonly?: boolean;
  value?: string;
  width?: number;
  fullWidth?: boolean;
  errorMessage?: string;
  autoComplete?: string;
  hideArrows?: boolean;
  clearButton?: boolean;
  autoFocus?: boolean;
  integer?: boolean;
  step?: number;
  optionalLabel?: string;
  errorFeedback?: boolean;
  withoutDebounce?: boolean;

  onChange?(value: string, name: string): void;

  onTrailingClick?(): void;

  onButtonClick?(): void;

  onFocus?(): void;

  onBlur?(value: string, name: string): void;

  onClick?(): void;
}

const Input: React.FC<Props> = React.memo(
  ({
    name,
    testId,
    type,
    placeholder,
    disabled,
    onChange,
    invalid,
    label,
    Leading,
    Trailing,
    onTrailingClick,
    withButton,
    onButtonClick,
    ButtonIcon,
    buttonDisabled,
    defaultValue,
    unit,
    min,
    max,
    maxlength,
    readonly,
    value,
    width,
    fullWidth,
    errorMessage,
    autoComplete,
    hideArrows,
    clearButton,
    autoFocus,
    optionalLabel,
    onFocus,
    step,
    onBlur,
    integer = false,
    errorFeedback,
    withoutDebounce,
    ...props
  }) => {
    const input = useRef<HTMLInputElement>(null);

    const [showClear, setShowClear] = useState(false);

    const [error, setError] = useState(errorMessage);

    const { t } = useTranslation('common');

    const inputId = useMemo(() => `${name}-input`, [name]);

    const isNumber = useMemo(() => type === 'number', [type]);

    const toggleClear = useCallback(
      (value: string) => setShowClear(value.length > 0),
      [],
    );

    const handleEvent = useCallback((e: React.SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
    }, []);

    const onSetAvailableOutput = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (type === 'number' && errorFeedback) {
          let value = e.target.value;
          const parsedValue = integer ? parseInt(value, 10) : parseFloat(value);

          const clampedValue = clamp(parsedValue, min, max);
          value = isNaN(parsedValue) ? '' : String(clampedValue);

          if (input.current && error) {
            input.current.value = value;
          }
          if (error) setError(undefined);
          onChange?.(value, e.target.name);
          toggleClear(value);
        }
      },
      [integer, max, min, onChange, toggleClear, type, error, errorFeedback],
    );

    const parsedDefaultValue = useMemo(() => {
      if (typeof defaultValue !== 'undefined') {
        if (isNumber) {
          return Number(defaultValue);
        }
        if (typeof defaultValue !== 'string') {
          return String(defaultValue);
        }
      }
      return defaultValue;
    }, [defaultValue, isNumber]);

    const onNumberIncrease = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        handleEvent(e);
        if (input.current) {
          const newValue =
            step && step < 1
              ? fixValue(Number(input.current.value) + step)
              : Number(input.current.value) + (step ?? 1);
          if (typeof max !== 'undefined' && newValue > max) {
            return;
          }
          const stringify = String(newValue);
          input.current.value = stringify;
          onChange?.(stringify, name);
          toggleClear(stringify);
        }
      },
      [handleEvent, step, max, onChange, name, toggleClear],
    );

    const onNumberDecrease = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        handleEvent(e);
        if (input.current) {
          const newValue =
            step && step < 1
              ? fixValue(Number(input.current.value) - step)
              : Number(input.current.value) - (step ?? 1);
          if (typeof min !== 'undefined' && newValue < min) {
            return;
          }
          const stringify = String(newValue);
          input.current.value = stringify;
          onChange?.(stringify, name);
          toggleClear(stringify);
        }
      },
      [handleEvent, step, min, onChange, name, toggleClear],
    );

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (type === 'number') {
          const parsedValue = integer ? parseInt(value, 10) : parseFloat(value);
          const clampedValue = clamp(parsedValue, min, max);
          value = isNaN(parsedValue) ? '' : String(clampedValue);

          if (errorFeedback) {
            if (parsedValue && error) setError(undefined);
            if (min && parsedValue < min) {
              setError(t('value.min', { value: min }));
            }
            if (max && parsedValue > max) {
              setError(t('value.max', { value: max }));
            }

            if (input.current && !error && !onBlur) {
              input.current.value = value;
            }

            onChange?.(value, e.target.name);
            toggleClear(value);
            return;
          }

          if (input.current) {
            input.current.value = value;
          }
        }

        onChange?.(value, e.target.name);
        toggleClear(value);
      },
      [integer, max, min, onChange, toggleClear, type, error, errorFeedback, t],
    );

    const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      if (type === 'number') {
        const parsedValue = integer ? parseInt(value, 10) : parseFloat(value);
        const clampedValue = clamp(parsedValue, min, max);
        value = isNaN(parsedValue) ? '' : String(clampedValue);

        if (input.current) {
          input.current.value = value;
        }
      }

      onBlur?.(value, e.target.name);
    };

    const onBlurWithFeedback = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onSetAvailableOutput(e);
        handleBlur(e);
      },
      [onSetAvailableOutput, handleBlur],
    );

    const onFocusWithFeedback = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onFocus?.();
        onSetAvailableOutput(e);
      },
      [onSetAvailableOutput, onFocus],
    );

    const debouncedHandleChange = useDebounce(handleChange);

    const onHandleChange =
      (typeof min !== 'undefined' && !withoutDebounce) ||
      (typeof max !== 'undefined' && !withoutDebounce)
        ? debouncedHandleChange
        : handleChange;

    const handleOnBlur =
      type === 'number' && errorFeedback ? onBlurWithFeedback : handleBlur;

    const handleOnFocus =
      type === 'number' && errorFeedback ? onFocusWithFeedback : onFocus;

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && type === 'number') {
        input.current?.blur();
      }
    };

    const handleWheel = (e: React.WheelEvent<HTMLInputElement>) =>
      e.currentTarget.blur();

    const clear = useCallback(() => {
      if (input.current) {
        input.current.value = '';
        onChange?.('', name);
        toggleClear(input.current.value);
      }
    }, [name, onChange, toggleClear]);

    useEffect(() => {
      if (input.current && typeof value === 'string') {
        input.current.value = value;
      }
    }, [value]);

    return (
      <Container {...props} labeled={!!label}>
        {label && (
          <LabelWrapper>
            <Typography variant={TypographyVariants.H5}>
              <Label htmlFor={inputId}>{label}</Label>
            </Typography>
            {optionalLabel && (
              <OptionalLabel>
                <Typography variant={TypographyVariants.H5}>
                  <span>&nbsp;</span>
                  {`(${optionalLabel})`}
                </Typography>
              </OptionalLabel>
            )}
          </LabelWrapper>
        )}
        <InputWrapper disabled={disabled} width={width} fullWidth={fullWidth}>
          {Leading && <LeadingIcon>{Leading}</LeadingIcon>}
          {(Trailing || showClear) && (
            <TrailingIcon
              data-testid="trailing-icon-button"
              role={
                !disabled && (onTrailingClick || clearButton)
                  ? 'button'
                  : undefined
              }
              onClick={
                !disabled ? (clearButton ? clear : onTrailingClick) : undefined
              }
              withButton={withButton}
              hasNumber={isNumber && !hideArrows}>
              {clearButton ? <Close /> : Trailing}
            </TrailingIcon>
          )}
          <StyledInput
            ref={input}
            aria-label={!label ? name : undefined}
            data-testid={testId}
            name={name}
            id={inputId}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onHandleChange}
            invalid={invalid || !!error}
            hasLeading={!!Leading}
            hasTrailing={!!Trailing}
            hasButton={withButton}
            defaultValue={parsedDefaultValue}
            maxLength={maxlength}
            readOnly={readonly}
            autoComplete={autoComplete}
            hideArrows={hideArrows}
            autoFocus={autoFocus}
            hasUnit={!!unit}
            min={min}
            max={max}
            onFocus={handleOnFocus}
            step={step}
            onBlur={handleOnBlur}
            onKeyDown={handleKeyDown}
            onWheel={handleWheel}
          />
          {isNumber && !hideArrows && (
            <NumberAddon
              unit={unit}
              onIncrease={onNumberIncrease}
              onDecrease={onNumberDecrease}
              withButton={withButton}
              disabled={disabled || readonly}
            />
          )}
          {withButton && (
            <TrailingButton
              onClick={onButtonClick}
              disabled={buttonDisabled || disabled}>
              {ButtonIcon ?? <Filters />}
            </TrailingButton>
          )}
        </InputWrapper>
        {invalid && errorMessage && (
          <ErrorMessage>
            <Typography variant={TypographyVariants.H5}>
              {errorMessage}
            </Typography>
          </ErrorMessage>
        )}
        {error && errorFeedback ? (
          <ErrorMessage>
            <Typography variant={TypographyVariants.H5}>
              {errorMessage}
            </Typography>
            <Typography variant={TypographyVariants.H5}>{error}</Typography>
          </ErrorMessage>
        ) : null}
      </Container>
    );
  },
);

Input.displayName = 'Input';

export default Input;
