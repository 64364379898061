import styled from 'styled-components';
import { layout } from '../../styles';

export const Container = styled(layout.Row)`
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 16px;
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  z-index: 15;
`;

export const Section = styled(layout.Row)`
  flex-shrink: 0;
  margin-right: 32px;
  &:last-of-type {
    margin-right: 0;
    margin-left: 32px;
  }
  > div {
    margin-right: 8px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const SectionWrapper = styled(layout.Row)`
  align-items: center;
`;

export const CreateOrderWrapper = styled.div`
  position: relative;
`;
