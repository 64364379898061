import * as React from 'react';
import { Container, Button, Icon, CustomWrapper } from './styles';
import { Typography, TypographyVariants } from '../Typography';
import { useTranslation } from 'react-i18next';
import { Add } from '../icons';
import { InsertCategory, InsertOption } from './types';
import { NicheItem } from '../InsertNiche';
import List from './List';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useClickOutside } from '../../hooks';
import CategoryList from './CategoryList';
import { InsertNiche } from '../InsertNiche';
import { Tooltip } from '../Tooltip';

export interface Props {
  categories: InsertCategory[];
  onSelect(option: InsertOption | NicheItem): void;
  name?: string;
}

const Insert: React.FC<Props> = React.memo(({ categories, onSelect, name }) => {
  const { t } = useTranslation('project');
  const [active, setActive] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const singleCategory = categories.length === 1;

  const handleSelect = useCallback(
    (option: InsertOption | NicheItem) => {
      onSelect(option);
      setActive(false);
    },
    [onSelect],
  );

  const onClickOutside = useCallback(() => setActive(false), []);

  const disabled = useMemo(() => singleCategory && categories[0].disabled, [
    categories,
    singleCategory,
  ]);

  useClickOutside({ elementRef, onClickOutside });

  if (categories.length > 0) {
    return (
      <Container aria-label={name} data-testid="insert" ref={elementRef}>
        <Tooltip
          text={categories[0].disabledReason ?? ''}
          delay={1000}
          disabled={!disabled}>
          <Button
            disabled={disabled}
            active={active}
            aria-label={
              singleCategory ? categories[0].title : t('toolbar.insert')
            }
            role="button"
            onClick={() => setActive(!active)}>
            <Icon>
              <Add />
            </Icon>
            <Typography variant={TypographyVariants.H4}>
              {singleCategory ? categories[0].title : t('toolbar.insert')}
            </Typography>
          </Button>
        </Tooltip>
        {active &&
          (singleCategory ? (
            categories[0].niche ? (
              <CustomWrapper>
                <InsertNiche
                  onSelect={handleSelect}
                  title={t('toolbar.insertNiche')}
                />
              </CustomWrapper>
            ) : (
              <List options={categories[0].options} onSelect={handleSelect} />
            )
          ) : (
            <CategoryList categories={categories} onSelect={handleSelect} />
          ))}
      </Container>
    );
  }
  return null;
});

Insert.displayName = 'Insert';

export default Insert;
