import { AxiosInstance } from 'axios';

import axios from '../config';
import { Endpoints } from '../endpoints';
import { EditParametersCommand, ParametersInfo } from '../models/parameters';

class ParametersApi {
  constructor(private readonly http: AxiosInstance) {}

  public getParameters = () => {
    return this.http.get<ParametersInfo>(`${Endpoints.PARAMETERS}`);
  };

  public updateParameters = (command: EditParametersCommand) =>
    this.http.put<ParametersInfo>(`${Endpoints.PARAMETERS}`, command);
}

export const parametersApi = new ParametersApi(axios);
export default parametersApi;
