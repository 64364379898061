import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import { Button } from '../../../../components/Button';
import { Container, Wrapper, Header, InfoBox, Tips } from './styles';

export interface Props {
  email: string;
  onClose(): void;
  buttonTile?: string;
}

const EmailChanged: React.FC<Props> = React.memo(
  ({ email, onClose, buttonTile, ...props }) => {
    const { t } = useTranslation('emailChange');

    return (
      <Container {...props}>
        <Wrapper>
          <Header variant={TypographyVariants.H1}>{t('headerChange')}</Header>
          <InfoBox>
            <Typography variant={TypographyVariants.H5}>
              <Trans t={t} i18nKey={'notification.success.change.header'}>
                {{ email }}
              </Trans>
            </Typography>
          </InfoBox>
          <Tips>
            <Typography variant={TypographyVariants.H4}>
              {t('notification.success.change.tips.header')}
            </Typography>
            <Typography variant={TypographyVariants.H4}>
              {t('notification.success.change.tips.tip1')}
            </Typography>
            <Typography variant={TypographyVariants.H4}>
              {t('notification.success.change.tips.tip2')}
            </Typography>
            <Typography variant={TypographyVariants.H4}>
              {t('notification.success.change.tips.tip3')}
            </Typography>
          </Tips>
          <Button
            title={buttonTile || t('forms.buttons.close')}
            onClick={onClose}
            fullWidth
          />
        </Wrapper>
      </Container>
    );
  },
);

EmailChanged.displayName = 'EmailChanged';

export default EmailChanged;
