import * as React from 'react';
import { Form, ButtonsWrapper } from './styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { BottomModal } from '../../../../components/Modal';
import { Input } from '../../../../components/Input';
import { Button, ButtonTypes } from '../../../../components/Button';

export interface Props {
  show: boolean;
  onCancel(): void;
  onSubmit(templateName: string, description?: string): void;
}

interface FormikValues {
  templateName: string;
  description?: string;
}

const initialValues: FormikValues = {
  templateName: '',
};

const validationSchema = Yup.object().shape({
  templateName: Yup.string().required(),
  description: Yup.string().optional(),
});

const SaveTemplateModal: React.FC<Props> = React.memo(
  ({ show, onCancel, onSubmit }) => {
    const { t } = useTranslation('project');

    const formik = useFormik<FormikValues>({
      initialValues,
      validationSchema,
      onSubmit: ({ templateName, description }) =>
        onSubmit(templateName, description),
    });

    const handleInputChange = useCallback(
      (value: string, name: string) => {
        formik.setFieldValue(name, value);
        formik.setFieldTouched(name);
      },
      [formik],
    );

    return (
      <BottomModal show={show} title={t('modals.save.title')}>
        <Form onSubmit={formik.handleSubmit}>
          <Input
            name="templateName"
            label={t('modals.save.form.templateName')}
            placeholder={t('modals.save.form.templateName')}
            onChange={handleInputChange}
            invalid={!!formik.errors.templateName}
            autoFocus
          />
          <Input
            name="description"
            label={t('modals.save.form.description')}
            placeholder={t('modals.save.form.description')}
            onChange={handleInputChange}
            invalid={!!formik.errors.description}
          />
          <ButtonsWrapper>
            <Button
              testId="cancel-button"
              title={t('modals.cancel')}
              type={ButtonTypes.SECONDARY}
              onClick={onCancel}
              noMinWidth
            />
            <Button
              testId="submit-button"
              htmlButtonType="submit"
              title={t('modals.save.form.submitTemplate')}
              disabled={!formik.isValid || !formik.dirty}
            />
          </ButtonsWrapper>
        </Form>
      </BottomModal>
    );
  },
);

SaveTemplateModal.displayName = 'SaveTemplateModal';

export default SaveTemplateModal;
