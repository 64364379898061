import * as React from 'react';
import { Arrow } from 'react-konva';
import { selectTheme, useConfigStore } from '../../store/config';

export interface Props {
  points: number[];
}

const DistanceArrow: React.FC<Props> = React.memo(({ points }) => {
  const theme = useConfigStore(selectTheme);

  return (
    <Arrow
      points={points}
      stroke={theme.colors.accentBlue}
      fill={theme.colors.accentBlue}
      strokeWidth={1}
      pointerWidth={5}
      pointerLength={5}
      pointerAtBeginning
    />
  );
});

DistanceArrow.displayName = 'DistanceArrow';

export default DistanceArrow;
