import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { ButtonsWrapper, Form } from './styles';
import { BottomModal } from '../../../../components/Modal';
import { Input } from '../../../../components/Input';
import { Button, ButtonTypes } from '../../../../components/Button';
import { OptionObject, Select } from '../../../../components/Select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ClientSearch } from '../../../../services/api/models/client';
import { Option } from '../../../../components/Select/SelectBox';

export interface Props {
  show: boolean;
  clients: ClientSearch[];
  onCancel(): void;
  onSubmit(projectName: string, clientId?: string): void;
  onClientSearch(input: string): Promise<void>;
}

interface FormikValues {
  projectName: string;
  client?: OptionObject;
}

const initialValues: FormikValues = {
  projectName: '',
};

const validationSchema = Yup.object().shape({
  projectName: Yup.string().required(),
  client: Yup.object()
    .shape({
      name: Yup.string(),
      option: Yup.object().shape({
        value: Yup.string(),
        title: Yup.string(),
      }),
    })
    .optional(),
});

const SaveAsModal: React.FC<Props> = React.memo(
  ({ show, onCancel, onSubmit, clients, onClientSearch }) => {
    const { t } = useTranslation('project');

    const formik = useFormik<FormikValues>({
      initialValues,
      validationSchema,
      onSubmit: ({ projectName, client }) =>
        onSubmit(projectName, client?.option?.value),
    });

    const handleInputChange = useCallback(
      (value: string, name: string) => {
        formik.setFieldValue(name, value);
        formik.setTouched({ ...formik.touched, [name]: true });
      },
      [formik],
    );

    const handleClientSelect = useCallback(
      (client: OptionObject) => {
        if (client.option) {
          formik.setFieldValue('client', client);
          formik.setTouched({ ...formik.touched, client: true });
        } else {
          formik.setFieldValue('client', undefined);
        }
      },
      [formik],
    );

    const selectOptions = useMemo<Option[]>(
      () =>
        clients.map((client) => ({
          title: client.name,
          value: String(client.id),
        })),
      [clients],
    );

    useEffect(() => {
      show && onClientSearch('');
    }, [show, onClientSearch]);

    return (
      <BottomModal show={show} title={t('modals.save.title')} timeout={0}>
        <Form onSubmit={formik.handleSubmit}>
          <Input
            name="projectName"
            label={t('modals.save.form.projectName')}
            placeholder={t('modals.save.form.projectName')}
            onChange={handleInputChange}
            invalid={!!formik.errors.projectName}
            autoFocus
          />
          <Select
            name="client"
            label={t('modals.save.form.clientLabel')}
            placeholder={t('modals.save.form.client')}
            options={selectOptions}
            onAsyncSearch={onClientSearch}
            onChange={handleClientSelect}
            invalid={!!formik.errors.client}
            selected={formik.values.client?.option}
            clearButton
            unfoldOnTop
            search
            async
          />
          <ButtonsWrapper>
            <Button
              testId="cancel-button"
              title={t('modals.cancel')}
              type={ButtonTypes.SECONDARY}
              onClick={onCancel}
              noMinWidth
            />
            <Button
              testId="submit-button"
              htmlButtonType="submit"
              title={t('modals.save.form.submit')}
              disabled={!formik.isValid || !formik.dirty}
            />
          </ButtonsWrapper>
        </Form>
      </BottomModal>
    );
  },
);

SaveAsModal.displayName = 'SaveAsModal';

export default SaveAsModal;
