import { AxiosInstance } from 'axios';

import axios from '../config';
import { Endpoints } from '../endpoints';
import { FileReference } from '../models/fileUpload';

class FileUploadApi {
  public constructor(private http: AxiosInstance) {}

  public uploadFile = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<FileReference>(`${Endpoints.FILE}/upload`, formData);
  };
}

const fileUploadApi = new FileUploadApi(axios);
export default fileUploadApi;
