import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AxiosError } from 'axios';

import {
  CancelOrderCommand,
  Order,
  OrderFromOfferCommand,
  TransmitOrderCommand,
} from '../../services/api/models/order';
import { orderApi, orderAdminApi } from '../../services/api/services';
import { createAsyncAction } from '../middlewares/actions';
import {
  OrderEditRequest,
  OrderProductsRequest,
} from '../../services/api/services/order';

export const STORE_NAME = `@store/orders/order`;

export type State = {
  loading: boolean;
  fetch(
    id: number,
    admin: boolean,
    onSuccess?: (order: Order) => void,
    onError?: () => void,
  ): Promise<void>;
  recalculate(
    orderId: number,
    payload: OrderProductsRequest,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  transmit(
    orderId: number,
    command: TransmitOrderCommand,
    onSuccess?: (systemId: string | null) => void,
    onError?: () => void,
  ): Promise<void>;
  cancel(
    orderId: number,
    command: CancelOrderCommand,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  create(
    offerId: number,
    command: OrderFromOfferCommand,
    onSuccess?: (orderId: number) => void,
    onError?: () => void,
  ): Promise<void>;
  createFromProject(
    projectTd: number,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  edit(
    orderId: number,
    command: OrderEditRequest,
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void>;
  data?: Order;
  error?: AxiosError;
};

const fetchAction = (set: SetState<State>) => (
  id: number,
  admin: boolean,
  onSuccess?: (order: Order) => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    const { data } = admin
      ? await orderAdminApi.getOrder(id)
      : await orderApi.getOrder(id);
    set({ data });
    onSuccess?.(data);
  }, onError);

const transmitAction = (set: SetState<State>) => (
  orderId: number,
  command: TransmitOrderCommand,
  onSuccess?: (id: string) => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    const { data } = await orderApi.transmitOrder(orderId, command);
    set({ data });
    onSuccess?.(data.systemId ?? '');
  }, onError);

const cancelAction = (set: SetState<State>) => (
  orderId: number,
  command: CancelOrderCommand,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      const { data } = await orderAdminApi.cancelOrder(orderId, command);
      set({ data });
    },
    onError,
    onSuccess,
  );

const recalculateAction = (set: SetState<State>) => (
  orderId: number,
  payload: OrderProductsRequest,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      const { data } = await orderApi.recalculateOrder(orderId, payload);
      set({ data });
    },
    onError,
    onSuccess,
  );

const createAction = (set: SetState<State>) => (
  offerId: number,
  command: OrderFromOfferCommand,
  onSuccess?: (orderId: number) => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(async () => {
    const { data: order } = await orderApi.createOrderFromOffer(
      offerId,
      command,
    );
    onSuccess?.(order.id);
  }, onError);

const createFromProjectAction = (set: SetState<State>) => (
  projectId: number,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      await orderApi.createOrderFromProject(projectId);
    },
    onError,
    onSuccess,
  );

const editAction = (set: SetState<State>) => (
  orderId: number,
  command: OrderEditRequest,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  createAsyncAction(set)(
    async () => {
      await orderApi.editOrder(orderId, command);
    },
    onError,
    onSuccess,
  );

const store = (set: SetState<State>) => ({
  loading: false,
  fetch: fetchAction(set),
  recalculate: recalculateAction(set),
  transmit: transmitAction(set),
  cancel: cancelAction(set),
  create: createAction(set),
  createFromProject: createFromProjectAction(set),
  edit: editAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
