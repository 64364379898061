import Konva from 'konva';
import { useCallback } from 'react';
import {
  selectOpenContextMenu,
  useSelectStore,
  ShapeType,
} from '../store/select';

export interface UseContextMenu {
  open(e: Konva.KonvaEventObject<PointerEvent>): void;
  preventDefault(e: Konva.KonvaEventObject<PointerEvent>): void;
}

const NAVIGATION_OFFSET = 57;

export const preventDefault = (e: Konva.KonvaEventObject<PointerEvent>) =>
  e.evt.preventDefault();

const useContextMenu = (
  type: ShapeType,
  itemId: number | string,
  isGlassGlass?: boolean,
): UseContextMenu => {
  const openMenu = useSelectStore(selectOpenContextMenu);

  const open = useCallback(
    (e: Konva.KonvaEventObject<PointerEvent>) => {
      e.evt.preventDefault();
      const { x, y } = e.evt;
      const position = { x, y: y - NAVIGATION_OFFSET };
      openMenu({ position, type, itemId, isGlassGlass });
    },
    [isGlassGlass, itemId, openMenu, type],
  );

  return {
    open,
    preventDefault,
  };
};

export default useContextMenu;
