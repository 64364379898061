import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AxiosResponse } from 'axios';

import { BasicOffer } from '../../services/api/models/offer';
import { FilterRequest } from '../../services/api/services/offer';
import { offerApi } from '../../services/api/services';
import { Page } from '../../services/api/models/requests';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/offers/filter`;

export type State = {
  loading: boolean;
  filterOffers(filters: FilterRequest): Promise<void>;
  filterClientOffers(id: number, filters: FilterRequest): Promise<void>;
  data?: Page<BasicOffer>;
  error?: AxiosResponse;
};

const filterOffersAction = (set: SetState<State>) => (filters: FilterRequest) =>
  createAsyncAction(set)(async () => {
    const { data } = await offerApi.filterOffers(filters);
    set({ data });
  });

const filterClientOffersAction = (set: SetState<State>) => (
  id: number,
  filters: FilterRequest,
) =>
  createAsyncAction(set)(async () => {
    const { data } = await offerApi.filterClientOffers(id, filters);
    set({ data });
  });

const store = (set: SetState<State>) => ({
  loading: false,
  filterOffers: filterOffersAction(set),
  filterClientOffers: filterClientOffersAction(set),
});

export const useStore = create<State>(devtools(store, STORE_NAME));
