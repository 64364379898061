import * as React from 'react';

const SvgHelp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16.001A8 8 0 0112 20z"
        fill={props.fill ?? '#272B5F'}
      />
      <path
        d="M12 6a3.5 3.5 0 00-3.5 3.5 1 1 0 102 0A1.5 1.5 0 1112 11a1 1 0 00-1 1v2a1 1 0 002 0v-1.16A3.49 3.49 0 0012 6zM12 18a1 1 0 100-2 1 1 0 000 2z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgHelp = React.memo(SvgHelp);
export default MemoSvgHelp;
