import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled(layout.Row)`
  cursor: pointer;
  margin: 16px 0;
`;

export const GlassShape = styled.div`
  width: 64px;
  height: 92px;
  background-color: ${({ theme }) => theme.colors.accentBlue2};
  border: 1px solid ${({ theme }) => theme.colors.grey};
`;

export const DataColumn = styled(layout.Column)`
  flex: 1;
  justify-content: space-between;
  margin-left: 16px;
`;

export const TitleRow = styled(layout.Row)`
  justify-content: space-between;
  > span {
    color: ${({ theme }) => theme.colors.mainBlue};
  }
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    opacity: 0.5;
  }
  > svg {
    width: 16px;
    height: 16px;
    > path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export const ItemsWrapper = styled.div``;

export const DataItem = styled(layout.Row)`
  margin: 4px 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  > span {
    color: ${({ theme }) => theme.colors.grey};
    &:first-of-type {
      color: ${({ theme }) => theme.colors.dark};
      flex-basis: 45%;
    }
  }
`;
