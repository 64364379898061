import { OptionValue } from './Switch';

export const composeSelected = (
  value: OptionValue,
  selected: OptionValue[],
  multi = false,
): OptionValue[] => {
  let newSelected: OptionValue[];
  if (multi) {
    selected.includes(value)
      ? (newSelected = selected.filter(
          (selectedValue) => selectedValue !== value,
        ))
      : (newSelected = [...selected, value]);
  } else {
    if (selected[0] === value) {
      return selected;
    }
    newSelected = [value];
  }
  return newSelected;
};
