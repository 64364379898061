import Big, { BigSource } from 'big.js';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectionType } from '../../../services/api/models/connection';
import {
  CutType,
  Glass,
  GlassDimensions,
  GlassPropertiesSearch,
} from '../../../services/api/models/glass';
import {
  BaseType,
  ProductModel,
  ProductOrientation,
  ProductSearch,
} from '../../../services/api/models/product';
import { checkIfVSG, cutGlass } from '../../../utils/glass';
import { CONNECT_OFFSET, DEFAULT_WIDTH } from '../components/Pipe';
import {
  ANCHOR_SIZE,
  NEIGHBOR_ADJACENT_ANCHORS,
  SAME_GLASS_ADJACENT_ANCHORS,
} from '../constants/anchors';
import { DEFAULT_RATIO } from '../store/config';
import { GlassTarget, ProductTarget } from '../store/drag';
import {
  CreatorProduct,
  GlassChild,
  selectProducts,
  useProductsStore,
} from '../store/products';
import {
  selectData,
  selectProjectGlasses,
  selectProjectPosition,
  selectProjectProducts,
  selectUpdateGlass,
  selectUpdateManyProducts,
  selectUsedProducts,
  useProjectStore,
} from '../store/project';
import { Shape as ShapeType } from '../types';
import { Anchor } from '../types/Anchor';
import { resizeBarToFitGlass } from '../utils/bar';
import { fixValue } from '../utils/fix';
import {
  Direction,
  Neighbor,
  NeighborGlass,
  NeighborType,
} from '../utils/neighbors';
import { DiagonalDirection } from '../utils/shadows';
import useGaps from './useGaps';
import useGlassGlassProduct, { GlassGlassType } from './useGlassGlassProduct';
import { Shape } from '../../space';
import { findXOnLine, findYOnLine } from '../../../utils/shape';
import map from 'lodash/map';
import { Vector2d } from 'konva/lib/types';
import { useUpdateGlassGap } from './useUpdateGlassGap';

const LIFTING_HINGE_GAP = 8; // in mm

enum INVALID_REASON {
  CANCELED = 'canceled',
  SAME_TYPE_HINGE = 'sameTypeHinge',
}

type LiftingHingeDecision = 'addGap' | 'ignoreGap' | 'cancel';

const liftingHingeDecisions: LiftingHingeDecision[] = ['addGap', 'ignoreGap'];

export interface ProductGaps {
  gapFromEdge: number;
  gapFromGlass: number;
  gapFromWall: number;
}

export const defaultGaps: ProductGaps = {
  gapFromEdge: 0,
  gapFromGlass: 0,
  gapFromWall: 0,
};

interface SameHandlesActions {
  confirm: () => void;
  reject: () => void;
}

interface LiftingHingeActions {
  select: (decision: LiftingHingeDecision) => void;
  cancel: () => void;
}

export interface ValidPositionOnGlassConfig {
  shape: ShapeType;
  baseType: BaseType;
  glass: Glass;
  glassProperties: GlassPropertiesSearch;
  isGlassGlass: boolean;
  glassGlassType?: GlassGlassType;
  gapFromEdge?: ProductGaps;
  anchor: Anchor;
  rotated?: boolean;
}

export type GetGlassProductsFunc = (
  target: GlassTarget,
) => Array<{ product: ProductModel; usedProduct: ProductSearch }>;

export type HasGlassInDirectionFunc = (
  glass: Glass,
  direction: ProductOrientation,
) => boolean;

const sameHandlesActions: SameHandlesActions = {
  confirm: () => null,
  reject: () => null,
};

const liftingHingeActions: LiftingHingeActions = {
  select: (decision) => decision,
  cancel: () => null,
};

const ignoringGapProducts: BaseType[] = [BaseType.BAR, BaseType.GASKET];

export interface UseDropValidators {
  validPositionOnGlass(config: ValidPositionOnGlassConfig): ShapeType;

  validProductsOnGlass(target: GlassTarget): ProductModel[];

  validPositionOnProduct(
    shape: ShapeType,
    target: ProductTarget,
    dropType: BaseType,
  ): ShapeType;

  validBaseType(
    product: ProductSearch,
    target: GlassTarget,
    onTargetEdit?: (newTarget: GlassTarget) => void,
  ): Promise<string | boolean>;

  validGlassGap(
    productBaseType: BaseType,
    productMountTypes: ProductSearch['mountTypes'],
    glassToGlassGap: GlassDimensions,
    wallToGlassGap: GlassDimensions,
    target: GlassTarget,
    isGlassGlass?: boolean,
    glassGlassType?: GlassGlassType,
  ): Glass;

  validVSG(product: ProductSearch, target: GlassTarget): boolean;

  validRequiredProducts(product: ProductSearch, target: GlassTarget): boolean;

  sameHandlesActions: SameHandlesActions;
  liftingHingeActions: LiftingHingeActions;
  liftingHingeDecisions: LiftingHingeDecision[];
  liftingHingeOptions: string[];
  showSameHandlesModal: boolean;
  showLiftingHingeModal: boolean;

  getUsedProductsWithProjectProducts: GetGlassProductsFunc;
  hasGlassInDirection: HasGlassInDirectionFunc;
}

const useDropValidators = (): UseDropValidators => {
  const { t } = useTranslation('project');
  const creatorProducts = useProductsStore(selectProducts);
  const projectProducts = useProjectStore(selectProjectProducts);
  const usedProducts = useProjectStore(selectUsedProducts);
  const projectGlasses = useProjectStore(selectProjectGlasses);
  const updateGlass = useProjectStore(selectUpdateGlass);
  const updateManyProducts = useProjectStore(selectUpdateManyProducts);
  const projectData = useProjectStore(selectData);
  const templatePosition = useProjectStore(selectProjectPosition);

  const [showSameHandlesModal, setShowSameHandlesModal] = useState(false);
  const [showLiftingHingeModal, setShowLiftingHingeModal] = useState(false);
  const {
    updateGlassGap4Glasses,
    updateGlassNeighborsGap,
    updateGlassGap,
  } = useUpdateGlassGap();
  const {
    correctProductPosition: correctGlassGlassProductPosition,
    getGlassNeighbors,
  } = useGlassGlassProduct();

  const { findGapOnAnchor, findTopGap } = useGaps({
    glasses: projectGlasses,
    templatePosition,
    templateDimensions: projectData.dimensions,
  });

  const liftingHingeOptions = useMemo(
    () => [
      t('modals.liftingHinge.options.add'),
      t('modals.liftingHinge.options.ignore'),
    ],
    [t],
  );

  const getFilteredGlassProducts = useCallback(
    (filter: (product: GlassChild) => boolean) =>
      creatorProducts
        .filter((item) => item.parentType === 'glass')
        .map((item) => item as GlassChild)
        .filter(filter)
        .map((item) => usedProducts[item.data.productId])
        .filter((item) => typeof item !== 'undefined'),
    [creatorProducts, usedProducts],
  );

  const getSameAnchorProducts = useCallback(
    (target: GlassTarget, includeSameGlassEdge = false) => {
      const sameAnchorProducts = getFilteredGlassProducts(
        ({ parent, anchor }) =>
          String(parent.id) === target.targetId && anchor === target.anchor,
      );

      /*
       * We need to check current edge corners, opposite edge and their corners
       * as well as bottom corners. On following diagram A means current anchor
       * whereas # all the anchors we must take care of.
       *
       *   +---##--++
       *   |   A#   |
       *   +---##---+
       *   +---##---+
       *   |   ||   |
       *   +---++---+
       */
      if (includeSameGlassEdge) {
        const sameGlassAdjacentProducts = getFilteredGlassProducts(
          ({ parent, anchor }) =>
            String(parent.id) === target.targetId &&
            SAME_GLASS_ADJACENT_ANCHORS[target.anchor].includes(anchor),
        );

        sameAnchorProducts.push(...sameGlassAdjacentProducts);

        const neighbors = getGlassNeighbors(target.glass.id);
        const allNeighborsWithDirection = Object.getOwnPropertyNames(
          neighbors,
        ).reduce<
          { neighbor: Neighbor; direction: Direction | DiagonalDirection }[]
        >((acc, key) => {
          const direction = key as Direction | DiagonalDirection;
          const mappedNeighbors = neighbors[direction].map((neighbor) => ({
            neighbor,
            direction,
          }));
          acc.push(...mappedNeighbors);
          return acc;
        }, []);

        const neighborAnchorProducts = allNeighborsWithDirection
          .filter(({ neighbor }) => neighbor.data.type !== NeighborType.WALL)
          .reduce<ProductSearch[]>((products, { neighbor, direction }) => {
            const neighborGlass = neighbor.data as NeighborGlass;

            const neighborProducts = getFilteredGlassProducts(
              ({ parent, anchor }) =>
                parent.id === neighborGlass.glass.id &&
                NEIGHBOR_ADJACENT_ANCHORS[target.anchor][direction].includes(
                  anchor,
                ),
            );

            products.push(...neighborProducts);
            return products;
          }, []);

        sameAnchorProducts.push(...neighborAnchorProducts);
      }

      return sameAnchorProducts;
    },
    [getGlassNeighbors, getFilteredGlassProducts],
  );

  const getGlassProjectProducts = useCallback(
    (target: GlassTarget) =>
      projectProducts.filter(({ connections }) =>
        connections.some(
          ({ type, targetId }) =>
            type === 'GLASS' && targetId === target.targetId,
        ),
      ),
    [projectProducts],
  );

  const getGlassProducts = useCallback(
    (target: GlassTarget) => {
      const { targetId } = target;

      return creatorProducts.filter(
        (creatorProduct) =>
          creatorProduct.parentType === 'glass' &&
          String(creatorProduct.parent.id) === targetId,
      );
    },
    [creatorProducts],
  );

  const getUsedProductsForCreatorProducts = useCallback(
    (creatorProducts: CreatorProduct[]) =>
      creatorProducts
        .map((item) => usedProducts[item.data.productId])
        .filter((item) => typeof item !== 'undefined'),
    [usedProducts],
  );

  const getUsedProductsWithProjectProducts = useCallback(
    (
      target: GlassTarget,
    ): Array<{ product: ProductModel; usedProduct: ProductSearch }> => {
      const products = getGlassProjectProducts(target);

      return products
        .map((item) => ({
          product: item,
          usedProduct: usedProducts[item.productId],
        }))
        .filter((item) => typeof item.usedProduct !== 'undefined');
    },
    [getGlassProjectProducts, usedProducts],
  );

  const alignCornerPositions = useCallback(
    (shape: ShapeType, parent: Shape, anchor?: Anchor): ShapeType => {
      switch (anchor) {
        case 'bottomRight': {
          return {
            ...shape,
            position: {
              y: parent.corners['bottom-right'][1],
              x: parent.corners['bottom-right'][0],
            },
          };
        }
        case 'bottomLeft': {
          return {
            ...shape,
            position: {
              y: parent.corners['bottom-left'][1],
              x: parent.corners['bottom-left'][0],
            },
          };
        }
        case 'topRight': {
          return {
            ...shape,
            position: {
              y: parent.corners['top-right'][1],
              x: parent.corners['top-right'][0],
            },
          };
        }
        case 'topLeft': {
          return {
            ...shape,
            position: {
              y: parent.corners['top-left'][1],
              x: parent.corners['top-left'][0],
            },
          };
        }
        default: {
          return shape;
        }
      }
    },
    [],
  );

  const validVSG = useCallback(
    (product: ProductSearch, target: GlassTarget) => {
      const isVSG = checkIfVSG(target.glass.thickness, target.glass.hardening);
      if (isVSG) {
        const { glassProperties } = product;
        return !glassProperties.some(
          (property) =>
            property.cutType === CutType.EVERY_HINGE ||
            property.cutType === CutType.BIG_INNER_ON_EDGE,
        );
      }
      return true;
    },
    [],
  );

  const validPositionOnProduct = useCallback(
    (
      shape: ShapeType,
      target: ProductTarget,
      dropType: BaseType,
    ): ShapeType => {
      const creatorProduct = creatorProducts.find(
        (item) => item.data.id === target.targetId,
      );
      if (creatorProduct) {
        const {
          data: { position, width: targetWidth },
          anchor,
        } = creatorProduct;
        if (dropType === BaseType.PIPE) {
          const { x, y } = position;
          let positionX;
          if (anchor.toLowerCase().match('right')) {
            positionX = x - (DEFAULT_WIDTH - CONNECT_OFFSET);
          } else if (anchor.toLowerCase().match('left')) {
            positionX = x + (targetWidth - CONNECT_OFFSET);
          } else {
            positionX = x + targetWidth / 2 - DEFAULT_WIDTH / 2;
          }
          return { ...shape, position: { y, x: positionX } };
        }
        return { ...shape, position };
      }
      return shape;
    },
    [creatorProducts],
  );

  const findProductGapFromGlassEdge = useCallback(
    (glass: Glass, gaps: ProductGaps, anchor?: Anchor) => {
      let minDistance = 0;
      const { gapFromWall, gapFromGlass, gapFromEdge } = gaps;
      const gap = anchor && findGapOnAnchor(anchor, glass);
      if (gap) {
        switch (gap.neighbor) {
          case NeighborType.WALL: {
            minDistance = gapFromWall;
            break;
          }
          case NeighborType.GLASS: {
            minDistance = gapFromGlass;
            break;
          }
        }
      }

      return {
        x: new Big(minDistance).times(DEFAULT_RATIO),
        y: new Big(gapFromEdge).times(DEFAULT_RATIO),
      };
    },
    [findGapOnAnchor],
  );

  const getProductPadding = (
    dimensionLinePadding: GlassDimensions,
    anchor?: Anchor,
  ) => {
    const productPadding = {
      x: 0,
      y: 0,
    };

    if (anchor) {
      switch (anchor) {
        case 'bottomRight':
          productPadding.x = dimensionLinePadding.right;
          productPadding.y = dimensionLinePadding.bottom;
          break;
        case 'bottomLeft':
          productPadding.x = -dimensionLinePadding.left;
          productPadding.y = dimensionLinePadding.bottom;
          break;
        case 'topRight':
          productPadding.x = dimensionLinePadding.right;
          productPadding.y = -dimensionLinePadding.top;
          break;
        case 'topLeft':
          productPadding.x = -dimensionLinePadding.left;
          productPadding.y = -dimensionLinePadding.top;
          break;
        case 'top':
          productPadding.y = -dimensionLinePadding.top;
          break;
        case 'left':
          productPadding.x = -dimensionLinePadding.left;
          break;
        case 'right':
          productPadding.x = dimensionLinePadding.right;
          break;
        case 'bottom':
          productPadding.y = dimensionLinePadding.bottom;
          break;
        default:
          productPadding.x = 0;
          productPadding.y = 0;
      }
    }

    return productPadding;
  };

  const validPositionOnGlass = useCallback(
    ({
      shape,
      glass,
      anchor,
      glassProperties,
      rotated,
      gapFromEdge,
      isGlassGlass,
      glassGlassType,
    }: ValidPositionOnGlassConfig): ShapeType => {
      const gaps = gapFromEdge ?? defaultGaps;
      const glassObj = new Shape({ corners: glass.corners }, glass.position);

      let valid = { ...alignCornerPositions(shape, glassObj, anchor) };
      const minGapFromEdge = findProductGapFromGlassEdge(glass, gaps, anchor);

      let validY: BigSource = valid.position.y;
      let validX: BigSource = valid.position.x;

      const paddings = getProductPadding(
        glassProperties.dimensionLinePadding,
        anchor,
      );

      if (anchor.includes('top')) {
        const verticalParentPoint = findYOnLine(
          validX,
          map(glassObj.topEdge.points, (item) => item) as [Vector2d, Vector2d],
        );

        if (verticalParentPoint) {
          validY = new Big(verticalParentPoint)
            .plus(minGapFromEdge.y)
            .plus(new Big(paddings.y));
        }
      }

      if (anchor.includes('bottom')) {
        const verticalParentPoint = findYOnLine(
          validX,
          map(glassObj.bottomEdge.points, (item) => item) as [
            Vector2d,
            Vector2d,
          ],
        );

        if (verticalParentPoint) {
          validY = new Big(verticalParentPoint)
            .plus(new Big(paddings.y))
            .minus(rotated ? valid.width : valid.height)
            .minus(minGapFromEdge.y);
        }
      }
      if (anchor.toLowerCase().includes('left')) {
        const horizontalParentPoint = findXOnLine(
          Number(validY),
          map(glassObj.leftEdge.points, (item) => item) as [Vector2d, Vector2d],
        );

        if (horizontalParentPoint) {
          validX = new Big(horizontalParentPoint)
            .plus(minGapFromEdge.x)
            .plus(new Big(paddings.x));
        }
      }

      if (anchor.toLowerCase().includes('right')) {
        const horizontalParentPoint = findXOnLine(
          Number(validY),
          map(glassObj.rightEdge.points, (item) => item) as [
            Vector2d,
            Vector2d,
          ],
        );

        if (horizontalParentPoint) {
          validX = new Big(horizontalParentPoint)
            .plus(new Big(paddings.x))
            .minus(rotated ? valid.height : valid.width)
            .minus(minGapFromEdge.x);
        }
      }

      valid.position.x = fixValue(validX, 5);
      valid.position.y = fixValue(validY, 5);

      if (isGlassGlass) {
        valid = correctGlassGlassProductPosition(
          valid,
          glassGlassType,
          anchor,
          rotated,
          glassProperties.glassToGlassGap,
        );
      }

      return valid;
    },
    [
      alignCornerPositions,
      correctGlassGlassProductPosition,
      findProductGapFromGlassEdge,
    ],
  );

  const validProductsOnGlass = useCallback(
    (target: GlassTarget) => {
      const pairs = getUsedProductsWithProjectProducts(target);

      const validProducts = pairs.map(({ product, usedProduct }) => {
        const glassProperties = usedProduct.glassProperties.find(
          (item) => item.thickness === target.glass.thickness,
        );

        if (!glassProperties) {
          throw new Error('Invalid product already on glass!');
        }

        return resizeBarToFitGlass(usedProduct, product, target, 1);
      });

      updateManyProducts(validProducts);

      return validProducts;
    },

    [getUsedProductsWithProjectProducts, updateManyProducts],
  );

  const confirmSameHandles = useCallback(async () => {
    setShowSameHandlesModal(true);
    const sameHandlesPromise = new Promise<boolean>((res) => {
      sameHandlesActions.confirm = () => res(true);
      sameHandlesActions.reject = () => res(false);
    });
    const result = await sameHandlesPromise;
    setShowSameHandlesModal(false);
    return result;
  }, []);

  const confirmLiftingHinge = useCallback(async () => {
    setShowLiftingHingeModal(true);
    const liftingHingePromise = new Promise<LiftingHingeDecision>((res) => {
      liftingHingeActions.select = (decision) => res(decision);
      liftingHingeActions.cancel = () => res('cancel');
    });
    const result = await liftingHingePromise;
    setShowLiftingHingeModal(false);
    return result;
  }, []);

  const validGlassGap = useCallback(
    (
      productBaseType: BaseType,
      productMountTypes: ProductSearch['mountTypes'],
      glassToGlassGap: GlassDimensions,
      wallToGlassGap: GlassDimensions,
      target: GlassTarget,
      isGlassGlass?: boolean,
      glassGlassType?: GlassGlassType,
    ) => {
      const sameAnchorProductsBaseTypes = getSameAnchorProducts(
        target,
        false,
      ).map((item) => item.baseType);
      if (
        ignoringGapProducts.includes(productBaseType) &&
        sameAnchorProductsBaseTypes.some(
          (item) => !ignoringGapProducts.includes(item),
        )
      ) {
        return target.glass;
      }

      if (isGlassGlass && glassGlassType === GlassGlassType.T180_4) {
        return updateGlassGap4Glasses(
          target.glass,
          target.anchor,
          glassToGlassGap,
        );
      }

      const updatedGlassesList = updateGlassNeighborsGap({
        glassToGlassGap,
        wallToGlassGap,
        targetGlass: target.glass,
        productBaseType,
        productMountTypes,
        edge: target.anchor,
      });

      return updateGlassGap({
        glassToGlassGap,
        wallToGlassGap,
        targetGlass: target.glass,
        productBaseType,
        edge: target.anchor,
        updatedGlassesList,
      });
    },
    [
      getSameAnchorProducts,
      updateGlassGap4Glasses,
      updateGlassGap,
      updateGlassNeighborsGap,
    ],
  );

  const validBaseType = useCallback(
    async (
      product: ProductSearch,
      target: GlassTarget,
      onTargetEdit?: (target: GlassTarget) => void,
    ) => {
      const { baseType } = product;
      const sameAnchorProducts = getSameAnchorProducts(target);
      switch (baseType) {
        case BaseType.HINGE: {
          return (
            sameAnchorProducts.some(
              (item) => item.baseType === BaseType.LIFTING_HINGE,
            ) && INVALID_REASON.SAME_TYPE_HINGE
          );
        }
        case BaseType.LIFTING_HINGE: {
          if (
            sameAnchorProducts.some((item) => item.baseType === BaseType.HINGE)
          ) {
            return INVALID_REASON.SAME_TYPE_HINGE;
          }
          if (target.type === ConnectionType.GLASS) {
            const { value: gap } = findTopGap(target.glass);
            const bigGap = new Big(Math.max(...gap));
            if (bigGap.gte(LIFTING_HINGE_GAP)) {
              return false;
            }
            const confirm = await confirmLiftingHinge();

            if (confirm === 'addGap') {
              const cut = cutGlass(
                'top',
                fixValue(new Big(LIFTING_HINGE_GAP).minus(Math.max(...gap))),
                target.glass,
              );
              updateGlass(cut, true);
              onTargetEdit?.({ ...target, glass: cut });
              return false;
            }
            if (confirm === 'ignoreGap') {
              return false;
            }
          }
          return INVALID_REASON.CANCELED;
        }
        case BaseType.HANDLE: {
          if (
            sameAnchorProducts.some((item) => item.baseType === BaseType.HANDLE)
          ) {
            const confirm = await confirmSameHandles();
            return confirm ? false : INVALID_REASON.CANCELED;
          }
          return false;
        }
        case BaseType.GLASS_GLASS_90:
        case BaseType.GLASS_GLASS_180: {
          const isHinge = (type: BaseType) =>
            type === BaseType.GLASS_GLASS_90 ||
            type === BaseType.GLASS_GLASS_180;
          return (
            getSameAnchorProducts(target, true).some(
              (item) => isHinge(item.baseType) && item.code !== product.code,
            ) && INVALID_REASON.SAME_TYPE_HINGE
          );
        }
        default: {
          return false;
        }
      }
    },
    [
      confirmLiftingHinge,
      confirmSameHandles,
      findTopGap,
      getSameAnchorProducts,
      updateGlass,
    ],
  );

  const validRequiredProducts = useCallback(
    (product: ProductSearch, target: GlassTarget) => {
      const { requiredTypes, requiredTypeCount } = product.attributes;

      const sameGlassProducts = getGlassProducts(target);
      const sameGlassUsedProducts = getUsedProductsForCreatorProducts(
        sameGlassProducts,
      );

      const requiredProducts = sameGlassUsedProducts.filter((item) =>
        requiredTypes.includes(item.type),
      );
      return requiredProducts.length >= requiredTypeCount;
    },
    [getGlassProducts, getUsedProductsForCreatorProducts],
  );

  const hasGlassInDirection = useCallback(
    (glass: Glass, direction: ProductOrientation): boolean => {
      const glassObj = new Shape({ corners: glass.corners }, glass.position);
      const otherGlasses = projectGlasses
        .filter(({ id }) => id !== glass.id)
        .map((glass) => new Shape({ corners: glass.corners }, glass.position));

      switch (direction) {
        case ProductOrientation.LEFT:
          return otherGlasses.some(
            (otherGlass) =>
              otherGlass.extremePoints.left.point.x <
              glassObj.extremePoints.left.point.x,
          );
        case ProductOrientation.RIGHT:
          return otherGlasses.some(
            (otherGlass) =>
              otherGlass.extremePoints.right.point.x >
              glassObj.extremePoints.right.point.x,
          );
        case ProductOrientation.TOP:
          return otherGlasses.some(
            (otherGlass) =>
              otherGlass.extremePoints.top.point.y <
              glassObj.extremePoints.top.point.y,
          );
        case ProductOrientation.BOTTOM:
          return otherGlasses.some(
            (otherGlass) =>
              otherGlass.extremePoints.bottom.point.y >
              glassObj.extremePoints.bottom.point.y,
          );
        default:
          return false;
      }
    },
    [projectGlasses],
  );

  return {
    validPositionOnGlass,
    validProductsOnGlass,
    validBaseType,
    validGlassGap,
    sameHandlesActions,
    liftingHingeActions,
    liftingHingeDecisions,
    showSameHandlesModal,
    showLiftingHingeModal,
    liftingHingeOptions,
    validPositionOnProduct,
    validVSG,
    validRequiredProducts,
    getUsedProductsWithProjectProducts,
    hasGlassInDirection,
  };
};

export default useDropValidators;
