import * as React from 'react';

const SvgProjects = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={185}
      height={125}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={34.926}
        width={114.99}
        height={114.99}
        rx={14}
        transform="rotate(4.644 34.926 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#projects_svg__filter0_d)">
        <rect x={10} y={17} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={52} y={28} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={52} y={43} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={17} y={23} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M26.333 41h9.333a.667.667 0 010 1.333h-9.333a.667.667 0 110-1.333z"
        fill="#fff"
      />
      <path
        d="M36.333 40.334v1.333a.667.667 0 01-1.333 0v-1.334a.667.667 0 111.333 0zM27 40.334v1.333a.667.667 0 01-1.333 0v-1.334a.667.667 0 111.333 0zM28.334 35.667a.667.667 0 00-.4 1.2l2.666 2a.666.666 0 00.787 0l2.667-1.88a.667.667 0 10-.774-1.087L31 37.507 28.734 35.8a.666.666 0 00-.4-.133z"
        fill="#fff"
      />
      <path
        d="M31 31a.667.667 0 00-.667.666V37a.666.666 0 101.333 0v-5.334A.667.667 0 0031 31z"
        fill="#fff"
      />
      <g filter="url(#projects_svg__filter1_d)">
        <rect x={25} y={65} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={67} y={76} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={67} y={91} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={32} y={71} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M41.333 89h9.333a.667.667 0 010 1.333h-9.333a.667.667 0 110-1.333z"
        fill="#fff"
      />
      <path
        d="M51.333 88.334v1.333a.667.667 0 01-1.333 0v-1.334a.667.667 0 111.333 0zM42 88.334v1.333a.667.667 0 01-1.333 0v-1.334a.667.667 0 111.333 0zM43.334 83.667a.667.667 0 00-.4 1.2l2.666 2a.666.666 0 00.787 0l2.667-1.88a.667.667 0 10-.774-1.087L46 85.507 43.734 83.8a.666.666 0 00-.4-.133z"
        fill="#fff"
      />
      <path
        d="M46 79a.667.667 0 00-.667.666V85a.666.666 0 101.333 0v-5.334A.667.667 0 0046 79z"
        fill="#fff"
      />
      <defs>
        <filter
          id="projects_svg__filter0_d"
          x={0}
          y={17}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="projects_svg__filter1_d"
          x={15}
          y={65}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgProjects = React.memo(SvgProjects);
export default MemoSvgProjects;
