import { AxiosInstance } from 'axios';
import axios from '../config';
import { Endpoints } from '../endpoints';
import {
  Subscription,
  EditCompanySubscription,
  SubscriptionPlan,
} from '../models/subscriptions';

class SubscriptionApi {
  constructor(private http: AxiosInstance) {}

  public getSubscriptionPlans = () =>
    this.http.get<SubscriptionPlan[]>(`${Endpoints.SUBSCRIPTION}/plans`);

  public getSubscription = () =>
    this.http.get<Subscription[]>(Endpoints.SUBSCRIPTION);

  public editCompanySubscription = (
    subscriptionCompanyId: number,
    command: EditCompanySubscription,
  ) =>
    this.http.put<Subscription>(
      `${Endpoints.SUBSCRIPTION}/${subscriptionCompanyId}`,
      command,
    );
}

const subscriptionApi = new SubscriptionApi(axios);
export default subscriptionApi;
