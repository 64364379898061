import * as React from 'react';
import { ItemLabel as Container } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';

export interface Props {
  label: string;
}

const ItemLabel: React.FC<Props> = React.memo(({ label }) => {
  return (
    <Container>
      <Typography variant={TypographyVariants.PARAGRAPH}>{label}</Typography>
    </Container>
  );
});

ItemLabel.displayName = 'ItemLabel';

export default ItemLabel;
