import styled, { css } from 'styled-components';
import { TooltipDrawFrom, TooltipPosition } from './types';

export type ArrowSize = 'big' | 'small';

const draw_from_right = css`
  right: 0;
`;

const arrow_on_left = css`
  left: 10px;
`;

const arrow_on_right = css`
  right: 10px;
`;

const tooltip_bottom_position = css`
  top: 100%;
  transform: translateY(10px);
`;

const tooltip_top_position = css`
  bottom: 100%;
  transform: translateY(-10px);
`;

const tooltip_left_position = css`
  right: 100%;
  top: 50%;
  transform: translate(-10px, -50%);
`;

const tooltip_right_position = css`
  left: 100%;
  top: 50%;
  transform: translate(10px, -50%);
`;

const arrow_bottom_position = css`
  border-color: ${({ theme }) => theme.colors.mainBlue} transparent transparent
    transparent;
  top: 100%;
`;

const arrow_top_position = css`
  border-color: transparent transparent ${({ theme }) => theme.colors.mainBlue}
    transparent;
  bottom: 100%;
`;

const arrow_left_position = css`
  border-color: transparent ${({ theme }) => theme.colors.mainBlue} transparent
    transparent;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

const arrow_right_position = css`
  border-color: transparent transparent transparent
    ${({ theme }) => theme.colors.mainBlue};
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

const getArrowSize = (size?: ArrowSize) => {
  switch (size) {
    case 'big': {
      return 5;
    }
    default:
    case 'small': {
      return 3;
    }
  }
};

const getArrowPosition = (
  position?: TooltipPosition,
  drawFrom?: TooltipDrawFrom,
) => {
  switch (position) {
    case TooltipPosition.TOP: {
      return css`
        ${arrow_bottom_position};
        ${drawFrom && drawFrom === TooltipDrawFrom.RIGHT
          ? arrow_on_right
          : arrow_on_left}
      `;
    }
    case TooltipPosition.RIGHT: {
      return arrow_left_position;
    }
    case TooltipPosition.LEFT: {
      return arrow_right_position;
    }
    default:
    case TooltipPosition.BOTTOM: {
      return css`
        ${arrow_top_position};
        ${drawFrom && drawFrom === TooltipDrawFrom.RIGHT
          ? arrow_on_right
          : arrow_on_left}
      `;
    }
  }
};

const getTooltipPosition = (
  position?: TooltipPosition,
  drawFrom?: TooltipDrawFrom,
) => {
  switch (position) {
    case TooltipPosition.TOP: {
      return css`
        ${tooltip_top_position};
        ${drawFrom && drawFrom === TooltipDrawFrom.RIGHT && draw_from_right};
      `;
    }
    case TooltipPosition.RIGHT: {
      return tooltip_right_position;
    }
    case TooltipPosition.LEFT: {
      return tooltip_left_position;
    }
    default:
    case TooltipPosition.BOTTOM: {
      return css`
        ${tooltip_bottom_position};
        ${drawFrom && drawFrom === TooltipDrawFrom.RIGHT && draw_from_right}
      `;
    }
  }
};

const tooltipArrow = (width: number) => css`
  content: '';
  border-width: ${width}px;
  border-style: solid;
  position: absolute;
`;

const Tooltip = styled.div<{
  tooltipPosition?: TooltipPosition;
  arrowSize?: ArrowSize;
  drawFrom?: TooltipDrawFrom;
  alwaysVisible?: boolean;
}>`
  visibility: ${({ alwaysVisible }) => (alwaysVisible ? 'visible' : 'hidden')};
  display: flex;
  transition: 0s visibility;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.mainBlue};
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  ${({ tooltipPosition, drawFrom }) =>
    getTooltipPosition(tooltipPosition, drawFrom)}
  > span {
    color: ${({ theme }) => theme.colors.white};
  }
  &:after {
    ${({ arrowSize }) => tooltipArrow(getArrowSize(arrowSize))}
    ${({ tooltipPosition, drawFrom }) =>
      getArrowPosition(tooltipPosition, drawFrom)}
  }
`;

export const Provider = styled.div<{
  fullWidth?: boolean;
  display?: 'inline' | 'block' | 'flex';
  flex?: string | number;
}>`
  display: ${({ display }) => (display === 'block' ? 'block' : 'inline-block')};
  flex: ${({ flex }) => flex};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  align-items: center;
  position: relative;
`;

export const ChildWrapper = styled.div<{
  display?: 'inline' | 'block' | 'flex';
  fullWidth?: boolean;
  tooltipDelay?: number;
}>`
  display: ${({ display }) => display ?? 'flex'};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'unset')};
  align-items: center;
  box-sizing: border-box;
  &:focus-within,
  &:hover {
    + div {
      visibility: visible;
      transition-delay: ${({ tooltipDelay }) => `${tooltipDelay ?? 0}ms`};
    }
  }
`;

export const SmallTooltip = styled(Tooltip)`
  padding: 3px 4px;
  white-space: nowrap;
`;

export const BigTooltip = styled(Tooltip)`
  width: 224px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.dark};
`;
