import * as React from 'react';
import { SaveAsModal } from './components/SaveAsModal';
import { SavingModal } from './components/SavingModal';
import { ConfirmModal } from './components/ConfirmModal';
import { useClients, useSave, useCreator } from '../../pages/Project/hooks';
import { SaveTemplateModal } from './components/SaveTemplateModal';
import {
  selectModals,
  useModalsStore,
  Modals as AvailableModals,
} from '../../store/modals';
import { ValidateProjectModal } from './components/ValidateProjectModal';
import { useRevalidateProject } from '../../pages/Project/hooks';

const Modals: React.FC = React.memo(() => {
  const {
    project,
    historyPointer,
    validateProject,
    toggleValidateProject,
  } = useCreator();
  const { search: clientsSearch, clients } = useClients();
  const modals = useModalsStore(selectModals);

  const {
    cancel,
    saveAs,
    confirm,
    closeSaveAs,
    saveProjectTemplateAs,
    closeSaveTemplate,
    rejectChanges,
  } = useSave(project);

  const {
    projectErrors,
    handleKeepEditing,
    handleSaveAnyway,
  } = useRevalidateProject(
    validateProject,
    toggleValidateProject,
    historyPointer,
  );

  return (
    <>
      <SaveAsModal
        show={!!modals.get(AvailableModals.SAVE_AS)}
        onCancel={closeSaveAs}
        onSubmit={saveAs}
        clients={clients}
        onClientSearch={clientsSearch}
      />
      <SaveTemplateModal
        show={!!modals.get(AvailableModals.SAVE_TEMPLATE)}
        onCancel={closeSaveTemplate}
        onSubmit={saveProjectTemplateAs}
      />
      <SavingModal
        show={!!modals.get(AvailableModals.SAVING)}
        onCancel={cancel}
      />
      <ConfirmModal
        show={!!modals.get(AvailableModals.CONFIRM)}
        onReject={rejectChanges}
        onConfirm={confirm}
      />
      <ValidateProjectModal
        show={!!modals.get(AvailableModals.VALIDATE_PROJECT)}
        errors={projectErrors}
        onKeepEditing={handleKeepEditing}
        onSaveAnyway={handleSaveAnyway}
      />
    </>
  );
});

Modals.displayName = 'Modals';

export default Modals;
