import axios from '../config';
import { AxiosInstance } from 'axios';
import { Endpoints } from '../endpoints';

export interface GuidePdfResponse {
  urlToFaq: string;
}

class FaqApi {
  public constructor(private http: AxiosInstance) {}

  public getGuidePdf = () =>
    this.http.get<GuidePdfResponse>(`${Endpoints.FAQ}`);
}

const faqApi = new FaqApi(axios);
export default faqApi;
