import * as React from 'react';
import { Line } from 'react-konva';
import { selectTheme, useConfigStore } from '../../store/config';

export interface Props {
  points: number[];
}

const DistanceLine: React.FC<Props> = React.memo(({ points }) => {
  const theme = useConfigStore(selectTheme);

  return (
    <Line
      points={points}
      stroke={theme.colors.accentBlue}
      strokeWidth={1}
      lineJoin="round"
      dash={[4, 4]}
    />
  );
});

DistanceLine.displayName = 'DistanceLine';

export default DistanceLine;
