import { State } from './drag';

export const selectDragging = (state: State) => state.dragging;

export const selectOnDragStart = (state: State) => state.onDragStart;

export const selectOnDragEnd = (state: State) => state.onDragEnd;

export const selectOnDrop = (state: State) => state.onDrop;

export const selectOnDrag = (state: State) => state.onDrag;

export const selectDragShape = (state: State) => state.shape;

export const selectDragData = (state: State) => state.data;

export const selectDragId = (state: State) => state.dragId;

export const selectCollision = (state: State) => state.collision;

export const selectSetCollision = (state: State) => state.setCollision;

export const selectInvalid = (state: State) => state.invalid;

export const selectSetInvalid = (state: State) => state.setInvalid;

export const selectDropTarget = (state: State) => state.dropTarget;

export const selectSetDropTarget = (state: State) => state.setDropTarget;

export const selectOnDragOver = (state: State) => state.onDragOver;
