import * as React from 'react';
import { MainItem as Container } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import { RightSm } from '../../../../components/icons';
import { useCallback } from 'react';
import SubList from './SubList';
import ItemLabel from './ItemLabel';

export interface Props {
  id: string;
  title: string;
  level: number;
  showDescendants: Record<number, string>;
  onClick?(): void;
  onMouseEnter?(
    level: number,
    id: string,
    disabled?: boolean,
    noDescendants?: boolean,
  ): void;
  descendants?: MainItemType[];
  label?: string;
  disabled?: boolean;
}

export type MainItemType = Omit<
  Props,
  'onMouseEnter' | 'showDescendants' | 'id'
>;

const MainItem: React.FC<Props> = React.memo(
  ({
    id,
    title,
    onClick,
    onMouseEnter,
    descendants,
    showDescendants,
    level,
    label,
    disabled,
  }) => {
    const handleClick = useCallback(() => !descendants && onClick?.(), [
      onClick,
      descendants,
    ]);

    const handleMouseEnter = useCallback(
      () => onMouseEnter?.(level, id, disabled, !descendants),
      [disabled, descendants, onMouseEnter, level, id],
    );

    return (
      <Container
        role="menuitem"
        secondary={!descendants}
        disabled={disabled}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}>
        <Typography variant={TypographyVariants.H4}>{title}</Typography>
        {label && <ItemLabel label={label} />}
        {!!descendants && <RightSm />}
        {!!descendants && showDescendants[level] === id && (
          <SubList
            items={descendants}
            showDescendants={showDescendants}
            onMouseEnter={onMouseEnter}
          />
        )}
      </Container>
    );
  },
);

MainItem.displayName = 'MainItem';

export default MainItem;
