import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  correctBoundariesCopy,
  validateGlasses,
  validatePosInBoundsNew,
} from '../../utils/boundaries';
import { DEFAULT_RATIO } from '../../store/config';
import { Position } from '../../types';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { RectCorners } from '../../../../types';
import { Glass } from '../../../../services/api/models/glass';
import { scaleGlass } from '../../utils/shapes';
import { fixValue } from '../../utils/fix';
import Big from 'big.js';
import {
  ProductModel,
  ProductSearch,
} from '../../../../services/api/models/product';
import { DisabledEdge } from '../../../../services/api/models/transformer';
import { ConnectionType } from '../../../../services/api/models/connection';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../../store/notifications';
import { NotificationVariants } from '../../../../components/Notification';
import { useTranslation } from 'react-i18next';
import { Vector2 } from '../../utils/vector';
import { BigPosition } from '../../../../utils/shape';
import { selectCollidingGlasses, useProjectStore } from '../../store/project';

interface UseGlassBoundsProps {
  id: number;
  templateDimensions: ProjectDimensions;
  templatePosition: Position;
  glassPosition: Position;
  projectGlasses: Glass[];
  isBlank: boolean;
  projectProducts: ProductModel[];
  corners: RectCorners;
  groupRef: any;
}

const useGlassBounds = ({
  id,
  templateDimensions,
  templatePosition,
  glassPosition,
  projectGlasses,
  isBlank,
  projectProducts,
  corners,
  groupRef,
}: UseGlassBoundsProps) => {
  const { t } = useTranslation('project');
  const setNotification = useNotificationsStore(selectSetNotification);
  const projectCollidingGlasses = useProjectStore(selectCollidingGlasses);
  const [glassErrors, setGlassErrors] = useState({
    intersectionFound: false,
    collisionWithParent: false,
    notConnected: false,
  });

  const mountedProducts = useMemo(
    () =>
      projectProducts.filter((product) =>
        product.connections.some(
          (connection) => connection.targetId === String(id),
        ),
      ),
    [id, projectProducts],
  );

  const glassIntersects = useMemo(() => {
    const shapes = projectGlasses.map(({ originCorners, position }) => ({
      corners: originCorners,
      position: position,
    }));

    const config = {
      shape: {
        position: glassPosition,
        corners: corners,
      },
      shapes,
      parents: [
        {
          position: templatePosition,
          corners: templateDimensions.corners,
          indent: templateDimensions.indent,
        },
      ],
    };
    const validationResult = validateGlasses(config);

    if (Object.values(validationResult).some(Boolean)) {
      projectCollidingGlasses.add(id);
    } else {
      projectCollidingGlasses.delete(id);
    }

    setGlassErrors(validationResult);

    return (
      validationResult.collisionWithParent ||
      validationResult.intersectionFound ||
      validationResult.notConnected
    );
  }, [
    corners,
    glassPosition,
    id,
    projectGlasses,
    templateDimensions.corners,
    templateDimensions.indent,
    templatePosition,
    projectCollidingGlasses,
  ]);

  const dragBoundFunc = useCallback(
    (pos: BigPosition, initPos: BigPosition) => {
      const shapes = projectGlasses
        .filter((glass) => glass.id !== id)
        .map(({ originCorners, position }) => ({
          corners: originCorners,
          position: position,
        }));

      const config = {
        shape: {
          position: glassPosition,
          corners,
        },
        // If glass is blank, we don't want to check collisions with other glasses
        shapes: !isBlank ? shapes : [],
        parents: [
          {
            position: templatePosition,
            corners: templateDimensions.corners,
            indent: templateDimensions.indent,
          },
        ],
        scale: 1,
      };

      const positionV = new Vector2([pos.x, pos.y]);
      const glassPositionV = new Vector2([initPos.x, initPos.y]);
      const moveVector = positionV.copy().subtract(glassPositionV);

      return validatePosInBoundsNew(config, moveVector, positionV);
    },
    [corners, groupRef, glassPosition],
  );

  const disableTransformEdge = useMemo<DisabledEdge>(() => {
    const anchors = mountedProducts
      .map((prod) =>
        prod.connections.filter(
          (connection) =>
            connection.type === ConnectionType.GLASS &&
            Number(connection.targetId) === id,
        ),
      )
      .flat()
      .map((connection) => connection.anchor);

    return {
      left:
        anchors.includes('left') ||
        anchors.includes('topLeft') ||
        anchors.includes('bottomLeft'),
      right:
        anchors.includes('right') ||
        anchors.includes('bottomRight') ||
        anchors.includes('topRight'),
      top:
        anchors.includes('top') ||
        anchors.includes('topLeft') ||
        anchors.includes('topRight'),
      bottom:
        anchors.includes('bottom') ||
        anchors.includes('bottomLeft') ||
        anchors.includes('bottomRight'),
    };
  }, [id, mountedProducts]);

  const setGlassCollisionNotification = useCallback(
    (
      isError: boolean,
      errorType: 'collisionWithParent' | 'notConnected' | 'intersectionFound',
    ) => {
      if (isError) {
        setNotification({
          variant: NotificationVariants.ALERT,
          text: t(`notifications.glassErrors.${errorType}`, { id }),
        });
      }
    },
    [setNotification, t, id],
  );

  useEffect(
    () =>
      setGlassCollisionNotification(
        glassErrors.collisionWithParent,
        'collisionWithParent',
      ),
    [glassErrors.collisionWithParent],
  );

  useEffect(
    () =>
      setGlassCollisionNotification(glassErrors.notConnected, 'notConnected'),
    [glassErrors.notConnected],
  );

  useEffect(
    () =>
      setGlassCollisionNotification(
        glassErrors.intersectionFound,
        'intersectionFound',
      ),
    [glassErrors.intersectionFound],
  );

  return {
    glassIntersects,
    disableTransformEdge,
    dragBoundFunc,
    mountedProducts,
  };
};

export default useGlassBounds;
