import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { MenuItem } from '../components/ContextMenu';
import { creatorSelect } from '../../../modules/creator';
import { useEditActions } from '../../../hooks';

const { useSelectStore, selectContextMenu } = creatorSelect;

export interface UseContextItems {
  items: MenuItem[];
}

const useContextItems = () => {
  const context = useSelectStore(selectContextMenu);

  const { t } = useTranslation('project');

  const {
    disabled,
    copy: onCopy,
    cut: onCut,
    delete: onDelete,
    paste: onPaste,
    connect: onConnect,
  } = useEditActions(context);

  const pipeItems = useMemo<MenuItem[]>(
    () => [
      {
        title: t('contextMenu.pipe.connect'),
        hotkey: '',
        onClick: onConnect,
      },
    ],
    [onConnect, t],
  );

  const baseItems = useMemo<MenuItem[]>(
    () => [
      {
        title: t('contextMenu.copy'),
        hotkey: 'ctrl+c',
        onClick: onCopy,
        disabled: disabled.copy,
      },
      {
        title: t('contextMenu.cut'),
        hotkey: 'ctrl+x',
        onClick: onCut,
        disabled: disabled.cut,
      },
      {
        title: t('contextMenu.paste'),
        hotkey: 'ctrl+v',
        onClick: onPaste,
        disabled: disabled.paste,
      },
      {
        title: t('contextMenu.delete'),
        hotkey: 'delete',
        onClick: onDelete,
        disabled: disabled.delete,
      },
    ],
    [
      disabled.copy,
      disabled.cut,
      disabled.delete,
      disabled.paste,
      onCopy,
      onCut,
      onDelete,
      onPaste,
      t,
    ],
  );

  return useMemo<MenuItem[]>(() => {
    const base = [...baseItems];
    if (context?.type === 'pipe') {
      base.unshift(...pipeItems);
    }
    return base;
  }, [baseItems, context?.type, pipeItems]);
};

export default useContextItems;
