import React, { useMemo, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import { Input } from '../../../../components/Input';
import { Button, ButtonTypes } from '../../../../components/Button';
import {
  Notification,
  NotificationVariants,
} from '../../../../components/Notification';

import { Container, InfoBox, Form, Header, ButtonsWrapper } from './styles';

export interface FormValues {
  newEmail: string;
  retypedEmail: string;
}

export interface Props {
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
  submitError: boolean;
  onSubmit(values: FormValues, helpers: FormikHelpers<FormValues>): void;
  onCancel(): void;
}

const initialValues: FormValues = {
  newEmail: '',
  retypedEmail: '',
};

const EmailForm: React.FC<Props> = React.memo(
  ({
    onCancel,
    onSubmit,
    cancelButtonTitle,
    submitButtonTitle,
    submitError,
    ...props
  }) => {
    const { t } = useTranslation('emailChange');

    const validationSchema = useMemo(
      () =>
        Yup.object().shape({
          newEmail: Yup.string()
            .required(t('forms.errors.required'))
            .email(t('forms.errors.email')),
          retypedEmail: Yup.string()
            .required(t('forms.errors.required'))
            .email(t('forms.errors.email')),
        }),
      [t],
    );

    const formik = useFormik<FormValues>({
      initialValues,
      onSubmit: (values, helpers) => onSubmit(values, helpers),
      validationSchema,
    });

    const handleChange = useCallback(
      (value: string, name: string) => {
        formik.setFieldValue(name, value);
        formik.setTouched({ ...formik.touched, [name]: true });
      },
      [formik],
    );

    const {
      errors,
      values: { newEmail, retypedEmail },
      touched,
      isValid,
      dirty,
      isSubmitting,
    } = formik;

    const mailsMatch = useMemo(
      () => touched['retypedEmail'] && retypedEmail !== newEmail,
      [newEmail, retypedEmail, touched],
    );

    return (
      <Container>
        <Form {...props} onSubmit={formik.handleSubmit}>
          <Header variant={TypographyVariants.H1}>{t('headerChange')}</Header>
          {(mailsMatch || submitError) && (
            <Notification
              text={
                mailsMatch
                  ? t('forms.errors.notMatch')
                  : t('forms.errors.general')
              }
              variant={NotificationVariants.ERROR}
              fullWidth
            />
          )}
          <Input
            name="newEmail"
            testId="newEmail-input"
            label={t('forms.fields.newEmail')}
            placeholder={t('forms.fields.clientName')}
            type="email"
            onChange={handleChange}
            value={newEmail}
            invalid={touched['newEmail'] && !!errors['newEmail']}
            errorMessage={errors['newEmail']}
            fullWidth
          />
          <Input
            name="retypedEmail"
            testId="retypedEmail-input"
            label={t('forms.fields.retypedEmail')}
            placeholder={t('forms.fields.email')}
            type="email"
            onChange={handleChange}
            value={retypedEmail}
            invalid={touched['retypedEmail'] && !!errors['retypedEmail']}
            errorMessage={errors['retypedEmail']}
            fullWidth
          />

          <InfoBox>
            <Typography variant={TypographyVariants.H5}>
              {t('forms.additionalInfo.basic')}
            </Typography>
            <Typography variant={TypographyVariants.H5}>
              <Trans t={t} i18nKey={'forms.additionalInfo.basic2'}>
                {''}
              </Trans>
            </Typography>
          </InfoBox>
          <ButtonsWrapper>
            <Button
              testId="cancel-button"
              title={cancelButtonTitle || t('forms.buttons.cancel')}
              type={ButtonTypes.SECONDARY}
              onClick={onCancel}
              noMinWidth
            />
            <Button
              testId="submit-button"
              title={submitButtonTitle || t('forms.buttons.confirm2')}
              htmlButtonType="submit"
              disabled={
                !dirty || !isValid || retypedEmail !== newEmail || isSubmitting
              }
              fullWidth
            />
          </ButtonsWrapper>
        </Form>
      </Container>
    );
  },
);

EmailForm.displayName = 'EmailForm';

export default EmailForm;
