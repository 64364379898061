import { DragEvent } from 'react';
import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import createVanilla from 'zustand/vanilla';
import { ConnectionType } from '../../../../services/api/models/connection';
import { Glass } from '../../../../services/api/models/glass';
import {
  ExtendedAnchoringOptions,
  ProductSearch,
} from '../../../../services/api/models/product';
import { Position, Projection, Shape } from '../../types';
import { Anchor } from '../../types/Anchor';
import { translateToCanvasPosition } from '../../utils/position';
import { configStore } from '../config';

export const STORE_NAME = `@store/creator/drag`;

type Data = ProductSearch;

export type DropCollisions = ExtendedAnchoringOptions;

let tempDragImage: HTMLElement;

export interface InvalidDrag {
  invalid: boolean;
  reason?: string;
}

interface Target {
  type: ConnectionType;
  targetId: string;
  anchor: Anchor;
}

export interface GlassTarget extends Target {
  type: ConnectionType.GLASS;
  glass: Glass;
}

export interface ProductTarget extends Target {
  type: ConnectionType.PRODUCT;
  product: ProductSearch;
}

export interface WallTarget extends Target {
  type: ConnectionType.WALL;
}

export type DropTarget = GlassTarget | WallTarget | ProductTarget;

export type State = {
  onDragStart(
    e: DragEvent,
    data: Data,
    dragId?: string | number,
    dragImage?: HTMLElement,
  ): void;
  onDragEnd(e: DragEvent): void;
  onDrop(
    e: DragEvent,
    callback?: (
      data: Data,
      shape: Shape,
      invalid: InvalidDrag,
      collision: DropCollisions,
      dropTarget?: DropTarget,
    ) => void,
  ): void;
  onDrag(e: DragEvent): void;
  onDragOver(e: DragEvent): void;
  setCollision(collision: Partial<DropCollisions>): void;
  setInvalid(invalid: InvalidDrag): void;
  setDropTarget(dropTarget?: DropTarget): void;
  dragPosition: Position;
  dragging: boolean;
  collision: DropCollisions;
  invalid: InvalidDrag;
  dragId?: string | number;
  dropTarget?: DropTarget;
  shape?: Shape;
  data?: Data;
};

const PADDING = 2 * 10 + 2 * 2; // Inner dragImage container padding and border width;

const position: Position = { x: 0, y: 0 };

const initCollision: ExtendedAnchoringOptions = {
  top: false,
  bottom: false,
  left: false,
  right: false,
  bottomLeft: false,
  topLeft: false,
  topRight: false,
  bottomRight: false,
  center: false,
  product: false,
};

const store = (set: SetState<State>, get: GetState<State>): State => ({
  dragPosition: position,
  dragging: false,
  collision: initCollision,
  invalid: { invalid: false },
  onDragStart: (e, data, dragId, dragImage) => {
    const { projection } = configStore.getState();
    const width = data.width + PADDING;
    const height = data.height + PADDING;
    if (dragImage) {
      tempDragImage = dragImage;
      tempDragImage.style.display = 'block';
      e.dataTransfer.setDragImage(tempDragImage, width / 2, height / 2);
    }
    if (projection !== Projection.TOP) {
      set({
        data,
        dragging: true,
        dragId,
        shape: {
          position: translateToCanvasPosition(
            e.clientX - width / 2,
            e.clientY - height / 2,
          ),
          width,
          height,
        },
      });
    }
  },
  onDragEnd: (e) => {
    set({
      dragPosition: position,
      dragging: false,
      collision: initCollision,
      dropTarget: undefined,
      invalid: { invalid: false },
    });
  },
  onDrop: (e, callback) => {
    if (tempDragImage) {
      tempDragImage.style.display = 'none';
    }
    const { data, shape, invalid, collision, dropTarget } = get();
    const { scale } = configStore.getState();

    if (data && shape) {
      const rightAlignmentOffset =
        dropTarget?.anchor === 'right' ? data.width : 0;
      const bottomAlignmentOffset =
        dropTarget?.anchor === 'bottom' ? data.height : 0;

      const updatedShape = {
        position: {
          x: shape.position.x / scale + PADDING / 2 + rightAlignmentOffset,
          y: shape.position.y / scale + PADDING / 2 + bottomAlignmentOffset,
        },
        width: data.width,
        height: data.height,
      };
      callback?.(data, updatedShape, invalid, collision, dropTarget);
    }
  },
  onDrag: (e) => {
    const { data, dragPosition, shape: stateShape } = get();
    const width = data?.width ?? 1 + PADDING;
    const height = data?.height ?? 1 + PADDING;
    const shape = {
      position: translateToCanvasPosition(
        dragPosition.x - width / 2,
        dragPosition.y - height / 2,
      ),
      width,
      height,
    };

    if (
      shape.position.x !== stateShape?.position.x ||
      shape.position.y !== stateShape?.position.y
    ) {
      set({
        shape,
      });
    }
  },
  onDragOver: (e) => {
    e.preventDefault();
    const { clientY, clientX } = e;
    set({ dragPosition: { x: clientX, y: clientY } });
  },
  setCollision: (collision: Partial<DropCollisions>) => {
    const { collision: origin } = get();
    set({ collision: { ...origin, ...collision } });
  },
  setInvalid: (invalid: InvalidDrag) => set({ invalid }),
  setDropTarget: (dropTarget?: DropTarget) => set({ dropTarget }),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
