import styled, { css } from 'styled-components';
import { layout } from '../../styles';

export const Container = styled.div`
  width: fit-content;
  position: relative;
`;

const Base = styled(layout.Row)`
  height: 40px;
  padding: 0 12px;
  align-items: center;
  min-width: 87px;
  justify-content: center;
  cursor: pointer;
  > svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
  &:hover {
    > svg {
      path {
        fill: ${({ theme }) => theme.colors.accentBlue};
      }
    }
  }
`;

export const Button = styled(Base)`
  align-items: center;
  > span {
    color: ${({ theme }) => theme.colors.dark};
    white-space: nowrap;
  }
`;

export const List = styled(layout.Column)`
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 10px 10px rgba(39, 43, 95, 0.08);
`;

const selected_option = css`
  > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  > svg {
    visibility: visible;
    path {
      fill: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;

export const Option = styled(Base)<{ isSelected?: boolean }>`
  justify-content: flex-end;
  > svg {
    visibility: hidden;
  }
  ${({ isSelected }) => isSelected && selected_option}
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
  }
`;
