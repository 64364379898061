import * as React from 'react';
import { Container, Trigger } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import {
  DownSm,
  Profile,
  SignOut,
  Password,
} from '../../../../components/icons';
import List from './List';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  onLogout(): void;
  changePassword(): void;
  changeEmail(): void;
}

const AdminMenu: React.FC<Props> = React.memo(
  ({ onLogout, changeEmail, changePassword }) => {
    const { t } = useTranslation('navigation');
    const [unfolded, setUnfolded] = useState(false);
    const userName = useMemo(() => t('userMenu.admin'), [t]);

    const items = useMemo(
      () => [
        {
          title: t('navigation:userMenu.changeEmail'),
          Icon: <Profile />,
          onClick: changeEmail,
        },
        {
          title: t('navigation:userMenu.changePassword'),
          Icon: <Password />,
          onClick: changePassword,
        },
        {
          title: t('navigation:userMenu.logout'),
          Icon: <SignOut />,
          onClick: onLogout,
        },
      ],
      [changeEmail, changePassword, onLogout, t],
    );

    const onUnfold = useCallback(() => {
      !unfolded && setUnfolded(true);
    }, [unfolded]);

    const onClose = useCallback(() => {
      setUnfolded(false);
    }, []);

    return (
      <Container data-testid="admin-menu">
        <Trigger role="button" onClick={onUnfold}>
          <Typography variant={TypographyVariants.H4_BOLD}>
            {userName}
          </Typography>
          <DownSm />
        </Trigger>
        {unfolded && (
          <List items={items} onClose={onClose} username={userName} />
        )}
      </Container>
    );
  },
);

AdminMenu.displayName = 'AdminMenu';

export default AdminMenu;
