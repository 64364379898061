import * as React from 'react';

const SvgUpSm = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M16 14.5a1.003 1.003 0 01-.71-.29L12 10.9l-3.3 3.18a1 1 0 11-1.41-1.42l4-3.86a1 1 0 011.4 0l4 4a.999.999 0 010 1.42 1 1 0 01-.69.28z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgUpSm = React.memo(SvgUpSm);
export default MemoSvgUpSm;
