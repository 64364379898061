import { State } from './session';

export const selectAccessToken = (state: State) => state.accessToken;

export const selectRefreshToken = (state: State) => state.refreshToken;

export const selectHasSession = (state: State) => state.hasSession;

export const selectLoading = (state: State) => state.loading;

export const selectError = (state: State) => state.error;

export const selectLogin = (state: State) => state.login;

export const selectLogout = (state: State) => state.logout;

export const selectManualLogout = (state: State) => state.manualLogout;

export const selectForcedLogout = (state: State) => state.forcedLogout;

export const selectShouldResetPassword = (state: State) =>
  state.shouldResetPassword;

export const selectUser = (state: State) => state.user;

export const selectPermissions = (state: State) => state.permissions;
