import * as React from 'react';

const SvgRedo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M4.023 12.393a4.849 4.849 0 014.893-4.235h7.87l-.55-.551a.941.941 0 111.33-1.331l2.158 2.158a.941.941 0 010 1.33l-2.158 2.159a.941.941 0 01-1.33-1.331l.55-.552H8.818a2.898 2.898 0 00-2.88 2.261 2.828 2.828 0 002.769 3.386h9.412a.941.941 0 01.94.942.94.94 0 01-.94.94H8.706a4.711 4.711 0 01-4.683-5.175z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgRedo = React.memo(SvgRedo);
export default MemoSvgRedo;
