import * as React from 'react';

const SvgHidePw = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M17.81 13.39A8.93 8.93 0 0021 7.62a1.006 1.006 0 10-2-.24 7.07 7.07 0 01-14 0 1.007 1.007 0 00-2 .24 8.93 8.93 0 003.18 5.77l-2.3 2.32a1 1 0 001.41 1.41l2.61-2.6c.97.49 2.02.802 3.1.92V19a1 1 0 002 0v-3.56a9.06 9.06 0 003.1-.92l2.61 2.6a1 1 0 001.41-1.41l-2.31-2.32z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgHidePw = React.memo(SvgHidePw);
export default MemoSvgHidePw;
