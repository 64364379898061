import * as React from 'react';

const SvgMoreH = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M12 14a2 2 0 100-4 2 2 0 000 4zM19 14a2 2 0 100-4 2 2 0 000 4zM5 14a2 2 0 100-4 2 2 0 000 4z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgMoreH = React.memo(SvgMoreH);
export default MemoSvgMoreH;
