const colors = {
  mainBlue: '#272B5F',
  mainBlue2: '#3D406F',
  mainBlue3: '#444774',
  mainBlue4: '#9699B9',
  accentBlue: '#326DE3',
  accentBlue2: '#DBE7F4',
  accentBlue3: '#E3EDFF',
  accentBlue4: '#EDF3F9',
  supportRed: '#DD0C15',
  black: '#000000',
  dark: '#232537',
  grey: '#828A98',
  grey2: '#D3D7E0',
  grey3: '#FAFBFD',
  grey4: '#FBFCFF',
  grey5: '#F4F5F7',
  grey6: '#707070',
  white: '#FFFFFF',
  validation: '#FF565D',
  validationLight: '#FFFBFB',
  success: '#4BC666',
  successLight: '#F7FDF8',
  alert: '#FF8946',
};

const font = {
  family: {
    default: "'Nunito', sans-serif",
  },
  size: {
    h1: '24px',
    h2: '18px',
    h3: '15px',
    h4: '14px',
    h5: '12px',
    body: '15px',
    paragraph: '9px',
    link: '12px',
    linkBig: '14px',
    huge: '120px',
  },
  weight: {
    normal: 400,
    medium: 600,
    bold: 700,
    bolder: 800,
  },
};

const breakpoints = {
  desktop: 1440,
};

const theme = {
  breakpoints,
  colors,
  font,
};

export type ThemeTypes = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeTypes {}
}

export default theme;
