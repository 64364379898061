import * as React from 'react';
import { Container, Column, ButtonWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { BottomModal } from '../../../../components/Modal';
import { Progress } from '../../../../components/Progress';
import { Button, ButtonTypes } from '../../../../components/Button';
import { Typography } from '../../../../components/Typography';

export interface Props {
  progress: number;
  title: string;
  description?: string;
  onCancel?: () => void;
  show?: boolean;
}

const CreateModal: React.FC<Props> = React.memo(
  ({ show, onCancel, progress, title, description }) => {
    const { t } = useTranslation('project');

    return (
      <BottomModal show={show} title={title} timeout={0} fullWidth>
        <Container>
          <Column withCancel={!!onCancel}>
            <Progress current={progress} />
            {description && <Typography>{description}</Typography>}
          </Column>
          {onCancel && (
            <ButtonWrapper>
              <Button
                title={t('modals.cancel')}
                onClick={onCancel}
                type={ButtonTypes.SECONDARY}
                noMinWidth
              />
            </ButtonWrapper>
          )}
        </Container>
      </BottomModal>
    );
  },
);

CreateModal.displayName = 'CreateModal';

export default CreateModal;
