import * as React from 'react';
import { MinorItem as Container } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import { useCallback } from 'react';
import { RootRoutes } from '../../../../router/routes';

export interface Props {
  title: string;
  Icon: React.ReactNode;
  onClick(): void;
  onMouseEnter?(): void;
  active?: boolean;
  hide?: boolean;
}

export type MinorItemType = Omit<Props, 'active'> & { route: RootRoutes };

const MinorItem: React.FC<Props> = React.memo(
  ({ title, Icon, active, onClick, onMouseEnter, hide }) => {
    const handleClick = useCallback(() => !active && onClick(), [
      active,
      onClick,
    ]);

    if (!hide) {
      return (
        <Container
          role="menuitem"
          onClick={handleClick}
          onMouseEnter={onMouseEnter}
          active={active}>
          {Icon}
          <Typography variant={TypographyVariants.H4}>{title}</Typography>
        </Container>
      );
    }
    return null;
  },
);

MinorItem.displayName = 'MinorItem';

export default MinorItem;
