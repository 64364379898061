import styled, { css } from 'styled-components';

import { layout } from '../../styles';

export const input_font = css`
  font-size: ${({ theme }) => theme.font.size.h5};
  font-weight: 600;
  line-height: 16px;
`;

export const invalid_style = css`
  background: ${({ theme }) => theme.colors.validationLight};
  border: 1px solid ${({ theme }) => theme.colors.validation};
  &:hover {
    background: ${({ theme }) => theme.colors.validationLight};
  }
  &:active,
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.validation};
    background: ${({ theme }) => theme.colors.validationLight};
  }
`;

export const disabled_style = css`
  background: ${({ theme }) => theme.colors.grey4};
  border-style: dashed;
  cursor: default;
  &:focus,
  &:active {
    border: 1px dashed ${({ theme }) => theme.colors.accentBlue2};
  }
`;

const leftSpacing = css`
  padding-left: 32px;
`;

const rightSpacing = css`
  padding-right: 32px;
`;

const labelBottomMargin = css`
  > span:first-child {
    margin-bottom: 4px;
  }
`;

export const Container = styled.div<{ labeled: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ labeled }) => labeled && labelBottomMargin};
`;

const disabledSvg = css`
  svg {
    > path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const withButton = css`
  border-radius: 4px 0 0 4px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.dark};
`;

export const InputWrapper = styled.div<{
  disabled?: boolean;
  width?: number;
  fullWidth?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: ${({ width, fullWidth }) =>
    fullWidth ? '100%' : `${width ?? 270}px`};
  height: 40px;
  ${({ disabled }) => disabled && disabledSvg}
`;

export const Input = styled.input<{
  invalid?: boolean;
  hasLeading?: boolean;
  hasTrailing?: boolean;
  hasButton?: boolean;
  hideArrows?: boolean;
  hasUnit?: boolean;
}>`
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  height: inherit;
  padding: 12px 8px;
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  outline: 0;
  color: ${({ theme }) => theme.colors.dark};
  width: inherit;
  ${input_font};
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey6};
    ${input_font};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.grey4};
  }
  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.colors.mainBlue};
  }
  &:disabled {
    ${disabled_style};
  }
  &:invalid {
    ${invalid_style};
  }
  &[type='number'] {
    padding-right: ${({ hideArrows, hasUnit }) =>
      !hideArrows && hasUnit ? '48px' : !hasUnit ? '16px' : 'initial'};
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  ${({ invalid }) => invalid && invalid_style};
  ${({ hasLeading }) => hasLeading && leftSpacing};
  ${({ hasTrailing }) => hasTrailing && rightSpacing};
  ${({ hasButton }) => hasButton && withButton};
`;

export const LeadingIcon = styled.div`
  position: absolute;
  left: 8px;
  > svg {
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
  }
`;

export const clickable = css`
  &:hover {
    cursor: pointer;
    opacity: 0.66;
  }
`;

const iconPosition = (withButton?: boolean, hasNumber?: boolean) => {
  let position = 8;
  if (withButton) {
    position = position + 40;
  }
  if (hasNumber) {
    position = position + 48;
  }
  return position;
};

export const TrailingIcon = styled.div<{
  role?: string;
  withButton?: boolean;
  hasNumber?: boolean;
}>`
  position: absolute;
  right: ${({ withButton, hasNumber }) =>
    iconPosition(withButton, hasNumber)}px;
  > svg {
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
  }
  ${({ role }) => role === 'button' && clickable}
`;

export const TrailingButton = styled.button`
  box-sizing: border-box;
  width: 40px;
  height: inherit;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  border-left: 0;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  > svg {
    margin-bottom: -3px;
  }
  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      outline: 0;
      background: ${({ theme }) => theme.colors.grey4};
      svg {
        > path {
          fill: ${({ theme }) => theme.colors.accentBlue};
        }
      }
    }
  }
  &:disabled {
    cursor: default;
    border: 1px dashed ${({ theme }) => theme.colors.accentBlue2};
    border-left: 0;
    svg {
      > path {
        fill: ${({ theme }) => theme.colors.grey};
      }
    }
  }
`;

export const AddonContainer = styled.div<{ withButton?: boolean }>`
  display: flex;
  flex-direction: row;
  height: inherit;
  align-items: center;
  position: absolute;
  right: ${({ withButton }) => (withButton ? 40 : 0)}px;
  max-width: 50px;
  justify-content: space-between;
  > span {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

export const UnitWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;

export const AddonControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
`;

export const AddonControl = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 18px;
  svg {
    width: 10px;
    height: 10px;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.66;
  }
  &:first-of-type {
    svg {
      margin-bottom: -5px;
    }
  }
  &:not(:first-of-type) {
    svg {
      margin-top: -5px;
    }
  }
`;

export const ErrorMessage = styled.div`
  width: fit-content;
  > span {
    color: ${({ theme }) => theme.colors.validation};
  }
`;

export const OptionalLabel = styled(layout.Row)`
  align-items: center;
  height: 16px;
  > span {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

export const LabelWrapper = styled(layout.Row)`
  align-items: center;
  padding-bottom: 4px;
`;

export const ErrorLabelBreak = styled.div`
  height: 38px;
`;
