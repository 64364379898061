import * as React from 'react';
import {
  Container,
  GlassShape,
  DataColumn,
  ItemsWrapper,
  DataItem,
  DeleteButton,
  TitleRow,
} from './styles';
import { Glass } from '../../../../../../services/api/models/glass';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { Close } from '../../../../../../components/icons';
import { useTranslation } from 'react-i18next';
import { GlassTypes } from '../../../../../../types';
import { Shape } from '../../../../../../modules/space';
import { useMemo } from 'react';

export interface Props {
  data: Glass;
  onDelete(glassId: number, type: 'glass'): void;
  onSelect(glassId: number, type: 'glass'): void;
}

const AttachedGlass: React.FC<Props> = React.memo(
  ({ data, onDelete, onSelect, ...props }) => {
    const { t } = useTranslation(['project', 'creator']);

    const glassObj = useMemo(() => new Shape({ corners: data.corners }), [
      data.corners,
    ]);

    const dataItems = useMemo(() => {
      if (glassObj.isRectangular) {
        return {
          width: data.width,
          height: data.height,
          thickness: data.thickness,
        };
      } else {
        return {
          top: glassObj.topEdge.length,
          right: glassObj.rightEdge.length,
          bottom: glassObj.bottomEdge.length,
          left: glassObj.leftEdge.length,
          thickness: data.thickness,
        };
      }
    }, [data, glassObj]);

    return (
      <Container
        role="listitem"
        onClick={() => onSelect(data.id, 'glass')}
        {...props}>
        <GlassShape />
        <DataColumn>
          <TitleRow>
            <Typography variant={TypographyVariants.H5_BOLD}>
              {t('project:productsPanel.attached.glass')} {data.id}{' '}
              {data.panelType &&
                `: ${
                  data.panelType === GlassTypes.DOOR
                    ? t('creator:panelType.door')
                    : t('creator:panelType.fixed')
                }`}
            </Typography>
            <DeleteButton
              role="button"
              onClick={() => onDelete(data.id, 'glass')}>
              <Close />
            </DeleteButton>
          </TitleRow>
          <ItemsWrapper>
            {Object.entries(dataItems).map(([key, value]) => (
              <DataItem key={key} aria-label={key}>
                <Typography variant={TypographyVariants.H5}>
                  {t(`project:productsPanel.attached.${key}`)}:
                </Typography>
                <Typography variant={TypographyVariants.H5_BOLD}>
                  {t('units.millimeterValue', { value })}
                </Typography>
              </DataItem>
            ))}
          </ItemsWrapper>
        </DataColumn>
      </Container>
    );
  },
);

AttachedGlass.displayName = 'AttachedGlass';

export default AttachedGlass;
