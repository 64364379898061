import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import createVanilla from 'zustand/vanilla';
import fileUpload from '../../../../services/api/services/fileUpload';

export const STORE_NAME = `@store/creator/snapshot`;

export type State = {
  generator(): string;
  setGenerator(generator: () => string): void;
  catch(): Promise<string>;
};

type Set = SetState<State>;

type Get = GetState<State>;

const catchAction = (set: Set, get: Get) => async () => {
  const { generator } = get();
  const dataUrl = generator();
  const data = await fetch(dataUrl);
  const blob = await data.blob();
  const file = new File([blob], 'snapshot.png', { type: blob.type });
  const { data: fileData } = await fileUpload.uploadFile(file);
  return fileData.accessUrl;
};

const store = (set: Set, get: Get): State => ({
  generator: () => '',
  setGenerator: (generator) => {
    set({ generator });
  },
  catch: catchAction(set, get),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
