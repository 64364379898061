import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  creatorConfig,
  creatorProducts,
  creatorProject,
} from '../../../../../../modules/creator';
import useProduct from '../../../../../../modules/creator/components/Product/useProduct';
import useProductMinEgdeGap from '../../../../../../modules/creator/components/Product/useProductMinEgdeGap';
import {
  DEFAULT_RATIO,
  selectScale,
} from '../../../../../../modules/creator/store/config';
import { CreatorProduct } from '../../../../../../modules/creator/store/products';
import {
  selectData,
  selectProjectGlasses,
  selectProjectPosition,
} from '../../../../../../modules/creator/store/project';
import { scaleShape } from '../../../../../../modules/creator/utils/shapes';
import {
  BaseType,
  ProductModel,
  ProductSearch,
} from '../../../../../../services/api/models/product';
import { useCreator } from '../../../../hooks';
import { AlignTo, AlignToTypes } from '../../components/AlignTo';
import { PanelHeader } from '../../components/PanelHeader';
import { ProductInfo } from '../../components/ProductInfo';
import { FormSection } from '../../styles';
import ProductPositioning from './ProductPositioning';
import { Container } from './styles';

export interface Props {
  productSearchData: ProductSearch[];
  multiselect?: boolean;
  creatorProduct: CreatorProduct;
}

const {
  useProjectStore,
  selectUpdateProduct: selectUpdateProjectProduct,
} = creatorProject;

const {
  useProductsStore,
  selectDistanceType,
  selectSetDistanceType,
} = creatorProducts;

const { useConfigStore, selectMainLayerPosition } = creatorConfig;

const ProductForm: React.FC<Props> = React.memo(
  ({ productSearchData, multiselect, creatorProduct }) => {
    const { t } = useTranslation('project');

    const title = useMemo(() => {
      if (!multiselect && productSearchData) {
        return productSearchData[0]?.code;
      }
      return 'propertyPanel.titles.product';
    }, [multiselect, productSearchData]);

    const {
      templateId,
      selectShape,
      selected: templateSelected,
    } = useCreator();

    const isTemplateSelected = useMemo(
      () => templateSelected[0]?.type === 'template',
      [templateSelected],
    );
    const selectTemplate = useCallback(() => {
      if (templateId) {
        selectShape({ shapeId: templateId, type: 'template' });
      }
    }, [selectShape, templateId]);

    const [showProductInfo, setShowProductInfo] = useState<
      Map<number, boolean>
    >(new Map());
    const scale = useConfigStore(selectScale);
    const layerPosition = useConfigStore(selectMainLayerPosition);
    const updateProjectProduct = useProjectStore(selectUpdateProjectProduct);
    const distanceType = useProductsStore(selectDistanceType);
    const setDistanceType = useProductsStore(selectSetDistanceType);

    const templatePosition = useProjectStore(selectProjectPosition);
    const { dimensions: templateDimensions } = useProjectStore(selectData);
    const glasses = useProjectStore(selectProjectGlasses);

    const { parentConnection, glassParent, mountedEdgePoints } = useProduct({
      data: creatorProduct.data,
      glasses,
    });

    const minGapToEdge = useProductMinEgdeGap({
      glassParent,
      usedProduct: productSearchData[0],
      parentConnection,
      glasses,
      templatePosition,
      templateDimensions,
    });

    const isPipe = useMemo(() => {
      const item = productSearchData.find(
        (data) => data.id === creatorProduct.data.productId,
      );
      return item?.baseType === BaseType.PIPE;
    }, [creatorProduct.data.productId, productSearchData]);

    const toggleProductInfo = useCallback(
      (id: number) => {
        showProductInfo.set(id, !showProductInfo.get(id));
        setShowProductInfo(new Map(showProductInfo));
      },
      [showProductInfo],
    );

    const alignTo = useCallback(
      (align: AlignToTypes) => {
        if (creatorProduct.parentType === 'glass') {
          const { data, boundaries } = creatorProduct;
          const scaled = scaleShape(data, scale);
          let updatedProduct: ProductModel;
          // TODO: Divisions should be done better
          switch (align) {
            case AlignToTypes.LEFT: {
              updatedProduct = {
                ...data,
                position: {
                  ...data.position,
                  x:
                    (boundaries.left - layerPosition.x) / DEFAULT_RATIO / scale,
                },
              };
              break;
            }
            case AlignToTypes.RIGHT: {
              updatedProduct = {
                ...data,
                position: {
                  ...data.position,
                  x:
                    (boundaries.right - layerPosition.x - scaled.width) /
                    DEFAULT_RATIO /
                    scale,
                },
              };
              break;
            }
            case AlignToTypes.UP: {
              updatedProduct = {
                ...data,
                position: {
                  ...data.position,
                  y: (boundaries.top - layerPosition.y) / DEFAULT_RATIO / scale,
                },
              };
              break;
            }
            case AlignToTypes.LOW: {
              updatedProduct = {
                ...data,
                position: {
                  ...data.position,
                  y:
                    (boundaries.bottom - layerPosition.y - scaled.height) /
                    DEFAULT_RATIO /
                    scale,
                },
              };
              break;
            }
            case AlignToTypes.CENTER: {
              updatedProduct = {
                ...data,
                position: {
                  ...data.position,
                  x:
                    (boundaries.left +
                      (boundaries.right - boundaries.left) / 2 -
                      scaled.width / 2 -
                      layerPosition.x) /
                    DEFAULT_RATIO /
                    scale,
                },
              };
              break;
            }
            case AlignToTypes.MIDDLE: {
              updatedProduct = {
                ...data,
                position: {
                  ...data.position,
                  y:
                    (boundaries.top +
                      (boundaries.bottom - boundaries.top) / 2 -
                      scaled.height / 2 -
                      layerPosition.y) /
                    DEFAULT_RATIO /
                    scale,
                },
              };
              break;
            }
          }
          updatedProduct &&
            setTimeout(() => updateProjectProduct(updatedProduct));
        }
      },
      [
        creatorProduct,
        layerPosition.x,
        layerPosition.y,
        scale,
        updateProjectProduct,
      ],
    );

    return (
      <>
        <PanelHeader
          title={t(title)}
          actionTitle={t('propertyPanel.actionTitle')}
          onAction={selectTemplate}
          withAction={!isTemplateSelected}
        />
        <Container>
          {!isPipe && (
            <>
              <FormSection noPadding>
                <AlignTo onSelect={alignTo} disabled={multiselect} />
              </FormSection>
              <FormSection>
                <ProductPositioning
                  productSearchData={productSearchData}
                  updateProjectProduct={updateProjectProduct}
                  creatorProduct={creatorProduct}
                  distanceType={distanceType}
                  onDistanceTypeChange={setDistanceType}
                  minGapToEdge={minGapToEdge}
                  mountedEdgePoints={mountedEdgePoints}
                />
              </FormSection>
            </>
          )}
          {productSearchData.map((product) => (
            <ProductInfo
              key={product.id}
              data={{ type: 'search', product }}
              onToggle={toggleProductInfo}
              unfold={showProductInfo.get(product.id)}
              multiselect={multiselect}
              realWidth={creatorProduct.data.width}
              realHeight={creatorProduct.data.height}
            />
          ))}
        </Container>
      </>
    );
  },
);

export default ProductForm;
