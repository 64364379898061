import * as React from 'react';
import { Container } from './styles';
import { useLocation } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import { useMemo } from 'react';
import { Breadcrumb as BreadcrumbType } from './types';
import { RootRoutes } from '../../router/routes';
import { useTranslation } from 'react-i18next';

export interface Props {
  customRouteName?: string;
}

const Breadcrumbs: React.FC<Props> = ({ customRouteName }) => {
  const { t } = useTranslation('routes');
  const { pathname } = useLocation();

  const home = useMemo<BreadcrumbType>(
    () => ({
      pathname: RootRoutes.HOME,
      title: t('home'),
    }),
    [t],
  );

  const breadcrumbs = useMemo(() => {
    const items: BreadcrumbType[] = [];
    const names = pathname.split('/').filter(Boolean);
    names.forEach((name, index) =>
      items.push({
        pathname: `${items[index - 1] ?? ''}/${name}`,
        title: t(name),
      }),
    );
    if (customRouteName && items.length > 0) {
      items[items.length - 1].title = customRouteName;
    }
    return items;
  }, [customRouteName, pathname, t]);

  return (
    <Container role="navigation" aria-label="breadcrumbs">
      <Breadcrumb data={home} last={breadcrumbs.length < 1} root />
      {breadcrumbs.map((item, index) => (
        <Breadcrumb
          key={item.pathname}
          data={item}
          last={index === breadcrumbs.length - 1}
        />
      ))}
    </Container>
  );
};

export default Breadcrumbs;
