import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Container } from './styles';
import { PanelHeader } from '../../components/PanelHeader';
import { useCreator } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import {
  ProjectDimensions,
  ProjectStatus,
} from '../../../../../../services/api/models/project';
import { EdgeType, IndentEdgeType } from '../../../../../../types';
import { changeShape } from '../../../../../../utils/shape';
import { findInnerGlassesBounds } from '../../../../../../modules/creator/utils/boundaries';
import {
  HorizontalPositioning,
  VerticalPositioning,
} from '../../components/DimensionsInputs';
import NicheDimensions from './NicheDimensions';
import { AreaInputs } from '../../components/AreaInputs';
import { Shape } from '../../../../../../modules/space';

const NicheForm: React.FC = () => {
  const { t } = useTranslation('project');

  const {
    project,
    templateId,
    selectShape,
    selected,
    toggleAdjustManually,
    updateCorners,
    updateDimensions,
    projectGlasses,
  } = useCreator();

  const isTemplateSelected = useMemo(() => selected[0]?.type === 'template', [
    selected,
  ]);

  const selectTemplate = useCallback(() => {
    if (templateId) {
      selectShape({ shapeId: templateId, type: 'template' });
    }
  }, [selectShape, templateId]);

  const handleUpdateDimensions = useCallback(
    ({
      diff,
      edge,
      direction,
      lockOpposite,
    }: {
      diff: number;
      edge: IndentEdgeType;
      direction: HorizontalPositioning | VerticalPositioning;
      lockOpposite?: boolean;
    }) => {
      const lock = project.data.dimensions.indent
        ? !project.data.adjustManually
        : lockOpposite;

      const dimensions = changeShape(
        project.data.dimensions,
        edge as EdgeType,
        diff,
        direction,
        lock,
      );

      const shapeObj = new Shape({ corners: dimensions.corners });

      shapeObj.roundCorners();

      updateCorners({
        ...project.data.dimensions,
        corners: shapeObj.corners,
      } as ProjectDimensions);
    },
    [project.data.dimensions, updateCorners],
  );

  const glassBoundaries = useMemo(
    () => findInnerGlassesBounds(projectGlasses),
    [projectGlasses],
  );

  return (
    <>
      <PanelHeader
        title={t('propertyPanel.titles.niche')}
        actionTitle={t('propertyPanel.actionTitle')}
        onAction={selectTemplate}
        withAction={!isTemplateSelected}
        adjustManually={project.data.adjustManually}
        onToggleAdjustManually={toggleAdjustManually}
      />
      <Container>
        {project.projectStatus !== ProjectStatus.CLEAR && (
          <>
            {project.data.adjustManually || project.data.dimensions.indent ? (
              <AreaInputs
                selectedId={parseFloat(templateId ?? '')}
                dimensions={project.data.dimensions}
                onChange={handleUpdateDimensions}
              />
            ) : (
              <NicheDimensions
                projectData={project.data}
                innerBoundaries={glassBoundaries}
                onUpdateDimensions={updateDimensions}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default NicheForm;
