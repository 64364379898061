import * as React from 'react';

const SvgDownSm = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M12 15.5a1 1 0 01-.71-.29l-4-4a1.004 1.004 0 111.42-1.42L12 13.1l3.3-3.18a1 1 0 111.38 1.44l-4 3.86a1 1 0 01-.68.28z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgDownSm = React.memo(SvgDownSm);
export default MemoSvgDownSm;
