import { Position } from './position';
import { ExtendedAnchoringOptions } from './product';

export enum ConnectionType {
  GLASS = 'GLASS',
  WALL = 'WALL',
  PRODUCT = 'PRODUCT',
}

export interface Connection {
  type: ConnectionType;
  position: Position;
  targetId: string;
  anchor: keyof ExtendedAnchoringOptions;
}
