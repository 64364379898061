import * as React from 'react';
import { Container } from './styles';
import { Variants } from './variants';

export interface Props {
  variant?: Variants;
  color?: string;
  onClick?(): void;
  disabled?: boolean;
  oneLine?: boolean;
  as?: string;
}

const Typography: React.FC<Props> = React.memo(
  ({
    as,
    children,
    color,
    onClick,
    disabled,
    variant = Variants.BODY,
    oneLine = false,
    ...rest
  }) => {
    return (
      <Container
        {...rest}
        as={as as undefined}
        color={color}
        variant={variant}
        onClick={onClick}
        hasClick={!!onClick}
        disabled={disabled}
        role={onClick ? 'button' : undefined}
        oneLine={oneLine}>
        {children}
      </Container>
    );
  },
);

Typography.displayName = 'Typography';

export default Typography;
