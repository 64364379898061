import * as React from 'react';
import { Container } from './styles';
import { Positioning, PositioningProps } from '../Positioning';
import {
  OptionValue,
  Switch,
  SwitchOption,
} from '../../../../../../components/Switch';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum PositioningTypes {
  AXIS = 'AXIS',
  EDGE = 'EDGE',
}

export interface Props extends Omit<PositioningProps, 'title'> {
  positioningType: PositioningTypes;
  onTypeChange(type: PositioningTypes): void;
  onlyEdge?: boolean;
}

const ProductPosition: React.FC<Props> = React.memo(
  ({ positioningType, onTypeChange, onlyEdge, ...props }) => {
    const { t } = useTranslation('project');

    const switchOptions = useMemo<SwitchOption[]>(
      () => [
        {
          title: t('propertyPanel.form.positioningTypes.axis'),
          value: PositioningTypes.AXIS,
        },
        {
          title: t('propertyPanel.form.positioningTypes.edge'),
          value: PositioningTypes.EDGE,
        },
      ],
      [t],
    );

    const selectedType = useMemo(() => [positioningType], [positioningType]);

    const handleTypeChange = useCallback(
      (values: OptionValue[]) => onTypeChange(values[0] as PositioningTypes),
      [onTypeChange],
    );

    return (
      <Container onlyEdge={onlyEdge}>
        <Typography variant={TypographyVariants.H5_BOLD}>
          {t(
            onlyEdge
              ? 'propertyPanel.form.productPositionFromEdge'
              : 'propertyPanel.form.productPosition',
          )}
        </Typography>
        {!onlyEdge && (
          <Switch
            options={switchOptions}
            selected={selectedType}
            onChange={handleTypeChange}
            width={330}
          />
        )}
        <Positioning {...props} />
      </Container>
    );
  },
);

ProductPosition.displayName = 'ProductPosition';

export default ProductPosition;
