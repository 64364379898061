import { useCallback, useState } from 'react';
import { ClientSearch } from '../../../services/api/models/client';
import { clientApi } from '../../../services/api/services';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../../../store/notifications';
import { NotificationVariants } from '../../../components/Notification';

export interface UseClients {
  clients: ClientSearch[];
  search(input: string): Promise<void>;
}

const useClients = (): UseClients => {
  const [clients, setClients] = useState<ClientSearch[]>([]);
  const setNotification = useNotificationsStore(selectSetNotification);

  const search = useCallback(
    async (input: string) => {
      try {
        const { data } = await clientApi.filterClients({ command: { input } });
        setClients(data.content);
      } catch (e) {
        setNotification({
          text: e.message,
          variant: NotificationVariants.ERROR,
        });
      }
    },
    [setNotification],
  );

  return {
    clients,
    search,
  };
};

export default useClients;
