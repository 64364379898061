import * as React from 'react';

const SvgClients = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={187}
      height={125}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={36.926}
        width={114.99}
        height={114.99}
        rx={14}
        transform="rotate(4.644 36.926 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#clients_svg__filter0_d)">
        <rect x={27} y={17} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={69} y={28} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={69} y={43} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={34} y={23} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M52.666 33.667h-2v-1A1.667 1.667 0 0049 31h-2a1.666 1.666 0 00-1.667 1.667v1h-2a2 2 0 00-2 2V41a2 2 0 002 2h9.333a2 2 0 002-2v-5.333a2 2 0 00-2-2zM50 35v6.667h-4V35h4zm-3.334-2.333a.333.333 0 01.334-.334h2a.333.333 0 01.333.334v1h-2.667v-1zm-4 8.333v-5.333a.667.667 0 01.667-.667h1.333v6.667h-1.333a.667.667 0 01-.667-.667zm10.667 0a.666.666 0 01-.667.667h-1.333V35h1.333a.666.666 0 01.667.667V41z"
        fill="#fff"
      />
      <g filter="url(#clients_svg__filter1_d)">
        <rect x={10} y={65} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={52} y={76} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={52} y={91} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={17} y={71} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M35.666 81.667h-2v-1A1.667 1.667 0 0032 79h-2a1.666 1.666 0 00-1.667 1.667v1h-2a2 2 0 00-2 2V89a2 2 0 002 2h9.333a2 2 0 002-2v-5.333a2 2 0 00-2-2zM33 83v6.667h-4V83h4zm-3.334-2.333a.333.333 0 01.334-.334h2a.333.333 0 01.333.334v1h-2.667v-1zm-4 8.333v-5.333a.667.667 0 01.667-.667h1.333v6.667h-1.333a.667.667 0 01-.667-.667zm10.667 0a.666.666 0 01-.667.667h-1.333V83h1.333a.666.666 0 01.667.667V89z"
        fill="#fff"
      />
      <defs>
        <filter
          id="clients_svg__filter0_d"
          x={17}
          y={17}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="clients_svg__filter1_d"
          x={0}
          y={65}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgClients = React.memo(SvgClients);
export default MemoSvgClients;
