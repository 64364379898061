import { useCallback, useMemo } from 'react';
import { TemplateTypes } from '../modules/creator';
import { Gap } from '../types';
import { useCreator, useOpenProject } from '../pages/Project/hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LocationState, RouteParams } from '../pages/Project/Project';
import { NotificationVariants } from '../components/Notification';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../store/notifications';
import { useTranslation } from 'react-i18next';
import { InsertOption, InsertType } from '../components/Insert';
import { NicheItem } from '../components/InsertNiche';
import { InsertItemTypes } from '../modules/creator/store/insert';

export interface UseInsert {
  onInsert(insert: InsertOption | NicheItem): void;
  insertNiche(template: TemplateTypes): void;
  insertGlass(value: number): void;
  insertGap(value: number): void;
}

const useInsert = (useInsertPayload: {
  onGlassCreationStart: () => void;
  onGlassCreationComplete: () => void;
}): UseInsert => {
  const { onGlassCreationStart, onGlassCreationComplete } = useInsertPayload;

  const { t } = useTranslation('project');
  const { id } = useParams<RouteParams>();
  const location = useLocation<LocationState>();
  const { replace } = useHistory();
  const { openNew } = useOpenProject();
  const setNotification = useNotificationsStore(selectSetNotification);
  const {
    project,
    setBaseTemplate,
    selectTemplate,
    addGlass,
    insertItem,
    setIndent,
  } = useCreator();

  const projectId = useMemo(() => id ?? project?.id, [project?.id, id]);

  const startCreate = useCallback(
    (template: TemplateTypes) => {
      openNew(template, true);
      replace({ ...location, state: { create: true } });
    },
    [location, openNew, replace],
  );

  const insertNiche = useCallback(
    (template: TemplateTypes) => {
      if (projectId !== -1) {
        const tempObject = selectTemplate(template);
        tempObject.dimensions.indent && setIndent(tempObject.dimensions.indent);
        return setBaseTemplate(tempObject);
      }
      return startCreate(template);
    },
    [projectId, selectTemplate, setBaseTemplate, setIndent, startCreate],
  );

  const onInsertGlassWarn = useCallback(() => {
    setNotification({
      variant: NotificationVariants.ERROR,
      text: t('notifications.glassLimit'),
    });
  }, [setNotification, t]);

  const onGlassCreationInProgress = useCallback(() => {
    onGlassCreationStart?.();
    setNotification({
      variant: NotificationVariants.INFO,
      text: t('notifications.glassCreationInProgress'),
      timeout: 3000,
    });
  }, [setNotification, t, onGlassCreationStart]);

  const onFallbackGlassCreation = useCallback(() => {
    setNotification({
      variant: NotificationVariants.ALERT,
      text: t('notifications.fallbackGlassCreated'),
    });
  }, [setNotification, t]);

  const onComplete = useCallback(() => {
    onGlassCreationComplete?.();
  }, [onGlassCreationComplete]);

  const insertGlass = useCallback(
    (value: number) => {
      return addGlass(
        value,
        onInsertGlassWarn,
        onGlassCreationInProgress,
        onFallbackGlassCreation,
        onComplete,
      );
    },
    [
      addGlass,
      onInsertGlassWarn,
      onGlassCreationInProgress,
      onFallbackGlassCreation,
      onComplete,
    ],
  );

  const insertGap = useCallback(
    (size: Gap) => {
      setNotification({
        variant: NotificationVariants.INFO,
        text: t('notifications.insertGap'),
      });
      return insertItem({ type: InsertItemTypes.GAP, size });
    },
    [insertItem, setNotification, t],
  );

  const onInsert = useCallback(
    (insert: InsertOption | NicheItem) => {
      switch (insert.type) {
        case InsertType.NICHE: {
          return insertNiche(insert.value as TemplateTypes);
        }
        case InsertType.GLASS: {
          return insertGlass(insert.value as number);
        }
        case InsertType.GAP: {
          return insertGap(insert.value as Gap);
        }
        default: {
          break;
        }
      }
    },
    [insertGap, insertGlass, insertNiche],
  );

  return {
    onInsert,
    insertGlass,
    insertNiche,
    insertGap,
  };
};

export default useInsert;
