import styled from 'styled-components';
import { AttachedGlassStyles } from '../AttachedGlass';

export const ImageWrapper = styled.div``;

export const Image = styled.img`
  width: 64px;
  height: auto;
  object-fit: contain;
`;

export const Container = AttachedGlassStyles.Container;

export const DataColumn = AttachedGlassStyles.DataColumn;

export const TitleRow = AttachedGlassStyles.TitleRow;

export const ItemsWrapper = AttachedGlassStyles.ItemsWrapper;

export const DataItem = AttachedGlassStyles.DataItem;

export const DeleteButton = AttachedGlassStyles.DeleteButton;
