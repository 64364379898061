import * as React from 'react';
import { Container, Header, ItemsWrapper } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { DownSm, UpSm } from '../../../../../../components/icons';
import { PropertySelect, PropertySelectOption } from '../PropertySelect';
import {
  PropertySwitchOption,
  PropertyOptionValue,
  PropertySwitch,
} from '../PropertySwitch';
import { OptionObject } from '../../../../../../components/Select';

interface PropertyItem {
  type: 'switch' | 'select';
  title: string;
  name: string;
}

export interface PropertySelectItem extends PropertyItem {
  options: PropertySelectOption[];
  selected?: PropertySelectOption;
  onSelect(option: OptionObject): void;
}

export interface PropertySwitchItem extends PropertyItem {
  options: PropertySwitchOption[];
  selected: PropertyOptionValue;
  disabled?: boolean;
  onChange(value: PropertyOptionValue, name: string): void;
}

export interface Props {
  title: string;
  items: (PropertySelectItem | PropertySwitchItem)[];
  onFold(): void;
  validateThicknessChange(option: OptionObject): Promise<boolean>;
  fold?: boolean;
}

const GlassProperties: React.FC<Props> = React.memo(
  ({ title, fold, items, onFold, validateThicknessChange, ...props }) => {
    return (
      <Container {...props}>
        <Header aria-label="fold-button" role="button" onClick={onFold}>
          <Typography variant={TypographyVariants.H5_BOLD}>{title}</Typography>
          {fold ? <UpSm /> : <DownSm />}
        </Header>
        {!fold && (
          <ItemsWrapper>
            {items.map(({ type, ...itemProps }) => {
              switch (type) {
                case 'select': {
                  return (
                    <PropertySelect
                      key={itemProps.name}
                      validate={
                        itemProps.name === 'glassThickness'
                          ? validateThicknessChange
                          : undefined
                      }
                      {...(itemProps as PropertySelectItem)}
                    />
                  );
                }
                case 'switch': {
                  return (
                    <PropertySwitch
                      key={itemProps.name}
                      {...(itemProps as PropertySwitchItem)}
                    />
                  );
                }
                default: {
                  return null;
                }
              }
            })}
          </ItemsWrapper>
        )}
      </Container>
    );
  },
);

GlassProperties.displayName = 'GlassProperties';

export default GlassProperties;
