import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationVariants } from '../components/Notification';
import {
  selectSetNotification,
  useNotificationsStore,
} from '../store/notifications';
import { validateExtension } from '../utils/files';

export interface UseFileExtensionValidator {
  validate(
    target: HTMLInputElement,
    sizeLimit: number,
    allowedFileExtensions: string[],
    onSuccess: (file: File, extension: string) => void,
  ): void;
}

const useFileExtensionValidator = (): UseFileExtensionValidator => {
  const { t } = useTranslation('common');
  const setNotification = useNotificationsStore(selectSetNotification);

  const validate = useCallback(
    (
      target: HTMLInputElement,
      sizeLimit: number,
      allowedFileExtensions: string[],
      onSuccess: (file: File, extension: string) => void,
    ) => {
      if (target.files && target.files[0]) {
        const file = target.files[0];
        const extension = file.name.split('.').pop();
        if (extension) {
          if (file.size > sizeLimit) {
            setNotification({
              variant: NotificationVariants.ERROR,
              text: t('files.upload.errors.toBigSize', {
                limit: `${sizeLimit / 1024 / 1024}${t('units.megabytes')}`,
              }),
            });
          } else if (!validateExtension(extension, allowedFileExtensions)) {
            setNotification({
              variant: NotificationVariants.ERROR,
              text: t('files.upload.errors.incorrectExtension', {
                extensions: allowedFileExtensions.join(', '),
              }),
            });
          } else {
            onSuccess(file, extension);
          }
        }
        target.value = '';
      }
    },
    [t, setNotification],
  );

  return { validate };
};

export default useFileExtensionValidator;
