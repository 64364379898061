import styled, { css } from 'styled-components';

const center_content = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlue2};
  padding: 12px 16px;
`;

export const Results = styled.div<{ empty?: boolean }>`
  height: calc(100% - 116px);
  box-sizing: border-box;
  padding-bottom: 44px;
  overflow: auto;
  ${({ empty }) => empty && center_content}
`;
