import { Shape } from './Shape';
import { Vector2, Vector3 } from '../vector';

enum PolygonWinding {
  Clockwise,
  CounterClockwise,
}

class Edge {
  public distance: number;
  public normal: Vector2;
  public index: number;

  constructor(distance: number, normal: Vector2, index: number) {
    this.distance = distance;
    this.normal = normal;
    this.index = index;
  }
}

enum EvolveResult {
  NoIntersection,
  FoundIntersection,
  StillEvolving,
}

export class GJK2D {
  /**
   The maximum number of simplex evolution iterations before we accept the
   given simplex. For non-curvy shapes, this can be low. Curvy shapes potentially
   require higher numbers, but this can introduce significant slow-downs at
   the gain of not much accuracy.
   */
  static MAX_ITERATIONS = 10;
  /**
   The maximum number of iterations to employ when running the EPA algorithm, usually
   required to be large-ish for circles
   **/
  static MAX_INTERSECTION_ITERATIONS = 10;

  /**
   How accurate do intersection calculations need to be when using expanding polytopes
   **/
  static INTERSECTION_EPSILON = 0.0000001;

  private vertices: Vector2[];
  private direction: Vector2;

  public constructor() {
    this.vertices = [];
    this.direction = new Vector2();
  }

  private calculateSupport(
    direction: Vector2,
    shapeA: Shape,
    shapeB: Shape,
  ): Vector2 {
    const oppositeDirection: Vector2 = direction.negate();
    const shapeASupport: Vector2 = shapeA.support(direction);
    const shapeBSupport: Vector2 = shapeB.support(oppositeDirection);

    return shapeASupport.subtract(shapeBSupport);
  }

  private addSupport(
    direction: Vector2,
    shapeA: Shape,
    shapeB: Shape,
  ): boolean {
    const newVertex = this.calculateSupport(direction, shapeA, shapeB);

    this.vertices.push(newVertex);

    return Vector2.dot(direction, newVertex).gt(0);
  }

  private tripleProduct(a: Vector2, b: Vector2, c: Vector2): Vector2 {
    const A: Vector3 = new Vector3([a.x, a.y, 0]);
    const B: Vector3 = new Vector3([b.x, b.y, 0]);
    const C: Vector3 = new Vector3([c.x, c.y, 0]);

    const first: Vector3 = Vector3.cross(A, B, new Vector3());
    const second: Vector3 = Vector3.cross(first, C, new Vector3());

    return new Vector2([second.x, second.y]);
  }

  private evolveSimplex(shapeA: Shape, shapeB: Shape): EvolveResult {
    switch (this.vertices.length) {
      case 0: {
        const shapeACentre: Vector2 = shapeA.centre();
        const shapeBCentre: Vector2 = shapeB.centre();
        this.direction = shapeBCentre.subtract(shapeACentre);
        break;
      }
      case 1: {
        this.direction = this.direction.negate();
        break;
      }
      case 2: {
        const b: Vector2 = this.vertices[1];
        const c: Vector2 = this.vertices[0];
        const cb: Vector2 = b.subtract(c);
        const c0: Vector2 = c.negate();

        this.direction = this.tripleProduct(cb, c0, cb);
        break;
      }
      case 3: {
        const a: Vector2 = this.vertices[2];
        const b: Vector2 = this.vertices[1];
        const c: Vector2 = this.vertices[0];

        const a0: Vector2 = a.negate();
        const ab: Vector2 = b.subtract(a);
        const ac: Vector2 = c.subtract(a);
        const abPerp: Vector2 = this.tripleProduct(ac, ab, ab);
        const acPerp: Vector2 = this.tripleProduct(ab, ac, ac);

        if (Vector2.dot(abPerp, a0).gt(0)) {
          this.vertices.splice(2, 1);
          this.direction = abPerp;
        } else if (Vector2.dot(acPerp, a0).gt(0)) {
          this.vertices.splice(1, 1);
          this.direction = acPerp;
        } else {
          return EvolveResult.FoundIntersection;
        }
        break;
      }
      default: {
        throw `Can't have simplex with ${this.vertices.length} verts!`;
        return EvolveResult.NoIntersection;
      }
    }

    // If direction after second step is zero that means that centers of the shapes are inline vertically.
    // In this case we can't calculate perpendicular vector to the line between centers.
    // But before GJK we have AABB check, so we can assume that shapes are intersecting.
    if (
      this.direction.y.eq(0) &&
      this.direction.x.eq(0) &&
      this.vertices.length === 2
    ) {
      return EvolveResult.FoundIntersection;
    }

    return this.addSupport(this.direction, shapeA, shapeB)
      ? EvolveResult.StillEvolving
      : EvolveResult.NoIntersection;
  }

  /**
   * Test whether the two shapes intersect
   @return Bool
   */
  public test(shapeA: Shape, shapeB: Shape): boolean {
    // reset everything
    this.vertices = [];
    this.direction = new Vector2();

    // do the actual test
    let result: EvolveResult = EvolveResult.StillEvolving;
    let iterations = 0;

    while (
      iterations < GJK2D.MAX_ITERATIONS &&
      result === EvolveResult.StillEvolving
    ) {
      result = this.evolveSimplex(shapeA, shapeB);
      iterations++;
    }
    return result === EvolveResult.FoundIntersection;
  }

  private findClosestEdge(winding: PolygonWinding): Edge {
    let closestDistance: number = Number.POSITIVE_INFINITY;
    let closestNormal: Vector2 = new Vector2();
    let closestIndex = 0;

    for (let i = 0; i < this.vertices.length; i++) {
      let j: number = i + 1;
      if (j >= this.vertices.length) j = 0;

      const currentPoint: Vector2 = this.vertices[i].copy();
      const nextPoint: Vector2 = this.vertices[j].copy();

      const edge: Vector2 = nextPoint.subtract(currentPoint).copy();

      let edgeVector: Vector2;
      switch (winding) {
        case PolygonWinding.Clockwise:
          edgeVector = new Vector2([edge.y, -edge.x]);
          break;
        case PolygonWinding.CounterClockwise:
          edgeVector = new Vector2([-edge.y, edge.x]);
          break;
      }

      const norm = edgeVector.normalize();

      // calculate how far away the edge is from the origin
      const dist = Vector2.dot(norm, currentPoint);

      if (dist.toNumber() < closestDistance) {
        closestDistance = dist.toNumber();
        closestNormal = norm;
        closestIndex = j;
      }
    }

    return new Edge(closestDistance, closestNormal, closestIndex);
  }

  /**
   Test whether two shapes overlap or not. If they don't, returns
   `null`. If they do, calculates the penetration vector and returns it.
   @param shapeA Shape
   @param shapeB Shape
   @return Null<IntersectResult>
   */
  public intersect(shapeA: Shape, shapeB: Shape): Vector2 | null {
    // first, calculate the base simplex
    if (!this.test(shapeA, shapeB)) {
      // if we're not intersecting, return null
      return null;
    }

    // If vertices are only 2 that means that we have corner case when shapes are inline vertically.
    // Then we can assume that first corner of the simplex is the closest to the origin and it will resole the collision.
    if (this.vertices.length === 2) {
      return this.vertices[0];
    }

    // calculate the winding of the existing simplex
    const e0 = this.vertices[1].x
      .minus(this.vertices[0].x)
      .times(this.vertices[1].y.plus(this.vertices[0].y))
      .toNumber();
    const e1 = this.vertices[2].x
      .minus(this.vertices[1].x)
      .times(this.vertices[2].y.plus(this.vertices[1].y))
      .toNumber();
    const e2 = this.vertices[0].x
      .minus(this.vertices[2].x)
      .times(this.vertices[0].y.plus(this.vertices[2].y))
      .toNumber();
    const winding: PolygonWinding =
      e0 + e1 + e2 >= 0
        ? PolygonWinding.Clockwise
        : PolygonWinding.CounterClockwise;

    let intersection: Vector2 = new Vector2();

    for (let i = 0; i < GJK2D.MAX_INTERSECTION_ITERATIONS; i++) {
      const edge: Edge = this.findClosestEdge(winding);
      const support: Vector2 = this.calculateSupport(
        edge.normal,
        shapeA,
        shapeB,
      );
      const distance = Vector2.dot(support, edge.normal);

      intersection = edge.normal.scale(distance.toNumber());

      if (
        Math.abs(distance.toNumber() - edge.distance) <=
        GJK2D.INTERSECTION_EPSILON
      ) {
        return intersection;
      } else {
        this.vertices.splice(edge.index, 0, support);
      }
    }

    return intersection;
  }
}
