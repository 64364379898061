import { useCallback, useMemo, useState } from 'react';
import {
  endOfMonth,
  setMonth,
  startOfMonth,
  addMonths,
  subMonths,
  eachWeekOfInterval,
  getWeeksInMonth,
  setDate as setDateFns,
  format as formatFns,
  eachDayOfInterval,
  endOfWeek,
  setYear,
} from 'date-fns';
import { DateLocales, currentLocale } from '../utils/dateLocales';
import * as weekdayArrays from '../utils/weekdays';

const DEFAULT_FORMAT = 'LLLL yyyy';

export enum WeekdaysFormat {
  NORMAL,
  SHORT,
  SHORTEN,
  LETTER,
}

export interface UseCurrentMonthConfig {
  format?: string;
  locale?: keyof DateLocales;
  currentMonth?: number;
  currentDate?: number;
  currentYear?: number;
  weekdaysFormat?: WeekdaysFormat;
}

export interface UseCurrentMonth {
  date: Date;
  month: number;
  formatted: string;
  start: string;
  end: string;
  weeks: Date[];
  weeksInMonth: number;
  weekdays: string[];
  daysOfWeeks: Date[][];
  onNext(): void;
  onPrev(): void;
}

const initDate = (month?: number, date?: number, year?: number) => {
  let init = new Date();
  if (typeof date !== 'undefined') {
    init = setDateFns(init, date);
  }
  if (typeof month !== 'undefined') {
    init = setMonth(init, month);
  }
  if (typeof year !== 'undefined') {
    init = setYear(init, year);
  }
  return init;
};

const useCurrentMonth = ({
  currentYear,
  currentMonth,
  currentDate,
  format = DEFAULT_FORMAT,
  weekdaysFormat = WeekdaysFormat.SHORT,
}: UseCurrentMonthConfig = {}) => {
  const [date, setDate] = useState<Date>(
    initDate(currentMonth, currentDate, currentYear),
  );

  const month = useMemo(() => date.getMonth(), [date]);

  const formatted = useMemo(
    () => formatFns(date, format, { locale: currentLocale() }),
    [date, format],
  );

  const start = useMemo(() => startOfMonth(date), [date]);

  const end = useMemo(() => endOfMonth(date), [date]);

  const weeksInMonth = useMemo(() => getWeeksInMonth(date), [date]);

  const weeks = useMemo(
    () =>
      eachWeekOfInterval(
        { start, end },
        { weekStartsOn: 1, locale: currentLocale() },
      ),
    [start, end],
  );

  const daysOfWeeks = useMemo(
    () =>
      weeks.map((start) =>
        eachDayOfInterval({
          start,
          end: endOfWeek(start, { weekStartsOn: 1 }),
        }),
      ),
    [weeks],
  );

  const weekdays = useMemo(() => {
    switch (weekdaysFormat) {
      case WeekdaysFormat.SHORTEN: {
        return weekdayArrays.shortenNames;
      }
      case WeekdaysFormat.SHORT: {
        return weekdayArrays.shortNames;
      }
      case WeekdaysFormat.LETTER: {
        return weekdayArrays.firstLetters;
      }
      case WeekdaysFormat.NORMAL:
      default: {
        return weekdayArrays.names;
      }
    }
  }, [weekdaysFormat]);

  const onNext = useCallback(() => setDate(addMonths(date, 1)), [date]);

  const onPrev = useCallback(() => setDate(subMonths(date, 1)), [date]);

  return {
    date,
    month,
    formatted,
    start,
    end,
    onNext,
    onPrev,
    weeksInMonth,
    weeks,
    weekdays,
    daysOfWeeks,
  };
};

export default useCurrentMonth;
