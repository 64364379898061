import { ProjectDimensions } from '../../../../services/api/models/project';
import { Glass } from '../../../../services/api/models/glass';
import { Position } from '../../types';
import { Space } from '../../../space';
import { RectCorners } from '../../../../types';
import { generateNewGlassId } from './generate-glass-id';

// mocking it here since for some reason global setups don't recognize it anyways
if (!globalThis.Worker) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalThis.Worker = class Worker {
    postMessage(msg: string) {
      // eslint-disable-next-line no-console
      console.log(msg);
    }

    terminate() {
      // eslint-disable-next-line no-console
      console.log('Worker terminated');
    }

    addEventListener(event: unknown) {
      // eslint-disable-next-line no-console
      console.log(`Event '${event}' added`);
    }

    removeEventListener(event: unknown) {
      // eslint-disable-next-line no-console
      console.log(`Event '${event}' removed`);
    }
  };
}

/**
 * Don't modify create-glass.worker.js directly, it's generated by create-glass.worker.ts
 */

let createGlassWorker: Worker | undefined;

const isGlass = (glass: unknown): glass is Glass => {
  return Boolean(
    glass && typeof glass === 'object' && glass.hasOwnProperty('corners'),
  );
};

const getGlassFallbackGlassDimensions = (
  plane: Space,
): { width: number; height: number } => {
  const getMaxAllowedDimension = (dimension: number): number => {
    const MIN_ALLOWED_GLASS_DIMENSION_IN_MM = 100;
    const MIN_ALLOWED_PLANE_SIZE_PERCENTAGE = 0.25;
    const MAX_ALLOWED_PLANE_SIZE_PERCENTAGE = 0.75;

    return Math.min(
      Math.max(
        dimension * MIN_ALLOWED_PLANE_SIZE_PERCENTAGE,
        MIN_ALLOWED_GLASS_DIMENSION_IN_MM,
      ),
      dimension * MAX_ALLOWED_PLANE_SIZE_PERCENTAGE,
    );
  };

  return {
    width: getMaxAllowedDimension(plane.width),
    height: getMaxAllowedDimension(plane.height),
  };
};

type CreateFallbackGlassPayload = {
  plane: Space;
  id: number;
  thickness: number;
};
const createFallbackGlass = ({
  plane,
  id,
  thickness,
}: CreateFallbackGlassPayload): Glass => {
  const {
    width: glassWidth,
    height: glassHeight,
  } = getGlassFallbackGlassDimensions(plane);
  const {
    polygonCentroid: { x: planeCentroidX, y: planeCentroidY },
  } = plane;

  const corners: RectCorners = {
    'bottom-left': [
      planeCentroidX - glassWidth / 2,
      planeCentroidY + glassHeight / 2,
    ],
    'bottom-right': [
      planeCentroidX + glassWidth / 2,
      planeCentroidY + glassHeight / 2,
    ],
    'top-left': [
      planeCentroidX - glassWidth / 2,
      planeCentroidY - glassHeight / 2,
    ],
    'top-right': [
      planeCentroidX + glassWidth / 2,
      planeCentroidY - glassHeight / 2,
    ],
  };

  return {
    id,
    corners,
    originCorners: corners,
    width: glassWidth,
    height: glassHeight,
    position: { x: 0, y: 0 },
    adjustManually: false,
    thickness,
    hardening: true,
    blank: true,
  };
};

export const createGlass = (
  projectDimensions: ProjectDimensions,
  position: Position,
  glassSheets: Glass[],
  thickness: number,
  onGlassCreationInProgress?: () => void,
  onFallbackGlassCreation?: () => void,
  onComplete?: () => void,
): Promise<{ glass?: Glass }> => {
  let inProgress = true;
  createGlassWorker = new Worker(
    `${process.env.PUBLIC_URL}/create-glass.worker.js`,
    { type: 'module' },
  );
  let promiseResolve: any;

  const DEFAULT_TIMEOUT_IN_MS = 5_000;

  setTimeout(function () {
    if (inProgress === true && createGlassWorker !== undefined) {
      createGlassWorker?.terminate();
      const fallbackGlass = handleFallbackGlassCreation();
      promiseResolve({ glass: fallbackGlass });
    }
  }, DEFAULT_TIMEOUT_IN_MS);

  onGlassCreationInProgress?.();
  const plane = new Space(projectDimensions, position);

  const handleFallbackGlassCreation = () => {
    const fallbackGlass = createFallbackGlass({
      plane,
      id: generateNewGlassId(glassSheets),
      thickness,
    });

    onFallbackGlassCreation?.();

    return fallbackGlass;
  };

  const existingGlassItems = glassSheets.map(
    ({ originCorners, position, id }) => ({
      id,
      corners: originCorners,
      position,
    }),
  );

  createGlassWorker?.postMessage({
    projectDimensions,
    position,
    glasses: existingGlassItems,
    thickness,
    hardening: true,
  });

  const fallbackGlassDimensions = getGlassFallbackGlassDimensions(plane);

  const createGlassPromise = new Promise<{ glass?: Glass }>((resolve) => {
    promiseResolve = resolve;
    if (createGlassWorker !== undefined) {
      createGlassWorker.onmessage = (event) => {
        const glassData: unknown = JSON.parse(event.data);

        if (!isGlass(glassData)) {
          const fallbackGlass = handleFallbackGlassCreation();
          resolve({ glass: fallbackGlass });
          return;
        }

        if (
          glassData.width < fallbackGlassDimensions.width ||
          glassData.height < fallbackGlassDimensions.height
        ) {
          const fallbackGlass = handleFallbackGlassCreation();
          resolve({ glass: fallbackGlass });
          return;
        }

        resolve({ glass: glassData });
      };
    }

    if (createGlassWorker !== undefined) {
      createGlassWorker.onerror = () => {
        const fallbackGlass = handleFallbackGlassCreation();
        resolve({ glass: fallbackGlass });
      };
    }
  });

  createGlassPromise.finally(() => {
    createGlassWorker?.terminate();
    inProgress = false;
    onComplete?.();
  });

  return createGlassPromise;
};
