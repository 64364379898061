import Big from 'big.js';
import Konva from 'konva';
import { findXOnLine, findYOnLine, pointsDistance, toVector } from './shape';
import { Anchor, Anchor as Edge } from '../modules/creator/types/Anchor';
import { Glass } from '../services/api/models/glass';
import { fixValue } from '../modules/creator/utils/fix';
import { Shape } from '../modules/space';
import { GlassThickness, RectCorners, RectCornersType } from '../types';
import forEach from 'lodash/forEach';

const GlassThicknessMM: Record<GlassThickness, number> = {
  '6': 6,
  '8': 8,
  '10': 10,
  '12': 12,
  '44.2': 8.76,
  '44.4': 9.52,
  '55.2': 10.76,
  '55.4': 11.52,
  '66.2': 12.76,
  '66.4': 13.52,
};

const SQUARE_METER_WEIGHT = 2.5;

export const checkIfVSG = (thickness: number, hardening: boolean) =>
  thickness % 1 > 0 && !hardening;

export const findAngle = (
  A: Konva.Vector2d,
  B: Konva.Vector2d,
  C: Konva.Vector2d,
) => {
  const AB = pointsDistance([A.x, A.y], [B.x, B.y]);
  const BC = pointsDistance([B.x, B.y], [C.x, C.y]);
  const AC = pointsDistance([A.x, A.y], [C.x, C.y]);

  const angle = Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB)) * 180;

  return Number(new Big(angle > 0 ? angle / Math.PI : 0).toFixed(1));
};

//TODO: Optimize this function
export const cutGlass = (edge: Edge, gap: number, glass: Glass): Glass => {
  const { corners } = glass;
  switch (edge) {
    case 'top': {
      const newTopLeftY = fixValue(
        new Big(corners['top-left'][1]).plus(new Big(gap)),
      );
      const newTopRightY = fixValue(
        new Big(corners['top-right'][1]).plus(new Big(gap)),
      );
      const newTopLeftX =
        findXOnLine(newTopLeftY, [
          toVector(corners['top-left']),
          toVector(corners['bottom-left']),
        ]) ?? 0;
      const newTopRightX =
        findXOnLine(newTopRightY, [
          toVector(corners['top-right']),
          toVector(corners['bottom-right']),
        ]) ?? 0;
      return {
        ...glass,
        corners: {
          ...corners,
          'top-left': [newTopLeftX, newTopLeftY],
          'top-right': [newTopRightX, newTopRightY],
        },
      };
    }
    case 'bottom': {
      const newBottomLeftY = fixValue(
        new Big(corners['bottom-left'][1]).minus(new Big(gap)),
      );
      const newBottomRightY = fixValue(
        new Big(corners['bottom-right'][1]).minus(new Big(gap)),
      );
      const newBottomLeftX =
        findXOnLine(newBottomLeftY, [
          toVector(corners['top-left']),
          toVector(corners['bottom-left']),
        ]) ?? 0;
      const newBottomRightX =
        findXOnLine(newBottomRightY, [
          toVector(corners['top-right']),
          toVector(corners['bottom-right']),
        ]) ?? 0;
      return {
        ...glass,
        corners: {
          ...corners,
          'bottom-left': [newBottomLeftX, newBottomLeftY],
          'bottom-right': [newBottomRightX, newBottomRightY],
        },
      };
    }
    case 'left': {
      const newTopLeftX = fixValue(
        new Big(corners['top-left'][0]).plus(new Big(gap)),
      );
      const newBottomLeftX = fixValue(
        new Big(corners['bottom-left'][0]).plus(new Big(gap)),
      );
      const newTopLeftY =
        findYOnLine(newTopLeftX, [
          toVector(corners['top-right']),
          toVector(corners['top-left']),
        ]) ?? 0;
      const newBottomLeftY =
        findYOnLine(newBottomLeftX, [
          toVector(corners['bottom-right']),
          toVector(corners['bottom-left']),
        ]) ?? 0;
      return {
        ...glass,
        corners: {
          ...corners,
          'top-left': [newTopLeftX, newTopLeftY],
          'bottom-left': [newBottomLeftX, newBottomLeftY],
        },
      };
    }
    case 'right': {
      const newTopRightX = fixValue(
        new Big(corners['top-right'][0]).minus(new Big(gap)),
      );
      const newBottomRightX = fixValue(
        new Big(corners['bottom-right'][0]).minus(new Big(gap)),
      );
      const newTopRightY =
        findYOnLine(newTopRightX, [
          toVector(corners['top-right']),
          toVector(corners['top-left']),
        ]) ?? 0;
      const newBottomRightY =
        findYOnLine(newBottomRightX, [
          toVector(corners['bottom-right']),
          toVector(corners['bottom-left']),
        ]) ?? 0;
      return {
        ...glass,
        corners: {
          ...corners,
          'top-right': [newTopRightX, newTopRightY],
          'bottom-right': [newBottomRightX, newBottomRightY],
        },
      };
    }
    default: {
      return glass;
    }
  }
};

export const cutGlassByValue = (
  glass: Glass,
  value: [number, number],
  min: number,
  edge: Anchor,
  fillGap = false,
): Glass => {
  let newGlass = glass;
  const gap = fixValue(new Big(min).minus(Math.max(...value)));
  if (fillGap || new Big(Math.max(...value)).lt(min)) {
    newGlass = cutGlass(edge, gap, newGlass);
  }
  return newGlass;
};

export const calculateGlassWeight = (glass: Glass): number => {
  const glassObj = new Shape({ corners: glass.corners });

  const width = new Big(glassObj.width).div(1000);
  const height = new Big(glassObj.height).div(1000);
  const thickness = new Big(
    GlassThicknessMM[glass.thickness as GlassThickness],
  );

  return width
    .times(height)
    .times(thickness)
    .times(SQUARE_METER_WEIGHT)
    .toNumber();
};

export const calculateGlassOriginCorners = (
  oldCorners: RectCorners,
  newCorners: RectCorners,
  originCorners: RectCorners,
) => {
  const originCornersCopy = JSON.parse(JSON.stringify(originCorners));

  forEach(oldCorners, (positions, corner) => {
    const cornerKey = corner as RectCornersType;

    forEach(positions, (pos, key) => {
      if (newCorners[cornerKey][key] !== pos) {
        originCornersCopy[cornerKey][key] = newCorners[cornerKey][key];
      }
    });
  });

  return originCornersCopy;
};
