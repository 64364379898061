import { configStore } from './config';
import { historyStore } from './history';
import { projectStore } from './project';
import { selectStore } from './select';
import { templateStore } from './template';

export const resetAllStates = () => {
  selectStore.getState()?.deselect();
  historyStore.getState()?.clear();
  projectStore.getState()?.clear();
  templateStore.getState()?.clear();
  configStore.getState()?.reset();
};
