import { useEffect, useState } from 'react';

export type Validators = Record<string, (pw: string) => boolean>;

export type Validated = Record<keyof Validators, boolean>;

export interface UsePasswordValid {
  results: Validated;
  valid: boolean;
  match?: boolean;
}

const usePasswordValid = (
  password: string,
  validators: Validators,
  retyped?: string,
): UsePasswordValid => {
  const [valid, setValid] = useState<UsePasswordValid>({
    valid: false,
    results: {},
  });

  useEffect(() => {
    const newResults: Validated = {};
    Object.entries(validators).forEach(([key, validator]) => {
      newResults[key] = validator(password);
    });
    setValid({
      results: newResults,
      valid: Object.values(newResults).every((result) => result),
      match:
        typeof retyped !== 'undefined' && retyped.length > 0
          ? password === retyped
          : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, retyped]);

  return valid;
};

export default usePasswordValid;
