import { useMediaQuery } from 'react-responsive';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export interface UseRWD {
  isDesktop: boolean;
  isMobile: boolean;
}

const useRWD = (): UseRWD => {
  const theme = useContext(ThemeContext);

  const isDesktop = useMediaQuery({
    minDeviceWidth: theme.breakpoints.desktop,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: theme.breakpoints.desktop - 1,
  });

  return {
    isDesktop,
    isMobile,
  };
};

export default useRWD;
