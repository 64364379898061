import * as React from 'react';

const SvgUsers = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={186}
      height={125}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x={35.926}
        width={114.99}
        height={114.99}
        rx={14}
        transform="rotate(4.644 35.926 0)"
        fill="#DBE7F4"
      />
      <g filter="url(#users_svg__filter0_d)">
        <rect x={10} y={17} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={52} y={28} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={52} y={43} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={17} y={23} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M29 36.333A2.667 2.667 0 1029 31a2.667 2.667 0 000 5.333zm0-4A1.333 1.333 0 1129 35a1.333 1.333 0 010-2.666zM34.333 37.666a2 2 0 100-3.998 2 2 0 000 3.998zm0-2.666a.666.666 0 110 1.332.666.666 0 010-1.332zM34.333 38.333c-.739.001-1.456.247-2.04.7a4.667 4.667 0 00-7.96 3.3.666.666 0 101.333 0 3.333 3.333 0 016.667 0 .666.666 0 101.333 0 4.6 4.6 0 00-.573-2.233 2 2 0 013.24 1.566.666.666 0 101.333 0 3.333 3.333 0 00-3.333-3.333z"
        fill="#fff"
      />
      <g filter="url(#users_svg__filter1_d)">
        <rect x={25} y={65} width={142} height={40} rx={4} fill="#fff" />
      </g>
      <rect x={67} y={76} width={88} height={4} rx={2} fill="#E3EDFF" />
      <rect x={67} y={91} width={43} height={4} rx={2} fill="#E3EDFF" />
      <rect x={32} y={71} width={28} height={28} rx={4} fill="#326DE3" />
      <path
        d="M44 84.333A2.667 2.667 0 1044 79a2.667 2.667 0 000 5.333zm0-4A1.333 1.333 0 1144 83a1.333 1.333 0 010-2.666zM49.333 85.666a2 2 0 100-3.998 2 2 0 000 3.998zm0-2.666a.666.666 0 110 1.332.666.666 0 010-1.332zM49.333 86.333c-.739.001-1.456.247-2.04.7a4.667 4.667 0 00-7.96 3.3.666.666 0 101.333 0 3.333 3.333 0 016.667 0 .666.666 0 101.333 0 4.6 4.6 0 00-.573-2.233 2 2 0 013.24 1.567.666.666 0 101.333 0 3.333 3.333 0 00-3.333-3.334z"
        fill="#fff"
      />
      <defs>
        <filter
          id="users_svg__filter0_d"
          x={0}
          y={17}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="users_svg__filter1_d"
          x={15}
          y={65}
          width={162}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const MemoSvgUsers = React.memo(SvgUsers);
export default MemoSvgUsers;
