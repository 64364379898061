import * as React from 'react';
import { Container, Item } from './styles';
import {
  Center,
  Left,
  Low,
  Middle,
  Right,
  Up,
} from '../../../../../../components/icons';
import { AlignToTypes } from './types';

interface AlignToItem {
  name: string;
  type: AlignToTypes;
  Icon: React.ReactNode;
}

const items: AlignToItem[] = [
  { name: 'align-left', type: AlignToTypes.LEFT, Icon: <Left /> },
  { name: 'align-center', type: AlignToTypes.CENTER, Icon: <Center /> },
  { name: 'align-right', type: AlignToTypes.RIGHT, Icon: <Right /> },
  { name: 'align-up', type: AlignToTypes.UP, Icon: <Up /> },
  { name: 'align-middle', type: AlignToTypes.MIDDLE, Icon: <Middle /> },
  { name: 'align-low', type: AlignToTypes.LOW, Icon: <Low /> },
];

export interface Props {
  onSelect(alignTo: AlignToTypes): void;
  disabled?: boolean;
}

const AlignTo: React.FC<Props> = React.memo(({ onSelect, ...props }) => {
  return (
    <Container {...props}>
      {items.map((item) => (
        <Item
          key={item.name}
          role="button"
          aria-label={item.name}
          onClick={() => onSelect(item.type)}>
          {item.Icon}
        </Item>
      ))}
    </Container>
  );
});

AlignTo.displayName = 'AlignTo';

export default AlignTo;
