import axiosStatic, { AxiosInstance } from 'axios';
import { stringify } from 'qs';

import { instance_v2 } from '../config';
import { Endpoints } from '../endpoints';
import {
  Order,
  BasicOrder,
  OrderForm,
  TransmitOrderCommand,
  OrderFromOfferCommand,
} from '../models/order';
import { Page, Pageable } from '../models/requests';
import { ProductQuantityCommand } from '../models/product';

const CancelToken = axiosStatic.CancelToken;

export interface FilterCommand {
  orderDateFrom?: string;
  orderDateTo?: string;
  status?: string;
  text?: string;
}

export type FilterRequest = FilterCommand & Partial<Pageable>;

export type GlassFormsRequest = {
  sorting: Pick<Pageable, 'sort'>;
};

export type OrderProductsRequest = {
  command: ProductQuantityCommand[];
  sorting: Pick<Pageable, 'sort'>;
};

export type OrderEditRequest = Pick<
  Order,
  'description' | 'descriptionForGlassTempering' | 'productsBelowProjectSpec'
> & { clientId: number | null };

class OrderApi {
  constructor(private readonly http: AxiosInstance) {}

  public createSource = CancelToken.source();

  public getOrders = async (command: FilterRequest) => {
    return await this.http.get<Page<BasicOrder>>(
      `${Endpoints.ORDERS}?${stringify(command)}`,
    );
  };

  public editOrder = async (id: number, command: OrderEditRequest) => {
    return await this.http.put<Order>(`${Endpoints.ORDERS}/${id}`, command);
  };

  public filterGlassForms = async (
    orderId: number,
    { sorting }: GlassFormsRequest,
  ) => {
    return await this.http.get<OrderForm[]>(
      `${Endpoints.ORDERS}/forms/${orderId}?${stringify(sorting)}`,
    );
  };

  public transmitOrder = (id: number, command: TransmitOrderCommand) =>
    this.http.put<Order>(`${Endpoints.ORDERS}/transmit/${id}`, command);

  public recalculateOrder = (
    orderId: number,
    { command, sorting }: OrderProductsRequest,
  ) =>
    this.http.post<Order>(
      `${Endpoints.ORDERS}/calculate/${orderId}?${stringify(sorting)}`,
      { commands: command },
    );

  public createOrderFromOffer = (
    offerId: number,
    command: OrderFromOfferCommand,
  ) =>
    this.http.post<Order>(`${Endpoints.ORDERS}/offer/${offerId}`, command, {
      cancelToken: this.createSource.token,
    });

  public createOrderFromProject = (projectId: number) =>
    this.http.post<Order>(`${Endpoints.ORDERS}/${projectId}`, undefined, {
      cancelToken: this.createSource.token,
    });

  public getOrder = async (id: number) => {
    return await this.http.get<Order>(`${Endpoints.ORDERS}/${id}`);
  };

  public filterClientOrders = (clientId: number, command: FilterRequest) => {
    return this.http.get<Page<BasicOrder>>(
      `${Endpoints.ORDERS}/client/${clientId}?${stringify(command)}`,
    );
  };
}
// TODO: Temporary instance caused by big BE changes, needs to be removed
export const orderApi = new OrderApi(instance_v2);
export default orderApi;
