import * as React from 'react';
import { Trigger as Container } from './styles';
import { LeftBg, RightBg } from '../icons';
import { SidePanelPosition } from './types';
import { useMemo } from 'react';

export interface Props {
  onClick(): void;
  open: boolean;
  panelPosition: SidePanelPosition;
}

const Trigger: React.FC<Props> = React.memo(
  ({ onClick, open, panelPosition }) => {
    const Icon = useMemo(() => {
      if (panelPosition === SidePanelPosition.RIGHT) {
        return open ? <RightBg /> : <LeftBg />;
      }
      return open ? <LeftBg /> : <RightBg />;
    }, [panelPosition, open]);

    return (
      <Container
        role="button"
        aria-label="toggle-side-panel"
        position={panelPosition}
        onClick={onClick}>
        {Icon}
      </Container>
    );
  },
);

Trigger.displayName = 'Trigger';

export default Trigger;
