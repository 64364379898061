import * as React from 'react';
import { Container, Header, Properties, Property } from './styles';
import {
  Product,
  ProductSearch,
} from '../../../../../../services/api/models/product';
import {
  Link,
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { DownSm, UpSm } from '../../../../../../components/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PanelHeader } from '../PanelHeader';

export interface SearchProduct {
  type: 'search';
  product: ProductSearch;
}

export interface CreatorProduct {
  type: 'creator';
  product: Product;
}

export interface Props {
  data: SearchProduct | CreatorProduct;
  realWidth: number;
  realHeight: number;
  onToggle(id: number): void;
  unfold?: boolean;
  multiselect?: boolean;
}

interface DataMap {
  code: string;
  series: string;
  category: string;
  type: string;
  material: string;
  finish: string;
  width: number;
  height: number;
  gap: number;
}

const hasUnit = (key: keyof DataMap) =>
  key === 'width' || key === 'height' || key === 'gap';

const ProductInfo: React.FC<Props> = React.memo(
  ({
    data,
    onToggle,
    multiselect,
    unfold = !multiselect,
    realWidth,
    realHeight,
    ...props
  }) => {
    const { t } = useTranslation('catalog');

    const dataMap = useMemo<DataMap>(
      () => ({
        code: data.product.code,
        series: data.product.series,
        category: data.product.category,
        type: data.product.type,
        material: data.product.material,
        finish: data.product.materialType,
        width: realWidth,
        height: realHeight,
        gap:
          data.type === 'search'
            ? data.product.attributes.gapFromGlassEdge
            : data.product.productAttributes.minGapFromGlassEdge,
      }),
      [data, realHeight, realWidth],
    );

    const specHref = useMemo(() => {
      if (data.type === 'search') {
        return data.product.productSpecificationUrl;
      }
      if (data.type === 'creator') {
        return data.product.attachments.find(
          (attachment) => attachment.type === 'SPECIFICATION',
        )?.url;
      }
    }, [data]);

    return (
      <>
        <PanelHeader
          title={dataMap.code}
          actionTitle={t('propertyPanel.actionTitle')}
        />
        <Container {...props}>
          <Header
            role="button"
            active={unfold}
            onClick={() => onToggle(data.product.id)}>
            <Typography variant={TypographyVariants.H5_BOLD}>
              {t('details.information', {
                productCode: multiselect ? data.product.code : undefined,
              })}
            </Typography>
            {unfold ? <UpSm /> : <DownSm />}
          </Header>
          {unfold && (
            <Properties aria-label="product-properties">
              {Object.entries(dataMap).map(([key, value]) => (
                <Property key={key} aria-label="product-property">
                  <Typography variant={TypographyVariants.H5}>
                    {t(`details.basicData.${key}`)}:
                  </Typography>
                  <Typography variant={TypographyVariants.H5_BOLD}>
                    {hasUnit(key as keyof DataMap)
                      ? `${value?.toFixed(2)} mm`
                      : value}
                  </Typography>
                </Property>
              ))}
              {specHref && (
                <Link href={specHref}>
                  {t('details.download.specification')}
                </Link>
              )}
            </Properties>
          )}
        </Container>
      </>
    );
  },
);

ProductInfo.displayName = 'ProductInfo';

export default ProductInfo;
