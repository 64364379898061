import { useCallback, useMemo } from 'react';
import { Connection } from '../../../../services/api/models/connection';
import { Glass } from '../../../../services/api/models/glass';
import {
  BaseType,
  ProductModel,
  ProductSearch,
} from '../../../../services/api/models/product';
import { RectCorners } from '../../../../types';
import { getCoordinatesForRect } from '../../../../utils/shape';
import { DEFAULT_RATIO } from '../../store/config';
import { Boundaries, Position, Shape } from '../../types';
import {
  correctBoundariesCopy,
  cutBoundariesToAnchor,
  DataCopy as FindBoundariesConfig,
  findBoundaries,
  validatePosInBounds,
} from '../../utils/boundaries';

interface Props {
  elementRef: any;
  data: Shape;
  glassParent?: Glass;
  glassParents: (Glass | undefined)[];
  usedProduct?: ProductSearch;
  isGlassGlass180: boolean;
  parentConnection: Connection;
  otherProducts: ProductModel[];
  layerPosition: Position;
  scale: number;
  minGapToEdge: Partial<Boundaries> | undefined;
}

const useProductBounds = ({
  elementRef,
  data,
  glassParent,
  glassParents,
  usedProduct,
  isGlassGlass180,
  parentConnection,
  otherProducts,
  layerPosition,
  scale,
  minGapToEdge,
}: Props) => {
  const sameParentProducts = useMemo(() => {
    const parentMap = new Map();
    glassParents.forEach((parent) => {
      if (parent) {
        parentMap.set(String(parent.id), parent);
      }
    });
    return otherProducts
      .filter((item) =>
        item.connections.some((connect) => parentMap.has(connect.targetId)),
      )
      .map((product) => ({
        ...product,
        corners: {
          'top-left': [0, 0],
          'top-right': [product.width, 0],
          'bottom-left': [0, product.height],
          'bottom-right': [product.width, product.height],
        } as RectCorners,
      }));
  }, [glassParents, otherProducts]);

  const glassProperties = useMemo(
    () =>
      glassParent &&
      usedProduct &&
      usedProduct.glassProperties.find(
        (item) => item.thickness === glassParent.thickness,
      ),
    [glassParent, usedProduct],
  );

  const getBoundaries = useCallback(() => {
    if (glassParent) {
      const config: FindBoundariesConfig = {
        shape: {
          position: data.position,
          corners: getCoordinatesForRect(data.width, data.height),
          cutout: data.cutout,
        },
        shapes: sameParentProducts,
        parents: glassParents
          .filter((item) => item !== undefined)
          .map((parent) => {
            const Parent = parent as Glass;
            return {
              corners: Parent.corners,
              position: Parent.position,
            };
          }),
        minGapToEdge: minGapToEdge,
        parentPaddings: glassProperties && glassProperties.dimensionLinePadding,
        scale: DEFAULT_RATIO * scale,
      };

      const boundariesPaddingAnchor =
        usedProduct?.baseType === BaseType.GASKET ||
        usedProduct?.baseType === BaseType.BAR
          ? parentConnection.anchor
          : undefined;

      const boundaries = correctBoundariesCopy(
        findBoundaries(config, boundariesPaddingAnchor),
        layerPosition,
      );

      return cutBoundariesToAnchor(
        boundaries,
        parentConnection.anchor,
        {
          width: data.width,
          height: data.height,
          position: data.position,
        },
        isGlassGlass180,
        glassProperties?.glassToGlassGap,
        scale,
      );
    }
  }, [
    data.position,
    data.cutout,
    glassParent,
    usedProduct,
    parentConnection.anchor,
    sameParentProducts,
    glassParents,
    glassProperties,
    scale,
    layerPosition,
    isGlassGlass180,
    minGapToEdge,
  ]);

  const dragBoundFunc = useCallback(
    (pos: Position) => {
      const initPosition = elementRef.current.getAbsolutePosition();
      const boundaries = getBoundaries();

      if (!data.corners) {
        return initPosition;
      }

      return validatePosInBounds(
        pos,
        boundaries,
        data.corners,
        initPosition,
        scale,
      );
    },
    [data, scale, getBoundaries],
  );

  return {
    getBoundaries,
    dragBoundFunc,
  };
};

export default useProductBounds;
