import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled.div``;

export const Header = styled(layout.Row)`
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  > svg {
    width: 20px;
    height: 20px;
  }
`;

export const ItemsWrapper = styled.div`
  margin-top: 20px;
  > div {
    margin: 8px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
