import * as React from 'react';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { SizeLabel } from '../SizeLabel';
import { Group } from 'react-konva';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { IndentEdgeType } from '../../../../types';
import {
  getAnchorPointsCopy,
  scaleAnchorPoints,
} from '../../utils/transformer';
import { ANCHOR_POINTS } from '../../../../services/api/models/transformer';

export type DimensionsPosition = 'top' | 'right' | 'bottom' | 'left';

export interface Props {
  groupPosition: Konva.Vector2d;
  dimensions: ProjectDimensions;
  edges: Record<IndentEdgeType, number>;
  visible?: boolean;
  show?: DimensionsPosition[];
  onParentTransform?(ref: RefObject<Konva.Label>): void;
}

interface LabelDim {
  width: number;
  height: number;
}

export const SPACING = 32;

const GroupDimensions: React.FC<
  Props & { ref?: RefObject<Konva.Group> }
> = React.memo(
  React.forwardRef<Konva.Group, Props>(
    (
      { groupPosition, visible, dimensions, edges, show = ['top', 'left'] },
      ref,
    ) => {
      const labelRef = useRef<Konva.Label>(null);
      const [labelDim, setLabelDim] = useState<LabelDim>({
        width: 0,
        height: 0,
      });

      const getPoint = (point: ANCHOR_POINTS) => {
        const anchorPoint = scaleAnchorPoints(getAnchorPointsCopy(dimensions));
        const found = anchorPoint.find((item) => item.id === point);

        return found ? found.pos : { x: 0, y: 0 };
      };

      const setVisibility = useCallback(
        (position: DimensionsPosition) => !!visible && show.includes(position),
        [show, visible],
      );

      useEffect(() => {
        labelRef.current &&
          setLabelDim({
            width: labelRef.current.getWidth(),
            height: labelRef.current.getHeight(),
          });
      }, []);

      return (
        <Group ref={ref} x={groupPosition.x} y={groupPosition.y}>
          <SizeLabel
            ref={labelRef}
            value={edges.top.toFixed(2)}
            x={getPoint(ANCHOR_POINTS.TOP).x - labelDim.width / 2}
            y={getPoint(ANCHOR_POINTS.TOP).y - SPACING}
            visible={setVisibility('top')}
          />
          <SizeLabel
            value={edges.left.toFixed(2)}
            x={getPoint(ANCHOR_POINTS.LEFT).x - labelDim.width - 10}
            y={getPoint(ANCHOR_POINTS.LEFT).y - labelDim.height / 2}
            visible={setVisibility('left')}
          />
          <SizeLabel
            value={edges.bottom.toFixed(2)}
            x={getPoint(ANCHOR_POINTS.BOTTOM).x - labelDim.width / 2}
            y={getPoint(ANCHOR_POINTS.BOTTOM).y - labelDim.height + SPACING}
            visible={setVisibility('bottom')}
          />
          <SizeLabel
            value={edges.right.toFixed(2)}
            x={getPoint(ANCHOR_POINTS.RIGHT).x - labelDim.height + SPACING}
            y={getPoint(ANCHOR_POINTS.RIGHT).y - labelDim.height / 2}
            visible={setVisibility('right')}
          />
          {dimensions.indent && dimensions.corners['center-right'] && (
            <SizeLabel
              value={edges['center-horizontal'].toFixed(2)}
              x={
                getPoint(ANCHOR_POINTS.CENTER_CENTER_RIGHT).x -
                labelDim.width / 2
              }
              y={getPoint(ANCHOR_POINTS.CENTER_CENTER_RIGHT).y - SPACING}
              visible={setVisibility('right')}
            />
          )}
          {dimensions.indent && dimensions.indent.includes('TOP') && (
            <SizeLabel
              value={edges['center-vertical'].toFixed(2)}
              x={
                getPoint(ANCHOR_POINTS.CENTER_CENTER_TOP).x -
                labelDim.height +
                SPACING
              }
              y={
                getPoint(ANCHOR_POINTS.CENTER_CENTER_TOP).y -
                labelDim.height / 2
              }
              visible={setVisibility('top')}
            />
          )}
          {dimensions.indent && dimensions.corners['center-left'] && (
            <SizeLabel
              value={edges['center-horizontal'].toFixed(2)}
              x={
                getPoint(ANCHOR_POINTS.CENTER_CENTER_LEFT).x -
                labelDim.width / 2
              }
              y={getPoint(ANCHOR_POINTS.CENTER_CENTER_LEFT).y - SPACING}
              visible={setVisibility('left')}
            />
          )}
          {dimensions.indent && dimensions.indent.includes('BOTTOM') && (
            <SizeLabel
              value={edges['center-vertical'].toFixed(2)}
              x={
                getPoint(ANCHOR_POINTS.CENTER_CENTER_BOTTOM).x -
                labelDim.height +
                SPACING
              }
              y={
                getPoint(ANCHOR_POINTS.CENTER_CENTER_BOTTOM).y -
                labelDim.height / 2
              }
              visible={setVisibility('bottom')}
            />
          )}
        </Group>
      );
    },
  ),
);

GroupDimensions.displayName = 'GroupDimensions';

export default GroupDimensions;
