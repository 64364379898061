import * as React from 'react';
import {
  PointerSwitchContainer as Container,
  PointerSwitchOption as Option,
} from './styles';
import { Move, Pointer } from '../icons';
import { Pointer as PointerOptions } from '../../types';
import { Tooltip } from '../Tooltip';
import { useTranslation } from 'react-i18next';

export interface Props {
  onChange(option: PointerOptions): void;
  active?: PointerOptions;
}

const PointerSwitch: React.FC<Props> = React.memo(({ active, onChange }) => {
  const { t } = useTranslation('components');

  return (
    <Container aria-label="pointer-switch" role="listbox">
      <Option
        role="option"
        active={active === PointerOptions.DEFAULT}
        onClick={() => onChange(PointerOptions.DEFAULT)}>
        <Tooltip text={t('pointerSwitch.pointer')} delay={1000}>
          <Pointer />
        </Tooltip>
      </Option>
      <Option
        role="option"
        active={active === PointerOptions.MOVE}
        onClick={() => onChange(PointerOptions.MOVE)}>
        <Tooltip text={t('pointerSwitch.move')} delay={1000}>
          <Move />
        </Tooltip>
      </Option>
    </Container>
  );
});

PointerSwitch.displayName = 'PointerSwitch';

export default PointerSwitch;
