import { State as FilterState } from './filter';
import { State } from './order';

type CommonState = State | FilterState;

export const selectLoading = (state: CommonState) => state.loading;

export const selectError = (state: CommonState) => state.error;

export const selectOrders = (state: FilterState) => state.data;

export const selectFilter = (state: FilterState) => state.filter;

export const selectFilterCompanyOrders = (state: FilterState) =>
  state.filterCampanyOrders;

export const selectFilterClientOrders = (state: FilterState) =>
  state.filterClientOrders;

export const selectOrder = (state: State) => state.data;

export const selectFetch = (state: State) => state.fetch;

export const selectRecalculate = (state: State) => state.recalculate;

export const selectTransmit = (state: State) => state.transmit;

export const selectCancel = (state: State) => state.cancel;

export const selectCreate = (state: State) => state.create;

export const selectEdit = (state: State) => state.edit;

export const selectCreateFromProject = (state: State) =>
  state.createFromProject;
