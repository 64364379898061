import { State } from './products';

export const selectProducts = (state: State) => state.products;

export const selectProduct = (id: string) => (state: State) =>
  state.products.find((product) => product.id === id);

export const selectAddProduct = (state: State) => state.addProduct;

export const selectUpdateProduct = (state: State) => state.updateProduct;

export const selectDeleteProduct = (state: State) => state.deleteProduct;

export const selectDeleteManyProducts = (state: State) =>
  state.deleteManyProducts;

export const selectDistanceType = (state: State) => state.distanceType;

export const selectSetDistanceType = (state: State) => state.setDistanceType;

export const selectUpdateDistance = (state: State) => state.updateDistance;
