import { pl, ru, it, enGB } from 'date-fns/locale';
import { i18next } from '../services/i18n';

type LocaleKeyType = keyof DateLocales;

const DEFAULT: LocaleKeyType = 'pl';

const locales = { pl, ru, it, enGB };
const locale = () =>
  (i18next.language as LocaleKeyType) ??
  (process.env.REACT_APP_LOCALE as LocaleKeyType) ??
  DEFAULT;

export const currentLocale = () => {
  const current = locale();

  return locales[current];
};

export type DateLocales = typeof locales;
export default locales;
