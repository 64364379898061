import create, { GetState, SetState } from 'zustand';
import createVanilla from 'zustand/vanilla';
import jwtDecode from 'jwt-decode';
import { devtools, persist } from 'zustand/middleware';
import { authApi } from '../../services/api/services';
import {
  LoginCommand,
  LoginErrorResponse,
} from '../../services/api/models/auth';
import { AxiosResponse } from 'axios';
import { LocalStorage } from '../../services/storage';
import { filterProjectsStore, recentProjectsStore } from '../projects';
import { resetAllStates as resetCreator } from '../../modules/creator';
import { User } from '../../services/api/models/user';
import {
  CaslResponse,
  CaslResponse as Permissions,
} from '../../services/api/models/permissions';
import { Ability, AbilityBuilder } from '@casl/ability';
import { AppAbilityType } from '../../services/casl';
import { ability } from '../../services/casl';

let onRehydrated: () => void;
export const sessionRehydration = new Promise((res) => {
  onRehydrated = res;
});

const clearSession = () => {
  LocalStorage.clear();
  resetCreator();
  filterProjectsStore.getState()?.clear();
  recentProjectsStore.getState()?.clear();
};

export const STORE_NAME = `@store/session`;

export type State = {
  loading: boolean;
  hasSession: boolean;
  login(command: LoginCommand): Promise<void>;
  logout(): Promise<void>;
  forceLogout(): Promise<void>;
  fetchPermissions(): Promise<void>;
  restorePermissions(): Promise<void>;
  user?: User;
  permissions?: Permissions;
  accessToken?: string;
  refreshToken?: string;
  manualLogout?: boolean;
  forcedLogout?: boolean;
  shouldResetPassword?: boolean;
  error?: AxiosResponse<LoginErrorResponse>;
};

const updateAbilities = (permissions: CaslResponse) => {
  const { can, rules } = new AbilityBuilder<AppAbilityType>(Ability);

  permissions.forEach((permission) =>
    can(permission.actions, permission.subject),
  );
  ability.update(rules);
};

const fetchPermissionsAction = (set: SetState<State>) => async () => {
  try {
    const { data } = await authApi.permissions();
    updateAbilities(data);
    set({ permissions: data });
  } catch (e) {
    set({ error: e.response });
  }
};

const restorePermissionsAction = (
  set: SetState<State>,
  get: GetState<State>,
) => async () => {
  const { permissions, fetchPermissions } = get();
  if (permissions) {
    updateAbilities(permissions);
  } else {
    await fetchPermissions();
  }
};

const loginAction = (set: SetState<State>, get: GetState<State>) => async (
  command: LoginCommand,
) => {
  try {
    set({ loading: true });
    const { data } = await authApi.login(command);
    set({
      ...data,
      hasSession: true,
      error: undefined,
    });
    if (!data.shouldResetPassword) {
      await get().fetchPermissions();
    }
    const { data: user } = await authApi.getUser();
    set({
      user,
    });
  } catch (e) {
    set({ error: e.response });
  } finally {
    set({ loading: false });
  }
};

export const resetSessionStore: Partial<State> = {
  hasSession: false,
  accessToken: undefined,
  refreshToken: undefined,
  shouldResetPassword: undefined,
  user: undefined,
  permissions: undefined,
};

const logoutAction = (set: SetState<State>) => async () => {
  try {
    set({ loading: true });
    await authApi.logout();
    set({
      ...resetSessionStore,
      manualLogout: true,
    });
    clearSession();
  } catch (e) {
    set({ error: e.response });
  } finally {
    set({ loading: false });
  }
};

const forceLogoutAction = (set: SetState<State>) => async () => {
  set({
    ...resetSessionStore,
    forcedLogout: true,
  });
  clearSession();
};

const store = (set: SetState<State>, get: GetState<State>) => ({
  loading: false,
  hasSession: false,
  login: loginAction(set, get),
  logout: logoutAction(set),
  forceLogout: forceLogoutAction(set),
  fetchPermissions: fetchPermissionsAction(set),
  restorePermissions: restorePermissionsAction(set, get),
});

export const areTokensValid = (state: Partial<State>) => {
  try {
    if (
      typeof state.accessToken !== 'string' ||
      typeof state.refreshToken !== 'string'
    ) {
      return false;
    }

    const currentSeconds = new Date().getTime() / 1000;

    const accessToken = jwtDecode(state.accessToken) as { exp: number };

    if (accessToken.exp && currentSeconds < accessToken.exp) {
      return true;
    }

    const refreshToken = jwtDecode(state.refreshToken) as { exp: number };

    return refreshToken.exp && currentSeconds < refreshToken.exp;
  } catch (e) {
    return false;
  }
};

const onRehydrateStorage = () => {
  return (state: State) => {
    if (!areTokensValid(state)) {
      state.forceLogout().then(() => {
        onRehydrated();
      });
    } else if (state.hasSession && !state.shouldResetPassword) {
      state.restorePermissions().then(() => {
        onRehydrated();
      });
    } else {
      onRehydrated();
    }
  };
};

const persistedStore = persist(store, {
  name: STORE_NAME,
  blacklist: ['error', 'manualLogout', 'forcedLogout'],
  onRehydrateStorage,
});

export const vanillaStore = createVanilla<State>(
  devtools(persistedStore, STORE_NAME),
);

export const useStore = create<State>(vanillaStore);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.zustandSessionStore = vanillaStore;
