import * as React from 'react';
import { Container, Trigger } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import { DownSm, Profile, SignOut } from '../../../../components/icons';
import List from './List';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompanyMemberInfo } from '../../../../hooks';

export interface Props {
  username?: string;
  onLogout(): void;
  goToProfile(): void;
}

const UserMenu: React.FC<Props> = React.memo(
  ({ username, onLogout, goToProfile }) => {
    const { t } = useTranslation('navigation');
    const [unfolded, setUnfolded] = useState(false);

    const { userName: companyMemberUserName } = useCompanyMemberInfo();

    const userName = useMemo<string>(() => {
      return username ?? companyMemberUserName;
    }, [companyMemberUserName, username]);

    const items = useMemo(
      () => [
        {
          title: t('navigation:userMenu.profile'),
          Icon: <Profile />,
          onClick: goToProfile,
        },
        {
          title: t('navigation:userMenu.logout'),
          Icon: <SignOut />,
          onClick: onLogout,
        },
      ],
      [goToProfile, onLogout, t],
    );

    const onUnfold = useCallback(() => {
      !unfolded && setUnfolded(true);
    }, [unfolded]);

    const onClose = useCallback(() => {
      setUnfolded(false);
    }, []);

    return (
      <Container data-testid="user-menu">
        <Trigger role="button" onClick={onUnfold}>
          <Typography variant={TypographyVariants.H4_BOLD} oneLine>
            {userName}
          </Typography>
          <DownSm />
        </Trigger>
        {unfolded && (
          <List items={items} onClose={onClose} username={userName} />
        )}
      </Container>
    );
  },
);

UserMenu.displayName = 'UserMenu';

export default UserMenu;
