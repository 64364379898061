import { State } from './notifications';

export const selectText = (state: State) => state.text;

export const selectVariant = (state: State) => state.variant;

export const selectDismiss = (state: State) => state.dismiss;

export const selectShow = (state: State) => state.show;

export const selectTimeout = (state: State) => state.timeout;

export const selectSetNotification = (state: State) => state.setNotification;
