import styled, { css } from 'styled-components';
import { layout } from '../../../../styles';

export const Container = styled.div`
  position: relative;
`;

const disabled_trigger = css`
  pointer-events: none;
`;

export const Trigger = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && disabled_trigger};
  display: flex;
  width: 55px;
  height: 55px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey4};
  }
`;

const List = styled(layout.Column)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.dark};
  width: fit-content;
  min-width: 200px;
  border-radius: 4px;
`;

export const MainList = styled(List)`
  top: 8px;
  left: 8px;
`;

export const SubList = styled(List)`
  top: 0;
  right: calc(-100% - 4px);
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  margin: 8px 8px 0;
  > svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Item = styled(layout.Row)`
  align-items: center;
  height: 48px;
  padding: 0 12px 0 16px;
  user-select: none;
`;

const ItemsWrapper = styled(layout.Column)`
  padding: 12px 0;
`;

export const MainItemsWrapper = styled(ItemsWrapper)`
  border-bottom: 1px solid rgba(130, 138, 152, 0.2);
`;

export const MinorItemsWrapper = styled(ItemsWrapper)``;

const main_item_style = css`
  cursor: default;
  background-color: rgba(255, 255, 255, 0);
  &:hover {
    background-color: rgba(255, 255, 255, 0.03);
  }
`;

const disabled_item = css`
  pointer-events: none;
  > span {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

export const MainItem = styled(Item)<{
  secondary?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.03);
  cursor: pointer;
  ${({ secondary }) => !secondary && main_item_style};
  > span {
    color: ${({ theme }) => theme.colors.white};
  }
  > svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
  &:hover {
    > span {
      font-weight: 700;
    }
    > svg {
      path {
        fill: ${({ theme }) => theme.colors.accentBlue};
      }
    }
  }
  ${({ disabled }) => disabled && disabled_item};
`;

const active_item = css`
  > svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  > span {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
  }
`;

export const MinorItem = styled(Item)<{ active?: boolean }>`
  cursor: pointer;
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
  > span {
    color: ${({ theme }) => theme.colors.grey};
  }
  ${({ active }) => active && active_item};
  &:hover {
    > svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
    > span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const ItemLabel = styled.div`
  display: inline-block;
  padding: 3px 4px;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.dark};
  > span {
    color: ${({ theme }) => theme.colors.grey2};
  }
`;
