import * as React from 'react';

const SvgLshape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={109}
      height={109}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g opacity={0.2}>
        <mask
          id="lshape_svg__a"
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={109}
          height={109}>
          <path fill="#828A98" stroke="#828A98" d="M.5.5h108v108H.5z" />
        </mask>
        <g mask="url(#lshape_svg__a)">
          <path
            stroke="#828A98"
            d="M22.398-44.963l-170.235 170.236M32.196-44.963L-138.04 125.273M41.994-44.963l-170.236 170.236M51.792-44.963l-170.236 170.236M61.59-44.963l-170.236 170.236M71.387-44.963L-98.849 125.273M81.185-44.963L-89.051 125.273M90.983-44.963L-79.253 125.273M100.781-44.963L-69.455 125.273M110.578-44.963L-59.658 125.273M120.376-44.963L-49.86 125.273M130.174-44.963L-40.062 125.273M139.971-44.963L-30.265 125.273M149.769-44.963L-20.467 125.273M159.567-44.963L-10.669 125.273M169.365-44.963L-.871 125.273M179.162-44.963L8.926 125.273M188.96-44.963L18.724 125.273M198.758-44.963L28.522 125.273M208.556-44.963L38.32 125.273M218.354-44.963L48.118 125.273M228.151-44.963L57.916 125.273M237.949-44.963L67.713 125.273M247.747-44.963L77.511 125.273M257.544-44.963L87.308 125.273M267.342-44.963L97.106 125.273"
          />
          <path fill="#fff" d="M64-2h50v50H64z" />
        </g>
      </g>
      <path d="M8 8h49.22v46H100v46H8V8z" fill="#D3D7E0" />
    </svg>
  );
};

const MemoSvgLshape = React.memo(SvgLshape);
export default MemoSvgLshape;
