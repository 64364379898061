import React, { useMemo } from 'react';
import { DEFAULT_RATIO } from '../../store/config';
import { BoundariesCopy as Edges } from '../../types';
import { GapType } from '../../types/Gap';
import { GapArrow } from '../GapArrow';
import { ProjectDimensions } from '../../../../services/api/models/project';
import { Shape } from '../../../space';
import position from '../../../../components/Position/Position';
import Konva from 'konva';
import { fixValue } from '../../utils/fix';

export interface GlassGapProps {
  gap: GapType['value'];
  gapPosition: keyof Edges;
  dimensions: ProjectDimensions;
  position: Konva.Vector2d;
}

const GlassGap = ({
  gap,
  gapPosition,
  dimensions,
  position,
}: GlassGapProps) => {
  const arrowPosition = useMemo(() => {
    const glass = new Shape({ corners: dimensions.corners }, position);

    return {
      bottom: {
        x:
          (glass.bottomEdge.points.left.x + glass.bottomEdge.points.right.x) /
          2,
        y:
          (glass.bottomEdge.points.left.y + glass.bottomEdge.points.right.y) /
          2,
      },
      top: {
        x: (glass.topEdge.points.left.x + glass.topEdge.points.right.x) / 2,
        y: (glass.topEdge.points.left.y + glass.topEdge.points.right.y) / 2,
      },
      left: {
        x: (glass.leftEdge.points.top.x + glass.leftEdge.points.bottom.x) / 2,
        y: (glass.leftEdge.points.top.y + glass.leftEdge.points.bottom.y) / 2,
      },
      right: {
        x: (glass.rightEdge.points.top.x + glass.rightEdge.points.bottom.x) / 2,
        y: (glass.rightEdge.points.top.y + glass.rightEdge.points.bottom.y) / 2,
      },
    };
  }, [dimensions.corners, position]);

  const gapAverage = useMemo(() => fixValue((gap[0] + gap[1]) / 2), [gap]);

  if (!gap) {
    return null;
  }

  switch (gapPosition) {
    case 'bottom':
      return (
        <GapArrow
          position={{
            x: arrowPosition.bottom.x,
            y: arrowPosition.bottom.y + gapAverage * DEFAULT_RATIO,
          }}
          gap={gapAverage}
        />
      );
    case 'top':
      return (
        <GapArrow
          position={{
            x: arrowPosition.top.x,
            y: arrowPosition.top.y,
          }}
          gap={gapAverage}
        />
      );
    case 'left':
      return (
        <GapArrow
          position={{
            x: arrowPosition.left.x,
            y: arrowPosition.left.y,
          }}
          gap={gapAverage}
          xAxis
        />
      );
    case 'right':
      return (
        <GapArrow
          position={{
            x: arrowPosition.right.x + gapAverage * DEFAULT_RATIO,
            y: arrowPosition.right.y,
          }}
          gap={gapAverage}
          xAxis
        />
      );
  }
};

export default GlassGap;
