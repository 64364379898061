import { instance_v2 } from '../config';
import axiosStatic, { AxiosInstance } from 'axios';
import { Endpoints } from '../endpoints';
import {
  IndentPosition,
  Project,
  ProjectData,
  ProjectSearch,
} from '../models/project';
import { Page, Pageable } from '../models/requests';
import { stringify } from 'qs';
import Konva from 'konva';
import { Glass } from '../models/glass';
import { ProductModel } from '../models/product';

const CancelToken = axiosStatic.CancelToken;

//TODO: This type overrides should be removed once we have a single type for both the API and the frontend.
export type RectCorners = {
  topLeft: Konva.Vector2d;
  topRight: Konva.Vector2d;
  bottomLeft: Konva.Vector2d;
  bottomRight: Konva.Vector2d;
};

export type IndentCorners = {
  topLeft: Konva.Vector2d;
  topRight: Konva.Vector2d;
  bottomLeft: Konva.Vector2d;
  bottomRight: Konva.Vector2d;
  center: Konva.Vector2d;
  centerLeft?: Konva.Vector2d;
  centerRight?: Konva.Vector2d;
};

interface ProjectDimensionsIndent {
  indent: IndentPosition;
  corners: IndentCorners;
}

interface ProjectDimensionsRect {
  indent?: undefined;
  corners: RectCorners;
}

type ProjectDimensions = ProjectDimensionsIndent | ProjectDimensionsRect;

export interface GlassCommand extends Omit<Glass, 'corners' | 'originCorners'> {
  corners: RectCorners;
  originCorners: RectCorners | undefined;
}

export interface ProductCommand extends Omit<ProductModel, 'corners'> {
  corners: RectCorners;
}

export type ProjectDataCommand = Omit<
  ProjectData,
  'dimensions' | 'glassSheets' | 'products'
> & {
  dimensions: ProjectDimensions;
  glassSheets: GlassCommand[];
  products: ProductCommand[];
};

export type Command = Pick<Project, 'name' | 'projectStatus'> & {
  clientId?: string | number;
  snapshotUrl?: string;
  data: ProjectDataCommand;
};

export type ProjectResponse = Omit<Project, 'data'> & {
  data: ProjectDataCommand;
};

export type FromTemplateCommand = Pick<
  Project,
  'name' | 'client' | 'projectStatus'
> & {
  templateId: number;
};

export type RenameCommand = Pick<Project, 'name'>;

export interface FilterCommand {
  name: string;
  dateFrom: string; // datetime
  dateTo: string; // datetime
}

export interface FilterRequest {
  command?: Partial<FilterCommand>;
  pageable?: Partial<Pageable>;
}

class ProjectApi {
  constructor(private http: AxiosInstance) {}

  public updateSource = CancelToken.source();

  public createSource = CancelToken.source();

  public getProject = (id: number) =>
    this.http.get<ProjectResponse>(`${Endpoints.PROJECT}/${id}`);

  public updateProject = (id: number, command: Command) =>
    this.http.put<ProjectResponse>(`${Endpoints.PROJECT}/${id}`, command, {
      cancelToken: this.updateSource.token,
    });

  public deleteProject = (id: number) =>
    this.http.delete(`${Endpoints.PROJECT}/${id}`);

  public filterProjects = ({ command, pageable }: FilterRequest = {}) =>
    this.http.post<Page<ProjectSearch>>(
      `${Endpoints.PROJECT}/filter?${stringify(pageable)}`,
      command ?? {},
    );

  public createProject = (command: Command) =>
    this.http.post<ProjectResponse>(`${Endpoints.PROJECT}`, command, {
      cancelToken: this.createSource.token,
    });

  public duplicateProject = (id: number) =>
    this.http.post<Project>(`${Endpoints.PROJECT}/${id}/duplicate`);

  public renameProject = (id: number, command: RenameCommand) =>
    this.http.put(`${Endpoints.PROJECT}/${id}/rename`, command);

  public createFromTemplate = (command: FromTemplateCommand) =>
    this.http.post<ProjectResponse>(
      `${Endpoints.PROJECT}/fromTemplate`,
      command,
    );
}

// TODO: Temporary instance caused by big BE changes, needs to be removed
const projectApi = new ProjectApi(instance_v2);
export default projectApi;
