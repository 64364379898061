export enum LocalStorageItems {}

export class LocalStorage {
  public static setItem = (key: string, value: string) =>
    localStorage.setItem(key, value);

  public static getItem = (key: string) => localStorage.getItem(key);

  public static clear = () => localStorage.clear();
}

export default LocalStorage;
