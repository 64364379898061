import { Ability, AbilityClass } from '@casl/ability';
import { ActionTypes, SubjectsTypes } from '../api/models/permissions';

export type AppAbilityType = Ability<[ActionTypes, SubjectsTypes]>;

export const AppAbility = Ability as AbilityClass<AppAbilityType>;
const ability = new AppAbility();
ability.can = ability.can.bind(ability);
ability.cannot = ability.cannot.bind(ability);

export default ability;
