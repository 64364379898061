import { ProjectDataCommand } from '../services/api/services/project';
import { ProjectData } from '../services/api/models/project';
import { getCoordinatesForRect, toPoint, toVector } from './shape';
import { fixPosition } from '../modules/creator/utils/fix';

//TODO: This whole file shouldn't exist. We should be using the same type for both the API and the frontend.
export const getProject = (command: ProjectDataCommand): ProjectData => {
  let dimensions: any;

  if (!command.dimensions) {
    const templatePosition = command.position
      ? fixPosition(command.position)
      : { x: 0, y: 0 };

    command.position = { x: 0, y: 0 };

    return {
      ...command,
      dimensions: {
        corners: getCoordinatesForRect(
          command.width,
          command.height,
          templatePosition,
        ),
      },
      glassSheets: command.glassSheets.map((glass) => {
        let glassCopy: any;
        if (glass) {
          const corners = getCoordinatesForRect(glass.width, glass.height, {
            x: 0,
            y: 0,
          });

          if (!glass.corners) {
            glassCopy = {
              ...glass,
              corners: corners,
              originCorners: corners,
            };
            return glassCopy;
          }

          if (!glass.originCorners) {
            glassCopy = {
              ...glass,
              originCorners: corners,
            };
          }
        }

        return glassCopy;
      }),
      products: command.products.map((product) => {
        let productCopy: any;
        if (product && !product.corners) {
          productCopy = {
            ...product,
            corners: getCoordinatesForRect(product.width, product.height, {
              x: 0,
              y: 0,
            }),
          };
        }

        return productCopy;
      }),
    };
  }

  if (command.dimensions.indent) {
    dimensions = {
      indent: command.dimensions.indent,
      corners: {
        'top-left': toPoint(command.dimensions.corners.topLeft),
        'top-right': toPoint(command.dimensions.corners.topRight),
        'bottom-left': toPoint(command.dimensions.corners.bottomLeft),
        'bottom-right': toPoint(command.dimensions.corners.bottomRight),
        center: toPoint(command.dimensions.corners.center),
        'center-left': command.dimensions.corners.centerLeft
          ? toPoint(command.dimensions.corners.centerLeft)
          : undefined,
        'center-right': command.dimensions.corners.centerRight
          ? toPoint(command.dimensions.corners.centerRight)
          : undefined,
      },
    };
  } else {
    dimensions = {
      indent: undefined,
      corners: {
        'top-left': toPoint(command.dimensions.corners.topLeft),
        'top-right': toPoint(command.dimensions.corners.topRight),
        'bottom-left': toPoint(command.dimensions.corners.bottomLeft),
        'bottom-right': toPoint(command.dimensions.corners.bottomRight),
      },
    };
  }

  return {
    ...command,
    dimensions: dimensions,
    glassSheets: command.glassSheets.map((glass) => {
      const corners = {
        'top-left': toPoint(glass.corners.topLeft),
        'top-right': toPoint(glass.corners.topRight),
        'bottom-left': toPoint(glass.corners.bottomLeft),
        'bottom-right': toPoint(glass.corners.bottomRight),
      };

      return {
        ...glass,
        corners,
        originCorners: glass.originCorners
          ? {
              'top-left': toPoint(glass.originCorners.topLeft),
              'top-right': toPoint(glass.originCorners.topRight),
              'bottom-left': toPoint(glass.originCorners.bottomLeft),
              'bottom-right': toPoint(glass.originCorners.bottomRight),
            }
          : corners,
      };
    }),
    products: command.products.map((product) => {
      return {
        ...product,
        corners: product.corners
          ? {
              'top-left': toPoint(product.corners.topLeft),
              'top-right': toPoint(product.corners.topRight),
              'bottom-left': toPoint(product.corners.bottomLeft),
              'bottom-right': toPoint(product.corners.bottomRight),
            }
          : getCoordinatesForRect(product.width, product.height, {
              x: 0,
              y: 0,
            }),
      };
    }),
  };
};

export const setProject = (project: ProjectData): ProjectDataCommand => {
  let dimensions: any;

  if (project.dimensions.indent) {
    dimensions = {
      indent: project.dimensions.indent,
      corners: {
        topLeft: toVector(project.dimensions.corners['top-left']),
        topRight: toVector(project.dimensions.corners['top-right']),
        bottomLeft: toVector(project.dimensions.corners['bottom-left']),
        bottomRight: toVector(project.dimensions.corners['bottom-right']),
        center: toVector(project.dimensions.corners['center']),
        centerLeft: project.dimensions.corners['center-left']
          ? toVector(project.dimensions.corners['center-left'])
          : undefined,
        centerRight: project.dimensions.corners['center-right']
          ? toVector(project.dimensions.corners['center-right'])
          : undefined,
      },
    };
  } else {
    dimensions = {
      indent: undefined,
      corners: {
        topLeft: toVector(project.dimensions.corners['top-left']),
        topRight: toVector(project.dimensions.corners['top-right']),
        bottomLeft: toVector(project.dimensions.corners['bottom-left']),
        bottomRight: toVector(project.dimensions.corners['bottom-right']),
      },
    };
  }

  return {
    ...project,
    dimensions: dimensions,
    glassSheets: project.glassSheets.map((glass) => {
      return {
        ...glass,
        corners: {
          topLeft: toVector(glass.corners['top-left']),
          topRight: toVector(glass.corners['top-right']),
          bottomLeft: toVector(glass.corners['bottom-left']),
          bottomRight: toVector(glass.corners['bottom-right']),
        },
        originCorners: {
          topLeft: toVector(glass.originCorners['top-left']),
          topRight: toVector(glass.originCorners['top-right']),
          bottomLeft: toVector(glass.originCorners['bottom-left']),
          bottomRight: toVector(glass.originCorners['bottom-right']),
        },
      };
    }),
    products: project.products.map((product) => {
      return {
        ...product,
        corners: {
          topLeft: toVector(product.corners['top-left']),
          topRight: toVector(product.corners['top-right']),
          bottomLeft: toVector(product.corners['bottom-left']),
          bottomRight: toVector(product.corners['bottom-right']),
        },
      };
    }),
  };
};
