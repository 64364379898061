import * as React from 'react';
import { List as Container, ListHeader, ListItem } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../components/Typography';
import { UpSm } from '../../../../components/icons';
import { useMemo, useRef } from 'react';
import { useClickOutside } from '../../../../hooks';

export interface UserMenuItem {
  title: string;
  onClick(): void;
  Icon: React.ReactNode;
}

export interface Props {
  username: string;
  items: UserMenuItem[];
  onClose(): void;
}

const List: React.FC<Props> = React.memo(({ username, items, onClose }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useClickOutside({ elementRef, onClickOutside: onClose });

  const Items = useMemo(
    () =>
      items.map((item, index) => (
        <ListItem
          key={`${item.title}+${index}`}
          role="menuitem"
          onClick={item.onClick}>
          <Typography variant={TypographyVariants.H5}>{item.title}</Typography>
          {item.Icon}
        </ListItem>
      )),
    [items],
  );

  return (
    <Container ref={elementRef} role="menu">
      <ListHeader role="button" onClick={onClose}>
        <Typography oneLine variant={TypographyVariants.H4_BOLD}>
          {username}
        </Typography>
        <UpSm />
      </ListHeader>
      {Items}
    </Container>
  );
});

List.displayName = 'List';

export default List;
