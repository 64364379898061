import { Axis } from './shapes';
import { Shape } from '../types';
import { fixValue } from './fix';
import Big from 'big.js';
import { normalizeVector } from './geometry/vectors';
import { Shape as ShapeClass } from '../../space';

export interface Shadow {
  start: number;
  end: number;
  axis: Axis;
  shapeRef: Shape;
}

export interface ElementShadows {
  x: Shadow;
  y: Shadow;
}

export type DiagonalDirection =
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight';

export interface ShadowDiagonalDistance {
  shapes: [ShapeClass, ShapeClass];
  direction: DiagonalDirection;
}

export const getShadow = (
  shape: Shape,
  axis: Axis = 'x',
  scale = 1,
): Shadow => {
  switch (axis) {
    case 'x': {
      return {
        start: fixValue(shape.position.x),
        end: fixValue(
          new Big(shape.position.x).plus(new Big(shape.width).div(scale)),
        ),
        shapeRef: shape,
        axis,
      };
    }
    case 'y': {
      return {
        start: fixValue(shape.position.y),
        end: fixValue(new Big(shape.position.y).plus(shape.height)),
        shapeRef: shape,
        axis,
      };
    }
  }
};

export const shadowCollision = (shadow1: Shadow, shadow2: Shadow): boolean =>
  (shadow1.start > shadow2.start && shadow1.start < shadow2.end) ||
  (shadow1.end > shadow2.start && shadow1.end < shadow2.end) ||
  (shadow2.start >= shadow1.start && shadow2.end <= shadow1.end);

export const shadowDiagonalDistance = ({
  shapes,
  direction,
}: ShadowDiagonalDistance): number => {
  const [item1, item2] = shapes;

  let x = new Big(-1);
  let y = new Big(-1);
  if (direction === 'topLeft') {
    y = new Big(item1.extremePoints.top.point.y).minus(
      item2.extremePoints.bottom.point.y,
    );
    x = new Big(item1.extremePoints.left.point.x).minus(
      item2.extremePoints.right.point.x,
    );
  }
  if (direction === 'topRight') {
    y = new Big(item1.extremePoints.top.point.y).minus(
      item2.extremePoints.bottom.point.y,
    );
    x = new Big(item2.extremePoints.left.point.x).minus(
      item1.extremePoints.right.point.x,
    );
  }
  if (direction === 'bottomRight') {
    y = new Big(item2.extremePoints.top.point.y).minus(
      item1.extremePoints.bottom.point.y,
    );
    x = new Big(item2.extremePoints.left.point.x).minus(
      item1.extremePoints.right.point.x,
    );
  }
  if (direction === 'bottomLeft') {
    y = new Big(item2.extremePoints.top.point.y).minus(
      item1.extremePoints.bottom.point.y,
    );
    x = new Big(item1.extremePoints.left.point.x).minus(
      item2.extremePoints.right.point.x,
    );
  }
  if (x.gte(0) && y.gte(0)) {
    return normalizeVector({ x, y });
  }
  return -1;
};
