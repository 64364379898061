import Konva from 'konva';
import { useMemo, useState } from 'react';
import * as React from 'react';
import { Arrow, Group, Line } from 'react-konva';
import { DEFAULT_RATIO, selectTheme, useConfigStore } from '../../store/config';
import { Dimensions } from '../../types';
import GapLabel from '../GapLabel/GapLabel';

export interface Props {
  position: Konva.Vector2d;
  gap: number;
  xAxis?: boolean;
}

const ADDITIONAL_DIST = 40;

const GapArrow: React.FC<Props> = React.memo(({ position, gap, xAxis }) => {
  const theme = useConfigStore(selectTheme);

  const scaledGap = gap * DEFAULT_RATIO;

  const labelRef = React.useRef<Konva.Label>(null);
  const [labelDim, setLabelDim] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const topArrowDistance = useMemo(
    () => [
      position.x,
      position.y - ADDITIONAL_DIST - scaledGap,
      position.x,
      position.y - scaledGap,
    ],
    [gap, position],
  );

  const bottomArrowDistance = useMemo(
    () => [position.x, position.y + ADDITIONAL_DIST, position.x, position.y],
    [gap, position],
  );

  const leftArrowDistance = useMemo(
    () => [
      position.x - ADDITIONAL_DIST - scaledGap,
      position.y,
      position.x - scaledGap,
      position.y,
    ],
    [gap, position],
  );

  const rightArrowDistance = useMemo(
    () => [position.x + ADDITIONAL_DIST, position.y, position.x, position.y],
    [gap, position],
  );

  const lineDistanceY = useMemo(
    () => [position.x, position.y, position.x, position.y - scaledGap],
    [position],
  );
  const lineDistanceX = useMemo(
    () => [position.x - scaledGap, position.y, position.x, position.y],
    [position],
  );

  const labelPos = useMemo(() => {
    if (xAxis) {
      return {
        x: position.x - scaledGap / 2 - labelDim.width / 2,
        y: position.y + 10,
      };
    }

    return {
      x: position.x + 5,
      y: position.y - scaledGap / 2 - labelDim.height / 2,
    };
  }, [position, scaledGap, labelDim]);

  React.useEffect(() => {
    labelRef.current &&
      setLabelDim({
        width: labelRef.current.getWidth(),
        height: labelRef.current.getHeight(),
      });
  }, [gap]);

  return (
    <Group>
      <Arrow
        points={xAxis ? leftArrowDistance : topArrowDistance}
        stroke={theme.colors.mainBlue3}
        fill={theme.colors.mainBlue3}
        strokeWidth={1}
        pointerWidth={5}
        pointerLength={5}
      />
      <Line
        points={xAxis ? lineDistanceX : lineDistanceY}
        stroke={theme.colors.mainBlue3}
        strokeWidth={1}
      />
      <GapLabel ref={labelRef} value={gap} {...labelPos} />
      <Arrow
        points={xAxis ? rightArrowDistance : bottomArrowDistance}
        stroke={theme.colors.mainBlue3}
        fill={theme.colors.mainBlue3}
        strokeWidth={1}
        pointerWidth={5}
        pointerLength={5}
      />
    </Group>
  );
});

GapArrow.displayName = 'GapArrow';

export default GapArrow;
