import * as React from 'react';
import { useMemo } from 'react';
import { Container } from './styles';
import Tab from './Tab';
import {
  selectActivePage,
  useNavigationStore,
} from '../../../../store/navigation';
import { RootRoutes } from '../../../../router/routes';
import { Project } from '../../../../services/api/models/project';
import { useOpenProject } from '../../../../pages/Project/hooks';

export interface Props {
  projects: Project[];
  activeProjectId: number;
  onProjectNameChange(name: string): void;
}

const Tabs: React.FC<Props> = React.memo(
  ({ projects, activeProjectId, onProjectNameChange }) => {
    const activePage = useNavigationStore(selectActivePage);
    const { open } = useOpenProject();

    const ProjectTabs = useMemo(
      () =>
        projects.map((project) => (
          <Tab
            key={project.id}
            projectName={project.name}
            onClick={() => open(project.id, activeProjectId === project.id)}
            onNameChange={onProjectNameChange}
            active={
              (activePage === RootRoutes.PROJECT ||
                activePage === RootRoutes.TEMPLATE) &&
              project.id === activeProjectId
            }
          />
        )),
      [activePage, activeProjectId, onProjectNameChange, open, projects],
    );

    return (
      <Container role="tablist">
        <Tab home active={activePage === RootRoutes.HOME} />
        {ProjectTabs}
      </Container>
    );
  },
);

Tabs.displayName = 'Tabs';

export default Tabs;
