import createVanilla from 'zustand/vanilla';
import create, { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ProjectSearch } from '../../services/api/models/project';
import { AxiosError } from 'axios';
import { FilterRequest } from '../../services/api/services/project';
import { projectApi } from '../../services/api/services';
import { Page } from '../../services/api/models/requests';
import { createAsyncAction } from '../middlewares/actions';

export const STORE_NAME = `@store/projects/filter`;

export type State = {
  loading: boolean;
  data?: Page<ProjectSearch>;
  error?: AxiosError;
  filter(data?: FilterRequest): Promise<void>;
  clear(): void;
};

const filterAction = (set: SetState<State>) => (payload?: FilterRequest) =>
  createAsyncAction(set)(async () => {
    const { data } = await projectApi.filterProjects(payload);
    set({ data });
  });

const store = (set: SetState<State>) => ({
  loading: false,
  filter: filterAction(set),
  clear: () => set({ data: undefined, error: undefined }),
});

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
