import * as React from 'react';

const SvgDownload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M5 18h14a1 1 0 110 2H5a1 1 0 110-2z"
        fill={props.fill ?? '#272B5F'}
      />
      <path
        d="M20 17v2a1 1 0 11-2 0v-2a1 1 0 112 0zM6 17v2a1 1 0 11-2 0v-2a1 1 0 112 0zM8 10a1 1 0 00-.6 1.8l4 3a1 1 0 001.18 0l4-2.82a1 1 0 10-1.16-1.63L12 12.76 8.6 10.2A1 1 0 008 10z"
        fill={props.fill ?? '#272B5F'}
      />
      <path
        d="M12 3a1 1 0 00-1 1v8a1 1 0 002 0V4a1 1 0 00-1-1z"
        fill={props.fill ?? '#272B5F'}
      />
    </svg>
  );
};

const MemoSvgDownload = React.memo(SvgDownload);
export default MemoSvgDownload;
