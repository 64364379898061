import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import createVanilla from 'zustand/vanilla';
import { ShapeType } from '../select';
import { Glass } from '../../../../services/api/models/glass';
import { projectStore } from '../project';
import { findEmptySpace } from '../../utils/space';
import { Position, Shape } from '../../types';
import { ProductModel } from '../../../../services/api/models/product';
import { productsStore } from '../products';
import { Anchor } from '../../types/Anchor';
import { getCoordinatesForRect } from '../../../../utils/shape';
import { Shape as ShapeClass } from '../../../space';
import { ProjectDimensions } from '../../../../services/api/models/project';

export const STORE_NAME = `@store/creator/copy`;

export interface CopyItem {
  data: Glass | ProductModel;
  type: ShapeType;
}

export type State = {
  copy(item: CopyItem): void;
  cut(item: CopyItem): void;
  paste(): CopyItem | undefined;
  validPaste(context: ShapeType): boolean | undefined;
  validSpace(): Shape | undefined;
  validPosition(
    glass: Glass,
    shape: Shape,
    anchor?: Anchor,
  ): Position | undefined;
  validGlassGlass(glass: Glass, item: CopyItem): boolean;
  copied?: CopyItem;
};

const { deleteGlass } = projectStore.getState();
const { deleteProduct } = projectStore.getState();
const { deleteProduct: deleteCreatorProduct } = productsStore.getState();

const store = (set: SetState<State>, get: GetState<State>): State => {
  return {
    copy: (item: CopyItem) => {
      set({
        copied: item,
      });
    },
    cut: (item: CopyItem) => {
      const { copy } = get();
      copy(item);
      if (item.type === 'glass') {
        deleteGlass((item.data as Glass).id);
      }
      if (item.type === 'product') {
        const iid = (item.data as ProductModel).id;
        deleteProduct(iid, () => deleteCreatorProduct(iid));
      }
    },
    paste: () => {
      const { copied } = get();
      return copied;
    },
    validSpace: () => {
      const { copied } = get();
      if (copied?.type === 'glass') {
        const copiedData = copied.data as Glass;
        const { project } = projectStore.getState();
        const copiedObj = new ShapeClass(
          { corners: copiedData.corners },
          copiedData.position,
        );
        const minWidth = copiedObj.width;
        const minHeight = copiedObj.height;
        if (project) {
          const { glassSheets } = project.data;
          const glasses = glassSheets.map(({ corners, position }) => ({
            corners,
            position,
          }));

          const result = findEmptySpace(
            project.data.dimensions,
            project.data.position ?? { x: 0, y: 0 },
            glasses,
            minWidth,
            minHeight,
          );
          if (result && result.width > 0 && result.height > 0) {
            result.removePositionFromStart();

            return result;
          }
        }
      }
    },
    validPosition: (glass, shape, anchor: Anchor = 'center') => {
      const { copied } = get();
      const { project } = projectStore.getState();
      if (copied && project) {
        let validShape: {
          corners: ProjectDimensions['corners'];
          position: Position;
        };
        const glassObj = new ShapeClass(
          { corners: glass.corners },
          glass.position,
        );
        switch (anchor) {
          case 'top': {
            validShape = {
              corners: getCoordinatesForRect(glassObj.width, shape.height),
              position: glass.position,
            };
            break;
          }
          case 'bottom': {
            validShape = {
              corners: getCoordinatesForRect(glassObj.width, shape.height),
              position: {
                ...glass.position,
                y: glassObj.extremePoints.bottom.point.y - shape.height,
              },
            };
            break;
          }
          case 'left': {
            validShape = {
              corners: getCoordinatesForRect(shape.width, glassObj.height),
              position: glass.position,
            };
            break;
          }
          case 'right': {
            validShape = {
              corners: getCoordinatesForRect(shape.width, glassObj.height),
              position: {
                ...glass.position,
                x: glassObj.extremePoints.right.point.x - shape.width,
              },
            };
            break;
          }
          case 'topLeft': {
            validShape = {
              corners: getCoordinatesForRect(shape.width, shape.height),
              position: glass.position,
            };
            break;
          }
          case 'topRight': {
            validShape = {
              corners: getCoordinatesForRect(shape.width, shape.height),
              position: {
                ...glass.position,
                x: glassObj.extremePoints.right.point.x - shape.width,
              },
            };
            break;
          }
          case 'bottomLeft': {
            validShape = {
              corners: getCoordinatesForRect(shape.width, shape.height),
              position: {
                ...glass.position,
                y: glassObj.extremePoints.bottom.point.y - shape.height,
              },
            };
            break;
          }
          case 'bottomRight': {
            validShape = {
              corners: getCoordinatesForRect(shape.width, shape.height),
              position: {
                x: glassObj.extremePoints.right.point.x - shape.width,
                y: glassObj.extremePoints.bottom.point.y - shape.height,
              },
            };
            break;
          }
          case 'center':
          default: {
            validShape = {
              corners: getCoordinatesForRect(glassObj.width, glassObj.height),
              position: glass.position,
            };
            break;
          }
        }
        const space = findEmptySpace(
          { corners: validShape.corners },
          validShape.position,
          project.data.products
            .filter((item) =>
              item.connections.some(
                (conn) =>
                  conn.anchor === anchor && conn.targetId === String(glass.id),
              ),
            )
            .map((product) => ({
              corners: getCoordinatesForRect(product.width, product.height),
              position: product.position,
            })),
          shape.width,
          shape.height,
        );

        if (space && space.width > 0 && space.height > 0) {
          return space.position;
        }
      }
    },
    validGlassGlass: (glass, item) => {
      return (item.data as ProductModel).connections.some(
        (connection) => connection.targetId === String(glass.id),
      );
    },
    validPaste: (context: ShapeType) => {
      const { copied } = get();
      if (copied) {
        switch (context) {
          case 'template': {
            return copied.type === 'glass';
          }
          case 'glass': {
            return copied.type === 'product';
          }
          default: {
            return false;
          }
        }
      }
    },
  };
};

export const vanillaStore = createVanilla<State>(devtools(store, STORE_NAME));

export const useStore = create<State>(vanillaStore);
