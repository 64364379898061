import {
  creatorConfig,
  creatorProject,
  creatorTemplate,
  templates,
  TemplateTypes,
} from '../../../modules/creator';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import {
  ProjectRoutes,
  RootRoutes,
  TemplateRoutes,
} from '../../../router/routes';
import {
  selectFetch,
  useProjectStore as useProjectApiStore,
} from '../../../store/projects';
import useSave from './useSave';
import {
  selectSelect,
  useSelectStore,
} from '../../../modules/creator/store/select';
import {
  selectFetch as selectProjectTemplateFetch,
  useTemplateStore as useProjectTemplateStore,
} from '../../../store/templates';

export interface UseOpenProject {
  openBlank(templateEdit?: boolean): void;
  openNew(
    template: TemplateTypes,
    noRedirect?: boolean,
    templateEdit?: boolean,
  ): void;
  open(
    projectId: number,
    preventReload?: boolean,
    templateEdit?: boolean,
  ): void;
  fetch(projectId: number): Promise<void>;
  fetchTemplate(templateId: number): Promise<void>;
}

const { useConfigStore, selectReset } = creatorConfig;
const {
  useTemplateStore,
  selectClear: selectTemplateClear,
  selectSetup: selectSetupTemplate,
} = creatorTemplate;
const {
  useProjectStore,
  selectClear,
  selectSetup,
  selectSaved,
  selectProject,
} = creatorProject;

const useOpenProject = (): UseOpenProject => {
  const { push } = useHistory();
  const resetCreatorConfig = useConfigStore(selectReset);
  const clearProject = useProjectStore(selectClear);
  const setupProject = useProjectStore(selectSetup);
  const clearTemplate = useTemplateStore(selectTemplateClear);
  const setupTemplate = useTemplateStore(selectSetupTemplate);
  const saved = useProjectStore(selectSaved);
  const project = useProjectStore(selectProject);
  const fetchProject = useProjectApiStore(selectFetch);
  const fetchProjectTemplate = useProjectTemplateStore(
    selectProjectTemplateFetch,
  );
  const select = useSelectStore(selectSelect);
  const { save } = useSave(project);

  const clearCreator = useCallback(() => {
    clearTemplate();
    clearProject();
    resetCreatorConfig();
  }, [clearTemplate, clearProject, resetCreatorConfig]);

  const saveOnOpen = useCallback(
    async (callback: () => void, projectId?: number) => {
      if (project && project.id !== projectId && !saved) {
        await save('confirm');
        callback();
      } else {
        callback();
      }
    },
    [project, save, saved],
  );

  const openBlank = useCallback(
    async (templateEdit?: boolean) => {
      await saveOnOpen(() => {
        clearCreator();
        push(templateEdit ? RootRoutes.TEMPLATE : RootRoutes.PROJECT);
      });
    },
    [clearCreator, push, saveOnOpen],
  );

  const openNew = useCallback(
    async (
      template: keyof typeof templates,
      noRedirect?: boolean,
      templateEdit?: boolean,
    ) => {
      await saveOnOpen(() => {
        clearCreator();
        const templateId = setupTemplate();
        setupProject({ ...templates[template], scale: 1 });
        select({ type: 'template', shapeId: templateId });
        !noRedirect &&
          push(templateEdit ? TemplateRoutes.CREATE : ProjectRoutes.CREATE, {
            create: true,
          });
      });
    },
    [clearCreator, push, saveOnOpen, select, setupProject, setupTemplate],
  );

  const open = useCallback(
    async (
      projectId: number,
      preventReload?: boolean,
      templateEdit?: boolean,
    ) => {
      await saveOnOpen(() => {
        if (!preventReload) {
          clearCreator();
          setupTemplate();
        }
        push(
          (templateEdit ? TemplateRoutes.EDIT : ProjectRoutes.EDIT).replace(
            ':id',
            String(projectId),
          ),
        );
        fetch(projectId);
      }, projectId);
    },
    [clearCreator, push, saveOnOpen, setupTemplate],
  );

  const fetch = useCallback(
    async (projectId: number) => {
      await fetchProject(projectId);
    },
    [fetchProject],
  );

  const fetchTemplate = useCallback(
    async (templateId: number) => {
      await fetchProjectTemplate(templateId);
    },
    [fetchProjectTemplate],
  );

  return {
    openBlank,
    openNew,
    open,
    fetch,
    fetchTemplate,
  };
};

export default useOpenProject;
