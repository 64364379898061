import * as React from 'react';
import { Container } from './styles';
import {
  Typography,
  TypographyVariants,
} from '../../../../../../components/Typography';
import { useTranslation } from 'react-i18next';
import { Empty } from '../../../../../../components/images/placeholders';

const EmptyResults: React.FC = ({ ...props }) => {
  const { t } = useTranslation('catalog');

  return (
    <Container {...props}>
      <Empty />
      <Typography variant={TypographyVariants.H5}>
        {t('list.placeholders.noSearchedProducts')}
      </Typography>
    </Container>
  );
};

export default EmptyResults;
