import styled from 'styled-components';
import { layout } from '../../../../../../styles';

export const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
`;

export const Sections = styled.div`
  height: calc(100% - 73px);
  box-sizing: border-box;
  padding-bottom: 44px;
  overflow: auto;
`;
