import {
  convertToGlass,
  DataCopy,
  getClippedPolygon,
  getParentPolygon,
  getRectPolygon,
  GlassWithEdges,
} from './boundaries';
import { ANCHOR_POINTS } from '../../../services/api/models/transformer';
import { Vector2d } from 'konva/lib/types';
import { ExtendedDataCopy } from '../components/Transformer/Transformer';
import { EdgeType, RectCornersType } from '../../../types';
import find from 'lodash/find';
import { toPoint, toPositionBig, toVector } from '../../../utils/shape';
import Big from 'big.js';
import { DEFAULT_RATIO } from '../store/config';
import forEach from 'lodash/forEach';
import { CollisionData } from './transformer';
import {
  findCrossPoint,
  findLine,
  findParallelLine,
  findXPointOnLine,
  findYPointOnLine,
  Line,
} from './geometry/lines';
import { calculateMidPoint, checkPointsEqual } from './geometry/points';
import { doVectorsIntersect, Vector } from './geometry/vectors';

export const getOppositeCorner = (corner: ANCHOR_POINTS) => {
  switch (corner) {
    case ANCHOR_POINTS.TOP_LEFT: {
      return ANCHOR_POINTS.BOTTOM_RIGHT;
    }
    case ANCHOR_POINTS.TOP_RIGHT: {
      return ANCHOR_POINTS.BOTTOM_LEFT;
    }
    case ANCHOR_POINTS.BOTTOM_LEFT: {
      return ANCHOR_POINTS.TOP_RIGHT;
    }
    default: {
      return ANCHOR_POINTS.TOP_LEFT;
    }
  }
};

const getAdjacentCornerForEdge = (
  corner: ANCHOR_POINTS,
  edge:
    | ANCHOR_POINTS.TOP
    | ANCHOR_POINTS.RIGHT
    | ANCHOR_POINTS.BOTTOM
    | ANCHOR_POINTS.LEFT,
) => {
  if (edge === ANCHOR_POINTS.TOP) {
    if (corner === ANCHOR_POINTS.TOP_LEFT) {
      return ANCHOR_POINTS.TOP_RIGHT;
    } else {
      return ANCHOR_POINTS.TOP_LEFT;
    }
  }
  if (edge === ANCHOR_POINTS.RIGHT) {
    if (corner === ANCHOR_POINTS.TOP_RIGHT) {
      return ANCHOR_POINTS.BOTTOM_RIGHT;
    } else {
      return ANCHOR_POINTS.TOP_RIGHT;
    }
  }
  if (edge === ANCHOR_POINTS.BOTTOM) {
    if (corner === ANCHOR_POINTS.BOTTOM_LEFT) {
      return ANCHOR_POINTS.BOTTOM_RIGHT;
    } else {
      return ANCHOR_POINTS.BOTTOM_LEFT;
    }
  }
  if (edge === ANCHOR_POINTS.LEFT) {
    if (corner === ANCHOR_POINTS.TOP_LEFT) {
      return ANCHOR_POINTS.BOTTOM_LEFT;
    } else {
      return ANCHOR_POINTS.TOP_LEFT;
    }
  }
};

/**
 * It returns the parent edge that the diagonal line crosses
 * @param collider
 * @param diagonalStart
 * @param diagonalEnd
 * @param outPointName
 * @param diagonalOutside - if false - the diagonal line is inside the collider polygon, if true - the diagonal line is outside
 * @returns {vector: Vector, name: string}
 * */
export const getCrossedEdge = (
  collider: GlassWithEdges,
  diagonalStart: Vector2d,
  diagonalEnd: Vector2d,
  outPointName: RectCornersType,
  diagonalOutside = false,
): { vector: Vector; name: string } => {
  let crossedEdge: { vector: Vector; name: string } = {
    vector: { startX: 0, startY: 0, endX: 0, endY: 0 },
    name: '',
  };

  let validatePointToEdges: Record<RectCornersType, [EdgeType, EdgeType]> = {
    'top-left': ['top', 'left'],
    'top-right': ['top', 'right'],
    'bottom-left': ['bottom', 'left'],
    'bottom-right': ['bottom', 'right'],
  };

  if (diagonalOutside) {
    validatePointToEdges = {
      'top-left': ['bottom', 'right'],
      'top-right': ['bottom', 'left'],
      'bottom-left': ['top', 'right'],
      'bottom-right': ['top', 'left'],
    };
  }

  const bindEdgeToPoint = (outPointName: EdgeType) => {
    const edge = collider.edges[outPointName];

    return {
      start: {
        x: edge.startX,
        y: edge.startY,
      },
      end: {
        x: edge.endX,
        y: edge.endY,
      },
    };
  };

  const edgesToValidate = validatePointToEdges[outPointName];

  forEach(edgesToValidate, (edgeName) => {
    const { start, end } = bindEdgeToPoint(edgeName);
    if (doVectorsIntersect(start, end, diagonalStart, diagonalEnd)) {
      crossedEdge = { vector: collider.edges[edgeName], name: edgeName };
    }
  });

  return crossedEdge;
};

const getCorrectedVector = (
  moveVector: Vector2d,
  corner: Vector2d,
  resultVector: Vector2d,
  scale: number,
): Vector2d => {
  const MoveVector = toPositionBig(moveVector);
  const Corner = toPositionBig(corner);
  const ResultVector = toPositionBig(resultVector);
  const Scale = new Big(scale).times(DEFAULT_RATIO);

  const diff = {
    x: MoveVector.x.minus(Corner.x).times(Scale),
    y: MoveVector.y.minus(Corner.y).times(Scale),
  };

  return {
    x: ResultVector.x.minus(diff.x).toNumber(),
    y: ResultVector.y.minus(diff.y).toNumber(),
  };
};

export const getCornersOutsideNiche = (data: DataCopy) => {
  const glass = convertToGlass(data.shape);
  const glassCorners = convertToGlass(data.shape).corners;
  const glassPolygon = getRectPolygon(glass.corners);
  const { polygon: parentPolygon } = getParentPolygon(data.parents[0]);
  const clippedPolygon = getClippedPolygon(parentPolygon, glassPolygon);

  const cornersOutside: string[] = [];

  for (const [glassCornerName, glassCornerValue] of Object.entries(
    glassCorners,
  )) {
    if (
      glassCornerValue &&
      clippedPolygon.find((corner) =>
        checkPointsEqual(toPoint(corner), glassCornerValue),
      ) === undefined
    ) {
      cornersOutside.push(glassCornerName);
    }
  }

  return cornersOutside;
};

export const getCornersIntersectingGlasses = (data: ExtendedDataCopy) => {
  const glass = convertToGlass(data.shape);
  const glassCorners = convertToGlass(data.shape).corners;
  const glassPolygon = getRectPolygon(glass.corners);

  const collisions: CollisionData[] = [];

  forEach(data.shapes, (item, index) => {
    const otherGlass = convertToGlass(item);
    const otherGlassCorners = convertToGlass(item).corners;
    const otherGlassPolygon = getRectPolygon(otherGlass.corners);

    const clippedPolygon = getClippedPolygon(otherGlassPolygon, glassPolygon);

    for (const [glassCornerName, glassCornerValue] of Object.entries(
      glassCorners,
    )) {
      if (
        data.shape.id &&
        item.id &&
        glassCornerValue &&
        clippedPolygon.find((corner) =>
          checkPointsEqual(toPoint(corner), glassCornerValue),
        )
      ) {
        collisions.push({
          shapeId: data.shape.id,
          colliderId: item.id,
          cornerName: glassCornerName,
          length: 0,
        });
      }
    }

    for (const [otherGlassCornerName, otherGlassCornerValue] of Object.entries(
      otherGlassCorners,
    )) {
      if (
        data.shape.id &&
        item.id &&
        otherGlassCornerValue &&
        clippedPolygon.find((corner) =>
          checkPointsEqual(toPoint(corner), otherGlassCornerValue),
        )
      ) {
        collisions.push({
          shapeId: item.id,
          colliderId: data.shape.id,
          cornerName: otherGlassCornerName,
          length: 0,
        });
      }
    }
  });

  return collisions;
};

export const getGlassesWithCornersOutsideNiche = (
  data: Omit<DataCopy, 'shape'>,
) => {
  const glasses = data.shapes;
  const { polygon: parentPolygon } = getParentPolygon(data.parents[0]);

  const cornersOutside: { glass: GlassWithEdges; name: string }[] = [];

  glasses.forEach((glassElement) => {
    const glass = convertToGlass(glassElement);
    const glassCorners = convertToGlass(glassElement).corners;
    const glassPolygon = getRectPolygon(glass.corners);

    const clippedPolygon = getClippedPolygon(parentPolygon, glassPolygon);

    for (const [glassCornerName, glassCornerValue] of Object.entries(
      glassCorners,
    )) {
      if (
        glassCornerValue &&
        clippedPolygon.find((corner) =>
          checkPointsEqual(toPoint(corner), glassCornerValue),
        ) === undefined
      ) {
        cornersOutside.push({ glass: glass, name: glassCornerName });
      }
    }
  });
  return cornersOutside;
};

const findHorizontalMaxPoint = (
  outCorner: [number, number],
  point: [number, number],
  crossedEdge: { vector: Vector; name: string },
) => {
  const glassEdge = findLine(outCorner, point);

  if (crossedEdge.vector.startX !== crossedEdge.vector.endX) {
    const edgeLine = findLine(
      [crossedEdge.vector.startX, crossedEdge.vector.startY],
      [crossedEdge.vector.endX, crossedEdge.vector.endY],
    );

    const crossPoint = findCrossPoint(glassEdge, edgeLine);

    return toVector(crossPoint);
  } else {
    const maxPointY = findYPointOnLine(glassEdge, crossedEdge.vector.startX);
    return toVector([crossedEdge.vector.startX, maxPointY]);
  }
};

const findVerticalMaxPoint = (
  crossedEdge: { vector: Vector; name: string },
  outCorner: [number, number],
  point: [number, number],
) => {
  const edgeLine = findLine(
    [crossedEdge.vector.startX, crossedEdge.vector.startY],
    [crossedEdge.vector.endX, crossedEdge.vector.endY],
  );

  if (outCorner[0] !== point[0]) {
    const glassEdge = findLine(outCorner, point);
    const crossPoint = findCrossPoint(glassEdge, edgeLine);

    return toVector(crossPoint);
  } else {
    const maxPointY = findYPointOnLine(edgeLine, outCorner[0]);
    return toVector([outCorner[0], maxPointY]);
  }
};

export const handleDraggedCornerCollision = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId: RectCornersType,
  colliderGlass: GlassWithEdges,
  outCornerId: RectCornersType,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
  modifiedOutsideCollider = false,
) => {
  const glassCorners = modifiedGlass.corners;
  const outCorner = glassCorners[modifiedCornerId];

  const diagonalStart = toVector(glassCorners[modifiedCornerId]);
  const diagonalEnd = toVector(
    glassCorners[getOppositeCorner(modifiedCornerId as ANCHOR_POINTS)],
  );

  const crossedEdge = getCrossedEdge(
    colliderGlass,
    diagonalStart,
    diagonalEnd,
    outCornerId,
    modifiedOutsideCollider,
  );

  if (crossedEdge.name === '') {
    return vector;
  }

  let maxPoint = { x: 0, y: 0 };

  let validateEdgesForPoints = {
    [ANCHOR_POINTS.TOP_RIGHT]: [
      { neighborCorner: 'bottom-right', edge: 'top' },
      { neighborCorner: 'top-left', edge: 'right' },
    ],
    [ANCHOR_POINTS.BOTTOM_RIGHT]: [
      { neighborCorner: 'top-right', edge: 'bottom' },
      { neighborCorner: 'bottom-left', edge: 'right' },
    ],
    [ANCHOR_POINTS.BOTTOM_LEFT]: [
      { neighborCorner: 'top-left', edge: 'bottom' },
      { neighborCorner: 'bottom-right', edge: 'left' },
    ],
    [ANCHOR_POINTS.TOP_LEFT]: [
      { neighborCorner: 'bottom-left', edge: 'top' },
      { neighborCorner: 'top-right', edge: 'left' },
    ],
  };

  if (modifiedOutsideCollider) {
    validateEdgesForPoints = {
      [ANCHOR_POINTS.TOP_RIGHT]: [
        { neighborCorner: 'bottom-right', edge: 'bottom' },
        { neighborCorner: 'top-left', edge: 'left' },
      ],
      [ANCHOR_POINTS.BOTTOM_RIGHT]: [
        { neighborCorner: 'top-right', edge: 'top' },
        { neighborCorner: 'bottom-left', edge: 'left' },
      ],
      [ANCHOR_POINTS.BOTTOM_LEFT]: [
        { neighborCorner: 'top-left', edge: 'top' },
        { neighborCorner: 'bottom-right', edge: 'right' },
      ],
      [ANCHOR_POINTS.TOP_LEFT]: [
        { neighborCorner: 'bottom-left', edge: 'bottom' },
        { neighborCorner: 'top-right', edge: 'right' },
      ],
    };
  }

  const validateEdges = validateEdgesForPoints[modifiedCornerId];

  validateEdges.forEach(({ neighborCorner, edge }, index) => {
    if (crossedEdge.name === edge) {
      const point = glassCorners[neighborCorner as 'top-left'];
      if (edge === 'top' || edge === 'bottom') {
        maxPoint = findVerticalMaxPoint(crossedEdge, outCorner, point);
      } else {
        maxPoint = findHorizontalMaxPoint(outCorner, point, crossedEdge);
      }
    }
  });

  return getCorrectedVector(scaledVector, maxPoint, vector, scale);
};

const findMaxAdjacentPoint = (
  outCorner: [number, number],
  adjacentCorner: [number, number],
  outOppositeCorner: [number, number],
  maxPoint: [number, number],
): [number, number] => {
  if (outCorner[0] === adjacentCorner[0]) {
    const line = findLine(outOppositeCorner, adjacentCorner);
    const maxAdjacentPointY = findYPointOnLine(line, maxPoint[0]);
    return [maxPoint[0], maxAdjacentPointY];
  } else {
    const lineA = findLine(outCorner, adjacentCorner);
    const lineAParallel = findParallelLine(maxPoint as [number, number], lineA);
    if (outOppositeCorner[0] === adjacentCorner[0]) {
      const maxAdjacentPointY = findYPointOnLine(
        lineAParallel,
        adjacentCorner[0],
      );
      return [adjacentCorner[0], maxAdjacentPointY];
    } else {
      const lineB = findLine(outOppositeCorner, adjacentCorner);
      return findCrossPoint(lineAParallel, lineB);
    }
  }
};

const findMaxPoint = (
  crossedEdge: Vector,
  outCorner: [number, number],
  adjacentOppositeCorner: [number, number],
): [number, number] => {
  if (crossedEdge.startX === crossedEdge.endX) {
    if (outCorner[0] === adjacentOppositeCorner[0]) {
      return [outCorner[0], outCorner[1]];
    } else {
      const line = findLine(outCorner, adjacentOppositeCorner);
      const maxPointY = findYPointOnLine(line, crossedEdge.startX);
      return [crossedEdge.startX, maxPointY];
    }
  } else {
    const edgeLine = findLine(
      [crossedEdge.startX, crossedEdge.startY],
      [crossedEdge.endX, crossedEdge.endY],
    );

    if (outCorner[0] === adjacentOppositeCorner[0]) {
      const maxPointY = findYPointOnLine(edgeLine, outCorner[0]);
      return [outCorner[0], maxPointY];
    } else {
      const line = findLine(outCorner, adjacentOppositeCorner);

      if (Math.abs(edgeLine.a) === 0 && Math.abs(line.a) === 0) {
        return [outCorner[0], outCorner[1]];
      }
      return findCrossPoint(edgeLine, line);
    }
  }
};

export const handleDraggedEdgeCollision = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId:
    | ANCHOR_POINTS.TOP
    | ANCHOR_POINTS.BOTTOM
    | ANCHOR_POINTS.LEFT
    | ANCHOR_POINTS.RIGHT,
  colliderGlass: GlassWithEdges,
  outCornerId: RectCornersType,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
  modifiedOutsideCollider = false,
) => {
  const glassCorners = modifiedGlass.corners;
  const outCorner = glassCorners[outCornerId];
  const adjacentCornerName = getAdjacentCornerForEdge(
    outCornerId as ANCHOR_POINTS,
    modifiedCornerId,
  );

  if (!adjacentCornerName) {
    console.error('adjacentCornerName is undefined');
    return vector;
  }

  const adjacentCorner = glassCorners[adjacentCornerName];
  const adjacentOppositeCorner =
    glassCorners[getOppositeCorner(adjacentCornerName)];
  const outOppositeCorner =
    glassCorners[getOppositeCorner(outCornerId as ANCHOR_POINTS)];

  const crossedEdge = getCrossedEdge(
    colliderGlass,
    { x: outOppositeCorner[0], y: outOppositeCorner[1] },
    { x: outCorner[0], y: outCorner[1] },
    outCornerId,
    modifiedOutsideCollider,
  ).vector;

  const maxPoint = findMaxPoint(crossedEdge, outCorner, adjacentOppositeCorner);

  const maxAdjacentPoint = findMaxAdjacentPoint(
    outCorner,
    adjacentCorner,
    outOppositeCorner,
    maxPoint,
  );

  const newAnchorPoint = calculateMidPoint(maxPoint, maxAdjacentPoint);

  return getCorrectedVector(scaledVector, newAnchorPoint, vector, scale);
};

/**
 * It resolves collision for dragged edge when colliding corner doesn't belong to modifiedGlass but to colliderGlass
 * @param modifiedGlass
 * @param modifiedCornerId
 * @param colliderGlass
 * @param outCornerId
 * @param scaledVector
 * @param vector
 * @param scale
 */
const handleDraggedEdgeCollisionInnerCollider = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId:
    | ANCHOR_POINTS.TOP
    | ANCHOR_POINTS.BOTTOM
    | ANCHOR_POINTS.LEFT
    | ANCHOR_POINTS.RIGHT,
  colliderGlass: GlassWithEdges,
  outCornerId: RectCornersType,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
) => {
  const adjacentCornerName = getAdjacentCornerForEdge(
    outCornerId as ANCHOR_POINTS,
    modifiedCornerId,
  );

  const oppositeAdjacentCornerName = getAdjacentCornerForEdge(
    adjacentCornerName as ANCHOR_POINTS,
    modifiedCornerId,
  );

  if (!adjacentCornerName || !oppositeAdjacentCornerName) {
    console.error('adjacentCornerName is undefined');
    return vector;
  }

  const adjacentResolved = handleDraggedCornerCollisionInnerCollider(
    modifiedGlass,
    adjacentCornerName,
    colliderGlass,
    outCornerId as ANCHOR_POINTS.TOP_LEFT,
    scaledVector,
    vector,
    scale,
  );
  const oppositeAdjacentResolved = handleDraggedCornerCollisionInnerCollider(
    modifiedGlass,
    oppositeAdjacentCornerName,
    colliderGlass,
    outCornerId as ANCHOR_POINTS.TOP_LEFT,
    scaledVector,
    vector,
    scale,
  );

  return calculateMidPoint(
    toPoint(adjacentResolved),
    toPoint(oppositeAdjacentResolved),
  );
};

const findMaxPositionForOutPoint = (
  outCorner: [number, number],
  oppositeMovedCorner: [number, number],
  crossedEdgeVector: Vector,
): [number, number] => {
  if (outCorner[0] === oppositeMovedCorner[0]) {
    const edgeLine = findLine(
      [crossedEdgeVector.startX, crossedEdgeVector.startY],
      [crossedEdgeVector.endX, crossedEdgeVector.endY],
    );

    const maxOutPointY = findYPointOnLine(edgeLine, outCorner[0]);
    return [outCorner[0], maxOutPointY];
  } else {
    const lineOutMovedOpposite = findLine(outCorner, oppositeMovedCorner);

    if (crossedEdgeVector.startX === crossedEdgeVector.endX) {
      const maxOutPointY = findYPointOnLine(
        lineOutMovedOpposite,
        crossedEdgeVector.startX,
      );

      return [crossedEdgeVector.startX, maxOutPointY];
    } else {
      const crossedEdgeLine = findLine(
        [crossedEdgeVector.startX, crossedEdgeVector.startY],
        [crossedEdgeVector.endX, crossedEdgeVector.endY],
      );

      if (
        Math.abs(crossedEdgeLine.a) === 0 &&
        Math.abs(lineOutMovedOpposite.a) === 0
      ) {
        return [outCorner[0], outCorner[1]];
      }

      return findCrossPoint(crossedEdgeLine, lineOutMovedOpposite);
    }
  }
};

const findMaxMovedPoint = (
  movedCorner: [number, number],
  outCorner: [number, number],
  maxPositionForOutPoint: [number, number],
  oppositeOutCorner: [number, number],
): [number, number] => {
  if (movedCorner[0] === outCorner[0]) {
    return [maxPositionForOutPoint[0], movedCorner[1]];
  } else {
    const lineMovedOut = findLine(movedCorner, outCorner);
    const lineMovedOutParallel = findParallelLine(
      maxPositionForOutPoint,
      lineMovedOut,
    );

    if (movedCorner[0] === oppositeOutCorner[0]) {
      const maxMovedPointY = findYPointOnLine(
        lineMovedOutParallel,
        movedCorner[0],
      );
      return [movedCorner[0], maxMovedPointY];
    } else {
      const lineMovedOppositeOut = findLine(movedCorner, oppositeOutCorner);
      return findCrossPoint(lineMovedOppositeOut, lineMovedOutParallel);
    }
  }
};

export const handleOtherCornerCollision = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId: RectCornersType,
  colliderGlass: GlassWithEdges,
  outCornerId: RectCornersType,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
  modifiedOutsideCollider = false,
) => {
  const glassCorners = modifiedGlass.corners;
  const outCorner = glassCorners[outCornerId];
  const oppositeOutCorner =
    glassCorners[getOppositeCorner(outCornerId as ANCHOR_POINTS)];
  const movedCorner = glassCorners[modifiedCornerId as RectCornersType];
  const oppositeMovedCorner =
    glassCorners[getOppositeCorner(modifiedCornerId as ANCHOR_POINTS)];

  const crossedEdge = getCrossedEdge(
    colliderGlass,
    toVector(outCorner),
    toVector(oppositeOutCorner),
    outCornerId,
    modifiedOutsideCollider,
  );

  const crossedEdgeVector = crossedEdge.vector;
  const crossedEdgeName = crossedEdge.name;

  if (crossedEdgeName === '') {
    return vector;
  }

  let maxPositionForOutPoint: [number, number];

  // In case outCorner and oppositeMovedCorner are vertically inline
  // and crossedEdgeVector is vertically inline we return current vector
  if (
    outCorner[0] === oppositeMovedCorner[0] &&
    crossedEdgeVector.startX === crossedEdgeVector.endX
  ) {
    maxPositionForOutPoint = [outCorner[0], crossedEdgeVector.startY];
  } else {
    maxPositionForOutPoint = findMaxPositionForOutPoint(
      outCorner,
      oppositeMovedCorner,
      crossedEdgeVector,
    );
  }

  const maxMovedPoint = findMaxMovedPoint(
    movedCorner,
    outCorner,
    maxPositionForOutPoint,
    oppositeOutCorner,
  );

  return getCorrectedVector(
    scaledVector,
    toVector(maxMovedPoint),
    vector,
    scale,
  );
};

/**
 * It resolves collision for dragged corner when colliding corner doesn't belong to modifiedGlass but to colliderGlass
 * @param modifiedGlass
 * @param modifiedCornerId
 * @param colliderGlass
 * @param outCornerId
 * @param scaledVector
 * @param vector
 * @param scale
 */
const handleDraggedCornerCollisionInnerCollider = (
  modifiedGlass: GlassWithEdges,
  modifiedCornerId:
    | ANCHOR_POINTS.TOP_LEFT
    | ANCHOR_POINTS.TOP_RIGHT
    | ANCHOR_POINTS.BOTTOM_LEFT
    | ANCHOR_POINTS.BOTTOM_RIGHT,
  colliderGlass: GlassWithEdges,
  outCornerId:
    | ANCHOR_POINTS.TOP_LEFT
    | ANCHOR_POINTS.TOP_RIGHT
    | ANCHOR_POINTS.BOTTOM_LEFT
    | ANCHOR_POINTS.BOTTOM_RIGHT,
  scaledVector: Vector2d,
  vector: Vector2d,
  scale: number,
) => {
  const findVerticalMaxPoint = () => {
    if (crossedEdge.vector.startX !== crossedEdge.vector.endX) {
      const edgeLine = findLine(
        [crossedEdge.vector.startX, crossedEdge.vector.startY],
        [crossedEdge.vector.endX, crossedEdge.vector.endY],
      );

      if (Math.abs(edgeLine.a) === 0) {
        if (outCorner[1] === movedCorner[1]) {
          return { x: outCorner[0], y: movedCorner[1] };
        } else {
          return { x: movedCorner[0], y: outCorner[1] };
        }
      }

      const parallelLine = findParallelLine(outCorner, edgeLine);
      const maxYPoint = findYPointOnLine(parallelLine, movedCorner[0]);

      return { x: movedCorner[0], y: maxYPoint };
    } else {
      return { x: movedCorner[0], y: outCorner[1] };
    }
  };

  const findHorizontalMaxPoint = () => {
    if (crossedEdge.vector.startX !== crossedEdge.vector.endX) {
      const edgeLine = findLine(
        [crossedEdge.vector.startX, crossedEdge.vector.startY],
        [crossedEdge.vector.endX, crossedEdge.vector.endY],
      );

      if (Math.abs(edgeLine.a) === 0) {
        return { x: movedCorner[0], y: outCorner[1] };
      }

      const parallelLine = findParallelLine(outCorner, edgeLine);
      const maxXPoint = findXPointOnLine(parallelLine, movedCorner[1]);

      return { x: maxXPoint, y: movedCorner[1] };
    } else {
      return { x: outCorner[0], y: movedCorner[1] };
    }
  };

  const outCorner = colliderGlass.corners[outCornerId];
  const oppositeOutCorner =
    colliderGlass.corners[getOppositeCorner(outCornerId)];
  const movedCorner = modifiedGlass.corners[modifiedCornerId];

  const crossedEdge = getCrossedEdge(
    modifiedGlass,
    toVector(outCorner),
    toVector(oppositeOutCorner),
    outCornerId,
    true,
  );

  if (crossedEdge.name === '') {
    return vector;
  }

  let maxPoint;

  ['top', 'bottom', 'left', 'right'].forEach((edge) => {
    if (crossedEdge.name === edge) {
      if (edge === 'top' || edge === 'bottom') {
        maxPoint = findVerticalMaxPoint();
      } else {
        maxPoint = findHorizontalMaxPoint();
      }
    }
  });

  if (!maxPoint) {
    return vector;
  }

  return getCorrectedVector(scaledVector, maxPoint, vector, scale);
};

/**
 * Global function for removing collision for glass corner when moving any of glass corners
 * @param id - id of currently moved corner
 * @param data - data of available shapes in project
 * @param scaledVector
 * @param vector - current position of dragged corner. It is modified in children functions
 * @param {CollisionData} collider - data of colliding shape
 * @returns {Vector2d} - new position of dragged corner
 */
export const removeCollisionForGlass = (
  id: ANCHOR_POINTS,
  data: ExtendedDataCopy,
  scaledVector: Vector2d,
  vector: Vector2d,
  collider: CollisionData,
): Vector2d => {
  const outCornerName = collider.cornerName as RectCornersType;
  const glass = convertToGlass(data.shape);

  if (collider.shapeId !== data.shape.id) {
    const collidingShape = find([data.shape, ...data.shapes], {
      id: collider.shapeId,
    });

    if (!collidingShape) {
      return vector;
    }

    const colliderGlass = convertToGlass(collidingShape);

    if (id === 'top' || id === 'bottom' || id === 'left' || id === 'right') {
      return handleDraggedEdgeCollisionInnerCollider(
        glass,
        id,
        colliderGlass,
        outCornerName,
        scaledVector,
        vector,
        data.scale,
      );
    }

    return handleDraggedCornerCollisionInnerCollider(
      glass,
      id as ANCHOR_POINTS.TOP_LEFT,
      colliderGlass,
      outCornerName as ANCHOR_POINTS.TOP_LEFT,
      scaledVector,
      vector,
      data.scale,
    );
  }

  const collidingShape = find([data.shape, ...data.shapes], {
    id: collider.colliderId,
  });

  if (!collidingShape) {
    return vector;
  }

  const colliderGlass = convertToGlass(collidingShape);

  if (outCornerName === id) {
    return handleDraggedCornerCollision(
      glass,
      id,
      colliderGlass,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
      true,
    );
  }

  if (id === 'top' || id === 'bottom' || id === 'left' || id === 'right') {
    return handleDraggedEdgeCollision(
      glass,
      id,
      colliderGlass,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
      true,
    );
  }

  return handleOtherCornerCollision(
    glass,
    id as ANCHOR_POINTS.TOP_LEFT,
    colliderGlass,
    outCornerName,
    scaledVector,
    vector,
    data.scale,
    true,
  );
};

export type UtmostPoints = {
  maxX: [number, number];
  maxY: [number, number];
  minX: [number, number];
  minY: [number, number];
};

// returns utmost left, right, top and bottom points from corners outside niche list
export const getUtmostPoints = (
  niche: GlassWithEdges,
  id: string,
  cornersOutside: {
    glass: GlassWithEdges;
    name: string;
  }[],
  ignoreNiche: boolean,
) => {
  const points: [number, number][] = [];

  if (!ignoreNiche) {
    if (id === 'top-left') {
      points.push(niche.corners['top-left']);
    }
    if (id === 'top-right') {
      points.push(niche.corners['top-right']);
    }
    if (id === 'bottom-left') {
      points.push(niche.corners['bottom-left']);
    }
    if (id === 'bottom-right') {
      points.push(niche.corners['bottom-right']);
    }
  }

  cornersOutside.forEach((corner) => {
    points.push(corner.glass.corners[corner.name as RectCornersType]);
  });

  let maxX: [number, number] = points[0];
  let maxY: [number, number] = points[0];
  let minX: [number, number] = points[0];
  let minY: [number, number] = points[0];

  points.map((point) => {
    if (point[0] > maxX[0]) {
      maxX = point;
    }
    if (point[1] > maxY[1]) {
      maxY = point;
    }
    if (point[0] < minX[0]) {
      minX = point;
    }
    if (point[1] < minY[1]) {
      minY = point;
    }
  });

  return {
    maxX: maxX,
    maxY: maxY,
    minX: minX,
    minY: minY,
  } as UtmostPoints;
};

const calculateEdgeAnchor = (
  cornerA: [number, number],
  cornerB: [number, number],
) => {
  return [(cornerA[0] + cornerB[0]) / 2, (cornerA[1] + cornerB[1]) / 2] as [
    number,
    number,
  ];
};

enum Direction {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT,
}

const getMoreDistantLine = (lineA: Line, lineB: Line, direciton: Direction) => {
  const biggerBLine = lineA.b > lineB.b ? lineA : lineB;
  const smallerBLine = lineA.b < lineB.b ? lineA : lineB;

  if (direciton === Direction.TOP) {
    return smallerBLine;
  }
  if (direciton === Direction.BOTTOM) {
    return biggerBLine;
  }
  if (direciton === Direction.RIGHT) {
    if (lineA.a < 0) {
      return biggerBLine;
    } else {
      return smallerBLine;
    }
  } else {
    if (lineA.a < 0) {
      return smallerBLine;
    } else {
      return biggerBLine;
    }
  }
};

const getNewLineForNiche = (
  utmostPointGlass: [number, number],
  utmostPointNiche: [number, number],
  line: Line,
  direction: Direction,
) => {
  const lineParallelGlass = findParallelLine(utmostPointGlass, line);
  const lineParallelNiche = findParallelLine(utmostPointNiche, line);

  return getMoreDistantLine(lineParallelGlass, lineParallelNiche, direction);
};

export const removeCollisionForNiche = (
  id: ANCHOR_POINTS,
  data: Omit<DataCopy, 'shape'>,
  scaledVector: Vector2d,
  vector: Vector2d,
  utmostPointsOnlyGlasses: UtmostPoints,
  utmostPointsWithNiche: UtmostPoints,
  adjustManually: boolean,
) => {
  let newAnchorPoint: [number, number] = [0, 0];
  const { parent } = getParentPolygon(data.parents[0]);
  const nicheCorners = parent.corners;

  // assumption: they are never vertical (90deg to x axis)
  const lineTLTR = findLine(
    nicheCorners['top-left'],
    nicheCorners['top-right'],
  );
  const lineBLBR = findLine(
    nicheCorners['bottom-left'],
    nicheCorners['bottom-right'],
  );

  if (id === 'left') {
    let newPointTL: [number, number] = [0, 0];
    let newPointBL: [number, number] = [0, 0];
    if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
      const lineTLBL = findLine(
        nicheCorners['top-left'],
        nicheCorners['bottom-left'],
      );
      const lineTLBLPararell = findParallelLine(
        utmostPointsOnlyGlasses.minX,
        lineTLBL,
      );

      newPointTL = findCrossPoint(lineTLTR, lineTLBLPararell);
      newPointBL = findCrossPoint(lineBLBR, lineTLBLPararell);
    } else {
      const minPointX = utmostPointsOnlyGlasses.minX[0];
      const newPointTLY = findYPointOnLine(lineTLTR, minPointX);
      const newPointBLY = findYPointOnLine(lineBLBR, minPointX);
      newPointTL = [minPointX, newPointTLY];
      newPointBL = [minPointX, newPointBLY];
    }
    newAnchorPoint = calculateEdgeAnchor(newPointTL, newPointBL);
  }
  if (id === 'right') {
    let newPointTR: [number, number] = [0, 0];
    let newPointBR: [number, number] = [0, 0];
    if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
      const lineTRBR = findLine(
        nicheCorners['top-right'],
        nicheCorners['bottom-right'],
      );
      const lineTRBRParallel = findParallelLine(
        utmostPointsOnlyGlasses.maxX,
        lineTRBR,
      );

      newPointTR = findCrossPoint(lineTLTR, lineTRBRParallel);
      newPointBR = findCrossPoint(lineBLBR, lineTRBRParallel);
    } else {
      const maxPointX = utmostPointsOnlyGlasses.maxX[0];
      const newPointTRY = findYPointOnLine(lineTLTR, maxPointX);
      const newPointBRY = findYPointOnLine(lineBLBR, maxPointX);
      newPointTR = [maxPointX, newPointTRY];
      newPointBR = [maxPointX, newPointBRY];
    }
    newAnchorPoint = calculateEdgeAnchor(newPointTR, newPointBR);
  }
  if (id === 'top') {
    const lineTLTRParallel = findParallelLine(
      utmostPointsOnlyGlasses.minY,
      lineTLTR,
    );

    let newPointTR: [number, number] = [0, 0];
    let newPointTL: [number, number] = [0, 0];

    if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
      const lineTRBR = findLine(
        nicheCorners['top-right'],
        nicheCorners['bottom-right'],
      );

      newPointTR = findCrossPoint(lineTLTRParallel, lineTRBR);
    } else {
      const newPointTRY = findYPointOnLine(
        lineTLTRParallel,
        nicheCorners['top-right'][0],
      );

      newPointTR = [nicheCorners['top-right'][0], newPointTRY];
    }

    if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
      const lineTLBL = findLine(
        nicheCorners['top-left'],
        nicheCorners['bottom-left'],
      );

      newPointTL = findCrossPoint(lineTLTRParallel, lineTLBL);
    } else {
      const newPointTLY = findYPointOnLine(
        lineTLTRParallel,
        nicheCorners['top-left'][0],
      );

      newPointTL = [nicheCorners['top-left'][0], newPointTLY];
    }

    newAnchorPoint = calculateEdgeAnchor(newPointTR, newPointTL);
  }
  if (id === 'bottom') {
    const lineBLBRParallel = findParallelLine(
      utmostPointsOnlyGlasses.maxY,
      lineBLBR,
    );

    let newPointBR: [number, number] = [0, 0];
    let newPointBL: [number, number] = [0, 0];

    if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
      const lineTRBR = findLine(
        nicheCorners['top-right'],
        nicheCorners['bottom-right'],
      );

      newPointBR = findCrossPoint(lineBLBRParallel, lineTRBR);
    } else {
      const newPointBRY = findYPointOnLine(
        lineBLBRParallel,
        nicheCorners['bottom-right'][0],
      );

      newPointBR = [nicheCorners['bottom-right'][0], newPointBRY];
    }

    if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
      const lineTLBL = findLine(
        nicheCorners['top-left'],
        nicheCorners['bottom-left'],
      );

      newPointBL = findCrossPoint(lineBLBRParallel, lineTLBL);
    } else {
      const newPointBLY = findYPointOnLine(
        lineBLBRParallel,
        nicheCorners['bottom-left'][0],
      );

      newPointBL = [nicheCorners['bottom-left'][0], newPointBLY];
    }

    newAnchorPoint = calculateEdgeAnchor(newPointBR, newPointBL);
  }
  if (!adjustManually) {
    if (id === 'top-left') {
      const lineTLTRNew = getNewLineForNiche(
        utmostPointsOnlyGlasses.minY,
        utmostPointsWithNiche.minY,
        lineTLTR,
        Direction.TOP,
      );

      if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
        const lineTLBL = findLine(
          nicheCorners['top-left'],
          nicheCorners['bottom-left'],
        );

        const lineTLBLNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.minX,
          utmostPointsWithNiche.minX,
          lineTLBL,
          Direction.LEFT,
        );

        newAnchorPoint = findCrossPoint(lineTLBLNew, lineTLTRNew);
      } else {
        const moreDistantPoint =
          utmostPointsWithNiche.minX[0] < utmostPointsOnlyGlasses.minX[0]
            ? utmostPointsWithNiche.minX
            : utmostPointsOnlyGlasses.minX;

        const newAnchorPointY = findYPointOnLine(
          lineTLTRNew,
          moreDistantPoint[0],
        );

        newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
      }
    }
    if (id === 'top-right') {
      const lineTLTRNew = getNewLineForNiche(
        utmostPointsOnlyGlasses.minY,
        utmostPointsWithNiche.minY,
        lineTLTR,
        Direction.TOP,
      );

      if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
        const lineTRBR = findLine(
          nicheCorners['top-right'],
          nicheCorners['bottom-right'],
        );

        const lineTRBRNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.maxX,
          utmostPointsWithNiche.maxX,
          lineTRBR,
          Direction.RIGHT,
        );

        newAnchorPoint = findCrossPoint(lineTRBRNew, lineTLTRNew);
      } else {
        const moreDistantPoint =
          utmostPointsWithNiche.maxX[0] > utmostPointsOnlyGlasses.maxX[0]
            ? utmostPointsWithNiche.maxX
            : utmostPointsOnlyGlasses.maxX;

        const newAnchorPointY = findYPointOnLine(
          lineTLTRNew,
          moreDistantPoint[0],
        );

        newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
      }
    }
    if (id === 'bottom-right') {
      const lineBLBRNew = getNewLineForNiche(
        utmostPointsOnlyGlasses.maxY,
        utmostPointsWithNiche.maxY,
        lineBLBR,
        Direction.BOTTOM,
      );

      if (nicheCorners['top-right'][0] !== nicheCorners['bottom-right'][0]) {
        const lineTRBR = findLine(
          nicheCorners['top-right'],
          nicheCorners['bottom-right'],
        );

        const lineTRBRNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.maxX,
          utmostPointsWithNiche.maxX,
          lineTRBR,
          Direction.RIGHT,
        );

        newAnchorPoint = findCrossPoint(lineTRBRNew, lineBLBRNew);
      } else {
        const moreDistantPoint =
          utmostPointsWithNiche.maxX[0] > utmostPointsOnlyGlasses.maxX[0]
            ? utmostPointsWithNiche.maxX
            : utmostPointsOnlyGlasses.maxX;

        const newAnchorPointY = findYPointOnLine(
          lineBLBRNew,
          moreDistantPoint[0],
        );

        newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
      }
    }
    if (id === 'bottom-left') {
      const lineBLBRNew = getNewLineForNiche(
        utmostPointsOnlyGlasses.maxY,
        utmostPointsWithNiche.maxY,
        lineBLBR,
        Direction.BOTTOM,
      );

      if (nicheCorners['top-left'][0] !== nicheCorners['bottom-left'][0]) {
        const lineTLBL = findLine(
          nicheCorners['top-left'],
          nicheCorners['bottom-left'],
        );

        const lineTLBLNew = getNewLineForNiche(
          utmostPointsOnlyGlasses.minX,
          utmostPointsWithNiche.minX,
          lineTLBL,
          Direction.LEFT,
        );

        newAnchorPoint = findCrossPoint(lineTLBLNew, lineBLBRNew);
      } else {
        const moreDistantPoint =
          utmostPointsWithNiche.minX[0] < utmostPointsOnlyGlasses.minX[0]
            ? utmostPointsWithNiche.minX
            : utmostPointsOnlyGlasses.minX;

        const newAnchorPointY = findYPointOnLine(
          lineBLBRNew,
          moreDistantPoint[0],
        );

        newAnchorPoint = [moreDistantPoint[0], newAnchorPointY];
      }
    }
  } else {
    // adjust manually
    const nicheTRCorner = nicheCorners['top-right'];
    const nicheTLCorner = nicheCorners['top-left'];
    const nicheBLCorner = nicheCorners['bottom-left'];
    const nicheBRCorner = nicheCorners['bottom-right'];

    if (id === 'top-left') {
      const lineTopNiche = findLine(nicheTRCorner, utmostPointsWithNiche.minY);
      const lineTopGlass = findLine(
        nicheTRCorner,
        utmostPointsOnlyGlasses.minY,
      );
      const lineTop = getMoreDistantLine(
        lineTopNiche,
        lineTopGlass,
        Direction.TOP,
      );

      if (
        nicheBLCorner[0] !== utmostPointsWithNiche.minX[0] &&
        nicheBLCorner[0] !== utmostPointsOnlyGlasses.minX[0]
      ) {
        const lineLeftNiche = findLine(
          nicheBLCorner,
          utmostPointsWithNiche.minX,
        );
        const lineLeftGlass = findLine(
          nicheBLCorner,
          utmostPointsOnlyGlasses.minX,
        );

        const lineLeft = getMoreDistantLine(
          lineLeftNiche,
          lineLeftGlass,
          Direction.LEFT,
        );

        newAnchorPoint = findCrossPoint(lineTop, lineLeft);
      } else {
        const minPointX =
          utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
            ? utmostPointsWithNiche.minX
            : utmostPointsOnlyGlasses.minX;
        const newAnchorPointY = findYPointOnLine(lineTop, minPointX[0]);
        newAnchorPoint = [minPointX[0], newAnchorPointY];
      }
    }
    if (id === 'bottom-left') {
      const lineBottomNiche = findLine(
        nicheBRCorner,
        utmostPointsWithNiche.maxY,
      );
      const lineBottomGlass = findLine(
        nicheBRCorner,
        utmostPointsOnlyGlasses.maxY,
      );
      const lineBottom = getMoreDistantLine(
        lineBottomNiche,
        lineBottomGlass,
        Direction.BOTTOM,
      );

      if (
        nicheTLCorner[0] !== utmostPointsWithNiche.minX[0] &&
        nicheTLCorner[0] !== utmostPointsOnlyGlasses.minX[0]
      ) {
        const lineLeftNiche = findLine(
          nicheTLCorner,
          utmostPointsWithNiche.minX,
        );
        const lineLeftGlass = findLine(
          nicheTLCorner,
          utmostPointsOnlyGlasses.minX,
        );

        const lineLeft = getMoreDistantLine(
          lineLeftNiche,
          lineLeftGlass,
          Direction.LEFT,
        );

        newAnchorPoint = findCrossPoint(lineBottom, lineLeft);
      } else {
        const minPointX =
          utmostPointsWithNiche.minX[0] > utmostPointsOnlyGlasses.minX[0]
            ? utmostPointsWithNiche.minX
            : utmostPointsOnlyGlasses.minX;
        const newAnchorPointY = findYPointOnLine(lineBottom, minPointX[0]);
        newAnchorPoint = [minPointX[0], newAnchorPointY];
      }
    }
    if (id === 'bottom-right') {
      const lineBottomNiche = findLine(
        nicheBLCorner,
        utmostPointsWithNiche.maxY,
      );
      const lineBottomGlass = findLine(
        nicheBLCorner,
        utmostPointsOnlyGlasses.maxY,
      );
      const lineBottom = getMoreDistantLine(
        lineBottomNiche,
        lineBottomGlass,
        Direction.BOTTOM,
      );

      if (
        nicheTRCorner[0] !== utmostPointsWithNiche.maxX[0] &&
        nicheTRCorner[0] !== utmostPointsOnlyGlasses.maxX[0]
      ) {
        const lineRightNiche = findLine(
          nicheTRCorner,
          utmostPointsWithNiche.maxX,
        );
        const lineRightGlass = findLine(
          nicheTRCorner,
          utmostPointsOnlyGlasses.maxX,
        );

        const lineRight = getMoreDistantLine(
          lineRightNiche,
          lineRightGlass,
          Direction.RIGHT,
        );

        newAnchorPoint = findCrossPoint(lineBottom, lineRight);
      } else {
        const maxPointX =
          utmostPointsWithNiche.maxX[0] < utmostPointsOnlyGlasses.maxX[0]
            ? utmostPointsWithNiche.maxX
            : utmostPointsOnlyGlasses.maxX;
        const newAnchorPointY = findYPointOnLine(lineBottom, maxPointX[0]);
        newAnchorPoint = [maxPointX[0], newAnchorPointY];
      }
    }
    if (id === 'top-right') {
      const lineTopNiche = findLine(nicheTLCorner, utmostPointsWithNiche.minY);
      const lineTopGlass = findLine(
        nicheTLCorner,
        utmostPointsOnlyGlasses.minY,
      );
      const lineTop = getMoreDistantLine(
        lineTopNiche,
        lineTopGlass,
        Direction.TOP,
      );

      if (
        nicheBRCorner[0] !== utmostPointsWithNiche.maxX[0] &&
        nicheBRCorner[0] !== utmostPointsOnlyGlasses.maxX[0]
      ) {
        const lineRightNiche = findLine(
          nicheBRCorner,
          utmostPointsWithNiche.maxX,
        );
        const lineRightGlass = findLine(
          nicheBRCorner,
          utmostPointsOnlyGlasses.maxX,
        );

        const lineLeft = getMoreDistantLine(
          lineRightNiche,
          lineRightGlass,
          Direction.RIGHT,
        );

        newAnchorPoint = findCrossPoint(lineTop, lineLeft);
      } else {
        const maxPointX =
          utmostPointsWithNiche.maxX[0] < utmostPointsOnlyGlasses.maxX[0]
            ? utmostPointsWithNiche.maxX
            : utmostPointsOnlyGlasses.maxX;
        const newAnchorPointY = findYPointOnLine(lineTop, maxPointX[0]);
        newAnchorPoint = [maxPointX[0], newAnchorPointY];
      }
    }
  }

  return getCorrectedVector(
    scaledVector,
    { x: newAnchorPoint[0], y: newAnchorPoint[1] },
    vector,
    data.scale,
  );
};

export const removeCollisionForCorner = (
  id: ANCHOR_POINTS,
  data: DataCopy,
  scaledVector: Vector2d,
  vector: Vector2d,
  outCorner: string,
) => {
  const outCornerName = outCorner as RectCornersType;
  const glass = convertToGlass(data.shape);
  const { parent } = getParentPolygon(data.parents[0]);

  if (outCornerName === id) {
    return handleDraggedCornerCollision(
      glass,
      id,
      parent,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
    );
  } else if (
    id === 'top' ||
    id === 'bottom' ||
    id === 'left' ||
    id === 'right'
  ) {
    return handleDraggedEdgeCollision(
      glass,
      id,
      parent,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
    );
  } else {
    return handleOtherCornerCollision(
      glass,
      id as ANCHOR_POINTS.TOP_LEFT,
      parent,
      outCornerName,
      scaledVector,
      vector,
      data.scale,
    );
  }
};
